//hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { NewTableDefault } from "../../../components/table/new.table.default";
//libraries
import { FaEdit, FaMoneyBill, FaArrowsAltH, FaQuestionCircle } from 'react-icons/fa'
import { FaArrowRightArrowLeft } from 'react-icons/fa6'
import Tippy from "@tippyjs/react";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { useThemeContext } from "../../../contexts/themeContext";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";


export function Accounts() {

    const navigate = useNavigate()
    const { defaultCallsFinancial } = useApiContext()
    const { userData } = useAuth()
    const { setShowUniversalModal, showUniversalModal } = useThemeContext()
    const [active, setActive] = useState(true)

    return (   
        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full">
                    <Title text='Contas e Bancos' />
                    <Button module={"financialAccounts:create"} userData={userData} shadow={true} onClick={() => navigate('/financial/accountsCreate')}>+ Novo</Button>
                </div>
            </TitlePage>
            <Body>
                <div className="flex flex-col ga-4 w-full">
                    <div className='flex flex-row items-center justify-start w-full mb-6'>
                        <button
                            className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${active === true ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`}
                            onClick={() => setActive(true)}
                        >
                            Ativos
                        </button>
                        <button
                            className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${active === false ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`}
                            onClick={() => setActive(false)}
                        >
                            Inativos
                        </button>
                    </div>
                    <NewTableDefault
                        key={'table-costCenter'}
                        endpoint="/api/v1/financial/accounts/withBalance"
                        token={userData[0]?.token}
                        module="financial"
                        columns={[
                            {
                                description: 'ID',
                                field: 'id',
                                type: 'number'
                            },
                            {
                                description: 'Tipo',
                                field: 'accountType',
                                type: 'string',
                                newFieldValue: (e) => {
                                    return <p className="font-bold">
                                        {e?.accountType == 'bank' ? 'Conta Bancária'
                                            : e?.accountType == 'gateway' ? 'Gateway'
                                                : 'Caixa'
                                        }
                                    </p>
                                }
                            },
                            {
                                description: 'Nome',
                                type: 'string',
                                field: 'name'
                            },
                            {
                                description: 'Saldo',
                                type: 'string',
                                field: 'balance',
                                newFieldValue: (account) => {

                                    return <div className="flex gap-2 justify-center items-center">
                                        <a>{account?.balance.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</a>
                                        <Tippy
                                            content={'Transferir saldo'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}
                                        >
                                            <div>
                                                <FaArrowRightArrowLeft 
                                                className="rotate-90"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    
                                                    navigate('/financial/accountsTransfers')
                                                }} />

                                            </div>
                                        </Tippy>
                                    </div>

                                }
                            },
                            {
                                description: 'Criado em',
                                field: 'created_at',
                                type: 'date',
                            },
                            {
                                description: 'Ações',
                                field: '',
                                type: 'string',
                                newFieldValue: (account) => {
                                    return <div className="flex items-center justify-center gap-3">
                                        <Tippy
                                            content={'Editar'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}
                                        >
                                            <div>
                                                <FaEdit className='cursor-pointer text-zinc-600' size={14} onClick={(e) => {
                                                    e.stopPropagation()
                                                    navigate(`/financial/accountsEdit/${account.id}`)
                                                }} />
                                            </div>
                                        </Tippy>
                                        {
                                            account.accountType == 'bank' && userData[0]?.customersData?.useOfxConciliation &&
                                            <Tippy
                                                content={'Conciliações'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}
                                            >
                                                <div>
                                                    <FaMoneyBill className='cursor-pointer text-lime-600' size={17} onClick={(e) => {
                                                        e.stopPropagation()
                                                        navigate(`/financial/accounts/${account.id}/conciliations`)
                                                    }} />
                                                </div>
                                            </Tippy>
                                        }
                                    </div>
                                }
                            }
                        ]}
                        filters={{
                            active
                        }}
                        onClickInTableLine={(row) => navigate(`/financial/accountsEdit/${row.id}`)}
                    />
                </div>
            </Body>
        </Container>
    )
}