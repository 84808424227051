import React, { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/useAuth'
import { FaRegBuilding } from 'react-icons/fa'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'
import { DefaultLoader } from '../loaders/defaultLoader'
import moment from 'moment'
import { useApiContext } from '../../contexts/ApiInterceptorContext'

export function ReceiptActivitiesActions(dataPath) {
    
    const { defaultCallsSchedules, api } = useApiContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const data = JSON.parse(localStorage.getItem('receiptActivitiesActions'))
    const [termOfUse, setTermOfUse] = useState('')

    async function getData() {
        try {
            if (data?.showTerms) {
                const terms = (await api.get(`/api/v1/activities/${data?.activities_id}`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })).data
                if (terms?.data?.length > 0) {
                    setTermOfUse(terms?.data[0]?.termOfUse)
                }
            } else {
                setTermOfUse({})
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    async function handlePrint() {
        await new Promise(resolve => setTimeout(resolve, 1000))
        window.print()
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (!loading) {
            handlePrint()
        }
    }, [loading])

    if (loading) {
        return <div>
            <DefaultLoader />
        </div>
    }

    return (
        <div id='printArea' className='w-[960px] min-h-[100vh] gap-2 overflow-auto flex flex-col items-center justify-start bg-white'>
            <div className='w-full border-b overflow-hidden py-2 border-gray-700 flex flex-col items-start justify-start'>
                <div className='font-bold gap-4 flex flex-row px-4 justify-center items-center'>
                    {
                        userData[0].customersData.photo?.length > 0 ?
                            <div className=''>
                                <img width={100} className='grayscale-0' src={userData[0].customersData.photo}></img>
                            </div>
                            :
                            <FaRegBuilding className='text-5xl' />
                    }
                    <p className='text-base font-bold'>{userData[0].customersData.name}</p>
                </div>
            </div>
            <div className='w-full flex flex-col items-start justify-start'>
                <div className=' border-b border-b-gray-700 flex flex-row gap-2 w-full h-44 items-center'>
                    <div className='flex flex-col px-4'>
                        <p className='text-base font-bold underline'>Recibo de inscrição</p>
                        <div className='flex flex-row gap-2'>
                            <p className='text-sm font-bold'>Cliente:</p>
                            <p className='text-sm'>{`${data?.client}`}</p>
                        </div>
                        <div className='flex flex-row gap-2'>
                            <p className='text-sm font-bold'>Data início:</p>
                            <p className='text-sm'>{moment(data?.original_startDate).utc(false).format("DD/MM/YYYY")}</p>
                        </div>
                        <div className='flex flex-row gap-2'>
                            <p className='text-sm font-bold'>Data fim:</p>
                            <p className='text-sm'>{moment(data?.original_finishDate).utc(false).format("DD/MM/YYYY")}</p>
                        </div>
                        <div className='flex flex-row gap-2'>
                            <p className='text-sm font-bold'>Criado em:</p>
                            <p className='text-sm'>{data?.created_at}</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-2 w-full py-2 items-center mb-10'>
                    <div className='flex flex-col h-full items-start justify-center px-4 w-full '>
                        <div className='flex flex-row gap-2'>
                            <p className='text-sm font-bold'>Plano:</p>
                            <p className='text-sm'>{data?.planName}</p>
                        </div>
                        <div className='flex flex-row gap-2 mb-4'>
                            <p className='text-sm font-bold'>Valor do plano:</p>
                            <p className='text-sm'>{data?.original_planValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                        </div>
                        <div className='flex flex-row gap-2 mb-4'>
                            <p className='text-sm font-bold'>Reembolso:</p>
                            <p className='text-sm'>{data?.chargeBack?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                        </div>
                        <div className='flex flex-row gap-2 mb-4'>
                            <p className='text-sm font-bold'>Valor total:</p>
                            <p className='text-sm'>{data?.totalValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                        </div>
                        <div className='flex flex-row gap-2 mb-4'>
                            <p className='text-sm font-bold'>Pago:</p>
                            <p className='text-sm'>{data?.numberOfInstallments}x de {(data?.totalValue / data?.numberOfInstallments).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                        </div>
                        <div className='flex flex-row gap-2 mb-4'>
                            <p className='text-sm font-bold'>Forma de pagamento:</p>
                            <p className='text-sm'>{data?.payments_method}</p>
                        </div>
                        <div className='flex flex-row gap-2 mb-4'>
                            <p className='text-sm font-bold'>Status:</p>
                            <p className='text-sm'>{data?.active ? 'Ativo' : 'Inativo/Cancelado'}</p>
                        </div>
                    </div>
                </div>
                <div className=' flex flex-row gap-2 w-full py-2 items-center px-4'>
                    <div className='text-sm flex flex-col items-center justify-start gap-2'>
                        <p>...................................................................................</p>
                        <p>Caixa recebimento</p>
                    </div>
                </div>
            </div>
            {
                !!termOfUse &&
                <div className='mb-10 w-full flex flex-col px-4 mt-10'>
                    <strong className='underline mb-10'>{termOfUse?.title}</strong>
                    <ReactQuill readOnly theme='bubble' value={termOfUse?.ops} />
                </div>
            }
        </div>
    )
}
