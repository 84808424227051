import React from 'react'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'

export const Body = ({ children, padding, background, shadow, hasFooter }) => {

    const { screenX, screenY } = useScreenSizeContext()
    const offSet = hasFooter && screenX < 640 ? 230 : 170
    const screenSize = screenY - offSet

    return (
        <div style={{ 
            maxHeight: hasFooter ? screenY + 'px' : 
            screenX > 640 ? 
            screenX > 1024 ? 
            screenY + 'px' : 
            screenY + 'px'  : 
            screenY - 136 + 'px', 
            height: hasFooter && screenX > 640 ? screenY : 
            !hasFooter && screenX < 640 ? screenSize + 38 : 
            !hasFooter ? screenY : screenSize + 38            
        }} className={`        
        scrollbar-thin scrollbar-thumb-zinc-400 scrollbar-track-zinc-200 dark:scrollbar-thumb-thirdDefaultDark animate-[wiggleLeft_.8s_ease-in-out]
        dark:scrollbar-track-primaryDefaultDark scrollbar-thumb-rounded-full scrollbar-track-rounded-full
        overflow-auto w-full flex flex-col items-center justify-start pb-6`}>
            <div
                className={`rounded-lg flex ${shadow === false ? '' : 'shadow-lg'}                 
            flex-col w-[96%] 3xl:w-full gap-4 items-center ${background === undefined ? 'dark:border dark:border-primaryBorderDark ' : ''} 
            ${background === undefined ? 'bg-bgPrimaryLight dark:bg-secondaryDefaultDark' : background} 
            ${padding || padding === undefined ? 'p-2 md:p-8' : ''} max-w-[1950px]`}>
                {children}
            </div>
        </div>

    )
}