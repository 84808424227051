import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import React, { useEffect, useState, useRef } from 'react'
import 'moment/locale/pt-br'
//components
import { CalendarMain } from '../../../../components/calendar/calendar.main'
import { Body } from '../../../../components/container/Body'
import { Container } from '../../../../components/container/container'
import { Input } from '../../../../components/input/input'
import { InputAutoComplete } from '../../../../components/input/input.autocomplete'
import { InputCheckBox } from '../../../../components/input/input.checkBox';
import { LabelInput } from '../../../../components/label/label.input'
import { Stepper } from '../../../../components/stepper/stepper'
import { Title } from '../../../../components/titlePages/title'
import { TitlePage } from '../../../../components/titlePages/title.page'
import CurrencyInput from '../../../../components/input/inputCurrencyPtBr';
import { ScreenModal } from '../../../../components/modals/notification/screenModal';
import { UsersToFind } from '../../../../components/table/usersToFind.table';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { Quillmodules } from '../../../../components/richText/quill.modules';
//context
import { useThemeContext } from '../../../../contexts/themeContext';
import { useAuth } from '../../../../contexts/useAuth';
import { DefaultLoader } from '../../../../components/loaders/defaultLoader';
import { Button } from '../../../../components/buttons/button.default';
import { FaAngleDown, FaCalendar, FaClock, FaListUl, FaMapMarkerAlt } from 'react-icons/fa';
//services
import { SwiperContainer } from '../../../../components/swiper/swiper';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../../../../components/footer/Footer';
import { responseError } from '../../../../utils/responsesFunctions/error.response';
import { paymentMethods } from '../../../../utils/payment/paymentMethods';
import moment from 'moment';
import { useApiContext } from '../../../../contexts/ApiInterceptorContext';

export function ReservationCreate() {

    const { defaultCallsSchedules, defaultCallsAuth, api } = useApiContext()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const spaceOneRef = useRef()
    const spaceTwoRef = useRef()
    const spaceTreeRef = useRef()
    const spaceCategorieRef = useRef()

    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, showUniversalModal, setShowUniversalModal } = useThemeContext()
    const { userData } = useAuth()
    const [filterdHours, setFilteredHours] = useState([])
    const [categories, setCategories] = useState([])
    const [usersList, setUsersList] = useState([])
    const [filteredServices, setFilteredServices] = useState([])
    const [servicePhotos, setServicesPhotos] = useState([])
    const [dontHavePaymentMethods, setDontHavePaymentMethods] = useState(false)
    const [showSpaceList, setShowSpaceList] = useState(false)
    const [userPermitted, setUserPermiited] = useState(false)
    const [showButtonSubmit, setShowButtonSubmit] = useState(true)
    const spaceState = useRef(showSpaceList)
    const setMyState = data => {
        spaceState.current = data;
        setShowSpaceList(data);
    };
    const [animateSpace, setAnimateSpace] = useState({ one: false, two: false, tree: false })
    const [discount, setDiscount] = useState(0)
    const [totalValue, setTotalValue] = useState(0)
    const [termOfUseDescription, setTermsOfUseDescription] = useState('')

    const [selectedUserToSchedule, setSelectedUserToSchedule] = useState('')
    const [selectedModalite, setSelectedModalite] = useState('')
    const [selectedCategorie, setSelectedCategorie] = useState([])
    const [selectedService, setSelectedService] = useState([])
    const [serviceDaysToOpen, setServiceDaysToOpen] = useState('')
    const [selectedValue, setSelectedValue] = useState([])
    const [selectedHours, setSelectedHours] = useState([])
    const [selectedObservation, setSelectedObservation] = useState('')
    const [selectedDay, setSelectedDay] = useState('')
    const [selectedContractSigned, setSelectedContractSigned] = useState({ id: false, description: "Não" })
    const [selectedPayment, setSelectedPayment] = useState('')
    const [selectedWeekday, setSelectedWeekday] = useState(null)
    const [useBalance, setUseBalance] = useState(false)
    const [usedBalance, setUsedBalance] = useState(0)
    const [fee, setFee] = useState(0)
    const [schedulesProtocol, setSchedulesProtocol] = useState(0)
    const [drawId, setDrawId] = useState('')
    const [showModalType, setShowModalType] = useState('')
    const [isRecurrent, setIsRecurrent] = useState(false)
    const [recurrenceFinishDate, setRecurrenceFinishDate] = useState('')
    const [recurrenceQuantityOfDays, setRecurrenceQuantityOfDays] = useState(0)
    const [recurrencePaymentDueDate, setRecurrencePaymentDueDate] = useState(moment().format('YYYY-MM-DD'))

    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState({
        id: 0, description: 'Pendente', valid: false
    })
    const [steps, setSteps] = useState([
        {
            id: 0, text: 'Reserva', valid: false
        },
        {
            id: 1, text: 'Pagamento', valid: false
        },
        {
            id: 2, text: 'Concluído', valid: false
        }
    ])

    const [statusPaymentOptions, setStatusPaymentOptions] = useState([
        {
            id: 0, description: 'Pendente'
        },
        {
            id: 1, description: 'Pago'
        },
    ])

    const [filteredSteps, setFilteredSteps] = useState(steps)
    const [activeStep, setActiveStep] = useState({ id: 0 })

    async function formatAPIPhoto(apiData, serviceId) {
        if (apiData.pictures?.length > 0) {

            let photosArray = []

            await Promise.all(apiData.pictures.map(async (picture) => {
                try {
                    const file = await api.get(`api/v1/servicesPictures/services_id/${serviceId}/picture/${picture}`, {
                        responseType: 'blob',
                        responseEncoding: 'base64',
                        headers: {
                            Authorization: `Bearer ${userData[0].token}`
                        }
                    })
                    const fileObj = URL.createObjectURL(file.data)
                    photosArray.push(fileObj)
                } catch (error) {
                    console.log('Image not found', error)
                }
            }))
            setServicesPhotos(photosArray)
        }
    }

    async function getServiceDetails(serviceId) {

        const getServices = await defaultCallsSchedules.getServicesEspecific(userData[0]?.token, serviceId)
        let termId = getServices.data[0].termsOfUse_id
        if (termId > 0) {
            const servicesTerms = await defaultCallsSchedules.getTermsOfUseEspecific(userData[0]?.token, termId)

            setTermsOfUseDescription(
                servicesTerms.data[0].termsOfUse.ops.length > 0 ?
                    servicesTerms.data[0].termsOfUse : false
            )
        }
        if (getServices.error) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(getServices.data)
        }

        await formatAPIPhoto(getServices.data[0], serviceId)
    }

    async function getServiceHoursFree(day) {
        try {
            const hours = userData[0]?.typeAccess_id < 4
                ? await defaultCallsSchedules.getSchedulesForAnotherPerson(userData[0]?.token, day.format("YYYY-MM-DD"), selectedService.id, selectedUserToSchedule.id)
                : await defaultCallsSchedules.getSchedulesHours(userData[0]?.token, day, selectedService.id)
            setSelectedDay(day)
            setSelectedWeekday(moment(day).weekday())
            setRecurrenceFinishDate('')
            setRecurrenceQuantityOfDays(0)
            setFilteredHours(hours.data)
            setSelectedValue(0)
            setSelectedHours([])

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                animateLoading(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                animateLoading(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function checkSpaceActiveKeyboard(e) {
        if (spaceState.current > 0 && e.code === 'Escape') {
            setShowSpaceList('')
            setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
        }
    }

    async function closeSpace() {
        await new Promise(resolve => setTimeout(resolve, 100))
        setShowSpaceList('')
        setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
    }

    function checkSpaceActive(element) {

        if (spaceState.current === 1 && !spaceOneRef.current?.contains(element.target)) {
            setShowSpaceList('')
            setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
        }

        if (spaceState.current === 2 && !spaceTwoRef.current?.contains(element.target)) {
            setShowSpaceList('')
            setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
        }

        if (spaceState.current === 3 && !spaceTreeRef.current?.contains(element.target)) {
            setShowSpaceList('')
            setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
        }

        if (spaceState.current === 4 && !spaceCategorieRef.current?.contains(element.target)) {
            setShowSpaceList('')
            setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
        }
    }

    function extractNumber(name) {
        const match = name.match(/\d+/);
        return match ? parseInt(match[0]) : Infinity;
    }

    useEffect(() => {

        async function getData() {
            // const servicesData = await defaultCallsSchedules.getServices(1)
            const users = await defaultCallsAuth.getUsers(userData[0]?.token)

            const categoriesData = await defaultCallsSchedules.getCategories(userData[0]?.token)
            if (categoriesData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(defaultCallsSchedules.getServices.data)
            }

            setCategories(categoriesData.data.filter(categorie => categorie.active))
            // setServices(filterActiveServices(servicesData.data))
            setUsersList(users.data)
            setUserPermiited(
                (userData[0]?.permissions?.indexOf('schedule:create') > -1 || userData[0]?.typeAccess_id < 3) ? true : false
            )
            setLoading(false)
        }
        getData()
    }, [])

    useEffect(() => {

        window.addEventListener('click', (e) => checkSpaceActive(e))
        window.addEventListener('keydown', (e) => checkSpaceActiveKeyboard(e))

        window.removeEventListener('keydown', (e) => checkSpaceActiveKeyboard(e))
        return window.removeEventListener('click', (e) => checkSpaceActive(e))

    }, [])

    useEffect(() => {

        async function alterAnimateSpace() {

            await new Promise(resolve => setTimeout(resolve, 100))
            if (showSpaceList === 1) {
                setAnimateSpace((prev) => ({ one: true, two: false, tree: false, four: false }))
            }
            if (showSpaceList === 2) {
                setAnimateSpace((prev) => ({ one: false, two: true, tree: false, four: false }))
            }
            if (showSpaceList === 3) {
                setAnimateSpace((prev) => ({ one: false, two: false, tree: true, four: false }))
            }

            if (showSpaceList === 4) {
                setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: true }))
            }
        }

        alterAnimateSpace()

    }, [showSpaceList])

    function formatHours(hour) {

        try {

            let thisHour = hour

            if (thisHour < 10) {
                thisHour = `0${hour?.toString()}`
            }
            if (thisHour?.toString()?.indexOf('.5') > -1) {
                let newHour = thisHour?.toString()?.replace('.5', ':30')
                return newHour
            }
            return `${thisHour}:00`
        } catch (error) {
            return hour
        }
    }

    function validateRequiredFields() {

        if (selectedCategorie === '') {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Selecione o tipo')
            return { error: true }
        }
        if (selectedService?.length === 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Selecione o local')
            return { error: true }
        }
        if (selectedDay === '') {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Selecione o dia')
            return { error: true }
        }
        if (isRecurrent && !recurrenceFinishDate){
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Informe a data fim da recorrência.')
            return { error: true }
        }
        if(isRecurrent && moment(recurrenceFinishDate).isBefore(selectedDay)){
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('A data fim da recorrência é menor que a da reserva.')
            return { error: true }
        }
        if (selectedHours.length === 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Selecione o horário')
            return { error: true }
        }
        if (selectedService.modalities?.length > 0 && selectedModalite === '') {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Selecione a modalidade')
            return { error: true }
        }
        if (selectedPaymentStatus.id === 1 && selectedPayment === '') {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Selecione a forma de pagamento')
            return { error: true }
        }
        if(isRecurrent && selectedPaymentStatus.id == 0 && !recurrencePaymentDueDate){
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Informe a data para pagamento dessa reserva recorrente')
            return { error: true }
        }
        return { error: false }
    }

    async function handleSubmit() {

        await new Promise((resolve) => setTimeout(resolve, 200))
        if (userData[0]?.typeAccess_id < 3 && selectedUserToSchedule === '') {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowButtonSubmit(true)
            return setShowNotificationModalText('Obrigatório informar o usuário')
        }

        if (validateRequiredFields().error) {
            setShowButtonSubmit(true)
            return
        }
        setShowNotificationModal(false)

        if (activeStep.id === 1 && selectedHours[0]?.isDrawn && !drawId) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowButtonSubmit(true)
            return setShowNotificationModalText('Obrigatório informar o id do sorteio ganhador')
        }

        if (activeStep.id === 1) {
            try {
                const newSchedule = await api.post(`/api/v1/schedules`, {
                    schedule_users_id: selectedUserToSchedule.id,
                    scheduleDate: selectedDay.format("YYYY-MM-DD"),
                    servicesHours: selectedHours.map(hour => hour.id),
                    services_id: selectedService.id,
                    discount: discount > 0 ? discount : undefined,
                    fee: fee,
                    useBalance: useBalance,
                    status: selectedPaymentStatus?.id,
                    paymentMethod: selectedPayment?.value,
                    observation: selectedObservation?.length > 0 ? selectedObservation : ' ',
                    schedulesDraw_id: drawId ? drawId : undefined,
                    contractSigned: selectedContractSigned?.id,
                    modality: selectedModalite ? selectedModalite : undefined,
                    isRecurrent,
                    recurrenceFinishDate: isRecurrent ? recurrenceFinishDate : undefined,
                    recurrencePaymentDueDate: isRecurrent ? recurrencePaymentDueDate : undefined
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })

                setShowButtonSubmit(true)
                setSchedulesProtocol(newSchedule.data.data.schedules[0].id)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(true)
                setShowNotificationModalText('Agendamento realizado com sucesso')

            } catch (error) {
                console.log(error)
                setShowButtonSubmit(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                return 
            }
        }
        setShowButtonSubmit(true)
        setActiveStep((prev) => ({ id: prev.id + 1 }))
    }

    async function selectCategorie(categorie) {
        setLoading(true)
        if (selectedCategorie.id !== categorie.id) {
            setSelectedCategorie(categorie)
            setSelectedService([])
            const customerAttendance = await defaultCallsSchedules.getCustomersAttendance(userData[0]?.token)
            const customerAttendanceForThisAssociation = customerAttendance.data[0].associationsParameters.filter(associationParam => associationParam.associations_id === selectedUserToSchedule.associations_id && associationParam.categories_id === categorie.id)
            setServiceDaysToOpen(customerAttendanceForThisAssociation[0]?.daysToOpen)

            const filteredServices = await defaultCallsSchedules.getServicesByCategoriesId(userData[0]?.token, categorie.id)
            if (filteredServices?.data?.length > 0) {
                setFilteredServices(filteredServices?.data?.sort((a, b) => {
                    const numA = extractNumber(a.name);
                    const numB = extractNumber(b.name);

                    if (numA !== numB) {
                        return numA - numB;
                    } else {
                        return a.name.localeCompare(b.name);
                    }
                }))
            }
        }
        setShowSpaceList('')
        setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
        setLoading(false)
    }

    async function selectService(service) {
        setLoading(true)
        await getServiceDetails(service.id)
        await new Promise(resolve => setTimeout(resolve, 300))
        setSelectedService(service)
        setLoading(false)
    }

    async function handleSelectedHour(hour) {

        const drawnHours = selectedHours.filter(hour => hour.isDrawn)
        const normalHorus = selectedHours.filter(hour => !hour.isDrawn)

        if (hour.isDrawn && normalHorus.length > 0) {
            return alert('Você não pode selecionar horas normais e de sorteio juntas')
        }

        if (!hour.isDrawn && drawnHours.length > 0) {
            return alert('Você não pode selecionar horas normais e de sorteio juntas')
        }

        if (drawnHours?.length > 0) {
            if (drawnHours.filter(drawnHour => drawnHour.id !== hour.id).length > 0) {
                return alert('Só é permitido selecionar um horário por vez')
            }
        }

        const hours = [...selectedHours]
        const hourAlreadyIsSelected = selectedHours.findIndex(h => h.id == hour.id)
        hourAlreadyIsSelected > -1
            ? hours.splice(hourAlreadyIsSelected, 1)
            : hours.push(hour)

        setSelectedHours(hours)
        const values = hours.reduce((acumulator, currentValue) => acumulator + currentValue.serviceValue, 0)
        setSelectedValue(values * (isRecurrent ? recurrenceQuantityOfDays : 1))
        setTotalValue(values * (isRecurrent ? recurrenceQuantityOfDays : 1))
    }

    function confirmationContainer() {
        return selectedService.reservationType === 'H' ?
            (
                <div className='max-w-[1000px] w-full flex flex-col gap-4 mt-3 mb-6 rounded-lg p-3 sm:p-0 items-start justify-start'>
                    <div className='flex flex-col w-full border-b border-gray-400'>
                        <h1 className='text-base font-bold text-primaryDefaultLight'>Confirmação</h1>
                    </div>
                    <div className='items-start justify-start w-full'>
                        <div className='h-full flex text-sm flex-row flex-wrap gap-6 items-start justify-start w-full'>
                            <div className='flex flex-col gap-4 items-start justify-start'>
                                <p className='font-bold text-sm text-titleBlackTextDark dark:text-titleGrayTextDark'>Cliente</p>
                                <div className='flex flex-row gap-1 items-start justify-start'>
                                    <p className='text-sm text-titleBlackTextDark dark:text-titleGrayTextDark'>{selectedUserToSchedule.id} - </p>
                                    <p className='text-sm text-titleBlackTextDark dark:text-titleGrayTextDark'>{selectedUserToSchedule.firstName}</p>
                                </div>
                            </div>
                            <div className='flex flex-col gap-4 items-start justify-start'>
                                <p className='font-bold text-sm text-titleBlackTextDark dark:text-titleGrayTextDark'>Categoria:</p>
                                <p className='text-sm text-titleBlackTextDark dark:text-titleGrayTextDark'>{selectedCategorie.name}</p>
                            </div>
                            <div className='flex flex-col gap-4 items-start justify-start'>
                                <p className='font-bold text-sm text-titleBlackTextDark dark:text-titleGrayTextDark'>Espaço:</p>
                                <p className='text-sm text-titleBlackTextDark dark:text-titleGrayTextDark'>{selectedService.name}</p>
                            </div>
                            <div className='flex flex-col gap-4 items-start justify-start'>
                                <p className='font-bold text-sm text-titleBlackTextDark dark:text-titleGrayTextDark'>Tipo:</p>
                                <p className='text-sm text-titleBlackTextDark dark:text-titleGrayTextDark'>{isRecurrent ? 'Recorrente' : 'Avulsa' }</p>
                            </div>
                            <div className='flex flex-col gap-4 items-start justify-start'>
                                <p className='font-bold text-sm text-titleBlackTextDark dark:text-titleGrayTextDark'>{isRecurrent ? 'Período' : 'Data' }:</p>
                                <p className='text-sm dark:text-titleGrayTextDark'>{selectedDay?.format("DD/MM/YYYY")?.toString()} {isRecurrent ? `até ${moment(recurrenceFinishDate)?.format("DD/MM/YYYY")?.toString()}` : ''}</p>
                            </div>
                            <div className='flex flex-col gap-4 items-start justify-start'>
                                <p className='font-bold text-sm text-titleBlackTextDark dark:text-titleGrayTextDark'>{'Horarios(s):'}</p>
                                <div className='flex flex-row gap-1 items-start justify-start flex-wrap'>
                                    {
                                        selectedHours?.map((hour, index) => {
                                            return <div className='flex flex-row gap-1 items-start justify-start'>
                                                <p className='dark:text-titleGrayTextDark'>{formatHours(hour?.startHour)}</p>
                                                <p className='dark:text-titleGrayTextDark'>as</p>
                                                <p className='dark:text-titleGrayTextDark'>{formatHours(hour?.finishHour)}</p>
                                                {index + 1 < selectedHours.length && <p className='mx-2 dark:text-titleGrayTextDark'>|</p>}
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            : // temporariamente não utilizado
            <div className='w-full flex flex-col gap-4 mt-6 mb-6  rounded-lg p-3'>
                <h1 className='text-base font-bold text-primaryDefaultLight'>Confirmação</h1>
                <div className='p-2 rounded-lg border border-primaryDefaultLight bg-bgPrimaryLight dark:bg-secondaryDefaultDark'>
                    <div className='flex flex-row gap-2 items-center justify-start'>
                        <FaMapMarkerAlt className='text-primaryDefaultLight' />
                        <p className='text-primaryDefaultLight text-sm font-bold'>Espaço selecionado: </p>
                        <h2 className='text-base text-titleBlackTextLight'>{selectedService?.name}</h2>
                    </div>
                    <div className='border-t border-primaryDefaultLight h-2 w-full mt-2 mb-2'>.</div>
                    <div className='flex flex-row gap-2 items-center justify-start'>
                        <FaCalendar className='text-primaryDefaultLight' />
                        <p className='text-primaryDefaultLight text-sm font-bold'>Mês | Ano selecionado: </p>
                        {/* <p className='text-titleBlackTextLight'>{selectedMonth?.format("MMMM")}</p>
                        <p className='text-titleBlackTextLight'>de</p>
                        <p className='text-titleBlackTextLight'>{selectedMonth?.format("YYYY")}</p> */}
                    </div>
                </div>
                {
                    !termOfUseDescription ?
                        <>
                        </>
                        :
                        <>
                            <h2 className='text-primaryDefaultLight font-bold text-base'>Termos e condiçoes</h2>
                            <div className='sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-[850px] 2xl:max-w-[950px] bg-zinc-50 rounded-lg shadow-inner border-zinc-200 overflow-auto dark:scrollbar-thin dark:scrollbar-track-zinc-700 dark:scrollbar-thumb-zinc-500 md:w-auto text-base break-words whitespace-pre-line sm:text-sm h-96 p-3 dark:bg-secondaryDefaultDark dark:text-titleGrayTextDark dark:border dark:border-thirdDefaultDark'>
                                <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96' readOnly={true} theme={"bubble"} value={termOfUseDescription} />
                            </div>
                            <p className='text-primaryDefaultLight'>Aceito o termo</p>
                            <InputCheckBox value={true}></InputCheckBox>
                        </>
                }
            </div>
    }

    function handleFee(feeValue) {
        if (feeValue > 0) {
            setFee(feeValue)
            setTotalValue((selectedValue - discount) + feeValue)
            setUseBalance(false)
            setUsedBalance(0)
        } else {
            setTotalValue(selectedValue - discount)
        }
    }

    function handleDiscount(discountValue) {
        if (discountValue > 0) {
            setDiscount(discountValue)
            setTotalValue(Number((selectedValue - discountValue) + fee))
            setUseBalance(false)
            setUsedBalance(0)
        } else {
            setTotalValue(Number(selectedValue + fee))
        }
    }

    function handleUseBalance() {
        setUseBalance(!useBalance)

        if (selectedUserToSchedule.balance > 0 && !useBalance) {

            let thisTotalValue = 0
            let hasDiscount = discount > 0 ? true : false

            if (discount >= selectedValue) {
                setUseBalance(false)
                setUsedBalance(0)
                return
            }

            if ((discount < totalValue) && ((selectedUserToSchedule.balance) <= totalValue)) {
                thisTotalValue = totalValue - (selectedUserToSchedule.balance)
                setUsedBalance(selectedUserToSchedule.balance)
                setTotalValue(Number(thisTotalValue))
            }

            //desconto menor do que a reserva e desconto + saldo maior do que a reserva
            if (((selectedUserToSchedule.balance) > totalValue)) {
                let rest = totalValue
                let diference = rest - selectedUserToSchedule.balance
                setUsedBalance(selectedUserToSchedule.balance + diference)
                setTotalValue(0)
            }
        } else {
            handleDiscount(discount)
            setUsedBalance(0)
        }
    }

    function valueConfirmation() {
        return <div className='flex flex-col gap-4'>
            <div className='flex flex-col w-full border-b border-gray-400'>
                <h2 className='text-primaryDefaultLight font-bold text-base'>Valores</h2>
            </div>
            {
                userPermitted && selectedPaymentStatus.id === 1 &&
                <>
                    <div className='flex flex-row w-full gap-6 flex-wrap'>
                        <div className="flex flex-row flex-wrap gap-2">
                            <LabelInput text={'Desconto'}>
                                <CurrencyInput placeHolder={`R$`} id='discountForReservation' onChange={(e) => {
                                    if (e.target.value?.length > 0 && parseFloat(e.target.value?.replace('R$', '')?.replace('.', '')?.replace(',', '.')) <= selectedValue) {
                                        handleDiscount(parseFloat(e.target.value?.replace('R$', '')?.replace('.', '')?.replace(',', '.')))
                                    } else {
                                        let resetDiscount = discount
                                        resetDiscount = resetDiscount?.length > 0 ? parseFloat(discount?.replace('R$', '')?.replace('.', '')?.replace(',', '.')).toString() : ''
                                        document.getElementById('discountForReservation').value = resetDiscount
                                        setDiscount(resetDiscount)
                                        setTotalValue(Number(selectedValue + fee))
                                    }
                                }}></CurrencyInput>
                            </LabelInput>
                            <LabelInput text={'Acréscimo'}>
                                <CurrencyInput placeHolder={`R$`} id='fee' onChange={(e) => {
                                    if (e.target.value?.length > 0) {
                                        handleFee(parseFloat(e.target.value?.replace('R$', '')?.replace('.', '')?.replace(',', '.')))
                                    } else {
                                        let resetFee = fee
                                        resetFee = resetFee?.length > 0 ? parseFloat(discount?.replace('R$', '')?.replace('.', '')?.replace(',', '.')).toString() : ''
                                        document.getElementById('fee').value = resetFee
                                        setFee(resetFee)
                                        setTotalValue(selectedValue - discount)
                                    }
                                }}></CurrencyInput>
                            </LabelInput>
                            {
                                selectedCategorie?.contractRequired &&
                                <LabelInput text={'Contrato assinado?'}>
                                    <InputAutoComplete
                                        width={44}
                                        preSelectedValue={selectedContractSigned?.description}
                                        data={[{ id: true, description: "Sim" }, { id: false, description: "Não" }]}
                                        optionList={['description']}
                                        onChange={(e) => setSelectedContractSigned(e)}
                                        selectedLabel={'description'} />
                                </LabelInput>
                            }
                        </div>
                        {
                            userData[0]?.customersData?.useBalance &&
                            <LabelInput text={'Usar saldo'}>
                                <InputCheckBox value={useBalance} onClick={() => handleUseBalance()}></InputCheckBox>
                            </LabelInput>
                        }
                    </div>
                </>
            }
            <div className=' text-titleBlackTextLight dark:text-titleGrayTextDark text-sm sm:w-auto bg-bgPrimaryLight dark:bg-secondaryDefaultDark border border-primaryDefaultLight rounded-lg'>
                {
                    userPermitted &&
                    <div className='flex flex-row gap-2 items-center justify-start pl-2 h-10 border-b border-primaryDefaultLight'>
                        <p className='text-primaryDefaultLight font-bold'>Valor de desconto: {discount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                    </div>
                }

                {
                    userData[0]?.customersData?.useBalance &&
                    <>
                        <div className='flex flex-row gap-2 items-center justify-start pl-2 h-10 border-b border-primaryDefaultLight'>
                            <p className='text-sm text-titleBlackTextDark dark:text-titleGrayTextDark'>{`Valor de Saldo: ${selectedUserToSchedule?.balance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}</p>
                        </div>
                        <div className='flex flex-row gap-2 items-center justify-start pl-2 h-10 border-b border-primaryDefaultLight'>
                            <p className='text-primaryDefaultLight font-bold'>Valor deduzido do saldo: {usedBalance.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                        </div>
                    </>
                }
                <div className='flex flex-row gap-2 items-center justify-start pl-2 h-10 border-b border-primaryDefaultLight'>
                    <p className='text-sm'>Valor da reserva: {selectedValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                </div>
                <div className='flex flex-row gap-2 items-center justify-start pl-2 h-10 border-b border-primaryDefaultLight text-primaryDefaultLight font-semibold'>
                    <p className='text-lg'>Total a pagar: {totalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                </div>
            </div>
        </div>
    }

    async function handleScheduleUser(e) {

        setLoading(true)
        setSelectedCategorie(``)
        setSelectedService(``)
        setSelectedDay(``)
        setSelectedHours([])

        //chama api para trazer o saldo
        const balance = await defaultCallsSchedules.getSelectedUserBalance(userData[0]?.token, e.id)
        const isBlockedUser = await defaultCallsAuth.getBlockedUsersByUserId(userData[0]?.token, e.id)

        setSelectedUserToSchedule({
            ...e,
            balance: balance.data[0]?.balance > 0 ? balance.data[0]?.balance : 0
        })

        setLoading(false)
        setShowUniversalModal(false)
        //verifica se usuário possui bloqueios
        isBlockedUser.data?.find(blocked => {
            if (blocked.schedules && moment(blocked.expires_in).isAfter(moment()) && blocked.users_id === e.id) {
                setShowModalType('blockedUser')
                setShowUniversalModal(true)
            }
        })
    }

    function calculateWeekdaysOfRecurrence(startDay, finishDay, targetWeekday) {
        const start = moment(startDay).clone()
        const end = moment(finishDay).clone()
        let weekdaysCount = 0
    
        // Iterar sobre as datas entre o início e o fim
        while (start.isSameOrBefore(end)) {
            if(targetWeekday === start.weekday()){
                weekdaysCount++
            }
            start.add(1, 'days')
        }
    
        return weekdaysCount
    }

    function validAndSetRecurrenceFinishDate(date){
        setRecurrenceFinishDate(date)
        if(moment(date).isBefore(moment(selectedDay))){
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Data de fim da recorrência menor que a data de inicio.')
        }
        setShowNotificationModal(false)

        const quantityDays = calculateWeekdaysOfRecurrence(selectedDay, date, selectedWeekday)
        setRecurrenceQuantityOfDays(quantityDays)
        const values = selectedHours.reduce((acumulator, currentValue) => acumulator + currentValue.serviceValue, 0)
        setSelectedValue(values * (isRecurrent ? quantityDays : 1))
        setTotalValue(values * (isRecurrent ? quantityDays : 1))
        
    }

    const isRecurrentOptions = [
        {id: true, description: 'Recorrente'}, 
        {id: false, description: 'Avulsa'}
    ]

    const weekday = [ 
        'Todo domingo',
        'Toda segunda-feira',
        'Toda terça-feira',
        'Toda quarta-feira',
        'Toda quinta-feira',
        'Toda sexta-feira',
        'Todo Sábado',
    ]

    return <>
        <ScreenModal>
            {
                showModalType == 'usersToFind' ?
                    <UsersToFind handleSelect={(e) => handleScheduleUser(e)} />
                    :
                    showModalType == 'blockedUser' &&
                    <div className="p-3 flex flex-col items-center gap-2">
                        <h1 className="text-red-600 font-semibold">Atenção!</h1>
                        <a>este usuário está bloqueado, verifique na listagem de bloqueios!</a>
                        <Button approval shadow onClick={()=> setShowUniversalModal(false)}>Prosseguir</Button>
                    </div>
            }
        </ScreenModal>
        <Container>
            <div className='w-full flex'>
                <TitlePage>
                    <div className='flex flex-row justify-between w-full items-center'>
                        <Title text={activeStep.id === 0 ? 'Reserva' : 'Pagamento'} />
                    </div>
                </TitlePage>
            </div>
            <Body hasFooter={true} padding={false}>
                {
                    loading ? // caso esteja carregando
                        <div className='flex flex-col items-center justify-center h-96'>
                            <DefaultLoader />
                        </div>
                        :
                        categories?.length === 0 ? // caso não tenha categorias de serviço cadastrado
                            <div className='flex flex-col items-center justify-center py-8'>
                                <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>
                                    Cliente não possui categorias/serviços ativos cadastrados
                                </p>
                                <p onClick={() => navigate('/services')} className='cursor-pointer hover:brightness-75 text-primaryDefaultLight underline text-base'>Configurar</p>
                            </div>
                            :
                            dontHavePaymentMethods ? // caso não tenha metodos de pagamento configurado
                                <div className='flex flex-col items-center justify-center py-8'>
                                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>
                                        Cliente não possui métodos de pagamento configurados
                                    </p>
                                    <p onClick={() => navigate('/paymentMethods')} className='cursor-pointer hover:brightness-75 text-primaryDefaultLight underline text-base'>Configurar</p>
                                </div>
                                : // Fluxo após tudo configurado
                                <div className='flex flex-col items-center'>
                                    <div className='w-[89%] max-w-[1950px] bg-bgPrimaryLight dark:bg-secondaryDefaultDark flex flex-col items-center h-16'>
                                        <div className='w-full lg:max-w-[1200px] 2xl:max-w-[70%] flex flex-col items-center justify-center dark:bg-secondaryDefaultDark h-16 px-4'>
                                            <Stepper steps={filteredSteps} activeStep={activeStep} changeStep={(e) => setActiveStep(e)} />
                                        </div>
                                    </div>
                                    {
                                        activeStep.id === 0 && // ETAPA 1: RESERVA
                                        <div className='select-none flex flex-col gap-2 items-center justify-center mb-12 mt-2 w-full'>
                                            {
                                                showSpaceList === 3 &&
                                                <div className='flex md:hidden top-0 left-0 z-30 opacity-75 absolute w-[100vw] h-[100vh]  bg-zinc-400'></div>
                                            }
                                            <div className='flex flex-col mt-8 gap-4 w-full'>
                                                {
                                                    userData[0]?.typeAccess_id < 4 &&
                                                    <div className='flex flex-row gap-2 w-full justify-center items-center'>
                                                        <a className='text-sm font-bold dark:text-titleGrayTextDark text-titleBlackTextLight'>Usuário:</a>
                                                        <div className='flex flex-row gap-2 items-start justify-start'>
                                                            {
                                                                selectedUserToSchedule !== '' &&
                                                                <>
                                                                    <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>{selectedUserToSchedule?.firstName}</a>
                                                                    <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>-</a>
                                                                    <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>{selectedUserToSchedule?.associationsName}</a>
                                                                </>
                                                            }
                                                            {
                                                                selectedUserToSchedule !== '' ?
                                                                    <p className='cursor-pointer text-sm underline text-primaryDefaultLight' onClick={() => { setShowModalType('usersToFind'); setShowUniversalModal(true) }}>Alterar</p>
                                                                    :
                                                                    <p className='cursor-pointer text-sm underline text-primaryDefaultLight' onClick={() => { setShowModalType('usersToFind'); setShowUniversalModal(true) }}>Selecionar</p>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    [1, 2, 3].includes(userData[0]?.typeAccess_id) && selectedUserToSchedule &&
                                                        <div className={`relative md:shadow-lg md:shadow-zinc-300 dark:md:shadow-zinc-900 md:border md:border-zinc-200 dark:md:border-thirdDefaultDark md:max-w-[700px] lg:max-w-[1000px] xl:max-w-[1200px] 2xl:max-w-[1300px] gap-4 md:gap-0 rounded-lg md:h-12 flex flex-col md:flex-row items-center justify-start pl-4 rounded-r-lg`}>
                                                            <div ref={spaceCategorieRef} id='spaceOne' onClick={() => { showSpaceList !== 4 && setMyState(4) }} className='relative  h-14 md:h-auto border border-zinc-200 dark:border-primaryDefaultLight md:border-none dark:text-titleGrayTextDark hover:text-primaryDefaultLight transition-all duration-200 cursor-pointer px-6 flex flex-row justify-center md:justify-between items-center w-[325px] md:border-r md:border-x-secondaryBorderDark'>
                                                                <div className='flex flex-row justify-between items-center w-full'>
                                                                    <div className='flex flex-row gap-2 items-center justify-center font-semibold'>
                                                                        <FaListUl className='text-2xl text-primaryDefaultLight' />
                                                                        {
                                                                            selectedCategorie?.length === 0 ?
                                                                                <a className='text-sm'>Selecione o tipo</a>
                                                                                :
                                                                                <a className='text-sm'>{selectedCategorie?.name}</a>
                                                                        }
                                                                    </div>
                                                                    <FaAngleDown className={`text-base ${showSpaceList === 4 ? 'text-primaryDefaultLight' : 'text-titleBlackTextLight'}`} />
                                                                </div>
                                                                <div className={`${showSpaceList === 4 ? 'flex' : 'hidden'} w-full lg:w-[200px] 2xl:w-[250px] dark:bg-secondaryDefaultDark bg-bgPrimaryLight translate-y-2 md:translate-y-1 roundedb-lg top-12 md:top-8 absolute z-30`}>
                                                                    <div className={`${animateSpace.four ? 'opacity-1' : 'opacity-0'} duration-200 md:dark:border-b border dark:border-primaryDefaultDark border-zinc-200 transition-all flex flex-col w-full max-h-64 overflow-y-auto dark:bg-primaryDefaultDark dark:shadow-lg dark:shadow-zinc-900 bg-bgPrimaryLight shadow-lg`}>
                                                                        {
                                                                            !selectedUserToSchedule ?
                                                                                <div className='p-4 text-base dark:hover:bg-secondaryDefaultDark hover:bg-bgSecondaryLight w-full'>
                                                                                    <a className='text-sm'>Selecione o usuário primeiro</a>
                                                                                </div>
                                                                                :
                                                                                categories.map((categorie) => {
                                                                                    return <div className='p-4 text-base dark:hover:bg-secondaryDefaultDark hover:bg-bgSecondaryLight w-full cursor-pointer ' 
                                                                                    onClick={() => { 
                                                                                        selectCategorie(categorie); 
                                                                                        setSelectedValue(0); 
                                                                                        setSelectedHours([]); 
                                                                                        setSelectedDay('') 
                                                                                    }}>
                                                                                        <a className='text-sm'>{categorie.name}</a>
                                                                                    </div>
                                                                                })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div ref={spaceOneRef} id='spaceOne' onClick={() => setMyState(1)} className='relative  h-14 md:h-auto border border-zinc-200 dark:border-primaryDefaultLight md:border-none dark:text-titleGrayTextDark hover:text-primaryDefaultLight transition-all duration-200 cursor-pointer px-6 flex flex-row justify-center md:justify-between items-center w-[325px] md:border-r md:border-x-secondaryBorderDark'>
                                                                <div className='flex flex-row justify-between items-center w-full'>
                                                                    <div className='flex flex-row gap-2 items-center justify-center font-semibold whitespace-nowrap truncate overflow-hidden'>
                                                                        <FaMapMarkerAlt className='text-2xl text-primaryDefaultLight' />
                                                                        {
                                                                            selectedService?.length === 0 ?
                                                                                <a className='text-sm'>Selecione o local</a>
                                                                                :
                                                                                <a className='text-sm'>{selectedService?.name}</a>
                                                                        }
                                                                    </div>
                                                                    <FaAngleDown className={`text-base ${showSpaceList === 1 ? 'text-primaryDefaultLight' : 'text-titleBlackTextLight'}`} />
                                                                </div>
                                                                <div className={`${showSpaceList === 1 ? 'flex' : 'hidden'} w-full lg:w-[200px] 2xl:w-[250px] dark:bg-secondaryDefaultDark bg-bgPrimaryLight translate-y-2 md:translate-y-1 roundedb-lg top-12 md:top-8 absolute z-30`}>
                                                                    <div className={`${animateSpace.one ? 'opacity-1' : 'opacity-0'} duration-200 md:dark:border-b border dark:border-primaryDefaultDark border-zinc-200 transition-all flex flex-col w-full max-h-64 overflow-y-auto dark:scrollbar-thin dark:scrollbar-track-zinc-900 dark:scrollbar-thumb-zinc-700 dark:bg-primaryDefaultDark dark:shadow-lg dark:shadow-zinc-900 bg-bgPrimaryLight shadow-lg`}>
                                                                        {
                                                                            filteredServices?.length > 0 ?
                                                                                filteredServices.map((service) => {
                                                                                    return (
                                                                                        <div 
                                                                                            className='p-4 text-base dark:hover:bg-secondaryDefaultDark hover:bg-bgSecondaryLight w-full cursor-pointer ' 
                                                                                            onClick={() => { 
                                                                                                selectService(service); 
                                                                                                setSelectedValue(0); 
                                                                                                setSelectedHours([]); 
                                                                                                setSelectedDay('')
                                                                                            }}
                                                                                        >
                                                                                            <a className='text-sm'>{service.name}</a>
                                                                                        </div>
                                                                                    )

                                                                                })
                                                                                :
                                                                                <div className='p-4 w-full cursor-not-allowed '>
                                                                                    <a className='text-sm'>Selecione um tipo</a>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div ref={spaceTwoRef} id='spaceTwo' onClick={() => setMyState(2)} className='dark:text-titleGrayTextDark relative h-14 md:h-auto border border-zinc-200 dark:border-primaryDefaultLight md:border-none hover:text-primaryDefaultLight transition-all duration-200 cursor-pointer px-6 flex flex-row justify-center md:justify-between items-center w-[325px] md:border-r md:border-x-secondaryBorderDark'>
                                                                <div className='flex flex-row items-center justify-between w-full'>
                                                                    <div className='flex flex-row gap-2 items-center justify-center font-semibold'>
                                                                        <FaCalendar className='text-2xl text-primaryDefaultLight' />
                                                                        {!selectedDay ?
                                                                            <a className='text-sm'>Selecione o dia</a>
                                                                            :
                                                                            selectedDay.format("DD/MM/YYYY")
                                                                        }
                                                                    </div>
                                                                    <FaAngleDown className={`text-base ${showSpaceList === 2 ? 'text-primaryDefaultLight' : 'text-titleBlackTextLight'}`} />
                                                                </div>
                                                                <div className={`${showSpaceList === 2 ? 'flex' : 'hidden'} w-full lg:w-[200px] 2xl:w-[250px] translate-y-4 md:translate-y-1 top-10 md:top-8 z-30 absolute shadow-lg`}>
                                                                    <div className='flex flex-col w-full cursor-default '>
                                                                        {
                                                                            selectedService?.length !== 0 ?

                                                                                selectedService.reservationType === 'H' &&
                                                                                <CalendarMain externalSelectedDay={selectedDay} onClick={(day) => getServiceHoursFree(day)}></CalendarMain>
                                                                                :
                                                                                <div className='bg-bgPrimaryLight py-4 flex flex-col items-center justify-center'>
                                                                                    <a className='text-sm'>Selecione o local primeiro</a>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div ref={spaceTreeRef} id='spaceTwo' onClick={() => setMyState(3)} className='dark:text-titleGrayTextDark h-14 md:h-auto border border-zinc-200 md:border-none 
                                                            hover:text-primaryDefaultLight transition-all duration-200 cursor-pointer 
                                                            px-6 flex flex-row justify-center md:justify-between items-center w-[325px] '>
                                                                <div className='flex flex-row justify-between items-start w-full'>
                                                                    <div className='flex flex-row gap-2 items-start justify-between font-semibold'>
                                                                        <FaClock className='text-2xl text-primaryDefaultLight' />
                                                                        {
                                                                            selectedHours?.length === 0 ?
                                                                                <a className='text-sm'>Selecione o horário</a>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        selectedHours?.length > 1 ?
                                                                                            <a className='text-sm'>+ de 1 horário</a>
                                                                                            :
                                                                                            <>
                                                                                                <a className='text-sm'>{formatHours(selectedHours[0].startHour)}</a>
                                                                                                <a className='text-sm'>as</a>
                                                                                                <a className='text-sm'>{formatHours(selectedHours[0].finishHour)}</a>
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </div>
                                                                    <FaAngleDown className={`text-base ${showSpaceList === 3 ? 'text-primaryDefaultLight' : 'text-titleBlackTextLight'}`} />
                                                                </div>
                                                                <div className={`${showSpaceList === 3 ? 'flex' : 'hidden'} items-center flex-row border 
                                                                gap-4 p-2 translate-y-2 -translate-x-2 md:translate-y-4 md:right-0 
                                                                animate-[wiggle_.2s_ease-in-out]
                                                                bottom-20 w-full md:w-auto md:inset-auto md:top-8 fixed md:absolute z-30 dark:bg-primaryDefaultDark dark:shadow-lg dark:shadow-zinc-900 
                                                                bg-bgPrimaryLight shadow-lg`}>

                                                                    <div className='w-full flex flex-row items-start justify-center gap-4 scrollbar-none sm:scrollbar'>
                                                                        <div className='flex flex-col items-center justify-center'>
                                                                            <div className='flex md:hidden flex-row items-center justify-between fixed top-0 border-b px-4 py-1 border-zinc-100 bg-white w-full'>
                                                                                <a className='text-sm opacity-0'>+ de 1 horário</a>
                                                                                {
                                                                                    selectedHours?.length > 0 ?
                                                                                        selectedHours?.length > 1 ?
                                                                                            <a className='text-sm'>+ de 1 horário</a>
                                                                                            :
                                                                                            <div className='flex gap-1'>
                                                                                                <a className='text-sm'>{formatHours(selectedHours[0]?.startHour)}</a>
                                                                                                <a className='text-sm'>as</a>
                                                                                                <a className='text-sm'>{formatHours(selectedHours[0]?.finishHour)}</a>
                                                                                            </div>
                                                                                        :
                                                                                        <a className='text-sm opacity-0'>+ de 1 horário</a>
                                                                                }
                                                                                <div className='flex items-center justify-center'>
                                                                                    <button onClick={() => closeSpace()} className={`z-50 bg-white shadow-md rounded-full flex flex-col items-center justify-center w-12 h-12 text-titleBlackTextLight border border-zinc-100 p-2 ${showSpaceList === 3 ? 'flex' : 'hidden'} md:hidden `}>x</button>
                                                                                </div>

                                                                            </div>
                                                                            <div className='w-full flex gap-4 '>
                                                                                <div className='w-[50%] mt-16 sm:mt-0 md:w-auto flex flex-col'>
                                                                                    <div className='flex flex-col gap-1'>
                                                                                        <div className='flex flex-col items-center justify-center py-4'>
                                                                                            <a className='text-sm text-primaryDefaultLight'>Horários disponíveis</a>
                                                                                        </div>
                                                                                        <div className='flex flex-row flex-wrap items-center 
                                                                                justify-center md:grid md:grid-cols-2 gap-1 overflow-y-auto max-h-[40vh]'>
                                                                                            {
                                                                                                filterdHours?.length > 0 &&
                                                                                                filterdHours.map((hour) => {
                                                                                                    if (!hour.isDrawn && hour.blockedDescription?.length > 0) {
                                                                                                        return <Tippy content={`BLOQUEADO: ${hour?.blockedDescription}`}
                                                                                                            arrow={true}
                                                                                                            animation='shift-away'
                                                                                                            placement='top'
                                                                                                            delay={0}>
                                                                                                            <div onClick={() => { !hour.isBlocked && handleSelectedHour(hour) }} className={`${selectedHours.map(selHour => selHour.id).includes(hour.id) ? 'border border-primaryDefaultLight bg-primaryDefaultLight text-bgPrimaryLight' : hour.isBlocked ? `bg-zinc-100 text-zinc-300 border border-zinc-100 hover:bg-zinc-100 cursor-not-allowed hover:text-zinc-200` : hour.blockedDescription?.length ? `text-red-200 border border-red-200` : `border border-primaryDefaultLight md:hover:bg-secondaryDefaultLight hover:text-bgPrimaryLight `}  flex flex-row items-center justify-center gap-1 rounded text-xs font-light cursor-pointer border-b border-zinc-200 p-6 md:p-1 md:w-24`}>
                                                                                                                <p>{formatHours(hour.startHour)}</p>
                                                                                                                <p>às</p>
                                                                                                                <p>{formatHours(hour.finishHour)}</p>
                                                                                                            </div>
                                                                                                        </Tippy>
                                                                                                    } else if (!hour.isDrawn) {
                                                                                                        return <div onClick={() => { !hour.isBlocked && handleSelectedHour(hour) }} className={`${selectedHours.map(selHour => selHour.id).includes(hour.id) ? 'border border-primaryDefaultLight bg-primaryDefaultLight text-bgPrimaryLight' : hour.isBlocked ? `bg-zinc-100 text-zinc-300 border border-zinc-100 hover:bg-zinc-100 cursor-not-allowed hover:text-zinc-200` : hour.blockedDescription?.length ? `text-red-200 border border-red-200` : `border border-primaryDefaultLight md:hover:bg-secondaryDefaultLight hover:text-bgPrimaryLight `} flex flex-row items-center justify-center gap-1 rounded text-xs font-light cursor-pointer border-b border-zinc-200 p-6 md:p-1 md:w-24`}>
                                                                                                            <p>{formatHours(hour.startHour)}</p>
                                                                                                            <p>às</p>
                                                                                                            <p>{formatHours(hour.finishHour)}</p>
                                                                                                        </div>
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    filterdHours?.length > 0 && filterdHours?.filter(hour => hour.isDrawn).length > 0 &&
                                                                                    <div className='mt-16 sm:mt-0 w-[50%] md:w-auto flex flex-col '>
                                                                                        <div className='flex flex-col gap-1 overflow-y-auto'>
                                                                                            <div className='flex flex-col items-center justify-center py-4'>
                                                                                                <a className='text-sm text-primaryDefaultLight'>Horários para sorteio</a>
                                                                                            </div>
                                                                                            <div className='flex flex-row flex-wrap items-center justify-center md:grid md:grid-cols-2 gap-1'>
                                                                                                {
                                                                                                    filterdHours?.length > 0 &&
                                                                                                    filterdHours.map((hour) => {
                                                                                                        if (hour.isDrawn && hour.blockedDescription?.length > 0 && !hour.finishedDraw) {
                                                                                                            return <Tippy content={hour?.blockedDescription}
                                                                                                                arrow={true}
                                                                                                                animation='shift-away'
                                                                                                                placement='top'
                                                                                                                delay={100}>
                                                                                                                <div onClick={() => { !hour.isBlocked && handleSelectedHour(hour) }} className={`${selectedHours.map(selHour => selHour.id).includes(hour.id) ? 'border border-primaryDefaultLight bg-primaryDefaultLight text-bgPrimaryLight' : hour.isBlocked ? `bg-zinc-100 text-zinc-300 border border-zinc-100 hover:bg-zinc-100 cursor-not-allowed hover:text-zinc-200` : `border border-primaryDefaultLight md:hover:bg-secondaryDefaultLight hover:text-bgPrimaryLight `} p-1 flex flex-row items-center justify-center gap-1 rounded text-xs font-light cursor-pointer border-b border-zinc-200 p-6 md:p-1 md:w-24`}>
                                                                                                                    <p>{formatHours(hour.startHour)}</p>
                                                                                                                    <p>às</p>
                                                                                                                    <p>{formatHours(hour.finishHour)}</p>
                                                                                                                </div>
                                                                                                            </Tippy>
                                                                                                        } else if (hour.isDrawn && !hour.finishedDraw) {
                                                                                                            return <div onClick={() => { !hour.isBlocked && handleSelectedHour(hour) }} className={`${selectedHours.map(selHour => selHour.id).includes(hour.id) ? 'border border-primaryDefaultLight bg-primaryDefaultLight text-bgPrimaryLight' : hour.isBlocked ? `bg-zinc-100 text-zinc-300 border border-zinc-100 hover:bg-zinc-100 cursor-not-allowed hover:text-zinc-200` : `border border-primaryDefaultLight md:hover:bg-secondaryDefaultLight hover:text-bgPrimaryLight `} p-1 flex flex-row items-center justify-center gap-1 rounded text-xs font-light cursor-pointer border-b border-zinc-200 p-6 md:p-1 md:w-24`}>
                                                                                                                <p>{formatHours(hour.startHour)}</p>
                                                                                                                <p>as</p>
                                                                                                                <p>{formatHours(hour.finishHour)}</p>
                                                                                                            </div>
                                                                                                        } else if (hour.isDrawn && hour.finishedDraw) {
                                                                                                            return <Tippy content={'Sorteio encerrado'}
                                                                                                                arrow={true}
                                                                                                                animation='shift-away'
                                                                                                                placement='top'
                                                                                                                delay={100}>
                                                                                                                <div onClick={() => { handleSelectedHour(hour) }} className={`${selectedHours.map(selHour => selHour.id).includes(hour.id) ? 'border border-primaryDefaultLight bg-primaryDefaultLight text-bgPrimaryLight' : `border border-primaryDefaultLight md:hover:bg-secondaryDefaultLight hover:text-bgPrimaryLight `} p-1 flex flex-row items-center justify-center gap-1 rounded text-xs font-light cursor-pointer border-b border-zinc-200 p-6 md:p-1 md:w-24`}>
                                                                                                                    <p>{formatHours(hour?.startHour)}</p>
                                                                                                                    <p>as</p>
                                                                                                                    <p>{formatHours(hour?.finishHour)}</p>
                                                                                                                </div>
                                                                                                            </Tippy>
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            <div className='mt-4 w-full flex flex-col sm:flex-row gap-8 justify-center'>
                                                {
                                                    selectedService?.length === 0 ?
                                                        <div className='flex flex-col items-center justify-center w-full'>
                                                            <a className='text-sm ext-titleBlackTextLight dark:text-titleGrayTextDark'>Nenhum local selecionado</a>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className='flex flex-col items-center md:items-start gap-4 justify-center md:justify-start'>
                                                                <div className=' w-full flex flex-row items-center justify-center md:justify-start'>
                                                                    <a className='text-primaryDefaultLight text-base'>Fotos do local</a>
                                                                </div>
                                                                {
                                                                    servicePhotos.length > 0
                                                                        ? <SwiperContainer imgs={servicePhotos}></SwiperContainer>
                                                                        : <p className='w-[300px] h-[350px] text-titleBlackTextLight'>Nenhuma imagem foi encontrada</p>
                                                                }
                                                            </div>
                                                            <div className='flex md:hidden flex-col w-full mt-6 md:mt-0  items-center'>
                                                                <h1 className='text-base text-primaryDefaultLight text-bold'>{selectedService?.name}</h1>
                                                                <textarea disabled={true} minLength='600px' wrap='hard' className='w-full max-w-[1000px] md:w-auto text-base sm:text-sm 2xl:text-base h-96 p-3 dark:bg-secondaryDefaultDark dark:text-titleGrayTextDark dark:border dark:border-thirdDefaultDark'>{selectedService?.description}</textarea>
                                                            </div>
                                                            <div className='hidden md:flex flex-col w-full gap-4 md:mt-0'>
                                                                <h1 className='text-base text-primaryDefaultLight text-bold'>{selectedService?.name}</h1>
                                                                <div className='sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-[600px] 2xl:max-w-[700px] bg-zinc-50 rounded-lg shadow-inner border-zinc-200 overflow-auto dark:scrollbar-thin dark:scrollbar-track-zinc-700 dark:scrollbar-thumb-zinc-500 md:w-auto text-base break-words whitespace-pre-line sm:text-sm h-96 p-3 dark:bg-secondaryDefaultDark dark:text-titleGrayTextDark dark:border dark:border-thirdDefaultDark'>
                                                                    {selectedService.description}
                                                                </div>
                                                                {/* <textarea disabled={true} minLength='600px' wrap='hard' className='w-full md:w-auto text-base sm:text-sm 2xl:text-base h-96 p-3 dark:bg-secondaryDefaultDark dark:text-titleGrayTextDark dark:border dark:border-thirdDefaultDark'>{selectedService?.description}</textarea> */}
                                                            </div>
                                                            <div className='hidden md:flex flex-col gap-4 items-start justify-start'>
                                                                <h1 className='text-base text-primaryDefaultLight text-bold'>Valores</h1>
                                                                {
                                                                    userData[0]?.customersData?.useBalance &&
                                                                    <div className='bg-bgPrimaryLight dark:bg-secondaryDefaultDark py-2 border-2 rounded-lg w-[200px] border-primaryDefaultLight flex flex-col items-center justify-center' >
                                                                        <p className='text-primaryDefaultLight text-base'><strong>Seu saldo:</strong></p>
                                                                        <p className='text-base dark:text-titleGrayTextDark'>
                                                                            {`${selectedUserToSchedule?.balance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}
                                                                        </p>
                                                                    </div>
                                                                }
                                                                <div className={`transition-all duration-300 dark:bg-secondaryDefaultDark py-2 border-2 rounded-lg w-[200px] text-center border-primaryDefaultLight flex flex-col items-center justify-center`}>
                                                                    <p className='text-base text-primaryDefaultLight'><strong>Valor da reserva:</strong></p>
                                                                    <p className='text-base dark:text-titleGrayTextDark'>
                                                                        {selectedValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}   
                                                                    </p>
                                                                </div>
                                                                <div className='flex flex-col gap-2 items-start justify-start'>
                                                                    {
                                                                        selectedService.modalities?.length > 0 &&
                                                                        <>
                                                                            <a className='text-base text-primaryDefaultLight'>Selecione a modalidade</a>
                                                                            {
                                                                                selectedService.modalities?.map(modalite => {
                                                                                    return <div className='flex flex-row gap-2 items-start justify-start'>
                                                                                        <InputCheckBox value={selectedModalite === modalite} onClick={() => setSelectedModalite(modalite)} />
                                                                                        <a>{modalite}</a>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                                <div className='flex flex-col gap-2'>
                                                                    {
                                                                        selectedHours.length > 0 && !selectedHours.find(h => h.isDrawn == true) &&
                                                                        <>
                                                                            <LabelInput text={'Tipo'}>
                                                                                <InputAutoComplete
                                                                                    data={isRecurrentOptions}
                                                                                    selectedLabel={'description'}
                                                                                    optionList={['description']}
                                                                                    width={48}
                                                                                    preSelectedValue={isRecurrentOptions.find(r => r.id == isRecurrent)?.description}
                                                                                    onChange={(e) => { setIsRecurrent(e.id) }}
                                                                                >
                                                                                </InputAutoComplete>
                                                                            </LabelInput>
                                                                            {
                                                                                isRecurrent &&
                                                                                <>
                                                                                    <p className='dark:text-titleGrayTextDark text-sm'>
                                                                                        {selectedDay ? weekday[moment(selectedDay).weekday()] : 'Toda ...'}:<br/>
                                                                                        {selectedHours.length > 0 && selectedHours.map((hour, index) => {
                                                                                            return <>
                                                                                                - {formatHours(hour.startHour)} as {formatHours(hour.finishHour)}; {index+1 < selectedHours.length && <br/>}
                                                                                            </>
                                                                                        })}
                                                                                    </p>
                                                                                    <LabelInput text={'Até o dia:'}>
                                                                                        <Input type={'date'} width={48} value={recurrenceFinishDate} onChange={e => validAndSetRecurrenceFinishDate(e.target.value)}/>
                                                                                    </LabelInput>
                                                                                    <p className='dark:text-titleGrayTextDark text-sm'>Qtd. de dias: {recurrenceQuantityOfDays}</p>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='mt-6 flex md:hidden flex-col gap-4 items-center justify-center'>
                                                                {
                                                                    userData[0]?.customersData.useBalance &&
                                                                    <div className='bg-bgPrimaryLight dark:bg-secondaryDefaultDark py-4 border-2 rounded-lg w-72 border-primaryDefaultLight flex flex-col items-center justify-center' >
                                                                        <p className='text-primaryDefaultLight text-base'><strong>Seu saldo:</strong> {selectedUserToSchedule?.balance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                                    </div>
                                                                }
                                                                <div className={`${selectedValue > 0 ? 'bg-primaryDefaultLight text-bgPrimaryLight' : 'bg-primaryLight text-primaryDefaultLight'} transition-all duration-300  dark:bg-secondaryDefaultDark py-4 border-2 rounded-lg w-72 border-primaryDefaultLight flex flex-col items-center justify-center`}>
                                                                    <p className='text-base'><strong>Valor da reserva:</strong>{selectedValue > 0 ? ` ${selectedValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}` : ' -'}</p>
                                                                </div>
                                                                <div className='flex flex-col gap-2 items-start justify-start'>
                                                                    {
                                                                        selectedService.modalities?.length > 0 &&
                                                                        <>
                                                                            <a className='text-base text-primaryDefaultLight'>Selecione a modalidade</a>
                                                                            {
                                                                                selectedService.modalities?.map(modalite => {
                                                                                    return <div className='flex flex-row gap-2 items-start justify-start'>
                                                                                        <InputCheckBox value={selectedModalite === modalite} onClick={() => setSelectedModalite(modalite)} />
                                                                                        <a>{modalite}</a>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        activeStep.id === 1 && // ETAPA 2: PAGAMENTO
                                        <div className='xl:w-[1000px] px-2 flex flex-col items-center justify-center sm:items-center sm:flex-row flex-wrap mt-12  max-w-[1000px] mb-12 gap-4'>
                                            <div className='w-full flex-col flex gap-4'>
                                                {confirmationContainer()}
                                                <div className='flex flex-col  sm:flex-row gap-4 mt-3 mb-6 rounded-lg'>
                                                    <LabelInput text={'Status do pagamento'}>
                                                        <InputAutoComplete
                                                            data={statusPaymentOptions}
                                                            selectedLabel={'description'}
                                                            optionList={['description']}
                                                            width={72}
                                                            preSelectedValue={selectedPaymentStatus?.description?.length > 0 ? selectedPaymentStatus.description : ''}
                                                            onChange={(e) => { setSelectedPaymentStatus(e) }}
                                                        >
                                                        </InputAutoComplete>
                                                    </LabelInput>
                                                    {
                                                        isRecurrent &&
                                                        <LabelInput text={'Data para pagamento (recorrência)'}>
                                                            <Input
                                                                width={72}
                                                                type={'date'}
                                                                value={recurrencePaymentDueDate}
                                                                onChange={(e) => { setRecurrencePaymentDueDate(e.target.value) }}
                                                            />
                                                        </LabelInput>
                                                    }
                                                    {
                                                        selectedPaymentStatus.id === 1 &&
                                                        <LabelInput text={'Forma de pagamento'}>
                                                            <InputAutoComplete
                                                                data={
                                                                    userData[0]?.customersData?.useBalance ?
                                                                        //caso cliente use saldo não poderá utilizar folha de pagamento
                                                                        paymentMethods.filter(method => method?.id !== 4)
                                                                        :
                                                                        paymentMethods
                                                                }
                                                                selectedLabel={'description'}
                                                                optionList={['description']}
                                                                width={72}
                                                                // preSelectedValue={[selectedPayment?.description?.length > 0 ? selectedPayment.description : '']}
                                                                onChange={(e) => setSelectedPayment(e)}
                                                            >
                                                            </InputAutoComplete>
                                                        </LabelInput>
                                                    }
                                                </div>
                                                {
                                                    selectedHours[0]?.isDrawn &&
                                                    <LabelInput text={'ID do sorteio'}>
                                                        <Input value={drawId} onChange={(e) => setDrawId(e.target.value)}></Input>
                                                    </LabelInput>
                                                }

                                                {valueConfirmation()}
                                                <div className='w-full mt-2 mb-4'>
                                                    <LabelInput text={'Observação'}>
                                                        <textarea wrap="hard"
                                                            onChange={(e) => {

                                                                setSelectedObservation(e.target.value)

                                                            }}
                                                            maxLength={40} className='
                                                            p-2 border border-gray-400 border-opacity-75 
                                                            h-24
                                                            w-full
                                                            text-lg sm:text-sm
                                                            outline-none transition-all duration-200 focus:shadow-borderShadow
                                                            dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark '>
                                                        </textarea>
                                                        <a className='text-xt text-inputPlaceholderLight'>{selectedObservation?.length} de 40</a>
                                                    </LabelInput>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        activeStep.id === 2 && // ETAPA 2: CONCLUIDO
                                        <div className='flex flex-col items-center sm:items-center w-full h-96 gap-4 justify-center'>
                                            <h1 className='text-primaryDefaultLight text-bold'>Agendado com sucesso!</h1>
                                            <h1 className='text-primaryDefaultLight text-center md:text-left text-base'>Protocolo: {schedulesProtocol}</h1>
                                        </div>
                                    }
                                </div>
                }
            </Body>
            <Footer>
                <div className={`flex flex-row gap-1 w-full h-96 items-center justify-between ${!showButtonSubmit && 'hidden'} `}>
                    <Button approval={false} shadow={true} onClick={() => activeStep.id > 0 & activeStep.id !== 2 ? setActiveStep((prev) => ({ id: prev.id - 1 })) : navigate('/reservation')}>Voltar</Button>
                    {
                        activeStep.id < 2 && showButtonSubmit &&
                        <Button shadow={true} onClick={() => { setShowButtonSubmit(false); handleSubmit() }}>Próximo passo</Button>
                    }
                </div>
            </Footer>
        </Container>
    </>
}