import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { useScreenSizeContext } from '../../contexts/screenSizeContext';
import { useAuth } from '../../contexts/useAuth';

export function CalendarMain({ onClick, externalSelectedDay, blockedDays, daysToOpen }) {

    const { screenX } = useScreenSizeContext()
    const { userData } = useAuth()
    const [value, setValue] = useState(moment().locale('pt-br'))
    const startDay = value.clone().startOf('month').startOf('week')
    const thisDay = value.clone().date()
    const endDay = value.clone().endOf('month').endOf('week')
    const [selectedDay, setSelectedDay] = useState(0)
    const [monthsToView, setMonthsToView] = useState([])
    const [showMonthView, setShowMonthView] = useState(false)
    const [calendar, setCalendar] = useState([])
    const [daysToCheckBlocked, setDaysToCheckBlocked] = useState(daysToOpen)

    useEffect(() => {
        if (externalSelectedDay) {
            setSelectedDay(externalSelectedDay)
            setValue(moment().locale('pt-br'))
            let startDay = value.clone().startOf('month').startOf('week')
            let endDay = value.clone().endOf('month').endOf('week')

            const day = startDay.clone().subtract(1, 'day')
            const a = []
            while (day.isBefore(endDay, "day")) {
                a.push(
                    Array(7).fill(0).map(() => day.add(1, "day").clone())
                )
            }
            setCalendar(a)
        }

    }, [])

    useEffect(()=> {
        console.log(blockedDays)
        console.log(daysToOpen)
        setDaysToCheckBlocked(daysToOpen)
    },[daysToOpen])

    useEffect(() => {

        const day = startDay.clone().subtract(1, 'day')
        const a = []
        while (day.isBefore(endDay, "day")) {
            a.push(
                Array(7).fill(0).map(() => day.add(1, "day").clone())
            )
        }
        setCalendar(a)
    }, [value])

    function listYear() {

        const monthsOfYear = []

        let controlMonth = value.clone()
        let aux = controlMonth.month()

        monthsOfYear.push(controlMonth.clone())

        while (aux < 11) {
            monthsOfYear.push(controlMonth.add(1, 'month').clone())
            aux++
        }

        controlMonth = value.clone()
        aux = controlMonth.month()

        while ((aux > 0)) {
            monthsOfYear.push(controlMonth.subtract(1, 'month').clone())
            aux--
        }

        setMonthsToView(monthsOfYear.sort(function (a, b) {
            return a.clone().month() < b.clone().month() ? -1 : a.clone().month() > b.clone().month() ? 1 : 0
        }))
    }

    function selectMonth(month) {

        if (month.clone().month() < value.clone().month()) {
            setValue(value.clone().subtract((value.clone().month() - month.clone().month()), 'month'))
            setShowMonthView(false)
            return value.clone().subtract((value.clone().month() - month.clone().month()), 'month')
        } else {
            setValue(value.clone().add(month.clone().month() - value.clone().month(), 'month'))
            setShowMonthView(false)
            return value.clone().add(month.clone().month() - value.clone().month(), 'month')
        }
    }

    function currentMonth() {
        return value.format("MMMM")
    }

    function currentYear() {
        return value.format("YYYY")
    }

    function nextMonth() {

        return value.clone().add(1, "month")
    }

    function prevMonth() {

        return value.clone().subtract(1, "month")
    }

    function nextYear() {

        return value.clone().add(1, "year")
    }

    function prevYear() {

        return value.clone().subtract(1, "year")
    }

    async function animateLeft() {

        const monthCalendar = document.getElementById('mainCalendar')

        monthCalendar.style.transition = '0.120s'
        monthCalendar.style.marginLeft = '-100px'
        monthCalendar.style.opacity = '0'

        await new Promise(resolve => setTimeout(resolve, 70))

        monthCalendar.style.transition = '0s'
        monthCalendar.style.marginLeft = '100px'

        await new Promise(resolve => setTimeout(resolve, 70))

        monthCalendar.style.transition = '0.120s'
        monthCalendar.style.opacity = '1'
        monthCalendar.style.marginLeft = '0px'
    }

    async function animateRight() {

        const monthCalendar = document.getElementById('mainCalendar')

        monthCalendar.style.transition = '0.120s'
        monthCalendar.style.marginLeft = '100px'
        monthCalendar.style.opacity = '0'

        await new Promise(resolve => setTimeout(resolve, 70))

        monthCalendar.style.transition = '0s'
        monthCalendar.style.marginLeft = '-100px'

        await new Promise(resolve => setTimeout(resolve, 70))

        monthCalendar.style.transition = '0.120s'
        monthCalendar.style.opacity = '1'
        monthCalendar.style.marginLeft = '0px'
    }

    function handleSelect(day) {
        onClick(day)
        setSelectedDay(day)
    }

    function verifyPermittedDay(day) {
        if (blockedDays || daysToCheckBlocked) {
            if (blockedDays?.includes(day?.day())) {
                return true
            }
            if ((day.date() < moment().date() && day.month() === moment().month() && day.year() === moment().year())) {
                return true
            }
            if ((day.month() < moment().month() && day.year() === moment().year())) {
                return true
            }
            if (day.year() < moment().year()) {
                return true
            }

            if (daysToCheckBlocked && (moment().add(daysToCheckBlocked, `day`) < day)) {
                return true
            }
        }      
    }

    function verifyDayBefore(day){
        if (userData[0]?.typeAccess_id < 3 && day?.isBefore(moment().startOf('day'))) {
            return true
        }
    }

    return (

        <div className='border bg-bgPrimaryLight dark:bg-secondaryDefaultDark border-primaryDefaultLight boxShadow h-80 sm:h-64 select-none flex flex-col w-full items-center justify-start  overflow-hidden'>
            {
                showMonthView ?
                    <div className='text-titleGrayTextLight bg-primaryDefaultLight flex flex-row gap-4 dark:bg-primaryDefaultDark p-2 w-full items-center justify-center text-lg '>

                        {
                            screenX > 640 ?
                                <>
                                    <Tippy content={'Ano ant'}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top'
                                        delay={100}>
                                        <div className='cursor-pointer'>
                                            <FaArrowLeft onClick={() => { animateRight(); setValue(prevYear()) }} />
                                        </div>
                                    </Tippy>

                                    <div className='flex flex-col items-center justify-center w-56' onClick={() => { setShowMonthView(!showMonthView); listYear() }}>
                                        <h2 className='text-base text-titleGrayTextLight'>{`${currentYear().toUpperCase()}`}</h2>
                                    </div>
                                    <Tippy content={'Próx ano'}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top'
                                        delay={100}>
                                        <div className='cursor-pointer'>
                                            <FaArrowRight onClick={() => { animateLeft(); setValue(nextYear()) }} />
                                        </div>
                                    </Tippy>

                                </>
                                :
                                <>
                                    <div className='flex flex-col items-center justify-center w-56' onClick={() => { setShowMonthView(!showMonthView); listYear() }}>
                                        <h2 className='text-base text-titleGrayTextLight'>{`${currentYear().toUpperCase()}`}</h2>
                                    </div>
                                    <div className='cursor-pointer'>
                                        <FaArrowLeft onClick={() => { animateRight(); setValue(prevYear()) }} />
                                    </div>
                                    <div className='cursor-pointer'>
                                        <FaArrowRight onClick={() => { animateLeft(); setValue(nextYear()) }} />
                                    </div>
                                </>
                        }

                    </div>
                    :
                    <>
                        <div className='flex flex-row gap-3 p-2 w-full items-center justify-center text-lg text-titleBlackTextLight'>
                            {
                                screenX > 640 ?

                                    <div className='flex flex-row items-center justify-between w-full'>

                                        <div className='flex flex-col items-start justify-start'>
                                            <div className='p-1 rounded-lg transition-all duration-100 flex flex-col items-center justify-center' >
                                                <h2 className='text-titleBlackTextLight text-sm'>{`${currentMonth()} ${currentYear()}`}</h2>
                                            </div>
                                        </div>
                                        <div className='flex flex-row gap-2 items-center justify-center'>
                                            <Tippy content={'Mes ant'}
                                                arrow={true}
                                                animation='perspective'
                                                placement='top'
                                                delay={100}>
                                                <div onClick={() => { animateRight(); setValue(prevMonth()) }} className='cursor-pointer w-8 h-8 hover:text-titleGrayTextLight hover:bg-primaryDefaultLight transition-all duration-150 rounded-full flex flex-col items-center justify-center border border-zinc-200'>
                                                    <a className=' font-semibold'>{"<"}</a>
                                                </div>
                                            </Tippy>
                                            <Tippy content={'Próx mes'}
                                                arrow={true}
                                                animation='perspective'
                                                placement='top'
                                                delay={100}>
                                                <div onClick={() => { animateLeft(); setValue(nextMonth()) }} className='cursor-pointer w-8 h-8 hover:text-titleGrayTextLight hover:bg-primaryDefaultLight transition-all duration-150 rounded-full flex flex-col items-center justify-center border border-zinc-200'>
                                                    <a className=' font-semibold'>{">"}</a>
                                                </div>
                                            </Tippy>
                                        </div>
                                    </div>
                                    :
                                    <div className='flex flex-row justify-between w-full'>
                                        <div className='p-1 rounded-lg transition-all duration-100 flex flex-col items-center justify-center' onClick={() => { setShowMonthView(!showMonthView); listYear() }}>
                                            <h2 className='text-titleBlackTextLight text-base'>{`${currentMonth()} ${currentYear().toUpperCase()}`}</h2>
                                        </div>
                                        <div className='flex flex-row gap-2 items-center justify-center'>
                                            <div className='w-8 h-8 hover:text-titleGrayTextLight hover:bg-primaryDefaultLight transition-all duration-150 rounded-full flex flex-col items-center justify-center border border-zinc-200'>
                                                <a className='cursor-pointer font-semibold' onClick={() => { animateRight(); setValue(prevMonth()) }} >{"<"}</a>
                                            </div>
                                            <div className='w-8 h-8 hover:text-titleGrayTextLight hover:bg-primaryDefaultLight transition-all duration-150 rounded-full flex flex-col items-center justify-center border border-zinc-200'>
                                                <a className='cursor-pointer font-semibold' onClick={() => { animateLeft(); setValue(nextMonth()) }} >{">"}</a>
                                            </div>
                                        </div>
                                    </div>
                            }

                        </div>
                        <div className='flex flex-row w-full items-center justify-center'>
                            {["D", "S", "T", "Q", "Q", "S", "S"].map((dayWeek) => {
                                return <div className='dark:text-titleGrayTextLight bg-bgPrimaryLight dark:bg-primaryDefaultDark text-base text-titleBlackTextLight p-2 flex flex-col w-full justify-center items-center'><p className='text-titleBlackTextLight text-sm'>{dayWeek}</p></div>
                            })}
                        </div>
                    </>
            }

            {
                showMonthView ?
                    <div id='mainCalendar' className='grid grid-cols-3 w-full gap-2 p-1 bg-bgPrimaryLight'>
                        {
                            monthsToView.map(month => <div className='hover:bg-blue-100 rounded-lg transition-all duration-200 mb-4 cursor-pointer flex flex-col items-center justify-center' onClick={() => selectMonth(month)}>
                                <p className={`'bg-primaryDefaultLight text-titleBlackTextLight p-2 rounded-full w-8 h-8 flex flex-col items-center justify-center`}>
                                    {month.format("MMMM").toString()?.slice(0, 3)}
                                </p>

                            </div>)
                        }
                    </div>
                    :
                    <div id='mainCalendar' className='flex-col w-full relative bg-bgPrimaryLight'>
                        {
                            calendar.map(week => <div className='grid grid-cols-7'>
                                {week.map(day => <div onClick={() => { !verifyPermittedDay(day) && handleSelect(day) }} className={`duration-200 transition-all dark:bg-primaryDefaultDark w-full  dark:border-primaryBorderDark text-sm text-titleBlackTextLight dark:text-titleGrayTextDark flex flex-col items-center justify-start `}>
                                    <p className={`${verifyPermittedDay(day) ? 'opacity-20 cursor-not-allowed  dark:text-white' : verifyDayBefore(day) && selectedDay !== day ? 'opacity-20' : selectedDay ? selectedDay?.date() === day?.date() && selectedDay?.month() === day.month() && selectedDay?.year() === day.year() ? 'bg-primaryDefaultLight text-titleGrayTextLight ' : '' : ''}  hover:bg-zinc-200 hover:rounded-full cursor-pointer  p-2 rounded-full w-9 h-9 sm:w-6 sm:h-6 2xl:w-6 2xl:h-6 flex flex-col items-center justify-center `}>
                                        {day.format("D").toString()}
                                    </p>
                                </div>)}
                            </div>)
                        }
                    </div>
            }
        </div>
    )

}