import React, { useEffect, useState, useRef } from "react";
//components
import { Container } from '../../../components/container/container'
import { FaEdit, FaFilter, FaSearch, FaBan } from "react-icons/fa";
import { Body } from '../../../components/container/Body'
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
import { Button } from "../../../components/buttons/button.default";
import { TableDefault } from "../../../components/table/table.default";
import { TableMobile } from "../../../components/table/table.mobile";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import { formattTimeToShow } from "../../../services/api/callAPIsFunctions/defaultCalls.api";
import 'tippy.js/animations/shift-away.css';
import { useNavigate } from "react-router-dom";
import { AgreementsEdit } from "./agreement.edit";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function Agreements() {

    const {defaultCallsSchedules, api} = useApiContext()
    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [loading, setLoading] = useState(true)

    const [agreementsList, setAgreementsList] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const [selectedAgreement, setSelectedAgreement] = useState('')
    const [showAgreementsEdit, setShowAgreementEdit] = useState(false)
    //filters search
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showInternalFilterModal, setShowInternalFilterModal] = useState(false)
    const [paginationFilteredFields, setPaginationFilteredFields] = useState({})
    const [paginationHistFilteredFields, setPaginationHistFilteredFields] = useState({})
    const [paginationHistFilteredFieldsArr, setPaginationHistFilteredFieldsArr] = useState([])
    const [cleanFilter, setCleanFilter] = useState(false)
    const tableFilterModal = useRef()

    const tableThead = [
        {
            "name": 'Categoria',
            "original_name": 'categories'
        },
        {
            "name": 'Titulo',
            "original_name": 'title'
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        }
    ]

    const filters = [
        {
            "name": 'Titulo',
            "original_name": 'title',
            "type": 'text'
        },
        {
            "name": 'Data criação',
            "original_name": 'startDate',
            "type": 'date'
        },
        {
            "name": 'Data',
            "original_name": 'finishDate',
            "type": 'date'
        },

    ]

    async function getData(e) {

        const agreementsData = await defaultCallsSchedules.getAgreements(userData[0].token, e?.filter, e?.page, e?.direction)
        if (agreementsData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(agreementsData.data)
        }

        const agreementsCategories = await defaultCallsSchedules.getAgreementsCategories(userData[0]?.token)
        if (agreementsCategories.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(agreementsCategories.data)
        }

        setTotalPages(agreementsData.data.totalPages)
        setQuantity(agreementsData.data.quantity)
        setAgreementsList(agreementsData.data.data?.map((agreement => {
            return {
                ...agreement,
                created_at: formattTimeToShow(agreement.created_at)
            }
        })))
        setLoading(false)
    }

    useEffect(() => {
        if (!showAgreementsEdit) {
            getData()
        }
    }, [showAgreementsEdit])

    useEffect(() => {

        let modal = document.getElementById('tableFilterModal')
        if (modal) {
            modal.addEventListener('click', (e) => handleChangeModalStatus(e, ''))
            document.addEventListener('keydown', (e) => handleChangeModalStatus(e, 'keyboard'))
        }

        async function animateFilterModal(status) {

            let fields = {}
            filters.map((column, index) => {
                fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
            })
            setPaginationFilteredFields(fields)
            if (status) {
                let histFields = {}
                filters.map((column, index) => {
                    histFields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
                })
                setPaginationHistFilteredFields(fields)
                setShowFilterModal(true)
                await new Promise(resolve => setTimeout(resolve, 200))
                return setShowInternalFilterModal(true)
            }
            setShowFilterModal(false)
            setShowInternalFilterModal(false)
        }

        animateFilterModal(showFilterModal)

        if (modal) {
            return () => { modal.removeEventListener('click', (e) => handleChangeModalStatus(e)); modal.removeEventListener('keydown', (e) => handleChangeModalStatus(e)) }
        }

    }, [showFilterModal])

    function customFilter() {
        let ToFilterData = []
        let fieldsKeys = Object.keys(paginationFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationFilteredFields[key].value !== '') {
                ToFilterData.push(paginationFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
        }

        getData({ filter: { filterObject: ToFilterData }, direction: false })
        setShowFilterModal(false)
    }

    function handleChangeModalStatus(e, type) {

        if (e.code === 'Escape' && type === 'keyboard') {
            setShowFilterModal(false)
        }

        if (!tableFilterModal?.current?.contains(e.target) && type !== 'keyboard') {
            setShowFilterModal(false)
        }
    }

    function orderTableData(e) {
        const ToFilterData = []
        const fieldsKeys = Object.keys(paginationHistFilteredFields)
        fieldsKeys.map((key) => {
            if (paginationHistFilteredFields[key].value !== '') {
                ToFilterData.push(paginationHistFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
            return getData({ filter: { filterObject: ToFilterData }, direction: e?.direction, page: e.page })
        }
        getData(e)
    }

    async function handleClearFilter() {

        setCleanFilter(true)
        setLoading(true)
        setPaginationHistFilteredFieldsArr([]);
        setPaginationHistFilteredFields([])
        let fields = {}
        filters.map((column, index) => {
            fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
        })
        setPaginationFilteredFields(fields)
        getData();
        await new Promise(resolve => setTimeout(resolve, 200))
        setLoading(false)
        setCleanFilter(false)

    }


    function handleEdit(e) {
        setSelectedAgreement(e)
        setShowAgreementEdit(true)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            customFilter()
        }
    }

    return (
        <>
            {
                showAgreementsEdit ?
                    <AgreementsEdit data={selectedAgreement} setShowAgreementEdit={(e) => setShowAgreementEdit(e)} />
                    :
                    <Container>
                        <TitlePage>
                            <div className="flex flex-row w-full items-center justify-between">
                                <div className="flex flex-col gap-2 items-start justify-center">
                                     <Title text={'Convênios'} />
                                    <a className="text-sm dark:text-titleBlackTextDark">{quantity} convênios</a>
                                </div>
                                <Button module={"agreements:create"} userData={userData} onClick={() => navigate('/agreementsCreate')} shadow={true}>+ Novo</Button>
                            </div>
                         
                        </TitlePage>
                        <Body>
                            {
                                showFilterModal &&
                                <div id='tableFilterModal' className='fixed left-0 top-0 z-[500] w-full h-full flex flex-col items-center justify-start'>
                                    <div ref={tableFilterModal} className={`${showInternalFilterModal ? 'rounded-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark opacity-1 translate-x-0' : 'translate-x-[1000px] bg-transparent opacity-0'} top-28 absolute z-[501] p-6  boxShadow transition-all duration-300 flex flex-col`}>
                                        <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center mb-8'>
                                            <FaSearch />
                                            <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                                        </div>
                                        {

                                            filters.map(column => {

                                                return <form onKeyUp={(e)=> handleKeyPress(e)} className='flex flex-col sm:flex-row gap-4'>
                                                    <div className='w-28'>
                                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{column.name}</p>
                                                    </div>
                                                    {
                                                        column.type === 'text' ?
                                                            <LabelInput>
                                                                <Input value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                                    setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                                    setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                                }}></Input>
                                                            </LabelInput>
                                                            :
                                                            column.type === 'object' ?
                                                                <LabelInput>
                                                                    <InputAutoComplete data={column.data} optionList={column.optionList} selectedLabel={column.selectedLabel} onChange={(e) => {
                                                                        setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                                        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                                    }}></InputAutoComplete>
                                                                </LabelInput>
                                                                :
                                                                column.type === 'date' &&
                                                                <LabelInput>
                                                                    <Input type={`date`} value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                                        setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                                        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                                    }} />
                                                                </LabelInput>
                                                    }
                                                </form>
                                            })}
                                        <div className='flex w-full flex-col items-end justify-start mt-6'>
                                            <Button onClick={() => customFilter()} shadow={true}>Pesquisar</Button>
                                        </div>
                                    </div>
                                    <div className={`${showInternalFilterModal ? 'bg-gray-700 opacity-75' : 'bg-transparent opacity-0'} absolute h-full w-full transition-all duration-300`}>
                                    </div>
                                </div>
                            }
                            <div className="flex flex-row gap-4 w-full items-center justify-start">
                                <Tippy content={'Filtrar'}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    delay={100}>
                                    <div className={`p-1 cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150 ${paginationHistFilteredFieldsArr?.length > 0 && 'bg-gray-300 rounded-lg'}`} onClick={() => setShowFilterModal(true)}>
                                        <FaFilter />
                                    </div>
                                </Tippy>
                                {
                                    paginationHistFilteredFieldsArr?.length > 0 &&
                                    <Tippy content={'Excluir filtro'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150' onClick={() => { handleClearFilter() }}>
                                            <FaBan />
                                        </div>
                                    </Tippy>
                                }
                            </div>
                            <div id='agreementsTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                <TableDefault
                                    pagination={true}
                                    totalPages={totalPages}
                                    onClick={(e) => handleEdit(e)}
                                    title={tableThead}
                                    data={agreementsList}
                                    collumns={["categories","title", "created_at"]}
                                    loading={loading}
                                    filter={(e) => orderTableData(e)}
                                />
                            </div>
                            <div id='tablesTableContentMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                <TableMobile
                                    pagination={true}
                                    totalPages={totalPages}
                                    loading={loading}
                                    filter={(e) => orderTableData(e)}
                                    onClick={(e) => e.func.execute(e.data)}
                                    title={tableThead}
                                    data={agreementsList}
                                    collumns={["categories","title", "created_at"]}
                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                />
                            </div>
                        </Body>
                    </Container>
            }
        </>

    )
}