import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight, FaArrowCircleLeft, FaPlus, FaQuestionCircle, FaTrash } from 'react-icons/fa'
//components
import { Container } from '../../../components/container/container'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { Button } from '../../../components/buttons/button.default'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { Quillmodules } from "../../../components/richText/quill.modules";
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Toogle } from '../../../components/toogle/Toogle'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { permittedFiles } from '../../../utils/permittedFiles/permittedFiles'
//utils
import { resizePhoto } from '../../../utils/formatImages/resizeImage'
import { TableDefault } from '../../../components/table/table.default'
import { Footer } from '../../../components/footer/Footer'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import moment from 'moment'
import { UrlToImage } from '../../../utils/formatImages/urlToImgFile'
import { convertURLtoFile } from '../../../utils/formatImages/convertToUrl'
import { formattTimeToShow } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { resizeImageTest } from '../../../utils/formatImages/resizeImageTest'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function ActivitiesEdit() {

    const { userData } = useAuth()
    const { api } = useApiContext()
    const navigate = useNavigate()
    const location = useLocation()
    const activitiesId = useParams()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, showUniversalModal, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    const [showPhoto, setShowPhoto] = useState(<a>Sem dados</a>)

    const [activitiesName, setActivitiesName] = useState('')
    const [isSchool, setIsSchool] = useState(false)
    const [description, setDescription] = useState('')
    const [deltaDescription, setDeltaDescription] = useState({})
    const [termdescription, setTermDescription] = useState('')
    const [deltaTermDescription, setDeltaTermDescription] = useState({})
    const [openForSubscription, setOpenForSubscriptions] = useState(false)
    const [numberOfVacance, setNumberOfVacance] = useState(0)
    const [startDate, setStartDate] = useState('')
    const [finishDate, setFinishDate] = useState('')
    const [activitiesPictures, setActivitiesPictures] = useState([])
    const [activitiesPicturesHistory, setActivitiesPicturesHistory] = useState([])
    const [activitiesAppendPictures, setActivitiesAppendPictures] = useState([])
    const [regulationFile, setRegulationFile] = useState('')
    const [regulationFileChanged, setRegulationFileChanged] = useState('')
    const [activitiesPlans, setActivitiesPlans] = useState([])
    const [activitiesStatus, setActivitiesStatus] = useState(true)
    const [activitiesStatusHistory, setActivitiesStatusHistory] = useState(true)
    const [activitiesData, setActivitiesData] = useState(null)
    const [hyperLink, setHyperLink] = useState('')
    const [hyperLinkDescription, setHyperLinkDescription] = useState('')

    useEffect(() => {
        getData()
    }, [])


    async function getData() {
        try {

            const getActivities = await api.get(`/api/v1/activities/${activitiesId.id}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            const activitiesData = getActivities?.data?.data[0]

            setActivitiesData(getActivities?.data?.data[0])
            setActivitiesName(activitiesData?.activitiesName)
            setIsSchool(activitiesData?.isSchool)
            setActivitiesName(activitiesData?.activitiesName)
            setDescription(activitiesData?.description)
            setDeltaDescription(activitiesData?.description)
            setTermDescription(activitiesData?.termOfUse)
            setDeltaTermDescription(activitiesData?.termOfUse)
            setOpenForSubscriptions(activitiesData?.openForSubscriptions)
            setNumberOfVacance(activitiesData?.numberOfVacancies)
            setStartDate(moment(activitiesData?.startDate).format('YYYY-MM-DD'))
            setFinishDate(moment(activitiesData?.finishDate).format('YYYY-MM-DD'))
            setActivitiesPictures(activitiesData?.activitiesPictures)
            setActivitiesPicturesHistory(structuredClone(activitiesData?.activitiesPictures))
            setActivitiesPlans(activitiesData?.activitiesPlans.map(plan => { return { ...plan, created_at: formattTimeToShow(plan.created_at) } }))
            setRegulationFile(activitiesData?.regulationFile)
            setActivitiesStatus(activitiesData?.active)
            setActivitiesStatusHistory(activitiesData?.active)
            setHyperLink(activitiesData?.hyperLink)
            setHyperLinkDescription(activitiesData?.hyperLinkDescription)

            await Promise.all(activitiesData?.activitiesPictures?.map(async picture => {
                const getPicture = await api.get(`/api/v1/activitiesPictures/activities_id/${activitiesId.id}/picture/${picture.picture}`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    },
                    responseType: 'blob'
                })

                const newUrl = URL.createObjectURL(getPicture.data)

                setActivitiesAppendPictures((prev) => ([...prev, newUrl]))
            }))

            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    async function formatAPINewPhoto(pictures) {
        let photosAppendArray = []

        await Promise.all(pictures.map(async (picture) => {
            photosAppendArray.push(picture)
        }))
        setActivitiesAppendPictures(photosAppendArray)
    }

    async function handleSubmit() {

        setLoading(true)
        try {
            await api.put(`/api/v1/activities/${activitiesId.id}`, {
                activitiesName,
                isSchool,
                description: deltaDescription,
                openForSubscriptions: openForSubscription,
                numberOfVacancies: numberOfVacance,
                startDate,
                finishDate,
                hyperLink: hyperLink?.length > 0 ? hyperLink : ' ',
                hyperLinkDescription: hyperLinkDescription?.length > 0 ? hyperLinkDescription : ' ',
                termOfUse: deltaTermDescription
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            const formData = new FormData();
            if (activitiesAppendPictures?.length > 0) {
                await Promise.all(activitiesAppendPictures.map(async (photo, index) => {
                    formData.append('images', await UrlToImage(photo, activitiesPictures[index]));
                }))

                await api.post(`/api/v1/activitiesPictures/activities_id/${activitiesId.id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                })
            }
            if (activitiesPictures?.length === 0 && activitiesPicturesHistory?.length > 0) {

                await Promise.all(activitiesPicturesHistory.map(async (photo, index) => {
                    await api.delete(`/api/v1/activitiesPictures/activities_id/${activitiesId?.id}/picture/${photo.picture}`, {
                        headers: {
                            Authorization: `Bearer ${userData[0]?.token}`,
                        }
                    })
                }))
            }

            if (regulationFileChanged) {
                if (regulationFile) {
                    const formData = new FormData()
                    formData.append('regulation', regulationFile)
                    await api.post(`/api/v1/activities/${activitiesId.id}/regulation`, formData, {
                        headers: {
                            Authorization: `Bearer ${userData[0].token}`,
                            'Content-Type': 'multipart/form-data',
                        }
                    })
                } else {
                    await api.delete(`/api/v1/activities/${activitiesId.id}/regulation`, {
                        headers: {
                            Authorization: `Bearer ${userData[0].token}`,
                        }
                    })
                }
            }
            if (activitiesStatus !== activitiesStatusHistory) {
                await api.put(`/api/v1/activities/${activitiesId.id}/active/${activitiesStatus ? 1 : 0}`, {}, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            }

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            history.back()
            return setShowNotificationModalText("Atividade cadastrada com sucesso!")

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function handleChangeValueText(content, delta, source, editor) {
        setDescription(content)
        setDeltaDescription(editor.getContents());
    }

    function handleChangeValueTextTerm(content, delta, source, editor) {
        setTermDescription(content)
        setDeltaTermDescription(editor.getContents());
    }

    function handleClickInputFile() {
        let inputEl = document.getElementById('pictureAddInput')
        inputEl.click()
    }

    async function handleNewPicture(e) {

        const validFile = permittedFiles(e.target.files[0], 'image')
        if (validFile.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(validFile.response)
        }

        if (activitiesPictures.length < 5) {

            const formattedImg = await new Promise((resolve, reject) => {
                resizeImageTest(e, (err, resizedFile) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    resolve(resizedFile)
                })
            })

            const photoObj = URL.createObjectURL(formattedImg)            
            setActivitiesPictures([...activitiesPictures, e.target.files[0].name])

            await formatAPINewPhoto([...activitiesAppendPictures, photoObj])
        }

    }

    const prevPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = activitiesAppendPictures[showPhoto - 1]
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto - 1)
    }

    const nextPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = activitiesAppendPictures[showPhoto + 1]
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto + 1)
    }

    async function showThisPhoto(photo, indexPhoto) {

        setShowPhotoModal(true)
        const modalElement = document.getElementById('showPhotoModal')

        let img = document.createElement('img')
        img.src = activitiesAppendPictures[indexPhoto]
        img.id = 'appendedPreviewPhoto'
        modalElement.appendChild(img)
        setShowPhoto(indexPhoto)

        await new Promise(resolve => setTimeout(resolve, 100))
        document.getElementById('photoContainer').style.opacity = '1'
        document.getElementById('photoContainer').style.transform = 'translateY(0px)'

    }

    async function closeThisPhoto() {

        document.getElementById('photoContainer').style.opacity = '0'
        document.getElementById('photoContainer').style.transform = 'translateY(-100px)'
        await new Promise(resolve => setTimeout(resolve, 300))

        setShowPhotoModal(false)
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)
        setShowPhoto('')
    }

    async function removeThisPhoto(photo, indexPhoto) {

        document.getElementById(photo + '-' + indexPhoto).style.transform = 'scale(.5)'
        await new Promise(resolve => setTimeout(resolve, 500))
        const newPictures = activitiesPictures.filter(photoToRemove => photoToRemove !== photo)
        setActivitiesPictures(newPictures)
        document.getElementById(photo + '-' + indexPhoto).style.transform = 'scale(1)'
        await formatAPINewPhoto(activitiesAppendPictures.filter((photoToRemove, index) => indexPhoto !== index))

    }

    async function resolveAndDownloadBlob(response, nameFile, originalName, id) {
        let filename = `${originalName}`;

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const file = await convertURLtoFile(url, filename)

        const link = document.createElement('a')
        const urlToDownload = URL.createObjectURL(file)

        link.href = urlToDownload
        link.download = file.name
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(urlToDownload)
    }

    function handleClickRegulationFileInput() {
        let inputEl = document.getElementById('regulationFileInput')
        inputEl.click()
    }

    function handleNewRegulation(e) {

        const validFile = permittedFiles(e.target.files[0], 'pdf')
        if (validFile.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            removeThisRegulation()
            return setShowNotificationModalText(validFile.response)
        }

        setRegulationFile(e.target.files[0])
        setRegulationFileChanged(true)
    }

    async function removeThisRegulation() {
        setRegulationFile('')
    }

    async function handleDownloadRegulation() {
        const file = await api.get(`api/v1/activities/${activitiesId.id}/regulation`, {
            headers: {
                Authorization: `Bearer ${userData[0].token}`
            },
            responseType: 'blob'
        })
        await resolveAndDownloadBlob(file, 'Regulamento', 'regulamento.pdf', 1)
    }

    async function deleteRegulation() {
        setRegulationFile(null)
        setRegulationFileChanged(true)
        setShowUniversalModal(false)
    }

    return (
        <>
            <ScreenModal>
                <div className='p-4 flex flex-col items-center gap-6 justify-center'>
                    <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja excluir o regulamento?</a>
                    <div className='flex flex-row gap-4 items-center justify-center'>
                        <Button shadow={true} onClick={() => deleteRegulation()}>Sim</Button>
                        <Button shadow={true} approval={false}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Editar atividade'} />
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex flex-col w-full h-96 items-center justify-center'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='w-full flex flex-col items-start justify-start gap-8'>
                                <div className='w-full flex flex-col items-start justify-start gap-4'>
                                    <div className='flex flex-col w-full border-b border-gray-300'>
                                        <h1 className='text-primaryDefaultLight text-base'>Informações da atividade</h1>
                                    </div>
                                    <LabelInput text={'Ativo'}>
                                        <Toogle status={activitiesStatus} onClick={() => {
                                            setActivitiesStatus(!activitiesStatus)
                                        }} />
                                    </LabelInput>
                                    <div className='flex items-center gap-4'>
                                        <LabelInput text={'Título *'}>
                                            <Input id='activitiesName' type={'text'} charLimit={255} value={activitiesName} onChange={(e) => setActivitiesName(e.target.value)}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Escolinha/Infantil *'}>
                                            <Toogle id='isSchool' key='isSchool' status={isSchool} onClick={(e) => setIsSchool(!isSchool)}></Toogle>
                                        </LabelInput>
                                    </div>
                                    <label className={`flex flex-col relative gap-1 justify-start w-full -mb-2`}>
                                        <a className={`tex-lg sm:text-sm text-inputPlaceholderLight`}>Descrição</a>
                                    </label>
                                    <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96 mb-12' readOnly={false} theme={"snow"} value={description} onChange={handleChangeValueText} />
                                    <LabelInput text={'Inscrições abertas ?'}>
                                        <div className='flex flex-row gap-6'>
                                            <div className='flex flex-row gap-4 items-start justify-start'>
                                                <InputCheckBox value={openForSubscription} onClick={() => setOpenForSubscriptions(true)}></InputCheckBox>
                                                <p className='text-sm text-inputPlaceholderLight'>Sim</p>
                                            </div>
                                            <div className='flex flex-row gap-4 items-start justify-start'>
                                                <InputCheckBox value={!openForSubscription} onClick={() => setOpenForSubscriptions(false)}></InputCheckBox>
                                                <p className='text-sm text-inputPlaceholderLight'>Não</p>
                                            </div>
                                        </div>
                                    </LabelInput>
                                    <div className='flex flex-col sm:flex-row gap-2 items-start justify-start'>
                                        <LabelInput text={'Data de início da atividade:'}>
                                            <Input width={44} type={'date'} value={startDate} onChange={(e) => setStartDate(e.target.value)}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Data do fim da atividade:'}>
                                            <Input width={44} type={'date'} value={finishDate} onChange={(e) => setFinishDate(e.target.value)}></Input>
                                        </LabelInput>
                                    </div>
                                </div>
                                <div className='w-full flex flex-col items-start justify-start gap-8'>
                                    <div className='flex flex-col w-full border-b border-gray-300'>
                                        <h1 className='text-base text-primaryDefaultLight'>Configurações de planos</h1>
                                    </div>
                                    <>
                                        <Tippy content={<p>Novo plano</p>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div onClick={() => navigate('/activitiesPlansCreate', {
                                                state: {
                                                    selectedActivities: activitiesData
                                                }
                                            })} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Adicionar</a>
                                            </div>
                                        </Tippy>
                                        <div className='boxShadow w-full'>
                                            <TableDefault
                                                onClick={(e) => navigate(`/activitiesPlansEdit/${e.id}`, {
                                                    state: {
                                                        selectedActivities: activitiesData
                                                    }
                                                })}
                                                title={[
                                                    {
                                                        name: 'Nome do Plano',
                                                        original_name: 'planName'
                                                    },
                                                    {
                                                        name: 'Data de Criação',
                                                        original_name: 'created_at'
                                                    }
                                                ]}
                                                data={activitiesPlans}
                                                collumns={["planName", "created_at"]}
                                                loading={loading}
                                            />
                                        </div>
                                    </>
                                </div>
                                <div className='flex flex-col w-full items-start justify-start'>
                                    <div className='flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark mb-4'>
                                        <h1 className='text-base text-primaryDefaultLight'>Termos e condição</h1>
                                    </div>
                                    <label className={`flex flex-col relative gap-1 justify-start w-full mb-1`}>
                                        <a className={`tex-lg sm:text-sm text-inputPlaceholderLight`}>Termos e condições</a>
                                    </label>
                                    <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96 mb-12' readOnly={false} theme={"snow"} value={termdescription} onChange={handleChangeValueTextTerm} />
                                </div>
                                <div className='flex flex-col w-full items-start justify-start gap-4'>
                                    <div className='flex flex-col w-full border-b border-gray-300'>
                                        <h1 className='text-base text-primaryDefaultLight'>Regulamento</h1>
                                    </div>
                                    <input id='regulationFileInput' type={'file'} onChange={(e) => handleNewRegulation(e)} className='hidden'></input>
                                    {
                                        regulationFile?.length > 0 ?
                                            <div className='flex flex-row items-center justify-center gap-2 '>
                                                <Button width={64} shadow={true} onClick={() => handleDownloadRegulation()}>{`Baixar regulamento`}</Button>
                                                <Tippy
                                                    content={<a>Excluir</a>}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div onClick={() => setShowUniversalModal(true)}>
                                                        <FaTrash className='text-xl cursor-pointer text-primaryDefaultLight hover:text-secondaryDefaultLight transition-all duration-300' />
                                                    </div>
                                                </Tippy>
                                            </div>
                                            :
                                            <Button width={64} shadow={true} onClick={() => handleClickRegulationFileInput()}>{`Configurar arquivo (.pdf)`}</Button>

                                    }
                                    {
                                        regulationFile?.name?.length > 0 &&
                                        <>
                                            <a className='text-sm text-inputPlaceholderLight'>{regulationFile?.name}</a>
                                            <a className='text-sm text-inputPlaceholderLight underline cursor-pointer hover:brightness-75' onClick={() => removeThisRegulation()}>remover</a>
                                        </>
                                    }
                                </div>
                                <div className='flex flex-row items-center gap-2'>
                                    <LabelInput text={'Link'}>
                                        <Input type={'text'} onChange={(e) => setHyperLink(e.target.value)} value={hyperLink} />
                                    </LabelInput>
                                    <Tippy content={'Link para aparecer na ativadade podendo ser um link para o site ou whatsapp'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='right'
                                        delay={300}>
                                        <div className='mt-6'>
                                            <FaQuestionCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='flex flex-row items-center gap-2'>
                                    <LabelInput text={'Descrição do link'}>
                                        <Input type={'text'} onChange={(e) => setHyperLinkDescription(e.target.value)} value={hyperLinkDescription} />
                                    </LabelInput>
                                    <Tippy content={'Texto para descrever este link'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='right'
                                        delay={300}>
                                        <div className='mt-6'>
                                            <FaQuestionCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                <div id='activitiesCreatePictures' className='w-full flex flex-col items-start justify-start gap-4 user-select-none'>
                                    <div className='flex flex-col w-full border-b border-gray-300'>
                                        <h1 className='text-primaryDefaultLight text-base'>Galeria de fotos</h1>
                                    </div>
                                    <div className='flex flex-row gap-2 items-start justify-start'>
                                        <div className={`${showPhotoModal ? 'flex' : 'hidden'} flex-row items-center justify-center w-full h-full fixed top-0 left-0 z-[101]`}>
                                            <div id='photoContainer' className={`opacity-0 transition-all duration-500 -translate-y-10 flex gap-4 flex-row items-center justify-center w-full h-full`}>
                                                {
                                                    showPhoto > 0 ?
                                                        <FaArrowCircleLeft onClick={prevPhoto} className='text-primaryDefaultLight hover:-translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                        :
                                                        <FaArrowCircleLeft className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                }
                                                <div className='bg-bgPrimaryLight dark:bg-primaryDefaultDark boxShadow p-8 w-full h-full md:w-[600px] md:h-[500px] lg:w-[1000px] lg:h-[700px] flex flex-col items-center justify-start'>
                                                    <div className='flex flex-row justify-end w-full'>
                                                        <button className='bg-primaryDefaultLight hover:brightness-125 transition-all duration-200 h-8 w-8 rounded-full flex flex-col items-center justify-center text-titleGrayTextLight' onClick={() => closeThisPhoto()}>X</button>
                                                    </div>
                                                    <div id='showPhotoModal' className='flex flex-col items-center justify-center overflow-auto w-full h-full'>
                                                    </div>
                                                </div>
                                                {
                                                    showPhoto < activitiesPictures.length - 1 ?
                                                        <FaArrowAltCircleRight onClick={nextPhoto} className='text-primaryDefaultLight hover:translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                        :
                                                        <FaArrowAltCircleRight className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                }
                                            </div>
                                        </div>
                                        <div className={`${showPhotoModal ? 'flex' : 'hidden'}  bg-gray-600 opacity-80 w-full h-full fixed z-[100] left-0 top-0`}></div>

                                        <h1 className='text-sm text-inputPlaceholderLight'>Fotos</h1>
                                        <a className='text-inputPlaceholderLight dark:text-titleGrayTextDark text-sm'>{activitiesPictures.length} de 5</a>
                                        <p className='font-semibold text-base cursor-pointer' onClick={() => handleClickInputFile()}>+</p>
                                    </div>
                                    <input id='pictureAddInput' type='file' className='hidden' onChange={(e) => handleNewPicture(e)}></input>
                                    <div className='flex flex-row gap-2 items-center justify-start'>
                                        {
                                            activitiesPictures.map((photo, indexPhoto) => {
                                                return <div id={photo + '-' + indexPhoto} className='cursor-pointer transition-all duration-500 ease-in-out relative flex flex-row gap-4 items-center justify-center'>
                                                    <Tippy content={'Remover foto'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='right'
                                                        delay={300}>
                                                        <button onClick={() => removeThisPhoto(photo, indexPhoto)} className='text-titleGrayTextLight absolute -left-2 -top-2 w-10 h-10 sm:w-6 sm:h-6 rounded-full bg-gray-400'>x</button>
                                                    </Tippy>

                                                    <img onClick={() => showThisPhoto(photo, indexPhoto)} className='border border-gray-500 rounded-xl w-20 h-20' src={activitiesAppendPictures[indexPhoto]}></img>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => history.back()}>Voltar</Button>
                        <Button module={"activities:update"} userData={userData} shadow={true} onClick={() => {
                            handleSubmit()
                        }} >Salvar</Button>
                    </div>
                </Footer>
            </Container>
        </>

    )
}