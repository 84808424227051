//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { validateFields } from '../../../utils/form.validator'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import Tippy from '@tippyjs/react'
import { FaInfoCircle, FaTrash, FaDownload } from 'react-icons/fa'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import { justNumber } from '../../../utils/validators/justNumber'
import { Toogle } from '../../../components/toogle/Toogle'
import { TableDefault } from '../../../components/table/table.default'
import { SystemAreas } from "../../../utils/statics/systemAreas"
import moment from 'moment'
import QRCode from 'react-qr-code'
import html2Canvas from 'html2canvas'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function PatrimonyEdit() {

    const navigate = useNavigate()
    const { defaultCallsFinancial, api_financial } = useApiContext()
    const params = useParams()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const { showUniversalModal, setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [detectModal, setDetectModal] = useState('')
    const [productList, setProductList] = useState([])
    const [listLinks, setListLinks] = useState([])
    const [patrimonyHistory, setPatrimonyHistory] = useState([])
    const dataDepreciationUnitMeasure = [{ id: 'day', name: 'Diária' }, { id: 'month', name: 'Mensal' }, { id: 'year', name: 'Anual' }]
    const tablePatrimonyHead = [
        {
            "name": 'Data inicio',
            "original_name": 'startDate'
        },
        {
            "name": 'Vinculado com',
            "original_name": 'linked'
        },
        {
            "name": 'Descrição',
            "original_name": 'linked_name'
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        },
        {
            "name": 'Remover',
            "original_name": 'itemRemove'
        }
    ]

    //selected variables
    const [patrimony, setPatrimony] = useState({
        financialProducts_id: 0,
        depreciationPercentage: 0,
        depreciationUnitMeasure: '',
        shoppingPrice: 0,
        active: undefined,
        nf: ''
    })
    const [patrimonyHistoryCreate, setPatrimonyHistoryCreate] = useState({
        financialPatrimony_id: undefined,
        startDate: undefined,
        linked: undefined,
        linked_id: undefined
    })

    async function getPatrimonyHistoryData() {
        const patrimonyHistoryData = await defaultCallsFinancial.getPatrimonyHistory(userData[0]?.token, params?.id)
        if (patrimonyHistoryData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(patrimonyHistoryData.data)
        }
        setPatrimonyHistory(patrimonyHistoryData?.data.map((items) => ({
            ...items,
            linked: SystemAreas.filter(areas => areas?.id === items?.linked)[0]?.description,
            itemRemove: <div className='flex items-center justify-center gap-1'>
                <FaTrash onClick={() => handleRemovePatrimonyHistory(items?.id)} size={16} color='#B22222' />
            </div>,
            startDate: moment(items?.startDate).format("DD/MM/YYYY"),
            created_at: moment(items?.created_at).format("DD/MM/YYYY"),
        })))
    }

    async function getData() {
        const patrimonyData = await defaultCallsFinancial.getPatrimonyById(userData[0]?.token, params?.id)
        if (patrimonyData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(patrimonyData.data)
            return
        }
        setPatrimony(patrimonyData?.data[0])

        const productData = await defaultCallsFinancial.getProducts(userData[0]?.token, 1)
        if (productData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(productData.data)
        }
        setProductList(productData?.data)

        const linksData = await defaultCallsFinancial.getFinancialListLinks(userData[0]?.token, 1)
        if (linksData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(linksData.data)
        }
        setListLinks(linksData?.data)

        getPatrimonyHistoryData()

        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    async function handleSubmit() {
        let requiredFields =
            [
                { "name": 'financialProducts_id', "value": patrimony?.financialProducts_id, "required": true, "type": 'string' },
                { "name": 'depreciationPercentage', "value": patrimony?.depreciationPercentage, "required": true, "type": 'string' },
                { "name": 'depreciationUnitMeasure', "value": patrimony?.depreciationUnitMeasure, "required": true, "type": 'string' },
                { "name": 'shoppingPrice', "value": patrimony?.shoppingPrice, "required": true, "type": 'number' },
            ]
        //chamada API
        setLoading(true)
        try {

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            await api_financial.put(`/api/v1/financial/patrimony/${params.id}`, {
                financialProducts_id: patrimony?.financialProducts_id,
                shoppingPrice: patrimony?.shoppingPrice,
                depreciationUnitMeasure: patrimony?.depreciationUnitMeasure,
                depreciationPercentage: patrimony?.depreciationPercentage,
                nf: patrimony?.nf,
                active: patrimony?.active
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Setor salvo com sucesso!")
            navigate('/financial/patrimony')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSubmitPatrimonyHistory() {
        try {
            setLoading(true)

            await api_financial.post(`/api/v1/financial/patrimonyHistory`, {
                financialPatrimony_id: patrimony?.id,
                startDate: patrimonyHistoryCreate?.startDate,
                linked: patrimonyHistoryCreate?.linked?.linked,
                linked_id: patrimonyHistoryCreate?.linked?.linked_id
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            getPatrimonyHistoryData()
            setShowUniversalModal(false)
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Sucesso ao cadastrar!")
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleRemove() {

        setLoading(true)
        try {

            await api_financial.delete(`/api/v1/financial/patrimony/${params.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Patrimônio removido com sucesso!")
            setShowUniversalModal(false)
            navigate('/financial/patrimony')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleRemovePatrimonyHistory(id) {
        setLoading(true)
        try {

            await api_financial.delete(`/api/v1/financial/patrimonyHistory/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            getPatrimonyHistoryData()
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Removido com sucesso!")
            setShowUniversalModal(false)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function downloadQrCodeImage(){
        const element = document.getElementById('patrimony-qrcode');
        const canvas = await html2Canvas(element);
        const data = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = data;
        link.download = `qrCode-patrimonio-${params?.id}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const totalPages = Math.ceil(patrimonyHistory?.length / 10)

    return (
        <>
            {
                showUniversalModal && detectModal === 'Excluir'
                &&
                <ScreenModal>
                    <div className='p-4 flex flex-col text-sm items-center justify-center gap-2'>
                        <a>Deseja realmente excluir este patrimônio?</a>
                        <div className='flex gap-4'>
                            <Button shadow={true} onClick={() => handleRemove()}>Sim</Button>
                            <Button approval={false} shadow={true} onClick={() => setShowUniversalModal(false)}>Cancelar</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            {
                showUniversalModal && detectModal === 'Lançar'
                &&
                <ScreenModal height={350} width={450}>
                    <div className='p-4 flex flex-col justify-center items-center gap-12'>
                        <div className='flex flex-col items-center justify-center gap-4'>
                            <p>Nova movimentação</p>
                            <LabelInput text={'Data inicial: *'}>
                                <Input 
                                    id='startDate' 
                                    type={'date'} 
                                    value={patrimonyHistoryCreate?.startDate ? patrimonyHistoryCreate?.startDate : ''} 
                                    onChange={(e) => setPatrimonyHistoryCreate(prev => ({ ...prev, startDate: e.target.value }))}>    
                                </Input>
                            </LabelInput>
                            <LabelInput text={'Vinculado com: *'}>
                                <InputAutoComplete
                                    data={listLinks}
                                    id={'linked'}
                                    onChange={(e) =>
                                        setPatrimonyHistoryCreate(prev => ({ ...prev, linked: e }))
                                    }
                                    value={listLinks?.filter(links => links?.linked_id === patrimonyHistoryCreate?.linked?.id)[0]?.linked_name}
                                    selectedLabel={'linked_name'}
                                    optionList={['linked_id', 'linked_name']}
                                />
                            </LabelInput>
                        </div>
                        <div className='flex items-center gap-2'>
                            <Button approval={false} shadow={true} onClick={() => setShowUniversalModal(false)}>Cancelar</Button>
                            <Button shadow={true} onClick={() => handleSubmitPatrimonyHistory()}>Criar</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            <Container>
                <TitlePage>
                    <Title text={'Editar Patrimônio'}></Title>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className=' relative w-full'>
                                <div className='absolute right-0 flex flex-col items-center gap-2'>
                                    <div id='patrimony-qrcode' className='p-2'>
                                        <QRCode value={`http://${userData[0]?.customers_app_name}.agendarapido.com/#/financial/patrimonyEdit/${Number(params?.id)}`} size={180}/>
                                    </div>
                                    <span onClick={() => downloadQrCodeImage()} className='text-gray-400 hover:text-primaryDefaultLight flex items-center gap-2 cursor-pointer'>
                                        <p>Baixar imagem</p>
                                        <FaDownload/>
                                    </span>
                                </div>  
                                <form className='flex flex-col gap-2 items-start justify-start sm:justify-center w-full'>
                                    <div className='flex gap-1 w-full'>
                                        <LabelInput text={'Produto: *'}>
                                            <InputAutoComplete
                                                data={productList}
                                                id={'financialProducts_id'}
                                                onChange={(e) =>
                                                    setPatrimony(prev => ({ ...prev, financialProducts_id: e?.id }))
                                                }
                                                value={productList.filter(prod => prod?.id === patrimony?.financialProducts_id)[0]?.name}
                                                selectedLabel={'name'}
                                                optionList={['id', 'name']}
                                            />
                                        </LabelInput>
                                        <Tippy
                                            content={<a>{`Selecione um produto já criado.`}</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                        >
                                            <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                <FaInfoCircle />
                                            </div>
                                        </Tippy>
                                    </div>
                                    <LabelInput text={'% de depreciação: *'}>
                                        <Input id='depreciationPercentage' value={patrimony?.depreciationPercentage ? patrimony?.depreciationPercentage : undefined} onChange={(e) => setPatrimony(prev => ({ ...prev, depreciationPercentage: justNumber(e.target.value) }))}></Input>
                                    </LabelInput>
                                    <div className='flex gap-1 w-full'>
                                        <LabelInput text={'Depreciação de unidade: *'}>
                                            <InputAutoComplete
                                                data={dataDepreciationUnitMeasure}
                                                id={'depreciationUnitMeasure'}
                                                onChange={(e) => setPatrimony(prev => ({ ...prev, depreciationUnitMeasure: e?.id }))}
                                                value={dataDepreciationUnitMeasure.filter(prod => prod?.id === patrimony?.depreciationUnitMeasure)[0]?.name}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                            />
                                        </LabelInput>
                                        <Tippy
                                            content={<a>{`Selecione como será calculado a depreciação (mensalmente ou anualmente).`}</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                        >
                                            <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                <FaInfoCircle />
                                            </div>
                                        </Tippy>
                                    </div>
                                    <div className='w-96'>
                                        <LabelInput text={'Valor de compra: *'}>
                                            <CurrencyInput
                                                value={patrimony?.shoppingPrice ? patrimony?.shoppingPrice : undefined}
                                                id={'shoppingPrice'}
                                                placeHolder='R$ 0,00'
                                                onChange={(e) => setPatrimony(prev => ({ ...prev, shoppingPrice: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))}
                                            >
                                            </CurrencyInput>
                                        </LabelInput>
                                    </div>
                                    <LabelInput text={'Nota fiscal:'}>
                                        <Input 
                                            id='nf' 
                                            type={'text'} 
                                            value={patrimony?.nf} 
                                            onChange={(e) => setPatrimony(prev => ({ ...prev, nf: e.target.value }))}
                                            charLimit={100}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'ativo'}>
                                        <Toogle status={patrimony?.active} onClick={() => setPatrimony(prev => ({ ...prev, active: !patrimony?.active }))} />
                                    </LabelInput>
                                    <div className='w-full flex flex-col items-end overflow-y-auto gap-2'>
                                        <div className='w-full flex items-end justify-end'>
                                            <Button type={'button'} onClick={() => { setShowUniversalModal(true); setDetectModal("Lançar") }}>Nova movimentação</Button>
                                        </div>
                                        <TableDefault
                                            data={patrimonyHistory}
                                            pagination={true}
                                            totalPages={totalPages}
                                            onClick={(e) => handleEdit(e)}
                                            title={tablePatrimonyHead}
                                            collumns={["startDate", "linked", "linked_name", "created_at", "itemRemove"]}
                                            loading={loading}
                                            filter={(e) => orderTableData(e)}
                                        />
                                    </div>
                                </form>
                            </div>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate('/financial/patrimony')}>Cancelar</Button>
                        <div className='flex gap-2'>
                            <Button shadow={true} approval={false} onClick={() => {
                                setShowUniversalModal(true);
                                setDetectModal('Excluir')
                            }}>Excluir</Button>
                            <Button shadow={true} approval={true} onClick={() => {
                                handleSubmit()
                            }}>Salvar</Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>

    )
}