//hooks
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Footer } from '../../../components/footer/Footer'
import { LabelInput } from '../../../components/label/label.input'
import { Button } from '../../../components/buttons/button.default'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
//services
import { formattTimeToShow } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
//utils
import { mCPF } from '../../../utils/validators/cpfMask'
import { validateFields } from '../../../utils/form.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { removeEspecialChar } from '../../../utils/validators/removeEspecialCharacter'
import { MyProfilePictureEdit } from './myProfile.pictureEdit'
import { validateEmail } from '../../../utils/validators/email.validator'
import { permittedFiles } from '../../../utils/permittedFiles/permittedFiles'
import QRCode from 'react-qr-code'
import { FaPrint } from 'react-icons/fa'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import { maskPhone } from '../../../utils/mask/maskPhone'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function MyProfileEdit() {

    const { api_auth, defaultCallsAuth } = useApiContext()
    const { userData, setUserData, setDontHaveEmail } = useAuth()
    const { screenX } = useScreenSizeContext()
    const [profilePic, setProfilePic] = useState('')
    const navegate = useNavigate()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    const [loading, setLoading] = useState(false)
    const [updatedUser, setUpdatedUser] = useState(false)
    const [errorToSubmit, setErrorToSubmit] = useState(false)
    const [showNotification, setShowNotication] = useState('')
    const [typeAccessList, setTypeAccessList] = useState([])
    const [permissionGroupsData, setPermissionsGroupsData] = useState([])
    const [thisUserData, setThisUserData] = useState([])
    const [userActive, setUserActive] = useState(true)
    const [userPermitted, setUserPermiited] = useState(userData[0]?.permissions?.indexOf('users:update') > -1 || userData[0]?.typeAccess_id === 3 ? true : false)
    const [isSettingNewPassword, setIsSettingNewPassword] = useState(false)
    const [imageSrc, setImageSrc] = useState(null)
    const [picPreview, setPicturePreview] = useState('')

    //selectedVariables
    const [selectedUserName, setSelectedUserName] = useState('')
    const [selectedUserId, setSelectedUserId] = useState('')
    const [selectedUserSubName, setSelectedUserSubName] = useState('')
    const [selectedUserEmail, setSelectedUserEmail] = useState('')
    const [selectedBirthDay, setSelectedBirthDay,] = useState('')
    const [selectedTypeAccesss, setSelectedTypeAccess] = useState('')
    const [selectedPermissionGroup, setSelectedPermissionGroup] = useState('')
    const [selectedUserGender, setSelectedUserGender] = useState('')
    const [selecteduserCpf, setSelectedUserCpf] = useState('')
    const [selectedDDDCell, setSelectedDDDCell] = useState('')
    const [selectedMobilePhone, setSelectedMobilePhone,] = useState('')
    const [selectedDDDPhone, setSelectedDDDPhone] = useState('')
    const [selectedPhone, setSelectedPhone,] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [newPassord, setNewPassord] = useState('')
    const [newPassordConfirmation, setNewPassordConfirmation] = useState('')
    const [showMemberShipPrint, setShowMemberShipCard] = useState(false)
    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        }
    ]

    async function getData() {

        const userDataApi = await defaultCallsAuth.getMyProfile(userData[0].token)
        if (userDataApi.error) {
            setLoading(false)
            setShowNotication(userDataApi.data)
            return
        }

        if (userDataApi.data[0]?.typeAccess_id !== 4) {

            const typeAccessData = await defaultCallsAuth.getTypeAccess(userData[0].token)
            if (typeAccessData.error) {
                setLoading(false)
                setShowNotication(typeAccessData.data)
                return
            }

            setTypeAccessList(typeAccessData.data.filter((type) => {
                if (type?.id === 2 || type?.id === 3 || type.id === 1) {
                    return type
                }
            }))

            const permissionGroupsData = await defaultCallsAuth.getPermissionGroups(userData[0].token)
            if (permissionGroupsData.error) {
                setLoading(false)
                setShowNotication(permissionGroupsData.data)
                return
            }
            setPermissionsGroupsData(permissionGroupsData.data)

        } else {
            setSelectedTypeAccess({ id: 4, description: 'Cliente' })
        }
        const thiUserGender = sexTypes.filter((gender) => {
            if (gender.id === userDataApi.data[0].gender) {
                return gender
            }
        })

        setThisUserData(userDataApi.data[0])
        setSelectedUserName(userDataApi.data[0].firstName)
        setSelectedUserSubName(userDataApi.data[0].lastName)
        setSelectedUserEmail(userDataApi.data[0].email)
        setSelectedTypeAccess({ id: userDataApi.data[0]?.typeAccess_id, description: userDataApi.data[0]?.typeAccessDescription })
        setSelectedPermissionGroup({ id: userDataApi.data[0]?.permissionGroups_id, description: userDataApi.data[0]?.permissionGroupsName })
        setSelectedBirthDay(userDataApi.data[0].birthday ? userDataApi.data[0].birthday.indexOf('T') > -1 ? userDataApi.data[0].birthday.slice(0, userDataApi.data[0].birthday.indexOf('T')) : userDataApi.data[0].birthday : '')
        setSelectedUserGender(thiUserGender[0])
        setSelectedUserCpf(userDataApi.data[0].cpf)
        setSelectedDDDCell(userDataApi.data[0].dddcell)
        setSelectedMobilePhone(userDataApi.data[0].cell)
        setSelectedDDDPhone(userDataApi.data[0].dddphone)
        setSelectedPhone(userDataApi.data[0].phone)
        setSelectedUserId(userDataApi?.data[0]?.id)
        setUserActive(userDataApi.data[0]?.active === 1 ? true : false)
        setUserPermiited(
            (userData[0]?.permissions?.indexOf('users:update') > -1 || userData[0]?.typeAccess_id < 5)
                && (userData[0]?.id === userDataApi.data[0]?.id)
                ? true : false
        )
        if (!userDataApi.data[0].email) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Você não possui email configurado, e-mail é obrigatório para utilizar o sistema, efetue o cadastro abaixo')
        }
        setLoading(false)
    }

    useEffect(() => {

        if (showMemberShipPrint) {
            handleClickImprimir()
        }
    }, [showMemberShipPrint])

    useEffect(() => {

        setShowUniversalModal(false)
        setLoading(true)
        getData()

    }, [updatedUser])

    useEffect(() => {

        async function getPic() {

            try {

                const response = await api_auth.get('/api/v1/auth/myProfile/picture', {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: 'Bearer ' + userData[0].token
                    }
                })

                setProfilePic(URL.createObjectURL(response.data))

            } catch (error) {
                setProfilePic('')
            }

        }

        getPic()

    }, [userData])

    function handleClickImprimir() {
        const componenteParaImprimir = document.getElementById('memberShipCard').children[0].cloneNode(true);

        const janelaImpressao = window.open('', '_blank');
        janelaImpressao.document.write('<html><head><title>Impressão</title></head><body>');
        janelaImpressao.document.write(componenteParaImprimir.outerHTML);
        janelaImpressao.document.write('</body></html>');
        janelaImpressao.document.close();
        janelaImpressao.print();
        setShowMemberShipCard(false)
    };


    async function handleSubmit() {

        const fields =
            [
                { "name": 'selectedUserName', "value": selectedUserName, "required": true, "type": 'string' },
                { "name": 'selectedUserSubName', "value": selectedUserSubName, "required": true, "type": 'string' },
                { "name": 'selectedBirthDay', "value": selectedBirthDay, "required": true, "type": 'string' },
                { "name": 'selectedPermissionGroup', "value": selectedPermissionGroup, "required": selectedTypeAccesss.id === 3 ? true : false, "type": 'object' },

            ]

        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Campos obrigatórios não preenchidos!')
            return setShowNotificationModalSuccess(false)
        }
        try {
            setLoading(true)
            await api_auth.put(`/api/v1/auth/myProfile`, {
                firstName: selectedUserName ? selectedUserName : undefined,
                lastName: selectedUserSubName ? selectedUserSubName : undefined,
                birthday: selectedBirthDay ? selectedBirthDay : undefined,
                gender: selectedUserGender ? selectedUserGender.id : undefined,
                dddphone: selectedDDDPhone ? selectedDDDPhone : undefined,
                dddcell: selectedDDDCell ? selectedDDDCell : undefined,
                phone: selectedPhone ? selectedPhone : undefined,
                cell: selectedMobilePhone ? selectedMobilePhone : undefined,
                cpf: selecteduserCpf ? removeEspecialChar(selecteduserCpf) : undefined,
                email: selectedUserEmail ? selectedUserEmail : undefined,

            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            if (isSettingNewPassword) {

                await api_auth.put(`/api/v1/auth/myProfile/password`, {

                    "oldPassword": oldPassword,
                    "newPassword": newPassord
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
            }
            let newUserData = userData[0]
            newUserData.email = selectedUserEmail
            setLoading(false)
            setUserData([newUserData])
            setShowNotificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            setDontHaveEmail(false)
            navegate('/')
        } catch (error) {
            setLoading(false)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function cleanPass() {

        setOldPassword('')
        setNewPassord('')
        setNewPassordConfirmation('')
    }

    //picture functions
    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }


    function handlePicture() {

        const input = document.getElementById('myProfilePicture')
        input.click()
    }

    async function showPreview(e) {
        const validImage = permittedFiles(e?.target?.files[0], 'image')
        if (validImage?.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(validImage?.response)
        }
        const file = e.target.files[0];
        let imageDataUrl = await readFile(file)
        setImageSrc(imageDataUrl);
        setPicturePreview(URL.createObjectURL(file))
    }

    function checkPassConfrmation() {

        if (newPassord !== newPassordConfirmation) {
            setErrorToSubmit(true)
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Confirmação de senha está incorreta')
        } else {
            setShowNotificationModal(false)
            setErrorToSubmit(false)
        }
    }

    function handleInactveUserInfo() {
        setLoading(false)
        setShowNotificationModalSuccess(false)
        setShowNotificationModal(true)
        return setShowNotificationModalText('Confirmação de senha está incorreta, a nova senha e a confirmação devem ser iguais')
    }

    return (

        <Container>
            <TitlePage>
                <Title text={'Editar meu perfil'}></Title>
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ?
                        <div className='flex w-full h-96 flex-col justify-center items-center'>
                            <DefaultLoader></DefaultLoader>
                        </div>
                        :
                        imageSrc ?
                            <MyProfilePictureEdit onChange={(e) => setImageSrc(e)} imgSrc={imageSrc} picPreview={picPreview} />
                            :
                            <div className='flex flex-col items-start justify-start w-full'>
                                <div className='w-full flex-col-reverse sm:flex-col flex items-center sm:items-start pt-6 sm:pt-0 gap-8'>
                                    <div className='flex flex-col items-center justify-center'>
                                        <div onClick={() => handlePicture()} className={`cursor-pointer flex flex-row items-center justify-center h-32 w-32 sm:h-20 sm:w-20 rounded-full ${profilePic?.length > 0 ? 'bg-transparent' : 'bg-primaryDefaultLight'} `}>
                                            {
                                                profilePic?.length > 0 ?
                                                    <div className='flex flex-col items-center justify-center h-32 w-32 sm:h-20 sm:w-20 shadow-lg bg-transparent rounded-full overflow-hidden'>
                                                        <img src={profilePic}></img>
                                                    </div>
                                                    :
                                                    <a className='text-titleGrayTextLight text-3xl'>{userData[0]?.firstName?.slice(0, 1)}</a>

                                            }
                                        </div>
                                        <a onClick={() => handlePicture()} className='mt-5 mb-5 hover:underline cursor-pointer text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Alterar foto de perfil</a>
                                        <input accept="image/png,image/jpeg,image/jpg,image/gif,image/jpeg" id='myProfilePicture' type='file' onChange={(e) => showPreview(e)} hidden={true}></input>
                                    </div>
                                    <div className=' w-full h-36 flex sm:hidden gap-2 flex-col items-center justify-center'>
                                        <QRCode value={`http://${userData[0]?.customersData?.code}.agendarapido.com/#/validUser/${selectedUserId}/${selectedTypeAccesss?.id}`} />
                                        <a className='text-xs text-titleBlackTextLight dark:text-titleGrayTextDark'>Apresente o Qr Code na portaria</a>
                                    </div>

                                </div>
                                <form className='w-full md:w-auto flex flex-col items-center justify-center md:grid sm:grid-cols-1 xl:grid-cols-2 gap-4'>
                                    <label className='w-full flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                        <Input disabled={true} charLimit={100} id='selectedUserName' type='text' value={selectedUserName} onChange={(e) => setSelectedUserName(e.target.value)} autoFocus={true}></Input>
                                    </label>
                                    <label className='w-full flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Sobrenome *</a>
                                        <Input disabled={true} charLimit={100} id='selectedUserSubName' type='text' value={selectedUserSubName} onChange={(e) => setSelectedUserSubName(e.target.value)}></Input>
                                    </label>
                                    <label className='w-full flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                        <Input disabled={false} charLimit={255} id='selectedUserEmail' type='e-mail' value={selectedUserEmail} onChange={(e) => { validateEmail(e.target.value); setSelectedUserEmail(e.target.value) }}></Input>
                                    </label>
                                    <label className='w-full flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Data de nascimento *</a>
                                        <Input disabled={false} id='selectedBirthDay' value={selectedBirthDay} type={'date'} onChange={(e) => { setSelectedBirthDay(e.target.value) }}></Input>
                                    </label>
                                    {
                                        selectedTypeAccesss.id === 3 &&
                                        <label className='relative flex flex-col gap-1 items-start justify-center'>
                                            <a className='text-sm text-inputPlaceholderLight'>Permissão *</a>
                                            <InputAutoComplete
                                                disabled={!userPermitted && true}
                                                data={permissionGroupsData}
                                                preSelectedValue={selectedPermissionGroup?.description}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                                onChange={value => { setSelectedPermissionGroup(value) }}
                                                id='selectedPermissionGroup'
                                            />
                                        </label>
                                    }
                                    <LabelInput text={'Genero'}>
                                        <InputAutoComplete
                                            data={sexTypes}
                                            disabled={userPermitted && selectedUserGender?.id === undefined ? false : !userPermitted ? true : false}
                                            preSelectedValue={selectedUserGender?.name}
                                            selectedLabel={'name'}
                                            optionList={['id', 'name']}
                                            placeHolder=' - '
                                            onChange={e => setSelectedUserGender(e)}
                                            id='selectedUserGender'
                                        />
                                    </LabelInput>
                                    <label className='w-full flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>CPF</a>
                                        <Input disabled={true} charLimit={14} id='selecteduserCpf' type='text' value={mCPF(selecteduserCpf)} onChange={(e) => setSelectedUserCpf(e.target.value)}></Input>
                                    </label>
                                    <LabelInput text={'Tipo de acesso *'}>
                                        <InputAutoComplete
                                            data={typeAccessList}
                                            inactive={true}
                                            preSelectedValue={selectedTypeAccesss?.description}
                                            selectedLabel={'description'}
                                            optionList={['description']}
                                            onChange={selectedTypeAccess => setSelectedTypeAccess(selectedTypeAccess)}
                                            id='selectedTypeAccesss'
                                        />
                                    </LabelInput>
                                    <div className='flex flex-col items-start justify-start w-full'>
                                        <div className='flex flex-row gap-3 '>
                                            <LabelInput text={'DDD'}>
                                                <Input disabled={!userPermitted && true} id='selectedDDDCell' charLimit={2} width={'12'} type={'text'} value={selectedDDDCell} onChange={(e) => setSelectedDDDCell(e.target.value)}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Celular'}>
                                                <Input disabled={!userPermitted && true} id='selectedMobilePhone' charLimit={9} width={screenX > 640 ? '80' : '64'} type={'text'} value={selectedMobilePhone} onChange={(e) => setSelectedMobilePhone(e.target.value)}></Input>
                                            </LabelInput>
                                        </div>
                                    </div>
                                    <div className='flex flex-col items-start justify-start w-full'>
                                        <div className='flex flex-row gap-3'>
                                            <LabelInput text={'DDD'}>
                                                <Input disabled={!userPermitted && true} id='selectedDDDPhone' charLimit={2} width={'12'} type={'text'} onChange={(e) => setSelectedDDDPhone(e.target.value)} value={selectedDDDPhone}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Telefone'}>
                                                <Input
                                                    disabled={!userPermitted && true}
                                                    id='selectedPhone'
                                                    charLimit={9}
                                                    width={screenX > 640 ? '80' : '64'}
                                                    type={'text'}
                                                    onChange={(e) => setSelectedPhone(e.target.value)}
                                                    value={selectedPhone ? maskPhone(String(selectedPhone)) : ''}
                                                ></Input>
                                            </LabelInput>
                                        </div>
                                    </div>
                                    <div className='flex flex-col items-start justify-start w-full'>
                                        <div className='flex flex-row gap-4 items-end justify-start'>
                                            <LabelInput text={!isSettingNewPassword ? 'Senha' : 'Senha atual'}>
                                                <Input disabled={!isSettingNewPassword ? true : false} id='oldPassword' width={60} type={'password'} value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}></Input>
                                            </LabelInput>
                                            <LabelInput>
                                                <Button
                                                    onClick={() => {
                                                        if (isSettingNewPassword) {
                                                            cleanPass()
                                                        }
                                                        setIsSettingNewPassword(!isSettingNewPassword)
                                                    }}>
                                                    {isSettingNewPassword ? 'Cancelar' : 'Alterar senha'}
                                                </Button>
                                            </LabelInput>
                                        </div>
                                    </div>
                                    {
                                        isSettingNewPassword &&
                                        <>
                                            <LabelInput text={'Nova senha'}>
                                                <Input id='newPassord' type={'password'} width={screenX < 640 ? 80 : ''} value={newPassord} onChange={(e) => setNewPassord(e.target.value)}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Confirmar nova senha'}>
                                                <Input onBlur={() => checkPassConfrmation()} width={screenX < 640 ? 80 : ''} id='newPassordConfirmation' type={'password'} value={newPassordConfirmation} onChange={(e) => setNewPassordConfirmation(e.target.value)}></Input>
                                            </LabelInput>
                                        </>
                                    }
                                </form>
                                <div className='flex flex-col mt-10 gap-2 w-full items-start justify-start'>
                                    <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Minha carteira</a>
                                    <div className="w-96 h-56 hidden sm:flex flex-col gap-8 items-start justify-start rounded-lg shadow-lg bg-gradient-to-r from-primaryDefaultLight to-secondaryDefaultLight p-2">
                                        <div className="mb-2">
                                            <QRCode size={60} value={`http://${userData[0]?.customersData?.code}.agendarapido.com/#/validUser/${selectedUserId}/${selectedTypeAccesss?.id}`} />
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <div className='flex gap-1 items-start justify-start'>
                                                <a className='text-xs font-semibold text-white'>Nome:</a>
                                                <a className="text-white text-xs">{`${selectedUserName} ${selectedUserSubName}`}</a>
                                            </div>
                                            <div className='flex gap-1 items-start justify-start'>
                                                <a className='text-xs font-semibold text-white'>CPF:</a>
                                                <div className='flex'>
                                                    <a className="text-white text-xs">{!selecteduserCpf ? 'Não informado' : selecteduserCpf}</a>
                                                </div>
                                            </div>
                                            <div className='flex gap-1 items-start justify-start'>
                                                <a className='text-xs font-semibold text-white'>Data de nascimento:</a>
                                                <div className='flex'>
                                                    <a className="text-white text-xs">{selectedBirthDay?.length === 0 ? 'Não informado' : formattTimeToShow(selectedBirthDay)}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={() => setShowMemberShipCard(true)} className='cursor-pointer w-full sm:flex hidden gap-2 mt-6 flex-row items-center justify-start'>
                                        <FaPrint className='text-3xl text-primaryDefaultLight' />
                                        <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Imprimir carteira digital</a>
                                    </div>
                                </div>
                                <div id='memberShipCard' style={{ display: 'none' }}>
                                    <div style={
                                        {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            border: '1px solid gray',
                                            borderRadius: '10px',
                                            gap: '10px',
                                            width: '600px',
                                            height: '300px',
                                            padding: '20px'
                                        }} className="w-96 h-56">
                                        <div className="mb-2">
                                            <QRCode size={100} value={`http://${userData[0]?.customersData?.code}.agendarapido.com/#/validUser/${selectedUserId}/${selectedTypeAccesss?.id}`} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-between' }}>
                                            <div>
                                                <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'start', gap: '5px' }} >
                                                    <a style={{ fontSize: '16px' }}>Nome:</a>
                                                    <a style={{ fontSize: '16px' }}>{`${selectedUserName} ${selectedUserSubName}`}</a>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'start', gap: '5px' }} >
                                                    <a style={{ fontSize: '16px' }}>CPF:</a>
                                                    <div className='flex'>
                                                        <a style={{ fontSize: '16px' }}>{!selecteduserCpf ? 'Não informado' : selecteduserCpf}</a>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'start', gap: '5px' }} >
                                                    <a style={{ fontSize: '16px' }}>Data de nascimento:</a>
                                                    <div className='flex'>
                                                        <a style={{ fontSize: '16px' }}>{selectedBirthDay?.length === 0 ? 'Não informado' : formattTimeToShow(selectedBirthDay)}</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <a style={{ fontSize: '16px' }}>Associação {userData[0]?.customersData?.name}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button
                        id='cancelUserEdit'
                        approval={false}
                        shadow={true}
                        onClick={() => { history.back(); setShowNotificationModal(false) }}
                    >Cancelar
                    </Button>
                    {
                        userPermitted &&
                        <Button
                            id='approvalUserEdit'
                            approval={true}
                            shadow={true}
                            onClick={() => { errorToSubmit ? handleInactveUserInfo() : handleSubmit() }}
                        >Confirmar edição
                        </Button>
                    }
                </div>
            </Footer>
        </Container>
    )
}
