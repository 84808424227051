import React, { useState } from "react";
//components
import { Container } from "../../../components/container/container";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { Body } from "../../../components/container/Body";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { Button } from "../../../components/buttons/button.default";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../components/footer/Footer";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//utils
import { responseError } from "../../../utils/responsesFunctions/error.response";
//contexts
import { useThemeContext } from "../../../contexts/themeContext";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { useAuth } from "../../../contexts/useAuth";
import { resizeImageTest } from "../../../utils/formatImages/resizeImageTest";
import { FaArrowAltCircleRight, FaArrowCircleLeft, FaPlus } from "react-icons/fa";
import { permittedFiles } from "../../../utils/permittedFiles/permittedFiles";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";


export function SiteBannerCreate() {

    const { defaultCallsSchedules, api } = useApiContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [loading, setLoading] = useState(false)
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    const [showPhoto, setShowPhoto] = useState(<a>Sem dados</a>)
    const [bannerPhotos, setBannersPhotos] = useState([])
    const [bannersAppendPhotos, setBannersAppendPhotos] = useState([])
    //selectedVariables
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [startDate, setStartDate] = useState('')
    const [finishDate, setFinishDate] = useState('')

    function mandatoryFields(status) {
        setShowNotificationModal(status)
        setShowNotificationModalSuccess(!status)
        setShowNotificationModalText('Campos obrigatórios não preenchidos!')
        if(!status){
            document.getElementById("bannerTitle").style.border = ''
            document.getElementById("bannerDescription").style.border = ''
            document.getElementById("bannerStartDate").style.border = ''
            document.getElementById("bannerFinishDate").style.border = ''
        }
    }

    async function handleSubmit() {

        let apiData = {
            title,
            description,
            startDate,
            finishDate
        }

        let errors
       
        if (!startDate) {
            errors = true
            document.getElementById("bannerStartDate").style.border = '1px solid red'
            mandatoryFields(true)
        }
        if (!finishDate) {
            errors = true
            document.getElementById("bannerFinishDate").style.border = '1px solid red'
            mandatoryFields(true)
        }
        if (errors) {
            return
        }

        setLoading(true)
        try {

            const banner = await api.post(`/api/v1/siteBanners`, {
                title,
                description,
                startDate,
                finishDate
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            const bannerId = banner?.data?.data[0]?.id
            if (bannersAppendPhotos.length > 0) {

                const formData = new FormData();
                bannersAppendPhotos.map((photo) => {
                    formData.append('images', photo);
                })

                try {
                    const sendPhoto = await api.post(`/api/v1/siteBannersPictures/banners_id/${bannerId}`, formData, {
                        headers: {
                            Authorization: `Bearer ${userData[0]?.token}`,
                            'Content-Type': 'multipart/form-data',
                        }
                    })
                } catch (error) {
                    console.log(error)
                    message = 'Sucesso ao cadastrar banner, porém houve um erro ao cadastrar a foto'
                }
            }

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Banner cadastrado com sucesso! Em até 30 minutos o banner estará disponível no seu site.')
            navigate(`/siteBanners`)

        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const handleAddPhoto = () => {

        const inputPhoto = document.getElementById('photoAddInput')
        inputPhoto.click()
    }

    const handleNewPhoto = async (photoFile) => {
        const validImage = permittedFiles(photoFile?.target?.files[0], 'image')
        if (validImage?.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(validImage?.response)
        }

        if (bannerPhotos.length < 1) {
            const formattedImg = await new Promise((resolve, reject) => {
                resizeImageTest(photoFile, (err, resizedFile) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    resolve(resizedFile)
                })
            })

            const photoObj = URL.createObjectURL(formattedImg)
            setBannersPhotos([photoObj, ...bannerPhotos])
            setBannersAppendPhotos([formattedImg, ...bannerPhotos])
        }
    }

    const prevPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = bannerPhotos[showPhoto - 1]
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto - 1)
    }

    const nextPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = bannerPhotos[showPhoto + 1]
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto + 1)
    }

    async function showThisPhoto(photo, indexPhoto) {

        setShowPhotoModal(true)

        const modalElement = document.getElementById('showPhotoModal')

        let img = document.createElement('img')
        img.src = photo
        img.id = 'appendedPreviewPhoto'
        modalElement.appendChild(img)
        setShowPhoto(indexPhoto)
        await new Promise(resolve => setTimeout(resolve, 100))
        document.getElementById('photoContainer').style.opacity = '1'
        document.getElementById('photoContainer').style.transform = 'translateY(0px)'

    }

    async function closeThisPhoto() {

        document.getElementById('photoContainer').style.opacity = '0'
        document.getElementById('photoContainer').style.transform = 'translateY(-100px)'
        await new Promise(resolve => setTimeout(resolve, 300))

        setShowPhotoModal(false)
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)
        setShowPhoto('')
    }

    async function removeThisPhoto(photo, indexPhoto) {

        document.getElementById(photo + '-' + indexPhoto).style.transform = 'scale(.5)'
        await new Promise(resolve => setTimeout(resolve, 500))
        setBannersPhotos(bannerPhotos.filter(photoToRemove => photoToRemove !== photo))
        document.getElementById(photo + '-' + indexPhoto).style.transform = 'scale(1)'
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Novo banner'} />
            </TitlePage>
            <Body hasFooter={true}>
                {loading ?
                    <div className="w-full h-96 flex flex-col items-center justify-center">
                        <DefaultLoader />
                    </div>
                    :
                    <form onSubmit={e => e.preventDefault()} className="flex flex-row gap-4 flex-wrap">
                        <div>
                            <LabelInput text={'Titulo'}>
                                <Input id='bannerTitle' charLimit={50} onChange={(e) => {setTitle(e.target.value); mandatoryFields(false)}} value={title} />
                            </LabelInput>
                            <p className='text-xs text-inputPlaceholderLight'>{title?.length} de 50</p>
                        </div>

                        <div className="w-full">
                            <LabelInput text={'Texto'}>
                                <textarea id='bannerDescription' wrap="hard" value={description} onChange={(e) => {setDescription(e.target.value); mandatoryFields(false)}} maxLength={255} className='
                      p-2 border border-gray-400 border-opacity-75 
                      h-64
                      w-full
                      text-sm
                      outline-none transition-all duration-200 focus:shadow-borderShadow
                      dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark 
                    '>
                                </textarea>
                            </LabelInput>
                            <p className='text-xs text-inputPlaceholderLight'>{description?.length} de 255</p>

                        </div>
                        <LabelInput text={'Data início'}>
                            <Input id={'bannerStartDate'} type={'date'} onChange={(e) => {setStartDate(e.target.value); mandatoryFields(false)}} value={startDate} />
                        </LabelInput>
                        <LabelInput text={'Data fim'}>
                            <Input id={"bannerFinishDate"} type={'date'} onChange={(e) => {setFinishDate(e.target.value); mandatoryFields(false)}} value={finishDate} />
                        </LabelInput>
                        <div className="w-full flex flex-col mt-10">
                            <div className={`${showPhotoModal ? 'flex' : 'hidden'} flex-row items-center justify-center w-full h-full fixed top-0 left-0 z-50`}>
                                <div id='photoContainer' className={`opacity-0 transition-all duration-500 -translate-y-10 flex gap-2 flex-row items-center justify-center w-full h-full`}>
                                    {
                                        showPhoto > 0 ?
                                            <FaArrowCircleLeft onClick={prevPhoto} className='text-primaryDefaultLight hover:-translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                            :
                                            <FaArrowCircleLeft className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                    }
                                    <div className='bg-bgPrimaryLight dark:bg-primaryDefaultDark boxShadow p-8 w-full h-full md:w-[700px] md:h-[500px] lg:w-[1000px] lg:h-[600px] flex flex-col items-center justify-start'>
                                        <div className='flex flex-row justify-end w-full'>
                                            <button className='bg-primaryDefaultLight hover:brightness-125 transition-all duration-200 h-8 w-8 rounded-full flex flex-col items-center justify-center text-titleGrayTextLight' onClick={() => closeThisPhoto()}>X</button>
                                        </div>
                                        <div id='showPhotoModal' className='flex flex-col items-center justify-center overflow-auto w-full h-full'>
                                        </div>
                                    </div>
                                    {
                                        showPhoto < bannerPhotos.length - 1 ?
                                            <FaArrowAltCircleRight onClick={nextPhoto} className='text-primaryDefaultLight hover:translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                            :
                                            <FaArrowAltCircleRight className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                    }
                                </div>
                            </div>
                            <div className={`${showPhotoModal ? 'flex' : 'hidden'}  bg-gray-600 opacity-80 w-full h-full fixed z-40 left-0 top-0`}></div>
                            <div id='bannerPhotos' className='flex flex-col gap-4 items-center justify-start'>
                                <div className='flex flex-col items-start justify-start w-full mb-5'>
                                    <p className='text-xl text-primaryDefaultLight'>Foto do banner</p>
                                </div>
                                <div className='flex flex-row gap-2 items-start justify-start w-full'>
                                    <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Foto:</p>
                                    <input id='photoAddInput' type='file' onChange={handleNewPhoto} accept="image/png,image/jpeg,image/jpg,image/gif,image/jpeg" className='hidden'></input>
                                    <a className='text-titleBlackTextLight dark:text-titleGrayTextDark'>{bannerPhotos.length}</a>
                                    <Tippy content={'Adicionar fotos'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='right'
                                        delay={100}>
                                        <div>
                                            <FaPlus onClick={handleAddPhoto} className='text-xl text-primaryDefaultLight cursor-pointer' />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='h-20 w-full'>
                                    <div className='flex flex-row gap-4 items-center justify-start'>
                                        {
                                            bannerPhotos.map((photo, indexPhoto) => {
                                                return <div key={photo + '-' + indexPhoto} id={photo + '-' + indexPhoto} className='cursor-pointer transition-all duration-500 ease-in-out relative flex flex-row gap-2 items-center justify-center'>
                                                    <Tippy content={'Remover foto'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='right'
                                                        delay={300}>
                                                        <button onClick={() => removeThisPhoto(photo, indexPhoto)} className='text-titleGrayTextLight absolute -left-2 -top-2 w-10 h-10 sm:w-6 sm:h-6 rounded-full bg-gray-400'>x</button>
                                                    </Tippy>
                                                    <img onClick={() => showThisPhoto(photo, indexPhoto)} className='border border-gray-500 rounded-xl w-20 h-20' src={photo}></img>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                }
            </Body>
            <Footer>
                <div className="flex h-full flex-row w-full items-center justify-between">
                    <Button shadow={true} approval={false} onClick={() => navigate('/siteBanners')}>Voltar</Button>
                    <Button module={"siteBanners:create"} userData={userData} shadow={true} onClick={() => handleSubmit()}>Salvar</Button>
                </div>
            </Footer>
        </Container>
    )
}