// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// components
import { Body } from '../../../components/container/Body'
import { TableDefault } from '../../../components/table/table.default'
import { Container } from '../../../components/container/container'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { LabelInput } from '../../../components/label/label.input'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Toogle } from '../../../components/toogle/Toogle'
import { Footer } from '../../../components/footer/Footer'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'
// services 
//utils
import { validateEmail } from '../../../utils/validators/email.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { removeEspecialChar } from '../../../utils/validators/removeEspecialCharacter'
import { maskCpf } from '../../../utils/mask/maskCpf'
import { maskPhone } from '../../../utils/mask/maskPhone'
import { FaEdit, FaMinus, FaInfo, FaPlus } from 'react-icons/fa'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'
import { maxNumber } from '../../../utils/mask/maxNumber'

export function ClientEdit() {
    const { defaultCallsAuth, defaultCallsFinancial, api_auth, api_financial } = useApiContext()
    const { userData } = useAuth()
    const { screenX } = useScreenSizeContext()
    const [errorDependents, setErrorDependents] = useState(false)
    const [showActivateUserAccount, setShowActivateUserAccount] = useState(false)
    const navegate = useNavigate()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const { id } = useParams()
    const [activeTab, setActiveTab] = useState(1)

    const [loading, setLoading] = useState(false)
    const [dependentsErrorList, setDependentsErrorList] = useState([])
    const [updatedUser, setUpdatedUser] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [typeAccessList, setTypeAccessList] = useState([])
    const [associationsList, setAssociationsList] = useState([])
    const [companiesList, setCompaniesList] = useState([])
    const [permissionGroupsData, setPermissionsGroupsData] = useState([])
    const [thisUserData, setThisUserData] = useState([])
    const [userActive, setUserActive] = useState(true)
    const [userDependents, setUserDependents] = useState({})
    const [newDependent, setNewDependent] = useState({})
    const [showNewDependent, setShowNewDependent] = useState(false)
    const [newDependentId, setNewDependentId] = useState('')
    const [userDependentsKeys, setUserDependentsKeys] = useState([])
    const [userPermitted, setUserPermiited] = useState(userData[0]?.permissions?.indexOf('users:update') > -1 || userData[0]?.typeAccess_id < 3 ? true : false)

    //selectedVariables
    const [selectedTypeAccesss, setSelectedTypeAccess] = useState('')
    const [selectedPermissionGroup, setSelectedPermissionGroup] = useState('')
    const [selectedUserName, setSelectedUserName] = useState('')
    const [selectedUserSubName, setSelectedUserSubName] = useState('')
    const [selectedUserEmail, setSelectedUserEmail] = useState('')
    const [selectedBirthDay, setSelectedBirthDay,] = useState('')
    const [selectedDDDPhone, setSelectedDDDPhone] = useState('')
    const [selectedUserGender, setSelectedUserGender] = useState('')
    const [selectedDDDCell, setSelectedDDDCell] = useState('')
    const [selectedMobilePhone, setSelectedMobilePhone,] = useState('')
    const [selectedPhone, setSelectedPhone,] = useState('')
    const [selecteduserCpf, setSelectedUserCpf] = useState('')
    const [selectedAssociate, setSelectedAssociate] = useState('')
    const [userAccountActivated, setUserAccountActivated] = useState(true)
    const [changePassWord, setChangePassword] = useState(false)
    const [requireRegistrationCode, setRequireRegistrationCode] = useState(false)
    const [registrationCode, setRegistrationCode] = useState('')
    const [selectedCompany, setSelectedCompany] = useState('')
    const [salary, setSalary] = useState(0)
    const [controlAgreementsBalance, setControlAgreementsBalance] = useState(false)
    const [agreementsBalance, setAgreementsBalance] = useState(0)

    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        }
    ]

    const theadDependents = [
        {
            "name": 'Nome',
            "original_name": 'fullName'
        },
        {
            "name": 'Grau de parentesco',
            "original_name": 'degreeOfKinship'
        },
        {
            "name": 'Usuario vinculado',
            "original_name": "user_references_name"
        }
    ]

    // config financial
    const [configFinancial, setConfigFinancial] = useState({
        useDefaultAssociationConfigBalance: false,
        useFinancialUsersBalance: false,
        useAutoRenewBalance: false,
        autoRenewPeriod: '',
        autoRenewMonth: '',
        autoRenewDay: '',
        autoRenewResetBalance: false,
        balanceType: '',
        balanceValue: ''
    })

    const boolOptions = [
        { value: true, label: "Sim" },
        { value: false, label: "Não" },
    ]

    const renewPeriodOptions = [
        { value: 'month', label: 'Mensal' },
        { value: 'year', label: 'Anual' },
    ]

    const balanceTypeOptions = [
        { value: "fixed", label: "Fixo" },
        { value: "percentageOfSalary", label: "Porcentagem do salário" },
    ]

    useEffect(() => {
        setShowUniversalModal(false)
        setLoading(true)

        async function getData() {
            const typeAccessData = await defaultCallsAuth.getTypeAccess(userData[0].token)

            if (typeAccessData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(typeAccessData.data)
            }
            setTypeAccessList(typeAccessData.data)

            const userDataApi = await defaultCallsAuth.getUserEspecific(userData[0].token, id)
            if (userDataApi.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(userDataApi.data)
            }

            setUserPermiited(
                (userData[0]?.permissions?.indexOf('users:update') > -1 || userData[0]?.typeAccess_id < 3)
                    && (userData[0]?.typeAccess_id <= userDataApi.data[0]?.typeAccess_id)
                    && (userDataApi.data[0]?.id !== userData[0]?.id)
                    ? true : false
            )

            const thisUserTypeAccesss = typeAccessData.data.filter((typeAccess) => {
                if (typeAccess.id === userDataApi.data[0].typeAccess_id) {
                    return typeAccess
                }
            })

            const permissionGroupsData = await defaultCallsAuth.getPermissionGroups(userData[0].token)
            if (permissionGroupsData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(permissionGroupsData.data)
            }
            setPermissionsGroupsData(permissionGroupsData.data)
            const thisUserPermissionGroups = permissionGroupsData.data.filter((permissionGroups) => {
                if (permissionGroups.id === userDataApi.data[0].permissionGroups_id) {
                    return permissionGroups
                }
            })

            const customersDataAPI = await defaultCallsAuth.getCustomers(userData[0].token)
            if (customersDataAPI.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(customersDataAPI.data)
            }

            const thiUserGender = sexTypes.filter((gender) => {
                if (gender.id === userDataApi.data[0].gender) {
                    return gender
                }
            })

            const associationsGet = await defaultCallsAuth.getAssociations(userData[0]?.token, 1)
            if (associationsGet.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(associationsGet.data)
                return
            }

            const userDependentsAPI = await defaultCallsAuth.getUserDependentsByUsersId(userData[0]?.token, userDataApi.data[0]?.id)
            if (userDependentsAPI.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(userDependentsAPI.data)
                return
            }

            const objetoFormatado = {};

            userDependentsAPI.data.forEach((item, index) => {
                objetoFormatado[index] = { ...item };
            });

            const companiesData = await defaultCallsAuth.getCompanies(userData[0].token)
            if (companiesData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(companiesData.data)
            }

            if (userData[0]?.customersData?.modules?.map(mod => mod?.id).includes(3)) {

                const financialUserData = await defaultCallsFinancial.getFinancialUsersPerId(userData[0]?.token, id)
                if (financialUserData.error) {
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText(financialUserData.data)
                }
                if (financialUserData?.data?.length > 0) {
                    setConfigFinancial({
                        autoRenewDay: financialUserData?.data[0]?.autoRenewDay,
                        autoRenewMonth: financialUserData?.data[0]?.autoRenewMonth,
                        autoRenewPeriod: financialUserData?.data[0]?.autoRenewPeriod,
                        autoRenewResetBalance: financialUserData?.data[0]?.autoRenewResetBalance,
                        balanceType: financialUserData?.data[0]?.balanceType,
                        balanceValue: financialUserData?.data[0]?.balanceValue,
                        useAutoRenewBalance: financialUserData?.data[0]?.useAutoRenewBalance,
                        useDefaultAssociationConfigBalance: financialUserData?.data[0]?.useDefaultAssociationConfigBalance,
                        useFinancialUsersBalance: financialUserData?.data[0]?.useFinancialUsersBalance,
                    })
                }
            }


            setCompaniesList(companiesData.data)
            setSelectedCompany(
                companiesData.data.length > 0 ?
                    companiesData.data.find(company => company.id === userDataApi.data[0]?.companies_id) : ''
            )

            setUserDependents(objetoFormatado)
            setUserDependentsKeys(Object.keys(objetoFormatado))
            setThisUserData(userDataApi.data[0])
            setSelectedUserName(userDataApi.data[0].firstName)
            setSelectedUserSubName(userDataApi.data[0].lastName)
            setSelectedUserEmail(userDataApi.data[0].email)
            setSelectedTypeAccess(thisUserTypeAccesss[0])
            setSelectedPermissionGroup(thisUserPermissionGroups[0])
            setSelectedBirthDay(userDataApi.data[0].birthday?.indexOf('T') > -1 ? userDataApi.data[0].birthday.slice(0, userDataApi.data[0].birthday.indexOf('T')) : userDataApi.data[0].birthday)
            setSelectedUserGender(thiUserGender[0])
            setSelectedUserCpf(userDataApi.data[0].cpf)
            setSelectedDDDCell(userDataApi.data[0].dddcell)
            setSelectedMobilePhone(userDataApi.data[0].cell)
            setSelectedDDDPhone(userDataApi.data[0].dddphone)
            setSelectedPhone(userDataApi.data[0].phone)
            setUserActive(userDataApi.data[0]?.active)
            setUserAccountActivated(userDataApi.data[0]?.usersActivationActive === false ? false : true)
            setSelectedAssociate(associationsGet.data?.find(association => association.id === userDataApi.data[0].associations_id))
            setSalary(userDataApi.data[0]?.salary)
            setControlAgreementsBalance(userDataApi.data[0]?.controlAgreementsBalance)
            setAgreementsBalance(userDataApi.data[0]?.agreementsBalance)
            setRequireRegistrationCode(associationsGet.data.filter(association => {
                return association.id == userDataApi.data[0]?.associations_id
            })[0]?.requireRegistrationCode)
            setRegistrationCode(userDataApi.data[0]?.registrationCode)
            if (!customersDataAPI?.data?.openToCommunity) {
                setAssociationsList(associationsGet.data.filter(association => association.id !== 1))
            } else {
                setAssociationsList(associationsGet.data)
            }
            setLoading(false)
        }
        getData()

    }, [updatedUser])

    async function handleSubmit() {
        const fields = [
            { "name": 'selectedUserName', "value": selectedUserName, "required": true, "type": 'string' },
            { "name": 'selectedUserSubName', "value": selectedUserSubName, "required": true, "type": 'string' },
            { "name": 'selectedUserEmail', "value": selectedUserEmail, "required": true, "type": 'string' },
            { "name": 'selectedBirthDay', "value": selectedBirthDay, "required": true, "type": 'string' },
            { "name": 'selectedTypeAccesss', "value": selectedTypeAccesss, "required": true, "type": 'string' },
            { "name": 'selectedPermissionGroup', "value": selectedPermissionGroup, "required": selectedTypeAccesss.id === 3 ? true : false, "type": 'object' },
            { "name": 'selectedAssociate', "value": selectedAssociate, "required": true, "type": 'object' },
        ]
        const configFinancialFields = []
        let currentTab = activeTab

        if (selectedAssociate?.isMonthlyPayer) fields.push({ "name": 'salary', "value": salary, "required": true, "type": 'value' })
        if (requireRegistrationCode) {
            fields.push({ "name": 'registrationCode', "value": registrationCode, "required": false, "type": 'string' })
        }
        if (userData[0].customersData?.requireCpf) {
            fields.push({ "name": 'selecteduserCpf', "value": selecteduserCpf, "required": true, "type": 'string' })
        }

        if (configFinancial?.useFinancialUsersBalance && configFinancial.balanceType == 'percentageOfSalary') {
            fields.push({ "name": 'selectedSalary', "value": salary, "required": true, "type": 'number' })
        }

        if (currentTab === 1) {
            if (validateFields(fields)?.length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalText('Campos obrigatórios não preenchidos na aba de "Dados"!')
                return setShowNotificationModalSuccess(false)
            }
        }

        if (selectedUserName && selectedUserName?.length < 2) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Verifique o nome, deve ser nome inteiro')
        }

        if (selectedUserSubName && selectedUserSubName?.length < 2) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Verifique o sobrenome, deve ser nome inteiro')
        }

        if (userData[0]?.customersData?.modules?.map(mod => mod?.id).includes(3)) {
            if (!configFinancial?.useDefaultAssociationConfigBalance && configFinancial?.useFinancialUsersBalance) {
                configFinancialFields.push(...[
                    { "name": 'autoRenewDay', "value": configFinancial?.autoRenewDay, "required": configFinancial?.useAutoRenewBalance ? true : false, "type": 'string' },
                    { "name": 'balanceValue', "value": configFinancial?.balanceValue, "required": true, "type": 'string' },
                    { "name": 'balanceType', "value": configFinancial?.balanceValue, "required": true, "type": 'string' },
                    { "name": 'autoRenewPeriod', "value": configFinancial?.autoRenewPeriod, "required": configFinancial.useAutoRenewBalance ? true : false, "type": 'string' },
                ])
            }
            if (configFinancial?.autoRenewPeriod === "year" && configFinancial.autoRenewResetBalance) {
                configFinancialFields.push({ "name": 'autoRenewMonth', "value": configFinancial?.autoRenewMonth, "required": true, "type": 'string' },)
            }
            if (configFinancial?.balanceType === "percentageOfSalary" && Number(configFinancial?.balanceValue) > 100) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('A porcentagem não pode ser maior do que 100, verifique o campo Valor do saldo: (%) * na aba "Financeiro"')
            }

            if (configFinancialFields?.length > 0) {
                setActiveTab(2)
                currentTab = 2
            }

            if (currentTab === 2) {
                if (validateFields(configFinancialFields)?.length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos da aba "Financeiro"')
                }
            }
        }

        setLoading(true)
        try {
            await api_auth.put(`/api/v1/auth/users/${id}`, {
                firstName: selectedUserName ? selectedUserName : undefined,
                lastName: selectedUserSubName ? selectedUserSubName : undefined,
                birthday: selectedBirthDay ? selectedBirthDay : undefined,
                gender: selectedUserGender ? selectedUserGender.id : undefined,
                companies_id: selectedCompany?.id ? selectedCompany?.id : undefined,
                dddphone: selectedDDDPhone ? selectedDDDPhone : undefined,
                dddcell: selectedDDDCell ? selectedDDDCell : undefined,
                phone: selectedPhone ? selectedPhone : undefined,
                cell: selectedMobilePhone ? selectedMobilePhone : undefined,
                cpf: selecteduserCpf ? removeEspecialChar(selecteduserCpf) : undefined,
                email: selectedUserEmail ? selectedUserEmail : undefined,
                password: undefined,
                typeAccess_id: selectedTypeAccesss ? selectedTypeAccesss.id : undefined,
                permissionGroups_id: selectedPermissionGroup ? selectedPermissionGroup.id : undefined,
                associations_id: selectedAssociate.id,
                registrationCode: registrationCode ? registrationCode : undefined,
                salary: salary > 0 ? salary : undefined,
                controlAgreementsBalance,
                agreementsBalance
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            if(userData[0]?.customersData?.modules?.map(mod => mod?.id).includes(3)){
                const financialUserBodyData = {
                    useDefaultAssociationConfigBalance: configFinancial?.useDefaultAssociationConfigBalance,
                    useFinancialUsersBalance: configFinancial?.useFinancialUsersBalance,
                }
                if (financialUserBodyData.useFinancialUsersBalance) {
                    financialUserBodyData.useAutoRenewBalance = configFinancial?.useAutoRenewBalance,
                        financialUserBodyData.autoRenewPeriod = configFinancial?.autoRenewPeriod || undefined
                    financialUserBodyData.autoRenewMonth = configFinancial?.autoRenewMonth ? Number(configFinancial.autoRenewMonth) : undefined
                    financialUserBodyData.autoRenewDay = configFinancial?.autoRenewDay ? Number(configFinancial.autoRenewDay) : undefined
                    financialUserBodyData.autoRenewResetBalance = configFinancial?.autoRenewResetBalance
                    financialUserBodyData.balanceType = configFinancial?.balanceType || undefined
                    financialUserBodyData.balanceValue = configFinancial?.balanceValue ? Number(configFinancial.balanceValue) : undefined
                }
    
                await api_financial.put(`/api/v1/financial/users/${id}`, financialUserBodyData, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
            }


            setShowNotificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            setLoading(false)
            navegate('/clients')
        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                if (error?.status === 409 || error?.response?.status === 400) {
                    return setShowNotificationModalText(responseError(error))
                }
                if (activeTab === 1) {
                    return setShowNotificationModalText('Verifique os campos informados na aba "Financeiro".')
                } else {
                    return setShowNotificationModalText('Verifique os campos informados na aba "Dados".')
                }
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDeleteClient() {

        setLoading(true)
        const deleteClient = await defaultCallsAuth.deleteUserEspecific(userData[0]?.token, id)

        if (deleteClient.error) {
            setLoading(false)
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(deleteClient.data)
            return
        }
        setShowNotificationModal(true)
        setShowNotificationModalSuccess(true)
        setShowNotificationModalText('Cliente removido com sucesso!')
        setShowUniversalModal(false)
        navegate('/clients')
        setLoading(true)

    }

    async function handleResendPassword() {
        if (!selectedUserEmail) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowUniversalModal(false)
            return setShowNotificationModalText('Não existe e-mail cadastrado, cadastre umm e-mail válido para este cliente para que possa receber a nova senha')
        }
        setLoading(true)
        setShowNotificationModal(false)
        try {

            await api_auth.put(`/api/v1/auth/users/${id}/resendAccess`, {}, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setChangePassword(false)
            setShowUniversalModal(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(`Senha reenviada com sucesso para o e-mail: ${selectedUserEmail}`)
            setShowNotificationModalSuccess(true)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleInactiveUser() {

        setUserActive(!userActive)
        try {

            await api_auth.patch(`/api/v1/auth/users/${id}/active/${thisUserData?.active ? 0 : 1}`, {

            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText(`Status alterado com sucesso!`)
            navegate(`/clients`)
        } catch (error) {
            setUserActive(!userActive)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function updateUserDependents() {
        try {
            setLoading(true)
            if (userDependentsKeys?.length > 0) {

                const dependents = userDependentsKeys?.map(dependent => {
                    let cpf = newDependent[dependent]?.cpf?.length === 0 || !newDependent[dependent]?.cpf ? undefined : newDependent[dependent]?.cpf
                    return {
                        birthday: newDependent[dependent].birthday,
                        cpf: cpf,
                        degreeOfKinship: newDependent[dependent].degreeOfKinship,
                        fullName: newDependent[dependent].fullName,
                        users_id: id
                    }
                })
                await api_auth.post(`/api/v1/auth/usersDependents`, {
                    dependents
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })

                setShowNotificationModal(true)
                setShowNotificationModalSuccess(true)
                setShowNotificationModalText('Dependentes atualizados com sucesso!')
                setLoading(false)
            } else {
                await api_auth.delete(`/api/v1/auth/usersDependents/users_id/${id}`, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(true)
                setShowNotificationModalText('Dependentes atualizados com sucesso!')
                setLoading(false)
            }
        } catch (error) {
            if (error?.response?.data?.statusCode === 403) {
                setErrorDependents(true)
                setShowUniversalModal(true)
                setDependentsErrorList(error?.response?.data?.details[0])
                setLoading(false)
                return
            }
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function activateUserAccount() {

        setLoading(true)
        try {
            await api_auth.put(`/api/v1/auth/users/${thisUserData.id}/activateAccount`, {}, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setUserAccountActivated(true)
            setShowUniversalModal(false)
            setLoading(false)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function handleNewDependent() {
        setShowNotificationModal(false)
        if (Object.keys(userDependents).length === 0) {
            let id = uuid()
            setNewDependentId(id)
            setNewDependent(prev => ({
                [id]: {
                    fullName: '',
                    cpf: '',
                    birthday: '',
                    degreeOfKinship: ''
                }
            }))
            setUserDependentsKeys(prev => (Object.keys({
                [id]: {
                    fullName: '',
                    cpf: '',
                    birthday: '',
                    degreeOfKinship: ''
                }
            })))
        } else {
            setNewDependent(userDependents)
        }
        setShowNewDependent(true)
    }
    //Exibe a edicao de dependentes
    function handleChangeShowNewDependentView() {
        setShowNotificationModal(false)
        setUserDependentsKeys(Object.keys(userDependents))
        setShowNewDependent(false)
    }
    //salva a listagem para envio na API
    async function handleConfirmNewDependents() {

        if (validDependentFields()) {
            setNewDependentId('')
            setUserDependents(newDependent)
            setShowNewDependent(false)
            await updateUserDependents()
        }
    }
    //funcao para validar os campos dos dependentes
    function validDependentFields() {
        const list = Object.keys(newDependent)
        let validFields = true

        if (list?.length > 0) {
            list.map(key => {
                if (!newDependent[key]?.fullName || newDependent[key]?.fullName?.trim() === '' || newDependent[key]?.fullName?.length <= 3) {
                    document.getElementById(`${key}-fullName`).style.border = '1px solid red'
                    validFields = false
                } else {
                    document.getElementById(`${key}-fullName`).style.border = ''
                }
                if (!newDependent[key]?.birthday) {
                    document.getElementById(`${key}-birthday`).style.border = '1px solid red'
                    validFields = false
                } else {
                    document.getElementById(`${key}-birthday`).style.border = ''
                }
                if (!newDependent[key]?.degreeOfKinship) {
                    document.getElementById(`${key}-degreeOfKinship`).style.border = '1px solid red'
                    validFields = false
                } else {
                    document.getElementById(`${key}-degreeOfKinship`).style.border = ''
                }
            })
        }

        if (!validFields) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Campos obrigatorios do dependente não preenchido')
            return false
        }
        setShowNotificationModal(false)
        return true
    }
    //adiciona dependente na listagem (em edicao)
    async function handleNewDependentInList() {

        let id = uuid()
        if (validDependentFields()) {
            setNewDependent({
                ...newDependent, [id]: {
                    fullName: '',
                    cpf: '',
                    birthday: '',
                    degreeOfKinship: ''
                }
            })
            setUserDependentsKeys(Object.keys({
                ...newDependent, [id]: {
                    fullName: '',
                    cpf: '',
                    birthday: '',
                    degreeOfKinship: ''
                }
            }))
        }
    }
    //remove o dependente da lista
    function handleRemoveDependent(key) {
        let dependentsToRemove = structuredClone(newDependent)
        delete dependentsToRemove[key]
        setNewDependent({ ...dependentsToRemove })
        setUserDependentsKeys(Object.keys(dependentsToRemove))
    }

    function handleActiveUserAccount() {

        setShowActivateUserAccount(true)
        setShowUniversalModal(true)
    }

    return (
        <>
            {
                errorDependents ?
                    <ScreenModal width={screenX > 640 ? screenX - 200 : screenX} height={500} title={'Erro dependentes'} visible={showModal}>
                        <div className='w-full flex flex-col items-center justify-center p-4 gap-4'>
                            <p className='text-sm text-center text-titleBlackTextLight'>Erro ao cadastrar dependentes, detalhes:</p>
                            <p className='text-sm text-center text-red-700'>Nenhum dependente foi cadastrado</p>
                            <p className='text-sm text-center text-titleBlackTextLight'>Detalhes</p>
                            <div id='clientsErrorDependentsTableContent' className='flex flex-col items-center justify-center w-full boxShadow overflow-y-auto rounded-lg'>
                                <TableDefault
                                    data={dependentsErrorList}
                                    title={theadDependents}
                                    collumns={["fullName", "degreeOfKinship", "user_references_name"]}
                                />
                            </div>
                        </div>
                    </ScreenModal>
                    :
                    showActivateUserAccount ?
                        <ScreenModal height={200} title={changePassWord ? 'Alteração de senha' : 'Exlusão de usuário'} visible={showModal}>
                            <div className='w-full h-96 flex flex-col items-center justify-center p-4'>
                                <p className='text-sm text-center text-titleBlackTextLight'>Deseja realmente ativar a conta deste cliente: {selectedUserName} ?</p>
                                <div className='flex flex-row gap-1 mt-10'>
                                    <Button id='modalConfirmUsersEdit' shadow={true} onClick={() => activateUserAccount()}>Sim</Button>
                                    <Button id='modalCancelUsersEdit' shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                                </div>
                            </div>
                        </ScreenModal>
                        :

                        <ScreenModal height={200} title={changePassWord ? 'Alteração de senha' : 'Exlusão de usuário'} visible={showModal}>
                            {
                                changePassWord ?
                                    <div className='w-full h-96 flex flex-col items-center justify-center p-4'>
                                        <p className='text-sm text-center text-titleBlackTextLight'>Deseja realmente gerar uma nova senha para o usuário {selectedUserName} ?</p>
                                        <div className='flex flex-row gap-1 mt-10'>
                                            <Button id='modalConfirmUsersEdit' shadow={true} onClick={() => handleResendPassword()}>Sim</Button>
                                            <Button id='modalCancelUsersEdit' shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                                        </div>
                                    </div>
                                    :
                                    <div className='w-full h-96 flex flex-col items-center justify-center p-4'>
                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextLight'>Deseja realmente excluir o cliente {selectedUserName} ?</p>
                                        <div className='flex flex-row gap-1 mt-10'>
                                            <Button shadow={true} onClick={() => handleDeleteClient()} >Sim</Button>
                                            <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                                        </div>
                                    </div>
                            }
                        </ScreenModal>
            }
            <Container>
                <TitlePage >
                    <div className='w-full flex items-center justify-between'>
                        <Title text={`Editar usuário - ${selectedUserName} `} />
                        {
                            userAccountActivated === false ?
                                <Button onClick={() => handleActiveUserAccount()} shadow={true}>Ativar conta do usário</Button>
                                :
                                <></>
                        }

                    </div>

                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex w-full h-full flex-col justify-center items-center'>
                                <DefaultLoader></DefaultLoader>
                            </div>
                            :
                            <div className='flex flex-col items-start justify-start w-full'>
                                {
                                    userData[0]?.customersData?.modules?.map(mod => mod?.id).includes(3) &&
                                    <div className='w-1/4 h-8 flex flex-row items-start justify-start border border-gray-300/50 mb-6'>
                                        <a
                                            onClick={() => setActiveTab(1)}
                                            className={`
                                                    cursor-pointer transition-all ease-in duration-200 text-sm
                                                    w-1/2 h-full ${activeTab === 1 ? 'bg-primaryDefaultLight text-white hover:brightness-110' : 'bg-transparent text-titleBlackTextLight hover:brightness-75'} 
                                                    font-semibold flex flex-col items-center justify-center
                                                `}
                                        >
                                            Dados
                                        </a>
                                        <a
                                            onClick={() => setActiveTab(2)}
                                            className={`
                                                    cursor-pointer transition-all ease-out duration-200 text-sm
                                                    w-1/2 h-full ${activeTab === 2 ? 'bg-primaryDefaultLight text-white hover:brightness-110' : 'bg-transparent text-titleBlackTextLight hover:brightness-75'} 
                                                    font-semibold flex flex-col items-center justify-center
                                                `}
                                        >
                                            Financeiro
                                        </a>
                                    </div>
                                }
                                {
                                    activeTab === 1 ?
                                        <>
                                            <form onSubmit={e => e.preventDefault()} className='flex flex-col items-center justify-center sm:grid sm:grid-cols-1 md:grid-cols-2 gap-4'>
                                                <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                                    <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                                    <Input charLimit={30} id='selectedUserName' type='text' value={selectedUserName} onChange={(e) => setSelectedUserName(e.target.value)} autoFocus={true}></Input>
                                                </label>
                                                <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                                    <a className='text-sm text-inputPlaceholderLight'>Sobrenome *</a>
                                                    <Input charLimit={30} id='selectedUserSubName' type='text' value={selectedUserSubName} onChange={(e) => setSelectedUserSubName(e.target.value)}></Input>
                                                </label>
                                                <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                                    <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                                    <Input inactive={!userPermitted ? true : false} id='selectedUserEmail' type='e-mail' value={selectedUserEmail} onChange={(e) => { validateEmail(e.target.value); setSelectedUserEmail(e.target.value) }}></Input>
                                                </label>
                                                <LabelInput text={'Data de nascimento *'}>
                                                    <Input id='selectedBirthDay' value={selectedBirthDay} type={'date'} onChange={(e) => { setSelectedBirthDay(e.target.value) }}></Input>
                                                </LabelInput>
                                                <label className='relative w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                                    <a className='text-sm text-inputPlaceholderLight'>Tipo de acesso *</a>
                                                    <InputAutoComplete
                                                        data={typeAccessList}
                                                        inactive={true}
                                                        preSelectedValue={selectedTypeAccesss?.description}
                                                        selectedLabel={'description'}
                                                        optionList={['description']}
                                                        onChange={selectedTypeAccess => setSelectedTypeAccess(selectedTypeAccess)}
                                                        id='selectedTypeAccesss'
                                                    />
                                                </label>
                                                {
                                                    selectedTypeAccesss.id === 3 &&
                                                    <label className='relative w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                                        <a className='text-sm text-inputPlaceholderLight'>Permissão *</a>
                                                        <InputAutoComplete
                                                            data={permissionGroupsData}
                                                            preSelectedValue={selectedPermissionGroup?.description}
                                                            selectedLabel={'name'}
                                                            optionList={['name']}
                                                            onChange={value => { setSelectedPermissionGroup(value) }}
                                                            id='selectedPermissionGroup'
                                                        />
                                                    </label>
                                                }
                                                <LabelInput text={'Genero'}>
                                                    <InputAutoComplete
                                                        data={sexTypes}
                                                        inactive={selectedUserGender?.id === undefined ? false : true}
                                                        preSelectedValue={selectedUserGender?.name}
                                                        selectedLabel={'name'}
                                                        optionList={['id', 'name']}
                                                        placeHolder=' - '
                                                        onChange={e => setSelectedUserGender(e)}
                                                        id='user_gender_select'
                                                    />
                                                </LabelInput>
                                                <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                                    <a className='text-sm text-inputPlaceholderLight'>CPF {userData[0].customersData?.requireCpf ? '*' : ''}</a>
                                                    <Input charLimit={14} id='selecteduserCpf' type='text' value={selecteduserCpf ? maskCpf(selecteduserCpf) : ''} onChange={(e) => setSelectedUserCpf(e.target.value)}></Input>
                                                </label>
                                                <div className='flex flex-col sm:flex-row gap-3'>
                                                    <LabelInput text={'DDD'}>
                                                        <Input charLimit={2} width={'12'} type={'text'} value={selectedDDDCell} onChange={(e) => setSelectedDDDCell(e.target.value)}></Input>
                                                    </LabelInput>
                                                    <LabelInput text={'Celular'}>
                                                        <Input charLimit={9} width={'80'} type={'text'} value={selectedMobilePhone} onChange={(e) => setSelectedMobilePhone(e.target.value)}></Input>
                                                    </LabelInput>
                                                </div>
                                                <div className='flex flex-col sm:flex-row gap-3'>
                                                    <LabelInput text={'DDD'}>
                                                        <Input charLimit={2} width={'12'} type={'text'} onChange={(e) => setSelectedDDDPhone(e.target.value)} value={selectedDDDPhone}></Input>
                                                    </LabelInput>
                                                    <LabelInput text={'Telefone'}>
                                                        <Input
                                                            charLimit={9}
                                                            width={'80'}
                                                            type={'text'}
                                                            onChange={(e) => setSelectedPhone(e.target.value)}
                                                            value={selectedPhone ? maskPhone(String(selectedPhone)) : ''}
                                                        ></Input>
                                                    </LabelInput>
                                                </div>
                                                <LabelInput text={'Tipo / Associação *'}>
                                                    <InputAutoComplete
                                                        data={associationsList}
                                                        selectedLabel={'name'}
                                                        optionList={['id', 'name']}
                                                        placeHolder=' - '
                                                        preSelectedValue={selectedAssociate?.name}
                                                        onChange={value => {
                                                            if (value.requireRegistrationCode) {
                                                                setRequireRegistrationCode(true)
                                                            } else {
                                                                setRequireRegistrationCode(false)
                                                            }
                                                            setSelectedAssociate(value)
                                                        }}
                                                        id='selectedAssociate'
                                                    />
                                                </LabelInput>
                                                <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                                    <a className='text-sm text-inputPlaceholderLight'>Matrícula {requireRegistrationCode ? '*' : ''}</a>
                                                    <Input charLimit={30} id='registrationCode' type='text' value={registrationCode} onChange={(e) => setRegistrationCode(e.target.value)}></Input>
                                                </label>
                                                <LabelInput text={'Salário'}>
                                                    <CurrencyInput
                                                        id='selectedSalary'
                                                        placeHolder={salary ? `R$ ${salary?.toString()?.replace('.', ',')}` : 'R$ 0,00'}
                                                        onChange={(e) => {
                                                            setSalary(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                                        }} />
                                                </LabelInput>
                                            </form>
                                            <div className='w-full flex flex-col pl-10 sm:pl-0 items-start justify-start mt-6 gap-2'>
                                                <p className='text-lg sm:text-sm text-titleBlackTextLight dark:text-titleGrayTextLight'>Ativo</p>
                                                {
                                                    userPermitted ?
                                                        <Toogle status={thisUserData?.active} onClick={(e) => handleInactiveUser(e)}></Toogle>
                                                        :
                                                        <Toogle status={thisUserData?.active} disabled={true} ></Toogle>
                                                }

                                            </div>
                                            {
                                                !loading &&
                                                <>
                                                    <div className='flex flex-col w-full mt-8'>
                                                        <h1 className='text-primaryDefaultLight text-base'>Dependentes</h1>
                                                        {
                                                            !showNewDependent ?
                                                                <div className='flex flex-row w-full mb-4 items-center justify-center gap-2'>
                                                                    <div className="flex flex-row items-start justify-start w-full">
                                                                        <Tippy content={<p>{Object.keys(newDependent)?.length === 0 ? 'Novo dependente' : 'Editar/adicionar'}</p>}
                                                                            arrow={true}
                                                                            animation='shift-away'
                                                                            placement='top'
                                                                            delay={100}>
                                                                            <div onClick={() => handleNewDependent()} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                                                <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Adicionar</a>
                                                                            </div>
                                                                        </Tippy>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='flex flex-row w-full mb-4 items-center justify-center gap-2'>
                                                                    <div className="flex flex-row items-start justify-start w-full">
                                                                        <Tippy content={<p>Salve e posterior clique em editar usuário para gravar</p>}
                                                                            arrow={true}
                                                                            animation='shift-away'
                                                                            placement='top'
                                                                            delay={100}>
                                                                            <div className="p-2 hover:bg-gray-100 rounded-md flex flex-row gap-2 items-center justify-center">
                                                                                {!showNewDependent ? <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                                                    :
                                                                                    <FaEdit className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />}
                                                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Editando</a>
                                                                            </div>
                                                                        </Tippy>
                                                                    </div>
                                                                </div>

                                                        }
                                                        {
                                                            showNewDependent &&
                                                            <div className='flex flex-col gap-2 w-full items-start justify-start'>
                                                                <div className='w-full flex gap-2 flex-wrap'>
                                                                    {
                                                                        userDependentsKeys.map(key => {
                                                                            return <>
                                                                                <div className='hidden sm:flex w-full gap-2'>
                                                                                    <LabelInput text={'Nome completo'}>
                                                                                        <Input id={`${key}-fullName`} width={56} value={newDependent[key]?.fullName} type={'text'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], fullName: e.target.value } }))}></Input>
                                                                                    </LabelInput>
                                                                                    <LabelInput text={'CPF'}>
                                                                                        <Input id={`${key}-cpf`} width={32} value={newDependent[key]?.cpf ? maskCpf(String(newDependent[key]?.cpf)) : ''} type={'text'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], cpf: e.target.value } }))}></Input>
                                                                                    </LabelInput>
                                                                                    <LabelInput text={'Data de nascimento'}>
                                                                                        <Input id={`${key}-birthday`} width={32} value={newDependent[key]?.birthday} type={'date'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], birthday: e.target.value } }))}></Input>
                                                                                    </LabelInput>
                                                                                    <LabelInput text={'Grau de parentesco'}>
                                                                                        <InputAutoComplete id={`${key}-degreeOfKinship`} width={56} preSelectedValue={newDependent[key]?.degreeOfKinship} data={[
                                                                                            { id: 0, description: 'Filho/Filha' },
                                                                                            { id: 1, description: 'Marido/Esposa' },
                                                                                        ]} selectedLabel={'description'} optionList={['description']} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], degreeOfKinship: e.description } }))} />
                                                                                    </LabelInput>
                                                                                    <LabelInput text={'Adicionar'}>
                                                                                        <div onClick={() => handleNewDependentInList()} className='cursor-pointer flex h-full items-center justify-center'>
                                                                                            <FaPlus className='dark:text-titleGrayTextDark' />
                                                                                        </div>
                                                                                    </LabelInput>
                                                                                    <LabelInput text={'Remover'}>
                                                                                        <div onClick={() => handleRemoveDependent(key)} className='cursor-pointer flex h-full items-center justify-center'>
                                                                                            <FaMinus className='dark:text-titleGrayTextDark' />
                                                                                        </div>
                                                                                    </LabelInput>
                                                                                </div>
                                                                                <div className='mb-2 flex w-full flex-col sm:hidden border border-zinc-200 dark:border-thirdDefaultDark p-1 rounded-lg bg-zinc-50 dark:bg-thirdDefaultDark'>
                                                                                    <LabelInput text={'Nome completo'}>
                                                                                        <Input value={newDependent[key]?.fullName} type={'text'} onChange={(e) => setNewDependent(prev => ({ [key]: { ...prev[key], fullName: e.target.value } }))}></Input>
                                                                                    </LabelInput>
                                                                                    <LabelInput text={''}>
                                                                                        <Input value={newDependent[key]?.cpf} type={'text'} onChange={(e) => setNewDependent(prev => ({ [key]: { ...prev[key], cpf: e.target.value } }))}></Input>
                                                                                    </LabelInput>
                                                                                    <LabelInput text={'Data de nascimento'}>
                                                                                        <Input value={newDependent[key]?.birthday} type={'date'} onChange={(e) => setNewDependent(prev => ({ [key]: { ...prev[key], birthday: e.target.value } }))}></Input>
                                                                                    </LabelInput>
                                                                                    <LabelInput text={'Grau de parentesco'}>
                                                                                        <InputAutoComplete preSelectedValue={newDependent[key]?.degreeOfKinship} data={[
                                                                                            { id: 0, description: 'Filho/Filha' },
                                                                                            { id: 1, description: 'Marido/Esposa' },
                                                                                        ]} selectedLabel={'description'} optionList={['description']} onChange={(e) => setNewDependent(prev => ({ [key]: { ...prev[key], degreeOfKinship: e.description } }))} />
                                                                                    </LabelInput>
                                                                                    <div className='my-4 sm:my-0 w-full gap-2 flex items-start justify-start'>
                                                                                        <LabelInput text={'Adicionar'}>
                                                                                            <div onClick={() => handleNewDependentInList()} className='p-2 border border-zinc-200 rounded-lg cursor-pointer flex h-full items-start justify-start'>
                                                                                                <FaPlus />
                                                                                            </div>
                                                                                        </LabelInput>
                                                                                        <LabelInput text={'Remover'}>
                                                                                            <div onClick={() => handleRemoveDependent(key)} className='p-2 border border-zinc-200 rounded-lg cursor-pointer flex h-full items-start justify-start'>
                                                                                                <FaMinus />
                                                                                            </div>
                                                                                        </LabelInput>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className='mt-4 flex gap-2'>
                                                                    <Button shadow={true} approval={true} onClick={() => handleConfirmNewDependents()}>Salvar</Button>
                                                                    <Button shadow={true} approval={false} onClick={() => handleChangeShowNewDependentView()}>Cancelar</Button>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            !showNewDependent ?
                                                                Object.keys(userDependents).length === 0 ?
                                                                    <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Nenhum depentende cadastrado.</a>
                                                                    :
                                                                    Object.keys(userDependents)?.map(key => {
                                                                        return <>
                                                                            <div className='hidden sm:flex w-full gap-2'>
                                                                                <LabelInput text={'Nome completo'}>
                                                                                    <Input disabled={true} width={56} value={userDependents[key]?.fullName} type={'text'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], fullName: e.target.value } }))}></Input>
                                                                                </LabelInput>
                                                                                <LabelInput text={'CPF'}>
                                                                                    <Input disabled={true} width={32} value={userDependents[key]?.cpf ? maskCpf(String(userDependents[key]?.cpf)) : ''} type={'text'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], cpf: e.target.value } }))}></Input>
                                                                                </LabelInput>
                                                                                <LabelInput text={'Data de nascimento'}>
                                                                                    <Input disabled={true} width={32} value={userDependents[key]?.birthday} type={'date'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], birthday: e.target.value } }))}></Input>
                                                                                </LabelInput>
                                                                                <LabelInput text={'Grau de parentesco'}>
                                                                                    <Input width={56} disabled={true} value={userDependents[key]?.degreeOfKinship} />
                                                                                </LabelInput>
                                                                            </div>
                                                                            <div className='mb-4 flex flex-col sm:hidden w-full gap-2 p-1 bg-zinc-50 dark:bg-thirdDefaultDark border border-zinc-200 dark:border-primaryBorderDark'>
                                                                                <LabelInput text={'Nome completo'}>
                                                                                    <Input disabled={true} value={userDependents[key]?.fullName} type={'text'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], fullName: e.target.value } }))}></Input>
                                                                                </LabelInput>
                                                                                <LabelInput text={'CPF'}>
                                                                                    <Input disabled={true} value={userDependents[key]?.cpf ? maskCpf(String(userDependents[key]?.cpf)) : ''} type={'text'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], cpf: e.target.value } }))}></Input>
                                                                                </LabelInput>
                                                                                <LabelInput text={'Data de nascimento'}>
                                                                                    <Input disabled={true} value={userDependents[key]?.birthday} type={'date'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], birthday: e.target.value } }))}></Input>
                                                                                </LabelInput>
                                                                                <LabelInput text={'Grau de parentesco'}>
                                                                                    <Input disabled={true} value={userDependents[key]?.degreeOfKinship} />
                                                                                </LabelInput>
                                                                            </div>
                                                                        </>
                                                                    })
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                    <div className='flex flex-col w-full mt-8 mb-8'>
                                                        <h1 className='text-primaryDefaultLight text-base'>Empresa vinculada</h1>
                                                        <label className='relative w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                                            <InputAutoComplete
                                                                data={companiesList}
                                                                preSelectedValue={selectedCompany?.name}
                                                                selectedLabel={'name'}
                                                                optionList={['name']}
                                                                onChange={selectedCompany => setSelectedCompany(selectedCompany)}
                                                                id='selectedCompany'
                                                            />
                                                        </label>
                                                    </div>
                                                </>
                                            }
                                        </> :
                                        <div className='w-full items-start justify-start flex flex-col gap-4'>
                                            <div className='w-full flex flex-col gap-2 items-start justify-start'>
                                                <div className='flex flex-row flex-wrap gap-4 w-full items-start justify-start'>
                                                    <div className='flex sm:w-auto gap-1 relative'>
                                                        <LabelInput text={'Usa configuração de saldo da associação: *'}>
                                                            <InputAutoComplete
                                                                id={'useDefaultAssociationConfigBalance'}
                                                                preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.useDefaultAssociationConfigBalance)?.label || ''}
                                                                data={boolOptions}
                                                                onChange={(e) => setConfigFinancial(prev => ({ ...prev, useDefaultAssociationConfigBalance: e?.value }))}
                                                                optionList={['label']}
                                                                selectedLabel={'label'}
                                                            />
                                                        </LabelInput>
                                                        <Tippy content={'Informe "Não" para que a associação não possa fazer movimentação de saldo para este cliente ou "Sim" para que seja possível.'}
                                                            arrow={true}
                                                            animation='shift-away'
                                                            placement='top'
                                                            delay={100}
                                                        >
                                                            <div className='absolute right-0 text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                                <FaInfo />
                                                            </div>
                                                        </Tippy>
                                                    </div>
                                                    {
                                                        !configFinancial?.useDefaultAssociationConfigBalance &&
                                                        <>
                                                            <div className='flex sm:w-auto gap-1 relative'>
                                                                <LabelInput text={'Utilizará saldo:'}>
                                                                    <InputAutoComplete
                                                                        id={'useFinancialUsersBalance'}
                                                                        preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.useFinancialUsersBalance)?.label || ''}
                                                                        data={boolOptions}
                                                                        onChange={(e) => setConfigFinancial(prev => ({ ...prev, useFinancialUsersBalance: e?.value }))}
                                                                        optionList={['label']}
                                                                        selectedLabel={'label'}
                                                                    />
                                                                </LabelInput>
                                                                <Tippy content={'Informe "Não" para que não seja possível lançar um saldo a parte para este cliente ou "Sim" para que seja possível.'}
                                                                    arrow={true}
                                                                    animation='shift-away'
                                                                    placement='top'
                                                                    delay={100}
                                                                >
                                                                    <div className='absolute right-0 text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                                        <FaInfo />
                                                                    </div>
                                                                </Tippy>
                                                            </div>
                                                            {
                                                                configFinancial?.useFinancialUsersBalance &&
                                                                <>
                                                                    <div className='flex flex-col items-start justify-start'>
                                                                        <LabelInput text={'Automatizar renovação de saldos:'}>
                                                                            <InputAutoComplete
                                                                                id={'useAutoRenewBalance'}
                                                                                preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.useAutoRenewBalance)?.label || ''}
                                                                                data={boolOptions}
                                                                                onChange={(e) => setConfigFinancial(prev => ({ ...prev, useAutoRenewBalance: e?.value }))}
                                                                                optionList={['label']}
                                                                                selectedLabel={'label'}
                                                                            />
                                                                        </LabelInput>
                                                                    </div>
                                                                    {
                                                                        configFinancial?.useAutoRenewBalance &&
                                                                        <>
                                                                            <LabelInput text={'Período de renovação: *'}>
                                                                                <InputAutoComplete
                                                                                    id={'autoRenewPeriod'}
                                                                                    preSelectedValue={renewPeriodOptions.find(op => op?.value === configFinancial?.autoRenewPeriod)?.label || ''}
                                                                                    data={renewPeriodOptions}
                                                                                    onChange={(e) => setConfigFinancial(prev => ({ ...prev, autoRenewPeriod: e?.value }))}
                                                                                    optionList={['label']}
                                                                                    selectedLabel={'label'}
                                                                                />
                                                                            </LabelInput>
                                                                            {
                                                                                configFinancial?.autoRenewPeriod === "year" &&
                                                                                <>
                                                                                    <LabelInput text={'Informe o mês: *'}>
                                                                                        <Input
                                                                                            id='autoRenewMonth'
                                                                                            charLimit={2}
                                                                                            placeholder={'Ex: 1'}
                                                                                            max={12}
                                                                                            type={'number'}
                                                                                            value={configFinancial?.autoRenewMonth || ''}
                                                                                            onChange={(e) => (e.target.value > 0 || !e.target.value) && setConfigFinancial(prev => ({ ...prev, autoRenewMonth: maxNumber(12, e.target.value) }))}
                                                                                        ></Input>
                                                                                    </LabelInput>
                                                                                </>
                                                                            }
                                                                            {
                                                                                configFinancial?.useAutoRenewBalance &&
                                                                                <LabelInput text={'Informe o dia: *'}>
                                                                                    <Input
                                                                                        id='autoRenewDay'
                                                                                        charLimit={2}
                                                                                        placeholder={'Ex: 1'}
                                                                                        type={'number'}
                                                                                        value={configFinancial?.autoRenewDay || ''}
                                                                                        onChange={(e) => (e.target.value > 0 || !e.target.value) && setConfigFinancial(prev => ({ ...prev, autoRenewDay: maxNumber(31, e.target.value) }))}
                                                                                    ></Input>
                                                                                </LabelInput>
                                                                            }
                                                                            <div className='flex sm:w-auto gap-1 relative'>
                                                                                <LabelInput text={'Resetar saldo na renovação: '}>
                                                                                    <InputAutoComplete
                                                                                        id={'autoRenewResetBalance'}
                                                                                        preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.autoRenewResetBalance)?.label || ''}
                                                                                        data={boolOptions}
                                                                                        onChange={(e) => setConfigFinancial(prev => ({ ...prev, autoRenewResetBalance: e?.value }))}
                                                                                        optionList={['label']}
                                                                                        selectedLabel={'label'}
                                                                                    />
                                                                                </LabelInput>
                                                                                <Tippy content={'Informe "Não" para que o saldo seja acumulado ou "Sim" para que na próxima renovação o saldo anterior seja zerado.'}
                                                                                    arrow={true}
                                                                                    animation='shift-away'
                                                                                    placement='top'
                                                                                    delay={100}
                                                                                >
                                                                                    <div className='absolute right-0 text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                                                        <FaInfo />
                                                                                    </div>
                                                                                </Tippy>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    <LabelInput text={'Tipo de saldo: *'}>
                                                                        <InputAutoComplete
                                                                            id={'balanceType'}
                                                                            preSelectedValue={balanceTypeOptions.find(op => op?.value === configFinancial?.balanceType)?.label || ''}
                                                                            data={balanceTypeOptions}
                                                                            onChange={(e) => setConfigFinancial(prev => ({ ...prev, balanceType: e?.value }))}
                                                                            optionList={['label']}
                                                                            selectedLabel={'label'}
                                                                        />
                                                                    </LabelInput>
                                                                    {
                                                                        configFinancial?.balanceType !== "percentageOfSalary"
                                                                            ?
                                                                            <LabelInput text={'Valor: *'}>
                                                                                <CurrencyInput
                                                                                    id={`balanceValue`}
                                                                                    className='pl-2 border border-gray-400 border-opacity-75 
                                                                                    outline-none
                                                                                    sm:w-96 w-full
                                                                                    text-sm sm:text-sm
                                                                                    transition-all duration-200
                                                                                    focus:shadow-borderShadow
                                                                                    h-10 sm:h-8
                                                                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                                                                    placeHolder={configFinancial.balanceValue ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(configFinancial.balanceValue) : 'R$ 0,00'}
                                                                                    onChange={(e) => {
                                                                                        setConfigFinancial(prev => ({ ...prev, balanceValue: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))
                                                                                    }}
                                                                                />
                                                                            </LabelInput> :
                                                                            <LabelInput text={'Valor: (%) *'}>
                                                                                <Input
                                                                                    id='balanceValue'
                                                                                    charLimit={4}
                                                                                    placeholder={'Ex: 1'}
                                                                                    type={'number'}
                                                                                    value={configFinancial?.balanceValue || ''}
                                                                                    onChange={(e) => (e.target.value > 0 || !e.target.value) && setConfigFinancial(prev => ({ ...prev, balanceValue: maxNumber(100, e.target.value) }))}
                                                                                ></Input>
                                                                            </LabelInput>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button
                            approval={false}
                            shadow={true}
                            onClick={() => { history.back(); setShowNotificationModal(false) }}
                        >Voltar
                        </Button>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <Button approval={false} shadow={true} onClick={() => { setErrorDependents(false); setChangePassword(false); setShowUniversalModal(true) }}> Excluir </Button>
                            <Tippy content={'Clique para gerar uma nova senha para este usuário. A senha será enviada por e-mail'}
                                arrow={true}
                                animation='shift-away'
                                placement='top'
                                delay={100}>
                                {userPermitted &&
                                    <div className=' flex flex-row gap-1 items-center justify-start'>
                                        <Button onClick={() => {
                                            setChangePassword(true)
                                            setShowUniversalModal(true)
                                        }} shadow={true}>Reenviar senha</Button>
                                    </div>
                                }
                            </Tippy>
                            <Button approval={true} shadow={true} onClick={() => handleSubmit()}> Editar usuário </Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
}
