export const SystemAreas = [
    {
        id: "services",
        description: "Serviços"
    },
    {
        id: "sector",
        description: "Setores"
    },
    {
        id: "gastronomy",
        description: "Gastronomia"
    },
    {
        id: "activities",
        description: "Atividades"
    },
    {
        id: "events",
        description: "Eventos"
    },
]