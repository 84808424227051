import React from "react";
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { TbMoneybag, TbPigMoney} from 'react-icons/tb'
import { useScreenSizeContext } from "../../../../contexts/screenSizeContext";
import { useNavigate } from "react-router-dom";

export function FinancialReports() {

    const navigate = useNavigate()
    const { screenX, screenY } = useScreenSizeContext()

    return (
        <div className="overflow-x-hidden flex flex-col gap-2 w-full h-full bg-bgSecondaryLight dark:bg-primaryDefaultDark pt-10 px-4" style={{ height: screenX < 640 ? screenY - 67 : 'auto' }}>
            <a className="text-lg text-primaryDefaultLight dark:text-titleGrayTextDark mb-5">Relatórios do sistema</a>
            <div className="flex flex-wrap gap-6 w-full">
                <div style={{ maxHeight: screenY - 200 }} className="animate-[wiggleLeft_.8s_ease-in-out] flex-col flex gap-4 p-2 border-2-l rounded-lg bg-zinc-50 dark:bg-secondaryDefaultDark shadow-xl border-primaryDefaultLight overflow-y-auto py-6 px-8 border-l-4">
                    <a className="text-lg border-l-4 pl-2 border-primaryDefaultLight text-titleBlackTextLight dark:text-titleGrayTextDark mb-2">Receitas</a>
                    <div onClick={() => navigate('/financial/reportsPerCategory/R')} className="hover:shadow-borderShadow w-full sm:w-60 h-36 border border-zinc-100 dark:border-primaryBorderDark bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg rounded-lg transition-all duration-200 cursor-pointer">
                        <div className="bg-zinc-100 p-2 flex gap-1 dark:bg-thirdDefaultDark items-center justify-start text-primaryDefaultLight dark:text-titleGrayTextDark">
                            <GiReceiveMoney className="text-xl" />
                            <a className="text-sm">Relatório por categoria</a>
                        </div>
                        <div className="p-2 leading-tight">
                            <a className="text-sm  text-zinc-500 dark:text-titleGrayTextLight">Possibilita a impressão de relatórios de receitas por categoria, em Excel</a>
                        </div>
                    </div>
                    <div onClick={() => navigate('/financial/reportsPerCostCenter/R')} className="hover:shadow-borderShadow w-full sm:w-60 h-36 border border-zinc-100 dark:border-primaryBorderDark bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg rounded-lg transition-all duration-200 cursor-pointer">
                        <div className="bg-zinc-100 p-2 flex gap-1 dark:bg-thirdDefaultDark items-center justify-start text-primaryDefaultLight dark:text-titleGrayTextDark">
                            <GiReceiveMoney className="text-xl" />
                            <a className="text-sm">Relatório por centro de custo</a>
                        </div>
                        <div className="p-2 leading-tight">
                            <a className="text-sm  text-zinc-500 dark:text-titleGrayTextLight">Possibilita a impressão de relatórios de receitas por centro de custo, em Excel</a>
                        </div>
                    </div>
                </div>
                <div style={{ maxHeight: screenY - 200 }} className="animate-[wiggleLeft_.8s_ease-in-out] flex-col flex gap-4 p-2 border-2-l rounded-lg bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg border-primaryDefaultLight overflow-x-hidden py-6 px-8 border-l-4">
                    <a className="text-lg border-l-4 pl-2 border-primaryDefaultLight text-titleBlackTextLight dark:text-titleGrayTextDark mb-2">Despesas</a>
                    <div onClick={() => navigate('/financial/reportsPerCategory/D')} className="hover:shadow-borderShadow w-full sm:w-60 h-36 border border-zinc-100 dark:border-primaryBorderDark bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg rounded-lg transition-all duration-200 cursor-pointer">
                        <div className="bg-zinc-100 p-2 flex gap-1 dark:bg-thirdDefaultDark items-center justify-start text-primaryDefaultLight dark:text-titleGrayTextDark">
                            <GiPayMoney className="text-xl" />
                            <a className="text-sm">Relatório por categoria</a>
                        </div>
                        <div className="p-2 leading-tight">
                            <a className="text-sm  text-zinc-500 dark:text-titleGrayTextLight">Possibilita a impressão de relatórios de despesas por categoria, em Excel</a>
                        </div>
                    </div>
                    <div onClick={() => navigate('/financial/reportsPerCostCenter/D')} className="hover:shadow-borderShadow w-full sm:w-60 h-36 border border-zinc-100 dark:border-primaryBorderDark bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg rounded-lg transition-all duration-200 cursor-pointer">
                        <div className="bg-zinc-100 p-2 flex gap-1 dark:bg-thirdDefaultDark items-center justify-start text-primaryDefaultLight dark:text-titleGrayTextDark">
                            <GiPayMoney className="text-xl" />
                            <a className="text-sm">Relatório por centro de custo</a>
                        </div>
                        <div className="p-2 leading-tight">
                            <a className="text-sm  text-zinc-500 dark:text-titleGrayTextLight">Possibilita a impressão de relatórios de despesas por centro de custo, em Excel</a>
                        </div>
                    </div>
                </div>
                <div style={{ maxHeight: screenY - 200 }} className="animate-[wiggleLeft_.8s_ease-in-out] flex-col flex gap-4 p-2 border-2-l rounded-lg bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg border-primaryDefaultLight overflow-x-hidden py-6 px-8 border-l-4">
                    <a className="text-lg border-l-4 pl-2 border-primaryDefaultLight text-titleBlackTextLight dark:text-titleGrayTextDark mb-2">Fluxo de Caixa</a>
                    <div onClick={() => navigate('/financial/reportsCashFlowMonthly')} className="hover:shadow-borderShadow w-full sm:w-60 h-36 border border-zinc-100 dark:border-primaryBorderDark bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg rounded-lg transition-all duration-200 cursor-pointer">
                        <div className="bg-zinc-100 p-2 flex gap-1 dark:bg-thirdDefaultDark items-center justify-start text-primaryDefaultLight dark:text-titleGrayTextDark">
                            <TbPigMoney className="text-xl" />
                            <a className="text-sm">Relatório mensal</a>
                        </div>
                        <div className="p-2 leading-tight">
                            <a className="text-sm  text-zinc-500 dark:text-titleGrayTextLight">Possibilita a impressão de relatórios de fluxo de caixa por mês, em Excel</a>
                        </div>
                    </div>
                </div>
                <div style={{ maxHeight: screenY - 200 }} className="animate-[wiggleLeft_.8s_ease-in-out] flex-col flex gap-4 p-2 border-2-l rounded-lg bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg border-primaryDefaultLight overflow-x-hidden py-6 px-8 border-l-4">
                    <a className="text-lg border-l-4 pl-2 border-primaryDefaultLight text-titleBlackTextLight dark:text-titleGrayTextDark mb-2">Reservas e Inscrições</a>
                    <div onClick={() => navigate('/financial/reserveMargin')} className="hover:shadow-borderShadow w-full sm:w-60 h-36 border border-zinc-100 dark:border-primaryBorderDark bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg rounded-lg transition-all duration-200 cursor-pointer">
                        <div className="bg-zinc-100 p-2 flex gap-1 dark:bg-thirdDefaultDark items-center justify-start text-primaryDefaultLight dark:text-titleGrayTextDark">
                            <TbMoneybag className="text-xl" />
                            <a className="text-sm">Margem de reservas</a>
                        </div>
                        <div className="p-2 leading-tight">
                            <a className="text-sm  text-zinc-500 dark:text-titleGrayTextLight">Possibilita a impressão de relatórios de margem das reservas por mês, em Excel</a>
                        </div>
                    </div>
                    <div onClick={() => navigate('/financial/activitiesMargin')} className="hover:shadow-borderShadow w-full sm:w-60 h-36 border border-zinc-100 dark:border-primaryBorderDark bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg rounded-lg transition-all duration-200 cursor-pointer">
                        <div className="bg-zinc-100 p-2 flex gap-1 dark:bg-thirdDefaultDark items-center justify-start text-primaryDefaultLight dark:text-titleGrayTextDark">
                            <TbMoneybag className="text-xl" />
                            <a className="text-sm">Margem de atividades</a>
                        </div>
                        <div className="p-2 leading-tight">
                            <a className="text-sm  text-zinc-500 dark:text-titleGrayTextLight">Possibilita a impressão de relatórios de margem das atividades por mês, em Excel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}