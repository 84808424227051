import { useNavigate } from "react-router-dom"
import { Body } from "../../../components/container/Body"
import { Container } from "../../../components/container/container"
import { Title } from "../../../components/titlePages/title"
import { TitlePage } from "../../../components/titlePages/title.page"
import { useApiContext } from "../../../contexts/ApiInterceptorContext"
import { useAuth } from "../../../contexts/useAuth"
import { useThemeContext } from "../../../contexts/themeContext"
import { useEffect, useState } from "react"
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Input } from "../../../components/input/input";
import CurrencyInput from "../../../components/input/inputCurrencyPtBr";
import Tippy from "@tippyjs/react";
import { FaInfo, FaQuestionCircle, FaPlus, FaMinus } from 'react-icons/fa'
import { Button } from "../../../components/buttons/button.default"
import { Footer } from "../../../components/footer/Footer"
import { responseError } from "../../../utils/responsesFunctions/error.response"
import { validateFields } from "../../../utils/form.validator"
import moment from "moment"

export function FinancialAccountsTransfersCreate() {

    const navigate = useNavigate()
    const { defaultCallsFinancial, api_financial } = useApiContext()
    const { userData } = useAuth()
    const { setShowNotificationModalText, setShowNotificationModal, setShowNotificationModalSuccess } = useThemeContext()
    const [active, setActive] = useState(true)
    const [accountsList, setAccountsList] = useState([])
    const [accountBalances, setAccountBalances] = useState([])
    const [accountBalance, setAccountBalance] = useState(0)
    const [accountRestBalance, setAccountRestBalance] = useState(0)
    const [loading, setLoading] = useState(true)
    const [transferData, setTransferData] = useState({})
    const [costCenter, setCostCenter] = useState([])
    const [categories, setCategories] = useState([])
    const [loadingAccounts, setLoadingAccounts] = useState(false)
    const [hasTaxes, setHasTaxes] = useState(false)
    const [apportionmentsBills, setApportionmentsBills] = useState([])
    const optionsBooleansList = [
        {
            type: true,
            description: "Sim"
        },
        {
            type: false,
            description: "Não"
        }
    ]
    const [moduleLinks, setModuleLinks] = useState([])

    async function getData() {
        try {
            setLoading(true)
            const accountsData = await defaultCallsFinancial.getAccounts(userData[0]?.token)
            setAccountsList(accountsData.data)

            const costCenterData = await defaultCallsFinancial.getCostCenter(userData[0]?.token, true)
            if (costCenterData.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(costCenterData.data)
            }
            setCostCenter(costCenterData?.data?.map(cost => {
                return {
                    ...cost,
                    valueInput: `${cost?.code ? cost?.code : cost?.id} - ${cost?.name}`
                }
            }))

            const categoriesApi = await defaultCallsFinancial.getFinancialCategories(userData[0]?.token)
            if (categoriesApi?.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(categoriesApi.data)
            }
            setCategories(categoriesApi.data?.filter(cat => cat.incomeOrExpense == 'D'))

            const linksApi = await defaultCallsFinancial.getFinancialListLinks(userData[0]?.token)
            if (linksApi?.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(linksApi.data)
            }
            setModuleLinks(linksApi.data)

            const accountsBalanceData = await defaultCallsFinancial.getFinancialBalanceAccountsWithBalance(userData[0]?.token)
            if (accountsBalanceData?.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(accountsBalanceData.data)
            }
            setAccountBalances(accountsBalanceData.data)

        } catch (error) {
            const formatedError = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(formatedError || 'Erro inesperado')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    async function handleSubmit() {

        const requiredFields = [
            { name: 'transferValue', value: transferData?.value, required: true, type: 'string' },
            { name: 'in_account_id', value: transferData?.in_account_id, required: true, type: 'number' },
            { name: 'out_account_id', value: transferData?.out_account_id, required: true, type: 'number' },
            { name: 'referenceDate', value: transferData?.referenceDate, required: true, type: 'string' },
            { name: 'tax', value: transferData?.tax, required: hasTaxes, type: 'string' },
        ]

        hasTaxes &&
            apportionmentsBills?.map((app, index) => {
                requiredFields.push(
                    { name: `apportionmentFinancialCategories_id-${index}`, value: app?.financialCategories_id, required: true, type: 'string' },
                    { name: `apportionmentPercentage-${index}`, value: app?.percentage, required: true, type: 'string' }
                )
                if (app.isLinked) {
                    requiredFields.push({ name: `apportionmentLinked-${index}`, value: app.linked_id, required: true, type: 'number' },)
                }
            })

        if (validateFields(requiredFields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setLoading(false)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        if (transferData?.referenceDate > moment()?.utc(false)?.format('YYYY-MM-DD')) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setLoading(false)
            return setShowNotificationModalText('Não é possível criar uma transferência para uma data futura.')
        }

        try {

            console.log(transferData)
            // return
            setLoading(true)

            await api_financial.post('/api/v1/financial/accountsTransfers', {
                referenceDate: transferData.referenceDate,
                in_account_id: transferData.in_account_id,
                out_account_id: transferData.out_account_id,
                value: transferData.value,
                tax: transferData.tax > 0 ? transferData?.tax : undefined,
                tax_apportionmentsBillConfig: apportionmentsBills?.length > 0 ?
                    apportionmentsBills.map(app => {
                        return {
                            financialCategories_id: app?.financialCategories_id,
                            financialCostCenter_id: app?.financialCostCenter_id
                                ? app?.financialCostCenter_id
                                : undefined,
                            percentage: app?.percentage,
                            linked: app?.linked
                                ? app?.linked
                                : undefined,
                            linked_id: app?.linked_id
                                ? app?.linked_id
                                : undefined
                        }
                    }) : [],
                users_id: userData[0]?.id,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Transferência realizada com sucesso!')
            navigate('/financial/accounts')
        } catch (error) {
            const formatedError = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(formatedError || 'Erro inesperado')
        } finally {
            setLoading(false)
        }
    }

    async function handleChangeAccounts(e, accountType) {

        setLoadingAccounts(accountType)
        setTransferData(prev => ({ ...prev, [accountType]: e.id }))

        if (accountType === 'out_account_id') {
            setAccountBalance(accountBalances.filter(account => account.id === e.id)[0]?.balance)
            setAccountRestBalance(accountBalances.filter(account => account.id === e.id)[0]?.balance)
        }
        await new Promise(r => setTimeout(r, 60))
        setLoadingAccounts('')
    }

    function calculateBalance() {

        setAccountRestBalance(accountBalance - transferData?.value)
    }

    function handleTaxes(e) {

        if (e?.value) {
            apportionmentsBills.push({
                financialCategories_id: undefined,
                financialCostCenter_id: undefined,
                percentage: undefined,
                isLinked: false,
                linked: undefined,
                linked_id: undefined,
            })

            setApportionmentsBills(apportionmentsBills)
        } else {
            setApportionmentsBills([])
        }

        setHasTaxes(e.value)
    }

    function validTaxValue(){

        if(transferData.tax >= transferData.value){
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText("Valor da taxa não pode ser igual ou maior do que o valor transferido")
            setTransferData({...transferData, tax: 0})
            document.querySelector("#tax").value = 'R$ 0,000'
            document.querySelector("#tax").style.border = '1px solid red'

        }
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Transferência entre contas'} />
            </TitlePage>
            <Body>

                {
                    loading ?
                        <div className='flex flex-col items-center justify-center w-full h-full'>
                            <DefaultLoader />
                        </div>
                        :
                        <div className="w-full gap-4 flex-col flex items-start justify-between">
                            <p>Utilize os campos abaixo para efetuar uma transferência entre contas no sistema</p>
                            <div className='flex flex-col gap-2 items-start justify-start w-full border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>         
                                <div className="flex items-center w-full gap-6">
                                    <LabelInput
                                        text={'Conta de saída'}
                                        required
                                        tippy={
                                            <Tippy content={'Informe de qual conta será transferido.'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}
                                            >
                                                <div className='dark:text-titleGrayTextDark'>
                                                    <FaQuestionCircle />
                                                </div>
                                            </Tippy>}
                                    >
                                        <InputAutoComplete
                                            data={accountsList}
                                            id={'out_account_id'}
                                            preSelectedValue={accountsList.find(opt => opt.id == transferData?.out_account_id)?.value}
                                            onChange={(e) => {
                                                setTransferData({ ...transferData, in_account_id: null, value: 0 })
                                                handleChangeAccounts(e, 'out_account_id')
                                            }}
                                            selectedLabel={'name'}
                                            optionList={['name']}
                                        />
                                    </LabelInput>
                                    <div className="flex mt-5 gap-1 items-center justify-center">
                                        <div className="flex gap-1 items-center">
                                            <h3 className="text-primaryDefaultLight">Saldo atual:</h3>
                                            <h3 className="text-primaryDefaultLight">{accountBalance.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h3>
                                        </div>
                                        <div className="h-6 text-primaryDefaultLight">|</div>
                                        <div className="flex gap-1 items-center">
                                            <h3 className="text-red-700">Saldo restante:</h3>
                                            <h3 className="text-red-700">{accountRestBalance.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h3>
                                        </div>
                                    </div>
                                </div>

                                {
                                    loadingAccounts !== 'out_account_id' ?
                                        <LabelInput
                                            text={'Conta de entrada'}
                                            required
                                            tippy={
                                                <Tippy content={'Informe de qual conta receberá o valor.'}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}
                                                >
                                                    <div className='dark:text-titleGrayTextDark'>
                                                        <FaQuestionCircle />
                                                    </div>
                                                </Tippy>}
                                        >
                                            <InputAutoComplete
                                                data={accountsList?.filter(account => account.id !== transferData?.out_account_id)}
                                                id={'in_account_id'}
                                                value={accountsList.find(opt => opt.id == transferData?.in_account_id)?.name}
                                                onChange={(e) => {
                                                    console.log(accountsList.find(opt => opt.id == transferData?.in_account_id))
                                                    handleChangeAccounts(e, 'in_account_id')
                                                }}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                            />
                                        </LabelInput>
                                        :
                                        <div className="w-96 h-14 flex flex-col gap-1">
                                            <a className="text-zinc-200 text-sm">Conta de entrada</a>
                                            <div className="h-8 border bg-zinc-100 w-96"></div>
                                        </div>
                                }

                                {
                                    loadingAccounts !== 'out_account_id' ?
                                        <LabelInput text={'Valor a ser transferido: *'}>
                                            <CurrencyInput
                                                id={`transferValue`}
                                                className='pl-2 border border-gray-400 border-opacity-75 
                                                    outline-none
                                                    sm:w-96 w-full
                                                    text-sm sm:text-sm
                                                    transition-all duration-200
                                                    focus:shadow-borderShadow
                                                    h-10 sm:h-8
                                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                                placeHolder={transferData.value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(transferData.value) : 'R$ 0,00'}
                                                onBlur={() => calculateBalance()}
                                                onChange={(e) => {
                                                    transferData.value = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))

                                                }}
                                            />
                                        </LabelInput>
                                        :
                                        <div className="w-96 h-14 flex flex-col gap-1">
                                            <a className="text-zinc-200 text-sm">Valor a ser transferido: *</a>
                                            <div className="h-8 border bg-zinc-100 w-96"></div>
                                        </div>
                                }

                                <LabelInput
                                    text={'Data da transferencia'}
                                    required
                                    tippy={
                                        <Tippy content={'Informe a data da transferência.'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}
                                        >
                                            <div className='dark:text-titleGrayTextDark'>
                                                <FaQuestionCircle />
                                            </div>
                                        </Tippy>}
                                >
                                    <Input type={'date'} id="referenceDate" value={transferData?.referenceDate} onChange={(e) => setTransferData({ ...transferData, referenceDate: e.target.value })}></Input>
                                </LabelInput>
                                <LabelInput
                                    text={'Possui taxas?'}
                                    required
                                    tippy={
                                        <Tippy content={'Informe se foi pago alguma taxa na trasnferência'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}
                                        >
                                            <div className='dark:text-titleGrayTextDark'>
                                                <FaQuestionCircle />
                                            </div>
                                        </Tippy>}
                                >
                                    <InputAutoComplete
                                        data={[
                                            { value: true, name: 'Sim' },
                                            { value: false, name: 'Não' }
                                        ]}
                                        selectedLabel={'name'}
                                        id={'hasTaxes'}
                                        preSelectedValue={'Não'}
                                        onChange={e => handleTaxes(e)}
                                    />
                                </LabelInput>

                            </div>
                            {
                                hasTaxes &&
                                <div className='flex flex-col gap-2 items-start justify-start w-full border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>

                                    <LabelInput text={'Taxas de transferência: *'}>
                                        <CurrencyInput
                                            id={`tax`}
                                            className='pl-2 border border-gray-400 border-opacity-75 
                                                    outline-none
                                                    sm:w-96 w-full
                                                    text-sm sm:text-sm
                                                    transition-all duration-200
                                                    focus:shadow-borderShadow
                                                    h-10 sm:h-8
                                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                            placeHolder={transferData.tax ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(transferData.tax) : 'R$ 0,00'}
                                            onChange={(e) => {
                                                transferData.tax = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                            }}
                                            onBlur={() => validTaxValue()}
                                        />
                                    </LabelInput>

                                    <h3 className='mt-4 text-primaryDefaultLight text-xl'>Rateios da taxa</h3>
                                    <div className='flex flex-row justify-start items-end mt-4 border-b border-gray-300 w-full'>
                                        <div className='flex flex-col items-start gap-2'>
                                            <div className='flex flex-row items-center gap-2'>
                                                <p className='text-titleGrayTextDark'>Restante do rateio</p>
                                                <Tippy content={'É o restante que não foi distribuído a partir do valor total desta compra. Para completar o rateio, este restante precisa estar zerado.'}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div className='cursor-help text-xs text-primaryDefaultLight hover:brightness-150'>
                                                        <FaInfo />
                                                    </div>
                                                </Tippy>
                                            </div>
                                            <div className='flex flex-row justify-start items-end gap-2'>
                                                <p className='text-xl text-gray-500 font-semibold tracking-wider'>
                                                    {100 - apportionmentsBills.reduce((accumulator, currentValue) => Number(accumulator) + (currentValue?.percentage ? Number(currentValue?.percentage) : 0), 0)}
                                                    %
                                                </p>
                                                {
                                                    apportionmentsBills.reduce((accumulator, currentValue) => Number(accumulator) + (currentValue?.percentage ? Number(currentValue?.percentage) : 0), 0) !== 100
                                                    &&
                                                    <p className='text-red-400 text-xs'>Para prosseguir com a compra o rateio precisa estar zerado.</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        apportionmentsBills.map((app, index) => {
                                            return (
                                                <>
                                                    <div className={`flex flex-col gap-2 mt-3s`}>
                                                        <div className={`flex flex-wrap gap-2  ${index == 0 ? '' : 'border-t pt-4'} `}>
                                                            <LabelInput text={'Categoria: *'}>
                                                                <InputAutoComplete
                                                                    data={categories}
                                                                    id={`apportionmentFinancialCategories_id-${index}`}
                                                                    preSelectedValue={categories.find(categorie => categorie.id === app.financialCategories_id)?.description}
                                                                    value={categories.find(categorie => categorie.id === app.financialCategories_id)?.description}
                                                                    onChange={(e) => {
                                                                        app.financialCategories_id = e.id
                                                                        setApportionmentsBills([...apportionmentsBills])
                                                                    }}
                                                                    selectedLabel={'description'}
                                                                    optionList={['id', 'description']}
                                                                />
                                                            </LabelInput>
                                                            <LabelInput text={'Centro de custo:'}>
                                                                <InputAutoComplete
                                                                    data={costCenter}
                                                                    id={`apportionmentFinancialCostCenter_id-${index}`}
                                                                    preSelectedValue={costCenter.find(cost => cost.id === app?.financialCostCenter_id)?.valueInput}
                                                                    value={costCenter.find(cost => cost.id === app?.financialCostCenter_id)?.valueInput}
                                                                    onChange={(e) => {
                                                                        app.financialCostCenter_id = e.id
                                                                        setApportionmentsBills([...apportionmentsBills])
                                                                    }}
                                                                    selectedLabel={'valueInput'}
                                                                    optionList={['valueInput']}
                                                                />
                                                            </LabelInput>
                                                            <LabelInput text={'Vinculado:'}>
                                                                <InputAutoComplete
                                                                    data={optionsBooleansList}
                                                                    id={`isLinked-${index}`}
                                                                    preSelectedValue={optionsBooleansList?.find(item => item?.type == app?.isLinked)?.description}
                                                                    value={optionsBooleansList?.find(item => item?.type == app?.isLinked)?.description}
                                                                    onChange={(e) => {
                                                                        app.isLinked = e.type
                                                                        setApportionmentsBills([...apportionmentsBills])
                                                                    }}
                                                                    selectedLabel={'description'}
                                                                    optionList={['description']}
                                                                />
                                                            </LabelInput>
                                                            {
                                                                app?.isLinked ?
                                                                    <LabelInput text={'Vinculado com: *'}>
                                                                        <InputAutoComplete
                                                                            data={moduleLinks}
                                                                            id={`apportionmentLinked-${index}`}
                                                                            preSelectedValue={moduleLinks?.find(item => item?.linked_id == app?.linked_id && item?.linked == app?.linked)?.linked_name}
                                                                            value={moduleLinks?.find(item => item?.linked_id == app?.linked_id && item?.linked == app?.linked)?.linked_name}
                                                                            onChange={(e) => {
                                                                                app.linked_id = e.linked_id
                                                                                app.linked = e.linked
                                                                                setApportionmentsBills([...apportionmentsBills])
                                                                            }}
                                                                            selectedLabel={'linked_name'}
                                                                            optionList={['linked_id', 'linked_name']}
                                                                        />
                                                                    </LabelInput>
                                                                    :
                                                                    <></>
                                                            }
                                                            <LabelInput text={'Porcentagem (%): *'}>
                                                                <Input
                                                                    id={`apportionmentPercentage-${index}`}
                                                                    type={'number'}
                                                                    value={app.percentage}
                                                                    onChange={(e) => {
                                                                        app.percentage = parseFloat(e.target.value)
                                                                        setApportionmentsBills([...apportionmentsBills])
                                                                    }}
                                                                />
                                                            </LabelInput>
                                                        </div>
                                                        <div className='flex items-center my-2 gap-2'>
                                                            <span onClick={() => {
                                                                setApportionmentsBills((prev) => ([...prev, {
                                                                    financialCategories_id: undefined,
                                                                    financialCostCenter_id: undefined,
                                                                    percentage: undefined,
                                                                    isLinked: false,
                                                                    linked: undefined,
                                                                    linked_id: undefined,
                                                                }]))
                                                            }}>
                                                                <FaPlus size={14} className='text-primaryDefaultLight hover:cursor-pointer' />
                                                            </span>
                                                            <span
                                                                onClick={() => {
                                                                    apportionmentsBills.splice(index, 1)
                                                                    setApportionmentsBills([...apportionmentsBills])
                                                                }}>
                                                                <FaMinus size={14} className='text-primaryDefaultLight hover:cursor-pointer' />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>

                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button shadow={true} approval={false} onClick={() => navigate(-1)}>Cancelar</Button>
                    <Button shadow={true} approval={true} onClick={() => handleSubmit()}>Salvar</Button>
                </div>
            </Footer>
        </Container>
    )
}