//hooks
import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaUserAlt, FaDoorOpen, FaExchangeAlt } from 'react-icons/fa'
import { useAuth } from '../../contexts/useAuth'
import { menuItems } from './menuItems'
import { financialMenuItems } from './financialMenuItems'
import { clientMenuItems } from './clientMenuItems'
import styles from './styles/menu.module.css'
//components
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { Button } from '../buttons/button.default'
//contexts
import { useThemeContext } from '../../contexts/themeContext'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { LogonLoader } from '../loaders/logonLoader'
import { useApiContext } from '../../contexts/ApiInterceptorContext'

export function Menu() {

    const { api_auth, api } = useApiContext()
    const location = useLocation()
    const navigate = useNavigate()
    const moduleRef = useRef()
    const modalRef = useRef()
    const openModalRef = useRef()
    const { screenX } = useScreenSizeContext()
    const { setShowNotificationModal } = useThemeContext()
    const { selectedModule, setSelectedModule } = useAuth()
    const { userData, setUserData, setSigned } = useAuth()
    const [loading, setLoading] = useState(false)
    const [loadingExit, setLoadingExit] = useState(false)
    const [path, setPath] = useState(location.pathname)
    const [animateThisModal, setAnimateThisModal] = useState(false)
    const [showExitModal, setShowExitModal] = useState(false)
    const [showMyProfileInternalModal, setShowMyProfileInternalModal] = useState(false)
    const [userPic, setUserPic] = useState('')
    const [showMyProfile, setShowMyProfile] = useState(false)
    const [menuHeight, setMenuHeight] = useState('0')
    const [thisMenuItems, setThisMenuItens] = useState(userData[0]?.typeAccess_id < 4 ? selectedModule?.includes(3) ? financialMenuItems : menuItems : clientMenuItems)
    const [filteredIconsItens, setFilteredIconsItens] = useState(thisMenuItems)
    const [removedMenuItens, setRemovedMenuItens] = useState([])
    const [displayMenuHide, setDisplayMenuHide] = useState('none')
    const [displayCom, setDisplayCom] = useState('flex')
    const [isToHideMenu, setIsToHideMenu] = useState(true)
    const [showModuleModal, setShowModuleModal] = useState(false)
    let newFilteredItens = filteredIconsItens
    let removedItens = []

    const navegate = useNavigate()

    useEffect(() => {

        const menu = document.getElementById('menuBar')
        checkWindowSize()
        window.addEventListener('resize', () => checkWindowSize())

        function checkWindowSize() {
            if (menu.getClientRects()[0]?.height < window.innerHeight && window.innerWidth > 640) {
                setMenuHeight('100vh')
                filteredIcons()
            } else if (window.innerWidth > 640) {
                setMenuHeight(`${window.innerHeight}px`)
                filteredIcons()
            } else {
                setMenuHeight(`68px`)
                setRemovedMenuItens([])
                setFilteredIconsItens(thisMenuItems)
            }
        }

        function filteredIcons() {

            const menuHide = document.getElementById('menuHideDiv')

            if (newFilteredItens.length < thisMenuItems.length) {
                menuHide.children[1].style.bottom = '10px'
            } else if (menuHide) {
                menuHide.children[1].style.bottom = ''
            }

            if ((thisMenuItems.length + 1) * 55 <= window.innerHeight) {
                removedItens = []
                newFilteredItens = thisMenuItems
                setRemovedMenuItens(removedItens)
                setFilteredIconsItens(newFilteredItens)
            }
            else if (newFilteredItens.length * 55 >= window.innerHeight) {
                while ((newFilteredItens.length + 1) * 55 >= window.innerHeight) {
                    removedItens.push(newFilteredItens.slice(newFilteredItens.length - 1, newFilteredItens.length)[0])
                    newFilteredItens = newFilteredItens.slice(0, newFilteredItens.length - 1)
                    setRemovedMenuItens(removedItens)
                    setFilteredIconsItens(newFilteredItens)
                }
            }
            else if ((newFilteredItens.length + 1) * 55 < window.innerHeight) {
                if (removedItens[removedItens.length - 1]) {
                    newFilteredItens.push(removedItens[removedItens.length - 1])
                    removedItens = removedItens.slice(0, removedItens.length - 1)
                    setRemovedMenuItens(removedItens)
                    setFilteredIconsItens(newFilteredItens)
                }
            }
        }

        return () => window.removeEventListener('resize', (e) => checkWindowSize(e))

    }, [])

    useEffect(() => {
        if (isToHideMenu) {
            handleStatusMenu(true)
        }
    }, [isToHideMenu])

    useEffect(() => {
        if (!userData[0]?.customersData?.useBalance) {
            setThisMenuItens(thisMenuItems.filter(menu => {
                if (menu?.path.indexOf('Balance') === -1) {
                    return menu
                }
            }))
        }
        setFilteredIconsItens(thisMenuItems)

        async function getPic() {

            try {

                const response = await api_auth.get('/api/v1/auth/myProfile/picture', {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: 'Bearer ' + userData[0].token
                    }
                })

                setUserPic(URL.createObjectURL(response.data))
                setLoading(false)
            } catch (error) {
                setUserPic('')
                setLoading(false)
            }
        }

        getPic()

    }, [userData])

    useEffect(() => {
        function handler(event) {
            if (!modalRef.current?.contains(event.target) && !openModalRef.current?.contains(event.target)) {
                if (modalRef.current.className.indexOf('hidden') === -1) {
                    setShowMyProfile(false)
                }
            }
            if (moduleRef.current?.contains(event.target)) {
                if (moduleRef.current.className.indexOf('hide') === -1) {                    
                    setShowModuleModal(false)
                    document.getElementById('selectedModule').classList.remove('show')
                    document.getElementById('selectedModule').classList.add('hide')
                } else {
                    document.getElementById('selectedModule').classList.remove('hide')
                    document.getElementById('selectedModule').classList.add('show')
                    setShowModuleModal(true)
                }
            } else {
                document.getElementById('selectedModule').classList.remove('show')
                document.getElementById('selectedModule').classList.add('hide')
                setShowModuleModal(false)
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    }, [])

    useEffect(() => {

        async function animateModal(status) {

            await new Promise(resolve => setTimeout(resolve, 60))
            showExitModal ? setAnimateThisModal(true) : setAnimateThisModal(false)
        }

        if (showExitModal) {
            animateModal(true)
        } else {
            animateModal(false)
        }
    }, [showExitModal])

    useEffect(() => {

        async function animateProfile(status) {

            if (status) {
                await new Promise(resolve => setTimeout(resolve, 200))
                setShowMyProfileInternalModal(true)
            } else {
                setShowMyProfileInternalModal(false)
                await new Promise(resolve => setTimeout(resolve, 200))
                setShowMyProfile(false)
            }
        }

        animateProfile(showMyProfile)

    }, [showMyProfile])

    useEffect(() => {
        const menuItemExists = thisMenuItems?.find(item => item?.path == location?.pathname)
        if (menuItemExists) setPath(location.pathname)
    }, [location])

    async function handleStatusMenu() {

        const ul = document.getElementById('ulHide')?.getAttribute('disabled')

        if (ul === 'true') {
            await new Promise(resolve => setTimeout(resolve, 500))

            setDisplayCom('flex')
            setDisplayMenuHide('none')
        }
    }

    const handleExit = async () => {
        setLoadingExit(true)
        try {

            await api_auth.post('/api/auth/logout', {
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            localStorage.removeItem('@auth:token')
            navigate('/' + userData[0]?.customers_app_name)
            setUserData('')
            setSigned(false)
            setLoadingExit(true)

        } catch (error) {
            localStorage.removeItem('@auth:token')
            navigate('/')
            setUserData('')
            setSigned(false)
            setLoadingExit(true)
            setLoadingExit(true)
            console.log(error)
        }
    }

    //altera o modulo selecionado
    async function changeModule(module) {

        setLoading(true)
        await new Promise(r => setTimeout(r, 60))
        setSelectedModule([module.id])
        setFilteredIconsItens(
            (module.id === 1 || module.id === 2) ? menuItems :
                module.id === 3 ? financialMenuItems :
                    []
        )
        let moduleSelected = (module.id === 1 || module.id === 2) ? '[1,2]' :
            `[${module.id}]`

        localStorage.setItem('@agenda-rapido-selectedModule', moduleSelected)
        setLoading(false)
        navigate("/")

    }

    return (
        <>
            {
                showExitModal &&
                <div className='flex flex-col'>
                    <div className={`${showExitModal ? 'flex' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] top-0 h-[100vh] sm:h-full z-[1000] `}>
                        {
                            loadingExit ?
                                <div className='flex flex-col'>
                                    <LogonLoader />
                                </div>
                                :
                                <div className={`${animateThisModal ? 'translate-y-0' : '-translate-y-96'} transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow`}>
                                    <div className='flex flex-row justify-between items-center p-2'>
                                        <h1 className='text-sm text-titleBlackTextLight dark:text-titleGrayTextLight'>Sair do sistema</h1>
                                        <button onClick={() => setShowExitModal(false)} className='bg-gray-300 w-6 h-6 rounded-full'>x</button>
                                    </div>
                                    <div className='flex flex-col items-center justify-center overflow-x-hidden'>
                                        <div className='flex flex-col items-center justify-center w-96 p-4'>
                                            <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm mb-6'>Deseja realmente sair do sistema?</p>
                                            <div className='flex flex-row w-full justify-center gap-4 items-center'>
                                                <Button approval={true} onClick={handleExit} shadow={true}>Sim</Button>
                                                <Button approval={false} onClick={() => setShowExitModal(false)} shadow={true}>Não</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className={`${showExitModal ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-full h-[100vh] sm:h-full z-[103]`}></div>
                </div>
            }
            <div className='absolute z-[1000] bottom-12 left-16 flex flex-col items-start justify-start gap-2'>
                {
                    userData[0]?.typeAccess_id < 4 &&
                    userData[0]?.customersData?.modules?.filter(moduleToView => !selectedModule?.includes(moduleToView.id)).map(module => {
                        if (module.id !== 1) {
                            return (
                                <div
                                    onClick={() => changeModule(module)}
                                    className={`${showModuleModal ? 'flex' : 'hidden'} whitespace-nowrap animate-[wiggleEasy_.3s_ease-in-out] 
                                                    left-0 cursor-pointer hover:brightness-95 transition-all duration-150 flex-col items-center 
                                                    justify-center  bg-white dark:bg-secondaryDefaultDark border dark:border-thirdDefaultDark border-zinc-200 p-2 rounded-lg shadow-lg text-primaryDefaultLight`}
                                >
                                    <a className='text-sm'>Módulo {module.id == 2 ? "Reservas e Inscrições" : module.name}</a>
                                </div>
                            )
                        }
                    })
                }
            </div>
            <div ref={modalRef} className={`${showMyProfile ? 'flex' : 'hidden'} sm:items-baseline items-start justify-center sm:justify-start w-full bottom-20 sm:w-auto fixed top-0 md:top-2 left-0 sm:left-16 z-30 flex-col  `}>
                <div className={`${showMyProfileInternalModal ? 'opacity-1 translate-y-0' : 'translate-y-10 opacity-0 max-h-0'} flex flex-col sm:items-baseline items-start justify-center sm:justify-start  w-full h-full transition-all duration-300 bg-bgPrimaryLight dark:bg-primaryDefaultDark p-6 gap-8 sm:gap-2 boxShadow border border-secondaryDefaultLight dark:border dark:border-thirdDefaultDark sm:h-48`}>
                    <div className='flex flex-col w-full sm:items-start items-start justify-start sm:justify-center '>
                        <h2 className='text-primaryDefaultLight dark:text-titleGrayTextDark mt-2 mb-4 font-bold text-3xl sm:text-base'>{userData[0].firstName}</h2>
                    </div>
                    <div onClick={() => { navigate(`/myProfileEdit/${userData[0]?.id}`); setShowMyProfile(false) }} className='cursor-pointer flex flex-row sm:gap-2 gap-8 w-full text-2xl sm:text-base items-start justify-start hover:brightness-50 duration-200 transition-all text-primaryDefaultLight'>
                        <FaUserAlt />
                        <a className='sm:text-sm text-2xl text-primaryDefaultLight'>Meu perfil</a>
                    </div>
                    <div onClick={() => { setShowExitModal(true); }} title='Sair do sistema' className='text-2xl sm:text-base cursor-pointer flex flex-row sm:gap-2 gap-8 w-full items-start sm:justify-start justicenter hove duration-200 transition-all text-primaryDefaultLight'>
                        <FaDoorOpen />
                        <a className='sm:text-sm text-2xl text-primaryDefaultLight'>Sair</a>
                    </div>
                    <button onClick={() => setShowMyProfile(!showMyProfile)} className='sm:hidden flex flex-col items-center justify-center absolute right-8 top-8 border p-2 border-primaryDefaultLight rounded-full w-10 h-10 text-titleBlackTextLight font-bold bg-primaryDefaultLight'>X</button>
                </div>
            </div>
            <div id='menuBar' style={{ height: menuHeight }} className={`${styles.menuContainer} boxShadow z-[102] sm:boxShadow overflow-x-auto 
                sm:overflow-hidden flex flex-row sm:flex-col gap-2 items-center justify-start sm:w-16 bg-gradient-to-t from-secondaryDefaultLight to-primaryDefaultLight dark:bg-gradient-to-t 
                dark:from-secondaryDefaultDark dark:via-primaryDefaultDark dark:to-secondaryDefaultDark dark:text-titleGrayTextLight dark:border-x dark:border-primaryBorderDark sm:pr-0`}>
                <div
                    ref={openModalRef} onClick={() => { setShowMyProfile(!showMyProfile); setShowNotificationModal(false) }}
                    key={`menu-1-profile`} id={`myProfile`}
                    className={`${userPic?.length > 0 ? 'bg-transparent' : 'bg-primaryDefaultLight dark:bg-secondaryDefaultDark'} 
                    w-12 hover:from-secondaryDefaultLight hover:to-primaryDefaultLight p-8 h-full sm:h-auto text-titleGrayTextLight 
                    dark:text-primaryDefaultLight flex flex-col gap-2 items-center justify-center text-2xl cursor-pointer md:hover:scale-110 
                    transition-all duration-200 py-2`}
                >
                    <div className={`${userPic?.length > 0 ? 'bg-transparent' : 'bg-primaryLight dark:bg-secondaryDefaultDark'} h-10 w-10 bg-primaryDefaultLight rounded-full flex flex-col items-center justify-center`}>
                        {
                            userPic?.length > 0 ?
                                <div className='flex flex-col items-center justify-center rounded-full w-10 h-10 overflow-hidden'>
                                    <img src={userPic}></img>
                                </div>
                                :
                                <a className='text-titleGrayTextLight'>{userData[0]?.firstName.slice(0, 1)}</a>
                        }
                    </div>
                </div>
                {
                    !loading &&
                    <>
                        {
                            filteredIconsItens.map((item, index) => {
                                return (
                                    //verifica se tem permissão ao modulo na propriedade "module" do menuItems
                                    !item?.module ||
                                    userData[0]?.customersData?.modules?.map(module => { return module.id })?.includes(item?.module)
                                ) && (
                                        userData[0]?.permissions.filter((userPermission) => item?.permissionsGroup?.includes(userPermission)).length > 0
                                        || userData[0]?.typeAccess_id != 3
                                        || item.path === '/configuration'
                                    ) ?
                                    screenX > 640 ?
                                        <Tippy
                                            key={`${index}-tippy`}
                                            content={<span>{item?.name}</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='right'
                                            delay={100}
                                        >
                                            <div
                                                key={`${item.name}-${index}`}
                                                id={`${item.name}-${index}`}
                                                onClick={() => { navegate(item.path); setShowNotificationModal(false) }}
                                                className={`
                                                ${path === item?.path ? 'bg-secondaryDefaultLight dark:bg-primaryDefaultDark dark:border dark:border-secondaryBorderDark' : ''}
                                                animate-[wiggle_1s_ease-in-out] text-titleGrayTextLight dark:text-primaryDefaultLight 
                                                flex flex-col items-center justify-center text-2xl cursor-pointer hover:bg-secondaryDefaultLight 
                                                dark:hover:bg-thirdDefaultDark p-2 rounded-lg transition-all duration-200
                                            `}
                                            >
                                                {item.icon}
                                                <a className='text-xt sm:hidden flex 2xl:text-xs font-light text-titleGrayTextLight dark:to-titleGrayTextDark'>
                                                    {item.name}
                                                </a>
                                            </div>
                                        </Tippy>
                                        :
                                        <div
                                            id={`${item.name}-${index}`}
                                            style={{ minWidth: '90px' }}
                                            key={`${item.name}-${index}`}
                                            onClick={() => { navegate(item.path); setShowNotificationModal(false) }}
                                            className={`
                                            ${path === item?.path ? 'bg-secondaryDefaultLight dark:bg-primaryDefaultDark dark:border dark:border-secondaryBorderDark' : ''}
                                            text-titleGrayTextLight dark:text-primaryDefaultLightw  flex flex-col 
                                            items-center justify-start pt-2 text-xl cursor-pointer 
                                            hover:bg-secondaryDefaultLight rounded-lg 
                                            transition-all duration-200
                                        `}
                                        >
                                            {item.icon}
                                            <a className='text-xt sm:hidden 2xl:text-xs font-light text-center text-titleGrayTextLight dark:to-titleGrayTextDark'>{item.name}</a>
                                        </div>
                                    :
                                    ''
                            })
                        }
                    </>
                }
                {/* Abre menu de selecão de modulos */}
                <div
                    ref={moduleRef}
                    id="selectedModule"
                    className={`
                            ${showModuleModal ? 'show' : 'hide'} 
                            cursor-pointer transition-all duration-150 absolute  hidden sm:flex flex-col items-center
                            justify-center bottom-4 w-16
                        `}
                >
                    {
                        userData[0]?.typeAccess_id < 4 &&
                        <Tippy
                            content={<span>{'Clique para selecionar o módulo desejado'}</span>}
                            arrow={true}
                            animation='perspective'
                            placement='right'
                        >
                            <div className='flex flex-col items-center justify-center text-center gap-1'>
                                <a className='text-xs text-white '>
                                    {selectedModule?.includes(3) ? 'Financeiro' : 'Reservas e Inscrições'}
                                </a>
                                <FaExchangeAlt className='text-sm text-white' />
                            </div>
                        </Tippy>
                    }
                </div>
                {/* fim do menu de seleção de módulos */}
            </div>
        </>

    )
}