export function generateCsv(data) {
  const csv = convertToCSV(data);
  const bom = '\uFEFF'; // Adiciona o BOM para suportar caracteres especiais
  const blob = new Blob([bom + csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', 'data.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function convertToCSV(data) {
  const header = Object.keys(data[0]).join(',') + '\n';
  const rows = data
    .map(obj => 
      Object.values(obj)
        .map(value => `"${String(value).replace(/"/g, '""')}"`) // Escapa aspas
        .join(',')
    )
    .join('\n');
  return header + rows;
}
