import React, { useState, useEffect } from "react";
import { Container } from "../../../../components/container/container";
import { TitlePage } from "../../../../components/titlePages/title.page";
import { Title } from "../../../../components/titlePages/title";
import { Body } from "../../../../components/container/Body";
import { LabelInput } from "../../../../components/label/label.input";
import { Input } from "../../../../components/input/input";
import { Button } from "../../../../components/buttons/button.default";
import { useAuth } from "../../../../contexts/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { useThemeContext } from "../../../../contexts/themeContext";
import { validateFields } from "../../../../utils/form.validator";
import moment from "moment";
import tableToExcel from "../../../../utils/excelFunctions/tableToExcel";
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { useApiContext } from "../../../../contexts/ApiInterceptorContext";

export function FinancialReportsCashFlowMonthly() {

    const navigate = useNavigate()
    const { defaultCallsFinancial } = useApiContext()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, showUniversalModal, setShowUniversalModal } = useThemeContext()
    const { incomeOrExpense } = useParams()
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({
        incomeOrExpense
    })
    const [categoriesGroupIncome, setCategoriesGroupIncome] = useState([])
    const [categoriesGroupExpense, setCategoriesGroupExpense] = useState([])
    const [categories, setCategories] = useState([])
    const [data, setData] = useState([])
    const [generatedReport, setGeneratedReport] = useState(false)

    useEffect(() => {
        getData()
    }, [])
    
    async function getData(){

        const getCategoriesGroup = await defaultCallsFinancial.getFinancialCategoriesGroups(userData[0]?.token)
        if (getCategoriesGroup.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(getCategories.data)
        }
        setCategoriesGroupIncome(getCategoriesGroup.data?.filter(dat => dat?.incomeOrExpense == 'R'))
        setCategoriesGroupExpense(getCategoriesGroup.data?.filter(dat => dat?.incomeOrExpense == 'D'))
        
        const getCategories = await defaultCallsFinancial.getFinancialCategories(userData[0]?.token)
        if (getCategories.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(getCategories.data)
        }
        setCategories(getCategories.data)

    }

    async function generateReport(){

        const requiredFields = [
            { name: 'startDate', value: filter?.startDate, required: true, type: 'string' },
            { name: 'finishDate', value: filter?.finishDate, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowUniversalModal(false)
            setLoading(false)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        setLoading(true)        

        const getData = await defaultCallsFinancial.getFinancialReportsCashFlowMonthly(userData[0]?.token, filter)
        if (getData.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(getData.data)
        }

        setData(getData.data)     
        setGeneratedReport(true)   
        setLoading(false)
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Fluxo de caixa mensal'}/>
            </TitlePage>
            <Body background={false} shadow={false}>
                <div className="w-full h-full relative">
                    <div className="absolute w-full bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex flex-col sm:flex-row justify-between w-full items-center">
                            <div className="flex flex-col sm:flex-row sm:gap-2 gap-4 sm:items-end items-center">
                                <LabelInput text={'Data inicial'} required>
                                    <Input
                                        type={'date'}
                                        width={60}
                                        onChange={(e) => setFilter(prev => ({...prev, startDate: e?.target?.value}))}
                                        value={filter?.startDate ? filter?.startDate : ''}
                                        id={'startDate'}
                                    />
                                </LabelInput>
                                <LabelInput text={'Data final'} required>
                                    <Input
                                        type={'date'}
                                        width={60}
                                        onChange={(e) => setFilter(prev => ({...prev, finishDate: e?.target?.value}))}
                                        value={filter?.finishDate ? filter?.finishDate : ''}
                                        id={'finishDate'}
                                    />
                                </LabelInput>
                                <Button height={8} onClick={() => generateReport()}>Gerar</Button>
                                <div className="flex sm:hidden">
                                    <Button height={8} onClick={() => tableToExcel(`${Number(new Date)}.xls`)}>Baixar em excel</Button>
                                </div>
                            </div>
                            {
                                data?.length > 0 &&
                                <div className="hidden sm:flex items-end h-full pr-2">
                                    <Tippy content={'Exportar em excel'}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top'
                                        delay={100}>
                                        <div>
                                            <FaCloudDownloadAlt className='text-xl cursor-pointer text-primaryDefaultLight' onClick={() => tableToExcel(`${Number(new Date)}.xls`)}/>
                                        </div>
                                    </Tippy>
                                    <a id="link-to-download" href="!#">{}</a>
                                </div>
                            }
                        </div>
                        {
                            loading ? 
                            <div className="w-full h-40 sm:h-96 flex items-center justify-center">
                                <p className="animate-pulse">Gerando relatório...</p>
                            </div>
                            : data?.length > 0 ?
                            <div className={`flex flex-col h-full mt-8 w-full`}>
                                <div className="flex h-full md:max-h-[42vh] lg:max-h-[53vh] xl:max-h-[60vh] 2xl:max-h-[63vh] flex-col pb-0 shadow-xl pr-0 overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 rounded-md focus-visible:outline-none focus-visible:border-none">
                                    <table className='border-collapse' id='TableToExport'>
                                        <thead className='sticky z-50 m-0 p-0 text-white font-bold bg-primaryDefaultLight primaryDefaultLight'>
                                            <tr className='sticky z-20 top-0'>
                                                <th className='sm:sticky -left-1 z-5 top-0 bg-primaryDefaultLight' rowSpan={2}>
                                                    <div className='min-w-[10rem] z-5 p-0 text-sm'>
                                                        Categorias de Lançamentos
                                                    </div>
                                                </th>
                                                {
                                                    data?.map((item, index) => (
                                                        <th key={index} className='bg-primaryDefaultLight text-sm' colSpan={2}>                                                                    
                                                            {item?.month_description}
                                                        </th>
                                                    ))
                                                }
                                            </tr>
                                            <tr className='sticky z-10 top-[22px]'>
                                                {
                                                    data?.map((_, index) => (
                                                        <React.Fragment key={index}>
                                                            <th className='min-w-[8rem] bg-primaryDefaultLight text-sm text-center'>
                                                                Previsto
                                                            </th>
                                                            <th className='min-w-[8rem] bg-primaryDefaultLight text-sm text-center'>
                                                                Realizado
                                                            </th>
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className='pb-2 m-0'>                                            
                                            <tr className='sticky bottom-0 text-sm'>
                                                <td className='sm:sticky -left-1 pl-5 bg-gray-200 green-500'>
                                                    <div className='min-w-[15rem] z-5 py-1 border-0 text-sm text-emerald-500 font-semibold tracking-wide'>
                                                        Total de Recebimentos
                                                    </div>
                                                </td>
                                                {
                                                    data?.map((item, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <td className='bg-gray-200 text-sm text-center'>
                                                                    {item?.valueIncomePlanneds?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                </td>
                                                                <td className='bg-gray-200 text-sm text-center'>
                                                                    {item?.valueIncomeRealized?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                </td>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            {
                                                categoriesGroupIncome?.map((group, index) => {                                            
                                                    const filterCategories = categories?.filter(item => item?.financialCategoriesGroups_id == group?.id)                                          
                                                    return (
                                                        <>
                                                            <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`}>                                                        
                                                                <td className={`m-0 p-0 sm:z-10 sm:sticky ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3 -left-1 green-500`}>
                                                                    <div className='min-w-[15rem] py-1 px-1 border-0 font-bold flex text-sm justify-start dark:text-white items-center gap-1 border-l-2 border-l-green-500'>
                                                                        {
                                                                            !group.expanded ?
                                                                            <FaAngleRight className="cursor-pointer" onClick={() => {
                                                                                group.expanded = !group.expanded
                                                                                setCategoriesGroupIncome([...categoriesGroupIncome])
                                                                            }}/>
                                                                            :
                                                                            <FaAngleDown className="cursor-pointer" onClick={() => {
                                                                                group.expanded = !group.expanded
                                                                                setCategoriesGroupIncome([...categoriesGroupIncome])
                                                                            }}/>
                                                                        }
                                                                        {group?.description}
                                                                    </div>
                                                                </td>
                                                                {
                                                                    data?.map(item => {
                                                                        const group_filterIncomePlanneds = item?.incomePlannedsPerCategories?.filter(inc => inc?.financialCategoriesGroups_id == group?.id)
                                                                        const group_filterIncomeRealizeds = item?.incomeRealizedsPerCategories?.filter(inc => inc?.financialCategoriesGroups_id == group?.id)

                                                                        const group_totalValueIncomePlanneds = group_filterIncomePlanneds.reduce((acc, i) => acc += i.value, 0)
                                                                        const group_totalValueIncomeRealizeds = group_filterIncomeRealizeds.reduce((acc, i) => acc += i.value, 0)

                                                                        return (
                                                                            <>
                                                                                <td className='text-sm text-center'>
                                                                                    {group_totalValueIncomePlanneds?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                </td>
                                                                                <td className='text-sm text-center'>
                                                                                    {group_totalValueIncomeRealizeds?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                </td>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>                                               
                                                            {
                                                                group?.expanded &&
                                                                filterCategories?.map(category => {                                                                                                                                  
                                                                    return (
                                                                        <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                                                            <td className={`m-0 p-0 sm:z-10 sm:sticky -left-1 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                                                <div className='min-w-[15rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white border-l-2 border-l-green-500'>
                                                                                    {category?.description}
                                                                                </div>
                                                                            </td>
                                                                            {
                                                                                data?.map(item => {
                                                                                    const category_filterIncomePlanneds = item?.incomePlannedsPerCategories?.filter(inc => inc?.financialCategories_id == category?.id)
                                                                                    const category_filterIncomeRealizeds = item?.incomeRealizedsPerCategories?.filter(inc => inc?.financialCategories_id == category?.id)

                                                                                    const category_totalValueIncomePlanneds = category_filterIncomePlanneds.reduce((acc, i) => acc += i.value, 0)
                                                                                    const category_totalValueIncomeRealizeds = category_filterIncomeRealizeds.reduce((acc, i) => acc += i.value, 0)

                                                                                    return (
                                                                                        <>
                                                                                            <td className='text-sm text-center'>
                                                                                                {category_totalValueIncomePlanneds?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                            </td>
                                                                                            <td className='text-sm text-center'>
                                                                                                {category_totalValueIncomeRealizeds?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                            </td>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                            <tr className='sticky bottom-0 text-sm'>
                                                <td className='sm:sticky sm:z-10 -left-1 pl-5 bg-gray-200 red-500'>
                                                    <div className='min-w-[15rem] z-5 py-1 border-0 text-sm text-red-500 font-semibold tracking-wide'>
                                                        Total de Pagamentos
                                                    </div>
                                                </td>
                                                {
                                                    data?.map((item, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <td className='bg-gray-200 text-sm text-center'>
                                                                    {item?.valueExpensePlanneds?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                </td>
                                                                <td className='bg-gray-200 text-sm text-center'>
                                                                    {item?.valueExpenseRealized?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                </td>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            {
                                                categoriesGroupExpense?.map((group, index) => {                                            
                                                    const filterCategories = categories?.filter(item => item?.financialCategoriesGroups_id == group?.id)                                        
                                                    return (
                                                        <>
                                                            <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`}>                                                        
                                                                <td className={`m-0 p-0 sm:sticky -left-1 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3 red-500`}>
                                                                    <div className='min-w-[15rem] py-1 px-1 border-0 font-bold flex text-sm justify-start dark:text-white items-center gap-1 border-l-2 border-l-red-500'>
                                                                        {
                                                                            !group.expanded ?
                                                                            <FaAngleRight className="cursor-pointer" onClick={() => {
                                                                                group.expanded = !group.expanded
                                                                                setCategoriesGroupExpense([...categoriesGroupExpense])
                                                                            }}/>
                                                                            :
                                                                            <FaAngleDown className="cursor-pointer" onClick={() => {
                                                                                group.expanded = !group.expanded
                                                                                setCategoriesGroupExpense([...categoriesGroupExpense])
                                                                            }}/>
                                                                        }
                                                                        {group?.description}
                                                                    </div>
                                                                </td>
                                                                {
                                                                    data?.map(item => {
                                                                        const group_filterExpensePlanneds = item?.expensePlannedsPerCategories?.filter(exp => exp?.financialCategoriesGroups_id == group?.id)
                                                                        const group_filterExpenseRealizeds = item?.expenseRealizedsPerCategories?.filter(exp => exp?.financialCategoriesGroups_id == group?.id)

                                                                        const group_totalValueExpensePlanneds = group_filterExpensePlanneds.reduce((acc, i) => acc += i.value, 0)
                                                                        const group_totalValueExpenseRealizeds = group_filterExpenseRealizeds.reduce((acc, i) => acc += i.value, 0)

                                                                        return (
                                                                            <>
                                                                                <td className='text-sm text-center'>
                                                                                    {group_totalValueExpensePlanneds?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                </td>
                                                                                <td className='text-sm text-center'>
                                                                                    {group_totalValueExpenseRealizeds?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                </td>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>                                               
                                                            {
                                                                group?.expanded &&
                                                                filterCategories?.map(category => {                                                                                                                                                                                                          
                                                                    return (
                                                                        <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                                                            <td className={`m-0 p-0 sm:z-10 sm:sticky -left-1 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                                                <div className='min-w-[15rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white border-l-2 border-l-red-500'>
                                                                                    {category?.description}
                                                                                </div>
                                                                            </td>
                                                                            {
                                                                                data?.map(item => {
                                                                                    const category_filterExpensePlanneds = item?.expensePlannedsPerCategories?.filter(exp => exp?.financialCategories_id == category?.id)
                                                                                    const category_filterExpenseRealizeds = item?.expenseRealizedsPerCategories?.filter(exp => exp?.financialCategories_id == category?.id)

                                                                                    const category_totalValueExpensePlanneds = category_filterExpensePlanneds.reduce((acc, i) => acc += i.value, 0)
                                                                                    const category_totalValueExpenseRealizeds = category_filterExpenseRealizeds.reduce((acc, i) => acc += i.value, 0)

                                                                                    return (
                                                                                        <>
                                                                                            <td className='text-sm text-center'>
                                                                                                {category_totalValueExpensePlanneds?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                            </td>
                                                                                            <td className='text-sm text-center'>
                                                                                                {category_totalValueExpenseRealizeds?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                            </td>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : generatedReport &&
                            <p className="w-full flex items-center justify-center text-primaryDefaultLight mt-8">
                                Não há registros
                            </p>
                        }
                    </div>
                </div>
            </Body>
        </Container>
    )

}