// hooks 
import React, { useState, useEffect } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom'
import './login.css'
// img
import LoginLogo from '../../assets/logos/loginLogo.png'
import bgLogin from '../../assets/default_assets/bg-login.jpg'
// contexts
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { useAuth } from '../../contexts/useAuth'
import { useThemeContext } from '../../contexts/themeContext'
//components
import { LoginLoader } from '../../components/loaders/loginLoader'
import { LabelInput } from '../../components/label/label.input'
import { Input } from '../../components/input/input'
import { Button } from '../../components/buttons/button.default'
//services
import { FaArrowAltCircleLeft } from 'react-icons/fa'
import { PiSmileySadDuotone } from 'react-icons/pi'
//utils
import { mCPF } from '../../utils/validators/cpfMask'
import { removeEspecialChar } from '../../utils/validators/removeEspecialCharacter'
import { colors } from '../../theme/colorsSchema'
import { justNumber } from '../../utils/validators/justNumber'
import { responseError } from '../../utils/responsesFunctions/error.response'
import { validateFields } from '../../utils/form.validator'
import { DefaultLoader } from '../../components/loaders/defaultLoader'
import { LogonLoader } from '../../components/loaders/logonLoader'
import moment from 'moment'
import { LoginPublicSchedules } from './loginPublicSchedules'
import { useApiContext } from '../../contexts/ApiInterceptorContext'

export function Login() {
    const { defaultCallsSchedules, api_auth, defaultCallsAuth, defaultCallsFinancial } = useApiContext()
    const navigate = useNavigate()
    const { usersId, userTypeAccess, app_name } = useParams()
    const { screenX, screenY } = useScreenSizeContext()
    const { setSigned, setUserData, setCustomersAlerts, setHaveTermsOfUse, setDontHaveEmail } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const date = Date.now()
    //light
    const bgPrimaryLight = '#FFFF'
    const bgSecondaryLight = '#ebf1f5'
    const titleGrayTextLight = '#FFFF'
    const inputPlaceholderLight = '#8F8F8F'
    const primaryLightSuccess = '#64B694'
    const [primaryDefaultLight, setPrimaryDefaultLight] = useState(colors.filter((color) => { if (color.name === 'default') { return color } })[0].primaryColor)
    const [secondaryDefaultLight, setSecondaryDefaultLight] = useState(colors.filter((color) => { if (color.name === 'default') { return color } })[0].primaryColor)
    //dark
    const hoverDark = '#2a2f36'
    const titleBlackTextDark = '#2a2f36'
    const titleGrayTextDark = '#C4C4C4'
    const primaryBorderDark = '#363E48'
    const secondaryBorderDark = '#444c56'
    const primaryDefaultDark = ''
    const secondaryDefaultDark = ''

    const [showErrorModal, setShowErrorModal] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const [customers_code, setCustomers_code] = useState('')
    const [customersData, setCustomersData] = useState('')

    const [signUp, setSignUp] = useState(false)
    const [showSignUp, setShowSignUp] = useState(false)
    const [errorFields, setErrorFields] = useState([])
    const [loading, setLoading] = useState(true)
    const [recoverPassword, setRecoverPassword] = useState(false)
    const [showResendActivateAccount, setShowResendActivateAccount] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showHelp, setShowHelp] = useState(false)
    const [forgetPassword, setForgetPassword] = useState(false)
    const [animateHelp, setAnimateHelp] = useState(false)
    const [animateErrorModal, setAnimateErrorModal] = useState(false)
    const [animateSuccessModal, setAnimateSuccessModal] = useState(false)
    const [clientFinded, setClientFinded] = useState(true)
    const [baseNotExists, setBaseNotExists] = useState(false)
    const [user, setUser] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [cpf, setCpf] = useState('')
    const [birthday, setBirthday] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [dddcell, setDddcell] = useState('')
    const [cell, setCell] = useState('')
    const [appName, setAppName] = useState('')

    useEffect(() => {
        setShowNotificationModal(false)
        setLoading(true)

        async function getCustomersData() {
            let dotPosition = window.location.hostname.indexOf('.')
            const thisCustomers = window.location.hostname.slice(0, dotPosition)
            setCustomers_code(thisCustomers)
            setDefaultColors()
            if (thisCustomers == 'app' && !app_name) {
                setClientFinded(false)
                setLoading(false)
                return
            }
            if (thisCustomers != 'app' && app_name) {
                navigate('/')
                return
            }

            try {
                const customersData = await api_auth.get(`/api/auth/public/customers/${app_name ?? thisCustomers}`)

                if(customersData.data.data[0]?.code == 'app' && thisCustomers !== 'app'){
                    setClientFinded(false)
                    setBaseNotExists(true)
                    setLoading(false)
                    return
                }

                if (customersData.data.data[0].photo?.length === 0) {
                    setCustomersData({
                        ...customersData?.data?.data[0],
                        photo: ''
                    })
                } else {
                    setCustomersData(customersData?.data?.data[0])
                }

                document.documentElement.style.setProperty('--bgPrimaryLight', bgPrimaryLight)

                const customerPrimaryColor = customersData?.data?.data[0]?.primaryColor
                const customerSecundaryColor = customersData?.data?.data[0]?.secundaryColor

                if (customersData?.data?.data[0]?.blockColor) {
                    document.documentElement.style.setProperty('--primaryDefaultLight', customerPrimaryColor ?? primaryDefaultLight)
                    document.documentElement.style.setProperty('--secondaryDefaultLight', customerSecundaryColor ?? secondaryDefaultLight)
                    setPrimaryDefaultLight(customerPrimaryColor ?? primaryDefaultLight)
                    setSecondaryDefaultLight(customerSecundaryColor ?? secondaryDefaultLight)
                } else {
                    if (!localStorage.getItem('primaryDefaultLight')) {
                        document.documentElement.style.setProperty('--primaryDefaultLight', customerPrimaryColor ?? primaryDefaultLight)
                        document.documentElement.style.setProperty('--secondaryDefaultLight', customerSecundaryColor ?? secondaryDefaultLight)
                    } else {
                        document.documentElement.style.setProperty('--primaryDefaultLight', localStorage.getItem('primaryDefaultLight'))
                        setPrimaryDefaultLight(localStorage.getItem('primaryDefaultLight'))
                        document.documentElement.style.setProperty('--secondaryDefaultLight', localStorage.getItem('secondaryDefaultLight'))
                        setSecondaryDefaultLight(localStorage.getItem('secondaryDefaultLight'))
                    }
                }

                setClientFinded(true)
                setLoading(false)
            } catch (error) {
                // window.location.assign('https://www.agendarapido.com')
                setLoading(false)
                setClientFinded(false)
                if (customers_code !== 'app' && !app_name) {
                    navigate('/')
                    setBaseNotExists(true) 
                }
                setShowError(true)
            }
        }

        getCustomersData()
    }, [app_name])

    useEffect(() => {
        if (signUp) {
            setShowSignUp(true)
        } else {
            setShowSignUp(false)
        }
    }, [signUp])

    useEffect(() => {
        async function changeHelpDisplay() {
            await new Promise(resolve => setTimeout(resolve, 100))
            showHelp ? setAnimateHelp(true) : setAnimateHelp(false)
        }
        changeHelpDisplay()
    }, [showHelp])

    useEffect(() => {
        async function changeHelpDisplay() {
            await new Promise(resolve => setTimeout(resolve, 100))
            showErrorModal ? setAnimateErrorModal(true) : setAnimateErrorModal(false)
        }
        changeHelpDisplay()
    }, [showErrorModal])

    useEffect(() => {
        async function changeHelpDisplay() {
            await new Promise(resolve => setTimeout(resolve, 100))
            showSuccessModal ? setAnimateSuccessModal(true) : setAnimateSuccessModal(false)
        }
        changeHelpDisplay()
    }, [showSuccessModal])

    function setDefaultColors() {
        //lightColors
        document.documentElement.style.setProperty('--primaryDefaultLight', primaryDefaultLight)
        document.documentElement.style.setProperty('--secondaryDefaultLight', secondaryDefaultLight)
        document.documentElement.style.setProperty('--bgPrimaryLight', bgPrimaryLight)
        document.documentElement.style.setProperty('--bgSecondaryLight', bgSecondaryLight)
        document.documentElement.style.setProperty('--titleGrayTextLight', titleGrayTextLight)
        document.documentElement.style.setProperty('--inputPlaceholderLight', inputPlaceholderLight)
        document.documentElement.style.setProperty('--primaryLightSuccess', primaryLightSuccess)
        //darkColors
        document.documentElement.style.setProperty('--hoverDark', hoverDark)
        document.documentElement.style.setProperty('--titleBlackTextDark', titleBlackTextDark)
        document.documentElement.style.setProperty('--titleGrayTextDark', titleGrayTextDark)
        document.documentElement.style.setProperty('--primaryBorderDark', primaryBorderDark)
        document.documentElement.style.setProperty('--secondaryBorderDark', secondaryBorderDark)
        document.documentElement.style.setProperty('--primaryDefaultDark', primaryDefaultDark)
        document.documentElement.style.setProperty('--secondaryDefaultDark', secondaryDefaultDark)
    }

    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    function handleErrorModal(status, message) {
        setErrorMessage(message)
    }

    function handleRequiredFields() {

        let errors = false
        if (errorFields.includes('password')) {
            errors = true
            document.getElementById('password').style.border = "1px solid red"
        } else {
            document.getElementById('password').style.border = ""
        }

        if (errorFields.includes('user')) {
            errors = true
            document.getElementById('user').style.border = "1px solid red"
        } else {
            document.getElementById('user').style.border = ""
        }
        return errors
    }

    async function handleLogin() {
        if (user === '') {
            if (!errorFields.includes('user')) {
                errorFields.push('user')
                setErrorFields(errorFields)
            }
        } else {
            errorFields.indexOf('user') > -1 && errorFields.splice(errorFields.indexOf('user'), 1)
            setErrorFields([...errorFields])
        }

        if (password === '') {
            if (!errorFields.includes('password')) {
                errorFields.push('password')
                setErrorFields(errorFields)
            }
        } else {
            errorFields.indexOf('password') > -1 && errorFields.splice(errorFields.indexOf('password'), 1)
            setErrorFields([...errorFields])
        }

        if (handleRequiredFields()) {
            return
        }

        try {
            setLoading(true)
            const login = await api_auth.post('/api/auth/logon', {
                "user": user?.toLocaleLowerCase()?.trim(),
                "password": password,
                customers_code: !customers_code ? undefined : customers_code,
            })

            if (login.data.status === 201) {
                const apiUserData = []
                apiUserData.push(login.data.userData)
                localStorage.setItem('typeUser', 1)
                apiUserData[0].customers_s3_url = login?.data?.userData?.customers_s3_url
                apiUserData[0].photo = login.data.userData?.photo?.length > 0 ? `${login?.data?.userData?.customers_s3_url}/${login.data.userData?.customers_code}/${login.data.userData?.photo}` : undefined
                apiUserData[0].customersData = customersData
                apiUserData[0].token = login.data.token
                apiUserData[0].customers_id = 1

                const paymentGateway = await defaultCallsSchedules.getPaymentPublicGateways(login.data.token)
                const alerts = await defaultCallsAuth.getCustomerAlerts(login.data.token)
                //alertas do sistema
                if (alerts.data.data[0]?.message?.length > 0) {
                    setCustomersAlerts(alerts.data.data)
                }
                apiUserData[0].customersData.hasGateway = paymentGateway.data?.data?.length > 0 ? true : false
                apiUserData[0].customersData.paymentGateway = paymentGateway.data.data?.length > 0 ? paymentGateway.data?.data[0] : []

                if (apiUserData[0].customersData?.modules.map(module => { return module.id }).includes(3)) {            
                    //cliente com modulo financeiro, valida se tem financialRules do contrário não permite acesso as reservas
                    const financialRules = await defaultCallsFinancial.getFinancialRules(login.data.token)
                    const rule = financialRules.data?.length > 0 ? financialRules.data.filter(rule => rule.active)[0] : null
                    if (!rule?.id) {
                        apiUserData[0].customersData.blockedByFinancialRules = true
                    }        
                }

                localStorage.setItem('@lastLogin', moment().format('YYYY-MM-DD'))
                localStorage.setItem('@auth:token', login.data.token)

                setUserData(apiUserData)
                setSigned(true)
                navigate('/')

                //leitura dos termos nao lidos
                const terms = await defaultCallsAuth.getUserTerms(login.data.token, apiUserData[0]?.id)
                if (terms.data?.data?.length > 0) {
                    setLoading(false)
                    if (apiUserData[0].email?.length === 0) {
                        setDontHaveEmail(true)
                    }
                    return setHaveTermsOfUse(true)
                }
                if (apiUserData[0].email?.length === 0 || !apiUserData[0]?.email) {
                    navigate(`/myProfileEdit/${apiUserData[0]?.id}`)
                    setDontHaveEmail(true)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setLoading(false)
                    return setShowNotificationModalText('Você não possui e-mail configurado, obrigatório cadastrar uma conta de e-mail para poder utilizar o sistema')
                } else {
                    setDontHaveEmail(false)
                }

                //verifica se esta sendo validado o usuario (qrCode carteira digital)
                if (usersId?.length > 0) {
                    Number(userTypeAccess) > 2 ? navigate(`/clientsEdit/${usersId}`) : navigate(`/usersEdit/${usersId}`)
                }
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            console.log(error.response.data.message === 'Erro ao realizar logon, você não ativou a sua conta.')
            setShowError(true)
            if (error?.response?.data?.statusCode === 401 && error?.response?.data?.message === 'Usuário não autenticado.') {
                setLoading(false)
                return handleErrorModal(true, 'Seu usuário está desativado, contate o administrador')
            } else if (error.response.data.message === 'Erro ao realizar logon, você não ativou a sua conta.') {
                setLoading(false)
                setShowResendActivateAccount(true)
                return handleErrorModal(true, error?.response?.data?.action)
            }
            else if (error?.response?.data?.statusCode === 401) {
                setLoading(false)
                return handleErrorModal(true, error?.response?.data?.action)
            } else {
                setLoading(false)
                return handleErrorModal(true, error?.response?.data?.message.concat(' ').concat(error?.response?.data?.action))
            }
        }
    }

    async function handleSubmitClient() {
        const fields = [
            { "name": 'firstName', "value": firstName, "required": true, "type": 'string' },
            { "name": 'lastName', "value": lastName, "required": true, "type": 'string' },
            { "name": 'email', "value": email, "required": true, "type": 'string' },
            { "name": 'birthday', "value": birthday, "required": true, "type": 'string' },
            { "name": 'password', "value": password, "required": true, "type": 'string' },
            { "name": 'dddcell', "value": dddcell, "required": true, "type": 'string' },
            { "name": 'cell', "value": cell, "required": true, "type": 'string' },
        ]
        if (customersData?.requireCpf) {
            fields.push({ "name": 'cpf', "value": cpf, "required": true, "type": 'string' })
        }

        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowErrorModal(true)
            return setErrorMessage('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (password !== passwordConfirmation) {
            setShowErrorModal(true)
            return setErrorMessage('Senha e confirmação de senha não estão iguais, veriique')
        }

        setLoading(true)
        try {
            await api_auth.post(`/api/auth/signUp/user`, {
                firstName,
                lastName,
                email: email?.toLocaleLowerCase()?.trim(),
                birthday,
                cpf: cpf.length > 0 ? removeEspecialChar(cpf) : undefined,
                password,
                customers_code,
                dddcell,
                cell,
                customers_code
            })
            setShowSuccessModal(true)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setShowErrorModal(true)
            handleErrorModal(true, responseError(error))
        }
    }

    async function handleRessendActiveEmail() {
        setLoading(true)
        try {
            const data = await api_auth.post(`/api/auth/resendActivateEmail`, {
                customers_code,
                email: user
            })
            setRecoverPassword(true)
            setForgetPassword(false)
            setShowError(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const handlePassword = async () => {
        try {
            setLoading(true)

            await api_auth.post(`/api/auth/forgetPassword`, {
                email: email?.toLowerCase(),
                customers_code
            })
            setEmail('')
            setRecoverPassword(true)
            setForgetPassword(false)
            setLoading(false)
        } catch (error) {
            setErrorMessage('Ocorreu um erro ao alterar a senha, se o problema persistir entre em contato com o suporte')
            setShowErrorModal(true)
            setLoading(false)
        }
    }

    if (customersData?.showAvailableHoursWithoutLogin) {
        return <LoginPublicSchedules customersData={customersData} clientFinded={clientFinded} />
    }

    if (loading) {
        return (
            <div style={{
                overflow: screenX < 600 ? 'auto' : 'hidden', height: '100vh',
                backgroundImage: `
                        linear-gradient(to bottom, rgba(${hexToRgb(primaryDefaultLight).r},${hexToRgb(primaryDefaultLight).g}, ${hexToRgb(primaryDefaultLight).b}, 0.80), 
                        rgba(${hexToRgb(secondaryDefaultLight).r},${hexToRgb(secondaryDefaultLight).g}, ${hexToRgb(secondaryDefaultLight).b}, .85)), url(${bgLogin})
                    `
            }}
                className={`w-full bg-cover flex flex-col bg-primaryDefaultLight items-center justify-end sm:justify-center `}
            >
                <LogonLoader />
            </div>
        )
    }

    return (
        <div style={{
            overflow: screenX < 600 ? 'hidden' : 'hidden', height: '100vh',
            backgroundImage: `linear-gradient(to bottom, rgba(${hexToRgb(primaryDefaultLight).r},${hexToRgb(primaryDefaultLight).g}, ${hexToRgb(primaryDefaultLight).b}, 0.80), 
                rgba(${hexToRgb(secondaryDefaultLight).r},${hexToRgb(secondaryDefaultLight).g}, ${hexToRgb(secondaryDefaultLight).b}, .85)), url(${bgLogin})`
        }}
            className={`w-full bg-cover flex flex-col bg-primaryDefaultLight items-center justify-end  sm:justify-center `}
        >
            <div className='flex flex-col'>
                <div className={`${showErrorModal ? 'flex' : 'hidden'} w-[100%] h-[100%] ${animateErrorModal ? '' : 'bg-transparent'} transition-all duration-100 z-50 absolute inset-0 boxShadow flex flex-col items-center justify-center`}>
                    <div className={`${animateErrorModal ? 'translate-y-0 opacity-1 bg-bgPrimaryLight' : 'translate-y-10 opacity-0 bg-transparent'} transition-all duration-300  w-96 h-80 shadow-xl shadow-zinc-700 flex flex-col items-center justify-start p-4 rounded-xl absolute z-30`}>
                        <div className='w-full flex flex-row justify-between h-6'>
                            <h2 className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'>Atenção</h2>
                            <button onClick={() => setShowErrorModal(false)} className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'>x</button>
                        </div>
                        <div className='h-[100%] flex flex-col items-center justify-center'>
                            <p className='text-titleBlackTextLight mb-10 text-center'>{errorMessage}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col'>
                <div className={`${showSuccessModal ? 'flex' : 'hidden'} w-[100%] h-[100%] ${animateSuccessModal ? '' : 'bg-transparent'} transition-all duration-100 z-50 absolute inset-0 boxShadow flex flex-col items-center justify-center`}>
                    <div className={`${animateSuccessModal ? 'translate-y-0 opacity-1 bg-bgPrimaryLight' : 'translate-y-10 opacity-0 bg-transparent'} transition-all duration-300  w-96 h-80 shadow-xl shadow-zinc-700 flex flex-col items-center justify-start p-4 rounded-xl absolute z-30`}>
                        <div className='w-full flex flex-row justify-between h-6'>
                            <h2 className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'></h2>
                            <button onClick={() => setShowSuccessModal(false)} className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'>x</button>
                        </div>
                        <div className='h-[100%] flex flex-col items-center justify-center'>
                            <p className='text-titleBlackTextLight mb-10 text-center'>
                                Um e-mail de confirmação foi enviado para o email de cadastro: <a className='text-sm bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight font-bold bg-clip-text text-transparent'>{email}</a>, para efeutar o login, acesse o e-mail e clique no link enviado para ativar sua conta.
                            </p>
                            <button onClick={() => { setShowSignUp(false); setShowSuccessModal(false) }} className='bg-gradient-to-r from-primaryDefaultLight to-secondaryDefaultLight hover:shadow-lg hover:shadow-zinc-400/60 hover:brightness-90 transition-all duration-200 ease-in-out w-40 h-12 text-titleGrayTextLight mt-5 rounded-3xl '>Login</button>
                        </div>
                    </div>
                </div>
                <div className={`${showSuccessModal ? 'flex' : 'hidden'} w-[100%] h-[100%] ${animateSuccessModal ? 'bg-zinc-700' : 'bg-transparent'} transition-all duration-100 opacity-75 z-40 absolute inset-0 boxShadow flex flex-col items-center justify-center`}>
                </div>
            </div>
            {
                !baseNotExists && clientFinded &&
                <>
                    {
                        showError ?
                            <div id='messageModal' className='px-2 sm:px-0 bg-transparent flex flex-col h-full items-center justify-center'>
                                <p className='text-lg text-titleGrayTextLight'>Erro ao efetuar login:</p>
                                <p className='text-lg text-titleGrayTextLight text-center'>{errorMessage}</p>
                                <div className='mt-10 flex gap-2'>
                                    <Button shadow={true} onClick={() => { setShowError(false); setLoading(false) }}>Voltar</Button>
                                    {
                                        showResendActivateAccount &&
                                        <Button shadow={true} onClick={() => { handleRessendActiveEmail(); setShowError(false); setLoading(false) }}>Reenviar e-mail</Button>
                                    }
                                </div>
                            </div>
                            :
                            <>
                                <div className='flex flex-col'>
                                    <div className={`${showHelp ? 'flex' : 'hidden'} w-[100%] h-[100%] ${animateHelp ? '' : 'bg-transparent'} transition-all duration-100 z-50 absolute inset-0 boxShadow flex flex-col items-center justify-center`}>
                                        <div className={`${animateHelp ? 'translate-y-0 opacity-1 bg-bgPrimaryLight' : 'translate-y-10 opacity-0 bg-transparent'} transition-all duration-300  w-96 h-80 shadow-xl shadow-zinc-700 flex flex-col items-center justify-start p-4 rounded-xl absolute z-30`}>
                                            <div className='w-full flex flex-row justify-between h-6'>
                                                <h2 className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'>Ajuda</h2>
                                                <button onClick={() => setShowHelp(false)} className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'>x</button>
                                            </div>
                                            <div className='h-[100%] flex flex-col items-center justify-center'>
                                                <h2 className='text-titleBlackTextLight font-base'>Precisa de ajuda?</h2>
                                                <p className='text-titleBlackTextLight mb-10'>Entre em contato com 47 3026-6006</p>
                                                <button className='bg-gradient-to-r from-primaryDefaultLight to-secondaryDefaultLight hover:shadow-lg hover:shadow-zinc-400/60 hover:brightness-90 transition-all duration-200 ease-in-out w-40 h-12 text-titleGrayTextLight mt-5 rounded-3xl '>Chamar Whatsapp</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${showHelp ? 'flex' : 'hidden'} w-[100%] h-[100%] ${animateHelp ? 'bg-zinc-700' : 'bg-transparent'} transition-all duration-100 opacity-75 z-40 absolute inset-0 boxShadow flex flex-col items-center justify-center`}>
                                    </div>
                                </div>
                                {
                                    loading ?
                                        <LoginLoader />
                                        :
                                        showSignUp ?
                                            <div className={`animate-[wiggle_1s_ease-in-out] sm:max-h-[650px] bg-white p-10 2xl:p-12 rounded-lg boxShadow overflow-auto`}>
                                                {
                                                    loading ?
                                                        <DefaultLoader />
                                                        :
                                                        <>
                                                            <div className='flex flex-col w-full items-start justify-center mb-4 2xl:mb-10'>
                                                                <a className='text-xl font-bold text-secondaryDefaultLight mb-2'>Cadastre-se</a>
                                                                <p><strong className='text-center sm:text-left text-secondaryDefaultLight'>Funcionários:</strong> Já possuem cadastro, faça o <a onClick={() => setSignUp(false)} className='cursor-pointer text-lg text-primaryDefaultLight'>Login</a>.</p>
                                                                <p><strong className='text-center text-secondaryDefaultLight'>Comunidade:</strong> Para realizar reservas ou inscrições em atividades, preencha o formulário abaixo para obter acesso.</p>
                                                            </div>
                                                            <div className='flex flex-col items-start justify-start w-full'>
                                                                <form className='flex flex-col gap-4  items-center justify-center w-full'>
                                                                    <div className='flex flex-col sm:flex-row gap-4 items-center justify-center w-full'>
                                                                        <LabelInput text={'Nome *'}>
                                                                            <Input
                                                                                width={screenX < 1400 ? `350px` : ''}
                                                                                autoFocus={true} id='firstName'
                                                                                value={firstName} type='text'
                                                                                onChange={(e) => setFirstName(e.target.value)}
                                                                            />
                                                                        </LabelInput>
                                                                        <LabelInput text={'Sobrenome *'}>
                                                                            <Input
                                                                                width={screenX < 1400 ? `350px` : ''}
                                                                                id='lastName'
                                                                                value={lastName}
                                                                                type='text'
                                                                                onChange={(e) => setLastName(e.target.value)}
                                                                            />
                                                                        </LabelInput>
                                                                    </div>
                                                                    <div className='flex flex-col sm:flex-row gap-4 items-center justify-center w-full' >
                                                                        <LabelInput text={'E-mail *'}>
                                                                            <Input
                                                                                width={screenX < 1400 ? `350px` : ''}
                                                                                id='email'
                                                                                value={email}
                                                                                type='e-mail'
                                                                                onChange={(e) => setEmail(e.target.value)}
                                                                            />
                                                                        </LabelInput>
                                                                        <LabelInput text={`CPF ${customersData?.requireCpf ? '*' : ''}`}>
                                                                            <Input
                                                                                width={screenX < 1400 ? `350px` : ''}
                                                                                id='cpf'
                                                                                charLimit={14}
                                                                                value={mCPF(cpf)}
                                                                                type='text'
                                                                                onChange={(e) => setCpf(e.target.value)}
                                                                            />
                                                                        </LabelInput>
                                                                    </div>
                                                                    <div className='flex flex-col sm:flex-row gap-1 sm:gap-4 items-center justify-center w-full'>
                                                                        <div className='flex sm:flex-row gap-4'>
                                                                            <LabelInput text={'DDD *'}>
                                                                                <Input
                                                                                    width={12}
                                                                                    charLimit={2}
                                                                                    id='dddcell'
                                                                                    value={dddcell}
                                                                                    type='phone'
                                                                                    onChange={(e) => setDddcell(justNumber(e.target.value))}
                                                                                />
                                                                            </LabelInput>
                                                                            <LabelInput text={'Celular *'}>
                                                                                <Input
                                                                                    width={screenX < 1400 ? 72 : 80}
                                                                                    charLimit={9}
                                                                                    id='cell'
                                                                                    value={cell}
                                                                                    type='phone'
                                                                                    onChange={(e) => setCell(justNumber(e.target.value))}
                                                                                />
                                                                            </LabelInput>
                                                                        </div>
                                                                        <div className='flex flex-col sm:flex-row gap-4 items-center justify-center w-full'>
                                                                            <LabelInput text={'Data de nascimento *'}>
                                                                                <Input width={screenX < 1400 ? `350px` : ''} id='birthday' value={birthday} type='date' onChange={(e) => setBirthday(e.target.value)}></Input>
                                                                            </LabelInput>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col sm:flex-row gap-4 items-center justify-center w-full'>
                                                                        <LabelInput text={'Senha *'}>
                                                                            <Input
                                                                                width={screenX < 1400 ? `350px` : ''}
                                                                                type='password'
                                                                                charLimit={100}
                                                                                id='password'
                                                                                value={password}
                                                                                onChange={(e) => setPassword(e.target.value)}
                                                                            />
                                                                        </LabelInput>
                                                                        <LabelInput text={'Confirmar senha *'}>
                                                                            <Input
                                                                                width={screenX < 1400 ? `350px` : ''}
                                                                                type='password' charLimit={100}
                                                                                id='passwordConfirmation'
                                                                                value={passwordConfirmation}
                                                                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                                                            />
                                                                        </LabelInput>
                                                                    </div>
                                                                </form>
                                                                <div className='flex flex-row gap-1 mb-20 mt-10 w-full items-center justify-center'>
                                                                    <button type='submit' id='loginButton' className='shadow-lg mt-2 bg-primaryDefaultLight hover:bg-secondaryDefaultLight outline-none focus:bg-secondaryDefaultLight transition-all duration-300 w-[350px] sm:w-64 h-16 sm:h-10 rounded-xl text-white text-base' onClick={() => handleSubmitClient()}>Cadastrar </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                }

                                            </div>
                                            :
                                            <div className={`animate-[wiggle_1s_ease-in-out] bg-bgPrimaryLight dark:bg-primaryDefaultDark w-full ${screenX > 640 ? 'h-full' : 'h-full'} md:w-[450px] 2xl:w-[500px] md:h-[550px] 2xl:h-[600px] shadow-lg shadow-zinc-600  flex flex-row items-center justify-center sm:rounded-2xl`}>
                                                <div className='flex flex-col md:w-[400px] 2xl:w-[500px] sm:w-[50%] gap-2 items-center h-full justify-center'>
                                                    <form className='px-6 w-full relative h-full flex flex-col items-center gap-2 justify-center' onSubmit={e => e.preventDefault()}>
                                                        {
                                                            forgetPassword ?
                                                                <div className='flex flex-col items-center justify-center gap-4 w-full flex-wrap'>
                                                                    <p className='mb-4 text-2xl text-titleBlackTextLight'><strong>Esqueceu</strong> sua senha?</p>
                                                                    <p className='mb-8 text-titleBlackTextLight text-center'>Enviaremos um e-mail com instruções para redefinir sua senha</p>
                                                                    <div onClick={() => setForgetPassword(false)} className='cursor-pointer hover:brightness-150 absolute top-6 left-0 sm:left-8 text-2xl text-primaryDefaultLight flex flex-col w-full items-start justify-start'>
                                                                        <FaArrowAltCircleLeft />
                                                                    </div>
                                                                    <LabelInput text={'E-mail *'}>
                                                                        <Input
                                                                            width={screenX < 1400 ? 80 : ''}
                                                                            id='email'
                                                                            value={email}
                                                                            type='e-mail'
                                                                            onChange={(e) => setEmail(e.target.value?.toLowerCase())}
                                                                        />
                                                                    </LabelInput>
                                                                    <Button onClick={handlePassword} shadow={true} approval={true}>Redefinir</Button>
                                                                </div>
                                                                :
                                                                recoverPassword ?
                                                                    <div className='animate-wiggle flex flex-col items-center justify-center w-full'>
                                                                        <p className='mb-4 text-2xl text-titleBlackTextLight'><strong>Sucesso</strong>, verifique seu e-mail!</p>
                                                                        <p className='mb-10 text-titleBlackTextLight'></p>
                                                                        <Button shadow={true} onClick={() => { setForgetPassword(false); setRecoverPassword(false) }}>Login</Button>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div className='flex flex-col items-center justify-center max-h-32 max-w-28 overflow-hidden'>
                                                                            <img className='mb-5' src={clientFinded ? customersData?.photo?.length > 0 ? customersData?.photo + '?' + date : LoginLogo : LoginLogo} width={screenX < 600 || screenY < 800 ? 250 : 200} />
                                                                        </div>
                                                                        <a className='mb-2 text-xs text-titleBlackTextLight'>* Caso entre com CPF, digite somente os números *</a>
                                                                        <LabelInput text={'Usuário'}>
                                                                            <Input
                                                                                id='user'
                                                                                autoFocus={true}
                                                                                width={screenX < 760 ? '' : '64'}
                                                                                onChange={(e) => setUser(e.target.value?.toLowerCase())}
                                                                                value={user}
                                                                            />
                                                                        </LabelInput>
                                                                        <LabelInput text={'Senha'}>
                                                                            <Input
                                                                                id='password'
                                                                                type={'password'}
                                                                                width={screenX < 760 ? '' : '64'}
                                                                                onChange={(e) => setPassword(e.target.value)}
                                                                                value={password}
                                                                            />
                                                                        </LabelInput>
                                                                        <button type='submit' id='loginButton' className='mt-2 bg-primaryDefaultLight hover:bg-secondaryDefaultLight outline-none focus:bg-secondaryDefaultLight transition-all duration-300 w-[350px] mb-2 sm:mb-4 sm:w-64 h-10 sm:h-10 rounded-lg sm:rounded-lg text-white text-base' onClick={() => handleLogin()}>Avançar </button>
                                                                        <p className='text-primaryDefaultLight text-sm underline cursor-pointer' onClick={() => setForgetPassword(true)}>Esqueci minha senha</p>
                                                                        {
                                                                            customersData?.openToCommunity &&
                                                                            <p onClick={() => setSignUp(true)} className='text-primaryDefaultLight cursor-pointer underline text-sm'>Não possui conta? crie uma agora</p>
                                                                        }
                                                                        {
                                                                            customersData?.phone > 0 &&
                                                                            <div className='flex flex-col absolute bottom-16 sm:bottom-6 items-center justify-center w-full'>
                                                                                <p className='text-center text-inputPlaceholderLight text-sm sm:text-sm'>{`Problemas com acesso`}</p>
                                                                                <p className='text-center text-inputPlaceholderLight text-sm sm:text-sm'>{`entre em contato: ${customersData?.dddPhone} - ${customersData?.phone}`}</p>
                                                                            </div>
                                                                        }
                                                                    </>
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                }
                            </>
                    }
                </>
            }
            {
                !clientFinded && !baseNotExists &&
                <div className={`animate-[wiggle_1s_ease-in-out] bg-white dark:bg-primaryDefaultDark w-full ${screenX > 640 ? 'h-full' : 'h-full'} md:w-[450px] 2xl:w-[500px] md:h-[350px] 2xl:h-[450px] shadow-lg shadow-zinc-600  flex flex-row items-center justify-center sm:rounded-2xl`}>
                    {
                        showError && app_name ? (
                            <div className='flex flex-col md:w-[400px] 2xl:w-[500px] sm:w-[50%] gap-2 items-center h-full justify-center'>
                                <div className='flex flex-col items-center justify-center overflow-hidden mt-10 p-2'>
                                    <img src={LoginLogo} width={screenX < 600 || screenY < 800 ? 250 : 200} />
                                </div>
                                <div id='messageModal' className='px-2 sm:px-0 bg-transparent flex flex-col h-full items-center justify-center'>
                                    <p className='text-lg text-titleBlackTextDark'>Base informada não encontrada.</p>
                                    <div className='mt-16 flex gap-2'>
                                        <Button shadow={true} onClick={() => { navigate('/'); setAppName(''); setShowError(false) }}>Voltar</Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='flex flex-col md:w-[400px] 2xl:w-[500px] sm:w-[50%] gap-2 items-center h-full justify-center'>
                                <div className='flex flex-col items-center justify-center overflow-hidden mt-10 p-2'>
                                    <img src={LoginLogo} width={screenX < 600 || screenY < 800 ? 250 : 200} />
                                </div>
                                <form className='px-6 w-full relative h-full flex flex-col items-center gap-10 justify-center' onSubmit={e => {
                                    e.preventDefault();
                                    navigate('/' + appName)
                                }}>
                                    <LabelInput text={'Informa a base que deseja acessar'}>
                                        <Input
                                            id='app_name' type={'text'} width={screenX < 760 ? '' : '64'}
                                            onChange={(e) => setAppName(e.target.value)} value={appName}
                                        />
                                    </LabelInput>
                                    <div className='flex flex-col items-center justify-center'>
                                        <button
                                            type='submit'
                                            id='loginButton'
                                            className='mt-2 bg-primaryDefaultLight hover:bg-secondaryDefaultLight outline-none focus:bg-secondaryDefaultLight transition-all duration-300 w-[350px] mb-2 sm:mb-4 sm:w-64 h-10 sm:h-10 rounded-lg sm:rounded-lg text-white text-base'
                                            onClick={() => handleLogin()}
                                        >Avançar</button>
                                        <a className='text-titleBlackTextLight text-sm underline hover:text-primaryDefaultLight'
                                            href='https://www.agendarapido.com'
                                        >Ir para o site</a>
                                    </div>
                                </form>
                            </div>
                        )
                    }
                </div>
            }
            {
                baseNotExists &&
                <div className={`animate-[wiggle_1s_ease-in-out] bg-white dark:bg-primaryDefaultDark w-full ${screenX > 640 ? 'h-full' : 'h-full'} md:w-[450px] 2xl:w-[500px] md:h-[350px] 2xl:h-[450px] shadow-lg shadow-zinc-600  flex flex-row items-center justify-center sm:rounded-2xl`}>
                    <div className='flex flex-col md:w-[400px] 2xl:w-[500px] sm:w-[50%] gap-2 items-center h-full justify-center'>
                        <div className='flex flex-col items-center justify-center overflow-hidden mt-10 p-2'>
                            <img src={LoginLogo} width={screenX < 600 || screenY < 800 ? 250 : 200} />
                        </div>
                        <div className='px-6 w-full relative h-full flex flex-col items-center gap-10 justify-center'>
                            <PiSmileySadDuotone color={primaryDefaultLight} size={40} />
                            <p className='text-titleBlackTextLight'>Base informada não encontrada</p>
                            <a className='text-titleBlackTextLight underline hover:text-primaryDefaultLight'
                                href='https://www.agendarapido.com'
                            >Ir para o site</a>
                        </div>
                    </div>
                </div>
            }
            {/* RODAPÉ DESATIVADO TEMPORARIAMENTE, O FOCO É NO AGENDA RÁPIDO. 14/10/2024 */}
            {/* <div className='absolute border-t border-bgSecondaryLight dark:border-primaryBorderDark bottom-0 flex flex-row gap-2 items-center justify-center w-full py-px rounded-t-3xl dark:bg-secondaryDefaultDark bg-white' id='logoImage'>
                <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-xs font-base'>Desenvolvido por</p>
                <a className='flex dark:hidden' href='https://www.mailoudev.com.br' target='__blank'>
                    <img width={screenX > 1400 ? '100px' : '100px'} src={logoMailouDev}></img>
                </a>
                <a className='dark:flex hidden grayscale-40' href='https://www.mailoudev.com.br' target='__blank'>
                    <img width={'80px'} src={logoMailouDev}></img>
                </a>
            </div> */}
        </div>
    )
}
