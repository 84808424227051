import { responseError } from "../../../utils/responsesFunctions/error.response"
import moment from "moment"
import { maskHours } from '../../../utils/mask/maskHours'
import { currencyPtBr } from "../../../utils/formatCurrency/currencyTextPTBR"

export function formattTimeToShow(apiTime) {
    try {
        let zPos = apiTime?.indexOf('Z')
        let offset = moment().utcOffset()

        let time = apiTime
        let localTime = moment(apiTime).format("L LT")?.replace('00:00', '')
        if (zPos > - 1) {
            time = apiTime.slice(0, zPos)
            time = time.replace('T', ' ')
            localTime = moment.utc(time).utcOffset(offset).format("L LT");
        }

        return localTime
    } catch (error) {
        return apiTime
    }
}

