import React, { useEffect, useState } from "react";
//components
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { TableDefault } from "../../../components/table/table.default";
import { TableMobile } from "../../../components/table/table.mobile";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/useAuth";
import { formattTimeToShow } from "../../../services/api/callAPIsFunctions/defaultCalls.api";
import { useThemeContext } from "../../../contexts/themeContext";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function SiteBanners() {

    const { defaultCallsSchedules } = useApiContext()
    const { userData } = useAuth()
    const [totalPages, setTotalPages] = useState(1)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [myBannerList, setMyBannerList] = useState([])
    const tableThead = [
        {
            "name": 'Titulo',
            "original_name": 'title'
        },
        {
            "name": 'Data inicio',
            "original_name": 'startDate'
        },

        {
            "name": 'Data fim',
            "original_name": "finishDate"
        },
        {
            "name": 'Criado em',
            "original_name": "created_at"
        },
    ]

    function handleNewBanner() {
        navigate('/siteBannersCreate')
    }


    async function getData(e) {
        
        const siteBanners = await defaultCallsSchedules.getSiteBanners(userData[0].token, '', e?.page ? e.page : '')

        if (siteBanners.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(siteBanners.data)
        }

        setTotalPages(siteBanners.data.data.totalPages)

        setMyBannerList(siteBanners.data.data.data.map(banner => {
            return {
                ...banner,
                title: banner?.title ? banner.title : 'Sem titulo',
                created_at: formattTimeToShow(banner.created_at),
                startDate: formattTimeToShow(banner.startDate),
                finishDate: formattTimeToShow(banner.finishDate)
            }
        }))
        setLoading(false)
    }

    function orderTableData(e) {
        getData(e)
    }

    useEffect(() => {
        setLoading(true)
        getData()

    }, [])

    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row items-center justify-between w-full">
                    <Title text={'Banners para site'} />
                    <Button module={"siteBanners:create"} userData={userData} onClick={() => handleNewBanner()} shadow={true}>+ Novo</Button>
                </div>

            </TitlePage>
            <Body>
                {
                    loading ?
                        <div className="w-full h-96 flex flex-col items-center justify-center">
                            <DefaultLoader />
                        </div>
                        :
                        // <></>
                        <div className="w-full flex flex-col gap-4">
                            <div className='p-4 bg-zinc-50 border border-zinc-100 rounded-lg dark:bg-thirdDefaultDark dark:border-secondaryBorderDark text-base'>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextLight'>
                                    Cadastre os banners que irão aparecer na home do site
                                </p>
                            </div>
                            <div id='siteBannersTable' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                <TableDefault data={myBannerList}
                                    loading={loading}
                                    pagination={true}
                                    totalPages={totalPages}
                                    onClick={(e) => navigate(`/siteBannersEdit/${e.id}`)}
                                    title={tableThead}
                                    filter={(e) => orderTableData(e)}
                                    collumns={['title', 'startDate', 'finishDate', 'created_at']} />
                            </div>
                            <div id='siteBannersTableMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                <TableMobile
                                    onClick={(e) => e.func.execute(e?.data)}
                                    title={tableThead}
                                    data={myBannerList}
                                    collumns={['title', 'startDate', 'finishDate', 'created_at']}
                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": (e) => {navigate(`/siteBannersEdit/${e.id}`)} }]}
                                />
                            </div>
                        </div>
                }
            </Body>
        </Container>
    )
}