import React, { useState } from 'react'
import { generalRoutesConfig, activitiesAndSchedulesRoutesConfig } from '../../routes/routes.config'
import { FaQuestionCircle, FaPlayCircle } from 'react-icons/fa'
import YouTube from 'react-youtube';
import { useScreenSizeContext } from '../../contexts/screenSizeContext';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { useAuth } from '../../contexts/useAuth';

export function Title({ text }) {

    const { userData } = useAuth()

    function findRoute() {
        try {

            let route = generalRoutesConfig.find(
                item => item?.name?.toLowerCase() === text.toLowerCase()
            );

            if (!route) {
                route = activitiesAndSchedulesRoutesConfig.find(
                    item => item?.name?.toLowerCase() === text.toLowerCase()
                );
            }
            return route;
        } catch (error) {


        }
    }

    const thisRoute = userData[0]?.typeAccess_id <= 2 ? findRoute() : ''
    const [helpIsOpen, setHelpIsOpen] = useState(false)
    const [videoEnded, setVideoEnded] = useState(false)
    const [player, setPlayer] = useState(null);
    const { screenX } = useScreenSizeContext()


    const onPlayerReady = (event) => {
        event.target.pauseVideo()
        setPlayer(event.target);

    }

    const opts = {
        height: screenX > 600 ? '500' : '400',
        width: '100%',
        playerVars: {
            autoplay: 0,
            controls: 1,        // Oculta os controles de play/pausa
            modestbranding: 1,  // Reduz a visibilidade do logo do YouTube
            rel: 0,             // Impede vídeos sugeridos ao final
            disablekb: 1,       // Desabilita o teclado para evitar controle manual

            showinfo: 0,
        },
    }

    const onVideoEnd = () => {
        setVideoEnded(true)
    }

    const handleRestart = () => {
        if (player) {
            setVideoEnded(false)
            player.seekTo(0);
            player.playVideo();
        }
    };

    return (

        <>

            <div className='flex gap-2 items-center justify-center'>
                <h2 className='font-normal flex md:flex text-xl 2xl:text-2xl  text-primaryDefaultLight dark:text-primaryDefaultLight'>{text}</h2>

                {
                    thisRoute?.helpVideo &&
                    <>

                        <Tippy content={'Acessar a ajuda'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <div onClick={() => setHelpIsOpen(!helpIsOpen)} className='p-1 text-lg cursor-pointer '>
                                <FaQuestionCircle></FaQuestionCircle>
                            </div>
                        </Tippy>


                        {
                            helpIsOpen &&
                            <div className='h-[100vh] flex items-center justify-center z-40 absolute top-0 right-0 w-full '>
                                <div className='bg-white w-[100wv] md:w-[840px] shadow-lg p-2 rounded-md z-50 gap-2 flex flex-col'>
                                    <div className='w-full flex justify-between'>
                                        <h2 className='text-lg text-primaryDefaultLight font-semibold'>Ajuda</h2>
                                        <a className='w-8 h-8 flex items-center justify-center rounded-full bg-zinc-200 text-white cursor-pointer hover:brightness-95' onClick={() => setHelpIsOpen(false)}>x</a>
                                    </div>
                                    <p className='text-zinc-700'>Veja abaixo o vídeo com os detalhes do funcionamento deste recurso:</p>
                                    <div className='w-full h-full'>
                                        {
                                            videoEnded ?
                                                <div onClick={handleRestart} className='hover:brightness-110 transition-all duration-150 cursor-pointer h-[400px] lg:h-[500px] 2xl:h-[700px] w-full flex gap-2 flex-col items-center justify-center bg-zinc-900 p-2 text-white'>
                                                    <a className='text-lg text-white font-semibold'
                                                    >
                                                        Assistir novamente
                                                    </a>
                                                    <FaPlayCircle className='text-4xl' />

                                                </div>
                                                :
                                                <YouTube videoId={thisRoute?.helpVideo} opts={opts} onEnd={onVideoEnd} onReady={onPlayerReady} />
                                        }

                                    </div>
                                </div>
                                <div className='h-[100vh] flex z-40 items-center justify-center absolute top-0 right-0 bg-zinc-600 w-full opacity-75'>
                                </div>
                            </div>
                        }

                    </>
                }
            </div>
        </>
    )
}