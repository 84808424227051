//hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
import Tippy from "@tippyjs/react";
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { NewTableDefault } from "../../../components/table/new.table.default";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { currencyPtBr } from "../../../utils/formatCurrency/currencyTextPTBR";
import { FaPen, FaTrash } from 'react-icons/fa'
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { useThemeContext } from "../../../contexts/themeContext";

export function FinancialAccountsTransfers() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { defaultCallsFinancial, api_financial } = useApiContext()
    const { 
        showUniversalModal, setShowUniversalModal,
        showNotificationModal, setShowNotificationModal,
        showNotificationModalText, setShowNotificationModalText,
        setShowNotificationModalSuccess
     } = useThemeContext()
    const [accountTransferId, setAccountTransferId] = useState(null)
    const [accountsList, setAccountsList] = useState([])
    const [loading, setLoading] = useState(true)
    const [showModalConciliation, setShowModalConciliation] = useState(false)
    const active = true

    async function getData() {
        try {
            const accountsData = await defaultCallsFinancial.getAccounts(userData[0]?.token)
            setAccountsList(accountsData.data)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (!showUniversalModal){
            setShowModalConciliation(false)
            setAccountTransferId(null)
        }
    }, [showUniversalModal])

    async function handleRemoveAccountTransfer(id){

        try {
            
            setLoading(true)
            await api_financial.delete(`api/v1/financial/accountsTransfers/${id}`, {
                headers :{
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Transferência removida com sucesso!')
            setShowUniversalModal(false)
            setLoading(false)


        } catch (error) {
            setShowUniversalModal(false)
            setLoading(false)
            console.log(error)
        }

    }

    async function handleRemoveConciliations(id){
        setLoading(true)
        try {        
            await api_financial.put(`api/v1/financial/accountsTransfers/removeConciliation/${id}`, {
                whats_remove: 'all'
            }, {
                headers :{
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Conciliações removidas com sucesso!')
            setShowUniversalModal(false)
            setLoading(false)


        } catch (error) {
            setShowUniversalModal(false)
        } finally {
            setLoading(false)        
        }

    }

    return (
        <>
        <ScreenModal>
            {
                showModalConciliation ?
                <div className="p-4 text-sm flex flex-col gap-1 justify-center items-center">
                    <p>Deseja desconciliar esta transferência?</p>
                    <p className="font-semibold">As conciliações vinculadas a essa tranferência seram removidas</p>
                    <div className="mt-4 flex gap-4 w-full items-center justify-center">
                        <Button 
                        shadow={true} 
                        onClick={()=> {
                            handleRemoveConciliations(accountTransferId)
                        }}
                        approval={true}>Sim</Button>
                        <Button 
                        shadow={true} 
                        approval={false}
                        onClick={()=> setShowUniversalModal(false)}
                        >Não</Button>
                    </div>
                </div>
                :
                <div className="p-4 text-sm flex flex-col gap-1 justify-center items-center">
                    <p>Deseja excluir esta transferência</p>
                    <p>Os valores serão estornados e qualquer taxa lançada será excluida</p>
                    <div className="mt-4 flex gap-4 w-full items-center justify-center">
                        <Button 
                        shadow={true} 
                        onClick={()=> {
                            handleRemoveAccountTransfer(accountTransferId)
                        }}
                        approval={true}>Sim</Button>
                        <Button 
                        shadow={true} 
                        approval={false}
                        onClick={()=> setShowUniversalModal(false)}
                        >Não</Button>
                    </div>
                </div>
            }
        </ScreenModal>
            {

                <Container>
                    <TitlePage>
                        <div className="flex flex-row justify-between w-full">
                            <Title text='Transferências entre contas' />
                            <Button module={"financialAccountsTransfers:create"} userData={userData} shadow={true} onClick={() => navigate('/financial/accountsTransfersCreate')}>+ Novo</Button>
                        </div>
                    </TitlePage>
                    <Body>
                        {
                            loading ?
                                <DefaultLoader />
                                :
                                <div className="flex flex-col ga-4 w-full">
                                    <NewTableDefault
                                        key={'table-costCenter'}
                                        endpoint="/api/v1/financial/accountsTransfers"
                                        token={userData[0]?.token}
                                        module="financial"
                                        columns={[
                                            {
                                                description: 'ID',
                                                field: 'id',
                                                type: 'number'
                                            },
                                            {
                                                description: 'Data de referência',
                                                field: 'referenceDate',
                                                type: 'date',
                                            },
                                            {
                                                description: 'Conta origem',
                                                field: 'name',
                                                type: 'string',
                                                newFieldValue: (account) => {
                                                    const thisAccount = accountsList?.filter(accountData => accountData.id === account.out_account_id)[0]?.name
                                                    return <a>{thisAccount}</a>
                                                }
                                            },
                                            {
                                                description: 'Conta destino',
                                                field: 'in_account_id',
                                                type: 'number',
                                                newFieldValue: (account) => {
                                                    const thisAccount = accountsList?.filter(accountData => accountData.id === account.in_account_id)[0]?.name
                                                    return <a>{thisAccount}</a>
                                                }
                                            },
                                            {
                                                description: 'Valor',
                                                field: 'value',
                                                type: 'number',
                                                newFieldValue: (account) => {

                                                    return <div className="flex gap-2 items-center justify-center">
                                                        <a>{currencyPtBr(account?.value)}</a>
                                                    </div>

                                                }
                                            },
                                            {
                                                description: '-',
                                                type: 'string',
                                                newFieldValue: (account) => {
                                                    if (account?.in_conciliationsTransactions_id && account?.out_conciliationsTransactions_id) {
                                                        return (
                                                            <div className="flex gap-2 items-center justify-center">
                                                                <a className="text-green-600 font-semibold">Conciliado</a>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className="flex gap-2 items-center justify-center">
                                                                <a className="text-red-600 font-semibold">Não concialiado</a>
                                                            </div>
                                                        )
                                                    }

                                                }
                                            },
                                            {
                                                description: 'Açoes',
                                                field: '',
                                                type: '',
                                                newFieldValue: (account) => {
                                                    return (
                                                        <div className="flex gap-2 items-center justify-center">
                                                            {
                                                                account?.in_conciliationsTransactions_id && account?.out_conciliationsTransactions_id ?
                                                                <Tippy content={'Desconciliar transferência'}
                                                                    arrow={true}
                                                                    animation='shift-away'
                                                                    placement='top'
                                                                    delay={100}
                                                                >
                                                                    <div>
                                                                        <FaPen onClick={()=> {
                                                                            setAccountTransferId(account?.id)
                                                                            setShowUniversalModal(true)
                                                                            setShowModalConciliation(true)
                                                                        }} className="text-blue-600 cursor-pointer" />
                                                                    </div>
                                                                </Tippy>
                                                                : <></>                                                       
                                                            }
                                                            <FaTrash onClick={()=> {
                                                                setAccountTransferId(account?.id)
                                                                setShowUniversalModal(true)
                                                            }} className="text-red-600 cursor-pointer" />
                                                        </div>
                                                    )
                                                }
                                            },

                                        ]}
                                        filters={active}
                                    />
                                </div>
                        }
                    </Body>
                </Container>
            }
        </>
    )
}