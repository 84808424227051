// hooks 
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaArrowDown, FaEdit, FaFilter, FaSearch, FaBan, FaCloudDownloadAlt } from 'react-icons/fa'
// components 
import { Container } from '../../../components/container/container'
import { Tooltip } from '../../../components/toolTip/toolTip'
import { Body } from '../../../components/container/Body'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { TableDefault } from '../../../components/table/table.default'
import { TableMobile } from '../../../components/table/table.mobile'
import { Button } from '../../../components/buttons/button.default'
import { LabelInput } from '../../../components/label/label.input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Input } from '../../../components/input/input'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//services
import { formattTimeToShow } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
//utils
import { mCPF } from '../../../utils/validators/cpfMask'
import { generateCsv } from '../../../utils/excelFunctions/generateCsv'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function ClientsTable() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [usersActiveButton, setUsersActiveButton] = useState(1)
    const [clientList, setClientList] = useState('')
    const [filteredUsers, setFilteredUsers] = useState([])
    const [associations, setAssociations] = useState([])
    const { defaultCallsAuth } = useApiContext()

    //filters search
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showInternalFilterModal, setShowInternalFilterModal] = useState(false)
    const [paginationFilteredFields, setPaginationFilteredFields] = useState({})
    const [paginationHistFilteredFields, setPaginationHistFilteredFields] = useState({})
    const [paginationHistFilteredFieldsArr, setPaginationHistFilteredFieldsArr] = useState([])
    const [cleanFilter, setCleanFilter] = useState(false)
    const tableFilterModal = useRef()
    const [totalPages, setTotalPages] = useState(1)
    const [quantity, setQuantity] = useState(0)

    const tableThead = [
        {
            "name": 'Nome',
            "original_name": 'name'
        },
        {
            "name": 'E-mail',
            "original_name": 'email'
        },
        {
            "name": 'Associação',
            "original_name": "associations_id"
        },
        {
            "name": 'CPF',
            "original_name": "cpf"
        },
        {
            "name": 'Criado em',
            "original_name": "created_at"
        },
    ]

    const filters = [
        {
            "name": 'Nome',
            "original_name": 'firstName',
            "type": 'text'
        },
        {
            "name": 'Sobrenome',
            "original_name": 'lastName',
            "type": 'text'
        },
        {
            "name": 'CPF',
            "original_name": 'cpf',
            "type": 'text'
        },
        {
            "name": 'Matrícula',
            "original_name": 'registrationCode',
            "type": 'text'
        },
        {
            "name": 'E-mail',
            "original_name": 'email',
            "type": 'text'
        },
        {
            "name": 'Genero',
            "original_name": 'gender',
            "type": 'text',
        },
        {
            "name": 'Associação',
            "original_name": 'associations_id',
            "type": 'object',
            "data": associations,
            "optionList": ['name'],
            "selectedLabel": 'name',
            "optionToFilter": 'id'
        },
        {
            "name": 'Nascimento',
            "original_name": 'birthday',
            "type": 'date'
        },
        {
            "name": 'Data criação',
            "original_name": 'created_at',
            "type": 'date'
        },
    ]
    async function getData(e) {   
        setLoading(true)    
        if (e?.filter?.column == 'name') { e.filter.column = 'firstName' }
        const usersData = await defaultCallsAuth.getUsersFilteredClients(userData[0].token, e?.filter, e?.page, e?.direction, usersActiveButton)
        if (usersData.error) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(usersData.data)
        }
        const clientData = usersData.data.data.filter((client) => {
            if (client?.typeAccess_id === 4) {
                return client
            }
        })

        let formattedClients = []

        clientData.map(client => formattedClients.push({
            ...client,
            name: client.firstName + ' ' + client.lastName,
            cpf: client?.cpf?.length > 0 ? mCPF(client.cpf) : '-',
        }))
        setClientList(formattedClients)
        setFilteredUsers(formattedClients)
        setQuantity(usersData.data.quantity)
        setTotalPages(usersData.data.totalPages)
        setLoading(false)
    }

    function handleEdit(client) {

        navigate(`/clientsEdit/${client.id}`)
    }

    async function handleFilterActiveUsers(status) {

        status > 0 ? setUsersActiveButton(1) : setUsersActiveButton(0)
        const usersData = await defaultCallsAuth.getUsersFilteredClients(userData[0].token, status?.filter, status?.page, status?.direction, status)
        setTotalPages(usersData.data.totalPages)
        setQuantity(usersData.data.quantity)


        if (usersData.error) {
            setLoading(false)
            setShowNotication(usersData.data)
            return
        }

        let formattedClients = []
        usersData.data.data.map(client => formattedClients.push({ ...client, cpf: client?.cpf?.length > 0 ? mCPF(client.cpf) : '-' }))
        setClientList(formattedClients)
        setFilteredUsers(formattedClients)
        setLoading(false)
    }

    //filter functions
    async function getDataForFilter() {

        setLoading(true)
        const associatiosData = await defaultCallsAuth.getAssociations(userData[0].token)
        if (associatiosData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(associatiosData.data)
            return
        }
        setAssociations(associatiosData.data)

    }

    useEffect(() => {
        getData()
        getDataForFilter()
    }, [])

    useEffect(() => {

        let modal = document.getElementById('tableFilterModal')
        if (modal) {
            modal.addEventListener('click', (e) => handleChangeModalStatus(e, ''))
            document.addEventListener('keydown', (e) => handleChangeModalStatus(e, 'keyboard'))
        }

        async function animateFilterModal(status) {

            let fields = {}
            filters.map((column, index) => {
                fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
            })
            setPaginationFilteredFields(fields)
            if (status) {
                let histFields = {}
                filters.map((column, index) => {
                    histFields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
                })
                setPaginationHistFilteredFields(fields)
                setShowFilterModal(true)
                await new Promise(resolve => setTimeout(resolve, 200))
                return setShowInternalFilterModal(true)
            }
            setShowFilterModal(false)
            setShowInternalFilterModal(false)
        }

        animateFilterModal(showFilterModal)

        if (modal) {
            return () => { modal.removeEventListener('click', (e) => handleChangeModalStatus(e)); modal.removeEventListener('keydown', (e) => handleChangeModalStatus(e)) }
        }

    }, [showFilterModal])

    function customFilter() {
            
        let ToFilterData = []
        let fieldsKeys = Object.keys(paginationFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationFilteredFields[key].value !== '') {
                ToFilterData.push(paginationFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
        }
        getData({ filter: { filterObject: ToFilterData }, direction: false })
        setShowFilterModal(false)
    }

    function handleChangeModalStatus(e, type) {

        if (e.code === 'Escape' && type === 'keyboard') {
            setShowFilterModal(false)
        }

        if (!tableFilterModal?.current?.contains(e.target) && type !== 'keyboard') {
            setShowFilterModal(false)
        }
    }

    function orderTableData(e) {

        let ToFilterData = []
        let fieldsKeys = Object.keys(paginationHistFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationHistFilteredFields[key].value !== '') {
                ToFilterData.push(paginationHistFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
            return getData({ filter: { filterObject: ToFilterData }, direction: e?.direction, page: e.page })
        }
        getData(e)
    }

    async function handleClearFilter() {

        localStorage.removeItem('@filter:clients')
        setCleanFilter(true)
        setLoading(true)
        setPaginationHistFilteredFieldsArr([]);
        setPaginationHistFilteredFields([])
        let fields = {}
        filters.map((column, index) => {
            fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
        })
        setPaginationFilteredFields(fields)
        getData();
        await new Promise(resolve => setTimeout(resolve, 200))
        setLoading(false)
        setCleanFilter(false)

    }

    function handleCsv() {

        const array = []
        filteredUsers.map(user => {
            array.push({
                'Código': user.users_id,
                'Usuário': user.firstName + ' ' + user.lastName,
                'E-mail': user?.email,
                'Associação': user?.associations_name,
                'CPF': user?.cpf,
                'Criado em': formattTimeToShow(user?.created_at)
            })
        })
        generateCsv(array);
    }

    const handleKeyPress = (event) => {
        
        event.preventDefault()
        if (event.key === 'Enter') {
            event.preventDefault()
            customFilter()
        }
    }

    return (

        <Container>
            <TitlePage>
                <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                    <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                        <Title text={'Clientes'}></Title>
                        <div className='flex flex-row gap-2 md:w-54 justify-between items-center'>
                            <h3 className='text-base font-light dark:text-titleGrayTextDark'>{`${quantity}  ${quantity > 1 ? 'Clientes' : 'Cliente'}`}</h3>
                        </div>
                    </div>
                    <div className='flex flex-row gap-10 items-center justify-center w-[50%] md:w-auto'>
                        <div className='hidden text-titleBlackTextLight md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                            <Tippy content={'Exportar lista de usuários'}
                                arrow={true}
                                animation='shift-away'
                                placement='left'
                                delay={100}
                            >
                                <div className='flex items-center gap-2'>
                                    <a className='text-sm dark:text-titleGrayTextDark'>Exportar</a>
                                    <FaArrowDown className='dark:text-titleGrayTextDark text-sm' />
                                </div>
                            </Tippy>
                        </div>
                        {
                            (userData[0]?.permissions?.indexOf('users:create') > -1 || userData[0]?.typeAccess_id < 3) &&
                            <Button shadow={true} onClick={() => navigate('/clientsCreate')}>+ Novo cliente</Button>
                        }
                    </div>
                </div>
            </TitlePage>
            <Body>
                {
                    showFilterModal &&
                    <div id='tableFilterModal' className='fixed left-0 top-0 z-[500] w-full h-full flex flex-col items-center justify-start'>
                        <div ref={tableFilterModal} className={`${showInternalFilterModal ? 'rounded-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark opacity-1 translate-x-0' : 'translate-x-[1000px] bg-transparent opacity-0'} top-28 absolute z-[501] p-6  boxShadow transition-all duration-300 flex flex-col`}>
                            <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center mb-8'>
                                <FaSearch />
                                <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                            </div>
                            {
                                filters.map(column => {
                                    return <div onKeyUp={(e) => handleKeyPress(e)} className='flex flex-row gap-4'>
                                        <div className='w-28'>
                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{column.name}</p>
                                        </div>
                                        {
                                            column.type === 'text' ?
                                                <LabelInput>
                                                    <Input value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                        setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                    }}></Input>
                                                </LabelInput>
                                                :
                                                column.type === 'object' ?
                                                    <LabelInput>
                                                        <InputAutoComplete data={column.data} optionList={column.optionList} selectedLabel={column.selectedLabel} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                        }}></InputAutoComplete>
                                                    </LabelInput>
                                                    :
                                                    column.type === 'date' &&
                                                    <LabelInput>
                                                        <Input type={`date`} value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        }} />
                                                    </LabelInput>
                                        }
                                    </div>
                                })}
                            <div className='flex w-full flex-col items-end justify-start mt-6'>
                                <Button onClick={() => customFilter()} shadow={true}>Pesquisar</Button>
                            </div>

                        </div>
                        <div className={`${showInternalFilterModal ? 'bg-gray-700 opacity-75' : 'bg-transparent opacity-0'} absolute h-full w-full transition-all duration-300`}>

                        </div>
                    </div>

                }
                <div className='flex flex-row w-full justify-start items-center gap-4'>
                    <div className='flex flex-row items-center justify-start'>
                        <Tippy content={'Usuários ativos'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${usersActiveButton === 1 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveUsers(1)}>Ativos</button>
                        </Tippy>
                        <Tippy content={'Usuários inativos'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${usersActiveButton === 0 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveUsers(0)}>Inativos</button>
                        </Tippy>
                    </div>
                    <Tippy content={'Filtrar'}
                        arrow={true}
                        animation='shift-away'
                        placement='top'
                        delay={100}>
                        <div className={`p-1 cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150 ${paginationHistFilteredFieldsArr?.length > 0 && 'bg-gray-300 rounded-lg'}`} onClick={() => setShowFilterModal(true)}>
                            <FaFilter />
                        </div>
                    </Tippy>
                    <Tippy content={'Exportar lista em excel'}
                        arrow={true}
                        animation='shift-away'
                        placement='top'
                        delay={100}>
                        <div>
                            <Button shadow={true}>
                                <div className={`p-1 cursor-pointer flex flex-row items-center justify-center gap-2`} onClick={() => handleCsv()}>
                                    <FaCloudDownloadAlt />
                                    Exportar
                                </div>
                            </Button>
                        </div>
                    </Tippy>
                    {
                        paginationHistFilteredFieldsArr?.length > 0 &&
                        <Tippy content={'Excluir filtro'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <div className='cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150' onClick={() => handleClearFilter()}>
                                <FaBan />
                            </div>
                        </Tippy>
                    }
                </div>
                <div id='clientsTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow overflow-y-auto rounded-lg'>
                    <TableDefault
                        pagination={true}
                        totalPages={totalPages}
                        filter={(e) => orderTableData(e)}
                        onClick={(e) => handleEdit(e)}
                        title={tableThead}
                        loading={loading}
                        data={filteredUsers}
                        collumns={["name", "email", 'associationsName', 'cpf', 'created_at']}
                    />
                </div>
                <div id='clientsMobileTableContent' className={`p-2 flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                    <TableMobile
                        onClick={(e) => e.func.execute(e.data)}
                        title={tableThead}
                        loading={loading}
                        data={filteredUsers}
                        pagination={true}
                        totalPages={totalPages}
                        filter={(e) => orderTableData(e)}
                        collumns={["name", "email", 'associationsName', 'cpf', 'created_at']}
                        functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                    />
                </div>
            </Body>
        </Container>
    )
}