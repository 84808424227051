import React, { useEffect, useState } from "react";
//components
import { Container } from '../../components/container/container'
import { Body } from '../../components/container/Body'
import { Title } from '../../components/titlePages/title'
import { TitlePage } from '../../components/titlePages/title.page'
import { useAuth } from "../../contexts/useAuth";
import { DefaultLoader } from "../../components/loaders/defaultLoader";
import { Button } from "../../components/buttons/button.default";
import { useScreenSizeContext } from "../../contexts/screenSizeContext";
import { useNavigate } from "react-router-dom";
import { useThemeContext } from "../../contexts/themeContext";
import { useApiContext } from "../../contexts/ApiInterceptorContext";

export function UserTerms() {

    const { defaultCallsAuth, api_auth, api } = useApiContext()
    const { screenY } = useScreenSizeContext()
    const navigate = useNavigate()
    const { userData, setHaveTermsOfUse, setSigned, setDontHaveEmail } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [termsToRead, setTermsToRead] = useState([])
    const [loading, setLoading] = useState(true)

    async function getData() {
        setLoading(true)
        const terms = await defaultCallsAuth.getUserTerms(userData[0]?.token, userData[0]?.id)
        const userDetails = await defaultCallsAuth.getUserEspecific(userData[0]?.token, userData[0]?.id)
        if (terms.data?.data.length > 0) {
            const details = await Promise.all(terms?.data?.data.map(async term => {
                const termsDetails = await defaultCallsAuth.getUserTermsDetails(userData[0]?.token, term.id)
                let detailsTerm = {}
                detailsTerm[term?.title] = termsDetails?.data?.data
                if (termsDetails?.data?.data?.length > 0) {
                    return detailsTerm
                }
            }))
            if (details?.filter(Boolean).length === 0) {
                handleConfirmTerm(terms.data?.data[0]?.id)
                setHaveTermsOfUse(false)
                setSigned(true)
                if (!userData[0]?.email) {
                    setDontHaveEmail(true)
                    navigate(`/myProfileEdit/${userData[0]?.id}`)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setLoading(false)
                    return setShowNotificationModalText('Você não possui e-mail configurado, obrigatório cadastrar uma conta de e-mail para poder utilizar o sistema')
                }else{
                    setDontHaveEmail(false)
                }
                return navigate('/')
            }
            setTermsToRead(details.filter(Boolean))
        } else {
            setHaveTermsOfUse(false)
            setSigned(true)
            if (!userData[0]?.email) {
                setDontHaveEmail(true)
                navigate(`/myProfileEdit/${userData[0]?.id}`)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                setLoading(false)
                return setShowNotificationModalText('Você não possui e-mail configurado, obrigatório cadastrar uma conta de e-mail para poder utilizar o sistema')
            }else{
                setDontHaveEmail(false)
            }
            return navigate('/')
        }
        setLoading(false)

    }

    async function handleConfirmTerm(termId) {
        setLoading(true)
        try {
            await api_auth.post(`/api/v1/auth/userTerms/users_id/${userData[0]?.id}/terms_id/${termId}`, {},
                {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
            setLoading(false)
            getData()
        } catch (error) {
            setLoading(false)

        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Container>
            <Title>
                <TitlePage text='Termos do sistema' />
            </Title>
            <Body>
                {
                    loading ?
                        <div className="flex flex-col h-96 w-full items-center justify-center">
                            <DefaultLoader />
                        </div>
                        :
                        <div className="flex flex-col w-full items-center justify-start gap-4">
                            <a className="text-base text-primaryDefaultLight font-semibold gap-4">{Object.keys(termsToRead[0])}</a>
                            {
                                termsToRead.map((term, index) => {
                                    if (index === 0) {
                                        let termId
                                        return <div className="flex flex-col w-full gap-2">
                                            <div style={{ maxHeight: screenY - 200 }} className="overflow-auto max-h-[400px] flex flex-col w-full items-start justify-start gap-2 border border-zinc-100 bg-zinc-50 dark:border-primaryBorderDark dark:bg-secondaryDefaultDark p-4 rounded-lg">
                                                {
                                                    Object.keys(term)?.map(paragraph => {
                                                        termId = term[paragraph][0]?.termId
                                                        return term[paragraph].map(text => {

                                                            return <div className="flex flex-col gap-2 items-start justify-start">
                                                                <a className="text-base text-primaryDefaultLight">{text.subTitle}</a>
                                                                <a className="ml-4 text-sm">{text.paragraph}</a>
                                                            </div>
                                                        })
                                                    })
                                                }
                                            </div>
                                            <div className="w-full flex items-center justify-center">
                                                <Button shadow={true} onClick={() => handleConfirmTerm(termId)} >Aceito</Button>
                                            </div>
                                        </div>
                                    }
                                })
                            }
                        </div>
                }
            </Body>
        </Container>
    )
}