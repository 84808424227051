import React, { useState, useEffect } from "react";
import { Container } from "../../../../components/container/container";
import { TitlePage } from "../../../../components/titlePages/title.page";
import { Title } from "../../../../components/titlePages/title";
import { Body } from "../../../../components/container/Body";
import { LabelInput } from "../../../../components/label/label.input";
import { Input } from "../../../../components/input/input";
import { Button } from "../../../../components/buttons/button.default";
import { useAuth } from "../../../../contexts/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { useScreenSizeContext } from "../../../../contexts/screenSizeContext";
import { useThemeContext } from "../../../../contexts/themeContext";
import { validateFields } from "../../../../utils/form.validator";
import moment from "moment";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import tableToExcel from "../../../../utils/excelFunctions/tableToExcel";
import { FaCloudDownloadAlt } from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { useApiContext } from "../../../../contexts/ApiInterceptorContext";

export function FinancialReportsPerCategory() {

    const { defaultCallsFinancial } = useApiContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { screenX } = useScreenSizeContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, showUniversalModal, setShowUniversalModal } = useThemeContext()
    const { incomeOrExpense } = useParams()
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({
        incomeOrExpense
    })
    const [categoriesGroup, setCategoriesGroup] = useState([])
    const [categories, setCategories] = useState([])
    const [data, setData] = useState([])
    const [totalPeriod, setTotalPeriod] = useState('')
    const [periodSelecteds, setPeriodSelecteds] = useState([])
    const [generatedReport, setGeneratedReport] = useState(false)

    useEffect(() => {
        getData()
    }, [])
    
    async function getData(){

        const getCategoriesGroup = await defaultCallsFinancial.getFinancialCategoriesGroups(userData[0]?.token)
        if (getCategoriesGroup.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(getCategories.data)
        }
        setCategoriesGroup(getCategoriesGroup.data?.filter(catG => catG?.incomeOrExpense == incomeOrExpense))
        
        const getCategories = await defaultCallsFinancial.getFinancialCategories(userData[0]?.token)
        if (getCategories.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(getCategories.data)
        }
        setCategories(getCategories.data?.filter(cat => cat?.incomeOrExpense == incomeOrExpense))

    }

    async function generateReport(){

        const requiredFields = [
            { name: 'startDate', value: filter?.startDate, required: true, type: 'string' },
            { name: 'finishDate', value: filter?.finishDate, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowUniversalModal(false)
            setLoading(false)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        setLoading(true)

        const momentStartDate = moment(filter?.startDate)
        const momentFinishDate = moment(filter?.finishDate)

        const monthsAndYear = []
        while (momentStartDate.isSameOrBefore(momentFinishDate, 'month')) {
            monthsAndYear.push({
                description: `${momentStartDate.locale('pt-br').format('MMMM').replace(/\b\w/, (c) => c.toUpperCase())}/${momentStartDate.format('YYYY')}`,
                month: Number(momentStartDate.format('MM')),
                year: Number(momentStartDate.format('YYYY'))
            })
            momentStartDate.add(1, 'month')
        }
        setPeriodSelecteds(monthsAndYear)

        const getData = await defaultCallsFinancial.getFinancialReportsPerCategory(userData[0]?.token, filter)
        if (getData.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(getData.data)
        }

        setData(getData.data)
        setTotalPeriod(getData.data.reduce((acc, i) => acc += i.value, 0))
        setGeneratedReport(true)

        setLoading(false)
    }

    return (
        <Container>
            <TitlePage>
                <Title text={`${incomeOrExpense == 'R' ? 'Receitas' : 'Despesas'} por categoria`}/>
            </TitlePage>
            <Body background={false} shadow={false}>
                <div className="w-full h-full relative">
                    <div className="absolute w-full bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex flex-col sm:flex-row justify-between w-full items-center">
                            <div className="flex flex-col sm:flex-row sm:gap-2 gap-4 sm:items-end items-center">
                                <LabelInput text={'Data inicial'} required>
                                    <Input
                                        type={'date'}
                                        width={60}
                                        onChange={(e) => setFilter(prev => ({...prev, startDate: e?.target?.value}))}
                                        value={filter?.startDate ? filter?.startDate : ''}
                                        id={'startDate'}
                                    />
                                </LabelInput>
                                <LabelInput text={'Data final'} required>
                                    <Input
                                        type={'date'}
                                        width={60}
                                        onChange={(e) => setFilter(prev => ({...prev, finishDate: e?.target?.value}))}
                                        value={filter?.finishDate ? filter?.finishDate : ''}
                                        id={'finishDate'}
                                    />
                                </LabelInput>
                                <Button height={8} onClick={() => generateReport()}>Gerar</Button>
                                <div className="flex sm:hidden">
                                    <Button height={8} onClick={() => tableToExcel(`${Number(new Date)}.xls`)}>Baixar em excel</Button>
                                </div>
                            </div>
                            {
                                data?.length > 0 &&
                                <div className="hidden sm:flex items-end h-full pr-2">
                                    <Tippy content={'Exportar em excel'}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top'
                                        delay={100}>
                                        <div>
                                            <FaCloudDownloadAlt className='text-xl cursor-pointer text-primaryDefaultLight' onClick={() => tableToExcel(`${Number(new Date)}.xls`)}/>
                                        </div>
                                    </Tippy>
                                    <a id="link-to-download" href="!#">{}</a>
                                </div>
                            }
                        </div>
                        {
                            loading ? 
                            <div className="w-full h-40 sm:h-96 flex items-center justify-center">
                                <p className="animate-pulse">Gerando relatório...</p>
                            </div>
                            : data?.length > 0 ?
                            <div className={`flex flex-col h-full mt-8 w-full`}>
                                <div className="flex h-full md:max-h-[42vh] lg:max-h-[53vh] xl:max-h-[60vh] 2xl:max-h-[63vh] flex-col pb-0 shadow-xl pr-0 overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 rounded-md">
                                    <table className='border-collapse' id='TableToExport'>
                                        <thead className='m-0 p-0 bg-primaryDefaultLight text-white font-bold relative'>
                                            <tr className='sticky z-20 top-0'>       
                                                <th className='sm:sticky left-0 top-0 bg-primaryDefaultLight '>
                                                    <div className='min-w-[8rem] z-5 p-0 border-0 text-sm'>
                                                        Categoria
                                                    </div>
                                                </th>
                                                <th className='bg-primaryDefaultLight text-sm'> Valor Total </th>
                                                <th className='bg-primaryDefaultLight text-sm'> AV (%) </th>
                                                {
                                                    periodSelecteds?.map(month => {
                                                        return (
                                                            <th className='bg-primaryDefaultLight text-sm'>{month?.description}</th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className='pb-2 m-0'>
                                            {
                                                categoriesGroup?.map((group, index) => {                                            
                                                    const filterCategories = categories?.filter(item => item?.financialCategoriesGroups_id == group?.id)                                                
                                                    const filterDataPerGroup = data?.filter(dat => dat?.financialCategoriesGroups_id == group?.id)
                                                    const totalValueGroup = filterDataPerGroup.reduce((acc, i) => acc += i.value, 0)
                                                    let av = 0
                                                    if (totalValueGroup > 0){
                                                        av = ((totalValueGroup / totalPeriod) * 100)?.toFixed(1)
                                                    }
                                                    return (
                                                        <>
                                                            <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`}>                                                        
                                                                <td className={`m-0 p-0 sm:z-10 sm:sticky left-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                                    <div className='min-w-[15rem] py-1 px-1 border-0 flex text-sm justify-start dark:text-white items-center gap-1'>
                                                                        {
                                                                            !group.expanded ?
                                                                            <FaAngleRight className="cursor-pointer" onClick={() => {
                                                                                group.expanded = !group.expanded
                                                                                setCategoriesGroup([...categoriesGroup])
                                                                            }}/>
                                                                            :
                                                                            <FaAngleDown className="cursor-pointer" onClick={() => {
                                                                                group.expanded = !group.expanded
                                                                                setCategoriesGroup([...categoriesGroup])
                                                                            }}/>
                                                                        }
                                                                        {group?.description}
                                                                    </div>
                                                                </td>
                                                                <td className={`m-0 px-0 sm:sticky left-0 sm:z-3 border-l border-l-gray-600`}>
                                                                    <div className='min-w-[6rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                        {totalValueGroup?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                    </div>
                                                                </td>
                                                                <td className={`m-0 px-0 border-l border-l-gray-600`}>
                                                                    <div className='min-w-[6rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                        {av}%
                                                                    </div>
                                                                </td>
                                                                {
                                                                    periodSelecteds?.map(period => {
                                                                        const filterData = filterDataPerGroup?.filter(dat => dat?.month == period?.month && dat.year == period?.year)
                                                                        const totalValuePeriod = filterData.reduce((acc, i) => acc += i.value, 0)
                                                                        return (
                                                                            <td className={`m-0 px-0 border-l border-l-gray-600`}>
                                                                                <div className='min-w-[6rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                    {totalValuePeriod?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                </div>
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>                                               
                                                            {
                                                                group?.expanded &&
                                                                filterCategories?.map(category => {
                                                                    const filterDataCategories = data?.filter(item => item?.financialCategoriesGroups_id == group?.id && item?.financialCategories_id == category?.id)                                            
                                                                    const totalValueCategory = filterDataCategories.reduce((acc, i) => acc += i.value, 0)
                                                                    let avCat = 0
                                                                    if (totalValueCategory > 0){
                                                                        avCat = ((totalValueCategory / totalValueGroup) * 100)?.toFixed(1)
                                                                    }
                                                                    return (
                                                                        <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                                                            <td className={`m-0 p-0 sm:z-10 sm:sticky left-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                                                <div className='min-w-[15rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                    {category?.description}
                                                                                </div>
                                                                            </td>
                                                                            <td className={`m-0 px-0 border-l border-l-gray-600`}>
                                                                                <div className='min-w-[6rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                    {totalValueCategory?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                </div>
                                                                            </td>
                                                                            <td className={`m-0 px-0 border-l border-l-gray-600`}>
                                                                                <div className='min-w-[6rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                    {avCat}%
                                                                                </div>
                                                                            </td>
                                                                            {
                                                                                periodSelecteds?.map(period => {
                                                                                    const filterData = filterDataCategories?.filter(dat => dat?.month == period?.month && dat.year == period?.year)
                                                                                    const totalValuePeriod = filterData.reduce((acc, i) => acc += i.value, 0)
                                                                                    return (
                                                                                        <td className={`m-0 px-0 border-l border-l-gray-600`}>
                                                                                            <div className='min-w-[6rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                {totalValuePeriod?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                            </div>
                                                                                        </td>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                            <tr className='sticky z-10 bottom-0 text-sm text-white'>
                                                <td className='sm:sticky sm:z-10 left-0 text-center bg-primaryDefaultLight'>
                                                    <div className='min-w-[15rem] font-bold z-5 py-1 border-0 text-sm'>
                                                        Total:
                                                    </div>
                                                </td>
                                                <td className='text-center bg-primaryDefaultLight'>
                                                    <div className='min-w-[8rem] font-bold z-5 py-1 border-0 text-sm'>
                                                        {data.reduce((acc, i) => acc += i.value, 0)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                    </div>
                                                </td>
                                                <td className='text-center bg-primaryDefaultLight'>
                                                    <div className='min-w-[8rem] font-bold z-5 py-1 border-0 text-sm'>
                                                        100%
                                                    </div>
                                                </td>
                                                {
                                                    periodSelecteds?.map(period => {
                                                        const filterData = data?.filter(dat => dat?.month == period?.month && dat.year == period?.year)
                                                        const totalValuePeriod = filterData.reduce((acc, i) => acc += i.value, 0)
                                                        return (
                                                            <td className='text-center bg-primaryDefaultLight'>
                                                                <div className='min-w-[8rem] font-bold z-5 py-1 border-0 text-sm'>
                                                                    {totalValuePeriod?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                </div>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : generatedReport &&
                            <p className="w-full flex items-center justify-center text-primaryDefaultLight mt-8">
                                Não há {incomeOrExpense == 'R' ? 'receitas' : 'despesas'} pagas no período selecionado
                            </p>
                        }
                    </div>
                </div>
            </Body>
        </Container>
    )

}