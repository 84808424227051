import React, { useEffect, useState, useRef } from "react";
//components
import { FaEdit, FaSearch } from "react-icons/fa";
import { LabelInput } from "../../../../components/label/label.input";
import { Input } from "../../../../components/input/input";
import { Container } from '../../../../components/container/container'
import { TableDefault } from '../../../../components/table/table.default'
import { InputAutoComplete } from "../../../../components/input/input.autocomplete";
import { TableMobile } from '../../../../components/table/table.mobile'
import { TitlePage } from '../../../../components/titlePages/title.page'
import { Title } from '../../../../components/titlePages/title'
import { Body } from '../../../../components/container/Body'
import { useAuth } from "../../../../contexts/useAuth";
import { useThemeContext } from "../../../../contexts/themeContext";
import { formattTimeToShow } from "../../../../services/api/callAPIsFunctions/defaultCalls.api";
import { maskHours } from "../../../../utils/mask/maskHours";
import { Button } from "../../../../components/buttons/button.default";
import { FaBan, FaFilter } from "react-icons/fa";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { DefaultLoader } from "../../../../components/loaders/defaultLoader";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useApiContext } from "../../../../contexts/ApiInterceptorContext";

export function MySchedules() {

    const {defaultCallsSchedules, api} = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { userData } = useAuth()
    const [mySchedulesList, setMySchedulesList] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    //filters search
    const [usersActiveButton, setUsersActiveButton] = useState(1)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showInternalFilterModal, setShowInternalFilterModal] = useState(false)
    const [paginationFilteredFields, setPaginationFilteredFields] = useState({})
    const [paginationHistFilteredFields, setPaginationHistFilteredFields] = useState({})
    const [paginationHistFilteredFieldsArr, setPaginationHistFilteredFieldsArr] = useState([])
    const [cleanFilter, setCleanFilter] = useState(false)
    const tableFilterModal = useRef()
    const [totalPages, setTotalPages] = useState(1)
    const [quantity, setQuantity] = useState(0)
    const [inEdit, setInEdit] = useState(false)
    const [editData, setEditData] = useState([])
    const statusList = [
        { id: 0, description: "Reservando" },
        { id: 1, description: "Pago | Realizado" },
        { id: 2, description: "Cancelado" },
        { id: 3, description: "Aguardando pagamento" },
    ]

    const tableThead = [
        {
            "name": "ID da Reserva",
            "original_name": "id"
        },
        {
            "name": "Tipo",
            "original_name": "isRecurrent"
        },
        {
            "name": 'Serviço/Espaço',
            "original_name": 'services_id'
        },
        {
            "name": 'Reserva',
            "original_name": 'startDate',
        },
        {
            "name": 'Status',
            "original_name": "status"
        },
        {
            "name": "ID do Pagamento",
            "original_name": "id",
            "table": "payments"
        },
    ]

    const filters = [
        {
            "name": 'ID da Reserva',
            "original_name": 'schedules_id',
            "type": 'text'
        },
        {
            "name": 'ID do Pagamento',
            "original_name": 'payments_id',
            "type": 'text'
        },
        {
            "name": 'Data agendada',
            "original_name": 'dateStart',
            "type": 'date'
        },
        {
            "name": 'Data criação',
            "original_name": 'created_at',
            "type": 'date'
        },
        {
            "name": 'Data',
            "original_name": 'endDate',
            "type": 'date'
        },
        {
            "name": 'Status',
            "original_name": 'status',
            "type": 'object',
            "data": statusList,
            "optionList": ['description'],
            "selectedLabel": 'description',
            "optionToFilter": 'id'
        }
    ]

    async function getData(e) {
        setLoading(true)
        const mySchedulesData = await defaultCallsSchedules.getMySchedules(userData[0].token, e?.filter, e?.page, e?.direction, usersActiveButton)
        if (mySchedulesData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(mySchedulesData.data)
        }
        if (mySchedulesData.data?.data?.length > 0) {
            mySchedulesData.data.data.map(schedule => {
                schedule.dueDate = schedule.dueDate ? formattTimeToShow(schedule.dueDate) : '-'
                schedule.created_at = schedule.created_at ? formattTimeToShow(schedule.created_at) : '-'
                schedule.original_dateToCancel = schedule.dateToCancel
                schedule.dateToCancel = schedule.dateToCancel ? moment(schedule?.dateToCancel).utcOffset(0).format('DD/MM/YYYY HH:mm') : '-'
                schedule.payments_dueDate = schedule.payments_dueDate ? formattTimeToShow(schedule.payments_dueDate) : '-'
                schedule.startDate = `${formattTimeToShow(schedule.startDate)} - ${maskHours({ hour: schedule.startHour })} - ${maskHours({ hour: schedule.finishHour })}`
                schedule.showIsRecurrent = schedule.isRecurrent ? 'Recorrente' : 'Avulsa'
                //trata status
                schedule.payments_original_status = schedule.payments_status
                schedule.payments_status = schedule.payments_status === 0 ?
                    <div className='p-2 '><a className='bg-yellow-500 p-1 rounded-lg text-white'>Reservando</a></div>
                    : schedule.payments_status === 1 && schedule.status == 2 ?
                    <div className='p-2 '><a className='bg-green-300 p-1 rounded-lg text-white'>Pago | Cancelado</a></div>
                        : schedule.payments_status === 1 && schedule.status == 1 ?
                        <div className='p-2 '><a className='bg-green-500 p-1 rounded-lg text-white'>Pago | Realizado</a></div>
                            : schedule.payments_status === 2 ?
                            <div className='p-2 '><a className='bg-gray-500 p-1 rounded-lg text-white'>Cancelado</a></div>
                                : schedule.payments_status === 3 
                                && <div className='p-2 '><a className='bg-blue-400 p-1 rounded-lg text-white'>Aguardando pagamento</a></div>
            })
        }
        setMySchedulesList(mySchedulesData.data.data)
        setTotalPages(mySchedulesData.data.totalPages)
        setQuantity(mySchedulesData.data.quantity)
        setLoading(false)

    }

    useEffect(() => {
        getData()
    }, [])

    //filter functions
    async function handleClearFilter() {

        setLoading(true)
        setPaginationHistFilteredFieldsArr([]);
        setPaginationHistFilteredFields([])
        let fields = {}
        filters.map((column, index) => {
            fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
        })
        setPaginationFilteredFields(fields)
        getData();
        await new Promise(resolve => setTimeout(resolve, 200))
        setLoading(false)

    }

    useEffect(() => {
        if (!inEdit) {
            getData()
        }
    }, [inEdit])

    useEffect(() => {

        let modal = document.getElementById('tableFilterModal')
        if (modal) {
            modal.addEventListener('click', (e) => handleChangeModalStatus(e, ''))
            document.addEventListener('keydown', (e) => handleChangeModalStatus(e, 'keyboard'))
        }

        async function animateFilterModal(status) {

            let fields = {}
            filters.map((column, index) => {
                fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
            })
            setPaginationFilteredFields(fields)
            if (status) {
                let histFields = {}
                filters.map((column, index) => {
                    histFields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
                })
                setPaginationHistFilteredFields(fields)
                setShowFilterModal(true)
                await new Promise(resolve => setTimeout(resolve, 200))
                return setShowInternalFilterModal(true)
            }
            setShowFilterModal(false)
            setShowInternalFilterModal(false)
        }

        animateFilterModal(showFilterModal)

        if (modal) {
            return () => { modal.removeEventListener('click', (e) => handleChangeModalStatus(e)); modal.removeEventListener('keydown', (e) => handleChangeModalStatus(e)) }
        }

    }, [showFilterModal])

    function customFilter() {

        let ToFilterData = []
        let fieldsKeys = Object.keys(paginationFilteredFields)
        //percorre os filtros informados pelo usuario para enviar no endpoint da API
        fieldsKeys.map((key) => {
            if (paginationFilteredFields[key]?.value !== '') {
                ToFilterData.push(paginationFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
        }
        //chama a API com os parametros informados pelo usuario
        getData({ filter: { filterObject: ToFilterData }, direction: false })
        setShowFilterModal(false)
    }

    function handleChangeModalStatus(e, type) {

        if (e.code === 'Escape' && type === 'keyboard') {
            setShowFilterModal(false)
        }

        if (!tableFilterModal?.current?.contains(e.target) && type !== 'keyboard') {
            setShowFilterModal(false)
        }
    }

    function orderTableData(e) {
        const ToFilterData = []
        const fieldsKeys = Object.keys(paginationHistFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationHistFilteredFields[key].value !== '') {
                ToFilterData.push(paginationHistFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
            return getData({ filter: { filterObject: ToFilterData }, direction: e?.direction, page: e.page })
        }
        getData(e)
    }

    function handleEdit(e) {    
        
        if(e.payments_original_status === 0){
            navigate('/reservation')
        }else if (e.payments_origin === 1 && e?.payments_original_status === 3) {
            navigate('/reservation')
        } else {
            localStorage.setItem('@mySchedulesDetails', JSON.stringify(e))
            navigate('/mySchedulesDetails')
        }
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Minhas reservas'} />
            </TitlePage>
            <Body>
                {
                    showFilterModal &&
                    <div id='tableFilterModal' className='fixed left-0 top-0 z-[500] w-full h-full flex flex-col items-center justify-start'>
                        <div ref={tableFilterModal} className={`${showInternalFilterModal ? 'rounded-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark opacity-1 translate-x-0' : 'translate-x-[1000px] bg-transparent opacity-0'} top-28 absolute z-[501] p-6  boxShadow transition-all duration-300 flex flex-col`}>
                            <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center mb-8'>
                                <FaSearch />
                                <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                            </div>
                            {

                                filters.map(column => {
                                    return column.original_name !== 'dateStart' && column.original_name !== 'endDate' &&
                                        <div className='flex flex-col sm:flex-row gap-4'>
                                            <div className='sm:w-28'>
                                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{column.name}</p>
                                            </div>
                                            {
                                                column.type === 'text' ?
                                                    <LabelInput>
                                                        <Input value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        }}></Input>
                                                    </LabelInput>
                                                    :
                                                    column.type === 'object' ?
                                                        <LabelInput>
                                                            <InputAutoComplete data={column.data} optionList={column.optionList} selectedLabel={column.selectedLabel} onChange={(e) => {
                                                                setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                                setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                            }}></InputAutoComplete>
                                                        </LabelInput>
                                                        :
                                                        column.type === 'date' &&
                                                        <LabelInput>
                                                            <Input type={`date`} value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                                setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                                setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                            }} />
                                                        </LabelInput>
                                            }
                                        </div>
                                })}
                            <div className='flex flex-col mt-8 sm:mt-0 sm:flex-row gap-4 border-t sm:border-none pt-4 sm:pt-0'>
                                <div className='w-28'>
                                    <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Periodo</a>
                                </div>
                                <LabelInput>
                                    <Input width={40} type={`date`} value={paginationFilteredFields['dateStart']['value']} onChange={(e) => {
                                        setPaginationFilteredFields({ ...paginationFilteredFields, ['dateStart']: { ...paginationFilteredFields['dateStart'], value: e.target.value } });
                                        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, ['dateStart']: { ...paginationFilteredFields['dateStart'], value: e.target.value } });
                                    }} />
                                </LabelInput>
                                <a className='mt-2 mx-3'>A</a>
                                <LabelInput>
                                    <Input width={40} type={`date`} value={paginationFilteredFields['endDate']['value']} onChange={(e) => {
                                        setPaginationFilteredFields({ ...paginationFilteredFields, ['endDate']: { ...paginationFilteredFields['endDate'], value: e.target.value } });
                                        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, ['endDate']: { ...paginationFilteredFields['endDate'], value: e.target.value } });
                                    }} />
                                </LabelInput>
                            </div>
                            <div className='flex w-full flex-col items-start sm:items-end justify-start mt-6'>
                                <Button onClick={() => customFilter()} shadow={true}>Pesquisar</Button>
                            </div>
                        </div>
                        <div className={`${showInternalFilterModal ? 'bg-gray-700 opacity-75' : 'bg-transparent opacity-0'} absolute h-full w-full transition-all duration-300`}>
                        </div>
                    </div>
                }
                <div className='flex flex-row w-full items-center justify-start gap-4'>
                    <Tippy content={'Filtros'}
                        arrow={true}
                        animation='shift-away'
                        placement='top'
                        delay={100}>
                        <div>
                            <Button shadow={true}>
                                <div className={`p-1 cursor-pointer flex flex-row items-center justify-center gap-2`} onClick={() => setShowFilterModal(true)}>
                                    <FaFilter />
                                    Filtrar
                                </div>
                            </Button>
                        </div>
                    </Tippy>
                    {
                        paginationHistFilteredFieldsArr?.length > 0 &&
                        <Tippy content={'Excluir filtro'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <div className='cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150' onClick={() => { handleClearFilter() }}>
                                <FaBan />
                            </div>
                        </Tippy>
                    }
                </div>
                {
                    !cleanFilter ?

                        <>
                            <div id='mySchedulesTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                <TableDefault
                                    pagination={true}
                                    totalPages={totalPages}
                                    onClick={(e) => handleEdit(e)}
                                    title={tableThead}
                                    data={mySchedulesList}
                                    collumns={['id', "showIsRecurrent", "services_name", "startDate", 'payments_status', 'payments_id']}
                                    loading={loading}
                                    filter={(e) => orderTableData(e)}
                                />
                            </div>
                            <div id='reservationMobileTableContent' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                <TableMobile
                                    onClick={(e) => e.func.execute(e.data)}
                                    title={tableThead}
                                    loading={loading}
                                    pagination={true}
                                    totalPages={totalPages}
                                    filter={(e) => orderTableData(e)}
                                    data={mySchedulesList}
                                    collumns={['id', "showIsRecurrent", "services_name", "startDate", 'payments_status', 'payments_id']}
                                    functions={[{ "title": "Ver detalhes", "icon": <FaEdit />, "execute": handleEdit }]}
                                />
                            </div>
                        </>
                        :
                        <div className="h-96 w-full items-center justify-center">
                            <DefaultLoader />
                        </div>
                }

            </Body>
        </Container>
    )
}