import React, { useEffect, useRef, useState } from "react";
import { CalendarMain } from "../../../components/calendar/calendar.main";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Input } from "../../../components/input/input";
import { LabelInput } from "../../../components/label/label.input";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Toogle } from "../../../components/toogle/Toogle";
import { Footer } from '../../../components/footer/Footer'
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components/buttons/button.default";
import { FaCalendarDay } from "react-icons/fa";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import moment from 'moment'
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import 'moment/locale/pt-br'
//utils
import { justNumber } from '../../../utils/validators/justNumber'
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function HolidayCalendarEdit() {

    const { api, defaultCallsSchedules} = useApiContext()
    const navigate = useNavigate()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const thisHoliday = useParams()
    const { userData } = useAuth()
    const [description, setDescription] = useState('')
    const [recurrency, setRecurrency] = useState(false)
    const [showCalendar, setShowCalendar] = useState(false)
    const [selectedDay, setSelectedDay] = useState('')
    const [selectedMonth, setSelectedMonth] = useState('')
    const [selectedDate, setSelectedDate] = useState('')
    const [loading, setLoading] = useState(true)

    const calendarRef = useRef()
    const calendaContainerRef = useRef()

    const monthList = [
        { id: 0, month: 1, description: 'Janeiro' },
        { id: 1, month: 2, description: 'Fevereiro' },
        { id: 2, month: 3, description: 'Março' },
        { id: 3, month: 4, description: 'Abril' },
        { id: 4, month: 5, description: 'Maio' },
        { id: 5, month: 6, description: 'Junho' },
        { id: 6, month: 7, description: 'Julho' },
        { id: 7, month: 8, description: 'Agosto' },
        { id: 8, month: 9, description: 'Setembro' },
        { id: 9, month: 10, description: 'Outubro' },
        { id: 10, month: 11, description: 'Novembro' },
        { id: 11, month: 12, description: 'Dezembro' },
    ]

    function handleCloseCalendar(e) {
        if (!calendarRef.current.contains(e.target)) {
            setShowCalendar(false)
        }
        if (e?.key === 'Escape') {
            setShowCalendar(false)
        }
    }

    function formatDate(year, month, day) {
        let thisDay = day < 10 ? '0' + day?.toString() : day
        let thisYear = moment().year()?.toString()
        let thisMonth = month < 10 ? '0' + month?.toString() : month

        try {
            return moment(`${thisYear}-${thisMonth}-${thisDay}`)?.format('DD/MM/YYYY')
        } catch (error) {
            return `${thisYear}-${thisMonth}-${thisDay}`
        }
    }


    useEffect(() => {

        if (!loading) {
            const calendarElement = calendaContainerRef.current
            if (calendarElement) {
                calendarElement.addEventListener('click', (e) => handleCloseCalendar(e))
                calendarElement.addEventListener('keydown', (e) => handleCloseCalendar(e))

                return () => {
                    calendarElement.removeEventListener('keypress', (e) => handleCloseCalendar(e))
                    calendarElement.removeEventListener('click', (e) => handleCloseCalendar(e))

                };
            }
        }
    }, [loading])

    useEffect(() => {

        setShowUniversalModal(false)

        async function getData() {

            setLoading(true)
            try {
                const holidays = await defaultCallsSchedules.getHolidays(userData[0]?.token)
                if (holidays.data?.length > 0) {
                    holidays.data.map(holiday => {
                        if (holiday.id !== parseInt(thisHoliday.id)) {
                            return
                        }
                        if (holiday?.recurrency) {
                            holiday.holidayDate = formatDate(holiday.year, holiday.month, holiday.day)
                        }
                        setDescription(holiday?.description)
                        setRecurrency(holiday?.recurrency)
                        setSelectedDate(holiday?.holidayDate)
                        setSelectedDay(holiday?.day)
                        setSelectedMonth(holiday?.month)
                    })
                }
                setLoading(false)

            } catch (error) {
                console.log(error)
                if (responseError(error).length > 0) {
                    setLoading(false)
                    animateLoading(true)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    animateLoading(true)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        getData()

    }, [])

    async function handleDeleteHoliday() {
        setLoading(true)
        try {

            await api.delete(`/api/v1/holidays/${thisHoliday.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText("Cadastro removido com sucesso")
            navigate('/holidayCalendar')

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal>
                <div className="flex flex-col w-full p-2 items-center justify-center">
                    <p className="mb-6 text-titleBlackTextLight dark:text-titleGrayTextDark text-base">Deseja realmente excluir o feriado?</p>
                    <div className="flex flex-row gap-4">
                        <Button shadow={true} onClick={() => handleDeleteHoliday()}>Sim</Button>
                        <Button approval={false} onClick={() => setShowUniversalModal(false)} >Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Editar feriado'} />
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className="flex flex-col items-center justify-center w-full h-96">
                                <DefaultLoader />
                            </div>
                            :
                            <div ref={calendaContainerRef} className="flex flex-col items-start justify-start flex-wrap w-full gap-4">
                                <LabelInput text={'Descrição *'}>
                                    <Input disabled={true} value={description} width={'320px'} type={'text'} charLimit={255} onChange={(e) => setDescription(e.target.value)} />
                                </LabelInput>
                                <a className="text-xt text-inputPlaceholderLight -translate-y-3">{description?.length} de 255</a>
                                <LabelInput text={'Recorrente'}>
                                    <Toogle disabled={true} onClick={() => setRecurrency(!recurrency)} status={recurrency ? true : false} />
                                </LabelInput>
                                {
                                    recurrency ?
                                        <div className="flex flex-row gap-2 items-start justify-start">
                                            <LabelInput text={'Mês'}>
                                                <InputAutoComplete disabled={true} preSelectedValue={monthList.filter(month => month.month === selectedMonth)[0]?.description} data={monthList} selectedLabel={'description'} optionList={['description']} onChange={(e) => setSelectedMonth(e)} />
                                            </LabelInput>
                                            <LabelInput text={'Dia'}>
                                                <Input value={selectedDay} type={'text'} charLimit={2} onChange={(e) => setSelectedDay(justNumber(e.target.value))}></Input>
                                            </LabelInput>
                                        </div>
                                        :
                                        <LabelInput text={'Data'}>
                                            <div className="bg-gray-300 dark:bg-thirdDefaultDark mt-2 flex flex-row items-start justify-start relative" ref={calendarRef}>
                                                <div className="flex flex-col items-center justify-center text-2xl text-primaryDefaultLight h-10 w-12 border border-gray-400 first-letter:dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark">
                                                    <FaCalendarDay />
                                                </div>
                                                <input disabled={true} onFocus={(e) => setShowCalendar(true)} onBlur={() => setShowCalendar(false)} value={selectedDate._isAMomentObject ? selectedDate?.format('DD/MM/YYYY') : selectedDate} onClick={() => setShowCalendar(true)} className={`pl-2 border border-gray-400 border-opacity-75 
                                    outline-none
                                    h-10
                                    w-80
                                    text-lg sm:text-sm
                                    transition-all duration-200
                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark 
                                    dark:focus:shadow-borderShadow'`}>

                                                </input>
                                                <div className="w-56 absolute top-12">
                                                    {
                                                        showCalendar &&
                                                        <CalendarMain onClick={(e) => setSelectedDate(e)} />
                                                    }
                                                </div>
                                            </div>
                                        </LabelInput>
                                }
                            </div>
                    }
                </Body >
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => history.back()}>Voltar</Button>
                        <Button shadow={true} onClick={() => setShowUniversalModal(true)} >Excluir</Button>
                    </div>
                </Footer>
            </Container >
        </>

    )
}