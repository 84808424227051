//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { validateFields } from '../../../utils/form.validator'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function CostCenterCreate() {

    const navigate = useNavigate()
    const { api_financial } = useApiContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    //selected variables
    const [costCenter, setCostCenter] = useState('')

    async function handleSubmit() {

        const requiredFields = [
            { "name": 'name', "value": costCenter?.name, "required": true, "type": 'string' },
            { "name": 'code', "value": costCenter?.code, "required": false, "type": 'string' },
        ]
        //chamada API
        setLoading(true)
        try {

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            await api_financial.post(`/api/v1/financial/costCenter`, {
                name: costCenter?.name,
                code: costCenter?.code ? costCenter.code : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Centro de custo criado com sucesso!")
            navigate('/financial/costCenter')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <Title text={'Cadastro de centro de custo'}></Title>
                </TitlePage>
                <Body hasFooter={true}>

                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <form className='flex flex-col gap-2 items-start justify-start sm:justify-center w-full'>
                                    <LabelInput text={'Nome: *'}>
                                        <Input id='name' type={'text'} value={costCenter?.name} onChange={(e) => setCostCenter(prev => ({ ...prev, name: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Código:'}>
                                        <Input id='code' type={'text'} value={costCenter?.code} onChange={(e) => setCostCenter(prev => ({ ...prev, code: e.target.value }))}></Input>
                                    </LabelInput>
                                </form>
                            </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate('/financial/costCenter')}>Cancelar</Button>
                        <Button shadow={true} approval={true} onClick={() => {
                          handleSubmit()
                        }}>Cadastrar</Button>
                    </div>
                </Footer>
            </Container>
        </>

    )
}