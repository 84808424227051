// hooks 
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
// components 
import { FaFilter, FaSearch, FaBan, FaMouse } from 'react-icons/fa'
import { TableDefault } from '../table/table.default'
import { TableMobile } from '../table/table.mobile'
import { Button } from '../buttons/button.default'
import { LabelInput } from '../label/label.input'
import { Input } from '../input/input'
import { InputAutoComplete } from '../input/input.autocomplete'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';

//contexts
import { useAuth } from '../../contexts/useAuth'
import { DefaultLoader } from '../loaders/defaultLoader'
import { useApiContext } from '../../contexts/ApiInterceptorContext'

export function UsersToFind({ handleSelect }) {

    const { api_auth, defaultCallsAuth} = useApiContext()
    const { userData } = useAuth()
    const [showNotification, setShowNotication] = useState('')
    const [loading, setLoading] = useState(true)
    const [usersActiveButton, setUsersActiveButton] = useState(1)
    const [usersList, setUsersList] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [totalPages, setTotalPages] = useState(1)

    //filters search
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showInternalFilterModal, setShowInternalFilterModal] = useState(false)
    const [paginationFilteredFields, setPaginationFilteredFields] = useState({})
    const [paginationHistFilteredFields, setPaginationHistFilteredFields] = useState({})
    const [paginationHistFilteredFieldsArr, setPaginationHistFilteredFieldsArr] = useState([])
    const tableFilterModal = useRef()
    const [permissionGroups, setPermissionGroups] = useState([])
    const [associations, setAssociations] = useState([])

    const tableThead = [
        {
            "name": 'Usuário',
            "original_name": 'firstName'
        },
        {
            "name": 'E-mail',
            "original_name": 'email'
        },
        {
            "name": 'Tipo de acesso',
            "original_name": "typeAccess_id"
        },
        {
            "name": 'Associação',
            "original_name": "associationsName"
        },
        {
            "name": 'Criado em',
            "original_name": "created_at"
        },
    ]

    const filters = [
        {
            "name": 'Nome',
            "original_name": 'firstName',
            "type": 'text'
        },
        {
            "name": 'Sobrenome',
            "original_name": 'lastName',
            "type": 'text'
        },
        {
            "name": 'Matricula',
            "original_name": 'registrationCode',
            "type": 'text'
        },
        {
            "name": 'E-mail',
            "original_name": 'email',
            "type": 'text'
        },
        {
            "name": 'Genero',
            "original_name": 'gender',
            "type": 'text',
        },
        {
            "name": 'Associação',
            "original_name": 'associations_id',
            "type": 'object',
            "data": associations,
            "optionList": ['name'],
            "selectedLabel": 'name',
            "optionToFilter": 'id'
        },
        {
            "name": 'Nascimento',
            "original_name": 'birthday',
            "type": 'text'
        },
        {
            "name": 'Data criação',
            "original_name": 'created_at',
            "type": 'text'
        },
        {
            "name": 'Grupo de permissão',
            "original_name": 'permissionGroups_id',
            "type": 'object',
            "data": permissionGroups,
            "optionList": ['name'],
            "selectedLabel": 'name',
            "optionToFilter": 'id'

        },
    ]

    async function getData(e) {

        setLoading(true)
        const usersData = await defaultCallsAuth.getAllUsersFiltered(userData[0].token, e?.filter, e?.page, e?.direction, true, true)

        setTotalPages(usersData.data.totalPages)

        if (usersData.error) {
            setLoading(false)
            setShowNotication(usersData.data)
            return
        }

        const filterUsers = usersData?.data.data?.filter((user) => {
            if (user.id !== 0) {
                return user
            }
        })

        setUsersList(filterUsers.map(user => {
            return {
                ...user,
                firstName: `${user?.firstName} ${user?.lastName}`
            }
        }))
        setFilteredUsers(filterUsers)
        setLoading(false)
    }

    useEffect(() => {

        handleFilterActiveUsers(1)

    }, [usersList])

    function handleFilterActiveUsers(status) {

        const newUserList = usersList.filter((user) => {
            if (status === 1 && user?.active) {
                return user
            }
            if (status === 0 && !user?.active) {
                return user
            }
            if (status === undefined) {
                return user
            }
        })

        status !== undefined ? setFilteredUsers(newUserList) : setFilteredUsers(usersList)
        status > 0 ? setUsersActiveButton(1) : setUsersActiveButton(0)
    }

    //filter functions
    async function getDataForFilter() {

        setLoading(true)
        const associatiosData = await defaultCallsAuth.getAssociations(userData[0].token)
        const permissionsGroupData = await defaultCallsAuth.getPermissionGroups(userData[0].token)

        setPermissionGroups(permissionsGroupData.data)
        setAssociations(associatiosData.data)

    }

    useEffect(() => {
        getData()
        getDataForFilter()
    }, [])

    useEffect(() => {

        let modal = document.getElementById('tableFilterModal')
        if (modal) {
            modal.addEventListener('click', (e) => handleChangeModalStatus(e, ''))
            document.addEventListener('keydown', (e) => handleChangeModalStatus(e, 'keyboard'))
        }

        async function animateFilterModal(status) {

            let fields = {}
            filters.map((column, index) => {
                fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
            })
            setPaginationFilteredFields(fields)
            if (status) {
                let histFields = {}
                filters.map((column, index) => {
                    histFields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
                })
                setPaginationHistFilteredFields(fields)
                setShowFilterModal(true)
                await new Promise(resolve => setTimeout(resolve, 200))
                return setShowInternalFilterModal(true)
            }
            setShowFilterModal(false)
            setShowInternalFilterModal(false)
        }

        animateFilterModal(showFilterModal)

        if (modal) {
            return () => { modal.removeEventListener('click', (e) => handleChangeModalStatus(e)); modal.removeEventListener('keydown', (e) => handleChangeModalStatus(e)) }
        }

    }, [showFilterModal])

    function customFilter() {
        let ToFilterData = []
        let fieldsKeys = Object.keys(paginationFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationFilteredFields[key].value !== '') {
                ToFilterData.push(paginationFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
        }
        getData({ filter: { filterObject: ToFilterData }, direction: false })
        setShowFilterModal(false)
    }

    function handleChangeModalStatus(e, type) {

        if (e.code === 'Escape' && type === 'keyboard') {
            setShowFilterModal(false)
        }

        if (!tableFilterModal?.current?.contains(e.target) && type !== 'keyboard') {
            setShowFilterModal(false)
        }
    }

    function orderTableData(e) {

        let ToFilterData = []
        let fieldsKeys = Object.keys(paginationHistFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationHistFilteredFields[key].value !== '') {
                ToFilterData.push(paginationHistFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
            return getData({ filter: { filterObject: ToFilterData }, direction: e?.direction })
        }
        getData(e)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            customFilter()
        }
    }

    return (

        <div className={`
                            md:h-[85vh]
                            md:m-0
                            h-[70vh]
                            w-[80vw]
                            max-w-[1950px]
                            scrollbar-thin scrollbar-thumb-zinc-400 scrollbar-track-zinc-200 dark:scrollbar-thumb-thirdDefaultDark
                            dark:scrollbar-track-primaryDefaultDark scrollbar-thumb-rounded-full scrollbar-track-rounded-full
                            overflow-auto flex flex-col items-center justify-start pb-6`}>
            <div className={`rounded-lg flex shadow-lg                 
                        flex-col w-full md:mt-[-2rem] 3xl:w-full gap-4 items-center dark:border dark:border-primaryBorderDark 
                        bg-bgPrimaryLight dark:bg-secondaryDefaultDark
                        p-2 md:p-8`}>
                {

                    showFilterModal &&
                    <div id='tableFilterModal' className='fixed left-0 top-0 z-[200] w-full flex flex-col items-center justify-start h-fit'>
                        <div ref={tableFilterModal} className={`${showInternalFilterModal ? 'rounded-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark opacity-1 translate-x-0' : 'translate-x-[1000px] bg-transparent opacity-0'}  top-5 md:top-28 absolute z-[501] p-6  boxShadow transition-all duration-300 flex flex-col`}>
                            <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center mb-8'>
                                <FaSearch />
                                <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                            </div>
                            {

                                filters.map(column => {
                                    return <form onKeyUp={(e) => handleKeyPress(e)} className='flex flex-row gap-4'>
                                        <div className='w-28'>
                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{column.name}</p>
                                        </div>
                                        {
                                            column.type === 'text' ?
                                                <LabelInput>
                                                    <Input value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                        setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                    }}></Input>
                                                </LabelInput>
                                                :
                                                <LabelInput>
                                                    <InputAutoComplete data={column.data} optionList={column.optionList} selectedLabel={column.selectedLabel} onChange={(e) => {
                                                        setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                    }}></InputAutoComplete>
                                                </LabelInput>
                                        }

                                    </form>
                                })}
                            <div className='flex w-full flex-row gap-4 items-end justify-end mt-6'>
                                <Button onClick={() => customFilter()} shadow={true}>Pesquisar</Button>
                                <Button onClick={() => setShowFilterModal(false)} shadow={true}>Cancelar</Button>
                            </div>

                        </div>
                        <div className={`${showInternalFilterModal ? 'bg-gray-500 opacity-75' : 'bg-gray-500 opacity-75'} z-[101] top-0 fixed transition-all w-full  h-full duration-300`}></div>
                    </div>

                }
                <div className='flex flex-row w-full justify-start items-center gap-4'>

                    <div className='cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight' onClick={() => setShowFilterModal(true)}>
                        <Button shadow={true}>
                            <div className={`p-1 cursor-pointer flex flex-row items-center justify-center gap-2`} onClick={() => setShowFilterModal(true)}>
                                <FaFilter />
                                Filtrar
                            </div>
                        </Button>
                    </div>
                    {
                        paginationHistFilteredFieldsArr?.length > 0 &&
                        <Tippy content={'Excluir filtro'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <div className='cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150' onClick={() => {setPaginationHistFilteredFieldsArr([]); getData()}}>
                                <FaBan />
                            </div>
                        </Tippy>
                    }
                </div>
                {
                    11 === 2 ?
                        <DefaultLoader />
                        :
                        <div id='usersTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                            <TableDefault
                                pagination={true}
                                totalPages={totalPages}
                                onClick={(e) => handleSelect(e)}
                                title={tableThead}
                                data={filteredUsers}
                                collumns={["firstName", "email", 'typeAccessDescription', , 'associationsName', 'created_at']}
                                loading={loading}
                                filter={(e) => orderTableData(e)}
                            />
                        </div>
                }

                <div id='usersMobileTableContent' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                    {
                        !loading &&
                        <TableMobile
                            onClick={(e) => e.func.execute(e.data)}
                            title={tableThead}
                            data={filteredUsers}
                            pagination={true}
                            totalPages={totalPages}
                            filter={(e) => orderTableData(e)}
                            collumns={["firstName", "email", 'typeAccessDescription', 'associationsName', 'created_at']}
                            functions={[{ "title": "Selecionar", "icon": <FaMouse />, "execute": handleSelect }]}
                        />
                    }

                </div>
            </div>

        </div>

    )


}