import React, { useState, useEffect, useRef } from 'react'
import { FaArrowDown } from 'react-icons/fa'
import { useThemeContext } from '../../contexts/themeContext'
import { responseError } from '../../utils/responsesFunctions/error.response'
import { useApiContext } from '../../contexts/ApiInterceptorContext'

/**
 * Função que cria input dinamico com totais.
 * @component
 * @param {Object} props - Objeto contendo
 * @param {string} props.id - Id do elemento
 * @param {string} props.endpoint - Endpoint do elemento
 * @param {string} props.module - Módulo do sistema, opções: "activitiesSubscritions" | "schedules" | "events" | "gastronomy" | "auth" | "financial"
 * @param {string} props.token - Token do elemento
 * @param {any} props.filters - Filtros em forma de objeto para aplicar na request (query params)
 * @param {string[]} props.optionList - O que é exibido na listagem
 * @param {string} props.optionToSearch - Campo que será usado na busca pelo valor
 * @param {string[]} props.mapTotalPages - Mapeia aonde estará no endpoint a informação de total de paginas
 * @param {string[]} props.mapApiData - Mapeia aonde estará no endpoint os dados retornados do endpoint
 * @param {Function} props.onChange - Callback para receber o valor externamente
 * @param {any} props.preSelectedValue - Valor inicial e/ou no momento
 * @param {string} props.placeholder - Descrição exibida no campo
 * @param {boolean} props.disabled - Para desabilitar e habilitar input
 * @param {boolean} props.autoFocus - Auto focar no elemento ao aparecer em tela
 * @param {number} props.width - Tamanho do input 
 * @returns {JSX.Element}
 */
export function InputDinamicAutoComplete({
    id, 
    endpoint,
    module,
    token,
    filters,
    optionList, 
    optionToSearch,
    mapTotalPages = [],
    mapApiData = [],
    onChange, 
    preSelectedValue,
    placeholder, 
    disabled, 
    autoFocus, 
    width
}) {

    //refs
    const componentRef = useRef(null)
    //contexts
    const { api, api_auth, api_financial } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { screenX } = useThemeContext()
    //states
    const [ loading, setLoading ] = useState(true)
    const [ loadingSearch, setLoadingSearch ] = useState(true)
    const [ page, setPage ] = useState(1)
    const [ lastPage, setLastPage ] = useState(1)
    const [ showOptions, setShowOptions ] = useState(false)
    const [ search, setSearch ] = useState('')
    const [ lastSearch, setLastSearch ] = useState('')
    const [ dataList, setDataList ] = useState([])
    const [ totalPages, setTotalPages ] = useState(1)
    const [ cursor, setCursor ] = useState(-1)
    const [ selectedValue, setSelectedValue ] = useState(null)
    const [ showSelectedValue, setShowSelectedValue ] = useState('')

    const WIDTH_INPUT = width > 0 ? width : 96
    const SEARCH_DELAY = 700;
    const MIN_SEARCH_LENGTH = 2;

    useEffect(() => {
        getData(null, preSelectedValue)

        function handleClickOutside(event) {
            if (componentRef.current && !componentRef.current.contains(event.target)) {
                setCursor(-1)
                setShowOptions(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [endpoint, token, module])

    useEffect(() => {
        const searchSize = search?.length
        if (lastSearch !== search && (searchSize > MIN_SEARCH_LENGTH || searchSize == 0)) {
            const timeoutId = setTimeout(() => {
                setPage(1)
                setLoading(true)
                setLastSearch(search)
                getData(search, null)
            }, SEARCH_DELAY)
            return () => clearTimeout(timeoutId)
        } else {
            if(lastPage !== page){
                setLastPage(page)
                getData(search)
            }
        }
    }, [search, page])

    async function getData(textToSearch = null, preValue = null){
        if(!endpoint && !token && !module){
            return console.log('Não é possivel realizar filtros sem saber aonde buscar, informe o "endpoint", "token" e "module"')
        }
        try {
            setLoadingSearch(true)
            
            const api_origin = identifyModule()

            const formatFilter = []
            if(Object.keys(filters)?.length > 0) {
                Object.keys(filters)?.map(filter => {
                    if(filters[filter] === null || filters[filter] === undefined) return
                    formatFilter.push(`${filter}=${filters[filter]?.toString()}`)
                })
            }
            if(preValue){
                Object.keys(preValue)?.map(value => {
                    if(preValue[value] === null || preValue[value] === undefined) return
                    formatFilter.push(`${value}=${preValue[value]?.toString()}`)
                })
            }
            if(optionToSearch && textToSearch && search?.length > MIN_SEARCH_LENGTH){
                formatFilter.push(`${optionToSearch}=${textToSearch?.toString()}`)
            }
            
            const formatedFilter = formatFilter?.length > 0 ? formatFilter.join('&') : ''

            const api_endpoint = endpoint + `?page=${page}&${formatedFilter}` 
            const response = await api_origin.get(api_endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            mapAndSetData(response.data, preValue)
            mapAndSetTotalPages(response.data)        
        } catch (error) {
            const errorResponse = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(errorResponse?.length > 0 ? errorResponse : 'Erro inesperado')
        } finally {
            setLoading(false)
            setLoadingSearch(false)
        }
    }
    
    function identifyModule(){
        if(module == 'activitiesSubscritions') return api
        if(module == 'schedules') return api
        if(module == 'events') return api
        if(module == 'gastronomy') return api
        if(module == 'auth') return api_auth
        if(module == 'financial') return api_financial
    }

    function mapAndSetData(apiResponseData, preValue = null){
        if(mapApiData.length > 0){ // mapeia no objeto da resposta o DATA
            const value = utilsMapObjectFinder(apiResponseData, mapApiData)

            if(preValue) {
                selectOption(value[0])
                getData(null, null)
            }

            return setDataList(
                page === 1 
                    ? value 
                    : [...dataList, ...value]
            )
        }

        if (preValue) {
            getData(null, null)
            selectOption(apiResponseData.data[0])
        }

        return setDataList(
            page === 1 
                ? apiResponseData.data 
                : [...dataList, ...apiResponseData.data]
        )
    }

    function mapAndSetTotalPages(apiResponseData){
        if(mapTotalPages.length > 0){
            try {
                const value = utilsMapObjectFinder(apiResponseData, mapTotalPages)
                setTotalPages(value)
            } catch (error) {
                setTotalPages(null)
                console.error('NewTableDefault, property: mapTotalPages -', error.message)
            }
        }
    }

    function utilsMapObjectFinder(object, mapSequence){
        let value = object
        for (const key of mapSequence) {
            if(!value.hasOwnProperty(key)){
                throw new Error(`Chave '${key}' não encontrada nos dados.`)
            }
            value = value[key];
        }
        return value
    }

    function keyBoardNavigation(e) {
        if (e.key === 'ArrowDown') {
            setCursor(cursor < dataList.length - 1 ? cursor + 1 : cursor)
        }
        if (e.key === 'ArrowUp') {
            setCursor(cursor > 0 ? cursor - 1 : 0)
        }
        if (e.key === 'Enter' && cursor > -1) {
            selectOption(dataList[cursor])
        }
        if (['Escape', 'Tab'].includes(e.key)) {
            e.stopPropagation()
            closeOptions()
        }
    }

    function onTypeToSearch(textToSearch){
        if(!showOptions){
            setShowOptions(true)
        }
        setShowSelectedValue(textToSearch)
        setSearch(textToSearch)
    }

    function selectOption(item){
        setShowOptions(false)
        setCursor(-1)
        const value = optionList?.map(option => item[option]).join(' - ')
        setShowSelectedValue(value)
        setSelectedValue(item)
        onChange(item)
    }

    function closeOptions(){
        setCursor(-1)
        setShowOptions(false)
        const value = optionList?.map(option => item[option]).join(' - ')
        setShowSelectedValue(value)
    }

    const inputStyle = `
        pl-2 text-lg sm:text-sm border border-titleGrayTextDark
        focus:bg-titleGrayTextLight dark:text-titleGrayTextDark 
        dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark h-16 sm:h-8 
        outline-none transition-all duration-200 focus:shadow-borderShadow w-${WIDTH_INPUT} 
        ${disabled ? 'bg-gray-300 dark:bg-thirdDefaultDark' : ''}
    `

    return (
        <div ref={componentRef} className='relative'>
            <div className='flex items-center justify-center relative'>
                <input 
                    id={id}
                    type="text" 
                    onFocus={() => setShowOptions(true)}
                    className={inputStyle}
                    onClick={() => !showOptions && setShowOptions(true)}
                    onChange={(e) => onTypeToSearch(e.target.value)}
                    value={showSelectedValue}
                    onKeyDown={e => keyBoardNavigation(e)}
                    placeholder={placeholder}
                    disabled={disabled}
                    autoFocus={autoFocus ? true : false}
                />
                <FaArrowDown 
                    className={`absolute right-0 mr-4 duration-200 transform ${showOptions ? 'rotate-180 text-primaryDefaultLight' : 'text-primaryBorderDark'}`} 
                    size={10}
                />
            </div>
            <div>
                {   
                    showOptions &&
                    <div className={`absolute z-50 w-full max-h-[120px] overflow-y-auto bg-white dark:bg-primaryDefaultDark border border-t-0 border-titleGrayTextDark shadow-lg`}>
                        {loading 
                            ? <p className='hover:bg-bgSecondaryLight dark:hover:bg-secondaryDefaultDark cursor-pointer p-2 text-primaryDefaultLight text-center'>
                                Carregando...
                            </p>
                            : showOptions && (
                                <ul className={`w-${WIDTH_INPUT} bg-white dark:bg-primaryDefaultDark`}>
                                    {dataList.map((item, index) => {
                                        return (
                                            <li 
                                                key={`item-${index}`}
                                                className={`hover:bg-gray-200 cursor-pointer p-2 ${cursor == index ? `bg-gray-200` : 'bg-white'}`}
                                                onClick={() => {
                                                    selectOption(item)
                                                }}
                                            > 
                                                {
                                                    optionList?.map((option, index) => {
                                                        return (
                                                            <span key={`description-${index}`}>
                                                                {item[option]}{optionList.length > 1 && index < optionList.length - 1 ? ' | ' : ''}
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </li>
                                        )
                                    })}
                                    {
                                        totalPages > page && !loadingSearch &&
                                        <li 
                                            onClick={() => setPage((prev) => prev + 1)}
                                            className={`hover:bg-bgSecondaryLight dark:hover:bg-secondaryDefaultDark cursor-pointer p-2 text-primaryDefaultLight text-center`}
                                        >
                                            Ver mais...
                                        </li>
                                    }
                                    {
                                        loadingSearch &&
                                        <li className={`hover:bg-bgSecondaryLight dark:hover:bg-secondaryDefaultDark cursor-pointer p-2 text-primaryDefaultLight animate-pulse text-center`}>
                                            Carregando...
                                        </li>
                                    }
                                    {
                                        dataList.length === 0 && !loadingSearch &&
                                        <li className={`hover:bg-bgSecondaryLight dark:hover:bg-secondaryDefaultDark cursor-pointer p-2 text-primaryDefaultLight text-center`}>
                                            Nenhum resultado encontrado
                                        </li>
                                    }
                                </ul>
                            )
                        }
                    </div>
                }
            </div>
        </div>
    )
}