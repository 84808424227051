import { useState } from 'react'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'

export function DropDown({ children, title }) {

    const [showItens, setShowItens] = useState(false)

    return <div
        onClick={() => setShowItens(!showItens)}
        className={`w-full select-none rounded-t-lg shadow-md ${!showItens && 'hover:bg-zinc-100 dark:hover:bg-primaryDefaultDark'} transition-all
                        border border-zinc-100 dark:border-secondaryBorderDark cursor-pointer flex flex-col justify-center items-center`}>
        <div className='w-full flex justify-between py-4 px-2 h-full items-center'>
            <a className='text-primaryDefaultLight dark:text-primaryDefaultLight font-semibold text-sm'>{title}</a>
            <FaArrowDown style={{ transform: showItens ? 'rotate(180deg)' : 'rotate(0deg)' }} className='dark:text-primaryDefaultLight transition-all duration-200 text-zinc-300' />
        </div>
        {
            showItens && children ?
                <div

                    onClick={(e) => {
                        e.stopPropagation(); // Impede a propagação do clique para o dropdown externo
                    }}
                    className='py-2 w-full animate-[wiggleDown_.2s_ease-in-out]'>
                    {children}
                </div>
                :
                showItens &&
                <div className='py-2 text-sm w-full flex items-start justify-center border-t border-zinc-300 text-zinc-600'>
                    Lista vazia
                </div>
        }
    </div>

}