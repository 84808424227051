//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { validateFields } from '../../../utils/form.validator'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Toogle } from '../../../components/toogle/Toogle'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function CostCenterEdit() {

    const params = useParams()
    const { defaultCallsFinancial, api_financial } = useApiContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    //selected variables
    const [costCenter, setCostCenter] = useState('')

    async function getData() {
        setShowUniversalModal(false)
        const costCenterData = await defaultCallsFinancial.getCostCenterById(userData[0].token, params?.id)
        if (costCenterData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(costCenterData.data)
            return
        }
        setCostCenter(costCenterData?.data[0])
    }

    useEffect(() => {
        getData()
    }, [])

    async function handleSubmit() {

        let requiredFields =
            [
                { "name": 'name', "value": costCenter?.name, "required": true, "type": 'string' },
                { "name": 'code', "value": costCenter?.code, "required": false, "type": 'string' },
            ]
        //chamada API
        setLoading(true)
        try {

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            await api_financial.put(`/api/v1/financial/costCenter/${params.id}`, {
                name: costCenter?.name,
                code: costCenter?.code ? costCenter.code : undefined,
                active: [true, false].includes(costCenter?.active) ? costCenter.active : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Centro de custo salvo com sucesso!")
            navigate('/financial/costCenter')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleRemove() {
        setLoading(true)

        try {
            await api_financial.delete(`/api/v1/financial/costCenter/${params.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Centro de custo removido com sucesso!")
            navigate('/financial/costCenter')

        } catch (error) {
            setShowUniversalModal(false)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal>
                <div className='p-4 flex flex-col text-sm items-center justify-center gap-2'>
                    <a>Deseja realmente excluir este centro de custo?</a>
                    <div className='flex gap-4'>
                        <Button shadow={true} onClick={() => handleRemove()}>Sim</Button>
                        <Button approval={false} shadow={true} onClick={() => setShowUniversalModal(false)}>Cancelar</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Edição de centro de custo'}></Title>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <form className='flex flex-col gap-2 items-start justify-start sm:justify-center w-full'>
                                    <LabelInput text={'Nome: *'}>
                                        <Input id='name' type={'text'} value={costCenter?.name} onChange={(e) => setCostCenter(prev => ({ ...prev, name: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Código: *'}>
                                        <Input id='code' type={'text'} value={costCenter?.code} onChange={(e) => setCostCenter(prev => ({ ...prev, code: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'ativo'}>
                                        <Toogle status={costCenter?.active} onClick={() => setCostCenter(prev => ({ ...prev, active: !costCenter?.active }))} />
                                    </LabelInput>
                                </form>
                            </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate('/financial/costCenter')}>Cancelar</Button>
                        <div className='flex gap-2'>
                            <Button
                                module={'financialCostCenter:delete'}
                                userData={userData}
                                shadow={true} approval={false} onClick={() => {
                                    setShowUniversalModal(true)
                                }}>Excluir</Button>
                            <Button
                                module={'financialCostCenter:edit'}
                                userData={userData}
                                shadow={true} approval={true} onClick={() => {
                                    handleSubmit()
                                }}>Salvar</Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>

    )
}
