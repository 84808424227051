import { Container } from '../../../components/container/container'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { NewTableDefault } from '../../../components/table/new.table.default'
import { useAuth } from '../../../contexts/useAuth'
import { useEffect, useState } from 'react'
import { FaTrash, FaSearch } from "react-icons/fa";
import { FiSlash } from "react-icons/fi";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import moment from 'moment'
import { LabelInput } from '../../../components/label/label.input'
import { InputDinamicAutoComplete } from '../../../components/input/input.dinamic.autocomplete'
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { Button } from '../../../components/buttons/button.default';
import { useThemeContext } from '../../../contexts/themeContext'
import { UsersToFind } from '../../../components/table/usersToFind.table'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'
import { SelectPeriod } from '../../../components/period/selectPeriod'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'

export function FinancialAgreementsShoppings() {

    const { userData } = useAuth()
    const { defaultCallsSchedules, api_financial } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [filter, setFilter] = useState(getInitialFilter())
    const [filterConstructor, setFilterConstructor] = useState({})
    const [loading, setLoading] = useState(true)
    const [loadingFilter, setLoadingFilter] = useState(false)
    const [agreementsList, setAgreementsList] = useState([])
    const [showModalType, setShowModalType] = useState('')
    const [selectedUser, setSelectUser] = useState('')
    const [selectedAgreement, setSelectedAgreement] = useState('')
    const [selectedShopping, setSelectedShopping] = useState()
    const [selectedShoppings, setSelectedShoppings] = useState([])


    const whatsDateList = [
        { value: 'created_at', label: 'Data de entrada' },
        { value: 'dueDate', label: 'Data de vencimento' },
    ]

    async function getData() {

        try {
            const agreementsData = await defaultCallsSchedules.getAgreements(userData[0]?.token)
            setAgreementsList(agreementsData?.data?.data)
            setLoading(false)
        } catch (error) {

        }
    }

    useEffect(() => {
        getData()
    }, [])

    function getInitialFilter() {
        const defaultFilter = {
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            finishDate: moment().endOf('month').format('YYYY-MM-DD'),
            whatsDate: 'created_at'
        }
        return defaultFilter
    }

    function setAndSaveFilter(filterObj) {
        delete filter.startDate
        delete filter.finishDate
        delete filter.description
        delete filter.users_id
        delete filter.agreements_id
        delete filter.created_at

        setSelectUser('')
        setSelectedAgreement('')

        setFilter({ ...filterObj, ...filter })
    }

    function handleSelectUser(e) {
        setFilterConstructor({ ...filterConstructor, users_id: e?.id })
        setSelectUser(e)
        setShowModalType('')
    }

    function identifyAgreementShoppingsMasterId() {

        if (selectedShopping?.internalReferencesSendId) {
            return selectedShopping?.internalReferencesSendId
        }

        return selectedShopping?.id
    }

    async function handleRemoveAgreementShoppings() {
        setLoading(true)
        try {
            await api_financial.delete(`/api/v1/financial/agreements/shoppings/${identifyAgreementShoppingsMasterId()}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Compra removida com sucesso')
            setShowUniversalModal(false)
        } catch (error) {
            console.log(error)
            setShowNotificationModal(true)
            setShowNotificationModalText('Erro ao remover compra')
        } finally {
            setLoading(false)
        }
    }

    async function handleSendAgreementShoppings() {

        const agreementsShoppingsId = selectedShoppings?.filter(e => !e?.isConciliated).map(e => e?.internalReferencesSendId ?? e.id)

        const agreementsMastersId = agreementsShoppingsId.filter((value, index, array) => {
            return array.indexOf(value) === index;
        })

        setLoading(true)
        try {
            await api_financial.post(`/api/v1/financial/agreements/shoppings/sendDefinitiveAgreementsShopping`, {
                agreementShoppings: agreementsMastersId
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Compra enviada com sucesso')
            setShowUniversalModal(false)
        } catch (error) {
            console.log(error)
            setShowNotificationModal(true)
            setShowNotificationModalText('Erro ao enviar compra')
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <ScreenModal>
                {
                    showModalType === "usersToFind" ?
                        <UsersToFind handleSelect={(e) => handleSelectUser(e)} />
                        :
                        showModalType === "confirmRemoveShopping" ?
                            <div className={`p-6 boxShadow duration-300 flex flex-col`}>
                                <a className='text-red-600 font-semibold'>Atenção as compras parceladas serão removidas todas as parcelas</a>
                                <a className='text-sm'>Deseja realmente prosseguir?</a>
                                <div className='flex gap-2 items-center justify-center'>
                                    <Button approval={true} onClick={() => handleRemoveAgreementShoppings()} shadow={true}>Sim</Button>
                                    <Button approval={false} onClick={() => { setShowModalType(''); setShowUniversalModal(false) }} shadow={true}>Não</Button>
                                </div>
                            </div>
                            :
                            showModalType === "confirmSendShoppings" ?
                                <div className={`p-6 boxShadow duration-300 flex flex-col`}>
                                    <a className='text-red-600 font-semibold'>Atenção as compras ja conciliadas não serão enviadas</a>
                                    <a className='text-sm'>Deseja realmente prosseguir e gerar o financeiro para estas compras?</a>
                                    <div className='flex gap-2 items-center justify-center'>
                                        <Button approval={true} onClick={() => handleSendAgreementShoppings()} shadow={true}>Sim</Button>
                                        <Button approval={false} onClick={() => { setShowModalType(''); setShowUniversalModal(false) }} shadow={true}>Não</Button>
                                    </div>
                                </div>
                                :
                                <div className={`p-6 boxShadow duration-300 flex flex-col`}>
                                    <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center'>
                                        <FaSearch />
                                        <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                                    </div>
                                    {
                                        loadingFilter ?
                                            <></>
                                            :
                                            <>
                                                <form className="flex flex-col gap-5 justify-center items-center w-[600px] py-10 ">
                                                    <div className='flex flex-col gap-5 items-start justify-start'>
                                                        <div className="flex flex-row gap-3 items-center justify-start w-96">
                                                            <a className="text-titleBlackTextLight text-sm w-24">Cliente: </a>
                                                            <a
                                                                onClick={() => setShowModalType('usersToFind')}
                                                                className='cursor-pointer underline text-sm'>
                                                                {
                                                                    selectedUser?.id ? `${selectedUser?.firstName.length >= 20 ? selectedUser?.firstName?.slice(0, 20).concat('...') : selectedUser.firstName}` : 'Selecionar'
                                                                }
                                                            </a>
                                                        </div>
                                                        <div className="flex flex-row gap-3 items-center justify-start">
                                                            <a className="text-titleBlackTextLight text-sm w-24">Convênio: </a>
                                                            <div className='w-80'>
                                                                <LabelInput text={''}>
                                                                    <InputAutoComplete
                                                                        width={80}
                                                                        data={agreementsList}
                                                                        optionList={['id', 'title']}
                                                                        selectedLabel={'title'}
                                                                        onChange={(e) => {
                                                                            setSelectedAgreement(e);
                                                                            setFilterConstructor({ ...filterConstructor, agreements_id: e?.id })
                                                                        }}
                                                                    >
                                                                    </InputAutoComplete>
                                                                </LabelInput>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row gap-3 items-center  justify-start">
                                                            <a className="text-titleBlackTextLight text-sm w-24">Descrição: </a>
                                                            <div className='w-80'>
                                                                <Input
                                                                    value={filterConstructor?.description ? filterConstructor.description : ''}
                                                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, description: e?.target.value })}></Input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className='flex w-full flex-col items-end justify-start mt-6'>
                                                    <Button onClick={() => {
                                                        setFilter({ ...filter, ...filterConstructor })
                                                        setShowUniversalModal(false)
                                                    }} shadow={true}>Pesquisar</Button>
                                                </div>
                                            </>
                                    }
                                </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Compras em convênios'}></Title>
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <DefaultLoader />
                            :
                            <div className='w-full flex flex-col gap-4'>
                                <div className="flex flex-row justify-center sm:justify-end items-center gap-3 w-full">

                                    <SelectPeriod
                                        initialDate={filter?.startDate}
                                        endDate={filter?.finishDate}
                                        showOptionsDate={true}
                                        selectedOptionDate={filter?.whatsDate}
                                        optionsDate={whatsDateList}
                                        onChange={(e) => setFilter({ ...filter, ...e })}
                                    />
                                    <Button onClick={() => { setShowUniversalModal(true); setShowModalType('') }}>Filtros</Button>
                                    {
                                        Object.keys(filterConstructor).length > 0 &&
                                        <Tippy
                                            key={`clearFilter`}
                                            content={<span>Limpar filtro</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='top'
                                            delay={100}>
                                            <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                                <FiSlash className='cursor-pointer' onClick={async () => {
                                                    setLoadingFilter(true)
                                                    setFilterConstructor({})
                                                    setAndSaveFilter({
                                                        startDate: filter.startDate,
                                                        finishDate: filter.finishDate,
                                                        whatsDate: filter?.whatsDate
                                                    })
                                                    await new Promise(resolve => setTimeout(resolve, 500))
                                                    setLoadingFilter(false)
                                                }} />
                                            </div>
                                        </Tippy>
                                    }
                                </div>
                                <NewTableDefault
                                    key={'table-financialBalances'}
                                    endpoint="/api/v1/financial/agreements/shoppings"
                                    token={userData[0]?.token}
                                    module="financial"
                                    withSelection={true}
                                    onSelect={e => setSelectedShoppings(e)}
                                    columns={[
                                        {
                                            description: 'ID',
                                            field: 'id',
                                            type: 'number'
                                        },
                                        {
                                            description: 'Descrição',
                                            field: 'description',
                                            type: 'string',

                                        },

                                        {
                                            description: 'Valor',
                                            field: 'value',
                                            type: 'money',
                                        },
                                        {
                                            description: 'Criado em',
                                            field: 'created_at',
                                            type: 'date',
                                        },
                                        {
                                            description: 'Vencimento',
                                            field: 'dueDate',
                                            type: 'date',
                                        },
                                        {
                                            description: 'Açoes',
                                            field: '',
                                            type: 'string',
                                            newFieldValue: (data) => {

                                                return <div className='flex gap-2 items-center justify-center'>
                                                    {
                                                        data?.isConciliated ?
                                                            <a className='text-xs'>Conciliado</a>
                                                            :
                                                            <FaTrash

                                                                onClick={() => {
                                                                    setSelectedShopping(data)
                                                                    setShowUniversalModal(true)
                                                                    setShowModalType('confirmRemoveShopping')
                                                                }}
                                                                className='text-red-600 cursor-pointer' />
                                                    }
                                                </div>
                                            }
                                        },
                                    ]}
                                    mapApiData={['data']}
                                    filters={filter}
                                />
                                {
                                    selectedShoppings?.length > 0 &&
                                    <div className='flex flex-row gap-4 items-center justify-center'>
                                        <Button
                                            onClick={() => {
                                                setShowModalType('confirmSendShoppings');
                                                setShowUniversalModal(true)
                                            }}
                                            approval={true} shadow={true}>Enviar</Button>
                                    </div>
                                }
                            </div>
                    }
                </Body>
            </Container>
        </>

    )
}