// hooks 
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// utils
import { configItems } from "./configuration.items";
//contexts
import { useAuth } from "../../../../contexts/useAuth";
import { useScreenSizeContext } from '../../../../contexts/screenSizeContext'
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import { NotificationModal } from "../../../../components/modals/notification/notification.modal";
import { FaArrowAltCircleRight } from "react-icons/fa";

export function Configuration() {

    const { userData, selectedModule } = useAuth()
    const navegate = useNavigate()
    const { screenX, screenY } = useScreenSizeContext()
    const [ordenedItens, setOrdenedItens] = useState([])

    useEffect(() => {
        setOrdenedItens(orderAndGroupArray(configItems))
    }, [])

    function orderAndGroupArray(array) {
        const groupedArray = array.reduce((result, item) => {
            const group = item.group;
            if (!result[group]) {
                result[group] = [];
            }
            result[group].push(item);
            return result;
        }, {});

        const orderedArray = Object.entries(groupedArray).map(([group, items]) => ({
            group,
            items,
        }));

        return orderedArray;
    }

    return (

        <div id='configuration-menu' className="overflow-y-auto flex flex-col bg-white dark:bg-primaryDefaultDark w-full p-4" style={{ height: screenX < 640 ? screenY - 67 : 'auto' }}>
            <NotificationModal />
            <h1 className="text-xl text-primaryDefaultLight">Configurações do sistema</h1>
            <div id='configuration-menu-items' className="mt-10 flex flex-row flex-wrap gap-4 items-start justify-start">
                {
                    ordenedItens.map(groupItems => {
                        let userModules = userData[0]?.customersData?.modules?.map(module => { return module.id })
                        let groupModules = groupItems.items.map(groupModules => { return groupModules.module})
                        userModules.push(0) // adicionando o menu geral

                        if (groupModules.filter(module => (userModules.includes(module) && selectedModule.includes(module)) || module == 0).length > 0) {
                            return <div className="md:w-auto w-full rounded-lg p-2 flex rounded-t-lg flex-col gap-2">
                                <div className="mb-5 border-b border-primaryDefaultLight items-center flex flex-row gap-2">
                                    <FaArrowAltCircleRight className="text-primaryDefaultLight font-light text-lg" />
                                    <h1 className="text-primaryDefaultLight">{groupItems.group ? groupItems.group : 'Sem categoria'}</h1>
                                </div>
                                {
                                    groupItems?.items.map((configItem, index) => {
                                        if (
                                            (userModules.includes(configItem?.module) || !configItem?.module)
                                            &&
                                            ((configItem.permission.indexOf(userData[0].typeAccess_id) > -1)
                                                ||
                                                userData[0]?.permissions?.filter((userPermission) => {
                                                    if (configItem.permission.indexOf(userPermission) > -1) {
                                                        return userPermission
                                                    }
                                                }).length > 0)
                                        ) {
                                            return <div id={`${configItem.name.replace(' ', '-').toLocaleLowerCase()}-config`} key={`${configItem.name.replace(' ', '-').toLocaleLowerCase()}-config`} className="flex flex-col w-64 justify-between items-start">
                                                <div key={`${index}-${configItem.name}`} className="flex flex-row gap-3 items-start justify-start">
                                                    <div className="flex flex-col text-primaryDefaultLight text-xl w-5 translate-y-1"> {configItem.icon}</div>
                                                    <Tippy content={configItem.legend}
                                                        animation="shift-away"
                                                        arrow={true}
                                                        placement="right"
                                                        delay={700}
                                                    >
                                                        <h2 className="text-base text-primaryDefaultLight font-light">{configItem.name}</h2>
                                                    </Tippy>
                                                </div>
                                                {
                                                    configItem.subItems.map((subItem, subIndex) => {
                                                        if ((subItem.permission.indexOf(userData[0].typeAccess_id) > -1)
                                                            ||
                                                            userData[0]?.permissions?.filter((userPermission) => {
                                                                if (subItem.permission.indexOf(userPermission) > -1) {
                                                                    return userPermission
                                                                }
                                                            }).length > 0
                                                        ) {
                                                            return <div key={`${index}-${subIndex}`} className="flex flex-row items-start justify-start gap-1">
                                                                <div className="flex flex-col w-7"></div>
                                                                <h3 onClick={() => navegate(subItem.path)} className="cursor-pointer text-inputPlaceholderLight dark:text-titleGrayTextDark font-light hover:brightness-125 dark:hover:brightness-50 transition-all duration-75 text-sm">{subItem.name}</h3>
                                                            </div>
                                                        }
                                                    })
                                                }
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        }
                    })
                }
            </div>
        </div>
    )
}

