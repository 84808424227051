import { Container } from "../../../components/container/container";
import { Body } from '../../../components/container/Body';
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { useState } from "react";
import { NewTableDefault } from "../../../components/table/new.table.default";
import { Button } from '../../../components/buttons/button.default';
import { useAuth } from "../../../contexts/useAuth";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import moment from "moment";
import { FaCloudDownloadAlt, FaSearch } from "react-icons/fa";
import { CiBank } from "react-icons/ci";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { useThemeContext } from "../../../contexts/themeContext";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { FiSlash } from "react-icons/fi";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { useScreenSizeContext } from "../../../contexts/screenSizeContext";
import { SelectPeriod } from "../../../components/period/selectPeriod";
import { InputDinamicAutoComplete } from "../../../components/input/input.dinamic.autocomplete";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";
import { Input } from "../../../components/input/input";

export function FinancialCashFlow() {
    
    const navigate = useNavigate()
    const { defaultCallsFinancial } = useApiContext()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext() 
    const [ incomeOrExpense, setIncomeOrExpense ] = useState(getInitialFilter()?.incomeOrExpense ?? 'R')
    const [ filter, setFilter ] = useState({...getInitialFilter()})
    const [ filterConstructor, setFilterConstructor ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [ loadingFilter, setLoadingFilter ] = useState(false)

    function getInitialFilter(){
        const defaultFilter = {
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            finishDate: moment().endOf('month').format('YYYY-MM-DD'),
            incomeOrExpense: 'R'
        }
        try {
            const lastFilter = sessionStorage.getItem('lastFilter:cashFlow')
            if(lastFilter){
                const filter = JSON.parse(lastFilter)
                if(filter?.startDate && filter?.finishDate && filter?.incomeOrExpense){
                    return filter
                }
            }
            return defaultFilter
        } catch (error) {
            return defaultFilter
        }
    }

    function setAndSaveFilter(filterObj){
        setFilter(filterObj)
        sessionStorage.setItem('lastFilter:cashFlow', JSON.stringify(filterObj))
    }

    async function exportTable(){
        setLoading(true)
        const exportAPI = await defaultCallsFinancial.exportCashFlow(userData[0].token, filter)
        if (exportAPI?.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(exportAPI.data)
        }

        let fileObj = URL.createObjectURL(exportAPI?.data)
        let myElement = document.createElement('a')
        myElement.setAttribute('href', fileObj)
        myElement.setAttribute("download", `FluxoDeCaixa_${Number((new Date))}`)
        myElement.click()
        setLoading(false)
    }

    const paymentStatusList = [
        { value: 0, description: 'Pendente' },
        { value: 1, description: 'Pago' },
        { value: 2, description: 'Cancelado' },
    ]

    const paymentCondition = [
        { id: 1, description: 'À vista' },
        { id: 2, description: 'Parcelado' },
    ]

    const whatsDateList = [
        {value: 'entryDate', label: 'Data de entrada'},
        {value: 'dueDate', label: 'Data de vencimento'},
        {value: 'paymentDate', label: 'Data de pagamento'}
    ]

    return (
        <>
            <ScreenModal>
                <div className={`p-6 boxShadow duration-300 flex flex-col`}>
                    <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center'>
                        <FaSearch />
                        <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                    </div>
                    {
                        loadingFilter ?
                        <></>
                        :
                        <>
                            <form className="flex flex-col justify-center items-center">
                                <div className="flex flex-row gap-3 items-center justify-center">
                                    <a className="text-gray-400 text-sm w-24">Cliente </a>
                                    <LabelInput>
                                        <InputDinamicAutoComplete                            
                                            id={'usersAndClients'}
                                            onChange={(e) => {
                                                if (e.linked == "financialClientsAndSuppliers") {
                                                    return setFilterConstructor({ ...filterConstructor, financialClientsAndSuppliers_id: e?.linked_id })
                                                } 
                                                if (e.linked == "users") {
                                                    return setFilterConstructor({ ...filterConstructor, client_users_id: e?.linked_id })
                                                }
                                                if (e.linked == "agreements") {
                                                    return setFilterConstructor({ ...filterConstructor, agreements_id: e?.linked_id })
                                                }
                                            }}
                                            endpoint={'/api/v1/financial/utils/usersClientsAndSuppliers'}
                                            module={'financial'}
                                            optionList={['nameToShow']}
                                            optionToSearch={'nameToShow'}
                                            filters={{}}
                                            token={userData[0]?.token}
                                            mapTotalPages={['totalPages']}
                                            width={72}
                                        />
                                    </LabelInput>
                                </div>
                                <div className="flex flex-row gap-3 items-center justify-center">
                                    <a className="text-gray-400 text-sm w-24">Status do pag. </a>
                                    <LabelInput>
                                        <InputAutoComplete
                                            width={72}
                                            data={paymentStatusList}
                                            id={'paymentStatus'}
                                            value={filterConstructor?.paymentStatus ? paymentStatusList.find(ps => ps.id == filterConstructor?.paymentStatus)?.description : ''}
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, paymentStatus: e?.value })}
                                            selectedLabel={'description'}
                                            optionList={['value', 'description']}
                                        />
                                    </LabelInput>
                                </div>
                                <div className="flex flex-row gap-3 items-center justify-center">
                                    <a className="text-gray-400 text-sm w-24">Forma de pag. </a>
                                    <LabelInput>
                                        <InputAutoComplete
                                            width={72}
                                            data={paymentCondition}
                                            id={'paymentCondition'}
                                            value={filterConstructor?.paymentCondition ? paymentCondition.filter(ps => ps.id == filterConstructor?.paymentCondition)[0]?.description : ''}
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, paymentCondition: e?.id })}
                                            selectedLabel={'description'}
                                            optionList={['id', 'description']}
                                        />
                                    </LabelInput>
                                </div>
                                <div className="flex flex-row gap-3 items-center justify-center">
                                    <a className="text-gray-400 text-sm w-24">Nota fiscal</a>
                                    <LabelInput>
                                        <Input
                                            width={72}
                                            id={'nf'}
                                            value={filterConstructor?.nf ? filterConstructor.nf : ''}
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, nf: e?.target?.value })}
                                        />
                                    </LabelInput>
                                </div>
                            </form>
                            <div className='flex w-full flex-col items-end justify-start mt-6'>
                                <Button onClick={() => {
                                    setFilter({ ...filter, ...filterConstructor })
                                    setShowUniversalModal(false)
                                }} shadow={true}>Pesquisar</Button>
                            </div>
                        </>
                    }
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className="flex flex-row justify-between w-full items-center">
                        <Title text='Fluxo de caixa'></Title>
                        <Button onClick={() => navigate(`/financial/bills/create/single/${incomeOrExpense}`)}>
                            Nova {incomeOrExpense == "R" ? 'Receita' : 'Despesa'}
                        </Button>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <div className="flex flex-row w-full justify-center items-center">
                            <DefaultLoader />
                        </div>
                        :
                        <div className="w-full h-full overflow-auto">
                            <div className="w-full grid sm:grid-cols-3 justify-between mb-6">
                                <div className='w-full flex flex-row items-center sm:justify-start justify-center'>
                                    <Tippy content={'Contas a receber'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}
                                    >
                                        <button 
                                            className={`
                                                font-bold shadow-md text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center 
                                                ${incomeOrExpense === 'R' ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} 
                                                dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25
                                            `}
                                            onClick={() => {
                                                setIncomeOrExpense('R')
                                                setAndSaveFilter({ ...filter, incomeOrExpense: 'R' })
                                            }}
                                        >
                                            Receber
                                        </button>
                                    </Tippy>
                                    <Tippy content={'Contas a pagar'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}
                                    >
                                        <button 
                                            className={`
                                                font-bold shadow-md text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center 
                                                ${incomeOrExpense === 'D' ? 'bg-red-500 hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-red-300'} 
                                                dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25
                                            `} 
                                            onClick={() => {
                                                setIncomeOrExpense('D')
                                                setAndSaveFilter({...filter, incomeOrExpense: 'D'})
                                            }}
                                        >
                                            Pagar
                                        </button>
                                    </Tippy>
                                </div>
                                <div className='w-full flex items-center justify-center'>
                                    <SelectPeriod
                                        initialDate={filter?.startDate}
                                        endDate={filter?.finishDate}
                                        showOptionsDate={true}
                                        optionsDate={whatsDateList}
                                        referenceDateOptions={filter?.whatsDate}
                                        onChange={(e) => {
                                            setAndSaveFilter({...filter, ...e})
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row sm:justify-end justify-center items-center gap-3 w-full">
                                    <Tippy content={'Exportar em excel'}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top'
                                        delay={100}>
                                        <div>
                                            <FaCloudDownloadAlt onClick={() => exportTable()} className="text-xl cursor-pointer text-primaryDefaultLight"/>                                            
                                        </div>
                                    </Tippy>
                                    <Button onClick={() => setShowUniversalModal(true)}>Filtros</Button>
                                    {
                                        Object.keys(filterConstructor).length > 0 &&
                                        <Tippy
                                            key={`clearFilter`}
                                            content={<span>Limpar filtro</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='top'
                                            delay={100}>
                                            <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                                <FiSlash className='cursor-pointer' onClick={async () => {
                                                    setLoadingFilter(true)
                                                    setFilterConstructor({})                                                
                                                    setAndSaveFilter({
                                                        incomeOrExpense,
                                                        startDate: filter.startDate,
                                                        finishDate: filter.finishDate,
                                                        whatsDate: filter?.whatsDate
                                                    })
                                                    await new Promise(resolve => setTimeout(resolve, 500))
                                                    setLoadingFilter(false)
                                                }} />
                                            </div>
                                        </Tippy>
                                    }
                                </div>
                            </div>
                            <NewTableDefault 
                                key={'table-cashFlow'}
                                endpoint="/api/v1/financial/cashFlow"
                                token={userData[0]?.token}
                                module="financial"
                                columns={[
                                    { //ID
                                        description: 'ID',
                                        field: 'financialBills_id',
                                        type: 'number',
                                        withOrderBy: true
                                    },
                                    { //Cliente
                                        description: 'Descrição',
                                        type: 'string',
                                        field: 'client_name',
                                        withOrderBy: true,
                                        newFieldValue: function (line) {
                                            let name = ''
                                            let personType = incomeOrExpense == 'R' ? 'Cliente' : 'Fornecedor'

                                            if(line?.financialClientsAndSuppliers_id){
                                                name = line?.financialClientsAndSuppliers_name
                                            } 
                                            if(line?.client_users_id) {
                                                personType = 'Usuário'  
                                                name = line?.client_users_name
                                            }
                                            if(line?.agreements_id) {
                                                personType = 'Convênio'
                                                name = line?.agreements_name
                                            }

                                            const entryType = line?.entryType == 'sales' ? 'Venda'
                                                : line?.entryType == 'shopping' ? 'Compra'
                                                : line?.incomeOrExpense == 'R' ? 'Receita avulsa'
                                                : 'Despesa avulsa'

                                            return <Tippy content={
                                                <p className="text-xs">
                                                    {personType}: {name || '-'} <br/>
                                                    Tipo: {entryType} <br/>
                                                    Descrição: {line.description || '-'} <br/>
                                                    NF: {line.nf || '-'}
                                                </p>
                                            }>
                                                <div className="flex flex-col items-center justify-center">
                                                    <p className="text-sm">
                                                        {
                                                            !name ? '-'
                                                            : name.length > 30 ? `${personType}: ${name?.substring(0, 30)}...` 
                                                            : `${personType}: ${name}`
                                                        }
                                                    </p>
                                                    <p className="text-xt">Tipo: {entryType}</p>
                                                </div>
                                            </Tippy>
                                        },
                                    },
                                    { //Vlr. Original
                                        description: 'Vlr. Original',
                                        field: 'value',
                                        type: 'money',
                                        withOrderBy: true
                                    },
                                    { //Vlr. Recebido
                                        description: 'Vlr. ' + (incomeOrExpense == 'R' ? 'Recebido' : 'Pago'),
                                        type: 'money',
                                        field: 'finalValue',
                                        withOrderBy: true,
                                        newFieldValue: function (line) {
                                            if(line?.paymentStatus == 1){
                                                return line?.finalValue
                                            } 
                                            return 0
                                        }
                                    },
                                    { //Parcela
                                        description: 'Parcela',
                                        type: 'string',
                                        field: 'numberOfInstallments',
                                        withOrderBy: true,
                                        newFieldValue: function (line) {
                                            if(line?.paymentCondition == 1){
                                                return 'À vista'
                                            }
                                            return `${line?.installment} de ${line?.numberOfInstallments}`
                                        }
                                    },
                                    { //Data entrada
                                        description: 'Entrada',
                                        field: 'entryDate',
                                        type: 'date',
                                        withOrderBy: true,
                                    },
                                    { //Data vencimento
                                        description: 'Vencimento',
                                        field: 'dueDate',
                                        withOrderBy: true,
                                        newFieldValue: (e) => {
                                            const today = moment().startOf('day').utc(false)
                                            const dueDate = moment(e.dueDate).startOf('day').utc(false)
                                            if(dueDate.isBefore(today) && !e.paymentDate){
                                                return (
                                                    <Tippy content={
                                                        <p className="text-xs">
                                                            {incomeOrExpense == 'R' ? 'Recebimento em atraso' : 'Pagamento em atraso'}
                                                        </p>
                                                    }>
                                                        <p className="text-red-500">{dueDate.format('DD/MM/YYYY')}</p> 
                                                    </Tippy>
                                                )
                                            } 
                                            
                                            if(dueDate.isSame(today)  && !e.paymentDate){
                                                return (
                                                    <Tippy content={
                                                        <p className="text-xs">
                                                            Vence hoje
                                                        </p>
                                                    }>
                                                        <p className="text-yellow-500">{dueDate.format('DD/MM/YYYY')}</p> 
                                                    </Tippy>
                                                )
                                            }

                                            return dueDate.format('DD/MM/YYYY')
                                        }
                                    },
                                    { //Data do pagamento
                                        description: 'Pagamento',
                                        field: 'paymentDate',
                                        type: 'date',
                                        withOrderBy: true,
                                    },
                                    { //Status
                                        description: 'Status',
                                        type: 'string',
                                        field: 'paymentStatus',
                                        withOrderBy: true,
                                        newFieldValue: function (line) {
                                            let status = <a className="text-yellow-500 text-sm">Pendente</a>
                                            if (line?.paymentStatus == 1){
                                                if (line?.paymentDate > moment().utc(false)?.format('YYYY-MM-DD')){
                                                    status = <a className="text-sky-700 text-sm">Pago/Provisionado</a>
                                                } else {
                                                    status = <a className="text-green-700 text-sm">Pago</a>
                                                }
                                            }
                                            if (line?.paymentStatus == 2){
                                                status = <a className="text-red-700 text-sm">Cancelado</a>
                                            }
                                            return (
                                                <div className="flex items-center justify-center gap-1">
                                                    {status}
                                                    {
                                                        line?.financialConciliationsTransactions_id &&
                                                        <Tippy content={'Conciliado'}
                                                            arrow={true}
                                                            animation='shift-away'
                                                            placement='top'
                                                            delay={100}
                                                        >
                                                            <div>
                                                                <CiBank />
                                                            </div>
                                                        </Tippy>
                                                    }
                                                </div>
                                            )
                                        }
                                    },
                                    { //Origem
                                        description: 'Origem',
                                        type: 'string',
                                        field: 'payments_id',
                                        withOrderBy: true,
                                        newFieldValue: function (line) {
                                            return line?.payments_id 
                                                ? line.applicationModules_name
                                                : 'Manual'
                                        }
                                    }
                                ]}
                                mapApiData={['data', 'sends']}
                                filters={filter}
                                totals={[
                                    {
                                        color: 'red',
                                        description: `Vencido`,
                                        mapValue: ['data', 'overdue'],
                                        type: 'money',
                                        highlightOnClick: true,
                                        onClickAction: () => {
                                            setFilter({ ...filter, paymentStatus: 0, isOverdue: true })
                                        }
                                    },
                                    {
                                        color: 'yellow',
                                        description: `${incomeOrExpense === 'D' ? 'Não pagos' : 'Não recebido'}`,
                                        mapValue: ['data', 'notReceived'],
                                        type: 'money',
                                        highlightOnClick: true,
                                        onClickAction: () => {
                                            delete filter.isOverdue
                                            setFilter({ ...filter, paymentStatus: 0 })
                                        }
                                    },
                                    {
                                        color: 'blue',
                                        description: `${incomeOrExpense === 'D' ? 'A pagar' : 'A receber'}`,
                                        mapValue: ['data', 'toReceive'],
                                        type: 'money',
                                        highlightOnClick: true,
                                        onClickAction: () => {
                                            delete filter.paymentStatus
                                            delete filter.isOverdue
                                            setFilter({ ...filter })
                                        }
                                    },
                                    {
                                        color: 'green',
                                        description: `${incomeOrExpense === 'D' ? 'Pagos' : 'Recebido'}`,
                                        mapValue: ['data', 'received'],
                                        type: 'money',
                                        highlightOnClick: true,
                                        onClickAction: () => {
                                            delete filter.isOverdue
                                            setFilter({ ...filter, paymentStatus: 1 })
                                        }
                                    }
                                ]}
                                usePagination={true}
                                mapQuantityOfRegisters={['quantity']}
                                mapTotalPages={['totalPages']}
                                onClickInTableLine={(row) => navigate(`/financial/cashFlowEdit/${row?.id}`)}
                            />
                        </div>
                    }
                </Body>
            </Container>
        </>
    )
}
