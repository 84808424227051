import { Container } from "../../../components/container/container";
import { Body } from '../../../components/container/Body'
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { useEffect, useState } from "react";
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { useAuth } from "../../../contexts/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { FaInfo, FaPlus, FaMinus } from "react-icons/fa";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Footer } from "../../../components/footer/Footer";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { useThemeContext } from "../../../contexts/themeContext";
import { validateFields } from '../../../utils/form.validator'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import { InputDinamicAutoComplete } from "../../../components/input/input.dinamic.autocomplete";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function FinancialBillsCreate() {
    
    const navigate = useNavigate()
    const { api_financial, defaultCallsFinancial } = useApiContext()
    const { incomeOrExpense, entryType } = useParams()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext() 
    const [ loading, setLoading ] = useState(true)
    const [ paymentMethodList, setPaymentMethodList ] = useState([])
    const [ costCenter, setCostCenter ] = useState([])
    const [ moduleLinks, setModuleLinks ] = useState([])
    const [ categories, setCategories ] = useState([])
    const [ banks, setBanks ] = useState([])
    const [ productsAndServices, setProductsAndServices ] = useState([])
    const [ bill, setBill ] = useState({ 
        incomeOrExpense: entryType == 'single' ? incomeOrExpense
            : entryType == 'shopping' ? 'D'
            : entryType == 'sales' ? 'R'
            : null
    })
    const [ apportionmentsBills, setApportionmentsBills ] = useState([{
        financialCategories_id: undefined,
        financialCostCenter_id: undefined,
        percentage: undefined,
        isLinked: false,
        linked: undefined,
        linked_id: undefined,
    }])
    const [ compositionsBills, setCompositionsBills ] = useState([{
        financialProducts_id: undefined,
        financialServices_id: undefined,
        isForUseAndConsumption: undefined,
        unitValue: undefined,
        quantity: undefined,
        isPatrimony: undefined,
        patrimonyLinked: undefined,
        patrimonyLinked_id: undefined,
        patrimonyDepreciationPercentage: undefined,
        patrimonyDepreciationUnitMeasure: undefined
    }])
    const [ controlSubmit, setControlSubmit ] = useState(false)

    useEffect(() => {
        if(!['single', 'sales', 'shopping'].includes(entryType)){
            return navigate('/404')
        }
        if(entryType == 'single' && !['R', 'D'].includes(incomeOrExpense)){
            return navigate('/404')
        }

        getData()
    }, [])

    async function getData(){
        try {
            setLoading(true)
            const paymentMethodAPI = await defaultCallsFinancial.getFinancialPaymentMethod(userData[0]?.token)
            if (paymentMethodAPI.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(paymentMethodAPI.data)
            }
            setPaymentMethodList(paymentMethodAPI?.data)

            const costCenterData = await defaultCallsFinancial.getCostCenter(userData[0]?.token, true)
            if (costCenterData.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(costCenterData.data)
            }
            setCostCenter(costCenterData?.data?.map(cost => {
                return {
                    ...cost,
                    valueInput: `${cost?.code ? cost?.code : cost?.id} - ${cost?.name}`
                }
            }))

            const getAccountsAPI = await defaultCallsFinancial.getAccounts(userData[0]?.token)
            if (getAccountsAPI?.error){
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(getAccountsAPI.data)
            }
            setBanks(getAccountsAPI?.data?.map(bank => {
                return {
                    ...bank,
                    valueInput: bank?.accountNumber ? `${bank?.accountNumber} - ${bank?.name}` : bank?.name 
                }
            }))

            const categoriesApi = await defaultCallsFinancial.getFinancialCategories(userData[0]?.token)
            if (categoriesApi?.error){
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(categoriesApi.data)
            }
            setCategories(categoriesApi.data?.filter(cat => cat.incomeOrExpense == bill.incomeOrExpense))

            const linksApi = await defaultCallsFinancial.getFinancialListLinks(userData[0]?.token)
            if (linksApi?.error){
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(linksApi.data)
            }
            setModuleLinks(linksApi.data)

            if(['sales', 'shopping'].includes(entryType)){
                const getProductsList = await defaultCallsFinancial.getProducts(userData[0].token, true)
                if (getProductsList.error) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText(getProductsList.data)               
                }
                const getServicesList = await defaultCallsFinancial.getFinancialServices(userData[0].token) 
                if (getServicesList.error) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText(getServicesList.data)            
                }
                setProductsAndServices(
                    getProductsList.data.map(product => {
                        return {
                            ...product,
                            compositionType: 'product',
                            type: 'Produto'
                        }
                    })?.concat(getServicesList.data.map(service => {
                        return {
                            ...service,
                            name: service.name + (service.description ? ` (${service.description})` : ''),
                            compositionType: 'service',
                            type: 'Serviço'
                        }
                    })
                ))
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }finally{
            setLoading(false)
        }

    }

    async function handleSubmit(){
        try {

            const requiredFields = [
                { name: 'entryDate', value: bill?.entryDate, required: true, type: 'string' },
                { name: 'clients_id', value: bill?.client_id, required: true, type: 'number' },
                { name: 'clients_id', value: bill?.clientOrigin, required: true, type: 'string' },
                { name: 'dueDate', value: bill?.dueDate, required: true, type: 'string' },
                { name: 'paymentCondition', value: bill?.paymentCondition, required: true, type: 'string' },
                { name: 'paymentStatus', value: [0, 1, 2].includes(bill?.paymentStatus), required: bill?.paymentCondition === 1 ? true : false, type: 'number' },
                { name: 'numberOfInstallments', value: Number(bill?.numberOfInstallments), required: bill?.paymentCondition === 2 ? true : false, type: 'string' },
            ]
            if(entryType == 'single'){
                requiredFields.push({ name: 'totalValue', value: bill?.totalValue, required: true, type: 'number' })
            }
            if(bill?.paymentStatus == 1){
                requiredFields.push(
                    { name: 'paymentDate', value: bill?.paymentDate, required: true, type: 'string' },
                    { name: 'bankAccount', value: bill?.financialAccounts_id, required: true, type: 'number' },
                    { name: 'paymentMethod', value: bill?.financialPaymentMethod_id, required: true, type: 'number' }
                )
            }
            apportionmentsBills?.map((app, index) => {
                requiredFields.push(
                    {name: `apportionmentFinancialCategories_id-${index}`, value: app?.financialCategories_id, required: true, type: 'string'},
                    {name: `apportionmentPercentage-${index}`, value: app?.percentage, required: true, type: 'string'}
                )
                if(app.isLinked){
                    requiredFields.push({ name: `apportionmentLinked-${index}`, value: app.linked_id, required: true, type: 'number' },)
                }
            })
            if(entryType != 'single'){
                compositionsBills.map((comp, index) => {
                    requiredFields.push(
                        {name: `productsAndServices-${index}`, value: comp?.financialProducts_id || comp?.financialServices_id, required: true, type: 'number'},
                        {name: `quantity-${index}`, value: comp?.quantity, required: true, type: 'number'},
                        {name: `unitValue-${index}`, value: comp?.unitValue, required: true, type: 'number'},
                    )
                    if(comp.isPatrimony && comp.financialProducts_id){
                        requiredFields.push(
                            { name: `patrimonyLinked-${index}`, value: comp.patrimonyLinked_id, required: true, type: 'number' },
                            { name: `patrimonyDepreciationUnitMeasure-${index}`, value: comp.patrimonyDepreciationUnitMeasure, required: true, type: 'string' },
                            { name: `patrimonyDepreciationPercentage-${index}`, value: comp.patrimonyDepreciationPercentage, required: true, type: 'number' },
                        )
                    }
                })
            }
            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                setControlSubmit(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }
            
            await api_financial.post(`/api/v1/financial/bills/${entryType}`, {
                entryDate: bill?.entryDate,
                dueDate: bill?.dueDate,
                description: bill?.description ? bill?.description : undefined,
                clientOrSupplierOrigin: bill?.clientOrigin,
                clientOrSupplier_id: bill?.client_id,
                incomeOrExpense: bill?.incomeOrExpense,
                totalValue: entryType == 'single' ? bill?.totalValue : undefined,
                externalCode: bill?.externalCode ? bill?.externalCode : undefined,
                paymentCondition: bill?.paymentCondition,
                numberOfInstallments: bill?.numberOfInstallments ? bill?.numberOfInstallments : 1,
                paymentStatus: bill?.paymentCondition != 2 ? bill?.paymentStatus : undefined,
                nf: bill?.nf ? bill?.nf : undefined,
                paymentDate: bill?.paymentStatus == 1 && bill?.paymentCondition != 2 
                    ? bill?.paymentDate 
                    : undefined,
                discount: bill?.paymentStatus == 1 && bill?.paymentCondition != 2 && bill?.discount 
                    ? bill?.discount 
                    : undefined,
                fees: bill?.paymentStatus == 1 && bill?.paymentCondition != 2 && bill?.fees 
                    ? bill?.fees 
                    : undefined,
                financialAccounts_id: bill?.paymentStatus == 1 && bill?.paymentCondition != 2 
                    ? bill?.financialAccounts_id 
                    : undefined,
                financialPaymentMethod_id: bill?.paymentStatus == 1 && bill?.paymentCondition != 2 
                    ? bill?.financialPaymentMethod_id 
                    : undefined,
                apportionments: apportionmentsBills.map(app => {
                    return {
                        financialCategories_id: app?.financialCategories_id,
                        financialCostCenter_id: app?.financialCostCenter_id 
                            ? app?.financialCostCenter_id 
                            : undefined,
                        percentage: app?.percentage,
                        linked: app?.linked 
                            ? app?.linked 
                            : undefined,
                        linked_id: app?.linked_id 
                            ? app?.linked_id 
                            : undefined
                    }
                }),
                compositions: entryType == 'single' ? [] : compositionsBills.map(comp => {
                    return {
                        financialProducts_id: comp?.financialProducts_id,
                        financialServices_id: comp?.financialServices_id,
                        isForUseAndConsumption: comp?.financialProducts_id ? comp?.isForUseAndConsumption : undefined,
                        unitValue: comp?.unitValue,
                        quantity: comp?.quantity,
                        isPatrimony: comp?.financialProducts_id 
                            ? comp?.isPatrimony
                            : false,
                        patrimonyLinked: comp?.financialProducts_id && comp?.isPatrimony
                            ? comp?.patrimonyLinked 
                            : undefined,
                        patrimonyLinked_id: comp?.financialProducts_id && comp?.isPatrimony
                            ? comp?.patrimonyLinked_id
                            : undefined,
                        patrimonyDepreciationPercentage: comp?.financialProducts_id && comp?.isPatrimony 
                            ? comp?.patrimonyDepreciationPercentage 
                            : undefined,
                        patrimonyDepreciationUnitMeasure: comp?.financialProducts_id && comp?.isPatrimony
                            ? comp?.patrimonyDepreciationUnitMeasure 
                            : undefined
                    }
                })
            }, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })
            
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Compra cadastrada com sucesso!")
            return navigate(-1)

        } catch (error) {
            setLoading(false)
            setControlSubmit(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const incomeOrExpenseOptions = [
        { id: 'R', description: 'Receita' },
        { id: 'D', description: 'Despesa' },
    ]

    const paymentCondition = [
        { id: 1, description: 'À vista' },
        { id: 2, description: 'Parcelado' }
    ]

    const optionsBooleansList = [
        {
            type: true,
            description: "Sim"
        },
        {
            type: false,
            description: "Não"
        }
    ]

    const paymentStatusList = [
        {
            description: 'Pendente',
            value: 0
        },
        {
            description: 'Pago',
            value: 1
        },
        {
            description: 'Cancelado',
            value: 2
        }
    ]

    const depreciationOptionList = [
        { id: 'day', name: 'Diária' }, 
        { id: 'month', name: 'Mensal' }, 
        { id: 'year', name: 'Anual' }
    ]

    return (
        <>
            <Container>
                <TitlePage>
                    <div className="flex flex-row justify-between w-full items-center">
                        <Title text={
                            entryType == 'single' ? bill.incomeOrExpense == 'R' ? 'Nova Receita Avulsa' : 'Nova Despesa Avulsa'  
                            : entryType == 'shopping' ? 'Nova Compra'
                            : entryType == 'sales' ? 'Nova Venda'
                            : entryType == 'Novo lançamento'
                        }/>
                    </div>
                </TitlePage>
                <Body hasFooter={true}>
                {
                        loading ?
                        <div className='flex flex-col items-center justify-center w-full h-full'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <form className='flex flex-col flex-wrap gap-2 items-start justify-start w-full pb-6'>
                                {/* Cadastro */}
                                <div className='flex flex-col gap-2 items-start justify-start w-full mt-6 border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>
                                    <h3 className='text-primaryDefaultLight text-xl'>Cadastro</h3>
                                    <div className='flex flex-wrap gap-2 items-start justify-start w-full mt-4 mb-2'>
                                        {
                                            entryType == 'single' && !incomeOrExpense &&
                                            <LabelInput text={'Tipo de lançamento avulso: *'}>
                                                <InputAutoComplete
                                                    data={incomeOrExpenseOptions}
                                                    id={'incomeOrExpense'}
                                                    preSelectedValue={incomeOrExpenseOptions.find(opt => opt.id == bill.incomeOrExpense)?.description}
                                                    onChange={(e) => {
                                                        setBill(prev => ({ ...prev, incomeOrExpense: e.id }))
                                                    }}
                                                    selectedLabel={'description'}
                                                    optionList={['description']}
                                                />
                                            </LabelInput>
                                        }
                                        <LabelInput text={bill.incomeOrExpense == 'R' ? 'Cliente: *' : 'Fornecedor: *'}>
                                            <InputDinamicAutoComplete                               
                                                id={'clients_id'}
                                                onChange={(e) => {
                                                    setBill(prev => ({ ...prev, clientOrigin: e.linked, client_id: e.linked_id }))
                                                }}
                                                endpoint={'/api/v1/financial/utils/usersClientsAndSuppliers'}
                                                module={'financial'}
                                                optionList={['nameToShow']}
                                                optionToSearch={'nameToShow'}
                                                filters={{
                                                    isClient: bill.incomeOrExpense == 'R' ? true : undefined,
                                                    isSupplier: bill.incomeOrExpense == 'D' ? true : undefined
                                                }}
                                                token={userData[0]?.token}
                                                mapTotalPages={['totalPages']}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Data de referência: *'}>
                                            <Input id='entryDate' type={'date'} value={bill?.entryDate} onChange={(e) => setBill(prev => ({ ...prev, entryDate: e.target.value }))}/>
                                        </LabelInput>
                                        <LabelInput text={'Descrição:'}>
                                            <Input id='description' type={'text'} value={bill?.description} onChange={(e) => setBill(prev => ({ ...prev, description: e.target.value }))}/>
                                        </LabelInput>
                                        <LabelInput text={'Código Externo:'}>
                                            <Input id='externalCode' type={'text'} value={bill?.externalCode} onChange={(e) => setBill(prev => ({ ...prev, externalCode: e.target.value }))}/>
                                        </LabelInput>
                                        <LabelInput text={'Nota fiscal:'}>
                                            <Input 
                                                id='nf' 
                                                type={'text'} 
                                                value={bill?.nf} 
                                                onChange={(e) => setBill(prev => ({ ...prev, nf: e.target.value }))}
                                                charLimit={100}
                                            />
                                        </LabelInput>
                                    </div>
                                </div>
                                <hr className='my-6 border w-full' /> 
                                {/* Rateio */}
                                <div className='flex flex-col gap-2 items-start justify-start w-full border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>
                                    <h3 className='text-primaryDefaultLight text-xl'>Rateios</h3>
                                    <div className='flex flex-row justify-start items-end mt-4 border-b border-gray-300 w-full'>
                                        <div className='flex flex-col items-start gap-2'>
                                            <div className='flex flex-row items-center gap-2'>
                                                <p className='text-titleGrayTextDark'>Restante do rateio</p>
                                                <Tippy content={'É o restante que não foi distribuído a partir do valor total desta compra. Para completar o rateio, este restante precisa estar zerado.'}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div className='cursor-help text-xs text-primaryDefaultLight hover:brightness-150'>
                                                        <FaInfo />
                                                    </div>
                                                </Tippy>
                                            </div>
                                            <div className='flex flex-row justify-start items-end gap-2'>
                                                <p className='text-xl text-gray-500 font-semibold tracking-wider'>
                                                    {100 - apportionmentsBills.reduce((accumulator, currentValue) => Number(accumulator) + (currentValue?.percentage ? Number(currentValue?.percentage) : 0), 0)}
                                                    %
                                                </p>
                                                {
                                                    apportionmentsBills.reduce((accumulator, currentValue) => Number(accumulator) + (currentValue?.percentage ? Number(currentValue?.percentage) : 0), 0) !== 100
                                                    &&
                                                    <p className='text-red-400 text-xs'>Para prosseguir com a compra o rateio precisa estar zerado.</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        apportionmentsBills.map((app, index) => {
                                            return (
                                                <>
                                                    <div className={`flex flex-col gap-2 mt-3s`}>
                                                        <div className={`flex flex-wrap gap-2  ${index == 0 ? '' : 'border-t pt-4'} `}>
                                                            <LabelInput text={'Categoria: *'}>
                                                                <InputAutoComplete
                                                                    data={categories}
                                                                    id={`apportionmentFinancialCategories_id-${index}`}
                                                                    preSelectedValue={categories.find(categorie => categorie.id === app.financialCategories_id)?.description}
                                                                    value={categories.find(categorie => categorie.id === app.financialCategories_id)?.description}
                                                                    onChange={(e) => {
                                                                        app.financialCategories_id = e.id
                                                                        setApportionmentsBills([ ...apportionmentsBills ])
                                                                    }}
                                                                    selectedLabel={'description'}
                                                                    optionList={['id', 'description']}
                                                                />
                                                            </LabelInput>
                                                            <LabelInput text={'Centro de custo:'}>
                                                                <InputAutoComplete
                                                                    data={costCenter}
                                                                    id={`apportionmentFinancialCostCenter_id-${index}`}
                                                                    preSelectedValue={costCenter.find(cost => cost.id === app?.financialCostCenter_id)?.valueInput}
                                                                    value={costCenter.find(cost => cost.id === app?.financialCostCenter_id)?.valueInput}
                                                                    onChange={(e) => {
                                                                        app.financialCostCenter_id = e.id
                                                                        setApportionmentsBills([ ...apportionmentsBills ])
                                                                    }}
                                                                    selectedLabel={'valueInput'}
                                                                    optionList={['valueInput']}
                                                                />
                                                            </LabelInput>
                                                            <LabelInput text={'Vinculado:'}>
                                                                <InputAutoComplete
                                                                    data={optionsBooleansList}
                                                                    id={`isLinked-${index}`}
                                                                    preSelectedValue={optionsBooleansList?.find(item => item?.type == app?.isLinked)?.description}
                                                                    value={optionsBooleansList?.find(item => item?.type == app?.isLinked)?.description}
                                                                    onChange={(e) => {
                                                                        app.isLinked = e.type
                                                                        setApportionmentsBills([ ...apportionmentsBills ])
                                                                    }}
                                                                    selectedLabel={'description'}
                                                                    optionList={['description']}
                                                                />
                                                            </LabelInput>
                                                            {
                                                                app?.isLinked ?
                                                                <LabelInput text={'Vinculado com: *'}>
                                                                    <InputAutoComplete
                                                                        data={moduleLinks}
                                                                        id={`apportionmentLinked-${index}`}
                                                                        preSelectedValue={moduleLinks?.find(item => item?.linked_id == app?.linked_id && item?.linked == app?.linked)?.linked_name}
                                                                        value={moduleLinks?.find(item => item?.linked_id == app?.linked_id && item?.linked == app?.linked)?.linked_name}
                                                                        onChange={(e) => {
                                                                            app.linked_id = e.linked_id
                                                                            app.linked = e.linked
                                                                            setApportionmentsBills([ ...apportionmentsBills ])
                                                                        }}
                                                                        selectedLabel={'linked_name'}
                                                                        optionList={['linked_id', 'linked_name']}
                                                                    />
                                                                </LabelInput>
                                                                :
                                                                <></>
                                                            }
                                                            <LabelInput text={'Porcentagem (%): *'}>
                                                                <Input
                                                                    id={`apportionmentPercentage-${index}`}
                                                                    type={'number'}
                                                                    value={app.percentage}
                                                                    onChange={(e) => {
                                                                        app.percentage = parseFloat(e.target.value)
                                                                        setApportionmentsBills([ ...apportionmentsBills ])
                                                                    }}
                                                                />
                                                            </LabelInput>
                                                        </div>
                                                        <div className='flex items-center my-2 gap-2'>
                                                            <span onClick={() => {
                                                                setApportionmentsBills((prev) => ([...prev, {
                                                                    financialCategories_id: undefined,
                                                                    financialCostCenter_id: undefined,
                                                                    percentage: undefined,
                                                                    isLinked: false,
                                                                    linked: undefined,
                                                                    linked_id: undefined,
                                                                }]))
                                                            }}>
                                                                <FaPlus size={14} className='text-primaryDefaultLight hover:cursor-pointer' />
                                                            </span>
                                                            <span
                                                                onClick={() => {
                                                                    apportionmentsBills.splice(index, 1)
                                                                    setApportionmentsBills([ ...apportionmentsBills ])
                                                                }}>
                                                                <FaMinus size={14} className='text-primaryDefaultLight hover:cursor-pointer' />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                                <hr className='my-6 border w-full' />
                                {/* Composição (venda e compra somente) */}
                                {
                                    ['sales', 'shopping'].includes(entryType) && 
                                    <>
                                        <div className='flex flex-col gap-2 items-start justify-start w-full border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>
                                            <h3 className='text-primaryDefaultLight text-xl'>Composição</h3>
                                            <div className='flex flex-col justify-between items-start gap-2 mt-4 w-full'>
                                                {
                                                    compositionsBills.map((composition, index) => {
                                                        return (
                                                            <div className='flex flex-col gap-2'>
                                                                <div className={`flex flex-wrap gap-2`}>
                                                                    <LabelInput text={'Produto/Serviço: *'}>
                                                                        <InputAutoComplete
                                                                            data={productsAndServices}
                                                                            id={`productsAndServices-${index}`}
                                                                            preSelectedValue={
                                                                                productsAndServices.find(ps => {
                                                                                    return (composition.financialProducts_id === ps.id && ps.compositionType == 'product') 
                                                                                        || (composition.financialServices_id === ps.id && ps.compositionType == 'service')
                                                                                })?.name
                                                                            }
                                                                            value={
                                                                                productsAndServices.find(ps => {
                                                                                    return (composition.financialProducts_id === ps.id && ps.compositionType == 'product') 
                                                                                        || (composition.financialServices_id === ps.id && ps.compositionType == 'service')
                                                                                })?.name
                                                                            }
                                                                            onChange={(e) => {
                                                                                if (e.compositionType == 'product') {
                                                                                    composition.financialProducts_id = e.id
                                                                                    composition.financialServices_id = null
                                                                                } else if (e.compositionType == 'service') {
                                                                                    composition.financialProducts_id = null
                                                                                    composition.financialServices_id = e.id
                                                                                } else {
                                                                                    composition.financialProducts_id = null
                                                                                    composition.financialServices_id = null
                                                                                }
                                                                                setCompositionsBills([ ...compositionsBills ])
                                                                            }}
                                                                            selectedLabel={'name'}
                                                                            optionList={['type', 'name']}
                                                                        />
                                                                    </LabelInput>
                                                                    {
                                                                        composition.financialProducts_id && entryType == 'shopping' &&
                                                                        <>
                                                                            <LabelInput text={'Uso/Consumo: *'}>
                                                                                <InputAutoComplete
                                                                                    data={optionsBooleansList}
                                                                                    id={`isForUseAndConsumption-${index}`}
                                                                                    preSelectedValue={optionsBooleansList?.find(item => item?.type == composition?.isForUseAndConsumption)?.description}
                                                                                    value={optionsBooleansList?.find(item => item?.type == composition?.isForUseAndConsumption)?.description}
                                                                                    onChange={(e) => {
                                                                                        composition.isForUseAndConsumption = e.type
                                                                                        setCompositionsBills([ ...compositionsBills ])
                                                                                    }}
                                                                                    selectedLabel={'description'}
                                                                                    optionList={['description']}
                                                                                />
                                                                            </LabelInput>
                                                                            <LabelInput text={'É um patrimônio:'}>
                                                                                <InputAutoComplete
                                                                                    data={optionsBooleansList}
                                                                                    id={`isPatrimony-${index}`}
                                                                                    preSelectedValue={optionsBooleansList?.find(item => item?.type == composition?.isPatrimony)?.description}
                                                                                    value={optionsBooleansList?.find(item => item?.type == composition?.isPatrimony)?.description}
                                                                                    onChange={(e) => {
                                                                                        if(composition?.financialServices_id){
                                                                                            composition.isPatrimony = false
                                                                                        }else{
                                                                                            composition.isPatrimony = e.type
                                                                                        }
                                                                                        setCompositionsBills([ ...compositionsBills ])
                                                                                    }}
                                                                                    selectedLabel={'description'}
                                                                                    optionList={['description']}
                                                                                />
                                                                            </LabelInput>
                                                                            {
                                                                                composition?.isPatrimony &&
                                                                                <>
                                                                                    <LabelInput text={'Vinculado com: *'}>
                                                                                        <InputAutoComplete
                                                                                            data={moduleLinks}
                                                                                            id={`patrimonyLinked-${index}`}
                                                                                            preSelectedValue={moduleLinks?.find(item => item?.linked_id == composition?.patrimonyLinked_id && item?.linked == composition?.patrimonyLinked)?.linked_name}
                                                                                            value={moduleLinks?.find(item => item?.linked_id == composition?.patrimonyLinked_id && item?.linked == composition?.patrimonyLinked)?.linked_name}
                                                                                            onChange={(e) => {
                                                                                                composition.patrimonyLinked = e.linked
                                                                                                composition.patrimonyLinked_id = e.linked_id
                                                                                                setCompositionsBills([ ...compositionsBills ])
                                                                                            }}
                                                                                            selectedLabel={'linked_name'}
                                                                                            optionList={['linked_id', 'linked_name']}
                                                                                        />
                                                                                    </LabelInput>
                                                                                    <LabelInput text={'Depreciação de unidade: *'}>
                                                                                        <InputAutoComplete
                                                                                            data={depreciationOptionList}
                                                                                            id={`patrimonyDepreciationUnitMeasure-${index}`}
                                                                                            preSelectedValue={depreciationOptionList.find(d => d.id == composition.patrimonyDepreciationUnitMeasure)?.name}
                                                                                            value={depreciationOptionList.find(d => d.id == composition.patrimonyDepreciationUnitMeasure)?.name}
                                                                                            onChange={(e) => {
                                                                                                composition.patrimonyDepreciationUnitMeasure = e.id
                                                                                                setCompositionsBills([ ...compositionsBills ])
                                                                                            }}
                                                                                            selectedLabel={'name'}
                                                                                            optionList={['name']}
                                                                                        />
                                                                                    </LabelInput>
                                                                                    <LabelInput text={'% de depreciação: *'}>
                                                                                        <Input
                                                                                            id={`patrimonyDepreciationPercentage-${index}`}
                                                                                            value={composition?.patrimonyDepreciationPercentage}
                                                                                            onChange={(e) => {
                                                                                                composition.patrimonyDepreciationPercentage = e.target.value
                                                                                                setCompositionsBills([ ...compositionsBills ])
                                                                                            }}
                                                                                        />
                                                                                    </LabelInput>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                    
                                                                    <LabelInput text={'Quantidade: *'}>
                                                                        <Input
                                                                            id={`quantity-${index}`}
                                                                            type={'number'}
                                                                            value={composition?.quantity}
                                                                            onChange={(e) => {
                                                                                composition.quantity = e.target.value
                                                                                setCompositionsBills([ ...compositionsBills ])
                                                                            }}
                                                                        />
                                                                    </LabelInput>
                                                                    <LabelInput text={'Valor unitário: *'}>
                                                                        <CurrencyInput
                                                                            id={`unitValue-${index}`}
                                                                            className='pl-2 border border-gray-400 border-opacity-75 
                                                                            outline-none
                                                                            sm:w-96 w-full
                                                                            text-sm sm:text-sm
                                                                            transition-all duration-200
                                                                            focus:shadow-borderShadow
                                                                            h-10 sm:h-8
                                                                            dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                                                            placeHolder={composition.unitValue ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(composition.unitValue) : 'R$ 0,00'}
                                                                            onChange={(e) => {
                                                                                composition.unitValue = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                                setCompositionsBills([ ...compositionsBills ])
                                                                            }}
                                                                        />
                                                                    </LabelInput>
                                                                </div>
                                                                <div className='flex items-center my-2 gap-2'>
                                                                    <span onClick={() => {
                                                                        compositionsBills.push({
                                                                            financialProducts_id: undefined,
                                                                            financialServices_id: undefined,
                                                                            isForUseAndConsumption: undefined,
                                                                            unitValue: undefined,
                                                                            quantity: undefined,
                                                                            isPatrimony: undefined,
                                                                            patrimonyLinked: undefined,
                                                                            patrimonyLinked_id: undefined,
                                                                            patrimonyDepreciationPercentage: undefined,
                                                                            patrimonyDepreciationUnitMeasure: undefined
                                                                        })
                                                                        setCompositionsBills([ ...compositionsBills ])
                                                                    }}><FaPlus size={14} className='text-primaryDefaultLight hover:cursor-pointer' /></span>
                                                                    <span
                                                                        onClick={() => {
                                                                            compositionsBills.splice(index, 1)
                                                                            setCompositionsBills([ ...compositionsBills ])
                                                                        }}>
                                                                        <FaMinus size={14} className='text-primaryDefaultLight hover:cursor-pointer' />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <hr className='my-6 border w-full' />
                                    </>
                                }
                                {/* Pagamento e Condições */}
                                <div className='flex flex-col gap-2 items-start justify-start w-full border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>
                                    <div className='flex flex-row justify-between items-center w-full'>
                                        <h3 className='text-primaryDefaultLight text-xl'>Pagamento e Condições</h3>
                                    </div>
                                    <div className='flex flex-wrap justify-start items-start gap-2 mt-4 mb-2 w-full'>
                                        {
                                            entryType === 'single' &&
                                            <LabelInput text={'Valor: *'}>
                                                <CurrencyInput
                                                    id={`totalValue`}
                                                    className='pl-2 border border-gray-400 border-opacity-75 outline-none sm:w-96 w-full text-sm sm:text-sm transition-all duration-200 focus:shadow-borderShadow h-10 sm:h-8 dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                                    placeHolder={bill.totalValue ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(bill.totalValue) : 'R$ 0,00'}
                                                    onChange={(e) => {
                                                        setBill((prev) => ({ 
                                                            ...prev, 
                                                            totalValue: parseFloat(e.target.value?.replace('R$', '')?.replace('.', '')?.replace(',', '.'))
                                                        }))
                                                    }}
                                                />
                                            </LabelInput>
                                        }
                                        <LabelInput text={'Forma de pagamento: *'}>
                                            <InputAutoComplete
                                                data={paymentCondition}
                                                id={'paymentCondition'}
                                                preSelectedValue={paymentCondition.find(ps => ps.id == bill?.paymentCondition)?.description}
                                                value={paymentCondition.find(ps => ps.id == bill?.paymentCondition)?.description}
                                                onChange={(e) => setBill(prev => ({ ...prev, paymentCondition: e.id, paymentStatus: null }))}
                                                selectedLabel={'description'}
                                                optionList={['id', 'description']}
                                            />
                                        </LabelInput>
                                        {
                                            bill?.paymentCondition == 1 ?
                                            <LabelInput text={'Status do pagamento: *'}>
                                                <InputAutoComplete
                                                    data={paymentStatusList}
                                                    id={'paymentStatus'}
                                                    preSelectedValue={[0, 1, 2].includes(bill?.paymentStatus) ? paymentStatusList.find(ps => ps.id == bill?.paymentStatus)?.description : ''}
                                                    value={bill?.paymentStatus ? paymentStatusList.find(ps => ps.id == bill?.paymentStatus)?.description : ''}
                                                    onChange={(e) => setBill(prev => ({ ...prev, paymentStatus: e.value }))}
                                                    selectedLabel={'description'}
                                                    optionList={['value', 'description']}
                                                />
                                            </LabelInput>
                                            :
                                            <></>                                        
                                        }
                                        {
                                            bill?.paymentCondition == 2 &&
                                            <LabelInput text={'Número de parcelas: *'}>
                                                <Input 
                                                    id='numberOfInstallments' 
                                                    type={'number'} 
                                                    width={50} 
                                                    value={bill?.numberOfInstallments} 
                                                    onChange={(e) => setBill(prev => ({ ...prev, numberOfInstallments: e.target.value }))}
                                                />
                                            </LabelInput>
                                        }
                                        <LabelInput text={bill?.paymentCondition == 2 ? 'Primeiro vencimento: *' : 'Vencimento: *'}>
                                            <Input 
                                                id='dueDate' 
                                                type={'date'} 
                                                value={bill?.dueDate} 
                                                onChange={(e) => setBill(prev => ({ ...prev, dueDate: e.target.value }))}
                                            />
                                        </LabelInput>
                                        {
                                            bill?.paymentStatus == 1 ?
                                            <>
                                                <LabelInput text={'Data do pagamento: *'}>
                                                    <Input
                                                        id={'paymentDate'}
                                                        type={'date'}
                                                        value={bill?.paymentDate ?? ''}
                                                        onChange={(e) => setBill(prev => ({ ...prev, paymentDate: e.target?.value }))}
                                                    />
                                                </LabelInput>
                                                <LabelInput text={'Banco: *'}>
                                                    <InputAutoComplete
                                                        data={banks}
                                                        id={'bankAccount'}
                                                        preSelectedValue={banks.find(bank => bank.id == bill?.financialAccounts_id)?.description}
                                                        onChange={(e) => setBill(prev => ({ ...prev, financialAccounts_id: e.id }))}
                                                        selectedLabel={'valueInput'}
                                                        optionList={['valueInput']}
                                                    />
                                                </LabelInput>
                                                <LabelInput text={'Forma de pagamento: *'}>
                                                    <InputAutoComplete
                                                        data={paymentMethodList}
                                                        id={'paymentMethod'}
                                                        preSelectedValue={paymentMethodList.find(bank => bank.id == bill?.financialPaymentMethod_id)?.description}
                                                        onChange={(e) => setBill(prev => ({ ...prev, financialPaymentMethod_id: e?.id }))}
                                                        selectedLabel={'description'}
                                                        height={96}
                                                        optionList={['description']}
                                                    />
                                                </LabelInput>
                                                <LabelInput text={'Juros: '}>
                                                    <CurrencyInput
                                                        id="fees"
                                                        className='pl-2 border border-gray-400 border-opacity-75 
                                                        outline-none
                                                        sm:w-96 w-full
                                                        text-sm sm:text-sm
                                                        transition-all duration-200
                                                        focus:shadow-borderShadow
                                                        h-10 sm:h-8
                                                        dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                                        placeHolder={bill?.fees ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(bill?.fees) : 'R$ 0,00'}
                                                        onChange={(e) => {
                                                            setBill(prev => ({ ...prev, fees: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))
                                                        }}
                                                    />
                                                </LabelInput>
                                                <LabelInput text={'Desconto: '}>
                                                    <CurrencyInput
                                                        id="discount"
                                                        className='pl-2 border border-gray-400 border-opacity-75 
                                                        outline-none
                                                        sm:w-96 w-full
                                                        text-sm sm:text-sm
                                                        transition-all duration-200
                                                        focus:shadow-borderShadow
                                                        h-10 sm:h-8
                                                        dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                                        placeHolder={bill?.discount ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(bill?.discount) : 'R$ 0,00'}
                                                        onChange={(e) => {
                                                            setBill(prev => ({ ...prev, discount: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))
                                                        }}
                                                    />
                                                </LabelInput>
                                            </>
                                            : 
                                            <></>
                                        }
                                    </div>
                                </div>
                            </form>
                        </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate(-1)}>Cancelar</Button>
                        <Button 
                            shadow={true} 
                            approval={true} 
                            onClick={() => {
                                if (!controlSubmit){
                                    setControlSubmit(true)
                                    handleSubmit()
                                }
                            }}
                        >
                            {controlSubmit ? "Aguarde..." : "Cadastrar"}
                        </Button>
                    </div>
                </Footer>
            </Container>
        </>
    )
}