// hooks
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FaInfoCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Body } from '../../../components/container/Body'
// components
import { Container } from '../../../components/container/container'
import { Section } from '../../../components/container/section'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Input } from '../../../components/input/input'
import { TableDefault } from '../../../components/table/table.default'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { LabelInput } from '../../../components/label/label.input'
import { Button } from '../../../components/buttons/button.default'
import { Toogle } from '../../../components/toogle/Toogle'
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import Multiselect from '../../../components/selects/mult.select'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'

import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Footer } from '../../../components/footer/Footer'
import { maskDays } from '../../../utils/mask/maskDays'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function CategoriesEdit() {

    const { defaultCallsSchedules, defaultCallsAuth, api} = useApiContext()
    const navigate = useNavigate()
    const categorieId = useParams()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [selectedNumberOfDay, setSelectedNumberOfDay] = useState(0)
    const [isLocalPayment, setIsLocalPayment] = useState(false)
    const [contractRequired, setContractrequired] = useState(false)
    const [selectedAssociations, setSelectedAssociations] = useState([])
    const [forAssociations, setForAssociations] = useState('')
    const [associationsList, setAssociationsList] = useState([])

    const [categorieActive, setCategorieActive] = useState()
    const [categoriesStatusChanged, setCategoriesStatusChanged] = useState(false)

    const optionList = [
        { id: 0, value: false, name: 'Não' },
        { id: 1, value: true, name: 'Sim' },
    ]

    useEffect(() => {

        setShowUniversalModal(false)

        async function getData() {

            try {

                const getCategorie = await defaultCallsSchedules.getCategoriesEspecific(userData[0]?.token,categorieId?.id)
                // console.log(getCategorie)
                if (getCategorie?.error) {

                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText(getCategorie?.data)
                }

                const associations = await defaultCallsAuth.getAssociations(userData[0]?.token)
                if (associations.error) {
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText(associations.data)
                }

                setAssociationsList(associations.data)

                setSelectedAssociations(getCategorie?.data[0]?.associations?.filter(association_id => associations.data?.filter(assoc => assoc.id === association_id)))
                getCategorie?.data?.map(categorie => {
                    let newAssociations = []
                    categorie?.associations?.map(association_id => {
                        associations.data?.map(ass => {
                            if (ass.id === association_id) {
                                newAssociations.push(ass)
                            }
                        })
                    })
                    categorie.associations = newAssociations
                })

                setName(getCategorie?.data[0]?.name)
                setSelectedAssociations(getCategorie?.data[0]?.associations)
                setForAssociations(getCategorie?.data[0]?.associations?.length > 0 ?
                    { id: 0, value: false, name: 'Não' }
                    :
                    { id: 1, value: true, name: 'Sim' })
                setDescription(getCategorie?.data[0]?.description)
                setCategorieActive(getCategorie?.data[0]?.active)
                setIsLocalPayment(getCategorie?.data[0]?.isLocalPayment)
                setContractrequired(getCategorie?.data[0]?.contractRequired)
                setLoading(false)
            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    animateLoading(true)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }
        getData()
    }, [])

    const handleChangeCategoriesStatus = () => {
        setCategorieActive(!categorieActive)
        setCategoriesStatusChanged(!categoriesStatusChanged)
    }

    async function handleSubmit() {

        const requiredFields = [
            { "name": 'name', "value": name, "required": true, "type": 'string' },
            { "name": 'description', "value": description, "required": true, "type": 'string' },
        ]
        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }
        setLoading(true)

        try {
            if (categoriesStatusChanged) {

                await api.put(`/api/v1/categories/${parseInt(categorieId?.id)}/active/${categorieActive ? 1 : 0}`, {
                },
                    {
                        headers: {
                            Authorization: `Bearer ${userData[0]?.token}`
                        }
                    })
            }

            const apiData = {
                "name": name,
                "description": description ? description : undefined,
                "isLocalPayment": isLocalPayment,
                "contractRequired": contractRequired,
                "forAll": forAssociations?.value,
                "associations_id": selectedAssociations?.length > 0 ? selectedAssociations?.map(association => association.id) : []
            }

            //api for edit categorie
            const createCategories = await api.put(`/api/v1/categories/${categorieId?.id}`, apiData, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Categoria editada com sucesso!")
            navigate('/categories')

        } catch (error) {
            console.log(error?.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                console.log(error)
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function deleteCategorie() {

        setLoading(true)

        try {
            await api.delete(`/api/v1/categories/${categorieId?.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Categoria excluída com sucesso!')
            navigate('/categories')
        } catch (error) {
            console.log(error?.response)
            if (responseError(error)?.length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal title={'Excluir categoria'} >
                <div className='flex flex-col p-8'>
                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark mb-8'>Deseja realmente excluir esta categoria?</p>
                    <div className='flex flex-row justify-center gap-2 w-full'>
                        <Button shadow={true} onClick={() => deleteCategorie()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <>
                    <TitlePage>
                        <Title text={'Editar categoria'} />
                    </TitlePage>
                    <Body hasFooter={true}>
                        {
                            loading ?
                                <div className='flex flex-col items-center justify-center'>
                                    <DefaultLoader />
                                </div>
                                :
                                <div className='flex flex-col gap-4 items-start justify-start w-full'>
                                    <form onSubmit={e => e.preventDefault()} className='flex flex-row gap-4 flex-wrap mb-4'>
                                        <LabelInput text={'Nome *'}>
                                            <Input id='name' type={'text'} value={name} onChange={(e) => setName(e.target.value)} charLimit={255}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Descrição'}>
                                            <Input id='description' type={'text'} value={description} onChange={(e) => setDescription(e.target.value)} charLimit={60}></Input>
                                        </LabelInput>
                                        <div className='flex flex-row gap-1 items-start justify-start'>
                                            <LabelInput text={'Todas as associações'}>
                                                <InputAutoComplete preSelectedValue={forAssociations?.name} data={optionList} onChange={(e) => setForAssociations(e)} selectedLabel={'name'} optionList={['name']} />
                                            </LabelInput>
                                            <Tippy content={'Marque não para especificar quais associaçoes poderão ver esta categoria'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}>
                                                <div>
                                                    <FaInfoCircle />
                                                </div>
                                            </Tippy>
                                        </div>
                                        {
                                            !forAssociations?.value &&
                                            <LabelInput text={'Associação'}>
                                                <Multiselect
                                                    items={associationsList}
                                                    mult
                                                    id='multselect-associations'
                                                    value={selectedAssociations}
                                                    onChange={(e) => {
                                                        setSelectedAssociations(e)
                                                    }}
                                                    selectedLabel={'name'}
                                                />
                                            </LabelInput>
                                        }
                                    </form>

                                    <div className='flex flex-col'>
                                        <Tippy content={'Caso ativado, o cliente deverá pagar na secretaria/administração (mesmo com gateway de pagamento ativo ele não terá opção de pagar online).'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div>
                                                <FaInfoCircle />
                                            </div>
                                        </Tippy>
                                        <LabelInput text={'Pagamento somente na secretaria'}>
                                            <Toogle status={isLocalPayment} onClick={(e) => setIsLocalPayment(!isLocalPayment)} />
                                        </LabelInput>
                                    </div>
                                    <div className='flex flex-col'>
                                        <Tippy content={'Caso ativado, a reserva terá um campo adicional "Contrato assinado" (visível apenas para administradores) onde poderá ser controlado se o contrato ja foi assinado pelo cliente, caso não utilize, deixe desativado'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div>
                                                <FaInfoCircle />
                                            </div>
                                        </Tippy>
                                        <LabelInput text={'Requer assinatura de contrato?'}>
                                            <Toogle status={contractRequired} onClick={(e) => setContractrequired(!contractRequired)} />
                                        </LabelInput>
                                    </div>
                                    <LabelInput text={'Ativo'}>
                                        <Toogle onClick={handleChangeCategoriesStatus} status={categorieActive ? true : false}></Toogle>
                                    </LabelInput>
                                </div>
                        }
                    </Body>
                    <Footer>
                        <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                            <Button shadow={true} approval={false} onClick={() => history.back()}>Voltar</Button>
                            <div className='flex flex-row gap-1 items-center justify-center'>
                                <Button module={"categories:delete"} userData={userData} shadow={true} approval={false} onClick={() => setShowUniversalModal(true)}>Excluir</Button>
                                <Button module={"categories:update"} userData={userData} shadow={true} onClick={() => handleSubmit()}>Editar</Button>
                            </div>
                        </div>
                    </Footer>
                </>
            </Container>
        </>
    )
}