import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//components
import { Button } from "../../../components/buttons/button.default";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Footer } from "../../../components/footer/Footer";
import { Input } from "../../../components/input/input";
import { InputCheckBox } from "../../../components/input/input.checkBox";
import { LabelInput } from "../../../components/label/label.input";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";
import { validateFields } from "../../../utils/form.validator";

export function UsersBlockedEdit() {

    const { api_auth, defaultCallsAuth } = useApiContext()
    const { userData } = useAuth()
    const params = useParams()
    const navigate = useNavigate()
    const { setShowUniversalModal, setShowNotificationModalSuccess, setShowNotificationModal, setShowNotificationModalText } = useThemeContext()
    const [loading, setLoading] = useState(false)
    const [userBlocked, setUserBlocked] = useState({
        users_id: '',
        schedules: false,
        activitiesSubscription: false,
        reason: '',
        expires_in: ''
    })

    async function getData() {
        setLoading(true)
        const userBlockedData = await defaultCallsAuth.getBlockedUsersByBlockedId(userData[0]?.token, params?.id)
        setUserBlocked(userBlockedData?.data[0])
        setLoading(false)
    }

    useEffect(() => {

        const blockedData = localStorage.getItem('@usersBlocked:data')
        if (!blockedData) {
            getData()
        }
        setUserBlocked(JSON.parse(blockedData))
        localStorage.removeItem('@usersBlocked:data')
        setShowUniversalModal(false)
    }, [])

    async function handleSubmit() {
        const requiredFields = [
            { "name": 'userBlockedReason', "value": userBlocked.reason, "required": true, "type": 'string' },
        ]
        if (validateFields(requiredFields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowUniversalModal(false)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        let formattedUserBlock = {
            users_id: userBlocked?.users_id,
            reason: userBlocked.reason,
            expires_in: userBlocked.expires_in,
            schedules: userBlocked.schedules,
            activitiesSubscription: userBlocked.activitiesSubscription
        }

        if (!formattedUserBlock?.schedules && !formattedUserBlock?.activitiesSubscription) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Obrigatório informar uma das opções: Inscrições | Reservas')

        }

        if (!formattedUserBlock?.expires_in) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Obrigatório informar uma data de expiração')
        }

        try {
            await api_auth.put(`/api/v1/auth/blockedUsers/blocked_id/${userBlocked?.id}`, formattedUserBlock, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Alteracão realizada com sucesso!')
            navigate('/usersBlocked')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDelete() {
        setLoading(true)
        try {

            await api_auth.delete(`/api/v1/auth/blockedUsers/${params.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Bloqueio removido com sucesso.')
            setShowUniversalModal(false)
            navigate(-1)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal title={'Exlusão de usuário'} width={400} height={200} >
                <div className='w-full h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir esse bloqueio?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button id='modalConfirmUsersEdit' shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button id='modalCancelUsersEdit' shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Novo bloqueio'} />
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className="flex flex-col h-96 items-center justify-center">
                                <DefaultLoader />
                            </div>
                            :
                            <div className="flex flex-col gap-4 w-full items-start justify-start px-2 sm:px-0">
                                <div className='p-6 bg-zinc-50 dark:bg-thirdDefaultDark border border-zinc-100 dark:border-secondaryBorderDark flex flex-col items-start justify-start w-full'>
                                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>Utilize o formuláario abaixo para efetuar bloqueio de clientes no sistema, ao selecionar o cliente <strong>não irá conseguir fazer nova reserva ou inscrição até a data estipulada</strong></p>
                                    {/* <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark underline cursor-pointer hover:text-primaryDefaultLight' onClick={() => navigate('/categoriesCreate')}>Criar categoria</p> */}
                                </div>
                                <div className="flex flex-col sm:flex-row gap-2">
                                    <a className="text-sm font-bold w-56 dark:text-titleGrayTextDark">Usuário para bloqueio:</a>
                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{
                                        userBlocked?.firstName ? userBlocked?.users_id + '-' + userBlocked?.firstName : 'Selecionar'
                                    }</a>
                                </div>
                                {
                                    userBlocked?.firstName &&
                                    <>
                                        <div className="flex flex-col sm:flex-row gap-2">
                                            <a className="text-sm font-bold w-56 dark:text-titleGrayTextDark">Bloqueio para:</a>
                                            <div className="flex flex-row gap-2">
                                                <LabelInput text={'Reservas'}>
                                                    <InputCheckBox value={userBlocked?.schedules} onClick={() => setUserBlocked(prev => ({ ...prev, schedules: !prev?.schedules }))} />
                                                </LabelInput>
                                                <LabelInput text={'Inscrições'}>
                                                    <InputCheckBox value={userBlocked?.activitiesSubscription} onClick={() => setUserBlocked(prev => ({ ...prev, activitiesSubscription: !prev?.activitiesSubscription }))} />
                                                </LabelInput>
                                            </div>
                                        </div>
                                        <div className="flex flex-col sm:flex-row gap-2">
                                            <a className="text-sm font-bold w-56 dark:text-titleGrayTextDark">Motivo</a>
                                            <div className="flex flex-col items-start justify-start gap-2">
                                                <textarea id="userBlockedReason" wrap="hard" value={userBlocked?.reason} onChange={(e) => setUserBlocked(prev => ({ ...prev, reason: e.target.value }))} maxLength={255}
                                                    className='p-2 border border-gray-400 border-opacity-75 h-64 w-96 text-sm outline-none transition-all duration-200 focus:shadow-borderShadow dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-'>
                                                </textarea>
                                                <a className="text-xt dark:text-titleGrayTextLight">{userBlocked?.reason?.length} de 255</a>
                                            </div>
                                        </div>
                                        <div className="flex flex-col sm:flex-row gap-2">
                                            <a className="text-sm font-bold w-56 dark:text-titleGrayTextDark">Bloqueado até</a>
                                            <LabelInput text={''}>
                                                <Input type={'date'} value={userBlocked?.expires_in} onChange={(e) => setUserBlocked(prev => ({ ...prev, expires_in: e.target.value }))} />
                                            </LabelInput>
                                        </div>
                                        <br />
                                    </>
                                }
                            </div>
                    }
                </Body>
                <Footer>
                    <div className="flex flex-row w-full items-center h-full justify-between">
                        <Button shadow={true} approval={false} onClick={() => history.back()} >Voltar</Button>
                        <div className="flex gap-4">
                            <Button approval={false} onClick={() => setShowUniversalModal(true)}>Excluir</Button>
                            <Button shadow={true} onClick={() => handleSubmit()} >Salvar</Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
}