//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
//utils
import { justNumber } from '../../../utils/validators/justNumber'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { validateFields } from '../../../utils/form.validator'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import Tippy from '@tippyjs/react'
import { FaInfoCircle, FaTrash } from 'react-icons/fa'
import { Toogle } from '../../../components/toogle/Toogle'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { TableDefault } from '../../../components/table/table.default'
import moment from 'moment'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function ProductsEdit() {

    const navigate = useNavigate()
    const { defaultCallsFinancial, api_financial } = useApiContext()
    const params = useParams()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    //states table
    const [paginationHistFilteredFields, setPaginationHistFilteredFields] = useState({})
    const [paginationHistFilteredFieldsArr, setPaginationHistFilteredFieldsArr] = useState([])

    //states
    const [detectModal, setDetectModal] = useState('')
    const [productsFiscalTypes, setProductsFiscalTypes] = useState([])
    const [stockData, setStockData] = useState([])
    const [stockBalance, setStockBalance] = useState([])
    const optionsUseStock = [
        {
            id: 1,
            description: 'SIM',
            value: true
        },
        {
            id: 2,
            description: 'NÃO',
            value: false
        }
    ]
    const tableStockThead = [
        {
            "name": 'Entrada / Saída',
            "original_name": 'incomingOutgoing'
        },
        {
            "name": 'Quantidade',
            "original_name": 'quantity'
        },
        {
            "name": 'Origem',
            "original_name": 'financialShoppingComposition_id'
        },
        {
            "name": "Lançamento",
            "original_name": 'entryDate',
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        },
        {
            "name": 'Remover',
            "original_name": 'itemRemove'
        }
    ]
    const incomingOutgoingOptions = [
        { id: 1, name: 'Entrada', value: 'E' },
        { id: 2, name: 'Saída', value: 'S' }
    ]

    //selected variables
    const [products, setProducts] = useState({ name: '', fiscalType: '', financialProductsCategories_id: '', useStock: '', unitMeasure: '' })
    const [stock, setStock] = useState({ financialProducts_id: '', incomingOutgoing: '', quantity: '', entryDate: '' })

    async function getData() {

        const productsFiscalTypesData = await defaultCallsFinancial.getProductsFiscalTypers(userData[0].token)
        if (productsFiscalTypesData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(productsFiscalTypesData.data)
        }
        setProductsFiscalTypes(productsFiscalTypesData?.data)

        const productData = await defaultCallsFinancial.getProductsById(userData[0]?.token, params.id)
        if (productData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(productData.data)
        }
        setProducts(productData?.data[0])
        setLoading(false)
    }

    async function getStockData() {
        try {
            const stockDataAPI = await api_financial.get(`/api/v1/financial/stock?financialProducts_id=${params?.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setStockData(stockDataAPI?.data?.data.map((items) => ({
                ...items,
                incomingOutgoing: <div className='flex items-center justify-center'>
                    <h1>{items?.incomingOutgoing === 'E' ? "Entrada" : "Saída"}</h1>
                </div>,
                financialShoppingComposition_id: <div className='flex items-center justify-center'>
                    <h1>{items?.financialShoppingComposition_id ? "Composição" : "Avulso"}</h1>
                </div>,
                entryDate: moment(items?.entryDate).format("DD/MM/YYYY"),
                created_at: moment(items?.created_at).format("DD/MM/YYYY"),
                itemRemove: items?.financialShoppingComposition_id
                    ?
                    <div className='flex items-center justify-center gap-1'>
                        <FaTrash size={16} color='#D1D5DB' />
                        <Tippy
                            content={<a>Este produto possui uma compra. Não é possível excluir.</a>}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                        >
                            <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                <FaInfoCircle />
                            </div>
                        </Tippy>
                    </div>
                    :
                    <div className='flex items-center justify-center'>
                        <FaTrash onClick={() => handleRemoveStock(items?.id)} size={16} color='#B22222' />
                    </div>
            })))

            const stockBalanceAPI = await api_financial.get(`/api/v1/financial/stock/balance/products/${params?.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setStockBalance(stockBalanceAPI?.data?.data)

            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        setShowUniversalModal(false)
        getData()
        getStockData()
    }, [])

    function orderTableData(e) {
        const ToFilterData = []
        const fieldsKeys = Object.keys(paginationHistFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationHistFilteredFields[key].value !== '') {
                ToFilterData.push(paginationHistFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
            return getData({ filter: { filterObject: ToFilterData }, direction: e?.direction, page: e.page })
        }
        // getData(e)
    }

    function cleanStatesSubmitStock() {
        setStock({
            financialProducts_id: '',
            incomingOutgoing: '',
            quantity: '',
            entryDate: ''
        })
    }

    async function handleSubmit() {
        let requiredFields =
            [
                { "name": 'name', "value": products?.name, "required": true, "type": 'string' },
                { "name": 'code', "value": products?.code, "required": false, "type": 'string' },
                { "name": 'useStock', "value": (typeof products?.useStock === 'boolean' ? 1 : ""), "required": true, "type": 'number' },
                { "name": 'fiscalType', "value": products?.fiscalType, "required": true, "type": 'string' },
            ]
        //chamada API
        setLoading(true)
        try {

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            await api_financial.put(`/api/v1/financial/products/${params.id}`, {
                name: products?.name,
                description: products?.description ? products?.description : undefined,
                barCode: products?.barCode ? products?.barCode : undefined,
                fiscalType: products?.fiscalType ? products?.fiscalType : undefined,
                useStock: products?.useStock,
                active: products?.active,
                sku: products?.sku ? products?.sku : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Produto salvo com sucesso!")
            navigate('/financial/products')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleRemove() {

        setLoading(true)
        try {

            await api_financial.delete(`/api/v1/financial/products/${params.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Produto removido com sucesso!")
            navigate(`/financial/products`)
        } catch (error) {
            setShowUniversalModal(false)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSubmitStock() {
        let requiredFields =
            [
                { "name": 'incomingOutgoing', "value": stock?.incomingOutgoing, "required": true, "type": 'string' },
                { "name": 'quantity', "value": stock?.quantity, "required": true, "type": 'string' },
                { "name": 'entryDate', "value": stock?.entryDate, "required": true, "type": 'string' },
            ]
        setLoading(true)
        try {
            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            await api_financial.post(`/api/v1/financial/stock`, {
                financialProducts_id: Number(params?.id),
                incomingOutgoing: stock?.incomingOutgoing,
                quantity: Number(stock?.quantity),
                entryDate: stock?.entryDate,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            cleanStatesSubmitStock()
            getStockData()
            setLoading(false)
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Lançamento realizado com sucesso!")

        } catch (error) {
            setShowUniversalModal(false)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleRemoveStock(id) {
        setLoading(true)
        try {
            await api_financial.delete(`/api/v1/financial/stock/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setStockData((state) => state.filter(items => items?.id !== id))
            getStockData()
            setLoading(false)
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Excluído com sucesso!")
        } catch (error) {
            setShowUniversalModal(false)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const totalPages = Math.ceil(stockData?.length / 10)

    return (
        <>
            {
                detectModal === 'Excluir'
                &&
                <ScreenModal>
                    <div className='p-4 flex flex-col gap-2'>
                        <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente excluir este produto?</a>
                        <div className='flex flex-row w-full items-center justify-center gap-2'>
                            <Button shadow={true} onClick={() => handleRemove()} approval={true} >Sim</Button>
                            <Button shadow={true} onClick={() => setShowUniversalModal(false)} approval={false} >Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            {
                detectModal === 'Lançar'
                &&
                <ScreenModal>
                    <div className='flex flex-col items-center justify-center p-10 pt-0'>
                        <form className='flex flex-col gap-2 items-start justify-start sm:justify-center w-full'>
                            <LabelInput text={'Entrada/Saída: *'}>
                                <InputAutoComplete
                                    data={incomingOutgoingOptions}
                                    id={'incomingOutgoing'}
                                    value={stock?.incomingOutgoing ? incomingOutgoingOptions.filter(item => item?.value === stock?.incomingOutgoing)[0]?.name : '  '}
                                    preSelectedValue={stock?.incomingOutgoing ? incomingOutgoingOptions.filter(item => item?.value === stock?.incomingOutgoing)[0]?.name : '  '}
                                    onChange={(e) => setStock(prev => ({ ...prev, incomingOutgoing: e.value }))}
                                    selectedLabel={'name'}
                                    optionList={['id', 'name']}
                                />
                            </LabelInput>
                            <LabelInput text={'Quantidade: *'}>
                                <Input id='quantity' type={'text'} value={stock?.quantity} onChange={(e) => setStock(prev => ({ ...prev, quantity: e.target.value }))}></Input>
                            </LabelInput>
                            <LabelInput text={'Data de lançamento: *'}>
                                <Input id='entryDate' type={'date'} value={stock?.entryDate} onChange={(e) => setStock(prev => ({ ...prev, entryDate: e.target.value }))}></Input>
                            </LabelInput>
                        </form>
                        <div className='flex flex-row w-full items-center justify-center gap-2 pt-10'>
                            <Button shadow={true} onClick={() => handleSubmitStock()}>Lançar</Button>
                            <Button shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Cancelar</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            <Container>
                <TitlePage>
                    <Title text={'Editar produto'}></Title>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <form className='flex flex-col gap-2 items-start justify-start sm:justify-center w-full'>
                                    <LabelInput text={'Nome: *'}>
                                        <Input id='name' type={'text'} value={products?.name} onChange={(e) => setProducts(prev => ({ ...prev, name: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Descrição:'}>
                                        <Input id='description' type={'text'} value={products?.description} onChange={(e) => setProducts(prev => ({ ...prev, description: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'SKU:'}>
                                        <Input id='sku' type={'text'} value={products?.sku} onChange={(e) => setProducts(prev => ({ ...prev, sku: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Código de barras:'}>
                                        <Input id='barCode' type={'text'} value={products?.barCode} onChange={(e) => setProducts(prev => ({ ...prev, barCode: justNumber(e.target.value) }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Utiliza estoque: *'}>
                                        <InputAutoComplete
                                            value={optionsUseStock.filter(type => type.value === products.useStock)[0]?.description}
                                            data={optionsUseStock}
                                            id={'useStock'}
                                            preSelectedValue={optionsUseStock.filter(type => type.value === products.useStock)[0]?.description}
                                            onChange={(e) => setProducts(prev => ({ ...prev, useStock: e.value }))}
                                            selectedLabel={'description'}
                                            optionList={['id', 'description']}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Unidade de medida: *'}>
                                        <Input id='unitMeasure' type={'text'} disabled={true} value={products?.unitMeasure} />
                                    </LabelInput>
                                    <div className='flex gap-1'>
                                        <LabelInput text={'Tipo fiscal: *'}>
                                            <InputAutoComplete
                                                preSelectedValue={productsFiscalTypes?.filter(type => type.id === products.fiscalType)[0]?.description}
                                                data={productsFiscalTypes}
                                                id={'fiscalType'}
                                                onChange={(e) => setProducts(prev => ({ ...prev, fiscalType: e.id }))}
                                                selectedLabel={'description'}
                                                optionList={['id', 'description']}
                                            />
                                        </LabelInput>
                                        <Tippy
                                            content={<a>Responsável por definir as alíquotas de impostos no Brasil.</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                        >
                                            <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                <FaInfoCircle />
                                            </div>
                                        </Tippy>
                                    </div>
                                    <LabelInput text={'ativo'}>
                                        <Toogle status={products?.active} onClick={() => setProducts(prev => ({ ...prev, active: !products?.active }))} />
                                    </LabelInput>
                                </form>
                                {
                                    products?.useStock
                                    &&
                                    <div className='w-full flex flex-col items-end overflow-y-auto gap-2'>
                                        <div className='w-full flex items-center justify-between'>
                                            {
                                                stockBalance.map(balance => {
                                                    return <h1 className='text-lg font-medium flex items-center gap-2'>Total em estoque: <strong>{balance?.stockBalance}</strong></h1>
                                                })
                                            }
                                            <Button onClick={() => { setShowUniversalModal(true); setDetectModal("Lançar") }}>Realizar novo lançamento</Button>
                                        </div>
                                        <TableDefault
                                            data={stockData}
                                            pagination={true}
                                            totalPages={totalPages}
                                            onClick={(e) => handleEdit(e)}
                                            title={tableStockThead}
                                            collumns={["incomingOutgoing", "quantity", "financialShoppingComposition_id", "entryDate", "created_at", "itemRemove"]}
                                            loading={loading}
                                            filter={(e) => orderTableData(e)}
                                        />
                                    </div>
                                }
                            </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate('/financial/products')}>Cancelar</Button>
                        <div className='flex gap-2'>
                            <Button module={"financialProdutcs:delete"} userData={userData} shadow={true} approval={false} onClick={() => { setShowUniversalModal(true); setDetectModal("Excluir") }}>Excluir</Button>
                            <Button module={"financialProdutcs:edit"} userData={userData} shadow={true} approval={true} onClick={() => {
                                handleSubmit()
                            }}>Salvar</Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
}
