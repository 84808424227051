import LoginLogo from '../../assets/logos/loginLogo.png'
import bgLogin from '../../assets/default_assets/bg-login.jpg'
import { colors } from '../../theme/colorsSchema'
import { LabelInput } from '../../components/label/label.input'
import { InputAutoComplete } from '../../components/input/input.autocomplete'
import { Input } from '../../components/input/input'
import { FaArrowAltCircleLeft, FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { Button } from '../../components/buttons/button.default'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { hourArr } from '../../utils/hour/generateHourArr'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { useThemeContext } from '../../contexts/themeContext'
import { useAuth } from '../../contexts/useAuth'
import moment from 'moment'
import { maskHours } from '../../utils/mask/maskHours'
moment.locale('pt');

//services
import { validateFields } from '../../utils/form.validator'
import { useApiContext } from '../../contexts/ApiInterceptorContext'

export function LoginPublicSchedules({ customersData, clientFinded }) {

    const { defaultCallsSchedules, defaultCallsAuth, defaultCallsFinancial, api_auth, api} = useApiContext()
    const { setSigned, setUserData, setCustomersAlerts, setHaveTermsOfUse, setDontHaveEmail } = useAuth()
    const { setShowNotificationModal, setShowUniversalModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { screenX, screenY } = useScreenSizeContext()

    const navigate = useNavigate()
    const { usersId, userTypeAccess, app_name } = useParams()
    const [primaryDefaultLight, setPrimaryDefaultLight] = useState(
        !!customersData?.primaryColor
        ?
        customersData?.primaryColor
        :
        colors.filter((color) => { if (color.name === 'default') { return color } })[0].primaryColor
    )
    const [secondaryDefaultLight, setSecondaryDefaultLight] = useState(
        !!customersData?.secundaryColor
        ?
        customersData?.secundaryColor
        :
        colors.filter((color) => { if (color.name === 'default') { return color } })[0].primaryColor
    )
    const [password, setPassword] = useState('')
    const date = Date.now()

    //states
    const [user, setUser] = useState('')
    const [errorFields, setErrorFields] = useState([])
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingSchedules, setLoadingSchedules] = useState(true)
    const [loadingBoard, setLoadingBoard] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [customers_code, setCustomers_code] = useState(customersData?.code)
    const [selectedCategorie, setSelectedCategorie] = useState('')
    const [categoriesList, setCategoriesList] = useState([])
    const [servicesList, setServicesList] = useState([])
    const [hourSelected, setHourSelected] = useState('')
    const [selectedDay, setSelectedDay] = useState(moment().format("YYYY-MM-DD"))
    const calendarRef = useRef(null)
    const [dragging, setDragging] = useState(false)
    const [showScheduleMandatory, setShowScheduleMandatory] = useState(false)
    const [touchSchedule, setTouchSchedule] = useState(false)
    const [touchStartTime, setTouchStartTime] = useState(null)
    const [startDate, setStartDate] = useState(moment())
    const [showIndex, setShowIndex] = useState([0])
    const [ recoverPassword, setRecoverPassword ] = useState(false)
    const [ forgetPassword, setForgetPassword ] = useState(false)
    const [email, setEmail] = useState('')

    function handleErrorModal(status, message) {
        if (status) {
            setErrorMessage(message)
        } else {
            setErrorMessage('')
        }
    }

    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    function scrollToCurrentHour() {

        const currentHour = moment().hour();
        const elementToScroll = document.getElementById(`hour-${currentHour}`);
        if (elementToScroll) {
            elementToScroll.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    async function getServices(categorie) {
        try {
            const servicesData = await api.get(`/api/public/${customersData.code}/services/categories_id/${categorie?.id}`)
            setServicesList(servicesData.data.data)

            return servicesData.data.data
        } catch (error) {
            console.log(error)
        }
    }

    async function getSchedules(date) {
        setLoadingSchedules(true)
        try {

            const schedulesData = await api.get(`/api/public/${customersData.code}/schedulesAndBlocks/date/${date}`)
            setLoadingSchedules(false)
            return schedulesData.data.data

        } catch (error) {
            setLoadingSchedules(false)
        }
    }

    async function getPublicCategories() {
        try {
            const categoriesData = await api.get(`/api/public/${customersData.code}/categories`)
            const filteredCategoriesForAll = categoriesData?.data?.data.filter(categories => !!categories?.forAll)

            setCategoriesList(filteredCategoriesForAll)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setLoadingSchedules(false)
            console.log(error)
            // implementar modal de erro
        }
    }

    async function getData() {
        getPublicCategories()
    }

    async function handleSelecteCategorie(e) {
        setLoadingSchedules(true)
        setLoadingBoard(true)
        try {
            const servicesData = await getServices(e)
            const schedulesData = await getSchedules(moment().format('YYYY-MM-DD'))

            servicesData.map(service => {
                service.schedules = schedulesData?.schedules.filter(schedule => schedule.services_id === service.id)
                service.blocks = schedulesData?.blocks.filter(schedule => schedule.services_id === service.id)
            })

            if (!touchSchedule) {
                setShowIndex([...servicesData.map((servive, i) => { return i })])
                setLoadingSchedules(false)
                setLoadingBoard(false)
            } else {
                setLoadingSchedules(false)
                setLoadingBoard(false)
            }
        } catch (error) {
            console.log(error)
            // adiconar modal de erro
        }

        setSelectedCategorie(e)
    }

    async function getNewSchedulesByDate(date) {

        setLoadingSchedules(true)

        const schedulesData = await getSchedules(date)
        servicesList.map(service => {
            service.schedules = schedulesData?.schedules.filter(schedule => schedule.services_id === service.id)
            service.blocks = schedulesData?.blocks.filter(schedule => schedule.services_id === service.id)
        })

        setServicesList(servicesList)
        await new Promise(resolve => setTimeout(resolve, 200))
        scrollToCurrentHour()

    }

    useEffect(() => {
        getData()
    }, [])

    function handleRequiredFields() {

        let errors = false
        if (errorFields.includes('password')) {
            errors = true
            document.getElementById('password').style.border = "1px solid red"
        } else {
            document.getElementById('password').style.border = ""
        }

        if (errorFields.includes('user')) {
            errors = true
            document.getElementById('user').style.border = "1px solid red"
        } else {
            document.getElementById('user').style.border = ""
        }
        return errors
    }

    async function handleLogin() {
        if (user === '') {
            if (!errorFields.includes('user')) {
                errorFields.push('user')
                setErrorFields(errorFields)
            }
        } else {
            errorFields.indexOf('user') > -1 && errorFields.splice(errorFields.indexOf('user'), 1)
            setErrorFields([...errorFields])
        }

        if (password === '') {
            if (!errorFields.includes('password')) {
                errorFields.push('password')
                setErrorFields(errorFields)
            }
        } else {
            errorFields.indexOf('password') > -1 && errorFields.splice(errorFields.indexOf('password'), 1)
            setErrorFields([...errorFields])
        }

        if (handleRequiredFields()) {
            return
        }

        try {
            setLoading(true)
            const login = await api_auth.post('/api/auth/logon', {
                "user": user?.toLocaleLowerCase()?.trim(),
                "password": password,
                customers_code: !customers_code ? undefined : customers_code,
            })

            if (login.data.status === 201) {

                const apiUserData = []
                apiUserData.push(login.data.userData)
                localStorage.setItem('typeUser', 1)
                apiUserData[0].customers_s3_url = login?.data?.userData?.customers_s3_url
                apiUserData[0].photo = login.data.userData?.photo?.length > 0 ? `${login?.data?.userData?.customers_s3_url}/${login.data.userData?.customers_code}/${login.data.userData?.photo}` : undefined
                apiUserData[0].customersData = customersData
                apiUserData[0].token = login.data.token
                apiUserData[0].customers_id = 1

                const paymentGateway = await defaultCallsSchedules.getPaymentPublicGateways(login.data.token)
                const alerts = await defaultCallsAuth.getCustomerAlerts(login.data.token)
                //alertas do sistema
                if (alerts.data.data[0]?.message?.length > 0) {
                    setCustomersAlerts(alerts.data.data)
                }
                apiUserData[0].customersData.hasGateway = paymentGateway.data?.data?.length > 0 ? true : false
                apiUserData[0].customersData.paymentGateway = paymentGateway.data.data?.length > 0 ? paymentGateway.data?.data[0] : []

                if (apiUserData[0].customersData?.modules.map(module => { return module.id }).includes(3)) {            
                    //cliente com modulo financeiro, valida se tem financialRules do contrário não permite acesso as reservas
                    const financialRules = await defaultCallsFinancial.getFinancialRules(login.data.token)
                    const rule = financialRules.data?.length > 0 ? financialRules.data.filter(rule => rule.active)[0] : null
                    if (!rule?.id) {
                        apiUserData[0].customersData.blockedByFinancialRules = true
                    }        
                }

                localStorage.setItem('@lastLogin', moment().format('YYYY-MM-DD'))
                localStorage.setItem('@auth:token', login.data.token)

                setUserData(apiUserData)

                if (!!selectedCategorie && !!hourSelected) {
                    navigate(`/reservation/categories_id/${selectedCategorie?.id}/services_id/${hourSelected?.service_id}`)
                } else {
                    navigate('/')
                }

                setSigned(true)

                //leitura dos termos nao lidos
                const terms = await  defaultCallsAuth.getUserTerms(login.data.token, apiUserData[0]?.id)
                if (terms.data?.data?.length > 0) {
                    setLoading(false)
                    if (apiUserData[0].email?.length === 0) {
                        setDontHaveEmail(true)
                    }
                    return setHaveTermsOfUse(true)
                }
                if (apiUserData[0].email?.length === 0 || !apiUserData[0]?.email) {
                    navigate(`/myProfileEdit/${apiUserData[0]?.id}`)
                    setDontHaveEmail(true)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setLoading(false)
                    return setShowNotificationModalText('Você não possui e-mail configurado, obrigatório cadastrar uma conta de e-mail para poder utilizar o sistema')
                } else {
                    setDontHaveEmail(false)
                }

                //verifica se esta sendo validado o usuario (qrCode carteira digital)
                if (usersId?.length > 0) {
                    Number(userTypeAccess) > 2 ? navigate(`/clientsEdit/${usersId}`) : navigate(`/usersEdit/${usersId}`)
                }
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            setShowError(true)
            if (error?.response?.data?.statusCode === 401 && error?.response?.data?.message === 'Usuário não autenticado.') {
                setLoading(false)
                return handleErrorModal(true, 'Seu usuário está desativado, contate o administrador')
            } else if (error?.response?.data?.message === 'Erro ao realizar logon, você não ativou a sua conta.') {
                setLoading(false)
                setShowResendActivateAccount(true)
                return handleErrorModal(true, error?.response?.data?.action)
            }
            else if (error?.response?.data?.statusCode === 401) {
                setLoading(false)
                return handleErrorModal(true, error?.response?.data?.action)
            } else {
                setLoading(false)
                return handleErrorModal(true, error?.response?.data?.message.concat(' ').concat(error?.response?.data?.action))
            }
        }
    }

    const handleDragStart = () => {
        const dragPreview = document.createElement('div')
        dragPreview.style.width = '1px'
        dragPreview.style.height = '1px'
        dragPreview.style.backgroundColor = 'rgba(0, 123, 255, 0.5)'
        dragPreview.style.display = 'flex'
        dragPreview.style.alignItems = 'center'
        dragPreview.style.justifyContent = 'center'

        document.body.appendChild(dragPreview)
        event.dataTransfer.setDragImage(dragPreview, 50, 25)

        setTimeout(() => document.body.removeChild(dragPreview), 0)
        setDragging(true);
    }

    const handleDragOver = (event, hour) => {
        event.preventDefault()
        if (dragging) {

            setHourSelected({ ...hourSelected, finishHour: hour });
        }
    }

    const handleDrop = () => {
        setDragging(false);
    }

    const handlePrev = () => {
        let maxDays = screenX > 850 ? 7 : 3
        setStartDate(startDate.clone().subtract(maxDays, 'days'))
    }

    const handleNext = () => {
        let maxDays = screenX > 850 ? 7 : 3
        setStartDate(startDate.clone().add(maxDays, 'days'))
    }

    const renderDays = () => {
        const days = [];
        let maxDays = screenX > 850 ? 7 : 3
        for (let i = 0; i < maxDays; i++) {
            const currentDay = startDate.clone().add(i, 'days');
            days.push(
                <div
                    onClick={() => {
                        setSelectedDay(currentDay.format("YYYY-MM-DD"));
                        setHourSelected('')
                        getNewSchedulesByDate(currentDay.format("YYYY-MM-DD"));
                    }}
                    key={i}
                    className={`rounded-full w-10 h-10 ${selectedDay === currentDay.format("YYYY-MM-DD") ? 'bg-blue-300 text-white' : 'bg-blue-100'}  
                    p-1 flex flex-col items-center justify-center w-full
                    cursor-pointer hover:bg-blue-200
            `}>
                    <a className="text-xs">{currentDay.format("DD")}</a>
                    <a className="text-xs">{currentDay.format("MMM")}</a>
                </div>
            );
        }
        return days;
    }

    const handleTouchStart = () => {
        setTouchStartTime(Date.now())
        setTouchSchedule(true)
    }

    const handleTouchEnd = (space, hour) => {
        const touchDuration = Date.now() - touchStartTime

        if (touchDuration < 150) {
            setShowScheduleMandatory(true);
            setTouchSchedule(true);
            setHourSelected({ startHour: hour, finishHour: hour + .5, service_id: space.id });
        }
    }

    const handleChangeServiceCursor = (direction) => {

        let actualIndex = Number(showIndex?.map(index => { return index }))
        if (actualIndex < servicesList.length - 1 && direction == "right") {
            setShowIndex([actualIndex + 1])
        }

        if (actualIndex < servicesList.length && actualIndex > 0 && direction == "left") {
            setShowIndex([actualIndex - 1])
        }
    }

    async function handlePassword() {
        try {
            const requiredFields = [
                { "name": 'email', "value": email, "required": true, "type": 'string' },
            ]
            if (validateFields(requiredFields).length > 0) {
                return
            }
            setLoading(true)

            await api_auth.post(`/api/auth/forgetPassword`, {
                email: email?.toLowerCase(),
                customers_code
            })

            setEmail('')
            setRecoverPassword(true)
            setForgetPassword(false)
            setErrorMessage('')
        } catch (error) {
            if (error?.response?.data?.statusCode === 400) {
                return handleErrorModal(true, "O e-mail informado é inválido")
            } else {
                return handleErrorModal(true, error?.response?.data?.action)
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <div style={{
            backgroundImage: `
                linear-gradient(to bottom, rgba(${hexToRgb(primaryDefaultLight).r},${hexToRgb(primaryDefaultLight).g}, ${hexToRgb(primaryDefaultLight).b}, 0.80), 
                rgba(${hexToRgb(secondaryDefaultLight).r},${hexToRgb(secondaryDefaultLight).g}, ${hexToRgb(secondaryDefaultLight).b}, .85)), url(${bgLogin})
            `
        }}
            className={`fixed w-full h-[100vh] bg-cover flex flex-col lg:flex lg:flex-row bg-primaryDefaultLight items-center justify-center`}
        >
            {
                loading ?
                    <div>
                        <p className='text-white'>Buscando dados</p>
                    </div>
                    :
                    !forgetPassword && !!errorMessage 
                    ?
                    <div id='messageModal' className='px-2 sm:px-0 bg-transparent flex flex-col h-full items-center justify-center'>
                        <p className='text-lg text-titleGrayTextLight'>Erro ao efetuar login:</p>
                        <p className='text-lg text-titleGrayTextLight text-center'>{errorMessage}</p>
                        <div className='mt-10 flex gap-2'>
                            <Button shadow={true} onClick={() => { setErrorMessage(false); setLoading(false) }}>Voltar</Button>
                        </div>
                    </div>
                    :
                    <>
                        <div className='w-full h-full flex flex-col md:flex md:flex-row items-center justify-center'>
                            <div className="w-[500px] hidden sm:flex md:min-w-[400px] max-w-[500px] p-2 2xl:p-12 rounded-lg boxShadow overflow-auto bg-white items-center justify-center">
                                <form className='flex h-[100vh] flex-col gap-4 items-center justify-center w-full md:w-auto'>
                                    {
                                        forgetPassword ?
                                        <div className='flex flex-col items-center justify-center gap-4 w-full flex-wrap'>
                                            <p className='mb-4 text-2xl text-titleBlackTextLight'><strong>Esqueceu</strong> sua senha?</p>
                                            <p className='mb-8 text-titleBlackTextLight text-center'>Enviaremos um e-mail com instruções para redefinir sua senha</p>
                                            <div onClick={() => { setErrorMessage(''); setForgetPassword(false) }} className='cursor-pointer hover:brightness-150 absolute top-6 left-0 sm:left-8 text-2xl text-primaryDefaultLight flex flex-col w-full items-start justify-start'>
                                                <FaArrowAltCircleLeft />
                                            </div>
                                            <LabelInput text={'E-mail *'}>
                                                <Input 
                                                    width={screenX < 760 ? '' : '64'}
                                                    id='email' 
                                                    name='email'
                                                    value={email} 
                                                    type='e-mail' 
                                                    onChange={(e) => setEmail(e.target.value?.toLowerCase())}
                                                />
                                            </LabelInput>
                                            {
                                                forgetPassword && !!errorMessage
                                                &&
                                                <div className='flex flex-col items-center justify-center'>
                                                    <p className='text-red-600 my-4 text-center'>{errorMessage}</p>
                                                </div>
                                            }
                                            <button 
                                                type='button'
                                                onClick={() => handlePassword()} 
                                                className='mt-2 bg-primaryDefaultLight hover:bg-secondaryDefaultLight outline-none focus:bg-secondaryDefaultLight transition-all duration-300 w-[350px] mb-2 sm:mb-4 sm:w-64 h-10 sm:h-10 rounded-lg sm:rounded-lg text-white text-base'
                                            >
                                                Redefinir
                                            </button>
                                        </div>
                                        :
                                        recoverPassword ?
                                        <div className='animate-wiggle flex flex-col items-center justify-center w-full'>
                                            <p className='mb-4 text-2xl text-center text-titleBlackTextLight'><strong>Sucesso</strong>, verifique seu e-mail!</p>
                                            <p className='mb-10 text-titleBlackTextLight'></p>
                                            <Button shadow={true} onClick={() => { setForgetPassword(false); setRecoverPassword(false) }}>Login</Button>
                                        </div>
                                        :
                                        <>
                                            <div className='flex flex-col items-center justify-center max-h-32 max-w-28 overflow-hidden'>
                                                <img className='mb-5' src={clientFinded ? customersData?.photo?.length > 0 ? customersData?.photo + '?' + date : LoginLogo : LoginLogo} width={screenX < 600 || screenY < 800 ? 250 : 200} />
                                            </div>
                                            <a className='mb-2 text-xs text-titleBlackTextLight'>* Caso entre com CPF, digite somente os números *</a>
                                            <LabelInput text={'Usuário'}>
                                                <Input id='user' autoFocus={true} width={screenX < 760 ? '' : '64'} onChange={(e) => setUser(e.target.value?.toLowerCase())} value={user}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Senha'}>
                                                <Input id='password' type={'password'} width={screenX < 760 ? '' : '64'} onChange={(e) => setPassword(e.target.value)} value={password}></Input>
                                            </LabelInput>
                                            <button 
                                                type='button' 
                                                id='loginButton' 
                                                className='mt-2 bg-primaryDefaultLight hover:bg-secondaryDefaultLight outline-none focus:bg-secondaryDefaultLight transition-all duration-300 w-[350px] mb-2 sm:mb-4 sm:w-64 h-10 sm:h-10 rounded-lg sm:rounded-lg text-white text-base' 
                                                onClick={() => handleLogin()}
                                            >
                                                Avançar 
                                            </button>
                                            <p className='text-primaryDefaultLight text-sm underline cursor-pointer' onClick={() => setForgetPassword(true)}>Esqueci minha senha</p>
                                        </>
                                    }
                                </form>
                            </div>

                            <div className={`animate-[wiggle_1s_ease-in-out] h-full w-full p-3 2xl:p-12 rounded-lg relative flex items-center justify-center`}>
                                {(showScheduleMandatory && touchSchedule) ?
                                    (
                                        <div className="absolute p-2 w-full h-full bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
                                            <div className="flex rounded-lg boxShadow overflow-auto bg-white relative items-center justify-center px-3 py-8">
                                                <form className='flex h-[100%] flex-col gap-4 items-center justify-center w-full md:w-auto'>
                                                    {
                                                        forgetPassword ?
                                                        <div className='flex flex-col items-center justify-center gap-4 w-full'>
                                                            <div className='w-full flex flex-row items-start justify-center gap-2'>
                                                                <div onClick={() => { setErrorMessage(''); setForgetPassword(false) }} className='text-2xl text-primaryDefaultLight'>
                                                                    <FaArrowAltCircleLeft />
                                                                </div>
                                                                <p className='mb-4 text-lg text-titleBlackTextLight'><strong>Esqueceu</strong> sua senha?</p>
                                                            </div>
                                                            <p className='mb-8 text-titleBlackTextLight text-center text-xs'>Enviaremos um e-mail com instruções para redefinir sua senha</p>
                                                            <LabelInput text={'E-mail *'}>
                                                                <Input 
                                                                    width={screenX < 760 ? '' : '64'} 
                                                                    id='email' 
                                                                    name='email'
                                                                    value={email} 
                                                                    type='e-mail' 
                                                                    onChange={(e) => setEmail(e.target.value?.toLowerCase())}
                                                                />
                                                            </LabelInput>
                                                            <button 
                                                                type='button'
                                                                onClick={() => handlePassword()} 
                                                                className='mt-2 bg-primaryDefaultLight hover:bg-secondaryDefaultLight outline-none focus:bg-secondaryDefaultLight transition-all duration-300 w-48 mb-2 sm:mb-4 h-8 rounded-lg sm:rounded-lg text-white text-base'
                                                            >
                                                                Redefinir
                                                            </button>
                                                        </div>
                                                        :
                                                        recoverPassword ?
                                                        <div className='animate-wiggle flex flex-col items-center justify-center w-full'>
                                                            <p className='mb-4 text-2xl text-titleBlackTextLight'><strong>Sucesso</strong>, verifique seu e-mail!</p>
                                                            <p className='mb-10 text-titleBlackTextLight'></p>
                                                            <Button shadow={true} onClick={() => { setForgetPassword(false); setRecoverPassword(false) }}>Login</Button>
                                                        </div>
                                                        :
                                                        <>
                                                            <a className="text-sm">* Efetue o login para reservar</a>
                                                            <div className='flex flex-col items-center justify-center max-h-32 max-w-28 overflow-hidden'>
                                                                <img className='mb-5' src={clientFinded ? customersData?.photo?.length > 0 ? customersData?.photo + '?' + date : LoginLogo : LoginLogo} width={screenX < 600 || screenY < 800 ? 250 : 200} />
                                                            </div>
                                                            <a className='mb-2 text-xs text-titleBlackTextLight'>* Caso entre com CPF, digite somente os números *</a>
                                                            <LabelInput text={'Usuário'}>
                                                                <Input id='user' autoFocus={true} width={screenX < 760 ? '' : '64'} onChange={(e) => setUser(e.target.value?.toLowerCase())} value={user}></Input>
                                                            </LabelInput>
                                                            <LabelInput text={'Senha'}>
                                                                <Input id='password' type={'password'} width={screenX < 760 ? '' : '64'} onChange={(e) => setPassword(e.target.value)} value={password}></Input>
                                                            </LabelInput>
                                                            <div className="flex text-sm gap-2 items-center justify-center w-48 max-w-48">
                                                                <button 
                                                                    type='button' 
                                                                    id='cancel' 
                                                                    className='mt-2 bg-red-700 hover:bg-secondaryDefaultLight outline-none focus:bg-secondaryDefaultLight transition-all duration-300 w-full mb-2 sm:mb-4 sm:w-64 h-10 sm:h-10 
                                                                    px-4 rounded-lg sm:rounded-lg text-white'
                                                                    onClick={() => { setShowScheduleMandatory(false); setHourSelected('') }}
                                                                >
                                                                    Cancelar 
                                                                </button>
                                                                <button 
                                                                    type='button' 
                                                                    id='loginButton' 
                                                                    className='text-sm mt-2 bg-primaryDefaultLight hover:bg-secondaryDefaultLight outline-none focus:bg-secondaryDefaultLight transition-all duration-300 w-full mb-2 sm:mb-4 sm:w-64 h-10 sm:h-10 px-4 rounded-lg sm:rounded-lg text-white'
                                                                    onClick={() => handleLogin()}
                                                                >
                                                                    Avançar 
                                                                </button>
                                                            </div>
                                                            <p className='text-primaryDefaultLight text-sm underline cursor-pointer' onClick={() => setForgetPassword(true)}>Esqueci minha senha</p>
                                                        </>
                                                    }
                                                </form>
                                            </div>
                                        </div>
                                    )
                                    :
                                    showScheduleMandatory &&
                                    (
                                        <div className="absolute w-full h-full bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
                                            <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center justify-center">
                                                <p className="">Efetue o login para reservar</p>
                                                <button
                                                    onClick={() => setShowScheduleMandatory(false)}
                                                    className="mt-4 p-2 bg-primaryDefaultLight text-white rounded"
                                                >
                                                    Fechar
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                <div className='flex flex-col items-start justify-start md:max-h-[95%] max-h-[55%] h-[95%]
                                w-full max-w-[99%] md:max-w-[95%] 2xl:max-w-[1300px] overflow-auto bg-white rounded-lg'>
                                    {
                                        !selectedCategorie ?
                                            <div onTouchStart={() => { setTouchSchedule(true) }} className="bg-white w-full h-full p-3 relative flex flex-col items-center justify-center py-16 z-10">
                                                <div className=' absolute flex sm:hidden top-8 flex-col items-center justify-center max-h-32 max-w-28 overflow-hidden'>
                                                    <img className='mb-5' src={clientFinded ? customersData?.photo?.length > 0 ? customersData?.photo + '?' + date : LoginLogo : LoginLogo} width={screenX < 600 || screenY < 800 ? 250 : 200} />
                                                </div>
                                                <h2 className="text-2xl font-semibold text-zinc-800">Verifique nossa <strong className="text-primaryDefaultLight">agenda!</strong></h2>
                                                <a className="text-zinc-700 ">Selecione um tipo de espaço abaixo:</a>
                                                <LabelInput
                                                    text={'Tipo'}
                                                >
                                                    <InputAutoComplete
                                                        width={'100px'}
                                                        value={selectedCategorie?.name}
                                                        data={categoriesList}
                                                        selectedLabel={'name'}
                                                        optionList={['name']}
                                                        placeHolder='Categoria'
                                                        onChange={e => handleSelecteCategorie(e)}
                                                        id='selectedCategorie'
                                                    />
                                                </LabelInput>
                                                <button type='button' id='showLoginPage' className='flex sm:hidden items-center justify-center shadow-md absolute bottom-8 mt-2 bg-primaryDefaultLight hover:bg-secondaryDefaultLight outline-none focus:bg-secondaryDefaultLight transition-all duration-300 
                                                px-3 mb-2 sm:mb-4 sm:w-64 h-10 sm:h-10 rounded-lg sm:rounded-lg text-white text-base'
                                                    onClick={() => {
                                                        setShowScheduleMandatory(true);
                                                        setTouchSchedule(true);
                                                    }}
                                                >
                                                    Ir para login
                                                </button>
                                            </div>
                                            :
                                            <>
                                                <div className="bg-white px-3 py-1 md:min-w-[500px] flex relative flex-col gap-1 w-full justify-center items-center">
                                                    <FaArrowLeft className="absolute left-3 cursor-pointer hover:brightness-110" onClick={() => {
                                                        setSelectedCategorie('');
                                                        setHourSelected('')
                                                    }}
                                                    />
                                                    <div className="w-full flex items-center justify-center gap-4">
                                                        <button onClick={handlePrev} className="p-2 bg-gray-200 rounded-full cursor-pointer">
                                                            &lt;
                                                        </button>
                                                        <div className="flex gap-2 items-center jsutify-center">
                                                            {renderDays()}
                                                        </div>
                                                        <button onClick={handleNext} className="p-2 bg-gray-200 rounded-full cursor-pointer">
                                                            &gt;
                                                        </button>
                                                    </div>
                                                    {
                                                        hourSelected && !touchSchedule &&
                                                        <div className="absolute right-2 top-2 animate-[wiggle_.2s_ease-in-out] transition-all duration-100">
                                                            <Button onClick={() => setShowScheduleMandatory(true)} shadow className="w-14">
                                                                Reservar
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="flex flex-row w-full items-start justify-start bg-blue-100 border-b border-blue-200 md:min-w-[500px]">
                                                    <div className='p-3 border-r min-w-24 w-24 h-full flex flex-col items-center justify-center'>
                                                        {
                                                            touchSchedule && Number(showIndex.map(i => { return i })[0]) > 0 ?
                                                                <FaArrowLeft className='text-zinc-700' onClick={() => handleChangeServiceCursor('left')} />
                                                                :
                                                                <FaArrowLeft className='text-zinc-400' onClick={() => handleChangeServiceCursor('left')} />
                                                        }
                                                    </div>
                                                    {
                                                        servicesList.map((service, index) => {
                                                            return showIndex.includes(index) &&
                                                                <div className={`p-3  min-w-56 ${showIndex?.length === 1 ? 'w-full' : 'w-48'} h-full flex flex-col items-center justify-center`}>
                                                                    <a className="text-xs">{service.name}</a>
                                                                </div>
                                                        })
                                                    }
                                                    {
                                                        touchSchedule && Number(showIndex.map(i => { return i })[0]) < servicesList?.length - 1 ?
                                                            <div className='p-3 flex border-r min-w-24 w-24 h-full flex-col items-center justify-center'>
                                                                <FaArrowRight className='text-zinc-700' onClick={() => handleChangeServiceCursor('right')} />
                                                            </div>
                                                            :
                                                            <div className='p-3 flex border-r min-w-24 w-24 h-full flex-col items-center justify-center'>
                                                                <FaArrowRight className='text-zinc-400' />
                                                            </div>
                                                    }
                                                </div>
                                                <div className="flex flex-col items-start justify-start overflow-y-scroll md:min-w-[500px] h-full w-full">
                                                    {
                                                        loadingSchedules ?
                                                            <div className="h-full w-full flex items-center animate-pulse justify-center text-zinc-500">
                                                                <p className='text-white'>Buscando dados...</p>
                                                            </div>
                                                            :
                                                            hourArr().map(hour => {

                                                                let midiHour = hour.hour?.toString().includes('.5')
                                                                return <div
                                                                    id={`hour-${Math.floor(hour.hour)}`}
                                                                    ref={calendarRef}
                                                                    className={`w-full ${midiHour ? 'h-6 ' : 'h-6 border-t border-blue-100'} 
                                                                    cursor-pointer relative flex flex-row items-start justify-start
                                                                    select-none`}
                                                                    onDragOver={(event) => handleDragOver(event, hour.hour)}
                                                                    onDrop={handleDrop}
                                                                >
                                                                    <div className='p-3 border-r border-blue-100 min-w-24 w-24 h-full flex flex-col items-center justify-center'>
                                                                        {
                                                                            !midiHour ?
                                                                                <a className='text-xs text-zinc-700'>{hour.description}</a>
                                                                                :
                                                                                <a className='text-xs text-zinc-200'>{hour.description}</a>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        servicesList.map((space, index) => {
                                                                            const matchingSchedule = space.schedules.find(schedule => {
                                                                                if (schedule.startHour <= hour.hour && (schedule.finishHour >= hour.hour + .5 ||
                                                                                    (schedule.finishHour <= hour.hour + .5 && moment(schedule.finishDate).isAfter(moment(selectedDay))))
                                                                                    ||
                                                                                    (schedule.finishHour >= hour.hour + .5 && moment(schedule.startDate).isBefore(moment(selectedDay)))
                                                                                ) {
                                                                                    return true
                                                                                }
                                                                            })

                                                                            return showIndex.includes(index) &&
                                                                                <div
                                                                                    className={`
                                                                                        min-w-56 ${showIndex.length === 1 ? 'w-full' : 'w-48'} border-r border-blue-100 ${midiHour ? 'h-6' : 'h-6'} ${dragging ? 'cursor-grabbing' : ''} z-20    
                                                                                        ${hourSelected.finishHour === hour.hour + .5 && 'cursor-move'}                                                                                   
                                                                                        ${space.id === hourSelected?.service_id &&
                                                                                        (hourSelected.startHour <= hour.hour && hourSelected.finishHour >= hour.hour + .5) &&
                                                                                        'bg-green-100 outline outline-2 outline-green-100 shadow-lg translate-y-px'
                                                                                        } 
                                                                                    `}
                                                                                    key={space.id}
                                                                                    onClick={() => {
                                                                                        if (!matchingSchedule) {
                                                                                            setHourSelected({ startHour: hour.hour, finishHour: hour.hour + .5, service_id: space.id })
                                                                                        }
                                                                                    }}
                                                                                    onTouchStart={handleTouchStart}
                                                                                    onTouchEnd={() => handleTouchEnd(space, hour.hour)}
                                                                                    draggable={hourSelected.finishHour === hour.hour + .5}
                                                                                    onDragStart={() => hourSelected.finishHour === hour.hour + .5 && handleDragStart(hour.hour, space.id)}
                                                                                >
                                                                                    {
                                                                                        space.id === hourSelected?.service_id && hourSelected?.startHour === hour.hour &&
                                                                                        <a className="text-xs text-green-500">{hour.hour === hourSelected?.startHour && `${maskHours({ hour: hourSelected?.startHour })} - ${maskHours({ hour: hourSelected?.finishHour })}`}</a>
                                                                                    }
                                                                                    {

                                                                                        matchingSchedule &&
                                                                                        <div onClick={e => e.stopPropagation} className={`py-2 ${midiHour ? 'h-6' : 'h-6'} 
                                                                                                ${touchSchedule || showIndex.length === 1 ? 'w-full' : 'w-48'} min-w-48 border-l z-20 cursor-not-allowed border-blue-100 bg-blue-100
                                                                                                ${hour.hour === matchingSchedule.startHour && 'rounded-t-lg'} 
                                                                                                ${hour.hour + .5 === matchingSchedule.finishHour && 'rounded-b-lg shadow-md'}                                                                                            
                                                                                                bg-white flex items-center gap-1 justify-center`}>
                                                                                            <a className="text-xs text-blue-500">{hour.hour === matchingSchedule.startHour && `${maskHours({ hour: matchingSchedule.startHour })} - ${maskHours({ hour: matchingSchedule.finishHour })} Não disponível`}</a>
                                                                                            {
                                                                                                matchingSchedule.finishHour === hour.hour + .5 &&
                                                                                                <a className="text-xs text-blue-500">Não disponível</a>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            })
                                                    }
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}
