import { Container } from "../../../components/container/container";
import { Body } from '../../../components/container/Body';
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { useEffect, useState } from "react";
import { Button } from '../../../components/buttons/button.default';
import { useAuth } from "../../../contexts/useAuth";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { FaQuestionCircle } from "react-icons/fa";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { useThemeContext } from "../../../contexts/themeContext";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Footer } from "../../../components/footer/Footer";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { justNumber } from "../../../utils/validators/justNumber";
import { Input } from "../../../components/input/input";
import { validateFields } from "../../../utils/form.validator";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function FinancialRulesCreate() {

    const navigate = useNavigate()
    const { defaultCallsFinancial, api_financial } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { userData, setUserData } = useAuth()
    const [accountsList, setAccountsList] = useState([])
    const [loading, setLoading] = useState(false)
    const [rule, setRule] = useState({
        useOfxConciliation: false,
        online_cc_daysAheadToRecive: 0,
        online_cc_payment_method: "integral",
        online_pix_daysAheadToRecive: 0,
        local_cc_daysAheadToRecive: 0,
        local_cc_payment_method: "integral",
        local_dc_daysAheadToRecive: 0,
        local_pix_daysAheadToRecive: 0,
    })

    const paymentsMethods = [
        { name: 'Integral', value: 'integral' },
        { name: 'Parcelado', value: 'installments' },
    ]

    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        try {
            setLoading(true)
            const accountsData = await defaultCallsFinancial.getAccounts(userData[0]?.token)
            setAccountsList(accountsData.data)

            const rules = await defaultCallsFinancial.getRules(userData[0]?.token)
            if (rules.data?.length > 0) {
                setRule({ ...rules.data?.filter(rule => rule.active)[0] })
            }

        } catch (error) {
            const formatedError = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(formatedError || 'Erro inesperado')
        } finally {
            setLoading(false)
        }
    }

    async function handleSubmit() {

        const requiredFields = [
            { name: 'useOfxConciliation', value: [true, false].includes(rule?.useOfxConciliation), required: true, type: 'string' },
            { name: 'online_gateway_account_id', value: rule.online_gateway_account_id, required: userData[0]?.customersData?.hasGateway, type: 'string' },
            { name: 'online_cc_payment_method', value: rule.online_cc_payment_method, required: userData[0]?.customersData?.hasGateway, type: 'string' },
            { name: 'local_money_account_id', value: rule.local_money_account_id, required: true, type: 'string' },
            { name: 'local_payrool_account_id', value: rule.local_payrool_account_id, required: true, type: 'string' },
            { name: 'local_cc_account_id', value: rule.local_cc_account_id, required: true, type: 'string' },
            { name: 'local_cc_payment_method', value: rule.local_cc_payment_method, required: true, type: 'string' },
            { name: 'local_dc_account_id', value: rule.local_dc_account_id, required: true, type: 'string' },
            { name: 'local_pix_account_id', value: rule.local_pix_account_id, required: true, type: 'string' },
        ]

        if (rule?.useSendEmailForLateBills) {
            requiredFields.push(
                { name: 'quantityDaysAfterSendCharge', value: rule?.quantityDaysAfterSendCharge, required: true, type: 'string' }
            )
        }

        if (validateFields(requiredFields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowUniversalModal(false)
            setLoading(false)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        setLoading(true)
        try {
            await api_financial.post(`/api/v1/financial/rules`, {
                useOfxConciliation: rule?.useOfxConciliation,
                online_gateway_account_id: rule?.online_gateway_account_id,
                online_cc_daysAheadToRecive: rule?.online_cc_daysAheadToRecive,
                online_cc_payment_method: rule?.online_cc_payment_method,
                online_pix_daysAheadToRecive: rule?.online_pix_daysAheadToRecive,
                local_money_account_id: rule?.local_money_account_id,
                local_payrool_account_id: rule?.local_payrool_account_id,
                local_cc_account_id: rule?.local_cc_account_id,
                local_cc_payment_method: rule?.local_cc_payment_method,
                local_cc_daysAheadToRecive: rule?.local_cc_daysAheadToRecive,
                local_dc_account_id: rule?.local_dc_account_id,
                local_dc_daysAheadToRecive: rule?.local_dc_daysAheadToRecive,
                local_pix_account_id: rule?.local_pix_account_id,
                local_pix_daysAheadToRecive: rule?.local_pix_daysAheadToRecive
            }, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setUserData(
                [
                    {
                        ...userData[0],
                        customersData:
                        {
                            ...userData[0]?.customersData,
                            useOfxConciliation: rule?.useOfxConciliation,
                            blockedByFinancialRules: false
                        }
                    }
                ]
            )

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText(`Regra salva com sucesso`)
            return navigate(-1)

        } catch (error) {
            const formatedError = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(formatedError || 'Erro inesperado')
        } finally {
            setLoading(false)
        }
    }

    const boolOptions = [
        { id: true, value: 'Sim' },
        { id: false, value: 'Não' },
    ]

    return (
        <>
            <Container>
                <TitlePage>
                    <div className="flex flex-row justify-between w-full items-center">
                        <Title text='Regras de negócio'></Title>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className="w-full flex items-center justify-center"><DefaultLoader /></div>
                            :
                            <div className='flex flex-col gap-2 items-start justify-start w-full'>
                                <form className='flex flex-col items-start justify-start gap-4 w-full'>
                                    <LabelInput
                                        text={'Utiliza conciliação bancária com OFX'}
                                        required
                                        tippy={
                                            <Tippy content={'Informe Sim se deseja efetuar a concialiação bancária, com isso será possível importar OFX e conciliar dentro do Agenda Rápido.'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}
                                            >
                                                <div className='dark:text-titleGrayTextDark'>
                                                    <FaQuestionCircle />
                                                </div>
                                            </Tippy>}
                                    >
                                        <InputAutoComplete
                                            data={boolOptions}
                                            id={'useOfxConciliation'}
                                            preSelectedValue={boolOptions.find(opt => opt.id == rule.useOfxConciliation)?.value}
                                            onChange={(e) => {
                                                setRule(prev => ({ ...prev, useOfxConciliation: e.id }))
                                            }}
                                            selectedLabel={'value'}
                                            optionList={['value']}
                                        />
                                    </LabelInput>
                                    {
                                        userData[0]?.customersData?.hasGateway &&
                                        <>
                                            <div className="mt-6 w-full">
                                                <a className="text-primaryDefaultLight"> Pagamento online - Recebidos por meio de gateway no Agenda rápido</a>
                                                <hr className="border-px border-primaryDefaultLight"></hr>
                                            </div>
                                            <LabelInput
                                                text={'Conta de recebimento de pagamentos online'}
                                                required
                                                tippy={
                                                    <Tippy content={'Informe para qual conta o sistema deverá informar assim que uma reserva for informada como paga no módulo de reservas.'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}
                                                    >
                                                        <div className='dark:text-titleGrayTextDark'>
                                                            <FaQuestionCircle />
                                                        </div>
                                                    </Tippy>}
                                            >
                                                <InputAutoComplete
                                                    data={accountsList?.length > 0 ? accountsList?.filter(account => account.accountType === 'gateway') : []}
                                                    id={'online_gateway_account_id'}
                                                    preSelectedValue={accountsList.find(opt => opt.id == rule?.online_gateway_account_id)?.name}
                                                    onChange={(e) => {
                                                        setRule(prev => ({ ...prev, online_gateway_account_id: e.id }))
                                                    }}
                                                    selectedLabel={'name'}
                                                    optionList={['name']}
                                                />
                                            </LabelInput>
                                            {
                                                rule?.online_gateway_account_id &&
                                                <div className="flex flex-wrap gap-2">
                                                    <LabelInput
                                                        text={'Dias para recebimento no cartão de crédito'}
                                                        tippy={
                                                            <Tippy content={'Informe em quantos dias o dinheiro fica disponível na conta do gateway para cartão de crédito, caso ele fique disponível no mesmo dia, deixe em branco ou zero.'}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='top'
                                                                delay={100}
                                                            >
                                                                <div className='dark:text-titleGrayTextDark'>
                                                                    <FaQuestionCircle />
                                                                </div>
                                                            </Tippy>}
                                                        required>
                                                        <Input
                                                            value={rule?.online_cc_daysAheadToRecive}
                                                            onChange={(e) => setRule({ ...rule, online_cc_daysAheadToRecive: justNumber(e.target.value) })}
                                                        />
                                                    </LabelInput>

                                                    <LabelInput
                                                        text={'Forma de recebimento no crédito'}
                                                        tippy={
                                                            <Tippy content={'Informe como o gateway repassa o valor: Liberado integral, ou conforme quantidade de parcelas.'}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='top'
                                                                delay={100}
                                                            >
                                                                <div className='dark:text-titleGrayTextDark'>
                                                                    <FaQuestionCircle />
                                                                </div>
                                                            </Tippy>}
                                                        required>
                                                        <InputAutoComplete
                                                            data={paymentsMethods}
                                                            id={'online_cc_payment_method'}
                                                            preSelectedValue={paymentsMethods.find(opt => opt.value == rule?.online_cc_payment_method).name}
                                                            onChange={(e) => {
                                                                setRule(prev => ({ ...prev, online_cc_payment_method: e.value }))
                                                            }}
                                                            selectedLabel={'name'}
                                                            optionList={['name']}
                                                        />
                                                    </LabelInput>

                                                    <LabelInput
                                                        text={'Dias para recebimento no PIX'}
                                                        tippy={
                                                            <Tippy content={'Informe em quantos dias o dinheiro fica disponível na conta do gateway para PIX, caso ele fique disponível no mesmo dia, deixe em branco ou zero.'}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='top'
                                                                delay={100}
                                                            >
                                                                <div className='dark:text-titleGrayTextDark'>
                                                                    <FaQuestionCircle />
                                                                </div>
                                                            </Tippy>}
                                                        required>
                                                        <Input
                                                            value={rule?.online_pix_daysAheadToRecive}
                                                            onChange={(e) => setRule({ ...rule, online_pix_daysAheadToRecive: justNumber(e.target.value) })}
                                                        />
                                                    </LabelInput>
                                                </div>
                                            }
                                        </>

                                    }
                                    <div className="mt-6 w-full">
                                        <a className="text-primaryDefaultLight"> Pagamento Offline (local)</a>
                                        <hr className="border-px border-primaryDefaultLight"></hr>
                                    </div>
                                    <LabelInput
                                        text={'Conta de recebimento de pagamentos em dinheiro'}
                                        tippy={
                                            <Tippy content={'Informe para qual conta o sistema deverá informar assim que uma reserva for informada como paga no módulo de reservas.'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}
                                            >
                                                <div className='dark:text-titleGrayTextDark'>
                                                    <FaQuestionCircle />
                                                </div>
                                            </Tippy>}
                                        required>
                                        <InputAutoComplete
                                            data={accountsList?.length > 0 ? accountsList?.filter(account => account.accountType === 'cash') : []}
                                            id={'local_money_account_id'}
                                            preSelectedValue={accountsList.find(opt => opt.id == rule?.local_money_account_id)?.name}
                                            onChange={(e) => {
                                                setRule(prev => ({ ...prev, local_money_account_id: e.id }))
                                            }}
                                            selectedLabel={'name'}
                                            optionList={['name']}
                                        />
                                    </LabelInput>

                                    <LabelInput text={'Conta de recebimento de pagamentos desconto em folha'}
                                        tippy={
                                            <Tippy content={'Informe para qual conta o sistema deverá informar assim que uma reserva for informada como paga no módulo de reservas.'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}
                                            >
                                                <div className='dark:text-titleGrayTextDark'>
                                                    <FaQuestionCircle />
                                                </div>
                                            </Tippy>}
                                        required>
                                        <InputAutoComplete
                                            data={accountsList?.length > 0 ? accountsList?.filter(account => account.accountType === 'bank') : []}
                                            id={'local_payrool_account_id'}
                                            preSelectedValue={accountsList.find(opt => opt.id == rule?.local_payrool_account_id)?.name}
                                            onChange={(e) => {
                                                setRule(prev => ({ ...prev, local_payrool_account_id: e.id }))
                                            }}
                                            selectedLabel={'name'}
                                            optionList={['name']}
                                        />
                                    </LabelInput>

                                    <div className="flex flex-wrap gap-2">
                                        <LabelInput text={'Conta de recebimento de pagamentos cartão de crédito'}
                                            tippy={
                                                <Tippy content={'Informe para qual conta o sistema deverá informar assim que uma reserva for informada como paga no módulo de reservas.'}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}
                                                >
                                                    <div className='dark:text-titleGrayTextDark'>
                                                        <FaQuestionCircle />
                                                    </div>
                                                </Tippy>}
                                            required>
                                            <InputAutoComplete
                                                data={accountsList?.length > 0 ? accountsList?.filter(account => ["gateway", "bank"].includes(account.accountType)) : []}
                                                id={'local_cc_account_id'}
                                                preSelectedValue={accountsList.find(opt => opt.id == rule?.local_cc_account_id)?.name}
                                                onChange={(e) => {
                                                    setRule(prev => ({ ...prev, local_cc_account_id: e.id }))
                                                }}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                            />
                                        </LabelInput>

                                        <LabelInput
                                            text={'Forma de recebimento no crédito'}
                                            tippy={
                                                <Tippy content={'Informe como o gateway repassa o valor: Liberado integral, ou conforme quantidade de parcelas.'}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}
                                                >
                                                    <div className='dark:text-titleGrayTextDark'>
                                                        <FaQuestionCircle />
                                                    </div>
                                                </Tippy>}
                                            required>
                                            <InputAutoComplete
                                                data={paymentsMethods}
                                                id={'local_cc_payment_method'}
                                                preSelectedValue={paymentsMethods.find(opt => opt.value == rule?.local_cc_payment_method)?.name}
                                                onChange={(e) => {
                                                    setRule(prev => ({ ...prev, local_cc_payment_method: e.value }))
                                                }}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                            />
                                        </LabelInput>

                                        {
                                            rule?.local_cc_account_id &&
                                            <LabelInput text={'Dias para recebimento'}
                                                tippy={
                                                    <Tippy content={'Informe em quantos dias o dinheiro fica disponível na conta, caso ele fique disponível no mesmo dia, deixe em branco ou zero.'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}
                                                    >
                                                        <div className='dark:text-titleGrayTextDark'>
                                                            <FaQuestionCircle />
                                                        </div>
                                                    </Tippy>}
                                                required>
                                                <Input
                                                    value={rule?.local_cc_daysAheadToRecive}
                                                    onChange={(e) => setRule({ ...rule, local_cc_daysAheadToRecive: justNumber(e.target.value) })}
                                                />
                                            </LabelInput>
                                        }
                                    </div>

                                    <div className="flex flex-wrap gap-2">
                                        <LabelInput text={'Conta de recebimento de pagamentos cartão de débito'}
                                            tippy={
                                                <Tippy content={'Informe para qual conta o sistema deverá informar assim que uma reserva for informada como paga no módulo de reservas.'}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}
                                                >
                                                    <div className='dark:text-titleGrayTextDark'>
                                                        <FaQuestionCircle />
                                                    </div>
                                                </Tippy>}
                                            required>
                                            <InputAutoComplete
                                                data={accountsList?.length > 0 ? accountsList?.filter(account => ["gateway", "bank"].includes(account?.accountType)) : []}
                                                id={'local_dc_account_id'}
                                                preSelectedValue={accountsList.find(opt => opt.id == rule?.local_dc_account_id)?.name}
                                                onChange={(e) => {
                                                    setRule(prev => ({ ...prev, local_dc_account_id: e.id }))
                                                }}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                            />
                                        </LabelInput>
                                        {
                                            rule?.local_dc_account_id &&
                                            <LabelInput text={'Dias para recebimento'}
                                                tippy={
                                                    <Tippy content={'Informe em quantos dias o dinheiro fica disponível na conta, caso ele fique disponível no mesmo dia, deixe em branco ou zero.'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}
                                                    >
                                                        <div className='dark:text-titleGrayTextDark'>
                                                            <FaQuestionCircle />
                                                        </div>
                                                    </Tippy>}
                                                required>
                                                <Input
                                                    value={rule?.local_dc_daysAheadToRecive}
                                                    onChange={(e) => setRule({ ...rule, local_dc_daysAheadToRecive: justNumber(e.target.value) })}
                                                />
                                            </LabelInput>
                                        }
                                    </div>
                                    <div className="flex flex-wrap gap-2">

                                        <LabelInput text={'Conta de recebimento de pagamentos PIX'}
                                            tippy={
                                                <Tippy content={'Informe para qual conta o sistema deverá informar assim que uma reserva for informada como paga no módulo de reservas.'}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}
                                                >
                                                    <div className='dark:text-titleGrayTextDark'>
                                                        <FaQuestionCircle />
                                                    </div>
                                                </Tippy>}
                                            required>
                                            <InputAutoComplete
                                                data={accountsList?.length > 0 ? accountsList?.filter(account => ["gateway", "bank"].includes(account?.accountType)) : []}
                                                id={'local_pix_account_id'}
                                                preSelectedValue={accountsList.find(opt => opt.id == rule?.local_pix_account_id)?.name}
                                                onChange={(e) => {
                                                    setRule(prev => ({ ...prev, local_pix_account_id: e.id }))
                                                }}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                            />
                                        </LabelInput>
                                        {
                                            rule?.local_pix_account_id &&
                                            <LabelInput text={'Dias para recebimento'}
                                                tippy={
                                                    <Tippy content={'Informe em quantos dias o dinheiro fica disponível na conta, caso ele fique disponível no mesmo dia, deixe em branco ou zero.'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}
                                                    >
                                                        <div className='dark:text-titleGrayTextDark'>
                                                            <FaQuestionCircle />
                                                        </div>
                                                    </Tippy>}
                                                required>
                                                <Input
                                                    value={rule?.local_pix_daysAheadToRecive}
                                                    onChange={(e) => setRule({ ...rule, local_pix_daysAheadToRecive: justNumber(e.target.value) })}
                                                />
                                            </LabelInput>
                                        }
                                    </div>

                                </form>
                            </div>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate(-1)}>Cancelar</Button>
                        <div className="flex flex-row items-center justify-center gap-2">
                            <Button shadow={true} approval={true} onClick={() => handleSubmit()}>{'Salvar'}</Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )

}
