import React, { useEffect, useState } from 'react'
//contexts
import { useThemeContext } from '../../../../contexts/themeContext'
import { useAuth } from '../../../../contexts/useAuth'
import { useScreenSizeContext } from '../../../../contexts/screenSizeContext'
//servies
import { Container } from '../../../../components/container/container'
import { TitlePage } from '../../../../components/titlePages/title.page'
import { Body } from '../../../../components/container/Body'
import { DefaultLoader } from '../../../../components/loaders/defaultLoader'
import { LabelInput } from '../../../../components/label/label.input'
import { Title } from '../../../../components/titlePages/title'
import { InputAutoComplete } from '../../../../components/input/input.autocomplete'
import { Button } from '../../../../components/buttons/button.default'
import moment from 'moment'
import { Input } from '../../../../components/input/input'
import { responseError } from '../../../../utils/responsesFunctions/error.response'
import { useApiContext } from '../../../../contexts/ApiInterceptorContext'

export function BalanceEntriesReport() {

    const { defaultCallsAuth, api} = useApiContext()
    const { userData } = useAuth()
    const { screenX } = useScreenSizeContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [loading, setLoading] = useState(false)

    const [associationsList, setAssociationsList] = useState([])

    const [selectedAssociation, setSelectedAssociation] = useState(null)
    const [selectedReportType, setSelectedReportType] = useState('xlsx')
    const [selectedEntryType, setSelectedEntryType] = useState(null)
    const [selectedStartDate, setSelectedStartDate] = useState(moment().startOf("month").format('YYYY-MM-DD'))
    const [selectedFinishDate, setSelectedFinishDate] = useState(moment().endOf("month").format('YYYY-MM-DD'))

    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        setLoading(true)
        const associationsListAPI = await defaultCallsAuth.getAssociations(userData[0]?.token, 1)
        setAssociationsList(associationsListAPI.data)
        setLoading(false)
    }

    async function getReport() {        
        try {
            const startDate = selectedStartDate ? moment(selectedStartDate) : moment().startOf('month')
            const finishDate = selectedFinishDate ? moment(selectedFinishDate) : moment().endOf('month')
    
            if(!selectedStartDate && !selectedFinishDate){
                setSelectedStartDate(startDate.format('YYYY-MM-DD'))
                setSelectedFinishDate(finishDate.format('YYYY-MM-DD'))
            }
    
            if (finishDate.diff(startDate, 'days') > 31) {
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Perído máximo é de 31 dias')
            }

            setLoading(true)

            const formatQueryParam = []

            if(selectedReportType) formatQueryParam.push(`reportType=${selectedReportType}`)
            if(selectedAssociation) formatQueryParam.push(`associations_id=${selectedAssociation}`)
            if(selectedEntryType) formatQueryParam.push(`entryType=${selectedEntryType}`)
            if(selectedStartDate) formatQueryParam.push(`startDate=${startDate.format('YYYY-MM-DD')}`)
            if(selectedFinishDate) formatQueryParam.push(`finishDate=${finishDate.format('YYYY-MM-DD')}`)
         
            const report = await api.get(`/api/v1/reports/balanceEntries${formatQueryParam.length ? '?'+formatQueryParam.join('&') : ''}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                },
                responseType: 'blob'
            })

            if (report.status == 204) {
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText('Nenhum dado encontrado para esta pesquisa')
                setLoading(false)
                return
            }
            setLoading(false)
            await new Promise(resolve => setTimeout(resolve, 100))
            const blob = report?.data // Seu Blob recebido
            const blobURL = URL.createObjectURL(blob);

            const downloadLink = document.getElementById('downloadLink');
            downloadLink.href = blobURL;
            downloadLink.download = `relatorio_total_saldos-${moment().format("DD/MM/YYYY")}_
            ${moment().hour()}_${moment().minutes()}_${moment().seconds()}.${selectedReportType}`;
            downloadLink.click();

            return URL.revokeObjectURL(blobURL);

        } catch (error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(responseError(error))
            setLoading(false)
        }
    }

    const reportOptions = [
        { id: 0, name: 'Excel', extension: 'xlsx' },
        { id: 1, name: 'PDF', extension: 'pdf' }
    ]

    const entryTypeOptions = [
        { id: 'C', name: 'Crédito' },
        { id: 'D', name: 'Débito' }
    ]

    return (
        <>
            <Container>
                <TitlePage>
                    <Title text={'Relatório de lançamentos/movimentações do saldo'} />
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className="w-full h-96 flex items-center justify-center">
                                <DefaultLoader />
                            </div>
                            :
                            <div className="flex flex-col gap-2 w-full">
                                <LabelInput text={'Data início *'}>
                                    <Input type={'date'} value={selectedStartDate} onChange={(e) => { setSelectedStartDate(e.target.value); setShowNotificationModal(false) }}></Input>
                                </LabelInput>
                                <LabelInput text={'Data fim *'}>
                                    <Input type={'date'} value={selectedFinishDate} onChange={(e) => { setSelectedFinishDate(e.target.value); setShowNotificationModal(false) }}></Input>
                                </LabelInput>
                                <LabelInput text={'Tipo/Associação'}>
                                    <InputAutoComplete 
                                        preSelectedValue={associationsList.find(a => a.id === selectedAssociation)?.description} 
                                        data={associationsList} 
                                        optionList={['description']} 
                                        selectedLabel={'description'} 
                                        onChange={(e) => setSelectedAssociation(e.id)} 
                                    />
                                </LabelInput>
                                <LabelInput text={'Tipo de lançamento'}>
                                    <InputAutoComplete 
                                        preSelectedValue={entryTypeOptions.find(r => r.id == selectedEntryType)?.name} 
                                        data={entryTypeOptions} 
                                        optionList={['name']} 
                                        selectedLabel={'name'} 
                                        onChange={(e) => setSelectedEntryType(e.id)} 
                                    />
                                </LabelInput>
                                <LabelInput text={'Tipo de relatório'}>
                                    <InputAutoComplete 
                                        preSelectedValue={reportOptions.find(r => r.extension == selectedReportType)?.name} 
                                        data={reportOptions} 
                                        optionList={['name']} 
                                        selectedLabel={'name'} 
                                        onChange={(e) => setSelectedReportType(e.extension)} 
                                    />
                                </LabelInput>
                                <a id="downloadLink"></a>
                                <div className="w-full sm:w-48 mt-6">
                                    <Button onClick={() => getReport()}>Download</Button>
                                </div>
                            </div>
                    }
                </Body>
            </Container>
        </>
    )
}