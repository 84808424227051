import React, { useEffect, useState } from 'react'
import { getCardToken } from '../../../../services/paymentAPIs/stone/stoneAPI'
import { FaMapMarkerAlt, FaCalendar, FaCheck, FaCopy, FaCircleNotch, FaExclamationCircle } from 'react-icons/fa'
//components
import { Container } from '../../../../components/container/container'
import { TitlePage } from '../../../../components/titlePages/title.page'
import { Title } from '../../../../components/titlePages/title'
import { Body } from '../../../../components/container/Body'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { LabelInput } from '../../../../components/label/label.input'
import { InputAutoComplete } from '../../../../components/input/input.autocomplete'
import { Input } from '../../../../components/input/input'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { Quillmodules } from '../../../../components/richText/quill.modules'
import { SwiperContainer } from '../../../../components/swiper/swiper'
//images
import pdf from '../../../../assets/logos/pdf-45.png'
import activitieDefaultImg from '../../../../assets/default_assets/activitie_default.webp'
import credit1 from '../../../../assets/logos/credit-1.webp'
import credit2 from '../../../../assets/logos/credit-2.webp'
import credit3 from '../../../../assets/logos/credit-3.webp'
import credit4 from '../../../../assets/logos/credit-4.webp'
import credit5 from '../../../../assets/logos/credit-5.webp'
import cardChip from '../../../../assets/reservation/chip.png'
import servicesImg from '../../../../assets/default_assets/soccer.jpeg'
//contexts
import { useThemeContext } from '../../../../contexts/themeContext'
import { formattTimeToShow } from '../../../../services/api/callAPIsFunctions/defaultCalls.api'
import { useAuth } from '../../../../contexts/useAuth'
import { DefaultLoader } from '../../../../components/loaders/defaultLoader'
import { InputCheckBox } from '../../../../components/input/input.checkBox'
import { useNavigate, useParams } from 'react-router-dom'
import { useScreenSizeContext } from '../../../../contexts/screenSizeContext'
import { SwiperFullWidthContainer } from '../../../../components/swiper/swiperFullWidth'
import { numberInstallments } from '../../../../utils/cardObjects/numberInstallments'
//utils
import { convertURLtoFile } from '../../../../utils/formatImages/convertToUrl'
import { Footer } from '../../../../components/footer/Footer'
import { Button } from '../../../../components/buttons/button.default'
import moment from 'moment'
import { justNumber } from '../../../../utils/validators/justNumber'
import { maskCardNumber } from '../../../../utils/mask/maskCardNumber'
import { currencyPtBr } from '../../../../utils/formatCurrency/currencyTextPTBR'
import { ScreenModal } from '../../../../components/modals/notification/screenModal'
import { cpfIsValid } from '../../../../utils/validators/cpfIsValid'
import Timer from '../../../../components/timer/timer'
import { responseError } from '../../../../utils/responsesFunctions/error.response'
import { copyToClipboard } from '../../../../utils/copyClipboard'
import { useApiContext } from '../../../../contexts/ApiInterceptorContext'
export function ActivitiesActionsCreateClients() {

    const { api, defaultCallsSchedules } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalText, setShowNotificationModalSuccess, setShowUniversalModal } = useThemeContext()
    const { screenX } = useScreenSizeContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [typeModal, setTypeModal] = useState('')
    const [loading, setLoading] = useState(true)
    const [loadingPlan, setLoadingPlan] = useState(false)
    const [loadingPhotos, setLoadingPhotos] = useState(false)

    const [activePage, setActivePage] = useState(0)
    const [activitiesList, setActivitiesList] = useState([])
    const [activitiesPlansList, setActivitiesPlansList] = useState([])
    const [plansOfSelectedActivitie, setPlansOfSelectedActivitie] = useState([])
    const [activitiesPhotos, setActivitiesPhotos] = useState([])
    const [regulationFile, setRegulationFile] = useState([])
    const [paymentOption, setPaymentOption] = useState([])
    const [installmentsForThisActivitie, setInstallmentsForThisActivitie] = useState([])

    const [selectedCardNumber, setSelectedCardNumber] = useState('')
    const [selectedCardDueDateMonth, setSelectedCardDueDateMonth] = useState('')
    const [selectedCardDueDateYear, setSelectedCardDueDateYear] = useState('')
    const [selectedCardPersonName, setSelectedCardPersonName] = useState('')
    const [selectedCardCVVNumber, setSelectedCardCVVNumber] = useState('')
    const [userSelectedCPF, setUserSelectedCPF] = useState('')
    const [copied, setCopied] = useState(false)
    const [selectedInstallments, setSelectedInstallments] = useState('')

    const [selectedActivitie, setSelectedAcitvitie] = useState('')
    const [selectedPlan, setSelectedPlan] = useState('')
    const [selectedAcceptedTerm, setSelectedAcceptedTerm] = useState(false)

    const [selectedPayment, setSelectedPayment] = useState('')
    const thisCustomerHasGateway = userData[0].customersData.hasGateway
    const [dontHavePaymentMethods, setDontHavePaymentMethods] = useState(false)
    const [termOfUseDescription, setTermsOfUseDescription] = useState('')

    const [seconds, setSeconds] = useState(900);
    const [remaingSeconds, setRemaingSeconts] = useState(900)
    const [startTimer, setStartTimer] = useState(false)
    const [loadingPayment, setLoadingPayment] = useState(false)
    const [startFindPaymentStatus, setStartFindPaymentStatus] = useState(false)
    const [startPaymentSearch, setStartPaymentSearch] = useState(false)
    const [paymentId, setPaymentId] = useState('')
    const [gatewayData, setGatewayData] = useState([])
    const [paymentStatus, setPaymentStatus] = useState(3)
    const paramsPaymentId = useParams()
    const [activitiesNotPermitted, setActivitiesNotPermitted] = useState('')
    const minutesDisplay = Math.floor(seconds / 60).toString().padStart(2, '0');
    const secondsDisplay = (seconds % 60).toString().padStart(2, '0');
    const progress = (seconds / (15 * 60)) * 100;
    if (progress === 0) {
        handleTimeIsOver(true)
    }

    //termina o tempo de pagament
    function handleTimeIsOver(over) {
        if (over && activeStep?.id !== 2) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Tempo esgotado, efetue a escolha do espaço novamente')
            localStorage.setItem('@activitiesActionsCreateFailed', paymentId)
            navigate(`/activitiesActionsCreateFailed/${paymentId}`)
        }
    }

    //busca status do pagamento a cada 15s
    useEffect(() => {
        const fetchData = async () => {
            const result = await defaultCallsSchedules.getExternalPaymentStatus(userData[0]?.token, paramsPaymentId?.paymentId)

            setPaymentStatus(result?.data[0]?.status)
            if (result?.data[0]?.status !== 3) {
                setStartTimer(false)
                clearInterval(interval)
                setActivePage(3)
            }
        }
        let interval
        if (startFindPaymentStatus) {
            interval = setInterval(() => {
                fetchData();
            }, 15000);

            return () => clearInterval(interval);
        } else {
            clearInterval(interval)
        }
    }, [startFindPaymentStatus]);

    //atualiza o timer
    useEffect(() => {
        let now = moment(Date.now())
        let timer

        if (startTimer) {

            now = moment(Date.now())

            timer = setInterval(() => {
                let diff = parseInt(Math.round(now.diff(moment(Date.now()), 'secons')?.toString().slice(0, 1)))
                setSeconds(seconds => seconds > 0 ? remaingSeconds + now.diff(moment(Date.now()), 'seconds') : 0);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        } else {
            clearInterval(timer)
        }
    }, [startTimer]);

    async function formatAPIPhoto(apiData, all) {

        let photosArray = []
        if (apiData.activitiesPictures?.length > 0) {
            try {
                await Promise.all(apiData.activitiesPictures.map(async (picture, index) => {
                    const file = await api.get(`api/v1/activitiesPictures/activities_id/${apiData.id}/picture/${picture.picture}`, {
                        responseType: 'blob',
                        responseEncoding: 'base64',
                        headers: {
                            Authorization: `Bearer ${userData[0].token}`
                        }
                    })
                    const fileObj = { picture: URL.createObjectURL(file.data), id: index }
                    photosArray.push(fileObj)
                }))
            } catch (error) {
                console.log('> Pictures not found')
            }
        }
        return photosArray
    }

    async function getData() {

        setLoading(true)
        if (userData[0]?.customersData?.blockedByFinancialRules) {
            setActivitiesNotPermitted('financialRules')
        }
        const activitiesData = await defaultCallsSchedules.getActivities(userData[0].token)
        const activitiesPlansData = await defaultCallsSchedules.getActivitiesPlans(userData[0].token, 1)
        const myPendings = await defaultCallsSchedules.getActivitiesSubscriptionsPendings(userData[0]?.token)

        if (activitiesData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(activitiesData.data)
            return
        }

        if (activitiesPlansData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(activitiesPlansData.data)
            return
        }

        if (myPendings.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(myPendings.data)
            return
        }
        const paymentsDataList = await defaultCallsSchedules.getPaymentMethods(userData[0].token)
        if (paymentsDataList.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(paymentsDataList.data)
            return
        }
        if (paymentsDataList.data?.length > 0) {
            const paymentMethodsForClient = paymentsDataList.data.filter(paymentOption => paymentOption.forClient && paymentOption?.modules?.includes(2) && paymentOption?.methodKey !== 'D')
            setPaymentOption(paymentMethodsForClient)

        } else {
            setDontHavePaymentMethods(true)
        }
        //Verifica se tem algum pagamento pendente de inscrição
        if (myPendings.data?.activitiesSubscriptions?.length > 0) {
            let activitie = myPendings.data?.activitiesSubscriptions[0]
            let payment = myPendings.data?.payment[0]
            setSelectedInstallments({ id: payment?.numberOfInstallments })
            setSelectedAcitvitie({ activitiesName: activitie?.activitiesName })
            setSelectedPlan({ value: payment?.totalValue })
            setRemaingSeconts(parseInt(payment?.expires_in))

            if (payment?.paymentMethod === 'P' && payment?.status === 3) {
                setSelectedPayment({ methodKey: "P" })
                setLoadingPayment(true)
                setLoading(false)
                setTypeModal('P')
                handlePaymentPixCheckout(payment?.id)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(true)
                return setShowNotificationModalText('Voce tem uma inscrição em andamento, caso ja tenha efetuado o pagamento, aguarde que o mesmo será processado')
            }
            //se pagamento for cartao de credito
            if (payment?.paymentMethod === 'C' && payment?.status === 3) {
                setSelectedPayment({ methodKey: "C" })
                setLoadingPayment(true)
                setTypeModal('C')
                setLoading(false)
                navigate(`/activitiesActionsCreate/${payment?.id ? payment?.id : paymentId}`)
                setStartFindPaymentStatus(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(true)
                return setShowNotificationModalText('Voce tem uma inscrição em andamento, caso ja tenha efetuado o pagamento, aguarde que o mesmo será processado')
            }
        }

        try {
            let activitiesDetailed = []
            let thisActivities = activitiesData.data.filter(activitie => {
                if (activitie.active && activitie?.openForSubscriptions && !activitie?.isSchool) {
                    return activitie
                }
            })
            let plansForThisAssociation = []

            await Promise.all(thisActivities.map(async (activitie) => {
                const thisActivitieDetail = await api.get(`/api/v1/activities/${activitie.id}`, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })

                let thisAssociationIncluded = false

                if (!Object.keys(thisActivitieDetail?.data.data[0]?.termOfUse).includes('ops')) {
                    thisActivitieDetail.data.data[0].termOfUse = { ops: '' }
                }

                if (thisActivitieDetail?.data?.data[0]?.activitiesPlans?.length > 0) {
                    thisActivitieDetail?.data.data[0]?.activitiesPlans.map(plan => {

                        const planForThisAssociation = plan.valuesPerAssociations.filter(planAssociation => planAssociation.associations_id === userData[0]?.associations_id)

                        if (planForThisAssociation?.length > 0) {
                            thisAssociationIncluded = true
                            plan = { ...plan, value: planForThisAssociation[0]?.value }
                            plansForThisAssociation.push(plan)
                        }
                    })
                }
                if (thisActivitieDetail?.data?.data[0]?.activitiesPictures?.length > 0) {
                    const thisPicture = await defaultCallsSchedules.getActivitiesPictures(userData[0].token, activitie.id, thisActivitieDetail?.data?.data[0]?.activitiesPictures[0]?.picture)
                    thisActivitieDetail.data.data[0].picturesURL = [URL.createObjectURL(thisPicture.data)]
                } else {
                    thisActivitieDetail.data.data[0].picturesURL = [activitieDefaultImg]
                }

                activitiesDetailed.push(thisActivitieDetail.data.data[0])
                if (thisAssociationIncluded) {
                    setActivitiesPlansList(plansForThisAssociation)
                }
            }))

            setActivitiesList(activitiesDetailed)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    function validateCardFields() {

        if (selectedCardNumber === '' || selectedCardNumber?.length < 16) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Prencha todos os numeros do cartao')
            return false
        }
        if (selectedCardCVVNumber === '' || selectedCardCVVNumber?.length < 3) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Preencha o codigo CVV, são no mínimo 3 digitos')
            return false
        }
        if (selectedCardPersonName === '' || selectedCardPersonName?.length < 4) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Preencha o nome como está gravado no cartão')
            return false
        }
        if (selectedCardDueDateMonth === '') {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Preencha o mês de vencimento do cartão')

            return false
        }
        if (selectedCardDueDateYear === '') {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Preencha o ano de vencimento do cartão')
            return false
        }
        return true
    }

    //Pagamentos com folha de pagamento
    async function handlePaymentWithPayRoll() {

        let paymentData

        setActivePage(prev => (prev + 1))
        setLoadingPayment(true)
        setTypeModal('F')
        try {
            //cria nova inscriçao - aguardando pagamento            
            paymentData = await handleSubmit()
            const paymentIdThisOperation = paymentData.data?.data?.payments[0]?.id

            //confirma pagamento
            const newPayment = await api.post(`/api/v1/payments/${paymentIdThisOperation}`, {
                paymentMethod: selectedPayment.methodKey,
                useBalance: false
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setCopied(false)
            setPaymentStatus(newPayment.data.data[0]?.status)
            setActivePage(prev => (prev + 1))
            setLoadingPayment(false)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    //pagar.me Payment functions
    //pagamento com cartão de crédito
    async function handlePaymentCardCheckout(payId) {
        if (!userSelectedCPF) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Obrigatório informar CPF para pagamentos em Cartão')
            const cpfElement = document.getElementById('userSelectedCPF')
            cpfElement.style.border = '1px solid red'
            return { error: true }
        }

        if (!selectedInstallments) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Obrigatório informar o número de parcelas')
            const cpfElement = document.getElementById('userSelectedCPF')
            cpfElement.style.border = '1px solid red'
            return { error: true }

        }
        setLoadingPayment(true)
        setTypeModal('C')
        setActivePage(prev => (prev + 1))

        //solicita tokenCard a Pagar.me
        let cardToken = ''
        try {
            const tokenPayment = await getCardToken({
                card: {
                    number: selectedCardNumber?.replace(/\s/g, ''),
                    holder_name: selectedCardPersonName,
                    exp_month: selectedCardDueDateMonth,
                    exp_year: selectedCardDueDateYear,
                    cvv: selectedCardCVVNumber,
                    "billing_address": {
                        "line_1": "-",
                        "zip_code": "00000000",
                        "city": "XX",
                        "state": "XX",
                        "country": "BR"
                    }
                },
                appId: userData[0].customersData?.paymentGateway?.client_public
            })
            cardToken = tokenPayment?.data
        } catch (error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setPaymentStatus(3)
            setLoadingPayment(false)
            setActivePage(prev => (prev + 1))
            setTypeModal('E')
            setShowNotificationModalText('Erro com os dados do cartão, verifique os dados informados!')
            return { error: true }
        }
        //caso pagarme não reconheça o cartão
        if (!cardToken) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setPaymentStatus(3)
            setLoadingPayment(false)
            setActivePage(prev => (prev + 1))
            setTypeModal('E')
            setShowNotificationModalText('Erro com os dados do cartão, verifique os dados informados!')
            return { error: true }
        }

        let paymentData

        try {
            //cria nova inscriçao - aguardando pagamento            
            paymentData = await handleSubmit()
        } catch (error) {
            setLoadingPayment(false)
            setLoading(false)
            setStartFindPaymentStatus(false)
            setStartTimer(false)
            setTypeModal('LocalError')
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
        const paymentIdThisOperation = paymentData.data?.data?.payments[0]?.id
        try {

            setStartPaymentSearch(true)

            //solicita pagamento com cartão de crédito
            const newCardPayment = await api.post(`/api/v1/payments/${paymentData.data?.data?.payments[0]?.id}`, {
                paymentMethod: "C",
                useBalance: false,
                numberOfInstallments: selectedInstallments?.id,
                cpf: userSelectedCPF,
                cardToken: cardToken?.id
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            if (newCardPayment?.data?.data[0]?.status_descripton === 'failed') {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setTypeModal('E')
                return setShowNotificationModalText('Ocorreu um erro ao tentar se comunicar com o serviço de pagamento')
            }
            setCopied(false)
            setGatewayData(newCardPayment?.data?.data[0]?.gatewayData)
            navigate(`/activitiesActionsCreate/${payId ? payId : paymentIdThisOperation}`)
            setPaymentStatus(newCardPayment?.data?.data[0]?.status)
            setStartFindPaymentStatus(true)
            return { error: false }

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText(responseError(error))
                return { error: true }
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText('Erro inesperado')
                return { error: true }
            }
        }
    }

    //pagamento com PIX
    async function handlePaymentPixCheckout(payId) {

        if (!payId && !cpfIsValid(userSelectedCPF)) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('CPF digitado é inválido, verifique os dados informados')
            const cpfElement = document.getElementById('userSelectedCPF')
            cpfElement.style.border = '1px solid red'
            return { error: true }
        }

        // se nao veio id de pagamento (quando usuario pressiona F5) e ele nao digitou o CPF
        // CPF é obrigatorio no primeiro envio
        if (!payId && !userSelectedCPF) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Obrigatório informar CPF para pagamentos em Cartão')
            const cpfElement = document.getElementById('userSelectedCPF')
            cpfElement.style.border = '1px solid red'
            return
        }
        setShowNotificationModal(false)
        try {

            setStartTimer(true)
            let paymentData
            setTypeModal('P')
            setLoadingPayment(true)
            setActivePage(2)
            if (!payId) {
                try {
                    //gera nova inscrição
                    paymentData = await handleSubmit()

                } catch (error) {
                    setLoadingPayment(false)
                    setLoading(false)
                    setStartFindPaymentStatus(false)
                    setStartTimer(false)
                    setTypeModal('LocalError')
                    if (responseError(error).length > 0) {
                        setLoading(false)
                        setShowNotificationModalSuccess(false)
                        setShowNotificationModal(true)
                        return setShowNotificationModalText(responseError(error))
                    } else {
                        setTypeModal('LocalError')
                        setLoading(false)
                        setShowNotificationModalSuccess(false)
                        setShowNotificationModal(true)
                        return setShowNotificationModalText('Erro inesperado')
                    }
                }
            }

            const paymentIdThisOperation = payId ? payId : paymentData.data?.data?.payments[0]?.id
            setLoadingPayment(true)
            setStartPaymentSearch(true)

            //solicita codigo PIX para pagar.me
            const newPixPayment = await api.post(`/api/v1/payments/${payId ? payId : paymentIdThisOperation}`, {
                paymentMethod: "P",
                cpf: payId ? '82233730097' : userSelectedCPF,
                pix_expires_in: seconds,
                useBalance: false
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            if (newPixPayment?.data?.data[0]?.status_descripton === 'failed') {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setTypeModal('E')
                return setShowNotificationModalText('Ocorreu um erro ao tentar se comunicar com o serviço de pagamento')
            }
            setCopied(false)
            setGatewayData(newPixPayment?.data?.data[0]?.gatewayData)
            navigate(`/activitiesActionsCreate/${payId ? payId : paymentIdThisOperation}`)
            setLoadingPayment(false)
            setLoading(false)
            setStartFindPaymentStatus(true)

        } catch (error) {
            setShowUniversalModal(false)
            setLoadingPayment(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Ocorreu um erro ao solicitar o codigo PIX, tente clicar novamente em pagar com pix, se o problema persistir, acessa a aba minhas reservas, anote o id da reserva e contate a secretaria')
            console.log(error)
        }
    }

    async function handleSubmit() {

        let apiData = {
            activities_id: selectedActivitie?.id,
            activities_plans_id: selectedPlan?.id,
            numberOfInstallments: selectedInstallments ? selectedInstallments?.id : 1,
            paymentMethod: selectedPayment?.methodKey
        }

        const payment = await api.post(`/api/v1/activitiesSubscriptions/client/`,
            apiData, {
            headers: {
                Authorization: `Bearer ${userData[0]?.token}`
            }
        })
        return payment

    }

    //regulation
    async function resolveAndDownloadBlob(response, nameFile, originalName, id) {
        let filename = `${originalName}`;

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const file = await convertURLtoFile(url, filename)

        const link = document.createElement('a')
        const urlToDownload = URL.createObjectURL(file)

        link.href = urlToDownload
        link.download = file.name
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(urlToDownload)
    }

    async function handleDownloadRegulation() {

        const file = await defaultCallsSchedules.getActivitiesRegulation(userData[0].token, selectedActivitie.id)
        await resolveAndDownloadBlob(file, 'Regulamento', 'regulamento.pdf', 1)
    }

    async function handleNextPage() {

        if (activePage === 1) {
            //se selecioando folha de pagamento ira entrar como pago
            if (selectedPayment?.methodKey === "F") {
                return await handlePaymentWithPayRoll()
            }
            //se selecionado cartao de credito e o customer tem gateway, valida os dados do cartao e chama a funcao para pagamento com cartao (pagarme)
            if (selectedPayment?.methodKey === 'C' && thisCustomerHasGateway) {
                if (!validateCardFields()) {
                    return
                }

                setShowUniversalModal(true)
                setTypeModal('C')

                return
            }
            //se selecionado Pix e o customer tem gateway, chama a criaçao do pixCheckout
            if (activePage === 1 && selectedPayment?.methodKey === 'P' && thisCustomerHasGateway) {
                setShowUniversalModal(true)
                setTypeModal('P')
                return
                // return await handlePaymentPixCheckout()
            }
        }
        if (selectedActivitie?.termOfUse?.ops[0]?.insert?.length > 1) {
            if (!selectedAcceptedTerm) {
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Aceite o termo para continuar')
            }
        }
        if (!selectedPlan?.id) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Selecione um dos planos')
        }
        setShowNotificationModal(false)
        setActivePage(prev => (prev + 1))
    }

    async function handleSelectThisCard(card, toSelectActivitie) {

        setLoadingPhotos(true)
        const photo = await formatAPIPhoto(toSelectActivitie, true)
        setActivitiesPhotos(photo.map(thisPhoto => thisPhoto.picture))

        if (toSelectActivitie?.regulationFile?.length > 0) {
            setRegulationFile(toSelectActivitie?.regulationFile)
        }

        if (activitiesPlansList?.length > 0) {
            const planOfThisActivitie = activitiesPlansList.filter((plan => plan.activities_id === toSelectActivitie?.id))
            const installmentNumber = planOfThisActivitie.map(install => install.numberOfInstallments)
            const installments = numberInstallments.filter(installment => installmentNumber.includes(installment?.id))
            setInstallmentsForThisActivitie(installments)
            setPlansOfSelectedActivitie(planOfThisActivitie)
        }

        setLoadingPhotos(false)
        setSelectedAcitvitie(prev => ({ ...toSelectActivitie, photos: photo }))

        activitiesList.map(activitie => {
            if (activitie.id !== card.id) {
                let otherActivitie = document.getElementById(`activitie${activitie.id}`)
                otherActivitie.style.boxShadow = ''
            }
        })

        let thisCard = document.getElementById(card)
        thisCard.style.boxShadow = '0 0 10px 2px var(--primaryDefaultLight)'

        let activitieContainer = document.getElementById('selectedActivitieContainer')
        activitieContainer.scrollIntoView({ behavior: 'smooth' })

    }
    //container com os valores
    function valueConfirmation() {

        return <div className='w-full flex flex-col gap-4'>
            <h2 className='text-primaryDefaultLight font-bold text-base'>Valores</h2>
            <div className='flex flex-col item-start justify-center w-full'>
                <div className='w-full  text-titleBlackTextLight dark:text-titleGrayTextDark text-sm sm:w-auto bg-bgPrimaryLight dark:bg-secondaryDefaultDark border border-primaryDefaultLight rounded-t-lg'>
                    <div className='flex flex-row gap-1 items-center justify-center w-full pl-2 h-10 border-b border-primaryDefaultLight'>
                        <p className='text-sm font-bold'>Valor a pagar: {(selectedPlan?.value || totalValue === 0) ? selectedPlan?.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}</p>
                        {
                            selectedPayment?.methodKey === 'C' &&
                            <>
                                <p className='text-sm font-bold'>em ate {selectedPlan?.numberOfInstallments}x</p>
                                <p className='text-sm font-bold'>de: {(selectedPlan?.value / selectedPlan?.numberOfInstallments).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            </>
                        }

                    </div>
                </div>
                <div className='bg-primaryDefaultLight w-full py-2 rounded-b-lg text-bgPrimaryLight dark:text-titleGrayTextDark flex flex-col items-center justify-center'>
                    <p className='text-sm font-bold'>Valor a pagar: {(selectedPlan?.value || totalValue === 0) ? selectedPlan?.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}</p>
                </div>
            </div>
        </div>
    }
    //container com os dados da inscrição
    function confirmationContainer() {
        return (
            <div className='mt-3 w-full flex flex-col gap-4 mb-6 rounded-lg p-3 sm:p-0 items-start'>
                <h1 className='text-base font-bold text-primaryDefaultLight'>Confirmação</h1>
                <div className='w-full bg-zinc-50 p-2 items-start justify-center rounded-lg border border-primaryDefaultLight bg-bgPrimaryLight dark:bg-secondaryDefaultDark'>
                    <div className='h-full flex flex-col sm:flex-row text-sm gap-2 items-center justify-center w-full'>
                        <div className='flex flex-row gap-2 w-full sm:w-[50%] items-center justify-center'>
                            <FaMapMarkerAlt className='text-secondaryDefaultLight text-2xl' />
                            <h2 className='text-2xl text-primaryDefaultLight font-bold '>{selectedActivitie?.activitiesName}</h2>
                        </div>
                        <div className='w-[50%] sm:border-l sm:border-primaryDefaultLight h-full text-sm flex flex-row gap-2 items-center justify-start'>
                            <FaCalendar className='ml-2 text-secondaryDefaultLight text-xl' />
                            <div className='flex flex-row items-center justify-center gap-4 w-full'>
                                <div className='flex flex-row gap-1 items-center justify-center border-r border-primaryDefaultLight px-2'>
                                    <p className='text-primaryDefaultLight font-bold text-xl dark:text-titleGrayTextDark'>{selectedPlan?.planName}</p>
                                    {
                                        selectedPayment?.methodKey === 'C' ?
                                            <p className='text-primaryDefaultLight font-bold text-xl dark:text-titleGrayTextDark'>{currencyPtBr(selectedPlan?.value)} em ate {selectedPlan?.numberOfInstallments}x</p>
                                            :
                                            <p className='text-primaryDefaultLight font-bold text-xl dark:text-titleGrayTextDark'>{currencyPtBr(selectedPlan?.value)}</p>

                                    }
                                </div>
                                {/* {
                                    selectedHour?.length > 1 ?
                                        <div className='flex flex-row gap-1 flex-wrap items-center justify-start w-full'>
                                            {
                                                selectedHour.map(hour => {
                                                    return <div className='flex flex-row gap-1'>
                                                        <p className='text-titleBlackTextLight font-bold dark:text-titleGrayTextDark'>{formatHours(hour?.startHour)}</p>
                                                        <p className='text-titleBlackTextLight font-bold dark:text-titleGrayTextDark'>as</p>
                                                        <p className='text-titleBlackTextLight font-bold dark:text-titleGrayTextDark'>{formatHours(hour?.finishHour)}</p>
                                                    </div>
                                                })
                                            }

                                        </div>
                                        :
                                        <div className='flex flex-row gap-1 items-center justify-start w-full'>
                                            <p className='text-primaryDefaultLight font-bold dark:text-titleGrayTextDark'>{formatHours(selectedHour[0]?.startHour)}</p>
                                            <p className='text-primaryDefaultLight font-bold dark:text-titleGrayTextDark'>as</p>
                                            <p className='text-primaryDefaultLight font-bold dark:text-titleGrayTextDark'>{formatHours(selectedHour[0]?.finishHour)}</p>
                                        </div>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !termOfUseDescription ?
                        <>
                        </>
                        :
                        <div className='mt-3 w-full flex flex-col items-start gap-4'>
                            <h2 className='text-base text-primaryDefaultLight font-bold'>Termos e condiçoes</h2>

                            <div className='w-full bg-zinc-50 rounded-lg shadow-inner border-zinc-200 overflow-auto dark:scrollbar-thin dark:scrollbar-track-zinc-700 dark:scrollbar-thumb-zinc-500 text-base break-words whitespace-pre-line sm:text-sm h-80 p-3 dark:bg-secondaryDefaultDark dark:text-titleGrayTextDark dark:border dark:border-thirdDefaultDark'>
                                <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark sm:min-w-[200px]  min-h-96' readOnly={true} theme={"bubble"} value={termOfUseDescription} />
                            </div>
                            <p className='text-sm text-primaryDefaultLight'>Aceito o termo</p>
                            {/* <InputCheckBox id='termOfUse' onClick={() => setSelectedTermsfUse(!selectedTermsOfUse)} value={selectedTermsOfUse ? true : false}></InputCheckBox> */}
                        </div>
                }
                <div className='w-full mt-2 mb-4 flex items-start flex-col justify-start'>
                    <a className='text-sm text-inputPlaceholderLight dark:text-titleGrayTextDark'>Observação</a>
                    <textarea wrap="hard" maxLength={2000} className='
                                    p-2 border border-gray-400 border-opacity-75 
                                    h-48
                                    w-full                                    
                                    text-lg sm:text-sm
                                    outline-none transition-all duration-200 focus:shadow-borderShadow
                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark '>
                    </textarea>

                </div>
            </div>
        )

    }

    //sucesso pagamento com gateway
    function paymentSuccessWithGateway() {

        return (
            <div className='flex flex-col gap-2 items-center justify-center h-80'>
                <div className='flex flex-row gap-2'>
                    <FaCheck className='text-lg text-green-600' />
                    <a className='text-base text-green-600 font-semibold'>Pagamento confirmado</a>
                </div>
                <p className='text-titleBlackTextLight text-center sm:text-left'>Sua inscrição foi confirmada, acesse o menu minhas inscriçòes para mais detalhes</p>
                <Button shadow={true} onClick={() => navigate('/mySubscriptions')}>Minhas inscriçoes</Button>
            </div>
        )
    }

    //falha de pagamento com gateway
    function paymentFailedWithGateway() {

        return (
            <div className='flex flex-col gap-2 items-center justify-center h-80'>
                <div className='flex flex-row gap-2'>
                    <FaCircleNotch className='text-lg text-red-600' />
                    <a className='text-base text-red-600 font-semibold'>Pagamento Cancelado</a>
                </div>
                <p className='text-sm text-titleBlackTextLight'>Seu pagamento foi recusado</p>
                <Button shadow={true} onClick={handleReset}>Voltar</Button>
            </div>
        )
    }

    //falha na criação da inscrição
    function submitFailed() {

        return (
            <div className='flex flex-col gap-2 items-center justify-center h-80'>
                <div className='flex flex-row gap-2'>
                    <FaExclamationCircle className='text-lg text-red-600' />
                    <a className='text-base text-red-600 font-semibold'>Erro ao processar</a>
                </div>
                <p className='text-sm text-titleBlackTextLight'>Verifique o erro no topo da tela</p>
                <Button shadow={true} onClick={handleReset}>Voltar</Button>
            </div>
        )
    }

    const handleReset = () => {

        setActivePage(0)
        setSelectedAcitvitie('')
        setSelectedPayment('')
        setSelectedAcceptedTerm(false)
        setSelectedCardCVVNumber('')
        setSelectedCardDueDateMonth('')
        setSelectedCardNumber('')
        setSelectedInstallments('')
        setSelectedPayment('')
        setTypeModal('')
        setSelectedPlan('')
        setShowNotificationModal('')
        setShowNotificationModalText('')
        setSelectedCardPersonName('')
        setUserSelectedCPF('')
        setSelectedCardDueDateYear('')
    }

    return (
        <>
            <ScreenModal>
                {
                    typeModal === "P" ? //Modal para PIX
                        <div className='flex p-6 flex-col gap-8 items-center justify-center'>
                            <div className='flex flex-col gap-2 w-full items-center'>
                                <p className='text-sm text-titleBlackTextLight'>Deseja pagar com PIX?</p>
                                <p className='text-sm text-titleBlackTextLight'>Após confirmar, o método de pagamento não poderá mais ser alterado</p>
                            </div>
                            <div className='flex flex-row gap-2 justify-center items-center'>
                                <Button shadow={true} onClick={() => { handlePaymentPixCheckout(); setShowUniversalModal(false); }}>Sim</Button>
                                <Button approval={false} shadow={true} onClick={() => { setTypeModal(''); setShowUniversalModal(false) }}>Não</Button>
                            </div>
                        </div>
                        :
                        typeModal === "C" && //Modal para cartão de crédito
                        <div className='flex p-6 flex-col gap-8 items-center justify-center'>
                            <div className='flex flex-col gap-2 w-full items-center'>
                                <div className='bg-zinc-100 border border-zinc-300 rounded-lg p-1 mb-2'>
                                    <p className='text-sm text-titleBlackTextLight'><strong>Forma de pagamento:</strong> Cartão de Crédito</p>
                                    <p className='text-sm text-titleBlackTextLight'><strong>Será cobrado em seu cartão:</strong> {selectedInstallments?.id}x de R$ {(selectedPlan.value / selectedInstallments?.id).toFixed(2).toString().replace('.', ',')}</p>
                                    <p className='text-sm text-titleBlackTextLight'><strong>Referente a inscrição de:</strong> {selectedActivitie?.activitiesName}</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight'>Deseja prosseguir?</p>
                            </div>
                            <div className='flex flex-row gap-2 justify-center items-center'>
                                <Button shadow={true} onClick={() => { handlePaymentCardCheckout(); setShowUniversalModal(false); }}>Sim</Button>
                                <Button approval={false} shadow={true} onClick={() => { setTypeModal(''); setShowUniversalModal(false) }}>Não</Button>
                            </div>
                        </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Nova inscrição'} />
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        activitiesNotPermitted == 'financialRules' ?
                            <div className='p-2 flex flex-col items-center justify-center my-8 dark:text-titleGrayTextLight text-titleBlackTextDark bg-zinc-100 dark:bg-thirdDefaultDark dark:border-secondaryDefaultLight rounded-lg border border-zinc-100'>
                                <p>Inscrições não liberadas ainda</p>
                                <p>Entre em contato com a associação</p>
                            </div>
                            :
                            <>
                                {
                                    loading ?
                                        <div className='w-full h-96 flex flex-col items-center justify-center'>
                                            <DefaultLoader />
                                        </div>
                                        :
                                        activePage === 0 ? //etapa 1 (seleçao da atividade + plano)
                                            activitiesList?.length > 0 && thisCustomerHasGateway ?
                                                <div className='flex flex-col flex-wrap gap-4 items-start justify-start w-full select-none'>
                                                    <div className='w-full flex flex-col items-center mb-4'>
                                                        <a className='text-xl text-titleBlackTextLight dark:text-titleGrayTextDark'>Inscreva-se em uma das nossas atividades</a>
                                                        <a className='text-titleBlackTextLight dark:text-titleGrayTextDark'>Clique para selecionar abaixo:</a>
                                                    </div>
                                                    <div className='flex flex-row gap-2 items-start justify-center w-full'>
                                                        <div style={{ maxWidth: screenX > 600 ? screenX - 300 : 'auto' }} className='overflow-x-hidden flex flex-row gap-8 items-center justify-center w-full p-6'>
                                                            <div id='moveRow' style={{
                                                                width: activitiesList.length * 450
                                                            }} className='flex flex-row flex-wrap gap-8 items-center justify-center w-full transition-all duration-300 ease-in-out'>
                                                                {
                                                                    activitiesList.map(activitie => {
                                                                        return <div
                                                                            style={{
                                                                                backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), rgba(27, 27, 27, 0.9)), url(${activitie?.picturesURL[0]})`,
                                                                            }}
                                                                            id={`activitie${activitie.id}`}
                                                                            onClick={() => handleSelectThisCard(`activitie${activitie.id}`, activitie)}
                                                                            className='bg-cover animate-[wiggleLeft_1s_ease-in-out] cursor-pointer hover:shadow-borderShadow-xl w-[300px] 2xl:w-[400px]  h-80 2xl:h-96 hover:scale-105 transition-all duration-300 ease-in-out boxShadow rounded-xl text-lg font-light items-center justify-center flex flex-col'>
                                                                            <a className='text-xl text-white font-light'>{activitie.activitiesName}</a>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        loadingPlan ?
                                                            <div className='flex flex-col w-full items-center justify-center h-96'>
                                                                <DefaultLoader />
                                                            </div>
                                                            :
                                                            <div id='selectedActivitieContainer' className='flex flex-col w-full mt-10'>
                                                                <div className='flex flex-col items-center justify-start gap-10'>
                                                                    <div className='flex flex-row w-full items-start gap-6 justify-center'>
                                                                        <div className='sm:flex flex-col hidden gap-4 translate-y-4'>

                                                                            {
                                                                                selectedActivitie.hyperLink?.length > 1 &&
                                                                                <div className='shadow-lg items-center justify-center cursor-pointer rounded-lg bg-primaryDefaultLight flex py-1 px-3 hover:bg-secondaryDefaultLight text-white transition-all duration-150'>
                                                                                    <a className='text-sm' href={`http://${selectedActivitie.hyperLink}`} target='__blank'>{selectedActivitie?.hyperLinkDescription}</a>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                activitiesPhotos?.length > 0 && !loadingPhotos ?
                                                                                    <div className='flex flex-col items-center justify-start'>
                                                                                        <SwiperFullWidthContainer imgs={activitiesPhotos}></SwiperFullWidthContainer>
                                                                                    </div>
                                                                                    :
                                                                                    loadingPhotos &&
                                                                                    <div className='flex flex-col items-center justify-center animate-pulse bg-gray-200 w-96 h-96'>
                                                                                        <a className='text-sm text-titleBlackTextLight animate-bounce'>Carregando foto...</a>
                                                                                    </div>
                                                                            }

                                                                        </div>
                                                                        <div className='max-w-[700px] flex flex-col items-start justify-start gap-2'>
                                                                            {/* <div className='flex flex-col items-start mt-4 justify-start w-full'>
                                                                    <h1 className='text-xl text-primaryDefaultLight'>{selectedActivitie.activitiesName}</h1>
                                                                </div> */}
                                                                            <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96' readOnly={true} theme={"bubble"} value={selectedActivitie.description} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-full flex sm:hidden flex-col items-center justify-center'>
                                                                        {
                                                                            activitiesPhotos?.length > 0 && !loadingPhotos ?
                                                                                <SwiperContainer imgs={activitiesPhotos}></SwiperContainer>
                                                                                :
                                                                                loadingPhotos &&
                                                                                <div className='flex flex-col items-center justify-center animate-pulse bg-gray-200 w-96 h-96'>
                                                                                    <a className='text-sm text-titleBlackTextLight animate-bounce'>Carregando foto...</a>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        plansOfSelectedActivitie?.length > 0 &&
                                                                        <>
                                                                            <h1 className='text-xl text-primaryDefaultLight'>Planos disponíveis</h1>
                                                                            <div className='flex flex-row flex-wrap gap-4 items-center justify-center w-full'>
                                                                                {
                                                                                    plansOfSelectedActivitie.map(plan => {
                                                                                        return <div onClick={() => setSelectedPlan(plan)} className={`cursor-pointer h-72 w-56 rounded-lg shadow-lg ${selectedPlan?.id !== plan?.id ? 'bg-zinc-200 grayscale' : 'shadow-borderShadow-xl grayscale-0 bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight'} transition-all duration-200 hover:grayscale-0 hover:bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight flex flex-col items-center`}>
                                                                                            <div className='p-4 flex flex-col items-center justify-center'>

                                                                                            </div>
                                                                                            <div className='flex flex-col flex-wrap break-words items-center justify-center h-20 bg-bgPrimaryLight w-full mb-4  '>
                                                                                                <a className='text-lg text-titleBlackTextLight text-center'>{plan.planName}</a>
                                                                                            </div>
                                                                                            <div className='w-full flex flex-col gap-2 items-center justify-center flex-wrap'>
                                                                                                <a className='text-xl text-white'>R$ {plan.value?.toFixed(2).toString().replace('.', ',')}</a>
                                                                                                <a className='text-sm text-white'>em até {plan.numberOfInstallments}X de</a>
                                                                                                <a className='text-sm text-white'>R$ {(plan.value / plan.numberOfInstallments).toFixed(2).toString().replace('.', ',')}</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    <div className='flex flex-col gap-6 items-center justify-center w-full'>
                                                                        {
                                                                            regulationFile?.length > 0 &&
                                                                            <button id={'regulationId'} onClick={() => handleDownloadRegulation()}
                                                                                className={`text-base sm:text-sm rounded-lg sm:rounded-lg 
                                                                    p-4 mt-4
                                                                    flex flex-row -gap-2 items-center justify-center
                                                                    bg-primaryDefaultLight hover:bg-secondaryDefaultLight
                                                                    transition-all duration-300 text-titleGrayTextLight 
                                                                    shadow-md shadow-gray-500/50 dark:shadow-md dark:shadow-secondaryDefaultDark `}>
                                                                                <img width={30} src={pdf}></img> Regulamento
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        selectedActivitie?.termOfUse?.ops[0]?.insert?.length > 1 &&
                                                                        <>
                                                                            <div className='flex flex-col w-full max-h-[700px] overflow-auto'>
                                                                                <div className='w-full border-b border-primaryDefaultLight flex flex-col'>
                                                                                    <a className='text-primaryDefaultLight'>Termos e condições</a>
                                                                                </div>
                                                                                <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96' readOnly={true} theme={"bubble"} value={selectedActivitie.termOfUse} />
                                                                            </div>
                                                                            <div className='flex flex-row gap-4 w-full items-center justify-center'>
                                                                                <InputCheckBox value={selectedAcceptedTerm} onClick={() => setSelectedAcceptedTerm(!selectedAcceptedTerm)} />
                                                                                <a className='texxt-sm text-inputPlaceholderLight'>Aceito o termo</a>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                                :
                                                <a className='text-xl text-titleBlackTextLight dark:text-titleGrayTextDark'>Nenhuma atividade encontrada</a>
                                            : activePage === 1 ? //etapa de pagamento 
                                                <div div className='flex flex-col items-center justify-center sm:items-center sm:flex-row flex-wrap mt-12 w-[100%] mb-12 gap-8'>
                                                    <LabelInput text={'Forma de pagamento'}>
                                                        <InputAutoComplete
                                                            data={paymentOption}
                                                            preSelectedValue={selectedPayment?.description}
                                                            selectedLabel={'description'}
                                                            optionList={['description']}
                                                            width={72}
                                                            onChange={(e) => setSelectedPayment(e)}>
                                                        </InputAutoComplete>
                                                    </LabelInput>
                                                    <LabelInput text={'Nome'}>
                                                        <Input disabled={true} value={userData[0].typeAccess_id === 2 ? selectedUserToSchedule.firstName : userData[0].firstName}></Input>
                                                    </LabelInput>
                                                    <LabelInput text={'E-mail'}>
                                                        <Input disabled={true} value={userData[0].typeAccess_id === 2 ? selectedUserToSchedule.email : userData[0].email}></Input>
                                                    </LabelInput>
                                                    {
                                                        //Dados do cartão de crédito
                                                        selectedPayment?.methodKey === 'C' && thisCustomerHasGateway ?
                                                            <div className='flex flex-col w-full items-center'>
                                                                <div className='w-full flex flex-col-reverse xl:flex-row flex-wrap items-center xl:items-start justify-center gap-8'>
                                                                    <div className='mt-3 flex flex-col sm:flex-row items-center sm:items-start justify-center gap-4'>
                                                                        <div className='w-full items-start flex flex-col gap-4 mb-6 rounded-lg'>
                                                                            <h2 className='text-primaryDefaultLight font-bold text-base'>Dados do cartão</h2>
                                                                            <div className='flex flex-row gap-1 items-center justify-start'>
                                                                                <Tippy content={'Mastercard'}
                                                                                    arrow={true}
                                                                                    animation='shift-away'
                                                                                    placement='top'
                                                                                    delay={100}>
                                                                                    <img src={credit1} width={50}></img>
                                                                                </Tippy>
                                                                                <Tippy content={'Visa'}
                                                                                    arrow={true}
                                                                                    animation='shift-away'
                                                                                    placement='top'
                                                                                    delay={100}>
                                                                                    <img src={credit2} width={50}></img>
                                                                                </Tippy>
                                                                                <Tippy content={'Elo'}
                                                                                    arrow={true}
                                                                                    animation='shift-away'
                                                                                    placement='top'
                                                                                    delay={100}>
                                                                                    <img src={credit3} width={50}></img>
                                                                                </Tippy>
                                                                                <Tippy content={'American Express'}
                                                                                    arrow={true}
                                                                                    animation='shift-away'
                                                                                    placement='top'
                                                                                    delay={100}>
                                                                                    <img src={credit4} width={50}></img>
                                                                                </Tippy>
                                                                                <Tippy content={'Hipercard'}
                                                                                    arrow={true}
                                                                                    animation='shift-away'
                                                                                    placement='top'
                                                                                    delay={100}>
                                                                                    <img src={credit5} width={50}></img>
                                                                                </Tippy>
                                                                            </div>
                                                                            <div id='creditCardVirtual' className='mb-4 shadow-lg shadow-zinc-400 dark:shadow-zinc-900 bg-gradient-to-r from-primaryDefaultLight to-secondaryDefaultLight rounded-lg w-72 h-40 flex flex-col items-center justify-center gap-2 p-4'>
                                                                                <div className='flex flex-col w-full items-start justify-start'>
                                                                                    <img width={30} height={25} src={cardChip} />
                                                                                    <p className='mb-2 text-titleGrayTextLight text-sm'>{selectedCardNumber?.length > 0 ? maskCardNumber(selectedCardNumber) : 'XXXX XXXX XXXX XXXX'}</p>
                                                                                    <div className='flex flex-col w-full items-start justify-center gap-1'>
                                                                                        <p className='text-[0.60rem] text-titleGrayTextLight font-bold'>Vencimento</p>
                                                                                        <div className='flex flex-row items-start justify-center'>
                                                                                            <p className='text-[0.60rem] text-titleGrayTextLight'>{selectedCardDueDateMonth?.id?.length > 0 ? selectedCardDueDateMonth?.id : '00'}</p>
                                                                                            <p className='text-[0.60rem] text-titleGrayTextLight'>{"/"}</p>
                                                                                            <p className='text-[0.60rem] text-titleGrayTextLight'>{selectedCardDueDateYear?.id > 0 ? selectedCardDueDateYear?.id : '0000'}</p>
                                                                                        </div>
                                                                                        <p className='text-[0.60rem] text-titleGrayTextLight '>{selectedCardPersonName?.length > 0 ? selectedCardPersonName : 'Seu nome como no cartão'}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <LabelInput text={'Numero do cartao'}>
                                                                                <div className='card_object border border-white'>
                                                                                    <Input id='number' charLimit={20} width={72} value={selectedCardNumber} onChange={(e) => { setSelectedCardNumber(justNumber(e.target.value)); borderToCardFields(false) }} type={'text'}></Input>
                                                                                </div>
                                                                            </LabelInput>
                                                                            <div className='flex flex-col sm:flex-row gap-2 items-center justify-start'>
                                                                                <LabelInput text={'Vencimento - Mes'}>
                                                                                    <div className='card_object border border-white'>
                                                                                        <Input className='card_object border border-white' id='exp_month' width={32} type={'text'}
                                                                                            value={selectedCardDueDateMonth}
                                                                                            onChange={(e) => setSelectedCardDueDateMonth(e.target.value)}
                                                                                            charLimit={2}
                                                                                        />
                                                                                    </div>
                                                                                </LabelInput>
                                                                                <LabelInput text={'Vencimento - Ano'}>
                                                                                    <div className='card_object border border-white'>
                                                                                        <Input className='card_object border border-white' id='exp_year' width={32} type={'text'}
                                                                                            value={selectedCardDueDateYear}
                                                                                            onChange={(e) => setSelectedCardDueDateYear(e.target.value)}
                                                                                            charLimit={2}
                                                                                        />
                                                                                    </div>
                                                                                </LabelInput>
                                                                            </div>
                                                                            <LabelInput text={'Nome no cartao'}>
                                                                                <div className='card_object border border-white'>
                                                                                    <Input className='card_object border border-white' id="holder_name" width={72} value={selectedCardPersonName} onChange={(e) => setSelectedCardPersonName(e.target.value)} type={'text'}></Input>
                                                                                </div>
                                                                            </LabelInput>
                                                                            <div className='flex flex-row gap-2 items-center justify-start'>
                                                                                <LabelInput text={'CVV'}>
                                                                                    <div className='card_object border border-white'>
                                                                                        <Input className='card_object border border-white' id="cvv" charLimit={3} value={selectedCardCVVNumber} onChange={(e) => setSelectedCardCVVNumber(e.target.value)} width={20} type={'text'}></Input>
                                                                                    </div>
                                                                                </LabelInput>
                                                                                <Tippy content={'Cód de segurança atrás do seu cartão'}
                                                                                    arrow={true}
                                                                                    animation='shift-away'
                                                                                    placement='top'
                                                                                    delay={100}>
                                                                                    <div className='mt-4 flex flex-col items-center justify-center w-24 h-16 px-2 gap-2 bg-gradient-to-r from-primaryDefaultLight to-secondaryDefaultLight rounded-lg'>
                                                                                        <div className='flex flex-col w-[90%] h-3 bg-black'></div>
                                                                                        <div className=' w-[80%] h-5 bg-bgPrimaryLight'>
                                                                                            <p className='text-xs text-titleBlackTextLight'>**** 989</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </Tippy>
                                                                            </div>
                                                                            <div className='flex flex-col gap-2'>
                                                                                <LabelInput text={'Numero de parcelas'}>
                                                                                    <InputAutoComplete id='selectedInstallments' width={72}
                                                                                        data={Array(selectedPlan?.numberOfInstallments).fill({ id: 0, description: 'teste' }).map((plan, index) => { return { id: index + 1, description: `${index + 1}x de ${(selectedPlan.value / (index + 1)).toFixed(2).toString().replace('.', ',')}` } })}
                                                                                        onChange={e => setSelectedInstallments(e)} optionList={['description']} selectedLabel={'description'} />
                                                                                </LabelInput>
                                                                            </div>
                                                                            <LabelInput text={'CPF *'}>
                                                                                <Input width={72} id='userSelectedCPF' value={userSelectedCPF} onChange={(e) => setUserSelectedCPF(justNumber(e?.target?.value))} />
                                                                            </LabelInput>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col items-center justify-center gap-4 w-full sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px]'>
                                                                        {confirmationContainer()}
                                                                        {valueConfirmation()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            //Dados para PIX
                                                            selectedPayment?.methodKey === 'P' && thisCustomerHasGateway ?
                                                                <div className='w-full flex flex-col-reverse xl:flex-row flex-wrap items-center justify-center gap-8'>
                                                                    <div className='flex flex-col items-center justify-center gap-4 w-full sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px]'>
                                                                        {confirmationContainer()}
                                                                        {valueConfirmation()}
                                                                        <div className='w-full flex flex-col items-center gap-4 mt-3 mb-6 rounded-lg'>
                                                                            <h2 className='text-primaryDefaultLight font-bold text-base'>Clique abaixo para pagamento</h2>
                                                                            <LabelInput text={'CPF *:'}>
                                                                                <Input id='userSelectedCPF' value={userSelectedCPF} onChange={(e) => setUserSelectedCPF(justNumber(e.target.value))} charLimit={11}></Input>
                                                                            </LabelInput>
                                                                            <div onClick={() => handleNextPage()} className='cursor-pointer sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px] flex flex-col items-center justify-center bg-primaryDefaultLight hover:bg-secondaryDefaultLight transition-all duration-200 rounded-lg h-12 px-8 shadow-lg'>
                                                                                <a className='text-white'>Pagar com Pix</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                //Dados para folha de pagamento
                                                                selectedPayment?.methodKey === 'F' &&
                                                                <div className='w-full flex flex-col-reverse xl:flex-row flex-wrap items-center justify-center gap-8'>
                                                                    <div className='flex flex-col items-center justify-center gap-4 w-full sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px]'>
                                                                        {confirmationContainer()}
                                                                        {valueConfirmation()}
                                                                    </div>
                                                                </div>

                                                    }
                                                </div>
                                                //Status pagamento em processamento PIX
                                                : activePage === 2 && typeModal === 'P' ?
                                                    //Loading solicitando PIX
                                                    loadingPayment ?
                                                        <div className='flex p-6 flex-col h-96 items-center justify-center gap-4 animate-pulse'>
                                                            <div className='p-4 text-transparent opacity-0 flex flex-col items-start justify-center -mt-12 border border-gray-200 bg-gray-100 rounded-lg'>
                                                                <a className='text-sm mb-4 text-titleBlackTextLight'>Efetue o <strong>pagamento do PIX</strong> com o QrCode abaixo para confirmar sua reserva</a>
                                                            </div>
                                                            <a className='text-sm text-titleBlackTextLight'>Solicitando codigo pix a operadora...</a>
                                                            <DefaultLoader />
                                                        </div>
                                                        :
                                                        <div className='flex p-6 flex-col gap-4 items-center justify-center min-h-[600px]'>
                                                            {
                                                                paymentStatus === 3 &&
                                                                <>
                                                                    <div className='p-4 flex flex-col items-start justify-center border border-gray-200 bg-gray-100 rounded-lg'>
                                                                        <a className='text-sm mb-4 text-titleBlackTextLight'>Efetue o <strong>pagamento do PIX</strong> com o QrCode abaixo para confirmar sua inscrição</a>
                                                                        <a className='text-sm text-titleBlackTextLight'><strong>Valor: </strong>{selectedPlan?.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                                                                        <a className='text-sm text-titleBlackTextLight'><strong>Nome: </strong>{gatewayData?.charges[0]?.customer?.name}</a>
                                                                        <a className='text-sm text-titleBlackTextLight'><strong>E-mail: </strong>{gatewayData?.charges[0]?.customer?.email}</a>
                                                                    </div>
                                                                    <div className='w-48 h-48'>
                                                                        <img src={gatewayData?.charges[0]?.last_transaction?.qr_code_url}></img>
                                                                    </div>
                                                                    <div className='flex flex-row gap-2 max-w-80'>
                                                                        <a className="text-sm max-w-80">Código: {gatewayData?.charges[0]?.last_transaction?.qr_code?.slice(0, 30)?.concat('...')}</a>
                                                                        {
                                                                            copied ?
                                                                                <FaCheck />
                                                                                :
                                                                                <FaCopy className='text-lg cursor-pointer' onClick={() => { copyToClipboard(gatewayData?.charges[0]?.last_transaction?.qr_code); setCopied(true) }} />
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        onClick={() => { copyToClipboard(gatewayData?.charges[0]?.last_transaction?.qr_code); setCopied(true) }}
                                                                        className={`sm:hidden w-full p-2 gap-4 rounded-lg shadow-lg bg-primaryDefaultLight flex flex-row items-center justify-center text-white`}>Copiar código
                                                                        {
                                                                            copied ?
                                                                                <FaCheck />
                                                                                :
                                                                                <FaCopy className='text-lg' />
                                                                        }
                                                                    </div>
                                                                    <a className='text-bold text-sm'>Expira em: {formattTimeToShow(gatewayData?.charges[0]?.last_transaction?.expires_at)}</a>
                                                                    <a className='text-red-600 font-semibold'>Atenção: Após efetuar o pagamento, aguarde até 10 minutos para o pagamento ser processado</a>
                                                                </>
                                                            }
                                                        </div>
                                                    :
                                                    activePage === 2 && typeModal === "C" ? //Status pagamento em processamento cartão de crédito
                                                        loadingPayment &&
                                                        <div className='flex p-6 flex-col h-96 items-center justify-center gap-4 animate-pulse'>
                                                            <a className='text-sm text-titleBlackTextLight'>Aguardando confirmaçao da operadora de cartão</a>
                                                            <DefaultLoader />
                                                        </div>
                                                        :
                                                        activePage === 2 && typeModal === "F" ? //Status pagamento em processamento folha de pagamento
                                                            <div className='flex p-6 flex-col h-96 items-center justify-center gap-4 animate-pulse'>
                                                                <a className='text-sm text-titleBlackTextLight'>Aguarde...</a>
                                                                <DefaultLoader />
                                                            </div>
                                                            : activePage === 3 && //Retornos do pagamento
                                                                paymentStatus === 1 ?
                                                                paymentSuccessWithGateway()
                                                                :
                                                                paymentStatus === 2 || typeModal === 'E' ?
                                                                    paymentFailedWithGateway()
                                                                    :
                                                                    typeModal === "LocalError" &&
                                                                    submitFailed()

                                }
                            </>
                    }
                </Body>
                <Footer>
                    <div className='w-full justify-between h-full items-center flex flex-row'>
                        {
                            activePage === 1 ?
                                <Button onClick={() => setActivePage(prev => (prev - 1))} shadow={true} approval={false}>Voltar</Button>
                                :
                                activePage === 0 &&
                                <div className='opacity-0'>
                                    <Button shadow={true} approval={false}>Voltar</Button>
                                </div>
                        }
                        {
                            activePage >= 2 && selectedPayment?.methodKey === "P" &&
                            <div className='flex flex-col w-full items-center justify-center'>
                                <Timer minutesDisplay={minutesDisplay} secondsDisplay={secondsDisplay} progress={progress} />
                            </div>
                        }
                        {
                            activitiesPlansList?.length > 0 ?
                                activePage === 1 && selectedPayment?.methodKey !== 'P' && selectedPayment.methodKey === "C" ?
                                    <Button shadow={true} onClick={() => handleNextPage()}>Confirmar</Button>
                                    :
                                    selectedPayment?.methodKey === 'C' || activePage < 1 &&
                                    <Button shadow={true} onClick={() => handleNextPage()}>Próximo</Button>
                                :
                                <div></div>
                        }
                    </div>
                </Footer>
            </Container >
        </>
    )
}
