import React, { useEffect, useState, useRef } from "react";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { TableDefault } from "../../../components/table/table.default";
import { Button } from "../../../components/buttons/button.default";
import { useNavigate } from "react-router-dom";
import { formattTimeToShow } from "../../../services/api/callAPIsFunctions/defaultCalls.api";
import { useAuth } from "../../../contexts/useAuth";
import { FaBan, FaCheck, FaFilter, FaSearch } from "react-icons/fa";
import { useThemeContext } from "../../../contexts/themeContext";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function UsersBlocked() {

    const { defaultCallsAuth  } = useApiContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const navigate = useNavigate()
    const [filteredUsers, setFilteredUsers] = useState([])
    const tableThead = [
        {
            "name": 'Usuário',
            "original_name": 'firstName'
        },
        {
            "name": 'Motivo',
            "original_name": 'reason'
        },
        {
            "name": 'Reservas',
            "original_name": "schedules"
        },
        {
            "name": 'Inscriçoes',
            "original_name": "activitiesSubscription"
        },
        {
            "name": 'Criado em',
            "original_name": "created_at"
        },
        {
            "name": 'Bloqueado até',
            "original_name": "expires_in"
        },
    ]
    //filters search
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showInternalFilterModal, setShowInternalFilterModal] = useState(false)
    const [paginationFilteredFields, setPaginationFilteredFields] = useState({})
    const [paginationHistFilteredFields, setPaginationHistFilteredFields] = useState({})
    const [paginationHistFilteredFieldsArr, setPaginationHistFilteredFieldsArr] = useState([])
    const [cleanFilter, setCleanFilter] = useState(false)
    const tableFilterModal = useRef()
    const [totalPages, setTotalPages] = useState(1)
    const [quantity, setQuantity] = useState(0)
    const filters = [
        {
            "name": 'Nome',
            "original_name": 'firstName',
            "type": 'text'
        },
        {
            "name": 'Sobrenome',
            "original_name": 'lastName',
            "type": 'text'
        }
    ]

    async function getData(e) {

        setLoading(true)
        const blockedUsersData = await defaultCallsAuth.getBlockedUsers(userData[0].token, e?.filter, e?.page, e?.direction)
        if (blockedUsersData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(blockedUsersData.data)
        }
        setTotalPages(blockedUsersData.data?.totalPages)
        setFilteredUsers(blockedUsersData?.data?.data?.map(block => {
            return {
                ...block,
                blocked_users_name: `${block.firstName} ${block.lastName}`,
                created_atToTable: formattTimeToShow(block.created_at),
                expires_in_toTable: formattTimeToShow(block.expires_in),
                schedulestoTable: block?.schedules ? <div className="text-lg items-center justify-center flex flex-col text-green-600"><FaCheck /></div> : <></>,
                activitiesSubscriptionToTable: block?.activitiesSubscription ? <div className="text-lg items-center justify-center flex flex-col text-green-600"><FaCheck /></div> : <></>
            }
        }))
        setLoading(false)
    }
    useEffect(() => {
        getData()
    }, [])

    function handleEdit(e) {

        localStorage.setItem('@usersBlocked:data', JSON.stringify(e))
        navigate(`/usersBlockedEdit/${e?.id}`)
    }

    //filter functions
    async function handleClearFilter() {

        setCleanFilter(true)
        setLoading(true)
        setPaginationHistFilteredFieldsArr([]);
        setPaginationHistFilteredFields([])
        let fields = {}
        filters.map((column, index) => {
            fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
        })
        setPaginationFilteredFields(fields)
        getData();
        await new Promise(resolve => setTimeout(resolve, 200))
        setLoading(false)
        setCleanFilter(false)

    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {

        let modal = document.getElementById('tableFilterModal')
        if (modal) {
            modal.addEventListener('click', (e) => handleChangeModalStatus(e, ''))
            document.addEventListener('keydown', (e) => handleChangeModalStatus(e, 'keyboard'))
        }

        async function animateFilterModal(status) {

            let fields = {}
            filters.map((column, index) => {
                fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
            })
            setPaginationFilteredFields(fields)
            if (status) {
                let histFields = {}
                filters.map((column, index) => {
                    histFields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
                })
                setPaginationHistFilteredFields(fields)
                setShowFilterModal(true)
                await new Promise(resolve => setTimeout(resolve, 200))
                return setShowInternalFilterModal(true)
            }
            setShowFilterModal(false)
            setShowInternalFilterModal(false)
        }

        animateFilterModal(showFilterModal)

        if (modal) {
            return () => { modal.removeEventListener('click', (e) => handleChangeModalStatus(e)); modal.removeEventListener('keydown', (e) => handleChangeModalStatus(e)) }
        }

    }, [showFilterModal])

    function customFilter() {
        let ToFilterData = []
        let fieldsKeys = Object.keys(paginationFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationFilteredFields[key].value !== '') {
                ToFilterData.push(paginationFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
        }

        getData({ filter: { filterObject: ToFilterData }, direction: false })
        setShowFilterModal(false)
    }

    function handleChangeModalStatus(e, type) {

        if (e.code === 'Escape' && type === 'keyboard') {
            setShowFilterModal(false)
        }

        if (!tableFilterModal?.current?.contains(e.target) && type !== 'keyboard') {
            setShowFilterModal(false)
        }
    }

    function orderTableData(e) {
        const ToFilterData = []
        const fieldsKeys = Object.keys(paginationHistFilteredFields)
        fieldsKeys.map((key) => {
            if (paginationHistFilteredFields[key].value !== '') {
                ToFilterData.push(paginationHistFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
            return getData({ filter: { filterObject: ToFilterData }, direction: e?.direction, page: e.page })
        }
        getData(e)
    }

    const handleKeyPress = (event) => {

        event.preventDefault()
        if (event.key === 'Enter') {
            event.preventDefault()
            customFilter()
        }
    }

    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row w-full items-center justify-between">
                    <Title text={'Clientes bloqueados'} />
                    <Button shadow={true} onClick={() => navigate('/usersBlockedCreate')} approval={true} >+ Novo</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    showFilterModal &&
                    <div id='tableFilterModal' className='fixed left-0 top-0 z-[500] w-full h-full flex flex-col items-center justify-start'>
                        <div ref={tableFilterModal} className={`${showInternalFilterModal ? 'rounded-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark opacity-1 translate-x-0' : 'translate-x-[1000px] bg-transparent opacity-0'} top-28 absolute z-[501] p-6  boxShadow transition-all duration-300 flex flex-col`}>
                            <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center mb-8'>
                                <FaSearch />
                                <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                            </div>
                            {

                                filters.map(column => {
                                    return <div onKeyUp={(e) => handleKeyPress(e)}  className='flex flex-row gap-4'>
                                        <div className='w-28'>
                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{column.name}</p>
                                        </div>
                                        {
                                            column.type === 'text' ?
                                                <LabelInput>
                                                    <Input value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                        setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                    }}></Input>
                                                </LabelInput>
                                                :
                                                column.type === 'object' ?
                                                    <LabelInput>
                                                        <InputAutoComplete data={column.data} optionList={column.optionList} selectedLabel={column.selectedLabel} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                        }}></InputAutoComplete>
                                                    </LabelInput>
                                                    :
                                                    column.type === 'date' &&
                                                    <LabelInput>
                                                        <Input type={`date`} value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        }} />
                                                    </LabelInput>
                                        }
                                    </div>
                                })}
                            <div className='flex w-full flex-col items-end justify-start mt-6'>
                                <Button onClick={() => customFilter()} shadow={true}>Pesquisar</Button>
                            </div>

                        </div>
                        <div className={`${showInternalFilterModal ? 'bg-gray-700 opacity-75' : 'bg-transparent opacity-0'} absolute h-full w-full transition-all duration-300`}>
                        </div>
                    </div>
                }
                <div className="w-full flex flex-col items-start justify-start gap-2">
                    <div className="w-full flex flex-col items-start justify-start gap-4">
                        <div className="flex gap-2">
                            <Tippy content={'Filtrar'}
                                arrow={true}
                                animation='shift-away'
                                placement='top'>
                                <div className={`p-1 cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150 ${paginationHistFilteredFieldsArr?.length > 0 && 'bg-gray-300 rounded-lg'}`} onClick={() => setShowFilterModal(true)}>
                                    <FaFilter />
                                </div>
                            </Tippy>
                            {
                                paginationHistFilteredFieldsArr?.length > 0 &&
                                <Tippy content={'Excluir filtro'}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'>
                                    <div className='cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150' onClick={() => { handleClearFilter() }}>
                                        <FaBan />
                                    </div>
                                </Tippy>
                            }
                        </div>
                        <div id='usersBlockedTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                            <TableDefault
                                pagination={true}
                                totalPages={totalPages}
                                onClick={(e) => handleEdit(e)}
                                title={tableThead}
                                data={filteredUsers}
                                collumns={["blocked_users_name", "reason", 'schedulestoTable', , 'activitiesSubscriptionToTable', 'created_atToTable', 'expires_in_toTable']}
                                loading={loading}
                                filter={(e) => orderTableData(e)}
                            />
                        </div>
                    </div>
                </div>
            </Body>
        </Container>
    )
}