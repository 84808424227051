import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RedirectRoutes } from './routes/redirect.routes';
import { AuthContextProvider } from './contexts/useAuth'
import { ScreenSizeProvider } from './contexts/screenSizeContext'
import { ThemeContextProvider } from './contexts/themeContext';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { ApiProvider } from './contexts/ApiInterceptorContext';
import { GlobalContextProvider } from './contexts/globalContext';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GlobalContextProvider>
    <ApiProvider>
      <AuthContextProvider>
        <ThemeContextProvider>
          <DndProvider backend={HTML5Backend}>
            <ScreenSizeProvider>
              <RedirectRoutes />
            </ScreenSizeProvider>
          </DndProvider>
        </ThemeContextProvider>
      </AuthContextProvider>
    </ApiProvider>
  </GlobalContextProvider>
);
