// hooks 
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// components 
import { FaEdit } from 'react-icons/fa'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { TableDefault } from '../../../components/table/table.default'
import { TableMobile } from '../../../components/table/table.mobile'
import { Button } from '../../../components/buttons/button.default'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
//services
import { formattTimeToShow } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function Categories() {

    const { defaultCallsSchedules, defaultCallsAuth, api} = useApiContext()
    const [loading, setLoading] = useState(true)
    const [categoriesActiveButton, setCategoriesActiveButton] = useState(1)
    const [filteredCategories, setFilteredCategories] = useState([])
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const tableThead = [
        {
            "name": 'Categoria',
            "original_name": 'name'
        },
        {
            "name": 'Descrição',
            "original_name": 'description'
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        }
    ]
    const [categoriesList, setCategoriesList] = useState([])

    useEffect(() => {

        async function getData() {
            const categoriesData = await defaultCallsSchedules.getCategories(userData[0].token,1)

            if (categoriesData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(categoriesData.data)
            }

            let formattedCategorie = []
            categoriesData?.data.map((categorie) => {
                formattedCategorie.push(
                    {
                        ...categorie,
                        description: categorie?.description ? categorie?.description : '-',
                        scheduleType: categorie?.scheduleType === 'S' ? 'Serviço' : categorie?.scheduleType === 'C' ? 'Categoria' : categorie?.scheduleType,
                        created_at: formattTimeToShow(categorie?.created_at)
                    }
                )
            })
            setCategoriesList(formattedCategorie)
            setLoading(false)
        }

        getData()

    }, [])

    function requestNewPage(page) {

    }

    function categoriesEdit(categorie) {
        navigate(`/categoriesEdit/${categorie.id}`)
    }

    async function handleFilterActiveCategories(status) {
        
        if(status !== categoriesActiveButton){
            setLoading(true)
            const newUserList = await getCategories(userData[0].token, status)    
    
            status !== undefined ? setCategoriesList(newUserList.data) : setCategoriesList(categoriesList)
            status > 0 ? setCategoriesActiveButton(1) : setCategoriesActiveButton(0)
            setLoading(false)

        }

    }

    return (
        <Container>
            <TitlePage>
                <div className='flex flex-row gap-1 items-start justify-between w-full'>
                    <Title text={'Categorias de reserva'}></Title>
                    <Button shadow={true} onClick={() => navigate('/categoriesCreate')}>+ Nova categoria</Button>
                </div>
            </TitlePage>
            <Body>
                <div className='flex flex-row items-center justify-start w-full'>
                    <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${categoriesActiveButton === 1 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveCategories(1)}>Ativos</button>
                    <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${categoriesActiveButton === 0 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveCategories(0)}>Inativos</button>
                </div>
                {
                    loading ?
                        <div className='flex flex-col items-center justify-center'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div id='reservationCategoryTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow overflow-y-auto rounded-lg'>
                                <TableDefault 
                                    onClick={(e) => categoriesEdit(e)} 
                                    pagination={false} 
                                    title={tableThead} 
                                    data={categoriesList} 
                                    collumns={["name", "description", "created_at"]} 
                                    changePage={(page) => requestNewPage(page)} 
                                />
                            </div>
                            <div id='reservationCategoryTableContentMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                <TableMobile
                                    onClick={(e) => e.func.execute(e.data)}
                                    title={tableThead}                    
                                    data={categoriesList}
                                    collumns={["name", "description", "created_at"]}
                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": categoriesEdit }]}
                                />
                            </div>
                        </>
                }
            </Body>
        </Container>
    )
}