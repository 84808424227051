//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
//utils
import { justNumber } from '../../../utils/validators/justNumber'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { validateFields } from '../../../utils/form.validator'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import Tippy from '@tippyjs/react'
import { FaInfoCircle } from 'react-icons/fa'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function ProductsCreate() {

    const navigate = useNavigate()
    const { defaultCallsFinancial, api_financial } = useApiContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    const [productsFiscalTypes, setProductsFiscalTypes] = useState([])
    const [productsUnitMeasures, setProductsUnitMeasures] = useState([])
    const optionsUseStock = [
        {
            id: 1,
            description: 'SIM'
        },
        {
            id: 2,
            description: 'NÃO'
        }
    ]

    //selected variables
    const [products, setProducts] = useState({ name: '', fiscalType: '', categories_id: '', useStock: '', unitMeasure: '' })

    async function getData() {
        try {
            const productsUnitMeasureData = await api_financial.get("api/v1/financial/productsUnitMeasure", {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setProductsUnitMeasures(productsUnitMeasureData?.data?.data)
    
            const productsFiscalTypesData = await defaultCallsFinancial.getProductsFiscalTypers(userData[0].token)
            if (productsFiscalTypesData.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(productsFiscalTypesData.data)
            }
    
            setProductsFiscalTypes(productsFiscalTypesData?.data)
    
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    async function handleSubmit() {
        let requiredFields =
            [
                { "name": 'name', "value": products?.name, "required": true, "type": 'string' },
                { "name": 'code', "value": products?.code, "required": false, "type": 'string' },
                { "name": 'useStock', "value": products?.useStock, "required": true, "type": 'number' },
                { "name": 'unitMeasure', "value": products?.unitMeasure, "required": true, "type": 'string' },
                { "name": 'fiscalType', "value": products?.fiscalType, "required": true, "type": 'string' },
            ]
        //chamada API
        setLoading(true)
        try {

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            await api_financial.post(`/api/v1/financial/products`, {
                name: products?.name,
                description: products?.description ? products?.description : undefined,
                barCode: products?.barCode ? products?.barCode : undefined,
                fiscalType: products?.fiscalType ? products?.fiscalType : undefined,
                unitMeasure: products?.unitMeasure,
                useStock: products?.useStock === 1 ? true : false,
                sku: products?.sku ? products?.sku : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Produto criado com sucesso!")
            navigate('/financial/products')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <Title text={'Cadastro de produto'}></Title>
                </TitlePage>
                <Body hasFooter={true}>

                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <form className='flex flex-col gap-2 items-start justify-start sm:justify-center w-full'>                   
                                    <LabelInput text={'Nome: *'}>
                                        <Input id='name' type={'text'} value={products?.name} onChange={(e) => setProducts(prev => ({ ...prev, name: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Descrição:'}>
                                        <Input id='description' type={'text'} value={products?.description} onChange={(e) => setProducts(prev => ({ ...prev, description: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'SKU:'}>
                                        <Input id='sku' type={'text'} value={products?.sku} onChange={(e) => setProducts(prev => ({ ...prev, sku: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Código de barras:'}>
                                        <Input id='barCode' type={'text'} value={products?.barCode} onChange={(e) => setProducts(prev => ({ ...prev, barCode: justNumber(e.target.value) }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Utiliza estoque: *'}>
                                        <InputAutoComplete
                                            data={optionsUseStock}
                                            id={'useStock'}
                                            preSelectedValue={optionsUseStock.filter(type => type.id === products.useStock)[0]?.description}
                                            onChange={(e) => setProducts(prev => ({ ...prev, useStock: e.id }))}
                                            selectedLabel={'description'}
                                            optionList={['id', 'description']}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Unidade de Medida'} required={true}>
                                        <InputAutoComplete
                                            data={productsUnitMeasures}
                                            id='unitMeasure'
                                            value={products.unitMeasure ? productsUnitMeasures.filter(op => op.id == products.unitMeasure)[0].description : ''}
                                            onChange={(e) => setProducts(prev => ({ ...prev, unitMeasure: e.id }))}
                                            selectedLabel={'description'}
                                            optionList={["description"]}
                                        />
                                    </LabelInput>
                                    <div className='flex gap-1'>
                                        <LabelInput text={'Tipo fiscal: *'}>
                                            <InputAutoComplete
                                                data={productsFiscalTypes}
                                                id={'fiscalType'}
                                                preSelectedValue={productsFiscalTypes.filter(type => type.id === products.fiscalType)[0]?.description}
                                                onChange={(e) => setProducts(prev => ({ ...prev, fiscalType: e.id }))}
                                                selectedLabel={'description'}
                                                optionList={['id', 'description']}
                                            />
                                        </LabelInput>
                                        <Tippy
                                            content={<a>Responsável por definir as alíquotas de impostos no Brasil.</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                        >
                                            <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                <FaInfoCircle />
                                            </div>
                                        </Tippy>
                                    </div>
                                </form>
                            </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate('/financial/products')}>Cancelar</Button>
                        <Button shadow={true} approval={true} onClick={() => {
                            handleSubmit()
                        }}>Cadastrar</Button>
                    </div>
                </Footer>
            </Container>
        </>

    )
}