import React, { useEffect, useState } from 'react'
import { Body } from '../components/container/Body'
import { Container } from '../components/container/container'
import { Title } from '../components/titlePages/title'
import { TitlePage } from '../components/titlePages/title.page'
import { SliceGraph } from '../components/dashboard/slice'
import { AreaGraph } from '../components/dashboard/area'
import { useAuth } from '../contexts/useAuth'
import agendaRapidoLogo from '../assets/logos/loginLogo.svg'
import { getDashboardActivitiesSubscriptionsByActivitie, getDashboardActivitiesSubscriptionsGeneralData, getDashboardActivitiesSubscriptionsIncomeByActivitie, getDashboardActivitiesSubscriptionsRevenueOfYear, getDashboardGeneralData, getDashboardIncomeByCategory, getDashboardPaymentMethods, getDashboardPaymentMethodsActivities, getDashboardRevenueOfYear, getDashboardSchedulesPerCategory } from '../services/api/callAPIsFunctions/defaultCalls.api'
import { useThemeContext } from '../contexts/themeContext'
import { currencyPtBr } from '../utils/formatCurrency/currencyTextPTBR'
import { FaArrowDown, FaArrowUp, FaCalendar, FaCalendarMinus, FaCar, FaMoneyBill, FaMoneyCheck, FaRunning, FaUser } from 'react-icons/fa'
import { BsCurrencyDollar } from 'react-icons/bs'
import { LabelInput } from '../components/label/label.input'
import { InputAutoComplete } from '../components/input/input.autocomplete'
import { HorizontalBars } from '../components/dashboard/horizontalBars'
import { paymentMethods } from '../utils/payment/paymentMethods'
import { LoginLoader } from '../components/loaders/loginLoader'
import moment from 'moment'
import { SliceGraphMobile } from '../components/dashboard/sliceMobile'
import { useNavigate } from 'react-router-dom'
import { useApiContext } from '../contexts/ApiInterceptorContext'

export function Home() {

    const { api, defaultCallsSchedules } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loadingDashView, setLoadingDashView] = useState(false)
    const [loadingDash, setLoadingDash] = useState({ generalData: true, revenueOfYear: true, schedulesPerCategory: true, incomeByCategory: true, paymentsMethods: true })
    const [loadingDashActivities, setLoadingDashActivities] = useState({ generalData: true, revenueOfYear: true, activitiesSubscriptionsByActivitie: true, incomeByActivitie: true, paymentsMethods: true })
    const { userData, selectedModule } = useAuth()
    const navigate = useNavigate()

    //schedules
    const [generalData, setGeneralData] = useState('')
    const [revenueOfYear, setRevenueOfYear] = useState([])
    const [yearsOfRevenue, setYearsOfRevenue] = useState([])
    const [schedulesPerCategory, setSchedulesPerCategory] = useState([])
    const [incomeByCategory, setIncomeByCategory] = useState([])
    const [paymentMethodsDashboard, setPaymentMethodsDasboard] = useState([])
    const [paymentMethodsTotal, setPaymentMethodsTotal] = useState([])

    //activitiesSubscriptions
    const [activitiesSubscriptionsRevenueOfYear, setActivitiesSubscriptionsRevenueOfYear] = useState([])
    const [activitiesSubscriptionsGeneralData, setActivitiesSubscriptionsGeneralData] = useState(``)
    const [activitiesSubscriptionsByActivitie, setActivitiesSubscriptionsByActivitie] = useState([])
    const [incomeByActivitie, setIncomeByActivitie] = useState([])
    const [paymentMethodsActivities, setPaymentMethodsActivities] = useState([])
    const [paymentMethodsActivitiesTotal, setPaymentMethodsActivitiesTotal] = useState([])

    //selected
    const [selectedView, setSelectedView] = useState('schedules')
    const [dashSubscriptionsLoaded, setDashSubscriptionsLoaded] = useState(false)
    const [selectedYear, setSelectedYear] = useState({ id: moment()?.format("YYYY") })
    const [selectedViewIncomeByCategory, setSelectedViewIncomeByCategory] = useState({ id: 0, description: 'Mês' })
    const [selectedSchedulesPerCategoryView, setSelectedSchedulesPerCategoryView] = useState({ id: 0, description: 'Mês' })
    const [selectedPaymentMethodsView, setSelectedPaymentMethodsView] = useState({ id: 0, description: 'Mês' })

    const [selectedYearActivities, setSelectedYearActivities] = useState({ id: moment()?.format("YYYY") })
    const [selectedActivitiesSubscriptionsByActivitieView, setSelectedActivitiesSubscriptionsByActivitieView] = useState({ id: 0, description: 'Mês' })
    const [selectedViewIncomeByActivitie, setSelectedViewIncomeByActivitie] = useState({ id: 0, description: 'Mês' })
    const [selectedPaymentMethodsViewActivities, setSelectedPaymentMethodsViewActivities] = useState({ id: 0, description: 'Mês' })

    function getYearsOfrevenue() {

        const years = [];
        const currentYear = moment().year();
        setSelectedYear({ id: currentYear?.toString(), description: currentYear?.toString() })

        for (let i = currentYear - 5; i <= currentYear + 5; i++) {
            years.push({
                id: i?.toString(),
                description: i?.toString()
            });
        }

        setYearsOfRevenue(years)
    }

    async function getData() {
        //formata os dados para o autocomplete (5 anos para tras e para frente)    
        getYearsOfrevenue()

        //busca os dados de tela
        const generalDataAPI = await defaultCallsSchedules.getDashboardGeneralData(userData[0]?.token)

        if (generalDataAPI.error) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(generalDataAPI.data)
        }
        //dados gerais
        setGeneralData({
            ...generalDataAPI?.data[0],
            totalSchedulesInMonthDiff: Number(generalDataAPI?.data[0]?.totalSchedulesInMonth) - Number(generalDataAPI?.data[0]?.totalSchedulesInPastMonth),
            newUsersInMonthDiff: Number(generalDataAPI?.data[0]?.newUsersInMonth) - Number(generalDataAPI?.data[0]?.newUsersInPastMonth),
            schedulesCancelledInMonthDiff: Number(generalDataAPI?.data[0]?.schedulesCancelledInMonth) - Number(generalDataAPI?.data[0]?.schedulesCancelledInPastMonth),
            revenueOfMonthDiff: Number(generalDataAPI?.data[0]?.revenueOfMonth) - Number(generalDataAPI?.data[0]?.revenueOfPastMonth),
            revenueOfYearDiff: Number(generalDataAPI.data[0]?.revenueOfYear) - Number(generalDataAPI?.data[0]?.revenueOfPastYear),
        })
        setLoadingDash(prev => ({ ...prev, generalData: false }))

        const revenueThisYearAPI = await defaultCallsSchedules.getDashboardRevenueOfYear(userData[0]?.token, moment().format("YYYY"))
        if (revenueThisYearAPI.error) {

            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(revenueThisYearAPI.data)
        }
        //dados faturamento anual
        const ordenedRevenue = revenueThisYearAPI?.data.sort((a, b) => {
            if (a.year === b.year) {
              return a.month - b.month
            } else {
              return a.year - b.year
            }
        })
        
        setRevenueOfYear(ordenedRevenue.map(revenue => {
            return {
                name: revenue?.month_description,
                value: revenue?.revenue,
                year: revenue?.year
            }
        }))
        setLoadingDash(prev => ({ ...prev, revenueOfYear: false }))

        const schedulesPerCategoryAPI = await defaultCallsSchedules.getDashboardSchedulesPerCategory(userData[0]?.token, moment()?.month() + 1)
        if (schedulesPerCategoryAPI.error) {

            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(schedulesPerCategoryAPI.data)
        }
        //dados de reservas por categoria        
        setSchedulesPerCategory((schedulesPerCategoryAPI?.data?.map(scheduleCategory => {
            if (Number(scheduleCategory?.quantity) > 0) {
                return {
                    name: scheduleCategory?.categories_name,
                    total: Number(scheduleCategory?.quantity)
                }
            }
        })).filter(Boolean))
        setLoadingDash(prev => ({ ...prev, schedulesPerCategory: false }))

        const incomeByCategoryAPI = await defaultCallsSchedules.getDashboardIncomeByCategory(userData[0]?.token)
        if (incomeByCategoryAPI.error) {

            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(incomeByCategoryAPI.data)
        }

        //dados de rendimento por categoria (mensal ou anual)
        setIncomeByCategory(incomeByCategoryAPI?.data?.map(income => {
            if (income?.totalValue > 0) {
                return {
                    name: income?.categories_name,
                    value: income?.totalValue
                }
            }
        }).filter(Boolean))

        setLoadingDash(prev => ({ ...prev, incomeByCategory: false }))

        const paymentMethodsAPI = await defaultCallsSchedules.getDashboardPaymentMethods(userData[0]?.token)
        if (paymentMethodsAPI.error) {

            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(paymentMethodsAPI.data)
        }

        let total = 0
        setPaymentMethodsDasboard(paymentMethodsAPI?.data?.map(method => {
            total += Number(method?.quantity)
            return {
                ...method,
                value: Number(method?.quantity),
                name: method?.paymentMethod === "CD" ? "Cartão de débito" :
                    method?.paymentMethod === "CE" || method?.paymentMethod === "C" ? "Cartão de Crédito" :
                        method?.paymentMethod === "D" ? "Dinheiro" :
                            method?.paymentMethod === "P" ? "Pix" :
                                method?.paymentMethod === "F" ? "Desconto em folha" : '',

            }
        }))
        setPaymentMethodsTotal(total)
        setLoadingDash(prev => ({ ...prev, paymentsMethods: false }))

    }
    useEffect(() => {
        if(selectedModule.includes(3)){
            navigate('/financial')
        }
        async function delay() {
            await new Promise(resolve => setTimeout(resolve, 300))
        }
        userData[0].token.length > 0 && delay()
        if (userData[0]?.permissions.includes('dashboards:read') || userData[0]?.typeAccess_id < 3) {
            getData()
        }

    }, [userData])
    //atualiza dados do relatorio "resultado anual"
    async function updateYearDashboardForAnualRevuenue(year) {

        setLoadingDash(prev => ({ ...prev, revenueOfYear: true }))
        setSelectedYear(year)
        const revenueThisYearAPI = await defaultCallsSchedules.getDashboardRevenueOfYear(userData[0]?.token, year?.description)
        if (revenueThisYearAPI.error) {

            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(revenueThisYearAPI.data)
        }

        const ordenedRevenue = revenueThisYearAPI?.data.sort((a, b) => {
            if (a.year === b.year) {
              return a.month - b.month
            } else {
              return a.year - b.year
            }
        })

        setRevenueOfYear(ordenedRevenue.map(revenue => {
            return {
                name: revenue?.month_description,
                value: revenue?.revenue,
                year: revenue?.year
            }
        }))
        setLoadingDash(prev => ({ ...prev, revenueOfYear: false }))
    }
    //atualiza dados do relatorio "Mais rentavel"
    async function handleUpdateIncomeByCategory(view) {

        setSelectedViewIncomeByCategory(view)
        setLoadingDash(prev => ({ ...prev, incomeByCategory: true }))
        let newData = []
        //visao mensal
        if (view.id === 0) {
            newData = await defaultCallsSchedules.getDashboardIncomeByCategory(userData[0]?.token)
        }
        //visao anual
        else if (view.id === 1) {
            newData = await defaultCallsSchedules.getDashboardIncomeByCategory(userData[0]?.token, moment().format("YYYY"))
        }

        setIncomeByCategory(newData?.data?.map(income => {
            if (income?.totalValue > 0) {
                return {
                    name: income?.categories_name,
                    value: Number(income?.totalValue)
                }
            }
        }).filter(Boolean))
        setLoadingDash(prev => ({ ...prev, incomeByCategory: false }))
    }
    //atualiza dados do relatório "reservas por categoria"
    async function handleChangeSchedulesPerCategoryView(view) {

        setLoadingDash(prev => ({ ...prev, schedulesPerCategory: true }))
        setSelectedSchedulesPerCategoryView(view)
        let newData = []
        if (view.id === 0) {
            newData = await defaultCallsSchedules.getDashboardSchedulesPerCategory(userData[0]?.token, moment()?.month() + 1)
        } else if (view.id === 1) {
            newData = await defaultCallsSchedules.getDashboardSchedulesPerCategory(userData[0]?.token)
        }

        setSchedulesPerCategory((newData?.data?.map(scheduleCategory => {
            if (Number(scheduleCategory?.quantity) > 0) {
                return {
                    name: scheduleCategory?.categories_name,
                    total: Number(scheduleCategory?.quantity)
                }
            }
        })).filter(Boolean))
        setLoadingDash(prev => ({ ...prev, schedulesPerCategory: false }))

    }
    //atualiza dados do relatório "formas de pagamento"
    async function handleChangePaymentMethodsView(view) {
        let newData = []
        let total = 0
        setSelectedPaymentMethodsView(view)
        setLoadingDash(prev => ({ ...prev, paymentsMethods: true }))
        if (view.id === 0) {
            newData = await defaultCallsSchedules.getDashboardPaymentMethods(userData[0]?.token)
        } else if (view.id === 1) {
            newData = await defaultCallsSchedules.getDashboardPaymentMethods(userData[0]?.token, moment()?.year())
        }

        setPaymentMethodsDasboard(newData?.data?.map(method => {
            total += Number(method?.quantity)
            return {
                ...method,
                value: Number(method?.quantity),
                name: method?.paymentMethod === "CD" ? "Cartão de débito" :
                    method?.paymentMethod === "CE" || method?.paymentMethod === "C" ? "Cartão de Crédito" :
                        method?.paymentMethod === "D" ? "Dinheiro" :
                            method?.paymentMethod === "P" ? "Pix" :
                                method?.paymentMethod === "F" ? "Desconto em folha" : '',

            }
        }))
        setPaymentMethodsTotal(total)
        setLoadingDash(prev => ({ ...prev, paymentsMethods: false }))

    }
    //visao inscrições
    async function getActivitiesDashboards() {

        setSelectedView('acitivitesSubscriptions')
        if (!dashSubscriptionsLoaded) {
            const newData = await defaultCallsSchedules.getDashboardActivitiesSubscriptionsGeneralData(userData[0]?.token)
            if (newData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(newData.data)
            }
            setActivitiesSubscriptionsGeneralData

            //dados gerais                        
            setActivitiesSubscriptionsGeneralData({
                ...newData?.data[0],
                totalNewActivitiesInMonthDiff: Number(newData?.data[0]?.totalNewActivitiesInMonth) - Number(newData?.data[0]?.totalNewActivitiesInPastMonth),
                newUsersInMonthDiff: Number(newData?.data[0]?.newUsersInMonth) - Number(newData?.data[0]?.newUsersInPastMonth),
                activitiesSubscriptionsFinishedInMonthDiff: Number(newData?.data[0]?.activitiesSubscriptionsFinishedInMonth) - Number(newData?.data[0]?.activitiesSubscriptionsFinishedInPastMonth),
                revenueOfMonthDiff: Number(newData?.data[0]?.revenueOfMonth) - Number(newData?.data[0]?.revenueOfPastMonth),
                revenueOfYearDiff: Number(newData.data[0]?.revenueOfYear) - Number(newData?.data[0]?.revenueOfPastYear),
            })
            setLoadingDashActivities(prev => ({ ...prev, generalData: false }))

            const revenueThisYearAPI = await defaultCallsSchedules.getDashboardActivitiesSubscriptionsRevenueOfYear(userData[0]?.token, moment().format("YYYY"))
            if (revenueThisYearAPI.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(revenueThisYearAPI.data)
            }
            //dados de faturamento anual
            const ordenedRevenue = revenueThisYearAPI?.data.sort((a, b) => {
                if (a.year === b.year) {
                  return a.month - b.month
                } else {
                  return a.year - b.year
                }
            })
            setActivitiesSubscriptionsRevenueOfYear(ordenedRevenue?.map(revenue => {
                return {
                    name: revenue?.month_description,
                    value: revenue?.revenue,
                    year: revenue?.year
                }
            }))

            setLoadingDashActivities(prev => ({ ...prev, revenueOfYear: false }))

            const activitiesSubscriptionsByActivitieAPI = await defaultCallsSchedules.getDashboardActivitiesSubscriptionsByActivitie(userData[0]?.token)
            if (activitiesSubscriptionsByActivitieAPI.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(activitiesSubscriptionsByActivitieAPI.data)
            }
            setActivitiesSubscriptionsByActivitie(activitiesSubscriptionsByActivitieAPI?.data?.map(activitie => {
                return {
                    name: activitie?.activitiesName,
                    total: Number(activitie.quantity)
                }
            }).filter(Boolean))

            setLoadingDashActivities(prev => ({ ...prev, activitiesSubscriptionsByActivitie: false }))
            const incomeByActivitie = await defaultCallsSchedules.getDashboardActivitiesSubscriptionsIncomeByActivitie(userData[0]?.token, moment().format("YYYY"))
            if (incomeByActivitie.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(incomeByActivitie.data)
            }

            //dados de rendimento por atividade (mensal ou anual)
            setIncomeByActivitie(incomeByActivitie?.data?.map(income => {
                if (income?.totalValue > 0) {
                    return {
                        name: income?.activitiesName,
                        value: income?.totalValue
                    }
                }
            }).filter(Boolean))

            setLoadingDashActivities(prev => ({ ...prev, incomeByActivitie: false }))

            //dados de formas de pagamentos
            const paymentMethodsAPI = await defaultCallsSchedules.getDashboardPaymentMethodsActivities(userData[0]?.token)
            if (paymentMethodsAPI.error) {

                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(paymentMethodsAPI.data)
            }

            let total = 0
            setPaymentMethodsActivities(paymentMethodsAPI?.data?.map(method => {
                total += Number(method?.quantity)
                return {
                    ...method,
                    value: Number(method?.quantity),
                    name: method?.paymentMethod === "CD" ? "Cartão de débito" :
                        method?.paymentMethod === "CE" ? "Cartão de Crédito" :
                            method?.paymentMethod === "D" ? "Dinheiro" :
                                method?.paymentMethod === "P" ? "Pix" :
                                    method?.paymentMethod === "F" ? "Desconto em folha" : '',

                }
            }))
            setPaymentMethodsActivitiesTotal(total)
            setLoadingDashActivities(prev => ({ ...prev, paymentsMethods: false }))
            setDashSubscriptionsLoaded(true)
        }
    }
    //atualiza dados do relatorio "resultado anual" atividades
    async function updateYearDashboardForAnualRevuenueActivities(year) {
        setLoadingDashActivities(prev => ({ ...prev, revenueOfYear: true }))
        setSelectedYearActivities(year)
        const revenueThisYearAPI = await defaultCallsSchedules.getDashboardActivitiesSubscriptionsRevenueOfYear(userData[0]?.token, year?.description)
        if (revenueThisYearAPI.error) {

            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(revenueThisYearAPI.data)
        }

        const ordenedRevenue = revenueThisYearAPI?.data.sort((a, b) => {
            if (a.year === b.year) {
              return a.month - b.month
            } else {
              return a.year - b.year
            }
        })
        setActivitiesSubscriptionsRevenueOfYear(ordenedRevenue?.map(revenue => {
            return {
                name: revenue?.month_description,
                value: revenue?.revenue,
                year: revenue?.year
            }
        }))
        setLoadingDashActivities(prev => ({ ...prev, revenueOfYear: false }))
    }
    //atualiza dados do relatório "inscriçoes por atividade"
    async function handleChangeActivitiesSubscriptionsByActivitieView(view) {

        setLoadingDashActivities(prev => ({ ...prev, activitiesSubscriptionsByActivitie: true }))
        setSelectedSchedulesPerCategoryView(view)
        let newData = []
        if (view.id === 0) {
            newData = await defaultCallsSchedules.getDashboardActivitiesSubscriptionsByActivitie(userData[0]?.token, moment()?.month() + 1)
        } else if (view.id === 1) {
            newData = await defaultCallsSchedules.getDashboardActivitiesSubscriptionsByActivitie(userData[0]?.token)
        }

        setActivitiesSubscriptionsByActivitie(newData?.data?.map(activitie => {
            return {
                name: activitie?.activitiesName,
                total: Number(activitie.quantity)
            }
        }).filter(Boolean))

        setLoadingDashActivities(prev => ({ ...prev, activitiesSubscriptionsByActivitie: false }))
    }
    //atualiza dados do relatorio mais rentavel de inscricoes
    async function handleUpdateIncomeByActivitie(view) {
        setSelectedViewIncomeByActivitie(view)
        setLoadingDashActivities(prev => ({ ...prev, incomeByActivitie: true }))
        let newData = []
        //visao mensal
        if (view.id === 0) {
            newData = await defaultCallsSchedules.getDashboardActivitiesSubscriptionsIncomeByActivitie(userData[0]?.token)
        }
        //visao anual
        else if (view.id === 1) {
            newData = await defaultCallsSchedules.getDashboardActivitiesSubscriptionsIncomeByActivitie(userData[0]?.token, moment().format("YYYY"))
        }

        setIncomeByActivitie(newData?.data?.map(income => {
            if (income?.totalValue > 0) {
                return {
                    name: income?.categories_name,
                    value: Number(income?.totalValue)
                }
            }
        }).filter(Boolean))
        setLoadingDashActivities(prev => ({ ...prev, incomeByActivitie: false }))
    }
    //atualiza dados do relatório "formas de pagamento" atividades
    async function handleChangePaymentMethodsViewActivities(view) {
        let newData = []
        let total = 0
        setSelectedPaymentMethodsViewActivities(view)
        setLoadingDashActivities(prev => ({ ...prev, paymentsMethods: true }))
        if (view.id === 0) {
            newData = await defaultCallsSchedules.getDashboardPaymentMethodsActivities(userData[0]?.token)
        } else if (view.id === 1) {
            newData = await defaultCallsSchedules.getDashboardPaymentMethodsActivities(userData[0]?.token, moment()?.year())
        }

        setPaymentMethodsActivities(newData?.data?.map(method => {
            total += Number(method?.quantity)
            return {
                ...method,
                value: Number(method?.quantity),
                name: method?.paymentMethod === "CD" ? "Cartão de débito" :
                    method?.paymentMethod === "CE" ? "Cartão de Crédito" :
                        method?.paymentMethod === "D" ? "Dinheiro" :
                            method?.paymentMethod === "P" ? "Pix" :
                                method?.paymentMethod === "F" ? "Desconto em folha" : '',

            }
        }))
        setPaymentMethodsActivitiesTotal(total)
        setLoadingDashActivities(prev => ({ ...prev, paymentsMethods: false }))

    }
    //altera a visão do dashboard
    async function changeViewMode(mode) {

        setLoadingDashView(true)
        await new Promise(resolve => setTimeout(resolve, 200))

        switch (mode) {
            case 'activitiesSubscriptions':
                await getActivitiesDashboards()
                setLoadingDashView(false)
                break;
            case 'schedules':
                setSelectedView('schedules')
                setLoadingDashView(false)
                break;
            default:
                break;
        }
    }

    return (
        <Container>
            <TitlePage>
                <Title text={userData[0]?.permissions.includes('dashboards:read') || userData[0]?.typeAccess_id < 3 ? 'Dashboard' : 'Início'}></Title>
            </TitlePage>
            <Body shadow={false} background={false} padding={false}>
                {
                    userData[0]?.permissions.includes('dashboards:read') || userData[0]?.typeAccess_id < 3 ?
                        <div className='flex flex-col w-full gap-2'>
                            <h1 className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Relatórios de:</h1>
                            <div className='w-min'>
                                <div className='select-none mb-6 flex items-center justify-center overflow-hidden rounded-md shadow-lg'>
                                    <div id={'viewSchedules'} key={'viewSchedules'} onClick={() => changeViewMode('schedules')} className={`p-1 ${selectedView === 'schedules' ? 'bg-primaryDefaultLight text-zinc-100' : 'bg-gray-200 opacity-50'}  flex items-center justify-center cursor-pointer w-20 transition-all duration-200`}>
                                        <a className='text-sm'>Reservas</a>
                                    </div>
                                    {
                                        userData[0]?.customersData?.modules?.map(module => { return module.module }).includes('activitiesSubscriptions') &&
                                        <div id={'viewSubsctriptions'} key={'viewSubsctriptions'} onClick={() => changeViewMode('activitiesSubscriptions')} className={`p-1 ${selectedView === 'acitivitesSubscriptions' ? 'bg-primaryDefaultLight text-zinc-100 ' : 'bg-gray-200 opacity-50'}  flex items-center justify-center cursor-pointer w-20 transition-all duration-200`}>
                                            <a className='text-sm'>Inscrições</a>
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                loadingDashView ?
                                    <div className='flex flex-col w-full gap-2 h-96 items-center justify-center'>
                                        <LoginLoader />
                                    </div>
                                    :
                                    selectedView === 'schedules' ?
                                        <div className='flex flex-col w-full gap-3 items-start lg:justify-center h-full'>
                                            <div className='grid grid-cols-1 lg:grid-cols-6 gap-4 w-full'>
                                                <div className=' p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                                    {
                                                        loadingDash['generalData'] ?
                                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                            :
                                                            <>
                                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                                    <p className='text-lg md:text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{generalData?.totalSchedulesInMonth}</p>
                                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Reservas mês</p>
                                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${generalData?.totalSchedulesInMonthDiff > 0 ? 'text-primaryDefaultLight' : 'text-red-300'} `}>
                                                                        {
                                                                            generalData?.totalSchedulesInMonthDiff > 0 ?
                                                                                <FaArrowUp />
                                                                                :
                                                                                <FaArrowDown />
                                                                        }
                                                                        <p className='text-xs font-semibold opacity-70'>{generalData?.totalSchedulesInMonthDiff}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='text-zinc-100 gap-2 flex flex-col items-center justify-center rounded-2xl w-10 h-10 bg-primaryDefaultLight shadow-sm opacity-70'>
                                                                    <FaCalendar />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div className='p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                                    {
                                                        loadingDash['generalData'] ?
                                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                            :
                                                            <>
                                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{generalData?.newUsersInMonth}</p>
                                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Novos clientes</p>
                                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${generalData?.newUsersInMonthDiff > 0 ? 'text-primaryDefaultLight' : 'text-red-300'} `}>
                                                                        {
                                                                            generalData?.newUsersInMonthDiff > 0 ?
                                                                                <FaArrowUp />
                                                                                :
                                                                                <FaArrowDown />

                                                                        }
                                                                        <p className='text-xs font-semibold opacity-70'>{generalData?.newUsersInMonthDiff}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='text-zinc-100 gap-2 flex flex-col items-center justify-center rounded-2xl w-10 h-10 bg-primaryDefaultLight shadow-sm opacity-70'>
                                                                    <FaUser />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div className=' p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                                    {
                                                        loadingDash['generalData'] ?
                                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                            :
                                                            <>
                                                                <div className='flex flex-col items-start justify-start gap-1'>

                                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{generalData?.schedulesCancelledInMonth}</p>
                                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Reservas canceladas</p>
                                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${generalData?.schedulesCancelledInMonthDiff > 0 ? 'text-red-300' : 'text-primaryDefaultLight'}`}>
                                                                        {
                                                                            generalData?.schedulesCancelledInMonthDiff > 0 ?
                                                                                < FaArrowUp />
                                                                                :
                                                                                <FaArrowDown />
                                                                        }
                                                                        <p className='text-xs font-semibold opacity-70'>{generalData?.schedulesCancelledInMonthDiff}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='text-zinc-100 gap-2 flex flex-col items-center justify-center rounded-2xl w-10 h-10 bg-primaryDefaultLight opacity-70 shadow-sm'>
                                                                    <FaCalendarMinus />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div className=' p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                                    {
                                                        loadingDash['generalData'] ?
                                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                            :
                                                            <>
                                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{!generalData?.pendingPayments ? 0 : currencyPtBr(generalData?.pendingPayments)}</p>
                                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Pagamentos pendentes</p>
                                                                </div>
                                                                <div className='text-zinc-100 gap-2 flex flex-col items-center justify-center rounded-2xl w-10 h-10 bg-primaryDefaultLight opacity-70 shadow-sm'>
                                                                    <FaMoneyBill />
                                                                </div>
                                                            </>
                                                    }

                                                </div>
                                                <div className=' p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                                    {
                                                        loadingDash['generalData'] ?
                                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                            :
                                                            <>
                                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{!generalData?.revenueOfMonth ? currencyPtBr(0) : currencyPtBr(generalData?.revenueOfMonth)}</p>
                                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Receita do mês</p>
                                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${generalData?.revenueOfMonthDiff > 0 ? 'text-primaryDefaultLight' : 'text-red-300'}`}>
                                                                        {
                                                                            generalData?.revenueOfMonthDiff > 0 ?
                                                                                <FaArrowUp />
                                                                                :
                                                                                <FaArrowDown />
                                                                        }
                                                                        <p className='text-xs font-semibold opacity-70'>{
                                                                            currencyPtBr(generalData?.revenueOfMonthDiff)
                                                                        }</p>
                                                                    </div>
                                                                </div>
                                                                <div className='text-zinc-100 gap-2 flex flex-col items-center justify-center rounded-2xl w-10 h-10 bg-primaryDefaultLight opacity-70 shadow-sm'>
                                                                    <BsCurrencyDollar />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div className=' p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                                    {
                                                        loadingDash['generalData'] ?
                                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                            :
                                                            <>
                                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{!generalData?.revenueOfYear ? currencyPtBr(0) : currencyPtBr(generalData?.revenueOfYear)}</p>
                                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Receita do ano</p>
                                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${generalData?.revenueOfYearDiff > 0 ? 'text-primaryDefaultLight' : 'text-red-300'}`}>
                                                                        {
                                                                            generalData?.revenueOfYearDiff > 0 ?
                                                                                <FaArrowUp />
                                                                                :
                                                                                <FaArrowDown />
                                                                        }
                                                                        <p className='text-xs font-semibold opacity-70'>{
                                                                            currencyPtBr(generalData?.revenueOfYearDiff)
                                                                        }</p>
                                                                    </div>
                                                                </div>
                                                                <div className='text-zinc-100 gap-2 flex flex-col items-center justify-center rounded-2xl w-10 h-10 bg-primaryDefaultLight opacity-70 shadow-sm'>
                                                                    <BsCurrencyDollar />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                            <div className='flex lg:flex-row flex-col gap-4 w-full'>
                                                <div className='flex flex-col shadow-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark p-2 lg:p-3 w-full xl:w-[50%] border border-zinc-200 dark:border dark:border-primaryBorderDark rounded-lg gap-2 items-'>
                                                    <div className='flex flex-col gap-1 justify-start items-start w-full'>
                                                        <h1 className='dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold text-lg xl:text-xl 2xl:text-2xl'>Resultado anual</h1>
                                                        {
                                                            <LabelInput text={'Ano'}>
                                                                <InputAutoComplete
                                                                    width={56}
                                                                    data={yearsOfRevenue}
                                                                    preSelectedValue={selectedYear?.id}
                                                                    selectedLabel={'id'}
                                                                    optionList={['id']}
                                                                    onChange={(e) => updateYearDashboardForAnualRevuenue(e)}
                                                                />
                                                            </LabelInput>
                                                        }
                                                    </div>
                                                    {
                                                        loadingDash['revenueOfYear'] ?
                                                            <div style={{ minHeight: '365px', height: '365px' }} className='w-full items-end justify-center animate-pulse flex flex-row gap-3'>
                                                                <div className='bg-gray-300 h-44 w-12'></div>
                                                                <div className='bg-gray-300 h-48 w-12'></div>
                                                                <div className='bg-gray-300 h-32 w-12'></div>
                                                                <div className='bg-gray-300 h-28 w-12'></div>
                                                                <div className='bg-gray-300 h-56 w-12'></div>
                                                                <div className='bg-gray-300 h-48 w-12'></div>
                                                                <div className='bg-gray-300 h-64 w-12'></div>
                                                                <div className='bg-gray-300 h-44 w-12'></div>
                                                                <div className='bg-gray-300 h-32 w-12'></div>
                                                                <div className='bg-gray-300 h-28 w-12'></div>
                                                            </div>
                                                            :
                                                            <div style={{ maxHeight: '365px', height: '365px' }} className='overflow-hidden'>
                                                                <AreaGraph
                                                                    id='schedulesAreaGraph'
                                                                    title={'Análise anual'}
                                                                    data={revenueOfYear?.filter(data => Number(data?.year) === Number(selectedYear?.id))}
                                                                    data2={revenueOfYear?.filter(data => Number(data?.year) === Number(selectedYear?.id - 1))}
                                                                />
                                                            </div>
                                                    }
                                                </div>
                                                <div className='relative shadow-lg p-2 lg:p-3 bg-bgPrimaryLight dark:bg-secondaryDefaultDark flex flex-col items-center xl:justify-center w-full xl:w-[50%] border border-zinc-200 dark:border dark:border-primaryBorderDark rounded-lg gap-2'>
                                                    <div className='relative flex flex-col gap-2 w-full items-center h-full justify-between '>
                                                        <div className='w-full flex flex-col gap-1 items-start justify-start '>
                                                            <h1 className='dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold text-lg xl:text-xl 2xl:text-2xl '>Reservas por categoria</h1>
                                                            <LabelInput text={'Visão'}>
                                                                <InputAutoComplete
                                                                    preSelectedValue={'Mês'}
                                                                    data={[{ id: 0, description: 'Mês' }, { id: 1, description: 'Ano' }]}
                                                                    selectedLabel={'description'}
                                                                    onChange={(e) => handleChangeSchedulesPerCategoryView(e)}
                                                                    width={56}
                                                                />
                                                            </LabelInput>
                                                        </div>
                                                        {
                                                            loadingDash['schedulesPerCategory'] ?
                                                                <div className='w-full animate-pulse flex flex-col items-center justify-center gap-4 h-full'>
                                                                    <div className='flex sm:hidden bg-gray-300 h-32 w-32 rounded-full' />
                                                                    <div className='hidden sm:flex bg-gray-300 h-56 w-56 rounded-full' />
                                                                </div>
                                                                : schedulesPerCategory?.length > 0 ?
                                                                    <>
                                                                        <div className='hidden sm:flex items-center justify-center h-full'>
                                                                            <SliceGraph id='schedulesPerCategory' title={'Locações por setor'} data={schedulesPerCategory} />
                                                                        </div>
                                                                        <div className='flex sm:hidden items-center justify-center h-[500px] w-[400px]'>
                                                                            <SliceGraphMobile id='schedulesPerCategoryMobile' title={'Locações por setor'} data={schedulesPerCategory} />
                                                                        </div>
                                                                    </> :
                                                                    <div className='h-full w-full flex flex-col items-center justify-center'>
                                                                        <h2 className="text-gray-500 text-base">Nenhuma informação encontrada</h2>
                                                                    </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='relative flex lg:flex-row flex-col gap-4 w-full'>
                                                <div className='shadow-lg p-2 lg:p-3 bg-bgPrimaryLight dark:bg-secondaryDefaultDark flex flex-col items-center xl:justify-center w-full xl:w-[50%] border border-zinc-200 dark:border dark:border-primaryBorderDark rounded-lg gap-2'>
                                                    <div className='flex flex-col gap-2 w-full items-center h-full justify-between '>
                                                        <div className='w-full gap-1 flex flex-col items-start justify-start'>
                                                            <h1 className='dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold text-lg xl:text-xl 2xl:text-2xl '>Mais rentável</h1>
                                                            <LabelInput text={'Visão'}>
                                                                <InputAutoComplete
                                                                    width={56}
                                                                    data={[{ id: 0, description: 'Mês' }, { id: 1, description: 'Ano' }]}
                                                                    selectedLabel={'description'}
                                                                    preSelectedValue={'Mês'}
                                                                    optionList={['description']}
                                                                    onChange={(e) => handleUpdateIncomeByCategory(e)}
                                                                />
                                                            </LabelInput>
                                                        </div>

                                                        {
                                                            loadingDash['incomeByCategory'] ?
                                                                <div className='w-full animate-pulse flex flex-col items-start justify-center gap-4 h-full'>
                                                                    <div className='bg-gray-300 h-10 w-full'></div>
                                                                    <div className='bg-gray-300 h-8 w-[70%]'></div>
                                                                    <div className='bg-gray-300 h-6 w-[60%]'></div>
                                                                </div>
                                                                :
                                                                <div className='flex flex-col items-center justify-start max-h-[365px] overflow-x-hidden  w-full'>
                                                                    <HorizontalBars data={incomeByCategory} />
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='shadow-lg p-2 lg:p-3 bg-bgPrimaryLight dark:bg-secondaryDefaultDark flex flex-col items-center xl:justify-center w-full xl:w-[50%] border border-zinc-200 dark:border dark:border-primaryBorderDark rounded-lg gap-2'>
                                                    <div className='flex flex-col gap-2 w-full items-center h-full justify-between '>
                                                        <div className='w-full flex flex-col gap-1 items-start justify-start '>
                                                            <h1 className='dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold text-lg xl:text-xl 2xl:text-2xl '>Formas de pagamento</h1>
                                                            <LabelInput text={'Visão'}>
                                                                <InputAutoComplete
                                                                    width={56}
                                                                    preSelectedValue={"Mês"}
                                                                    data={[{ id: 0, description: 'Mês' }, { id: 1, description: 'Ano' }]}
                                                                    selectedLabel={'description'}
                                                                    optionList={['description']}
                                                                    onChange={(e) => handleChangePaymentMethodsView(e)}
                                                                />
                                                            </LabelInput>
                                                        </div>
                                                        {
                                                            loadingDash['paymentsMethods'] ?

                                                                <div className='w-full animate-pulse flex flex-col items-start justify-center gap-4 h-full'>
                                                                    <div className='bg-gray-300 h-10 w-full'></div>
                                                                    <div className='bg-gray-300 h-8 w-[70%]'></div>
                                                                    <div className='bg-gray-300 h-6 w-[60%]'></div>
                                                                </div>
                                                                :
                                                                <div className='mt-4 sm:mt-0 grid grid-cols-2 sm:grid-cols-3 items-center justify-center px-2 h-full w-full'>
                                                                    {
                                                                        paymentMethods.map(method => {
                                                                            const quantity = paymentMethodsDashboard.filter(payment => payment.paymentMethod === method.value)?.length > 0 ? paymentMethodsDashboard.filter(payment => payment.paymentMethod === method.value)[0].quantity : 0
                                                                            return <div id={`paymentMethod-${method.description}`} key={`paymentMethod-${method.description}`} className='flex flex-col gap-1 items-start justify-start'>
                                                                                <a className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{method?.description}</a>
                                                                                {/* <div className={`w-40 h-4 rounded-lg ${method.color}`}></div> */}
                                                                                <div style={{ width: '150px' }} className='bg-zinc-100 dark:bg-thirdDefaultDark flex items-start justify-start rounded-lg'>
                                                                                    <div style={{ width: (quantity / paymentMethodsTotal) * 100 + '%' }} className={` h-4 rounded-lg ${method.color}`}></div>
                                                                                </div>
                                                                                <a className='text-lg text-titleBlackTextLight dark:text-titleGrayTextDark'>{quantity}</a>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='flex flex-col w-full gap-3 items-start lg:justify-center h-full'>
                                            <div className='grid grid-cols-1 lg:grid-cols-6 gap-4 w-full'>
                                                <div className=' p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                                    {
                                                        loadingDashActivities['generalData'] ?
                                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                            :
                                                            <>
                                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{activitiesSubscriptionsGeneralData?.totalNewActivitiesInMonth}</p>
                                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Inscrições mês</p>
                                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${activitiesSubscriptionsGeneralData?.totalNewActivitiesInMonthDiff > 0 ? 'text-primaryDefaultLight' : 'text-red-300'} `}>
                                                                        {
                                                                            activitiesSubscriptionsGeneralData?.totalNewActivitiesInMonthDiff > 0 ?
                                                                                <FaArrowUp />
                                                                                :
                                                                                <FaArrowDown />
                                                                        }
                                                                        <p className='text-xs font-semibold opacity-70'>{activitiesSubscriptionsGeneralData?.totalNewActivitiesInMonthDiff}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='text-zinc-100 gap-2 flex flex-col items-center justify-center rounded-2xl w-10 h-10 bg-primaryDefaultLight shadow-sm opacity-70'>
                                                                    <FaRunning />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div className='p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                                    {
                                                        loadingDashActivities['generalData'] ?
                                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                            :
                                                            <>
                                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{activitiesSubscriptionsGeneralData?.newUsersInMonth}</p>
                                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Novos clientes</p>
                                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${activitiesSubscriptionsGeneralData?.newUsersInMonthDiff > 0 ? 'text-primaryDefaultLight' : 'text-red-300'} `}>
                                                                        {
                                                                            activitiesSubscriptionsGeneralData?.newUsersInMonthDiff > 0 ?
                                                                                <FaArrowUp />
                                                                                :
                                                                                <FaArrowDown />

                                                                        }
                                                                        <p className='text-xs font-semibold opacity-70'>{activitiesSubscriptionsGeneralData?.newUsersInMonthDiff}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='text-zinc-100 gap-2 flex flex-col items-center justify-center rounded-2xl w-10 h-10 bg-primaryDefaultLight shadow-sm opacity-70'>
                                                                    <FaUser />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div className='p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                                    {
                                                        loadingDashActivities['generalData'] ?
                                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                            :
                                                            <>
                                                                <div className='flex flex-col items-start justify-start gap-1'>

                                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{activitiesSubscriptionsGeneralData?.activitiesSubscriptionsFinishedInMonth}</p>
                                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Inscrições Terminando</p>
                                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${activitiesSubscriptionsGeneralData?.activitiesSubscriptionsFinishedInMonthDiff > 0 ? 'text-red-300' : 'text-primaryDefaultLight'}`}>
                                                                        {
                                                                            activitiesSubscriptionsGeneralData?.activitiesSubscriptionsFinishedInMonthDiff > 0 ?
                                                                                < FaArrowUp />
                                                                                :
                                                                                <FaArrowDown />
                                                                        }
                                                                        <p className='text-xs font-semibold opacity-70'>{activitiesSubscriptionsGeneralData?.activitiesSubscriptionsFinishedInMonthDiff}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='text-zinc-100 gap-2 flex flex-col items-center justify-center rounded-2xl w-10 h-10 bg-primaryDefaultLight opacity-70 shadow-sm'>
                                                                    <FaCalendarMinus />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div className=' p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                                    {
                                                        loadingDashActivities['generalData'] ?
                                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                            :
                                                            <>

                                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{!activitiesSubscriptionsGeneralData?.pendingPayments ? 0 : currencyPtBr(activitiesSubscriptionsGeneralData?.pendingPayments)}</p>
                                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Pagamentos pendentes</p>

                                                                </div>
                                                                <div className='text-zinc-100 gap-2 flex flex-col items-center justify-center rounded-2xl w-10 h-10 bg-primaryDefaultLight opacity-70 shadow-sm'>
                                                                    <FaMoneyBill />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div className=' p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                                    {
                                                        loadingDashActivities['generalData'] ?
                                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                            :
                                                            <>
                                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{!activitiesSubscriptionsGeneralData?.revenueOfMonth ? currencyPtBr(0) : currencyPtBr(activitiesSubscriptionsGeneralData?.revenueOfMonth)}</p>
                                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Receita do mês</p>
                                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${activitiesSubscriptionsGeneralData?.revenueOfMonthDiff > 0 ? 'text-primaryDefaultLight' : 'text-red-300'}`}>
                                                                        {
                                                                            activitiesSubscriptionsGeneralData?.revenueOfMonthDiff > 0 ?
                                                                                <FaArrowUp />
                                                                                :
                                                                                <FaArrowDown />
                                                                        }
                                                                        <p className='text-xs font-semibold opacity-70'>{
                                                                            currencyPtBr(activitiesSubscriptionsGeneralData?.revenueOfMonthDiff)
                                                                        }</p>
                                                                    </div>
                                                                </div>
                                                                <div className='text-zinc-100 gap-2 flex flex-col items-center justify-center rounded-2xl w-10 h-10 bg-primaryDefaultLight opacity-70 shadow-sm'>
                                                                    <BsCurrencyDollar />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div className=' p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                                    {
                                                        loadingDashActivities['generalData'] ?
                                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                            :
                                                            <>
                                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{!activitiesSubscriptionsGeneralData?.revenueOfYear ? currencyPtBr(0) : currencyPtBr(activitiesSubscriptionsGeneralData?.revenueOfYear)}</p>
                                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Receita do ano</p>
                                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${activitiesSubscriptionsGeneralData?.revenueOfYearDiff > 0 ? 'text-primaryDefaultLight' : 'text-red-300'}`}>
                                                                        {
                                                                            activitiesSubscriptionsGeneralData?.revenueOfYearDiff > 0 ?
                                                                                <FaArrowUp />
                                                                                :
                                                                                <FaArrowDown />
                                                                        }
                                                                        <p className='text-xs font-semibold opacity-70'>{
                                                                            currencyPtBr(activitiesSubscriptionsGeneralData?.revenueOfYearDiff)
                                                                        }</p>
                                                                    </div>
                                                                </div>
                                                                <div className='text-zinc-100 gap-2 flex flex-col items-center justify-center rounded-2xl w-10 h-10 bg-primaryDefaultLight opacity-70 shadow-sm'>
                                                                    <BsCurrencyDollar />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                            <div className='flex lg:flex-row flex-col gap-4 w-full'>
                                                <div className='flex flex-col shadow-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark p-2 lg:p-3 w-full xl:w-[50%] border border-zinc-200 dark:border dark:border-primaryBorderDark rounded-lg gap-2 items-'>
                                                    <div className='flex flex-col gap-1 justify-start items-start w-full'>
                                                        <h1 className='dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold text-lg xl:text-xl 2xl:text-2xl'>Resultado anual</h1>
                                                        {
                                                            <LabelInput text={'Ano'}>
                                                                <InputAutoComplete
                                                                    width={56}
                                                                    data={yearsOfRevenue}
                                                                    preSelectedValue={selectedYearActivities?.id}
                                                                    selectedLabel={'id'}
                                                                    optionList={['id']}
                                                                    onChange={(e) => updateYearDashboardForAnualRevuenueActivities(e)}
                                                                />
                                                            </LabelInput>
                                                        }
                                                    </div>
                                                    {
                                                        loadingDashActivities['revenueOfYear'] ?
                                                            <div style={{ minHeight: '365px', height: '365px' }} className='w-full items-end justify-center animate-pulse flex flex-row gap-3'>
                                                                <div className='bg-gray-300 h-44 w-12'></div>
                                                                <div className='bg-gray-300 h-48 w-12'></div>
                                                                <div className='bg-gray-300 h-32 w-12'></div>
                                                                <div className='bg-gray-300 h-28 w-12'></div>
                                                                <div className='bg-gray-300 h-56 w-12'></div>
                                                                <div className='bg-gray-300 h-48 w-12'></div>
                                                                <div className='bg-gray-300 h-64 w-12'></div>
                                                                <div className='bg-gray-300 h-44 w-12'></div>
                                                                <div className='bg-gray-300 h-32 w-12'></div>
                                                                <div className='bg-gray-300 h-28 w-12'></div>
                                                            </div>
                                                            :
                                                            <div style={{ maxHeight: '365px', height: '365px' }} className='overflow-hidden'>
                                                                <AreaGraph
                                                                    id='activitiesSubscriptionsAreaGraph'
                                                                    title={'Análise anual'}
                                                                    data={activitiesSubscriptionsRevenueOfYear?.filter(data => Number(data?.year) === Number(selectedYearActivities?.id))}
                                                                    data2={activitiesSubscriptionsRevenueOfYear?.filter(data => Number(data?.year) === Number(selectedYearActivities?.id - 1))}
                                                                />
                                                            </div>
                                                    }
                                                </div>
                                                <div className='relative shadow-lg p-2 lg:p-3 bg-bgPrimaryLight dark:bg-secondaryDefaultDark flex flex-col items-center xl:justify-center w-full xl:w-[50%] border border-zinc-200 dark:border dark:border-primaryBorderDark rounded-lg gap-2'>
                                                    <div className='relative flex flex-col gap-2 w-full items-center h-full justify-between '>
                                                        <div className='w-full flex flex-col gap-1 items-start justify-start '>
                                                            <h1 className='dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold text-lg xl:text-xl 2xl:text-2xl '>Inscrições por atividade</h1>
                                                            <LabelInput text={'Visão'}>
                                                                <InputAutoComplete
                                                                    preSelectedValue={'Mês'}
                                                                    data={[{ id: 0, description: 'Mês' }, { id: 1, description: 'Ano' }]}
                                                                    selectedLabel={'description'}
                                                                    onChange={(e) => handleChangeActivitiesSubscriptionsByActivitieView(e)}
                                                                    width={56}
                                                                />
                                                            </LabelInput>
                                                        </div>
                                                        {
                                                            loadingDashActivities['activitiesSubscriptionsByActivitie'] ?
                                                                <div className='w-full animate-pulse flex flex-col items-start justify-center gap-4 h-full'>
                                                                    <div className='flex sm:hidden bg-gray-300 h-32 w-32 rounded-full' />
                                                                    <div className='hidden sm:flex bg-gray-300 h-56 w-56 rounded-full' />
                                                                </div>
                                                                :
                                                                <>
                                                                    <div className='hidden sm:flex items-center justify-center h-full'>
                                                                        <SliceGraph id='sliceActivities' data={activitiesSubscriptionsByActivitie} />
                                                                    </div>

                                                                    <div className='flex sm:hidden items-center justify-center h-full'>
                                                                        <SliceGraphMobile id='sliceActivitiesMobile' data={activitiesSubscriptionsByActivitie} />
                                                                    </div>
                                                                </>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='relative flex lg:flex-row flex-col gap-4 w-full'>
                                                <div className='shadow-lg p-2 lg:p-3 bg-bgPrimaryLight dark:bg-secondaryDefaultDark flex flex-col items-center xl:justify-center w-full xl:w-[50%] border border-zinc-200 dark:border dark:border-primaryBorderDark rounded-lg gap-2'>
                                                    <div className='flex flex-col gap-2 w-full items-center h-full justify-between '>
                                                        <div className='w-full gap-1 flex flex-col items-start justify-start'>
                                                            <h1 className='dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold text-lg xl:text-xl 2xl:text-2xl '>Mais rentável</h1>
                                                            <LabelInput text={'Visão'}>
                                                                <InputAutoComplete
                                                                    width={56}
                                                                    data={[{ id: 0, description: 'Mês' }, { id: 1, description: 'Ano' }]}
                                                                    selectedLabel={'description'}
                                                                    preSelectedValue={'Mês'}
                                                                    optionList={['description']}
                                                                    onChange={(e) => handleUpdateIncomeByActivitie(e)}
                                                                />
                                                            </LabelInput>
                                                        </div>

                                                        {
                                                            loadingDashActivities['incomeByActivitie'] ?
                                                                <div className='w-full animate-pulse flex flex-col items-start justify-center gap-4 h-full'>
                                                                    <div className='bg-gray-300 h-10 w-full'></div>
                                                                    <div className='bg-gray-300 h-8 w-[70%]'></div>
                                                                    <div className='bg-gray-300 h-6 w-[60%]'></div>
                                                                </div>
                                                                :
                                                                <div className='flex items-center justify-center h-full w-full'>
                                                                    <HorizontalBars data={incomeByActivitie} />
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='shadow-lg p-2 lg:p-3 bg-bgPrimaryLight dark:bg-secondaryDefaultDark flex flex-col items-center xl:justify-center w-full xl:w-[50%] border border-zinc-200 dark:border dark:border-primaryBorderDark rounded-lg gap-2'>
                                                    <div className='flex flex-col gap-2 w-full items-center h-full justify-between '>
                                                        <div className='w-full flex flex-col gap-1 items-start justify-start '>
                                                            <h1 className='dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold text-lg xl:text-xl 2xl:text-2xl '>Formas de pagamento</h1>
                                                            <LabelInput text={'Visão'}>
                                                                <InputAutoComplete
                                                                    width={56}
                                                                    preSelectedValue={"Mês"}
                                                                    data={[{ id: 0, description: 'Mês' }, { id: 1, description: 'Ano' }]}
                                                                    selectedLabel={'description'}
                                                                    optionList={['description']}
                                                                    onChange={(e) => handleChangePaymentMethodsViewActivities(e)}
                                                                />
                                                            </LabelInput>
                                                        </div>
                                                        {
                                                            loadingDashActivities['paymentsMethods'] ?

                                                                <div className='w-full animate-pulse flex flex-col items-start justify-center gap-4 h-full'>
                                                                    <div className='bg-gray-300 h-10 w-full'></div>
                                                                    <div className='bg-gray-300 h-8 w-[70%]'></div>
                                                                    <div className='bg-gray-300 h-6 w-[60%]'></div>
                                                                </div>
                                                                :
                                                                <div className='mt-4 sm:mt-0 grid grid-cols-2 sm:grid-cols-3 items-center justify-center px-2 h-full w-full'>
                                                                    {
                                                                        paymentMethods.map(method => {
                                                                            const quantity = paymentMethodsActivities.filter(payment => payment.paymentMethod === method.value)?.length > 0 ? paymentMethodsActivities.filter(payment => payment.paymentMethod === method.value)[0].quantity : 0
                                                                            return <div id={`paymentMethod2-${method.description}-activities`} key={`paymentMethod2-${method.description}-activities`} className='flex flex-col gap-1 items-start justify-start'>
                                                                                <a className='text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{method?.description}</a>
                                                                                {/* <div className={`w-40 h-4 rounded-lg ${method.color}`}></div> */}
                                                                                <div style={{ width: '150px' }} className='bg-zinc-100 dark:bg-thirdDefaultDark flex items-start justify-start rounded-lg'>
                                                                                    <div style={{ width: (quantity / paymentMethodsTotal) * 100 + '%' }} className={` h-4 rounded-lg ${method.color}`}></div>
                                                                                </div>
                                                                                <a className='text-lg text-titleBlackTextLight dark:text-titleGrayTextDark'>{quantity}</a>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            }
                        </div>
                        :
                        <div className='py-4 flex flex-col w-full gap-3 items-center justify-center h-full'>
                            <img src={agendaRapidoLogo} width={300} />
                            <h1 className='text-lg text-primaryDefaultLight dark:text-titleGrayTextDark'>Bem vindo {userData[0]?.firstName}</h1>
                            <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Use o menu lateral para acessar as funcionalidades</p>
                        </div>
                }
            </Body>
        </Container >

    )
}
