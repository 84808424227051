// hooks 
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
// components 
import { FaBan, FaEdit, FaFilter, FaSearch } from 'react-icons/fa'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { LabelInput } from '../../../components/label/label.input'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { TableDefault } from '../../../components/table/table.default'
import { TableMobile } from '../../../components/table/table.mobile'
import { Button } from '../../../components/buttons/button.default'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
//services
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function Services() {

    const { defaultCallsSchedules, api } = useApiContext()
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [filteredServices, setFilteredServices] = useState(true)
    const [servicesActiveButton, setServicesActiveButton] = useState(1)
    const [usersActiveButton, setUsersActiveButton] = useState(1)
    const [categoriesList, setCategoriesList] = useState([])
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const tableThead = [
        {
            "name": 'Serviço',
            "original_name": 'name'
        },
        {
            "name": 'Descrição',
            "original_name": 'description'
        },
        {
            "name": 'Categoria',
            "original_name": 'categories_name'
        },
        {
            "name": 'Tipo de reserva',
            "original_name": "reservation_type"
        },

    ]
    const [servicesList, setServicesList] = useState([])
    //filters search
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showInternalFilterModal, setShowInternalFilterModal] = useState(false)
    const [paginationFilteredFields, setPaginationFilteredFields] = useState({})
    const [paginationHistFilteredFields, setPaginationHistFilteredFields] = useState({})
    const [paginationHistFilteredFieldsArr, setPaginationHistFilteredFieldsArr] = useState([])
    const [cleanFilter, setCleanFilter] = useState(false)
    const tableFilterModal = useRef()
    const [totalPages, setTotalPages] = useState(1)

    const filters = [
        {
            "name": 'Serviço',
            "original_name": 'name',
            "type": 'text'
        },
        {
            "name": 'Descrição',
            "original_name": 'description',
            "type": 'text'
        },
        {
            "name": 'Categoria',
            "original_name": 'categories_id',
            "type": 'object',
            "data": categoriesList,
            "optionList": ['name'],
            "selectedLabel": 'name',
            "optionToFilter": 'id'
        },
    ]

    async function getData(e) {

        const servicesData = await defaultCallsSchedules.getServicesPagination(userData[0]?.token, e?.filter, e?.page, e?.direction, usersActiveButton)
        if (servicesData.error) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(servicesData.data)
        }
    
        const categoriesData = await defaultCallsSchedules.getCategories(userData[0]?.token,1)
        if (categoriesData.error) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(categoriesData.data)
        }

        setTotalPages(servicesData?.data?.totalPages)

        let serviceObj = []
        servicesData.data.data.map((service) => {
            serviceObj.push({
                ...service,
                reservationType: service.reservationType === 'H' ? "Horário" : "Mensal",
                description: service.description?.length > 0 ? service.description?.slice(0, 30) + '...' : 'Vazio'
            })
        })

        setCategoriesList(categoriesData.data)
        setServicesList(serviceObj.sort(function(a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }))
        setLoading(false)
    }

    useEffect(() => {
        getData()

    }, [])

    useEffect(() => {

        handleFilterActiveServices(1)

    }, [servicesList])

    function handleFilterActiveServices(status) {

        const newServicesList = servicesList.filter((service) => {
            if (status === 1 && service?.active) {
                return service
            }
            if (status === 0 && !service?.active) {
                return service
            }
            if (status === undefined) {
                return service
            }
        })

        status !== undefined ? setFilteredServices(newServicesList) : setFilteredServices(servicesList)
        status > 0 ? setServicesActiveButton(1) : setServicesActiveButton(0)
    }

    function servicesEdit(service) {
        navigate(`/servicesEdit/${service.id}`)
    }

    //filter functions
    async function handleClearFilter() {

        setCleanFilter(true)
        setLoading(true)
        setPaginationHistFilteredFieldsArr([]);
        setPaginationHistFilteredFields([])
        let fields = {}
        filters.map((column, index) => {
            fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
        })
        setPaginationFilteredFields(fields)
        getData();
        await new Promise(resolve => setTimeout(resolve, 200))
        setLoading(false)
        setCleanFilter(false)

    }
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {

        let modal = document.getElementById('tableFilterModal')
        if (modal) {
            modal.addEventListener('click', (e) => handleChangeModalStatus(e, ''))
            document.addEventListener('keydown', (e) => handleChangeModalStatus(e, 'keyboard'))
        }

        async function animateFilterModal(status) {

            let fields = {}
            filters.map((column, index) => {
                fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
            })
            setPaginationFilteredFields(fields)
            if (status) {
                let histFields = {}
                filters.map((column, index) => {
                    histFields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
                })
                setPaginationHistFilteredFields(fields)
                setShowFilterModal(true)
                await new Promise(resolve => setTimeout(resolve, 200))
                return setShowInternalFilterModal(true)
            }
            setShowFilterModal(false)
            setShowInternalFilterModal(false)
        }

        animateFilterModal(showFilterModal)

        if (modal) {
            return () => { modal.removeEventListener('click', (e) => handleChangeModalStatus(e)); modal.removeEventListener('keydown', (e) => handleChangeModalStatus(e)) }
        }

    }, [showFilterModal])

    function customFilter() {

        let ToFilterData = []
        let fieldsKeys = Object.keys(paginationFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationFilteredFields[key].value !== '') {
                ToFilterData.push(paginationFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
        }
        getData({ filter: { filterObject: ToFilterData }, direction: false, usersActiveButton })
        setShowFilterModal(false)
    }

    function handleChangeModalStatus(e, type) {

        if (e.code === 'Escape' && type === 'keyboard') {
            setShowFilterModal(false)
        }

        if (!tableFilterModal?.current?.contains(e.target) && type !== 'keyboard') {
            setShowFilterModal(false)
        }
    }

    function orderTableData(e) {
        const ToFilterData = []
        const fieldsKeys = Object.keys(paginationHistFilteredFields)
        fieldsKeys.map((key) => {
            if (paginationHistFilteredFields[key].value !== '') {
                ToFilterData.push(paginationHistFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
            return getData({ filter: { filterObject: ToFilterData }, direction: e?.direction, page: e.page })
        }
        getData(e)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            customFilter()
        }
    }

    return (
        <Container>
            <TitlePage>
                <div className='flex flex-row gap-1 items-start justify-between w-full'>
                    <Title text={'Serviços e Espaços para reserva'}></Title>
                    <Button module={"schedules:create"} userData={userData} shadow={true} onClick={() => navigate('/servicesCreate')}>+ Novo</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    showFilterModal &&
                    <div id='tableFilterModal' className='fixed left-0 top-0 z-[500] w-full h-full flex flex-col items-center justify-start'>
                        <div ref={tableFilterModal} className={`${showInternalFilterModal ? 'rounded-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark opacity-1 translate-x-0' : 'translate-x-[1000px] bg-transparent opacity-0'} top-28 absolute z-[501] p-6  boxShadow transition-all duration-300 flex flex-col gap-2`}>
                            <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center mb-8'>
                                <FaSearch />
                                <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                            </div>
                            {

                                filters.map(column => {
                                    return <form onKeyUp={(e)=> handleKeyPress(e)} className='flex sm:flex-row flex-col'>
                                        <div className='w-28'>
                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{column.name}</p>
                                        </div>
                                        {
                                            column.type === 'text' ?
                                                <LabelInput>
                                                    <Input value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                        setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                    }}></Input>
                                                </LabelInput>
                                                :
                                                column.type === 'object' ?
                                                    <LabelInput>
                                                        <InputAutoComplete data={column.data} optionList={column.optionList} selectedLabel={column.selectedLabel} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                        }}></InputAutoComplete>
                                                    </LabelInput>
                                                    :
                                                    column.type === 'date' &&
                                                    <LabelInput>
                                                        <Input type={`date`} value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        }} />
                                                    </LabelInput>
                                        }
                                    </form>
                                })}
                            <div className='flex w-full flex-col items-end justify-start mt-6'>
                                <Button onClick={() => customFilter()} shadow={true}>Pesquisar</Button>
                            </div>

                        </div>
                        <div className={`${showInternalFilterModal ? 'bg-gray-700 opacity-75' : 'bg-transparent opacity-0'} absolute h-full w-full transition-all duration-300`}>
                        </div>
                    </div>
                }
                {
                    loading ?
                        <div className='flex flex-col items-center justify-center'>
                            <DefaultLoader />
                        </div>
                        :
                        <div className='w-full flex flex-col'>
                            <div className='flex flex-row items-center justify-between w-full mb-6'>
                                <div className='flex items-center'>
                                    <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${servicesActiveButton === 1 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveServices(1)}>Ativos</button>
                                    <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${servicesActiveButton === 0 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveServices(0)}>Inativos</button>
                                </div>
                                <div className='flex items-center'>
                                    <Tippy content={'Filtrar'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className={`p-1 w-32 cursor-pointer items-center justify-center flex flex-row gap-2 text-xl text-primaryDefaultLight bg-primaryDefaultLight 
                                        pl-2 pr-2 h-10 md:h-6  md:w-28 hover:bg-secondaryDefaultLight transition-all duration-200 shadow-md shadow-gray-400 dark:shadow-xl dark:shadow-black/25`} onClick={() => setShowFilterModal(true)}>
                                            <FaFilter className='text-bgSecondaryLight text-base'/>
                                            <a className='text-bgSecondaryLight text-sm'>Filtrar</a>
                                        </div>
                                    </Tippy>
                                    {
                                        paginationHistFilteredFieldsArr?.length > 0 &&
                                        <Tippy content={'Excluir filtro'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div className='ml-2 cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150' onClick={() => { handleClearFilter() }}>
                                                <FaBan />
                                            </div>
                                        </Tippy>
                                    }
                                </div>
                            </div>
                            <div id='reservationCategoryTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow overflow-y-auto rounded-lg'>
                                <TableDefault
                                    onClick={(e) => servicesEdit(e)}
                                    pagination={true}
                                    totalPages={totalPages}
                                    title={tableThead}
                                    data={filteredServices}
                                    oading={loading}
                                    filter={(e) => orderTableData(e)}
                                    collumns={["name", "description", 'categories_name', 'reservationType']} />
                            </div>
                            <div id='usersMobileTableContent' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                <TableMobile
                                    onClick={(e) => e.func.execute(e.data)}
                                    title={tableThead}
                                    data={filteredServices}
                                    collumns={["name", "description", "reservationType"]}
                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": servicesEdit }]}
                                />
                            </div>
                        </div>
                }
            </Body>
        </Container>
    )
}