//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
//utils
import { justNumber } from '../../../utils/validators/justNumber'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { validateFields } from '../../../utils/form.validator'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import Tippy from '@tippyjs/react'
import { FaInfoCircle } from 'react-icons/fa'
import { Toogle } from '../../../components/toogle/Toogle'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function SectorsEdit() {

    const { api_auth, defaultCallsAuth } = useApiContext()
    const navigate = useNavigate()
    const params = useParams()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    //selected variables
    const [sectors, setsectors] = useState({ name: '', fiscalType: '', categories_id: '' })

    async function getData() {

        const sectorData = await defaultCallsAuth.getSectorsById(userData[0]?.token, params.id)
        if (sectorData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(sectorData.data)
        }

        setsectors(sectorData?.data[0])
        setLoading(false)
    }

    useEffect(() => {
        setShowUniversalModal(false)
        getData()
    }, [])

    async function handleSubmit() {
        let requiredFields =
            [
                { "name": 'name', "value": sectors?.name, "required": true, "type": 'string' },
            ]
        //chamada API
        setLoading(true)
        try {

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            await api_auth.put(`/api/v1/auth/sectors/${params.id}`, {
                name: sectors?.name,
                description: sectors?.description ? sectors?.description : undefined,
                active: sectors?.active,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Setor salvo com sucesso!")
            navigate('/sectors')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleRemove() {

        setLoading(true)
        try {

            await api_auth.delete(`/api/v1/auth/sectors/${params.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Setor removido com sucesso!")
            navigate('/sectors')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal>
                <div className='p-4 flex flex-col text-sm items-center justify-center gap-2'>
                    <a>Deseja realmente excluir este setor?</a>
                    <div className='flex gap-4'>
                        <Button shadow={true} onClick={() => handleRemove()}>Sim</Button>
                        <Button approval={false} shadow={true} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Editar setor'}></Title>
                </TitlePage>
                <Body hasFooter={true}>

                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <form className='flex flex-col gap-2 items-start justify-start sm:justify-center w-full'>
                                    <LabelInput text={'Nome: *'}>
                                        <Input id='name' type={'text'} value={sectors?.name} onChange={(e) => setsectors(prev => ({ ...prev, name: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Descrição:'}>
                                        <Input id='description' type={'text'} value={sectors?.description} onChange={(e) => setsectors(prev => ({ ...prev, description: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'ativo'}>
                                        <Toogle status={sectors?.active} onClick={() => setsectors(prev => ({ ...prev, active: !sectors?.active }))} />
                                    </LabelInput>
                                </form>
                            </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate('/sectors')}>Cancelar</Button>
                        <div className='flex gap-2'>
                            <Button shadow={true} approval={false} onClick={() => {
                                setShowUniversalModal(true)
                            }}>Excluir</Button>
                            <Button shadow={true} approval={true} onClick={() => {
                                handleSubmit()
                            }}>Salvar</Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>

    )
}