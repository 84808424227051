//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { FaEdit } from 'react-icons/fa'
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { TableMobile } from '../../../components/table/table.mobile'
import { TableDefault } from '../../../components/table/table.default'
import { Button } from '../../../components/buttons/button.default'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { MiniLoader } from '../../../components/loaders/miniLoader'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//utils
import { handleFilterTable } from '../../../utils/filters/filterForTableDefault'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function PaymentGatewayConfig() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { defaultCallsSchedules } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [loading, setLoading] = useState(true)
    const [gatewayActve, setGatewayActive] = useState(1)
    const [gatewayList, setGatewayList] = useState([])
    const [filteredList, setFilteredList] = useState([])

    const [filteredGateways, setFilteredGateways] = useState(true)
    const [gatewaysActiveButton, setGatewaysActiveButton] = useState(1)

    function filterList(status, list) {
        if (list) {
            const filtered = handleFilterTable(status, list)
            setGatewayActive(filtered.activeButton)
            setFilteredList(filtered.list)
        }
    }

    useEffect(() => {

        async function getData() {

            const paymentGateway = await defaultCallsSchedules.getPaymentGateways(userData[0].token, 1)

             if (paymentGateway.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(paymentGateway.data)
            }
            setGatewayList(paymentGateway.data)
            setFilteredGateways(paymentGateway.data)
            filterList(1, paymentGateway.data)
            setLoading(false)
        }
        getData()
    }, [])

    const tableThead = [
        {
            "name": 'Provedor',
            "original_name": 'paymentGatewayOptions_name'
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        },
    ]

    function handleEdit(e) {
        localStorage.setItem('@gateway', JSON.stringify(e))
        navigate(`/paymentGatewayEdit/${e.id}`)
    }

    async function handleFilterActiveGateways(status) {
        setLoading(true)
        if (status === 1) {
            const gatewayData = await defaultCallsSchedules.getPaymentGateways(userData[0].token, 1)

            setFilteredGateways(gatewayData.data)
        } else {
            const gatewayData = await defaultCallsSchedules.getPaymentGateways(userData[0].token, 0)
            console.log(gatewayData)
            setFilteredGateways(gatewayData.data)
        }
        status > 0 ? setGatewaysActiveButton(1) : setGatewaysActiveButton(0)
        setLoading(false)
    }

    return (
        <Container>
            <TitlePage>
                <div className='w-full justify-between flex flex-row items-center'>
                    <Title text={'Gateways de pagamento'}></Title>
                    <Button module={"paymentGateway:create"} userData={userData} onClick={() => navigate('/paymentGatewayConfigCreate')} shadow={true}>{'Novo'}</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                        <div className='flex flex-col items-center justify-center h-96'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div className='flex flex-row w-full justify-start items-center gap-4'>
                                <div className='flex flex-row items-center justify-start'>
                                    <Tippy content={'Usuários ativos'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${gatewaysActiveButton === 1 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveGateways(1)}>Ativos</button>
                                    </Tippy>
                                    <Tippy content={'Usuários inativos'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${gatewaysActiveButton === 0 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveGateways(0)}>Inativos</button>
                                    </Tippy>
                                </div>
                            </div>
                            <div id='paymentGatewayTable' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                <TableDefault
                                    onClick={(e) => handleEdit(e)}
                                    data={filteredGateways} title={tableThead}
                                    collumns={["paymentGatewayOptions_name", "created_at"]} />
                            </div>
                            <div id='paymentGatewayTableMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                <TableMobile
                                    onClick={(e) => e.func.execute(e.data)}
                                    title={tableThead}
                                    data={filteredGateways}
                                    collumns={["paymentGatewayOptions_name", "created_at"]}
                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]} />
                            </div>
                        </>
                }
            </Body>
        </Container>
    )
}