import { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { AiOutlineStop } from "react-icons/ai";
import moment from "moment";
import { LabelInput } from "../label/label.input";
import { InputAutoComplete } from "../input/input.autocomplete";


/**
 * Função que cria tabela com totais.
 * @component
 * @param {Object} props - Objeto para construção do componente.
 * @param {string} props.initialDate - Definir uma data inicial
 * @param {string} props.endDate - Definir uma data final
 * @param {Function} props.onChange - Callback de retorno com o periodo informado
 * @param {string} props.height - Tamanho/altura
 * @param {boolean} props.showOptionsDate - Habilita e exibe a opção de filtros de datas
 * @param {string[]} props.optionsDate - Opção de filtros de datas, quando puder informar por data de vencimento, entrada, pagamento, etc... (requerimento: showOptionsDate = true)
 * @param {string} props.referenceDateOptions - Data de referencia para inicialização, com base nas opções possíveis dentro do "optionsDate"
 * @returns {JSX.Element} Um componente de tabela personalizado.
 */
export function SelectPeriod({
    initialDate,
    endDate,
    onChange,
    height,
    showOptionsDate = false,
    optionsDate = [],
    referenceDateOptions,
}) {

    const [ startDate, setStartDate ] = useState(initialDate);
    const [ finishDate, setFinishDate ] = useState(endDate);
    const [ rangeStart, setRangeStart ] = useState(initialDate);
    const [ rangeFinish, setRangeFinish ] = useState(endDate);
    const [ openRange, setOpenRange ] = useState(false)
    const [ selectedOptionDate, setSelectedOptionDate ] = useState(referenceDateOptions || "")
    const [ isCompleteMonth, setIsCompleteMonth ] = useState(true)

    function getMonthBoundaries(date, type){
        const parsedDate = moment(date || new Date(), "YYYY-MM-DD").utc(false)
        return type === "start"
            ? parsedDate.startOf("month").format("YYYY-MM-DD")
            : parsedDate.endOf("month").format("YYYY-MM-DD")
    }

    function isFullMonth(start, end){
        const startMoment = moment(start, "YYYY-MM-DD").utc(false)
        const endMoment = moment(end, "YYYY-MM-DD").utc(false)

        return (
            startMoment.isSame(startMoment.clone().startOf("month"), 'date') &&
            endMoment.isSame(endMoment.clone().endOf("month"), 'date') &&
            startMoment.isSame(endMoment, "month")
        )
    }

    useEffect(() => {
        setIsCompleteMonth(isFullMonth(startDate, finishDate))
    }, [startDate, finishDate])

    function handleMonthChange(direction){
        const offset = direction === "prev" ? -1 : 1
        let newStart = moment(startDate, "YYYY-MM-DD").utc(false).add(offset, "month")
        let newEnd = moment(finishDate, "YYYY-MM-DD").utc(false).add(offset, "month")

        if(isCompleteMonth){
            newStart = newStart.startOf('month')
            newEnd = newEnd.endOf('month')
        }

        newStart = newStart.format('YYYY-MM-DD')
        newEnd = newEnd.format('YYYY-MM-DD')

        setIsCompleteMonth(isFullMonth(newStart, newEnd))
        setRangeStart(newStart)
        setRangeFinish(newEnd)
        setStartDate(newStart)
        setFinishDate(newEnd)
        onChange({ startDate: newStart, finishDate: newEnd, whatsDate: selectedOptionDate || undefined })
    }

    function resetToDefault(){
        const defaultStart = getMonthBoundaries(null, "start")
        const defaultEnd = getMonthBoundaries(null, "end")

        setOpenRange(false)
        setSelectedOptionDate(null)
        setRangeStart(null)
        setRangeFinish(null)
        setStartDate(defaultStart)
        setFinishDate(defaultEnd)
        onChange({
            startDate: defaultStart,
            finishDate: defaultEnd,
            whatsDate: undefined,
        })
    }

    function applyRange(){
        setStartDate(rangeStart)
        setFinishDate(rangeFinish)
        setRangeStart(rangeStart)
        setRangeFinish(rangeFinish)
        setIsCompleteMonth(isFullMonth(rangeStart, rangeFinish))
        setOpenRange(false)
        onChange({
            startDate: rangeStart,
            finishDate: rangeFinish,
            whatsDate: selectedOptionDate || undefined,
        })
    }

    return (
        <div className="flex items-center justify-center gap-2 z-[60]">
            <div className={`flex items-center text-white ${height ? `h-[${height}]` : "h-6"}`}>
                <div
                    className={`bg-primaryDefaultLight flex items-center h-full px-2 rounded-l-md ${openRange ? "cursor-not-allowed opacity-70" : "cursor-pointer hover:opacity-70"}`}
                    onClick={() => !openRange && handleMonthChange("prev")}
                >
                    <FaAngleLeft />
                </div>
                <div
                    className="border-x bg-primaryDefaultLight px-6 cursor-pointer"
                    onClick={() => {
                        if (!openRange) {
                            setRangeStart(startDate)
                            setRangeFinish(finishDate)
                        }
                        setOpenRange(true)
                    }}
                >
                    <p>
                        {isCompleteMonth === true
                            ? moment(startDate, "YYYY-MM-DD").utc(false).format("MM/YYYY")
                            : `${moment(startDate, "YYYY-MM-DD").utc(false).format("DD/MM/YYYY")} - ${moment(finishDate, "YYYY-MM-DD").utc(false).format("DD/MM/YYYY")}`}
                    </p>
                </div>
                <div
                    className={`bg-primaryDefaultLight flex items-center h-full px-2 rounded-r-md ${openRange ? "cursor-not-allowed opacity-70" : "cursor-pointer hover:opacity-70"}`}
                    onClick={() => !openRange && handleMonthChange("next")}
                >
                    <FaAngleRight />
                </div>
            </div>
            {(!isCompleteMonth || selectedOptionDate) && 
                <AiOutlineStop
                    className="cursor-pointer dark:text-white"
                    onClick={() => resetToDefault()}
                />
            }
            {openRange &&
                <div className={`absolute ${showOptionsDate ? "mt-52" : "mt-44"} mr-5 bg-white px-5 py-3 shadow-lg rounded-md flex flex-col gap-2`}>
                    {showOptionsDate && (
                        <div className="flex flex-row gap-3 items-center justify-center">
                            <LabelInput>
                                <InputAutoComplete
                                    width={60}
                                    data={optionsDate}
                                    id={"whatsDate"}
                                    value={
                                        selectedOptionDate
                                            ? optionsDate.find((ps) => ps.value === selectedOptionDate)?.label
                                            : ""
                                    }
                                    preSelectedValue={
                                        selectedOptionDate
                                            ? optionsDate.find((ps) => ps.value === selectedOptionDate)?.label
                                            : ""
                                    }
                                    onChange={(e) => setSelectedOptionDate(e?.value)}
                                    selectedLabel={"label"}
                                    optionList={["label"]}
                                />
                            </LabelInput>
                        </div>
                    )}
                    <div className="flex items-center justify-between gap-3">
                        <p>De:</p>
                        <input
                            type="date"
                            value={rangeStart || ""}
                            onChange={(e) => setRangeStart(e.target.value)}
                        />
                    </div>
                    <div className="flex items-center justify-between gap-3">
                        <p>Até:</p>
                        <input
                            type="date"
                            value={rangeFinish || ""}
                            onChange={(e) => setRangeFinish(e.target.value)}
                        />
                    </div>
                    <div className="flex justify-between items-center mt-2">
                        <button
                            type="button"
                            className="bg-gray-400 text-white rounded-md px-2 py-1"
                            onClick={() => {
                                setRangeStart(null)
                                setRangeFinish(null)
                                setOpenRange(false)
                            }}
                        >
                            Cancelar
                        </button>
                        <button
                            type="button"
                            className="bg-primaryDefaultLight text-white rounded-md px-2 py-1"
                            onClick={() => applyRange()}
                        >
                            Aplicar
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}