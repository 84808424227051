import React from 'react'
import { Route, Routes, HashRouter, Navigate } from 'react-router-dom'
import { Login } from '../pages/security/login'
import { SignUpCustomerFreeTrial } from '../pages/security/signUpCustomerFreeTrial'

export function LoginRoutes() {

    return (
        <HashRouter>
            <Routes>
                <Route path='/customer/createAccount/trial' element={<SignUpCustomerFreeTrial />} />
                <Route path='/validUser/:usersId/:userTypeAccess' element={<Login />} />
                <Route path='/:app_name' element={<Login />} />
                <Route path='/' element={<Login />} />
                <Route path='*' element={<Navigate to="/" />} />
            </Routes>
        </HashRouter>
    )
}