import { Body } from '../../../components/container/Body';
import { useEffect, useState } from "react";
import { Button } from '../../../components/buttons/button.default';
import { Input } from '../../../components/input/input';
import { useAuth } from "../../../contexts/useAuth";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { FaInfo, FaPlus, FaMinus } from "react-icons/fa";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Footer } from "../../../components/footer/Footer";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { useThemeContext } from "../../../contexts/themeContext";
import { validateFields } from '../../../utils/form.validator';
import CurrencyInput from '../../../components/input/inputCurrencyPtBr';
import { InputDinamicAutoComplete } from '../../../components/input/input.dinamic.autocomplete';
import { useApiContext } from '../../../contexts/ApiInterceptorContext';

export function FinancialBillsByConciliation({ transaction, setShow, setTransaction, withoutBody, diffValues, setDiffValues }) {    
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { defaultCallsFinancial } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext() 
    const [ loading, setLoading ] = useState(true)
    const [ costCenter, setCostCenter ] = useState([])
    const [ moduleLinks, setModuleLinks ] = useState([])
    const [ categories, setCategories ] = useState([])
    const [ paymentMethodList, setPaymentMethodList ] = useState([])
    const [ newTransaction, setNewTransaction ] = useState(transaction)
    const [ bill, setBill ] = useState({ 
        incomeOrExpense: newTransaction?.incomeOrExpense,
        description: newTransaction?.description,
        totalValue: newTransaction?.value,
        valueOriginal: newTransaction?.valueOriginal ? newTransaction?.valueOriginal : newTransaction?.value,
        discount: 0,
        fees: 0
    })
    
    const [ apportionmentsBills, setApportionmentsBills ] = useState([{
        financialCategories_id: undefined,
        financialCostCenter_id: undefined,
        percentage: undefined,
        isLinked: false,
        linked: undefined,
        linked_id: undefined,
    }])

    useEffect(() => {
        getData()
    }, [])

    async function getData(){
        try {
            setLoading(true)

            const costCenterData = await defaultCallsFinancial.getCostCenter(userData[0]?.token, true)
            if (costCenterData.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(costCenterData.data)
            }
            setCostCenter(costCenterData?.data?.map(cost => {
                return {
                    ...cost,
                    valueInput: `${cost?.code ? cost?.code : cost?.id} - ${cost?.name}`
                }
            }))

            const categoriesApi = await defaultCallsFinancial.getFinancialCategories(userData[0]?.token)
            if (categoriesApi?.error){
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(categoriesApi.data)
            }
            setCategories(categoriesApi.data?.filter(cat => cat.incomeOrExpense == bill.incomeOrExpense))

            const paymentMethodAPI = await defaultCallsFinancial.getFinancialPaymentMethod(userData[0]?.token)
            if (paymentMethodAPI.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(paymentMethodAPI.data)
            }
            setPaymentMethodList(paymentMethodAPI?.data)

            const linksApi = await defaultCallsFinancial.getFinancialListLinks(userData[0]?.token)
            if (linksApi?.error){
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(linksApi.data)
            }
            setModuleLinks(linksApi.data)
            
        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }finally{
            setLoading(false)
        }

    }

    async function handleSubmit(){
        try {
            const requiredFields = [
                { name: 'clients_id', value: bill?.client_id, required: true, type: 'number' },
                { name: 'clients_id', value: bill?.clientOrigin, required: true, type: 'string' },
                { name: 'dueDate', value: bill?.dueDate ? bill?.dueDate : newTransaction?.entryDate, required: true, type: 'string' },
                { name: 'paymentMethod', value: bill?.financialPaymentMethod, required: true, type: 'object' }
            ]
            
            apportionmentsBills?.map((app, index) => {
                requiredFields.push(
                    {name: `apportionmentFinancialCategories_id-${index}`, value: app?.financialCategories_id, required: true, type: 'string'},
                    {name: `apportionmentPercentage-${index}`, value: app?.percentage, required: true, type: 'string'}
                )
                if(app.isLinked){
                    requiredFields.push({ name: `apportionmentLinked-${index}`, value: app.linked_id, required: true, type: 'number' },)
                }
            })

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            const calculate = Number((bill.totalValue + bill.fees) - bill.discount).toFixed(2)
            if (calculate != Number(newTransaction?.value).toFixed(2)){
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Os valores do lançamento devem fechar com o valor da transação')
            }
            
            newTransaction.bill = {
                entryDate: newTransaction?.entryDate,
                dueDate: bill?.dueDate ? bill?.dueDate : newTransaction?.entryDate,
                description: bill?.description ? bill?.description : undefined,
                clientOrigin: bill?.clientOrigin,
                client_id: bill?.client_id,
                incomeOrExpense: newTransaction?.incomeOrExpense,
                totalValue: bill?.totalValue,
                externalCode: bill?.externalCode ? bill?.externalCode : undefined,
                paymentCondition: 1, // À vista
                numberOfInstallments: 1,
                paymentStatus: 1, // Pago
                paymentDate: newTransaction?.entryDate,
                discount: bill?.discount ? bill?.discount : 0,
                fees: bill?.fees ? bill?.fees : 0,
                financialAccounts_id: newTransaction?.financialAccounts_id,
                financialPaymentMethod: bill?.financialPaymentMethod,
                financialConciliationsTransactions_id: newTransaction?.id,
                apportionments: apportionmentsBills.map(app => {
                    return {
                        financialCategories_id: app?.financialCategories_id,
                        financialCostCenter_id: app?.financialCostCenter_id 
                            ? app?.financialCostCenter_id 
                            : undefined,
                        percentage: app?.percentage,
                        linked: app?.linked 
                            ? app?.linked 
                            : undefined,
                        linked_id: app?.linked_id 
                            ? app?.linked_id 
                            : undefined
                    }
                }),
                compositions: []
            }
            newTransaction.value = bill?.valueOriginal

            if (diffValues){
                if (diffValues < 0){
                    setDiffValues(diffValues + Number(newTransaction.value))
                } else {
                    setDiffValues(diffValues - Number(newTransaction.value))  
                }
                newTransaction.incomeOrExpense = newTransaction.incomeOrExpenseHistory
                newTransaction.isDiff = true                
            }

            setTransaction(newTransaction)
            
            setLoading(false)
            return setShow(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function calculateTotal(){
        const fees = bill.fees ? bill.fees : 0
        const discount = bill.discount ? bill.discount : 0

        const calculate = Number((bill.totalValue + fees) - discount).toFixed(2)
        let result

        if (calculate != Number(newTransaction?.value).toFixed(2)){
            result = <p className="text-red-500 text-md pl-2">
                O valor precisa ser igual da transação
            </p>
        } else {
            result = <p className="text-primaryDefaultLight text-md pl-2">
                Total: {calculate?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </p>
        }

        return result  
    }

    const optionsBooleansList = [
        {
            type: true,
            description: "Sim"
        },
        {
            type: false,
            description: "Não"
        }
    ]

    function ContentForm() {
        return (
            <form className='flex flex-col flex-wrap gap-2 items-start justify-start w-full pb-6'>
                    {/* Cadastro */}
                    <div className='relative flex flex-col gap-2 items-start justify-start w-full mt-6 border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>
                        <p className="absolute top-2 right-3 text-primaryDefaultLight">{newTransaction?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                        <h3 className='text-primaryDefaultLight text-xl'>Cadastro</h3>
                        <div className='flex flex-wrap gap-2 items-start justify-start w-full mt-4 mb-2'>                                    
                            <LabelInput text={bill.incomeOrExpense == 'R' ? 'Cliente: *' : 'Fornecedor: *'}>
                                <InputDinamicAutoComplete                            
                                    id={'clients_id'}
                                    onChange={(e) => {
                                        setBill(prev => ({ ...prev, clientOrigin: e.linked, client_id: e.linked_id }))
                                    }}
                                    endpoint={'/api/v1/financial/utils/usersClientsAndSuppliers'}
                                    module={'financial'}
                                    optionList={['nameToShow']}
                                    optionToSearch={'nameToShow'}
                                    filters={{
                                        isClient: bill?.incomeOrExpense == 'R' ? true : undefined,
                                        isSupplier: bill?.incomeOrExpense == 'D' ? true : undefined
                                    }}
                                    token={userData[0]?.token}
                                    mapTotalPages={['totalPages']}
                                />
                            </LabelInput>
                            <LabelInput text={'Data de referência: *'}>
                                <Input disabled id='entryDate' type={'date'} value={newTransaction?.entryDate} onChange={(e) => setBill(prev => ({ ...prev, entryDate: e.target.value }))}/>
                            </LabelInput>
                            <LabelInput text={'Descrição:'}>
                                <Input id='description' type={'text'} value={bill?.description} onChange={(e) => setBill(prev => ({ ...prev, description: e.target.value }))}/>
                            </LabelInput>
                            <LabelInput text={'Código Externo:'}>
                                <Input id='externalCode' type={'text'} value={bill?.externalCode} onChange={(e) => setBill(prev => ({ ...prev, externalCode: e.target.value }))}/>
                            </LabelInput>
                        </div>
                    </div>
                    <hr className='my-6 border w-full' /> 
                    {/* Rateio */}
                    <div className='flex flex-col gap-2 items-start justify-start w-full border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>
                        <h3 className='text-primaryDefaultLight text-xl'>Rateios</h3>
                        <div className='flex flex-row justify-start items-end mt-4 border-b border-gray-300 w-full'>
                            <div className='flex flex-col items-start gap-2'>
                                <div className='flex flex-row items-center gap-2'>
                                    <p className='text-titleGrayTextDark'>Restante do rateio</p>
                                    <Tippy content={'É o restante que não foi distribuído a partir do valor total desta compra. Para completar o rateio, este restante precisa estar zerado.'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div 
                                            className='cursor-help text-xs text-primaryDefaultLight hover:brightness-150' 
                                            onClick={() => { handleFiltersTable({}); setFiltersFieldsData({}); setShowRemoveFilters(false) }}>
                                            <FaInfo />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='flex flex-row justify-start items-end gap-2'>
                                    <p className='text-xl text-gray-500 font-semibold tracking-wider'>
                                        {100 - apportionmentsBills.reduce((accumulator, currentValue) => Number(accumulator) + (currentValue?.percentage ? Number(currentValue?.percentage) : 0), 0)}
                                        %
                                    </p>
                                    {
                                        apportionmentsBills.reduce((accumulator, currentValue) => Number(accumulator) + (currentValue?.percentage ? Number(currentValue?.percentage) : 0), 0) !== 100
                                        &&
                                        <p className='text-red-400 text-xs'>Para prosseguir com a compra o rateio precisa estar zerado.</p>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            apportionmentsBills.map((app, index) => {
                                return (
                                    <>
                                        <div className={`flex flex-col gap-2 mt-3s`}>
                                            <div className={`flex flex-wrap gap-2  ${index == 0 ? '' : 'border-t pt-4'} `}>
                                                <LabelInput text={'Categoria: *'}>
                                                    <InputAutoComplete
                                                        data={categories}
                                                        id={`apportionmentFinancialCategories_id-${index}`}
                                                        preSelectedValue={categories.find(categorie => categorie.id === app.financialCategories_id)?.description}
                                                        value={categories.find(categorie => categorie.id === app.financialCategories_id)?.description}
                                                        onChange={(e) => {
                                                            app.financialCategories_id = e.id
                                                            setApportionmentsBills([ ...apportionmentsBills ])
                                                        }}
                                                        selectedLabel={'description'}
                                                        optionList={['id', 'description']}
                                                    />
                                                </LabelInput>
                                                <LabelInput text={'Centro de custo:'}>
                                                    <InputAutoComplete
                                                        data={costCenter}
                                                        id={`apportionmentFinancialCostCenter_id-${index}`}
                                                        preSelectedValue={costCenter.find(cost => cost.id === app?.financialCostCenter_id)?.valueInput}
                                                        value={costCenter.find(cost => cost.id === app?.financialCostCenter_id)?.valueInput}
                                                        onChange={(e) => {
                                                            app.financialCostCenter_id = e.id
                                                            setApportionmentsBills([ ...apportionmentsBills ])
                                                        }}
                                                        selectedLabel={'valueInput'}
                                                        optionList={['valueInput']}
                                                    />
                                                </LabelInput>
                                                <LabelInput text={'Vinculado:'}>
                                                    <InputAutoComplete
                                                        data={optionsBooleansList}
                                                        id={`isLinked-${index}`}
                                                        preSelectedValue={optionsBooleansList?.find(item => item?.type == app?.isLinked)?.description}
                                                        value={optionsBooleansList?.find(item => item?.type == app?.isLinked)?.description}
                                                        onChange={(e) => {
                                                            app.isLinked = e.type
                                                            setApportionmentsBills([ ...apportionmentsBills ])
                                                        }}
                                                        selectedLabel={'description'}
                                                        optionList={['description']}
                                                    />
                                                </LabelInput>
                                                {
                                                    app?.isLinked ?
                                                    <LabelInput text={'Vinculado com: *'}>
                                                        <InputAutoComplete
                                                            data={moduleLinks}
                                                            id={`apportionmentLinked-${index}`}
                                                            preSelectedValue={moduleLinks?.find(item => item?.linked_id == app?.linked_id && item?.linked == app?.linked)?.linked_name}
                                                            value={moduleLinks?.find(item => item?.linked_id == app?.linked_id && item?.linked == app?.linked)?.linked_name}
                                                            onChange={(e) => {
                                                                app.linked_id = e.linked_id
                                                                app.linked = e.linked
                                                                setApportionmentsBills([ ...apportionmentsBills ])
                                                            }}
                                                            selectedLabel={'linked_name'}
                                                            optionList={['linked_id', 'linked_name']}
                                                        />
                                                    </LabelInput>
                                                    :
                                                    <></>
                                                }
                                                <LabelInput text={'Porcentagem (%): *'}>
                                                    <Input
                                                        id={`apportionmentPercentage-${index}`}
                                                        type={'number'}
                                                        value={app.percentage}
                                                        onChange={(e) => {
                                                            app.percentage = parseFloat(e.target.value)
                                                            setApportionmentsBills([ ...apportionmentsBills ])
                                                        }}
                                                    />
                                                </LabelInput>
                                            </div>
                                            <div className='flex items-center my-2 gap-2'>
                                                <span onClick={() => {
                                                    setApportionmentsBills((prev) => ([...prev, {
                                                        financialCategories_id: undefined,
                                                        financialCostCenter_id: undefined,
                                                        percentage: undefined,
                                                        isLinked: false,
                                                        linked: undefined,
                                                        linked_id: undefined,
                                                    }]))
                                                }}>
                                                    <FaPlus size={14} className='text-primaryDefaultLight hover:cursor-pointer' />
                                                </span>
                                                <span
                                                    onClick={() => {
                                                        apportionmentsBills.splice(index, 1)
                                                        setApportionmentsBills([ ...apportionmentsBills ])
                                                    }}>
                                                    <FaMinus size={14} className='text-primaryDefaultLight hover:cursor-pointer' />
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                    <hr className='my-6 border w-full' />
                    <div className='flex flex-col gap-2 items-start justify-start w-full border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>
                        <div className='flex flex-row justify-between items-center w-full'>
                            <h3 className='text-primaryDefaultLight text-xl'>Pagamento e Condições</h3>
                            <div className='flex flex-row items-center justify-center'>
                                <p className="text-primaryDefaultLight border-r-2 border-gray-400 pr-2">Transação: {newTransaction?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>                                            
                                {calculateTotal()}
                            </div>
                        </div>
                        <div className='flex flex-wrap justify-start items-start gap-2 mt-4 mb-2 w-full'>                                        
                            <LabelInput text={'Vencimento: *'}>
                                <Input 
                                    id='dueDate' 
                                    type={'date'} 
                                    value={bill?.dueDate ? bill?.dueDate : newTransaction?.entryDate} 
                                    onChange={(e) => setBill(prev => ({ ...prev, dueDate: e.target.value }))}
                                />
                            </LabelInput>
                            <LabelInput text={'Forma de pagamento: *'}>
                                <InputAutoComplete
                                    data={paymentMethodList}
                                    id={'paymentMethod'}
                                    preSelectedValue={paymentMethodList.find(bank => bank.id == bill?.financialPaymentMethod_id)?.description}
                                    onChange={(e) => setBill(prev => ({ ...prev, financialPaymentMethod: e }))}
                                    selectedLabel={'description'}
                                    height={96}
                                    optionList={['description']}
                                />
                            </LabelInput>
                            <LabelInput text={'Valor: *'}>
                                <CurrencyInput
                                    id={`totalValue`}
                                    className='pl-2 border border-gray-400 border-opacity-75 outline-none sm:w-96 w-full text-sm sm:text-sm transition-all duration-200 focus:shadow-borderShadow h-10 sm:h-8 dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                    placeHolder={bill.totalValue ? bill.totalValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}
                                    onChange={(e) => {
                                        setBill((prev) => ({
                                            ...prev, 
                                            totalValue: parseFloat(e.target.value?.replace('R$', '')?.replace('.', '')?.replace(',', '.'))
                                        }))
                                    }}
                                />
                            </LabelInput>
                            <LabelInput text={'Juros: '}>
                                <CurrencyInput
                                    id="fees"
                                    className='pl-2 border border-gray-400 border-opacity-75 
                                    outline-none
                                    sm:w-96 w-full
                                    text-sm sm:text-sm
                                    transition-all duration-200
                                    focus:shadow-borderShadow
                                    h-10 sm:h-8
                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                    placeHolder={bill?.fees ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(bill?.fees) : 'R$ 0,00'}
                                    onChange={(e) => {
                                        setBill(prev => ({ 
                                            ...prev, 
                                            fees: e.target.value ? parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) : 0 
                                        }))
                                    }}
                                />
                            </LabelInput>
                            <LabelInput text={'Desconto: '}>
                                <CurrencyInput
                                    id="discount"
                                    className='pl-2 border border-gray-400 border-opacity-75 
                                    outline-none
                                    sm:w-96 w-full
                                    text-sm sm:text-sm
                                    transition-all duration-200
                                    focus:shadow-borderShadow
                                    h-10 sm:h-8
                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                    placeHolder={bill?.discount ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(bill?.discount) : 'R$ 0,00'}
                                    onChange={(e) => {
                                        setBill(prev => ({ 
                                            ...prev, 
                                            discount: e.target.value ? parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) : 0 
                                        }))
                                    }}
                                />
                            </LabelInput>
                        </div>
                    </div>
            </form>
        )
    }

    return (
        <>
            {
                withoutBody ?
                <div className=''>
                    {ContentForm()}
                    <Footer>
                        <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                            <Button shadow={true} approval={false} onClick={() => setShow(false)}>Cancelar</Button>
                            <Button shadow={true} approval={true} onClick={() => handleSubmit()}>Cadastrar</Button>
                        </div>
                    </Footer>
                </div>
                :
                <>
                    <Body hasFooter={true}>
                        {
                            loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                {ContentForm()}
                            </>
                        }
                    </Body>
                    <Footer>
                        <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                            <Button shadow={true} approval={false} onClick={() => setShow(false)}>Voltar</Button>
                            <Button shadow={true} approval={true} onClick={() => handleSubmit()}>Cadastrar</Button>
                        </div>
                    </Footer>
                </>
            }
        </>
    )
}