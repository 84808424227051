import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../../components/buttons/button.default";
import { Body } from "../../../../components/container/Body";
import { Container } from "../../../../components/container/container";
import { Footer } from "../../../../components/footer/Footer";
import { ScreenModal } from "../../../../components/modals/notification/screenModal";
import { Title } from "../../../../components/titlePages/title";
import { TitlePage } from "../../../../components/titlePages/title.page";
import { useThemeContext } from "../../../../contexts/themeContext";
import { DefaultLoader } from '../../../../components/loaders/defaultLoader'
import Tippy from "@tippyjs/react";
import { FaList, FaRegNewspaper, FaRegWindowClose } from "react-icons/fa";
import { useAuth } from "../../../../contexts/useAuth";
import { formattTimeToShow } from "../../../../services/api/callAPIsFunctions/defaultCalls.api";
import { maskHours } from "../../../../utils/mask/maskHours";
import moment from "moment";
import QRCode from "react-qr-code";
import { useApiContext } from "../../../../contexts/ApiInterceptorContext";

export function MySchedulesDetails() {

    const {defaultCallsSchedules, api} = useApiContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(false)
    const [myScheduleDetails, setMyScheduleDetails] = useState('')
    const [thisService, setThisService] = useState('')
    const [customersAttendanceDetails, setCustomersAttendanceDetails] = useState('')
    const [notPermittedCancel, setNotPermittedCancel] = useState(false)

    useEffect(() => {
        setShowUniversalModal(false)
        getLinksAndFormatData()
    }, [])

    async function getLinksAndFormatData() {
        setLoading(true)
        try {
            const details = localStorage.getItem('@mySchedulesDetails')
            if (!details) navigate('/mySchedules')
            const mySchedule = JSON.parse(details)
            setNotPermittedCancel(moment(mySchedule?.original_dateToCancel, "YYYY-MM-DD HH:mm:ss.SSS").isBefore(moment()))
            const linkedByPayments = await api.get(`/api/v1/schedules/client?payments_id=${mySchedule.payments_id}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            const serviceDetails = await defaultCallsSchedules.getServicesEspecific(userData[0]?.token, mySchedule.services_id)
            setThisService(serviceDetails.data[0])

            const customersAttendance = await defaultCallsSchedules.getCustomersAttendance(userData[0]?.token)

            if (linkedByPayments?.data?.data?.length > 1) {
                const linkedSchedules = linkedByPayments?.data?.data?.filter(schedule => {
                    schedule.original_created_at = schedule.created_at
                    schedule.created_at = formattTimeToShow(schedule.created_at)
                    schedule.original_startDate = schedule.startDate
                    schedule.startDate = formattTimeToShow(schedule.startDate)
                    schedule.original_finishDate = schedule.finishDate
                    schedule.finishDate = formattTimeToShow(schedule.finishDate)
                    schedule.original_startHour = schedule.startHour
                    schedule.startHour = maskHours({ hour: schedule.startHour })
                    schedule.original_finishHour = schedule.finishHour
                    schedule.finishHour = maskHours({ hour: schedule.finishHour })
                    schedule.original_dateToCancel = schedule?.dataToCancel
                    schedule.dateToCancel = formattTimeToShow(schedule?.dataToCancel)
                    schedule.original_payments_dueDate = schedule?.payments_dueDate
                    schedule.payments_dueDate = formattTimeToShow(schedule?.payments_dueDate)
                    schedule.startDate = `${schedule.startDate} - ${schedule.startHour} - ${schedule.finishHour}`

                    return schedule.id !== mySchedule.id
                })

                mySchedule.associateSchedules = linkedSchedules?.map(anotherHour => anotherHour.startDate),
                    mySchedule.associateSchedulesValue = linkedSchedules?.map(anotherHour => anotherHour.serviceValue)

            } else {
                mySchedule.associateSchedules = []
                mySchedule.associateSchedulesValue = []
            }
            setMyScheduleDetails(mySchedule)
            setCustomersAttendanceDetails(customersAttendance?.data[0])
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    async function handleCancelSchedule() {
        setLoading(true)
        setShowUniversalModal(false)
        try {

            await api.put(
                `/api/v1/schedules/client/cancel/schedules_id/${myScheduleDetails.id}`,
                {
                    returnBalance: true
                },
                {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                }
            )
            myScheduleDetails.status = 2
            myScheduleDetails.original_status = 2
            setMyScheduleDetails(myScheduleDetails)

            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText(`Reserva cancelada com sucesso.`)
        } catch (error) {
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(error?.response?.data?.message ? error?.response?.data?.message : `Erro ao tentar realizar cancelamento da reserva.`)
        }
    }

    return (
        <>
            <ScreenModal>
                <div className="flex flex-col gap-2 justify-between p-4 pb-8 items-center">
                    <p className="text-sm text-titleBlackTextLight mb-0">Deseja realmente cancelar esta reserva?</p>
                    {/* {
                        myScheduleDetails?.payments_original_status == 1 ? <div className="flex pt-2 pb-4">
                            <LabelInput text={`Usar saldo`}>
                                    <InputAutoComplete
                                        data={[ {option: true, description: 'Sim'}, {option: false, description: 'Não'} ]}
                                        selectedLabel={'description'}
                                        optionList={['description']}
                                        preSelectedValue={useBalance ? 'Sim' : 'Não'}
                                        width={40}
                                        onChange={value => setUseBalance(value.option)}
                                        id='useBalance'
                                    />
                            </LabelInput>
                        </div>  : <></>
                    } */}
                    <div className="flex flex-row gap-2">
                        <Button shadow={true} onClick={() => handleCancelSchedule()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Detalhes da reserva'} />
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ? (
                            <div className="">
                                <DefaultLoader />
                            </div>
                        ) : (
                            <div className="w-full flex flex-col gap-4 sm:gap-1 justify-start items-start">
                                <div className='mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                    <h1 className='text-primaryDefaultLight text-base'>Dados da reserva</h1>
                                </div>

                                {
                                    userData[0]?.customersData?.useQrCodeGateControl && thisService?.deviceId &&
                                    <div className=' w-full h-36 flex sm:hidden gap-2 flex-col items-start justify-start'>
                                        <QRCode
                                            className="-translate-x-16"
                                            value={JSON.stringify({
                                                customers_code: userData[0].customersData.code,
                                                schedules_id: myScheduleDetails.id,
                                                deviceId: thisService?.deviceId
                                            })} />
                                        <a className=' text-xs text-titleBlackTextLight dark:text-titleGrayTextDark'>Apresente o Qr Code no leitor para liberação</a>
                                    </div>
                                }
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>ID da reserva: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">{myScheduleDetails?.id}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>Criado em: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">{myScheduleDetails?.created_at}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>Tipo: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">{myScheduleDetails?.isRecurrent ? 'Recorrente' : 'Avulsa'}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>Espaço: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">{myScheduleDetails?.services_name}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>Data: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">
                                        {myScheduleDetails?.startDate}
                                        {myScheduleDetails?.associateSchedules?.length > 0 && myScheduleDetails?.associateSchedules?.map(anoterHour => {
                                            return ` | ${anoterHour}`
                                        })}
                                    </p>
                                </div>
                                {
                                    myScheduleDetails?.status === 0 ?
                                        <div className='flex flex-col sm:flex-row gap-2 items-start justify-start'>
                                            <p className="w-44 text-sm text-titleBlackTextLight"><strong>Status da reserva: </strong></p>
                                            <a className='text-yellow-500 p-1 text-sm rounded-lg font-bold'>Reservando</a>
                                        </div>
                                        : myScheduleDetails?.status === 1 ?
                                            <div className='flex flex-col sm:flex-row gap-2 items-start justify-start'>
                                                <p className="w-44 text-sm text-titleBlackTextLight"><strong>Status da reserva: </strong></p>
                                                <a className='text-green-500 rounded-lg p-1 text-sm font-bold'>Pago</a>
                                            </div>
                                            : myScheduleDetails?.status === 2 ?
                                                <div className='flex flex-col sm:flex-row gap-2 items-start justify-start'>
                                                    <p className="w-44 text-sm text-titleBlackTextLight"><strong>Status da reserva: </strong>
                                                    </p><a className='text-gray-500 rounded-lg p-1 text-sm font-bold'>Cancelado</a>
                                                </div>
                                                : myScheduleDetails?.status === 3 &&
                                                <div className='flex flex-col sm:flex-row gap-2 items-start justify-start'>
                                                    <p className="w-44 text-sm text-titleBlackTextLight"><strong>Status da reserva: </strong></p>
                                                    <a className='text-blue-400 rounded-lg p-1 text-sm font-bold'>Aguardando pagamento</a>
                                                </div>

                                }



                                <div className='mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                    <h1 className='text-primaryDefaultLight text-base'>Dados de pagamento</h1>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>ID do pagamento: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">{[1, 2].includes(myScheduleDetails?.status) && myScheduleDetails?.payments_original_status == 1 ? myScheduleDetails.payments_id : '-'}</p>
                                </div>

                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>Prazo de pagamento: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">{myScheduleDetails?.payments_dueDate}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>{
                                        'Prazo de cancelamento:'
                                    }</strong></p>
                                    <p className={`${notPermittedCancel ? 'text-red-300 line-through' : 'text-titleBlackTextLight'} text-sm `}>{myScheduleDetails?.dateToCancel}</p>
                                </div>


                                {
                                    myScheduleDetails?.payments_original_status === 0 ?
                                        <div className='flex flex-col sm:flex-row gap-2 items-start justify-start'>
                                            <p className="w-44 text-sm text-titleBlackTextLight"><strong>Status do pagamento: </strong></p>
                                            <a className='text-yellow-500 p-1 text-sm rounded-lg font-bold'>Reservando</a>
                                        </div>
                                        : myScheduleDetails?.payments_original_status === 1 ?
                                            <div className='flex flex-col sm:flex-row gap-2 items-start justify-start'>
                                                <p className="w-44 text-sm text-titleBlackTextLight"><strong>Status do pagamento: </strong></p>
                                                <a className='text-green-500 rounded-lg p-1 text-sm font-bold'>Pago</a>
                                            </div>
                                            : myScheduleDetails?.payments_original_status === 2 ?
                                                <div className='flex flex-col sm:flex-row gap-2 items-start justify-start'>
                                                    <p className="w-44 text-sm text-titleBlackTextLight"><strong>Status do pagamento: </strong>
                                                    </p><a className='text-gray-500 rounded-lg p-1 text-sm font-bold'>Cancelado</a>
                                                </div>
                                                : myScheduleDetails?.payments_original_status === 3 &&
                                                <div className='flex flex-col sm:flex-row gap-2 items-start justify-start'>
                                                    <p className="w-44 text-sm text-titleBlackTextLight"><strong>Status do pagamento: </strong></p>
                                                    <a className='text-blue-400 rounded-lg p-1 text-sm font-bold'>Aguardando pagamento</a>
                                                </div>
                                }
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>Valor total: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">{myScheduleDetails?.payments_totalValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                </div>
                                <div className='mb-10 flex flex-row gap-4 flex-wrap items-start'>
                                    {
                                        <div className="mt-6 flex flex-col sm:flex-row items-start justify-start w-full">
                                            {
                                                myScheduleDetails.status !== 2 && customersAttendanceDetails?.allowClientCancel &&
                                                <Tippy content={
                                                        <p>
                                                            {notPermittedCancel ? 'Não é mais possivel cancelar, entre em contato com a administração.' 
                                                                : myScheduleDetails.isRecurrent ? 'Reservas recorrentes só podem ser canceladas pela administração.'
                                                                : 'Cancelar esta reserva'}
                                                        </p>
                                                    }
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div onClick={() => { !notPermittedCancel && !myScheduleDetails.isRecurrent && setShowUniversalModal(true) }} className={`${notPermittedCancel ? '' : 'cursor-pointer'} p-2 hover:bg-gray-100 rounded-md flex flex-row gap-2 items-center justify-center`}>
                                                        <FaRegWindowClose className={`text-lg ${notPermittedCancel || myScheduleDetails.isRecurrent ? 'text-zinc-200' : 'text-titleBlackTextLight dark:text-titleGrayTextDark cursor-pointer'} font-light`} />
                                                        <a className={`text-sm ${notPermittedCancel || myScheduleDetails.isRecurrent ? 'text-zinc-200' : 'text-titleBlackTextLight dark:text-titleGrayTextDark'}`}>Cancelar</a>
                                                    </div>
                                                </Tippy>
                                            }
                                            {
                                                myScheduleDetails.status === 1 &&
                                                <Tippy content={<p>Impressão do recibo</p>}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div onClick={() => {
                                                        localStorage.setItem('receipt', JSON.stringify({
                                                            id: myScheduleDetails?.id,
                                                            textComplement: 'da reserva',
                                                            manualReceiptNumber: myScheduleDetails.payments_manualReceipt,
                                                            local: myScheduleDetails.services_name,
                                                            paymentStatus: myScheduleDetails.original_status,
                                                            startDate: myScheduleDetails.startDate,
                                                            userId: myScheduleDetails.schedule_users_id,
                                                            userName: myScheduleDetails.schedule_users_firstname,
                                                            payments_status: myScheduleDetails.payments_original_status,
                                                            original_user_firstName: myScheduleDetails.schedule_users_firstname,
                                                            ...myScheduleDetails,
                                                        }));
                                                    }}
                                                        className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                        <FaList className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                        <Link className='flex flex-col items-center justify-center' to="/receipt" target="_blank">
                                                            <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Imprimir recibo</a>
                                                        </Link>
                                                    </div>
                                                </Tippy>
                                            }
                                            {
                                                !loading && myScheduleDetails.status !== 2 &&
                                                <Tippy content={<p>Imprimir ficha de reserva</p>}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div
                                                        onClick={() => localStorage.setItem('receipt', JSON.stringify({
                                                            id: myScheduleDetails?.id,
                                                            textComplement: 'da reserva',
                                                            manualReceiptNumber: myScheduleDetails.payments_manualReceipt,
                                                            local: myScheduleDetails.services_name,
                                                            paymentStatus: myScheduleDetails.original_status,
                                                            startDate: myScheduleDetails.startDate,
                                                            userId: myScheduleDetails.schedule_users_id,
                                                            userName: myScheduleDetails.schedule_users_firstname,
                                                            originalPaymentStatus: myScheduleDetails.payments_original_status,
                                                            original_user_firstName: myScheduleDetails.schedule_users_firstname,
                                                            ...myScheduleDetails,
                                                        }))}
                                                        className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                        <FaRegNewspaper className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                        <Link className='flex flex-col items-center justify-center' to="/receiptConfirmation" target="_blank">
                                                            <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Ficha de confirmação</a>
                                                        </Link>
                                                    </div>
                                                </Tippy>
                                            }
                                        </div>
                                    }
                                    {
                                        myScheduleDetails.status === 2 &&
                                        <div className='flex flex-col p-2 bg-gray-500'>
                                            <a className='text-white text-sm'>Cancelado</a>
                                        </div>
                                    }
                                </div>
                                {
                                    userData[0]?.customersData?.useQrCodeGateControl && thisService?.deviceId &&
                                    <>
                                        <div className='mb-4 hidden sm:flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                            <h1 className='text-primaryDefaultLight text-base'>QR Code</h1>
                                        </div>
                                        <div className=' w-full h-36 hidden sm:flex gap-2 flex-col items-start justify-start'>
                                            <QRCode
                                                className="-translate-x-16"
                                                value={JSON.stringify({
                                                    customers_code: userData[0].customersData.code,
                                                    schedules_id: myScheduleDetails.id,
                                                    deviceId: thisService?.deviceId
                                                })} />
                                            <a className=' text-xs text-titleBlackTextLight dark:text-titleGrayTextDark'>Apresente o Qr Code no leitor para liberação</a>
                                        </div>
                                    </>
                                }
                            </div>
                        )
                    }
                </Body>
                <Footer>
                    <div className="w-full flex flex-row items-center h-full justify-start">
                        <Button shadow={true} approval={false} onClick={() => navigate('/mySchedules')}>Voltar</Button>
                    </div>
                </Footer>
            </Container>
        </>
    )
}

