import React, { useEffect, useState } from "react";
import { Container } from "../../../../components/container/container";
import { Title } from "../../../../components/titlePages/title";
import { TitlePage } from "../../../../components/titlePages/title.page";
import { useAuth } from "../../../../contexts/useAuth";
import { Body } from "../../../../components/container/Body";
import { FaAddressCard, FaEdgeLegacy, FaMap, FaPhone } from "react-icons/fa";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { v4 as uuid } from 'uuid'
import { Button } from "../../../../components/buttons/button.default";
import { LabelInput } from "../../../../components/label/label.input";
import { InputAutoComplete } from "../../../../components/input/input.autocomplete";
import { SwiperContainer } from "../../../../components/swiper/swiper";
import { SwiperAgreementsContainer } from "../../../../components/swiper/swiperAgreements";
import { useApiContext } from "../../../../contexts/ApiInterceptorContext";

export function AgreementsClient() {

    const { defaultCallsSchedules, api } = useApiContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const [loadingPictures, setLoadingPictures] = useState(true)
    const [agreementsList, setAgreementsList] = useState([])
    const [agreementsListLoading, setAgreementsListLoading] = useState([])
    const [agreementsCategories, setAgreementsCategories] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [page, setActivePage] = useState(1)
    const [selectedAgreementCategorie, setSelectedAgreementCategorie] = useState('')
    const [filteredAgreementList, setFilteredAgreementList] = useState([])

    async function getData() {
        const agreementsCategories = await defaultCallsSchedules.getAgreementsCategories(userData[0]?.token)
        if (agreementsCategories.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(agreementsCategories.data)
        }
        const agreementsData = await defaultCallsSchedules.getAgreements(userData[0].token, '', 1, '', agreementsCategories.data[0]?.categories)
        setTotalPages(agreementsData?.data?.totalPages)
        setAgreementsList(agreementsData?.data?.data.sort((a, b) => {
            const nameA = a.title.toUpperCase();
            const nameB = b.title.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        }))

        setSelectedAgreementCategorie(agreementsData.data.data[0])
        setFilteredAgreementList(agreementsData?.data?.data?.filter(agreement => agreement.categories === agreementsData.data.data[0]?.categories)
            .sort((a, b) => {
                const nameA = a.title.toUpperCase();
                const nameB = b.title.toUpperCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            })
        )
        setLoading(false)

        setAgreementsListLoading(Array(agreementsData?.data?.data?.length).fill(0))
        await Promise.all(agreementsData.data.data.map(async agreement => {
            if (agreement.pictures?.length > 0) {

                try {
                    
                    const pictures = await Promise.all(agreement.pictures.map(async agreementPic => {
                        const picture = await defaultCallsSchedules.getAgreementsPicture(userData[0].token, agreement.id, agreementPic)
                        return URL.createObjectURL(picture.data)
                    }))
                    agreement.picturesImg = pictures.filter(Boolean)
                } catch (error) {
                    
                }
            }
        }))

        setAgreementsCategories(agreementsCategories.data)
        setLoadingPictures(false)
    }

    useEffect(() => {
        getData()
    }, [])

    async function getMorePage(actualPage) {

        setLoading(true)
        setLoadingPictures(true)
        const agreementsData = await defaultCallsSchedules.getAgreements(userData[0].token, '', actualPage, '', selectedAgreementCategorie?.categories)
        setAgreementsList([...agreementsList.concat(agreementsData.data.data)])
        setActivePage(actualPage)
        setLoading(false)
        await Promise.all(agreementsData.data.data.map(async agreement => {
            if (agreement.pictures?.length > 0) {
                const pictures = await Promise.all(agreement.pictures.map(async agreementPic => {
                    const picture = await defaultCallsSchedules.getAgreementsPicture(userData[0].token, agreement.id, agreementPic)
                    return URL.createObjectURL(picture.data)
                }))
                agreement.picturesImg = pictures.filter(Boolean)
            }
        }))
        setLoadingPictures(false)
    }

    async function handleSelectCategorie(categorie) {

        setActivePage(1)
        setLoading(true)
        setSelectedAgreementCategorie(categorie)
        const agreementsData = await defaultCallsSchedules.getAgreements(userData[0].token, '', page, '', categorie?.categories)
        setTotalPages(agreementsData.data.totalPages)
        setAgreementsList(agreementsData.data.data)
        setFilteredAgreementList(agreementsData.data.data.sort((a, b) => {
            const nameA = a.title.toUpperCase();
            const nameB = b.title.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        }))

        setAgreementsListLoading(Array(agreementsData?.data?.data?.length).fill(0))
        await Promise.all(agreementsData.data.data.map(async agreement => {
            if (agreement.pictures?.length > 0) {
                try {                
                    const pictures = await Promise.all(agreement.pictures.map(async agreementPic => {
                        const picture = await defaultCallsSchedules.getAgreementsPicture(userData[0].token, agreement.id, agreementPic)
                        return URL.createObjectURL(picture.data)
                    }))
                    agreement.picturesImg = pictures.filter(Boolean)
                } catch (error) {
                    
                }
            }
        }))
        setLoading(false)
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Convênios'} />
            </TitlePage>
            <Body>
                <div className="py-12 w-full flex flex-col gap-8 flex-wrap rounded-lg justify-center items-center">
                    <h1 className="text-lg text-primaryDefaultLight">Nossos convênios</h1>
                    {
                        !loading &&
                        <LabelInput text={'Categoria'}>
                            <InputAutoComplete preSelectedValue={selectedAgreementCategorie?.categories} data={agreementsCategories} selectedLabel={'categories'} optionList={['categories']} onChange={(e) => handleSelectCategorie(e)} />
                        </LabelInput>
                    }
                    {
                        agreementsList?.length == 0 && !loading &&
                        <h1 className="text-sm text-titleBlackTextLight">Nenhum convênio cadastrado ainda, volte mais tarde</h1>
                    }
                    <div className="w-full flex flex-row gap-8 flex-wrap rounded-lg justify-center">
                        {
                            loading ?
                                agreementsListLoading.map(load => {
                                    return <div className="shadow-lg relative flex flex-col bg-gray-100 dark:bg-thirdDefaultDark rounded-lg gap-2 w-[600px] sm:max-h-96 border border-gray-100 dark:border-primaryBorderDark">
                                        <div className="w-full flex flex-col items-center sm:items-start sm:flex-row gap-4 p-4">
                                            <div className='bg-gray-300 animate pulse rounded-md flex flex-col w-48 h-48 bg-no-repeat bg-center bg-cover'>
                                            </div>
                                            <div className="flex flex-col gap-2 sm:max-w-[352px] sm:h-72 sm:max-h-96 overflow-x-hidden">
                                                <span className="text-base w-full h-6 animate-pulse text-primaryDefaultLight dark:text-titleGrayTextDark font-extrabold break-words"></span>
                                                <p wrap="hard" className="animate-pulse text-sm text-titleBlackTextLight h-10 w-80" disabled ></p>
                                                <span className="text-base w-full h-6 animate-pulse text-primaryDefaultLight dark:text-titleGrayTextDark font-extrabold break-words"></span>
                                                <span className="text-base w-full h-6 animate-pulse text-primaryDefaultLight dark:text-titleGrayTextDark font-extrabold break-words"></span>

                                            </div>
                                        </div>
                                    </div>
                                })
                                :
                                filteredAgreementList.map((agreement, index) => {
                                    return <div key={uuid()} id={uuid()} className="shadow-lg relative flex flex-col bg-gray-100 dark:bg-thirdDefaultDark rounded-lg gap-2 w-[600px] sm:max-h-96 border border-gray-100 dark:border-primaryBorderDark ">
                                        <div className="w-full flex flex-col items-center sm:items-start sm:flex-row gap-4 p-4">
                                            {
                                                loadingPictures ?
                                                    <div className='rounded-md flex flex-col w-48 h-48 bg-no-repeat bg-center bg-cover'>
                                                        <span className="w-full roundd-md h-48 bg-gray-300 dark:bg-zinv-600 animate-pulse">

                                                        </span>
                                                    </div>
                                                    :
                                                    agreement?.picturesImg?.length > 0 ?
                                                        <div className='rounded-md flex items-center justify-center flex-col w-48 h-48 bg-no-repeat bg-contain bg-center border border-zinc-200 shadow-lg bg-zinc-50 '>
                                                            <SwiperAgreementsContainer imgs={agreement.picturesImg}></SwiperAgreementsContainer>
                                                        </div>
                                                        :
                                                        <div className='rounded-md p-4 items-center justify-center shadow-lg flex flex-col w-48 h-48 bg-no-repeat bg-center bg-cover'>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Em breve fotos...</a>
                                                        </div>
                                            }
                                            <div className="flex flex-col gap-2 sm:max-w-[352px] sm:h-72 sm:max-h-96 overflow-x-hidden">
                                                <h1 className="text-base text-primaryDefaultLight font-extrabold break-words">{agreement.title}</h1>
                                                <p wrap="hard" className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark h-48 w-80" disabled >{agreement.description}</p>
                                            </div>
                                        </div>

                                        <div className="text-sm bottom-0 h-14 bg-gray-200 dark:bg-zinc-600 flex flex-row gap-2 items-center justify-center">
                                            {
                                                agreement?.contacts?.link?.length > 0 &&
                                                <Tippy key={`${uuid()}`} content={agreement.contacts?.link}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div className="flex flex-row gap-1 items-center justify-center">
                                                        <FaEdgeLegacy className="text-lg" />
                                                        <a target="__blank" href={`${agreement.contacts?.link}`} className="hover:text-primaryDefaultLight dark:text-titleGrayTextDark transition-all duration-200 text-xs underline">{agreement?.contacts?.link?.slice(0, 15).concat('...')}</a>
                                                    </div>
                                                </Tippy>
                                            }
                                            {
                                                agreement?.contacts?.phone > 0 &&
                                                <Tippy key={`${uuid()}`} content={agreement.contacts?.phone}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div className="flex flex-row gap-1 items-center justify-center">
                                                        <FaPhone className="text-lg" />
                                                        <a className="text-xs hover:text-primaryDefaultLight transition-all duration-200">{agreement?.contacts?.phone?.slice(0, 15).concat('...')}</a>
                                                    </div>
                                                </Tippy>
                                            }
                                            {
                                                agreement?.contacts?.address?.length > 0 &&
                                                <Tippy key={`${uuid()}-tippy`} content={agreement?.contacts?.address}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div className="flex flex-row gap-1 items-center justify-center">
                                                        <FaMap className="text-lg" />
                                                        <a className="text-xs hover:text-primaryDefaultLight transition-all duration-200">{agreement?.contacts?.address?.slice(0, 15).concat('...')}</a>
                                                    </div>
                                                </Tippy>
                                            }
                                            {
                                                agreement?.contacts?.email?.length > 0 &&
                                                <Tippy key={`${uuid()}-tippy`} content={agreement?.contacts?.email}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div className="flex flex-row gap-1 items-center justify-center">
                                                        <FaAddressCard className="text-lg" />
                                                        <a className="text-xs hover:text-primaryDefaultLight transition-all duration-200">{agreement?.contacts?.email?.slice(0, 15).concat('...')}</a>
                                                    </div>
                                                </Tippy>
                                            }
                                        </div>
                                    </div>
                                })}
                    </div>
                    {
                        page < totalPages &&
                        <Button shadow={true} onClick={() => getMorePage(page + 1)}>Mais...</Button>
                    }
                </div>

            </Body>
        </Container>
    )
}