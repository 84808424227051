//hooks
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
//services
//contexts
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
import { NewTableDefault } from "../../../components/table/new.table.default";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function FinancialConciliations() {

    const { account_id } = useParams()
    const { defaultCallsFinancial } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [ accountsData, setAccountsData ] = useState()
    const [ loading, setLoading ] = useState(true)
    const [ inNewConciliation, setInNewConciliation ] = useState(false)

    useEffect(() => {
        async function getAccountsData(){
            setLoading(true)
            const getAccount = await defaultCallsFinancial.getAccountsById(userData[0]?.token, account_id)
            if (getAccount.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(getAccount.data)
            }
            setAccountsData(getAccount.data)
            setLoading(false)
        }
        getAccountsData()
    }, [])

    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full">
                    <Title text={`Conciliações`}/>
                    <Button 
                        module={"financialConciliation:create"} 
                        userData={userData} 
                        shadow={true} 
                        onClick={() => navigate(`/financial/accounts/${account_id}/conciliations/create`)}
                    >
                        + Novo
                    </Button>
                </div>
            </TitlePage>
            <Body>
                <div className="flex flex-col ga-4 w-full">
                    {
                        !loading &&
                        <div className="text-sm mb-2 text-zinc-500 dark:text-titleGrayTextDark">
                            <p>Banco: {accountsData[0]?.bankName} | Agência: {accountsData[0]?.accountAgency}</p>
                            <p>Conta: {accountsData[0]?.accountNumber}</p>
                        </div>
                    }
                    <NewTableDefault
                        key={'table-financialConciliations'}
                        endpoint={`/api/v1/financial/conciliations/account/${account_id}`}
                        token={userData[0]?.token}
                        module="financial"
                        columns={[
                            {
                                description: 'ID',
                                field: 'id',
                                type: 'number'
                            },
                            {
                                description: 'Data inicio',
                                field: 'startDate',
                                type: 'date'
                            },
                            {
                                description: 'Data fim',
                                field: 'finishDate',
                                type: 'date'
                            },
                            {
                                description: 'Conciliações Pendentes',
                                field: 'pendingConciliations',
                                type: 'string'
                            },
                            {
                                description: 'Criado em',
                                field: 'created_at',
                                type: 'date',
                            },
                        ]}
                        filters={[]}
                        onClickInTableLine={(row) => navigate(`/financial/conciliations/${row.id}/transactions`)}
                    /> 
                </div>
            </Body>
        </Container>
    )
}