import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
//components
import { Body } from '../../../../components/container/Body'
import { Toogle } from '../../../../components/toogle/Toogle'
import { Button } from '../../../../components/buttons/button.default'
import { Container } from '../../../../components/container/container'
import { Title } from '../../../../components/titlePages/title'
import { TitlePage } from '../../../../components/titlePages/title.page'
import moment from 'moment'
import { Footer } from '../../../../components/footer/Footer'
import { ScreenModal } from '../../../../components/modals/notification/screenModal'
import CurrencyInput from '../../../../components/input/inputCurrencyPtBr'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'
import { TableDefault } from '../../../../components/table/table.default'
//contexts
import { useThemeContext } from '../../../../contexts/themeContext'
import { useAuth } from '../../../../contexts/useAuth'
import { LabelInput } from '../../../../components/label/label.input'
import { InputAutoComplete } from '../../../../components/input/input.autocomplete'
//utils
import { paymentMethods } from '../../../../utils/payment/paymentMethods'
import { responseError } from '../../../../utils/responsesFunctions/error.response'
import { DefaultLoader } from '../../../../components/loaders/defaultLoader'
import { formatPaymentMethods } from '../../../../utils/formatObjects/formatPaymentMethods'
import { InputCheckBox } from '../../../../components/input/input.checkBox'
import { FaInfoCircle, FaList, FaRegMoneyBillAlt, FaRegNewspaper, FaRegWindowClose, FaPencilAlt } from 'react-icons/fa'
import { FiX, FiCheck } from 'react-icons/fi'
import { currencyPtBr } from '../../../../utils/formatCurrency/currencyTextPTBR'
import { LoginLoader } from '../../../../components/loaders/loginLoader'
import { NewTableDefault } from '../../../../components/table/new.table.default'
import { useApiContext } from '../../../../contexts/ApiInterceptorContext'

export function ReservationEdit({ setInEdit, data }) {
    
    const {defaultCallsSchedules, api} = useApiContext()
    const { userData } = useAuth()
    const { showUniversalModal, setShowUniversalModal, setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [ loading, setLoading ] = useState(false)
    const [ loadingLog, setLoadingLog ] = useState(false)
    const [ reservationData, setReservationData ] = useState(data)
    const [ selectedPaymentMethod, setSelectedPaymentMethod ] = useState('')
    const [ selectedReturnBalance, setSelectedReturnBalance ] = useState(true)
    const [ observationUpdate, setObservationUpdate ] = useState(true)
    const [ observation, setObservation ] = useState(data?.observation)
    const [ showTermsInReceiptModal, setShowTermsInReceiptModal ] = useState(false)
    const [ showPaymentModal, setShowPaymentModal ] = useState(false)
    const [ showAssociatedRecurrencesModal, setShowAssociatedRecurrencesModal ] = useState(false)
    const [ recurrenceLinks, setRecurrenceLinks ] = useState([])
    const [ wantCancelJustOneOfRecurrence, setWantCancelJustOneOfRecurrence ] = useState(true)

    //informar pagamento
    const [ totalValue, setTotalValue ] = useState(reservationData?.payments_totalValue || 0)
    const [ userBalance, setUserBalance ] = useState(0)
    const [ useBalance, setUseBalance ] = useState(false)
    const [ usedBalance, setUsedBalance ] = useState(reservationData?.payments_balance || 0)
    const [ discount, setDiscount ] = useState(reservationData?.payments_discount || 0)
    const [ paymentFee, setPaymentFee ] = useState(reservationData?.payments_fee || 0)

    //valores multa e reembolso
    const [ showCancelModal, setShowCancelModal ] = useState(false)
    const [ showChangeValuesModal, setShowChangeValuesModal ] = useState(false)
    const [ fee, setfee ] = useState(0)
    const [ chargeBack, setChargeBack ] = useState(0)

    const [ showReceiptNumber, setShowReceiptNumber ] = useState(false)
    const [ receiptNumber, setReceiptNumber ] = useState('')

    //logs
    const [ filteredLogs, setFilteredLogs ] = useState([])
    const tableThead = [
        {
            "name": 'Data realizada',
            "original_name": 'created_at'
        },
        {
            "name": 'Executado por',
            "original_name": 'users_fullname'
        },
        {
            "name": 'Ação',
            "original_name": 'action'
        }
    ]

    //filters search
    const [ totalPages, setTotalPages ] = useState(1)

    useEffect(() => {
        logs()
        if(reservationData.isRecurrent){
            getRecurrences()
        }
    }, [])

    useEffect(() => {
        setReceiptNumber(reservationData?.payments_manualReceipt)
        cancelAndPayDate()
    }, [])

    useEffect(() => {
        if (!showUniversalModal) {
            setShowCancelModal(false)
            setShowCancelModal(false)
            setShowChangeValuesModal(false)
            setShowTermsInReceiptModal(false)
            setShowPaymentModal(false)
            setShowAssociatedRecurrencesModal(false)
            setUserBalance(0)
            setUseBalance(false)
            setTotalValue(reservationData?.payments_totalValue || 0)
            setUsedBalance(reservationData?.payments_balance || 0)
            setDiscount(reservationData?.payments_discount || 0)
            setPaymentFee(reservationData?.payments_fee || 0)
        }
    }, [showUniversalModal])

    async function handleCancelSchedule() {
        setLoading(true)
        try {
            let apiData = userData[0]?.customersData?.useBalance ?
                {
                    returnBalance: (reservationData.payments_status === 0 || reservationData.payments_status === 3 || !userData[0].customersData?.useBalance) ? false : selectedReturnBalance,
                    fee
                }
                :
                {
                    returnBalance: false,
                    fee,
                    chargeBack
                }

            const cancellationData = await api.put(`/api/v1/schedules/cancel/schedules_id/${reservationData.id}`,
                apiData,
                {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            setLoading(false)
            reservationData.payments_totalValue = cancellationData.data.data.payment[0]?.totalValue,
            reservationData.payments_chargeBack = cancellationData.data.data.payment[0]?.chargeBack,
            reservationData.payments_fee = cancellationData.data.data.payment[0]?.fee,
            reservationData.payments_balance = cancellationData.data.data.payment[0]?.balance,
            reservationData.status = cancellationData.data.data.schedules[0]?.status,
            reservationData.original_status = cancellationData.data.data.payment[0]?.status,
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText(`Reserva cancelada`)
            setShowUniversalModal(false)
        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function logs(e) {
        setLoadingLog(true)
        const logData = await defaultCallsSchedules.getLogs(
            userData[0]?.token,
            e?.page ? e.page : '',
            'schedules',
            reservationData.id
        )
        setTotalPages(logData.data.totalPages)
        setFilteredLogs(logData.data.data)
        setLoadingLog(false)
    }

    async function getRecurrences(){
        const recurrences = await defaultCallsSchedules.getRecurrenceSchedulesByIdentifier(userData[0]?.token, reservationData?.recurrenceIdentifier)
        if(recurrences.error){
            console.log('auiq')
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(recurrences.error)
        }
        setRecurrenceLinks(recurrences.data)
    }   

    async function updatePaymentStatus(status) {
        if (!selectedPaymentMethod?.value) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            document.getElementById('paymentMethod').style.border = "1px solid red"
            return setShowNotificationModalText('Forma de pagamento é obrigatoria')
        }
        //Api atualizacao de pagamentos
        setLoading(true)
        try {
            const updatePayment = await api.put(`/api/v1/schedules/confirm/schedules_id/${reservationData?.id}`, {
                discount: discount > 0 ? discount : undefined,
                fee: paymentFee > 0 ? paymentFee : undefined,
                useBalance,
                paymentMethod: selectedPaymentMethod.value,
                sendEmail: true
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setReservationData({
                ...reservationData,
                status: updatePayment.data?.data.schedules[0]?.status,
                original_status: updatePayment.data?.data.schedules[0]?.status,
                payments_status: updatePayment.data?.data.payment[0]?.status,
                paymentMethod: selectedPaymentMethod?.value,
                originalPaymentStatus: updatePayment.data?.data.payment[0]?.status,
                paymentStatus: <div className='p-2 '><a className='bg-green-700 rounded-lg p-1 text-white'>Pago</a></div>,
                payments_discount: updatePayment.data.data?.payment[0].discount,
                payments_balance: updatePayment.data.data?.payment[0].balance,
                payments_fee: updatePayment.data.data?.payment[0].fee,
                payments_chargeBack: updatePayment.data.data?.payment[0].chargeBack,
                payments_totalValue: (reservationData.payments_value + paymentFee) - discount - usedBalance,
                paymentStatus: <div className='p-2 '><a className='bg-green-700 rounded-lg p-1 text-white'>Pago</a></div>
            })

            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Pagamento informado com sucesso!')
            setShowUniversalModal(false)
            setLoading(false)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                animateLoading(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
        setLoading(false)
    }

    async function handleSubmitNewPayment() {
        setLoading(true)
        setShowPaymentModal(true)
        setShowUniversalModal(true)
        const balance = await defaultCallsSchedules.getSelectedUserBalance(userData[0].token, reservationData.schedule_users_id)
        setUserBalance(balance?.data[0]?.balance || 0)
        setLoading(false)
    }

    async function handleChangeSchedulesValues() {
        try {
            setLoading(true)
            const updateScheduleValues = await api.put(`/api/v1/payments/${reservationData?.payments_id}`, {
                discount,
                fee,
                chargeBack
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            reservationData.payments_discount = updateScheduleValues.data.data[0]?.discount,
                reservationData.payments_totalValue = updateScheduleValues.data.data[0]?.totalValue,
                reservationData.payments_chargeBack = updateScheduleValues.data.data[0]?.chargeBack,
                reservationData.payments_fee = updateScheduleValues.data.data[0]?.fee,
                reservationData.payments_balance = updateScheduleValues.data.data[0]?.balance
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Valores atualizados')
            setShowUniversalModal(false)
            setShowChangeValuesModal(false)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowUniversalModal(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowUniversalModal(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function cancelAndPayDate() {
        //data do agendamento convertida para momentjs
        let scheduleDate = moment(reservationData.original_startDate)
        let scheduleDatePay = moment(reservationData.original_startDate)

        let daysToPay = reservationData.daysToPay
        let daysToCancel = reservationData.daysToCancel

        //Formata a data de cancelamento caso náo encontre parametrizacao ficará vazia
        if (daysToCancel > 0) {
            setReservationData((prev) => ({ ...prev, cancelDate: scheduleDate.subtract(daysToCancel, 'days').format("DD/MM/YYYY").concat(" 23:59") }))
        } else {
            setReservationData((prev) => ({ ...prev, cancelDate: '-' }))
        }
        //Formata a data de pagamento caso náo encontre parametrizacao ficará vazia
        if (daysToPay > 0) {
            setReservationData((prev) => ({ ...prev, payDate: scheduleDatePay.subtract(daysToPay, 'days').format("DD/MM/YYYY").concat(" 23:59") }))
        } else {
            setReservationData((prev) => ({ ...prev, payDate: '-' }))
        }
    }

    function handleUseBalance() {
        setUseBalance(!useBalance)
        if (userBalance > 0 && !useBalance) {
            if(userBalance > totalValue){
                return setUsedBalance(totalValue)
            }
            return setUsedBalance(userBalance)
        } 
        setUsedBalance(0)
    }

    async function handleNewReceipt() {

        if (!receiptNumber) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(`Campo recibo está vazio, verifique!`)
        }
        try {
            const receipt = await api.put(`/api/v1/payments/${reservationData.payments_id}/manualReceipt`, {
                manualReceipt: receiptNumber
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setShowReceiptNumber(false)
        } catch (error) {

            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(responseError(error))

        }
    }

    function handleShowCancelModal() {
        userData[0]?.customersData?.useBalance ?
            handleCancelSchedule()
            :
            setShowCancelModal(true)
    }

    async function handleUpdateContract(contractStatus) {
        setLoading(true)
        try {
            const changeContractStatus = await api.put(`/api/v1/schedules/contract/schedules_id/${reservationData.id}/contractSigned/${contractStatus}`, {}, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setReservationData({ ...reservationData, contractSigned: contractStatus })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(responseError(error))
        }
    }

    async function handleUpdateObservation(){
        setLoading(true)
        try {
            await api.put(`/api/v1/schedules/${reservationData.id}/observation`, {
                observation
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setReservationData({ ...reservationData, observation })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(responseError(error))
        }
    }

    async function handleCancelJustOnOfRecurrence(){
        setLoading(true)
        try {
            await api.patch(`/api/v1/schedules/cancel/schedules_id/${reservationData.id}/recurrence`, {}, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setReservationData(prev => ({ ...prev, status: 2 }))
            setShowNotificationModalText(`Reserva cancelada`)
            setShowUniversalModal(false)
        } catch (error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(responseError(error))
        }
    }

    function cancelOptions(){
        // caso seja recorrente e deseja cancelar apenas um horário
        if(reservationData?.isRecurrent  == true && wantCancelJustOneOfRecurrence) { 
            return (
                <>
                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja cancelar a reserva apenas deste dia e horário? (Essa reserva é recorrente)</p>
                    <div className='flex gap-4 items-center justify-center'>
                        <Button onClick={() => handleCancelJustOnOfRecurrence()} shadow={true}>Sim</Button>
                        <Button approval={false} onClick={() => {
                            setWantCancelJustOneOfRecurrence(false)
                        }} shadow={true}>Não</Button>
                    </div>
                </>
            )
        }

        // modal para clientes que não usam saldo
        if (!userData[0]?.customersData?.useBalance) {
            return (    
                <>
                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Dados para confirmação de cancelamento da reserva</p>
                    <div className='flex items-start justify-center'>
                        <LabelInput text={'Multa'}>
                            <CurrencyInput
                                id={`fee`}
                                placeHolder={`R$ ${(fee ? fee : '0').toString()?.replace('.', ',')}`}
                                width={40}
                                onChange={(e) => {
                                    setfee(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                }}
                            />
                        </LabelInput>
                        <Tippy content={<a>Informe se foi cobrado algum valor de multa para o cliente efetuar o cancelamento, o valor da multa será somado ao valor da reserva</a>}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                <FaInfoCircle  className='cursor-pointer'/>
                            </div>
                        </Tippy>
                    </div>
                    {
                        reservationData?.originalPaymentStatus == 1 && // se o pagamento foi confirmado
                        <div className='flex items-start justify-start'>
                            <LabelInput text={'Reembolso'}>
                                <CurrencyInput
                                    id={`chargeBack`}
                                    placeHolder={`R$ ${(chargeBack ? chargeBack : '0').toString()?.replace('.', ',')}`}
                                    width={40}
                                    onChange={(e) => {
                                        setChargeBack(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                    }}
                                />
                            </LabelInput>
                            <Tippy content={<a>Informe se foi reembolsado algum valor ao cliente</a>}
                                arrow={true}
                                animation='shift-away'
                                placement='top'
                                delay={100}>
                                <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                    <FaInfoCircle className='cursor-pointer' />
                                </div>
                            </Tippy>
                        </div>
                    }
                    <div className='flex gap-4 mt-2 items-center justify-center'>
                        <Button onClick={() => handleCancelSchedule()} shadow={true}>Confirmar</Button>
                        <Button approval={false} onClick={() => {
                            setWantCancelJustOneOfRecurrence(true)
                            setShowCancelModal(false)
                            setShowUniversalModal(false)
                        }} shadow={true}>Cancelar</Button>
                    </div>
                </>
            )
        }

        // modal para clientes que usam saldo
        if(userData[0]?.customersData?.useBalance){
            return (
                <>
                    {
                        reservationData?.associateSchedules?.length > 0 && !reservationData?.isRecurrent ?
                            <div className='flex flex-col gap-2 items-center'>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark '>{`Nesta reserva existem ${reservationData.associateSchedules?.length + 1} horários vinculado`}</p>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark '>{`ao efetuar o cancelamento, todos os horários serão afetados`}</p>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja cancelar a reserva?</p>
                                <p className='text-xs text-red-400 dark:text-red-700'>* Caso o método de pagamento escolhido for desconto em folha, o sistema irá cancelar a reserva e zerar o valor pago</p>
                            </div>
                            : 
                            <div className='flex flex-col gap-2 items-center'>
                                <p className='text-md mt-4 text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>Deseja cancelar a reserva?</p>
                            </div>
                    }
                    {
                        reservationData.payments_status === 1 && userData[0].customersData?.useBalance && //
                        <div className='flex flex-col sm:flex-row-reverse gap-2 items-center'>
                            <div className='flex items-start justify-start gap-1'>
                                <LabelInput text={'Voltar Saldo'}>
                                    <Toogle status={selectedReturnBalance} onClick={() => setSelectedReturnBalance(!selectedReturnBalance)}></Toogle>
                                </LabelInput>
                                <Tippy
                                    content={<a>Informe se o cancelamento deverá gerar saldo para o cliente, o saldo será o mesmo valor pago na reserva</a>}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    delay={100}>
                                    <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                        <FaInfoCircle />
                                    </div>
                                </Tippy>
                            </div>
                            {
                                userData[0]?.customersData?.useBalance &&
                                <div className='flex items-start justify-start'>
                                    <LabelInput text={'Multa'}>
                                        <CurrencyInput
                                            id={`fee`}
                                            placeHolder={`R$ ${(fee ? fee : '0').toString()?.replace('.', ',')}`}
                                            width={40}
                                            onChange={(e) => {
                                                setfee(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                            }}
                                        />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Informe se foi cobrado algum valor de multa para o cliente efetuar o cancelamento, o valor da multa será somado ao valor da reserva</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                            }
                        </div>
                    }
                    {   //informativo, caso reserva seja recorrente
                        reservationData?.isRecurrent &&
                        <div className='flex flex-col items-center justify-center my-4 text-xs text-titleBlackTextLight dark:text-titleGrayTextDark'>
                            <p className='text-red-400 text-center mb-2'>Obs.: Essa reserva é uma reserva recorrente.<br/>Somente voltará para status "aberto" os horários futuros, que não foram usados.</p>
                            <p>Qtd. de reservas: {recurrenceLinks?.length}</p>
                            <p>Qtd. de reservas usadas: {recurrenceLinks?.filter(r => r.wasItDone == 'Sim')?.length}</p>
                        </div>
                    }
                    <div className='flex flex-row justify-center gap-4'>
                        <Button onClick={() => handleShowCancelModal()} shadow={true}>Sim</Button>
                        <Button 
                            onClick={() => {
                                setShowUniversalModal(false); 
                                setWantCancelJustOneOfRecurrence(true)
                            }} 
                            approval={false} 
                            shadow={true}
                        >Não</Button>
                    </div>
                </>
            )
        }

        return <></>
    }

    return (
        <>
            <ScreenModal 
                title={
                    showPaymentModal ? 'Informar pagamento' 
                        : showCancelModal ? 'Cancelar reserva'
                        : showChangeValuesModal ? 'Alterar valores'
                        : showAssociatedRecurrencesModal ? 'Relação'
                        : 'Impressão do recibo'
                }   
                width={showAssociatedRecurrencesModal ? 800 : null}
            >
                {   // loading/carregamento
                    loading &&
                    <div className='h-96 w-[600px] flex flex-col items-center justify-center'>
                        <DefaultLoader />
                    </div>
                }
                {   // modal para informar pagamento
                    showPaymentModal && !loading && 
                    <div className='flex flex-col w-full sm:w-[600px] items-center justify-start gap-6 px-2 pb-8 sm:pb-8'>
                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark mt-10'>Informe a forma de pagamento</p>
                        <LabelInput text={'Forma de pagamento'}>
                            <InputAutoComplete id="paymentMethod" data={paymentMethods} selectedLabel={'description'} optionList={['id', 'description']} onChange={(e) => setSelectedPaymentMethod(e)} />
                        </LabelInput>
                        <div className='flex flex-col gap-4 items-start justify-start w-96'>
                            <div className='w-full flex flex-row items-center justify-between'>
                                <LabelInput text={'Desconto'}>
                                    <CurrencyInput placeHolder={`R$`} id='discountForReservation' onChange={(e) => {
                                        const discountVal = e.target.value.length > 0
                                            ? parseFloat(e.target.value?.replace('R$', '')?.replace('.', '')?.replace(',', '.'))
                                            : 0
                                        if (discountVal > ((reservationData?.payments_value + paymentFee) - usedBalance)) {
                                            document.getElementById('discountForReservation').value = ''
                                            return 
                                        } 
                                        setDiscount(discountVal)
                                    }}/>
                                </LabelInput>
                                <LabelInput text={'Multa'}>
                                    <CurrencyInput placeHolder={`R$`} id='paymentFeeForReservation' onChange={(e) => {
                                        if (e.target.value?.length > 0 && parseFloat(e.target.value?.replace('R$', '')?.replace('.', '')?.replace(',', '.'))) {
                                            setPaymentFee(parseFloat(e.target.value?.replace('R$', '')?.replace('.', '')?.replace(',', '.')))
                                        } else {
                                            setPaymentFee(0)
                                        }
                                    }}/>
                                </LabelInput>
                            </div>
                            {
                                userData[0]?.customersData?.useBalance &&
                                <LabelInput text={`Usar saldo`}>
                                    <InputCheckBox value={useBalance} onClick={() => handleUseBalance()} />
                                </LabelInput>
                            }
                        </div>
                        <div className='flex flex-col justify-start items-start w-96 gap-2 border border-primaryDefaultLight rounded-md p-2'>
                            {
                                userData[0]?.customersData?.useBalance &&
                                <>
                                    <div className='flex flex-row flex-wrap gap-4 justify-between border-b border-primaryDefaultLight w-full'>
                                        <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Saldo do cliente:</a>
                                        <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{(userBalance - usedBalance)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                                    </div>
                                    <div className='flex flex-row flex-wrap gap-4 justify-between border-b border-primaryDefaultLight w-full'>
                                        <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Saldo do utilizado:</a>
                                        <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{usedBalance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                                    </div>
                                </>
                            }
                            <div className='flex flex-row flex-wrap gap-4 justify-between border-b border-primaryDefaultLight w-full'>
                                <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Desconto:</a>
                                <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{discount?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                            </div>
                            <div className='flex flex-row flex-wrap gap-4 justify-between border-b border-primaryDefaultLight w-full'>
                                <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Multa:</a>
                                <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{paymentFee?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                            </div>
                            <div className='flex flex-row flex-wrap gap-4 justify-between border-b border-primaryDefaultLight w-full'>
                                <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Valor da reserva:</a>
                                <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                            </div>
                            <div className='flex flex-row justify-between w-full flex-wrap gap-4'>
                                <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Total a pagar:</a>
                                <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark font-bold'>{((reservationData?.payments_value + paymentFee) - discount - usedBalance)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                            </div>
                        </div>
                        <Button onClick={() => updatePaymentStatus(reservationData.original_status)} shadow={true}>Salvar</Button>
                    </div>
                }  
                {   // modal para alteraçao de valores
                    showChangeValuesModal && !loading && 
                    <div className="gap-4 flex flex-col w-full p-4 items-center justify-center">
                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Dados para alteraçao de valores da reserva.</p>
                        <div className='flex items-center justify-center'>
                            <LabelInput text={'Desconto'}>
                                <CurrencyInput
                                    id={`discount`}
                                    placeHolder={`R$ ${(discount ? discount : '').toString()?.replace('.', ',')}`}
                                    width={40}
                                    onChange={(e) => {
                                        setDiscount(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                    }}
                                />
                            </LabelInput>
                            <Tippy
                                content={<a>Informe se dado algum desconto ao cliente. Caso não alterar será mantido o valor atual</a>}
                                arrow={true}
                                animation='shift-away'
                                placement='top'
                                delay={100}>
                                <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                    <FaInfoCircle />
                                </div>
                            </Tippy>
                        </div>
                        <div className='flex items-start justify-start'>
                            <LabelInput text={'Multa'}>
                                <CurrencyInput
                                    id={`fee`}
                                    placeHolder={`R$ ${(fee ? fee : '').toString()?.replace('.', ',')}`}
                                    width={40}
                                    onChange={(e) => {
                                        setfee(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                    }}
                                />
                            </LabelInput>
                            <Tippy
                                content={<a>Informe se foi cobrado algum valor de multa para o cliente efetuar o cancelamento, o valor da multa será somado ao valor da reserva. Caso não alterar será mantido o valor atual</a>}
                                arrow={true}
                                animation='shift-away'
                                placement='top'
                                delay={100}>
                                <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                    <FaInfoCircle />
                                </div>
                            </Tippy>
                        </div>
                        {
                            reservationData?.originalPaymentStatus == 1 &&
                            <div className='flex items-start justify-start'>
                                <LabelInput text={'Reembolso'}>
                                    <CurrencyInput
                                        id={`chargeBack`}
                                        placeHolder={`R$ ${(chargeBack ? chargeBack : '').toString()?.replace('.', ',')}`}
                                        width={40}
                                        onChange={(e) => {
                                            setChargeBack(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                        }}
                                    />
                                </LabelInput>
                                <Tippy
                                    content={<a>Informe se foi reembolsado algum valor ao cliente. Caso não alterar será mantido o valor atual</a>}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    delay={100}>
                                    <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                        <FaInfoCircle />
                                    </div>
                                </Tippy>
                            </div>
                        }
                        <div className='flex gap-4 items-center justify-center'>
                            <Button onClick={() => handleChangeSchedulesValues()} shadow={true}>Confirmar</Button>
                            <Button approval={false} onClick={() => {
                                setShowCancelModal(false)
                                setShowUniversalModal(false)
                            }} shadow={true}>Canclear</Button>
                        </div>
                    </div>
                }                   
                {   // modal para informar se o recibo incluirá termos
                    showTermsInReceiptModal && !loading && 
                    <div className="gap-4 flex flex-col w-full p-12 items-start justify-center">
                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Exibir termos de uso em recibo?</p>
                        <div className='flex gap-4 items-center justify-center'>
                            <Button 
                                approval={true}
                                onClick={() => {
                                    localStorage.setItem('receipt', JSON.stringify({
                                        id: reservationData?.id,
                                        textComplement: 'da reserva',
                                        manualReceiptNumber: receiptNumber,
                                        local: reservationData.services_name,
                                        paymentStatus: reservationData.original_status,
                                        startDate: reservationData.startDate,
                                        userId: reservationData.schedule_users_id,
                                        userName: reservationData.schedule_users_firstname,
                                        original_user_firstName: reservationData.original_user_firstName,
                                        original_user_lastName: reservationData.schedule_users_lastname,
                                        ...reservationData,
                                        associateSchedules: reservationData.associateSchedules.map(anotherHour => anotherHour.startDate),
                                        associateSchedulesValue: reservationData.associateSchedules.map(anotherHour => anotherHour.serviceValue),
                                        showTerms: true
                                    }));
                                    window.open('/#/receipt', "_blank");
                                    setShowTermsInReceiptModal(false);
                                    setShowUniversalModal(false);
                                }}
                                shadow={true}
                            >
                                Exibir
                            </Button>
                            <Button 
                                approval={false} 
                                onClick={() => {
                                    localStorage.setItem('receipt', JSON.stringify({
                                        id: reservationData?.id,
                                        textComplement: 'da reserva',
                                        manualReceiptNumber: receiptNumber,
                                        local: reservationData.services_name,
                                        paymentStatus: reservationData.original_status,
                                        startDate: reservationData.startDate,
                                        userId: reservationData.schedule_users_id,
                                        userName: reservationData.schedule_users_firstname,
                                        original_user_firstName: reservationData.original_user_firstName,
                                        original_user_lastName: reservationData.schedule_users_lastname,
                                        ...reservationData,
                                        associateSchedules: reservationData.associateSchedules.map(anotherHour => anotherHour.startDate),
                                        associateSchedulesValue: reservationData.associateSchedules.map(anotherHour => anotherHour.serviceValue),
                                        showTerms: false
                                    }));
                                    window.open('/#/receipt', "_blank");
                                    setShowTermsInReceiptModal(false);
                                    setShowUniversalModal(false);
                                }}
                                shadow={true}
                            >
                                Não exibir
                            </Button>
                        </div>
                    </div>
                } 
                {   // mostrar relação de reservas recorrentes atreladas 
                    showAssociatedRecurrencesModal && !loading && 
                    <div className='p-10 w-full max-h-96'>
                        <NewTableDefault
                            staticData={recurrenceLinks}
                            columns={[
                                {
                                    description: 'Espaço',
                                    type: 'string',
                                    field: 'services_name',
                                },
                                {
                                    description: 'Data',
                                    type: 'string',
                                    field: 'startDateFormated',
                                },
                                {
                                    description: 'Horário',
                                    type: 'string',
                                    newFieldValue: (e) => {
                                        return e.startHourFormated + ' - ' + e.finishHourFormated
                                    }
                                },
                                {
                                    description: 'Realizada',
                                    type: 'string',
                                    field: 'wasItDone'
                                }
                            ]}
                            filters={[]}
                            totals={[]}
                        />
                    </div>
                }
                {   // modal de cacelamento 
                    showCancelModal && !loading && 
                    <div className="gap-4 flex flex-col w-full p-4 items-center justify-center">
                        {cancelOptions()}
                    </div>
                }
            </ScreenModal>
            <Container>
                {
                    loading &&
                    <div className='absolute z-50 flex flex-col items-center justify-center w-full h-full bg-zinc-700 opacity-70'>
                        <LoginLoader />
                    </div>
                }
                <TitlePage>
                    <div className='flex items-center gap-5'>
                        <Title text={`Detalhes da reserva`} />
                        <div className='flex gap-1 items-center justify-center'>
                            {reservationData.status !== 2 && <a className='text-sm'>{reservationData?.paymentStatus}</a>}
                            {
                                reservationData.categories_contractRequired && !reservationData.contractSigned &&
                                <a className='text-xs p-1 rounded-lg text-white bg-red-500'>Pendente assinar contrato</a>
                            }
                        </div>
                    </div>
                </TitlePage>
                <Body hasFooter={true}>
                    <div className='flex flex-col w-full gap-10'>
                        <div className='flex flex-col items-start justify-start gap-4 sm:gap-2'>
                            <div className='mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                <h1 className='text-primaryDefaultLight text-base'>Informações da reserva</h1>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>ID da reserva:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.id}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>ID do pagamento:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{[1, 2].includes(reservationData?.status) && reservationData.payments_status == 1 ? reservationData.payments_id : '-'}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>ID do gateway de pagamento:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.externalPayment_id ? reservationData?.externalPayment_id : '-'}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Responsável:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.schedule_users_firstname}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Criado por:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{`${reservationData.users_created_firstname} ${reservationData.users_created_lastname}`}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Categoria:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.categories_name}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Espaço:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.services_name}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Tipo:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.isRecurrent ? 'Recorrente' : 'Avulsa'}</p>
                            </div>
                            {
                                reservationData?.isRecurrent &&
                                <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                    <div className='w-56'>
                                        <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>ID da recorrência:</p>
                                    </div>
                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.recurrenceIdentifier}</p>
                                </div>
                            }
                            <div className='flex flex-row flex-wrap items-start justify-start w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Data da reserva:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.startDate}</p>
                            </div>
                            {
                                reservationData.associateSchedules?.length > 0 && !reservationData?.isRecurrent &&
                                <div className='flex flex-row flex-wrap items-start justify-start w-full sm:gap-2'>
                                    <div className='w-56'>
                                        <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Reservas relacionadas:</p>
                                    </div>
                                    <div className='flex flex-row flex-wrap items-center gap-3'>
                                        {
                                            reservationData.associateSchedules.map((schedule, index) => {
                                                return <p className='flex gap-3 text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{schedule.startDate} {index+1 < reservationData.associateSchedules.length && '&'} </p>
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                reservationData?.isRecurrent &&
                                <div className='flex flex-row flex-wrap items-start justify-start w-full sm:gap-2'>
                                    <div className='w-56'>
                                        <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Reservas relacionadas:</p>
                                    </div>
                                    <p 
                                        className='cursor-pointer underline hover:brightness-50 text-sm dark:text-titleGrayTextDark text-titleBlackTextLight'
                                        onClick={() => {
                                            setShowAssociatedRecurrencesModal(true); 
                                            setShowUniversalModal(true)
                                        }}
                                    >
                                        Ver relação
                                    </p>
                                </div>
                            }
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Associação:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.associations_name}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Observação:</p>
                                </div>
                                <textarea 
                                    wrap='hard' 
                                    disabled={observationUpdate}
                                    className='border border-gray-100 bg-gray-50 dark:bg-thirdDefaultDark max-h-96 overflow-auto text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'
                                    onChange={(e) => {
                                        setObservation(e.target.value)
                                    }}
                                >
                                    {observation}
                                </textarea>
                                <div className='flex items-center justify-center gap-2'>
                                    {
                                        !observationUpdate ? <>
                                            <Button type={'button'} height={8} approval={true} onClick={() => {
                                                handleUpdateObservation()
                                                setObservationUpdate(!observationUpdate)
                                            }}><FiCheck size={18}/></Button> 
                                            <Button type={'button'} height={8} approval={false} onClick={() => {
                                                setObservation(reservationData.observation)
                                                setObservationUpdate(!observationUpdate)
                                            }}><FiX size={18}/></Button>
                                        </> : <Button type='button' height={8} onClick={() => setObservationUpdate(!observationUpdate)}><FaPencilAlt/></Button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col items-start justify-start gap-4 sm:gap-2'>
                            <div className='mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                <h1 className='text-primaryDefaultLight text-base'>Dados de pagamento</h1>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Data da criação:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.created_at}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Prazo para cancelamento:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.dateToCancel?.length > 0 ? moment(reservationData?.dateToCancel).utcOffset(0).format('DD/MM/YYYY HH:mm') : '-'}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Prazo para pagamento:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_dueDate}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Pagamento:</p>
                                </div>
                                <p className='text-xs'>{reservationData?.status === 2 && reservationData?.payments_status === 2 ? '-' : reservationData.paymentStatus}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Valor total da reserva:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                            {
                                userData[0]?.customersData?.useBalance &&
                                <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                    <div className='w-56'>
                                        <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Saldo utilizado:</p>
                                    </div>
                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_balance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                </div>
                            }
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Desconto concedido:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_discount?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Valor multa:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_fee ? reservationData?.payments_fee.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : currencyPtBr(0)}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Valor reembolsado:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_chargeBack ? reservationData?.payments_chargeBack.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : currencyPtBr(0)}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Valor recebido:</p>
                                </div>
                                {
                                    [0, 3, 2].includes(reservationData?.originalPaymentStatus) ?
                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{currencyPtBr(0)}</p>
                                        :
                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.payments_totalValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>

                                }
                                {
                                    !userData[0]?.customersData?.useBalance && reservationData?.originalPaymentStatus === 1 &&
                                    <Tippy content={'Clique se for necessário ajustar valores como multa, desconto ou valor reembolsado'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <Button shadow={true} module={'payments:update'} userData={userData} onClick={() => { setShowChangeValuesModal(true); setShowUniversalModal(true); setDiscount(undefined), setfee(undefined), setChargeBack(undefined) }} >
                                            <p className='text-xs'>Alterar valores</p>
                                        </Button>
                                    </Tippy>
                                }
                            </div>
                        </div>
                        <div className='flex flex-col items-start justify-start gap-2'>
                            <div className='mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                <h1 className='text-primaryDefaultLight text-base'>Dados do pedido</h1>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Forma de pagamento:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{formatPaymentMethods(reservationData?.payments_paymentMethod) || '-'}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Origem do pagamento:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.payments_origin === 0 ? 'Secretária / Administracão' : "Online"}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Recibo número:</p>
                                </div>
                                {
                                    showReceiptNumber ?
                                        <div className='flex flex-row gap-2 '>
                                            <input className='w-40 max-w-40 text-sm p-2 h-10 border border-zinc-100' type='text' onChange={(e) => setReceiptNumber(e.target.value)} value={receiptNumber}></input>
                                            <div onClick={() => handleNewReceipt()} className='w-16 flex flex-col items-center justify-center shadow-lg rounded-lg cursor-pointer p-2 bg-primaryDefaultLight text-sm text-white'>
                                                <p>Salvar</p>
                                            </div>
                                            <div onClick={() => setShowReceiptNumber(false)} className='w-16 flex flex-col items-center justify-center shadow-lg rounded-lg cursor-pointer p-2 bg-primaryDefaultLight text-sm text-white'>
                                                <p>Cancelar</p>
                                            </div>
                                        </div>
                                        :
                                        receiptNumber?.length > 0 ?
                                            <div className='flex flex-row gap-2'>
                                                <div className='p-2 bg-gray-200 border max-w-40 w-40 h-10 overflow-hidden border-gray-300 text-sm text-titleBlackTextLight dark:bg-thirdDefaultDark dark:text-titleGrayTextDark'>
                                                    <p>{receiptNumber}</p>
                                                </div>
                                                <Tippy content={'Editar número do recibo'}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div onClick={() => setShowReceiptNumber(true)} className='w-16 flex flex-col items-center justify-center cursor-pointer shadow-lg rounded-lg p-2 bg-primaryDefaultLight text-sm text-white'>
                                                        <p>Editar</p>
                                                    </div>
                                                </Tippy>
                                            </div>
                                            :
                                            (userData[0]?.permissions.includes('schedules:update') || userData[0]?.typeAccess_id < 3) &&
                                            <Tippy content={'Informar um número de recibo manual, este número será impresso no recibo do sistema'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}>
                                                <div onClick={() => setShowReceiptNumber(true)} className='w-16 flex flex-col items-center justify-center shadow-lg rounded-lg cursor-pointer p-2 bg-primaryDefaultLight text-sm text-white'>
                                                    <p className='text-xs'>Informar</p>
                                                </div>
                                            </Tippy>
                                }
                            </div>
                            {
                                reservationData?.categories_contractRequired &&
                                <div className='relative flex flex-col sm:flex-row w-full sm:gap-2'>
                                    <div className='w-56'>
                                        <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Contrato assinado:</p>
                                    </div>
                                    <LabelInput>
                                        <Toogle status={reservationData.contractSigned} onClick={(e) => handleUpdateContract(!e)} />
                                    </LabelInput>

                                </div>
                            }
                        </div>
                        <div className='flex flex-row gap-4 flex-wrap'>
                            {
                                <div className="mt-6 flex flex-col sm:flex-row flex-wrap items-start justify-start w-full">
                                    {
                                        reservationData.status !== 2 && (userData[0]?.permissions.includes('schedules:update') || userData[0]?.typeAccess_id < 3) &&
                                        <Tippy content={<p>Cancelar esta reserva</p>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div onClick={() => { 
                                                setShowUniversalModal(true)
                                                setShowCancelModal(true)
                                            }} className="p-2 hover:bg-gray-100 hover:dark:bg-zinc-600 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                <FaRegWindowClose className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Cancelar</a>
                                            </div>
                                        </Tippy>
                                    }
                                    {
                                        reservationData.status === 1 &&
                                        <Tippy content={<p>Impressão do recibo</p>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div onClick={() => {
                                                setShowTermsInReceiptModal(true);
                                                setShowUniversalModal(true);
                                            }}
                                                className="p-2 hover:bg-gray-100 hover:dark:bg-zinc-600 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                <FaList className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Imprimir recibo</a>
                                            </div>
                                        </Tippy>
                                    }
                                    {
                                        (reservationData.payments_status === 0 || reservationData.payments_status === 3) && reservationData.status !== 2 &&
                                        <Tippy content={<p>Informar pagamento da reserva</p>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div onClick={() => handleSubmitNewPayment()} className="p-2 hover:bg-gray-100 hover:dark:bg-zinc-600 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                <FaRegMoneyBillAlt className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Informar pagamento</a>
                                            </div>
                                        </Tippy>
                                    }
                                    {
                                        !loading && reservationData.status !== 2 &&
                                        <Tippy content={<p>Imprimir ficha de reserva</p>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div
                                                onClick={() => localStorage.setItem('receipt', JSON.stringify({
                                                    id: reservationData?.id,
                                                    textComplement: 'da reserva',
                                                    local: reservationData.services_name,
                                                    paymentStatus: reservationData.originalPaymentStatus,
                                                    startDate: reservationData.startDate,
                                                    userId: reservationData.schedule_users_id,
                                                    userName: reservationData.schedule_users_firstname,
                                                    original_user_firstName: reservationData.original_user_firstName,
                                                    ...reservationData,
                                                    associateSchedules: reservationData.associateSchedules.map(anotherHour => anotherHour.startDate),
                                                    associateSchedulesValue: reservationData.associateSchedules.map(anotherHour => anotherHour.serviceValue),
                                                }))}
                                                className="p-2 hover:bg-gray-100 hover:dark:bg-zinc-600 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                <FaRegNewspaper className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                <Link className='flex flex-col items-center justify-center' to="/receiptConfirmation" target="_blank">
                                                    <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Ficha de confirmação</a>
                                                </Link>
                                            </div>
                                        </Tippy>
                                    }
                                </div>
                            }
                            {
                                reservationData.status === 2 &&
                                <div className='flex flex-col p-2 bg-gray-500'>
                                    <a className='text-white text-sm'>Cancelado</a>
                                </div>
                            }
                        </div>
                        <div className='flex flex-col w-full'>
                            <div className='mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                <h1 className='text-primaryDefaultLight text-base'>Logs</h1>
                            </div>
                            <div className='hidden sm:flex flex-col items-center justify-center w-full boxShadow overflow-y-auto rounded-lg'>
                                <TableDefault
                                    cursor={false}
                                    pagination={true}
                                    totalPages={totalPages}
                                    onClick={(e) => {}}
                                    title={tableThead}
                                    data={filteredLogs}
                                    collumns={["created_at", "users_fullname", "action",]}
                                    loading={loadingLog}
                                    filter={(e) => logs(e)}
                                />
                            </div>
                        </div>
                    </div>
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button
                            id='cancelUserCreate'
                            approval={false}
                            shadow={true}
                            onClick={() => setInEdit({ edit: false, data: reservationData })}
                        >Voltar
                        </Button>
                    </div>
                </Footer>
            </Container>
        </>
    )
}
