import { useEffect } from "react";
import { useAuth } from "../../../../contexts/useAuth";
import { ReservationClient } from "./reservation.create.client";
import { ClientCalendar } from "./reservationCard";

export function ReservationClientRoute() {

    const { userData } = useAuth()

    useEffect(() => {

        
    }, [])

    return userData[0].customersData?.schedulesViewType === 0 ?
        <ReservationClient />
        :
        <ClientCalendar />
}