import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

export function Input({ max, children, height, name, width, placeholder, onChange, value, autoFocus, id, type, inactive, disabled, charLimit, onBlur, ...restProps }) {

    const widthPixel = width > 0 ? `w-[${width}px]` : 'w-full md:w-96'
    const widthDefault = width > 0 ? `w-${width}` : 'w-full md:w-96'

    const heightPixel = height > 0 ? `h-[${height}px]` : 'h-10 sm:h-8'
    const heightDefault = height > 0 ? `h-${height}` : 'h-10 sm:h-8'

    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="relative flex items-center">

            <input
                min={0}
                type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
                max={type === "date" ? "9999-12-31" : max ? max : "false"}
                disabled={inactive || disabled ? true : false}
                id={id}
                name={name}
                onBlur={(e)=> onBlur !== undefined && onBlur()}
                value={value !== undefined && value !== false && value !== null ? value : ''}
                autoFocus={autoFocus ? true : false}
                onChange={(e) => e.target.value.length > charLimit ? '' : onChange(e)}
                placeholder={placeholder}
                className={`${width > 96 ? widthPixel : widthDefault} ${height > 96 ? heightPixel : heightDefault} 
                    pl-2 border border-gray-400 border-opacity-75 
                    outline-none
                    text-sm sm:text-sm
                    focus:shadow-borderShadow
                    transition-all duration-200
                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark 
                    ${inactive || disabled? 'bg-gray-300 dark:bg-thirdDefaultDark' : ''}
                    dark:focus:shadow-borderShadow'
                `}
                {...restProps}
            />
            {type === 'password' && (
                <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                    {showPassword ? (
                        <FaEye className="h-4 w-4 text-gray-500" />
                    ) : (
                        <FaEyeSlash className="h-4 w-4 text-gray-500" />
                    )}
                </button>
            )}
        </div>
    )
}