import React, { useState } from "react";
import { Container } from "../../../../components/container/container";
import { TitlePage } from "../../../../components/titlePages/title.page";
import { Title } from "../../../../components/titlePages/title";
import { Body } from "../../../../components/container/Body";
import { LabelInput } from "../../../../components/label/label.input";
import { Input } from "../../../../components/input/input";
import { Button } from "../../../../components/buttons/button.default";
import { useAuth } from "../../../../contexts/useAuth";
import { useNavigate } from "react-router-dom";
import { useThemeContext } from "../../../../contexts/themeContext";
import { validateFields } from "../../../../utils/form.validator";
import moment from "moment";
import tableToExcel from "../../../../utils/excelFunctions/tableToExcel";
import { FaCloudDownloadAlt } from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { useApiContext } from "../../../../contexts/ApiInterceptorContext";

export function FinancialReserveMargin(){

    const { defaultCallsFinancial } = useApiContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, showUniversalModal, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({
        linked: 'services'
    })
    const [data, setData] = useState([])
    const [ allLinkeds, setAllLinkeds ] = useState('')
    const [periodSelecteds, setPeriodSelecteds] = useState([])
    const [generatedReport, setGeneratedReport] = useState(false)

    async function generateReport(){

        const requiredFields = [
            { name: 'startDate', value: filter?.startDate, required: true, type: 'string' },
            { name: 'finishDate', value: filter?.finishDate, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowUniversalModal(false)
            setLoading(false)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        setLoading(true)
        const momentStartDate = moment(filter?.startDate)
        const momentFinishDate = moment(filter?.finishDate)

        const monthsAndYear = []
        while (momentStartDate.isSameOrBefore(momentFinishDate, 'month')) {
            monthsAndYear.push({
                description: `${momentStartDate.locale('pt-br').format('MMMM').replace(/\b\w/, (c) => c.toUpperCase())}/${momentStartDate.format('YYYY')}`,
                month: Number(momentStartDate.format('MM')),
                year: Number(momentStartDate.format('YYYY'))
            })
            momentStartDate.add(1, 'month')
        }
        setPeriodSelecteds(monthsAndYear)

        const getData = await defaultCallsFinancial.getFinancialReportsMarginLinkeds(userData[0]?.token, filter)
        if (getData.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(getData.data)
        }

        let filterLinkeds = []
        getData?.data?.map(item => {
            const find = filterLinkeds?.find(f => f.linked_id == item?.linked_id)
            if (!find){
                filterLinkeds.push({
                    linked: item?.linked,
                    linked_id: item?.linked_id,
                    linked_name: item?.linked_name
                })
            }
        })

        filterLinkeds = filterLinkeds?.map(fil => {
            const filterData = getData?.data?.filter(data => data.linked_id == fil?.linked_id)?.map(item => {
                return {
                    ...item,
                    value: Number(item.finalValue) * Number(item.percentage) / 100
                }
            })        

            return {
                ...fil,
                linkeds: filterData
            }
        })

        setData(filterLinkeds)
        setAllLinkeds(getData?.data?.map(item => {
            return {
                ...item,
                value: Number(item.finalValue) * Number(item.percentage) / 100
            }
        }))
        setGeneratedReport(true)

        setLoading(false)
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Margem de reservas'}/>
            </TitlePage>
            <Body background={false} shadow={false}>
                <div className="w-full h-full relative">
                    <div className="absolute w-full bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex flex-col sm:flex-row justify-between w-full items-center">
                            <div className="flex flex-col sm:flex-row sm:gap-2 gap-4 sm:items-end items-center">
                                <LabelInput text={'Data inicial'} required>
                                    <Input
                                        type={'date'}
                                        width={60}
                                        onChange={(e) => setFilter(prev => ({...prev, startDate: e?.target?.value}))}
                                        value={filter?.startDate ? filter?.startDate : ''}
                                        id={'startDate'}
                                    />
                                </LabelInput>
                                <LabelInput text={'Data final'} required>
                                    <Input
                                        type={'date'}
                                        width={60}
                                        onChange={(e) => setFilter(prev => ({...prev, finishDate: e?.target?.value}))}
                                        value={filter?.finishDate ? filter?.finishDate : ''}
                                        id={'finishDate'}
                                    />
                                </LabelInput>
                                <Button height={8} onClick={() => generateReport()}>Gerar</Button>
                                <div className="flex sm:hidden">
                                    <Button height={8} onClick={() => tableToExcel(`${Number(new Date)}.xls`)}>Baixar em excel</Button>
                                </div>
                            </div>
                            {
                                data?.length > 0 &&
                                <div className="hidden sm:flex items-end h-full pr-2">
                                    <Tippy content={'Exportar em excel'}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top'
                                        delay={100}>
                                        <div>
                                            <FaCloudDownloadAlt className='text-xl cursor-pointer text-primaryDefaultLight' onClick={() => tableToExcel(`${Number(new Date)}.xls`)}/>
                                        </div>
                                    </Tippy>
                                    <a id="link-to-download" href="!#">{}</a>
                                </div>
                            }
                        </div>
                        {
                            loading ? 
                            <div className="w-full h-40 sm:h-96 flex items-center justify-center">
                                <p className="animate-pulse">Gerando relatório...</p>
                            </div>
                            : data?.length > 0 ?
                            <div className={`flex flex-col h-full mt-8 w-full`}>
                                <div className="flex h-full md:max-h-[42vh] lg:max-h-[53vh] xl:max-h-[60vh] 2xl:max-h-[63vh] flex-col pb-0 shadow-xl pr-0 overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 rounded-md">
                                    <table className='border-collapse' id='TableToExport'>
                                        <thead className='m-0 p-0 bg-primaryDefaultLight text-white font-bold relative'>
                                            <tr className='sticky z-20 top-0'>       
                                                <th className='sm:sticky left-0 top-0 bg-primaryDefaultLight' rowSpan={2}>
                                                    <div className='min-w-[8rem] z-5 p-0 border-0 text-sm'>
                                                        Reserva
                                                    </div>
                                                </th>
                                                {
                                                    periodSelecteds?.map(month => {
                                                        return (
                                                            <th className='bg-primaryDefaultLight text-sm' colSpan={2}>
                                                                {month?.description}
                                                            </th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            <tr>                                                       
                                                {
                                                    periodSelecteds?.map((month, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <th className='min-w-[8rem] bg-primaryDefaultLight text-sm text-center'>
                                                                    Receita
                                                                </th>
                                                                <th className='min-w-[8rem] bg-primaryDefaultLight text-sm text-center'>
                                                                    Despesa
                                                                </th>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className='pb-2 m-0'>
                                            {
                                                data?.map((item, index) => {
                                                    return (
                                                        <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`} key={index}>
                                                            <td className={`m-0 p-0 sm:z-10 sm:sticky text-center left-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'} z-3`}>
                                                                <div className='min-w-[8rem] z-5 py-1 border-0 text-sm'>
                                                                    {item?.linked_name}
                                                                </div>
                                                            </td>
                                                            {
                                                                periodSelecteds?.map(period => {
                                                                    const filterData = item?.linkeds?.filter(dat => dat?.month == period?.month && dat.year == period?.year)
                                                                    const sumValueIncome = filterData?.filter(i => i?.incomeOrExpense == 'R').reduce((acc, i) => acc += i.value, 0)
                                                                    const sumValueExpense = filterData?.filter(i => i?.incomeOrExpense == 'D').reduce((acc, i) => acc += i.value, 0)
                                                                    return (
                                                                        <React.Fragment>
                                                                            <td className='min-w-[8rem] text-sm text-center border-l border-l-gray-600'>
                                                                                {sumValueIncome?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                            </td>
                                                                            <td className='min-w-[8rem] text-sm text-center'>
                                                                                {sumValueExpense?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                            </td>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }                                                                                        
                                            <tr className='sticky z-10 bottom-0 text-sm text-white'>
                                                <td className='sm:sticky sm:z-10 left-0 text-center bg-primaryDefaultLight'>
                                                    <div className='min-w-[15rem] font-bold z-5 py-1 border-0 text-sm'>
                                                        Total
                                                    </div>
                                                </td>
                                                {
                                                    periodSelecteds?.map((period, index) => {
                                                        const filterData = allLinkeds?.filter(dat => dat?.month == period?.month && dat.year == period?.year)                                                        
                                                        const totalValueIncome = filterData?.filter(i => i?.incomeOrExpense == 'R').reduce((acc, i) => acc += i.value, 0)
                                                        const totalValueExpense = filterData?.filter(i => i?.incomeOrExpense == 'D').reduce((acc, i) => acc += i.value, 0)
                                                        const margin = totalValueIncome - totalValueExpense
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <td className='text-center bg-primaryDefaultLight' colSpan={2}>
                                                                    <div className={`min-w-[8rem] font-bold z-5 py-1 border-0 text-sm ${margin < 0 ? 'text-red-600' : ''}`}>
                                                                        {margin?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                    </div>
                                                                </td>
                                                            </React.Fragment>
                                                            
                                                        )
                                                    })
                                                }
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : generatedReport &&
                            <p className="w-full flex items-center justify-center text-primaryDefaultLight mt-8">
                                Não há registros
                            </p>
                        }
                    </div>
                </div>
            </Body>
        </Container>
    )
}
