import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "./useAuth";
import { colors } from "../theme/colorsSchema";
import { useGlobalContext } from "./globalContext";

const ThemeContext = createContext()

export function ThemeContextProvider({ children }) {

    const { userData } = useAuth()
    //user
    const [bgPrimaryLight, setBgPrimaryLight] = useState('#FFFF')
    const [bgSecondaryLight, setBgSecondaryLight] = useState('#ebf1f5')
    const [primaryDefaultLight, setPrimaryDefaultLight] = useState('#0056E1')
    const [secondaryDefaultLight, setSecondaryDefaultLight] = useState('#011E42')
    const [hoverLight, setHoverLight] = useState('#ebf1f5')
    const [titleBlackTextLight, setTitleBlackTextLight] = useState('#595959')
    const [titleGrayTextLight, setTitleGrayTextLight] = useState('#FFFF')
    const [inputPlaceholderLight, setInputPlaceholderLight] = useState('#8F8F8F')
    const [primaryLightSuccess, setPrimaryLightSuccess] = useState('#64B694')
    const [primaryDefaultDark, setPrimaryDefaultDark] = useState('#1C2128')
    const [secondaryDefaultDark, setSecondaryDefaultDark] = useState('#22272E')
    const [thirdDefaultDark, setThirdDefaultDark] = useState('#373e47')
    const [hoverDark, setHoverDark] = useState('#2a2f36')
    const [titleBlackTextDark, setTitleBlackTextDark] = useState('#2a2f36')
    const [titleGrayTextDark, setTitleGrayTextDark] = useState('#C4C4C4')
    const [primaryBorderDark, setPrimaryBorderDark] = useState('#3A434E')
    const [secondaryBorderDark, setSecondaryBorderDark] = useState('#444c56')
    const [darkMode, setDarkMode] = useState(false)

    const { showNotificationModal,
        showUniversalModal,
        setShowUniversalModal,
        setShowNotificationModal,
        showNotificationModalSuccess,
        setShowNotificationModalSuccess,
        showNotificationModalText,
        setShowNotificationModalText
    } = useGlobalContext()

    useEffect(() => {
        if (userData) {
            if (localStorage.getItem('darkMode') === 'true') {
                document.documentElement.classList.add('dark')
                setDarkMode(true)
            } else {
                document.documentElement.classList.remove('dark')
            }

            const typeUser = localStorage.getItem('typeUser')
            if (!userData[0]?.customersData?.blockColor) {
                //white colors
                if (!localStorage.getItem('primaryDefaultLight')) {
                    document.documentElement.style.setProperty('--primaryDefaultLight', userData[0]?.customersData?.primaryColor?.length === 0 ? primaryDefaultLight : userData[0]?.customersData?.primaryColor?.length > 0 ? userData[0]?.customersData?.primaryColor : primaryDefaultLight)
                    document.documentElement.style.setProperty('--secondaryDefaultLight', userData[0]?.customersData?.secundaryColor?.length === 0 ? secondaryDefaultLight : userData[0]?.customersData?.secundaryColor?.length > 0 ? userData[0]?.customersData?.secundaryColor : secondaryDefaultLight)
                }
                else {
                    document.documentElement.style.setProperty('--primaryDefaultLight', localStorage.getItem('primaryDefaultLight'))
                    setPrimaryDefaultLight(localStorage.getItem('primaryDefaultLight'))
                    setSecondaryDefaultLight(localStorage.getItem('secondaryDefaultLight'))
                    document.documentElement.style.setProperty('--secondaryDefaultLight', localStorage.getItem('secondaryDefaultLight'))
                }

                //dark colors user    
                !localStorage.getItem('primaryDefaultDark') ? document.documentElement.style.setProperty('--primaryDefaultDark', primaryDefaultDark) :
                    document.documentElement.style.setProperty('--primaryDefaultDark', localStorage.getItem('primaryDefaultDark'))

                !localStorage.getItem('secondaryDefaultDark') ? document.documentElement.style.setProperty('--secondaryDefaultDark', secondaryDefaultDark) :
                    document.documentElement.style.setProperty('--secondaryDefaultDark', localStorage.getItem('secondaryDefaultDark'))

                !localStorage.getItem('thirdDefaultDark') ? document.documentElement.style.setProperty('--thirdDefaultDark', thirdDefaultDark) :
                    document.documentElement.style.setProperty('--thirdDefaultDark', localStorage.getItem('thirdDefaultDark'))



            } else {
                //cores padroes cliente
                document.documentElement.style.setProperty('--primaryDefaultLight', !userData[0]?.customersData?.primaryColor ? primaryDefaultLight : userData[0]?.customersData?.primaryColor)
                document.documentElement.style.setProperty('--secondaryDefaultLight', !userData[0]?.customersData?.secundaryColor ? secondaryDefaultLight : userData[0]?.customersData?.secundaryColor)

                userData[0]?.customersData?.primaryColor && setPrimaryDefaultLight(userData[0]?.customersData?.primaryColor)

                //dark colors clients
                document.documentElement.style.setProperty('--primaryDefaultDark', primaryDefaultDark)
                document.documentElement.style.setProperty('--secondaryDefaultDark', secondaryDefaultDark)
                document.documentElement.style.setProperty('--thirdDefaultDark', thirdDefaultDark)
            }

            if (!localStorage.getItem('bgPrimaryLight')) { document.documentElement.style.setProperty('--bgPrimaryLight', bgPrimaryLight) }
            else {
                document.documentElement.style.setProperty('--bgPrimaryLight', localStorage.getItem('bgPrimaryLight'))
                setBgPrimaryLight(localStorage.getItem('bgPrimaryLight'))
            }
        }

        !localStorage.getItem('bgSecondaryLight') ? document.documentElement.style.setProperty('--bgSecondaryLight', bgSecondaryLight) :
            document.documentElement.style.setProperty('--bgSecondaryLight', localStorage.getItem('bgSecondaryLight'))

        !localStorage.getItem('titleBlackTextLight') ? document.documentElement.style.setProperty('--titleBlackTextLight', titleBlackTextLight) :
            document.documentElement.style.setProperty('--titleBlackTextLight', localStorage.getItem('titleBlackTextLight'))

        !localStorage.getItem('titleGrayTextLight') ? document.documentElement.style.setProperty('--titleGrayTextLight', titleGrayTextLight) :
            document.documentElement.style.setProperty('--titleGrayTextLight', localStorage.getItem('titleGrayTextLight'))

        !localStorage.getItem('inputPlaceholderLight') ? document.documentElement.style.setProperty('--inputPlaceholderLight', inputPlaceholderLight) :
            document.documentElement.style.setProperty('--inputPlaceholderLight', localStorage.getItem('inputPlaceholderLight'))

        !localStorage.getItem('primaryLightSuccess') ? document.documentElement.style.setProperty('--primaryLightSuccess', primaryLightSuccess) :
            document.documentElement.style.setProperty('--primaryLightSuccess', localStorage.getItem('primaryLightSuccess'))

        !localStorage.getItem('hoverLight') ? document.documentElement.style.setProperty('--hoverLight', hoverLight) :
            document.documentElement.style.setProperty('--hoverLight', localStorage.getItem('hoverLight'))
        //darkColors
        !localStorage.getItem('hoverDark') ? document.documentElement.style.setProperty('--hoverDark', hoverDark) :
            document.documentElement.style.setProperty('--hoverDark', localStorage.getItem('hoverDark'))

        !localStorage.getItem('titleBlackTextDark') ? document.documentElement.style.setProperty('--titleBlackTextDark', titleBlackTextDark) :
            document.documentElement.style.setProperty('--titleBlackTextDark', localStorage.getItem('titleBlackTextDark'))

        !localStorage.getItem('titleGrayTextDark') ? document.documentElement.style.setProperty('--titleGrayTextDark', titleGrayTextDark) :
            document.documentElement.style.setProperty('--titleGrayTextDark', localStorage.getItem('titleGrayTextDark'))

        !localStorage.getItem('primaryBorderDark') ? document.documentElement.style.setProperty('--primaryBorderDark', primaryBorderDark) :
            document.documentElement.style.setProperty('--primaryBorderDark', localStorage.getItem('primaryBorderDark'))

        !localStorage.getItem('secondaryBorderDark') ? document.documentElement.style.setProperty('--secondaryBorderDark', secondaryBorderDark) :
            document.documentElement.style.setProperty('--secondaryBorderDark', localStorage.getItem('secondaryBorderDark'))

    }, [userData])

    function changeTheme(darkMode) {
        darkMode ? localStorage.setItem('darkMode', true) : localStorage.setItem('darkMode', '')
        darkMode ? document.documentElement.classList.add('dark') : document.documentElement.classList.remove('dark')
        setDarkMode(darkMode)
    }

    return <ThemeContext.Provider value={{

        bgPrimaryLight, setBgPrimaryLight,
        bgSecondaryLight, setBgSecondaryLight,
        primaryDefaultLight, setPrimaryDefaultLight,
        secondaryDefaultLight, setSecondaryDefaultLight,
        hoverLight, setHoverLight,
        titleBlackTextLight, setTitleBlackTextLight,
        titleGrayTextLight, setTitleGrayTextLight,
        inputPlaceholderLight, setInputPlaceholderLight,
        primaryLightSuccess, setPrimaryLightSuccess,
        primaryDefaultDark, setPrimaryDefaultDark,
        secondaryDefaultDark, setSecondaryDefaultDark,
        thirdDefaultDark, setThirdDefaultDark,
        hoverDark, setHoverDark,
        titleBlackTextDark, setTitleBlackTextDark,
        primaryBorderDark, setPrimaryBorderDark,
        secondaryBorderDark, setSecondaryBorderDark,
        darkMode,
        changeTheme,
        showUniversalModal, setShowUniversalModal,
        showNotificationModal, setShowNotificationModal,
        showNotificationModalText, setShowNotificationModalText,
        showNotificationModalSuccess, setShowNotificationModalSuccess,
    }}>
        {children}
    </ThemeContext.Provider>
}

export function useThemeContext() {

    return useContext(ThemeContext)
}