// hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Body } from '../../../components/container/Body'
// components
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Button } from '../../../components/buttons/button.default'
import { Toogle } from '../../../components/toogle/Toogle'
import Multiselect from '../../../components/selects/mult.select'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
//class
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Section } from '../../../components/container/section'
import { Footer } from '../../../components/footer/Footer'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { FaCircle, FaInfo, FaInfoCircle } from 'react-icons/fa'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function CategoriesCreate() {

    const { defaultCallsAuth, api} = useApiContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [loading, setLoading] = useState(false)
    const [associationsList, setAssociationsList] = useState([])

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [isLocalPayment, setIsLocalPayment] = useState(false)
    const [contractRequired, setContractrequired] = useState(false)
    const [selectedAssociations, setSelectedAssociations] = useState([])
    const [forAssociations, setForAssociations] = useState({ id: 1, value: true, name: 'Sim' })

    const optionList = [
        { id: 0, value: false, name: 'Não' },
        { id: 1, value: true, name: 'Sim' },
    ]

    async function handleSubmit() {

        //validade errors
        const requiredFields = [
            { "name": 'name', "value": name, "required": true, "type": 'string' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        setLoading(true)

        try {
            //create a new categorie
            await api.post(`/api/v1/categories`, {
                "name": name,
                "description": description ? description : undefined,
                "isLocalPayment": isLocalPayment,
                "contractRequired": contractRequired,
                "forAll": forAssociations?.value,
                "associations_id": selectedAssociations?.length > 0 ? selectedAssociations?.map(association => association.id) : []
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Categoria criada com sucesso!")
            navigate('/categories')

        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function getData() {

        setLoading(true)
        const associations = await defaultCallsAuth.getAssociations(userData[0]?.token)
        if (associations.error) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(associations.data)
        }

        setAssociationsList(associations.data)
        setLoading(false)
    }

    useEffect(() => {

        getData()

    }, [])

    return (
        <Container>

            <TitlePage>
                <Title text={'Criar nova categoria'} />
            </TitlePage>
            <Body hasFooter={true} shadow={false} background={false}>
                {
                    loading ?
                        <div className='flex flex-col w-full bg-white items-center justify-center h-96'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <Section>
                                {
                                    loading ?
                                        <div className='flex flex-col items-center justify-center'>
                                            <DefaultLoader />
                                        </div>
                                        :
                                        <div className='flex flex-col items-start justify-start w-full'>
                                            <form onSubmit={e => e.preventDefault()} className='flex flex-col justify-start items-start gap-4'>
                                                <div className='p-4 bg-zinc-50 border border-zinc-100 rounded-lg dark:bg-thirdDefaultDark dark:border-secondaryBorderDark text-sm'>
                                                    <p className='text-titleBlackTextLight dark:text-titleGrayTextLight'>
                                                        As categorias serão utilizadas no momento da reserva para separar os serviços/espaços
                                                    </p>
                                                </div>
                                                <div className='flex flex-row gap-4 flex-wrap'>
                                                    <LabelInput text={'Nome *'}>
                                                        <Input id='name' type={'text'} value={name} onChange={(e) => setName(e.target.value)} charLimit={255}></Input>
                                                    </LabelInput>
                                                    <LabelInput text={'Descrição'}>
                                                        <Input id='description' type={'text'} value={description} onChange={(e) => setDescription(e.target.value)} charLimit={60}></Input>
                                                    </LabelInput>

                                                    <div className='flex flex-row gap-1 items-start justify-start'>
                                                        <LabelInput text={'Todas as associações'}>
                                                            <InputAutoComplete preSelectedValue={'Sim'} data={optionList} onChange={(e) => setForAssociations(e)} selectedLabel={'name'} optionList={['name']} />
                                                        </LabelInput>
                                                        <Tippy content={'Marque não para especificar quais associaçoes poderão ver esta categoria'}
                                                            arrow={true}
                                                            animation='shift-away'
                                                            placement='top'
                                                            delay={100}>
                                                            <div>
                                                                <FaInfoCircle />
                                                            </div>
                                                        </Tippy>
                                                    </div>
                                                    {
                                                        !forAssociations?.value &&
                                                        <LabelInput text={'Associação'}>
                                                            <Multiselect
                                                                items={associationsList}
                                                                mult
                                                                id='multselect-associations'
                                                                value={selectedAssociations}
                                                                onChange={(e) => {
                                                                    setSelectedAssociations(e)
                                                                }}
                                                                selectedLabel={'name'}
                                                            />
                                                        </LabelInput>
                                                    }
                                                </div>

                                                <div className='flex flex-col'>
                                                    <Tippy content={'Caso ativado, o cliente deverá pagar na secretaria/administração (mesmo com gateway de pagamento ativo ele não terá opção de pagar online).'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}>
                                                        <div>
                                                            <FaInfoCircle />
                                                        </div>
                                                    </Tippy>
                                                    <LabelInput text={'Pagamento somente na secretaria'}>
                                                        <Toogle status={isLocalPayment} onClick={(e) => setIsLocalPayment(!isLocalPayment)} />
                                                    </LabelInput>
                                                </div>
                                                <div className='flex flex-col'>
                                                    <Tippy content={'Caso ativado, a reserva terá um campo adicional "Contrato assinado" (visível apenas para administradores) onde poderá ser controlado se o contrato ja foi assinado pelo cliente, caso não utilize, deixe desativado'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}>
                                                        <div>
                                                            <FaInfoCircle />
                                                        </div>
                                                    </Tippy>
                                                    <LabelInput text={'Requer assinatura de contrato?'}>
                                                        <Toogle status={contractRequired} onClick={(e) => setContractrequired(!contractRequired)} />
                                                    </LabelInput>
                                                </div>
                                            </form>
                                        </div>
                                }
                            </Section>
                        </>
                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button shadow={true} approval={false} onClick={() => navigate('/categories')}>Voltar</Button>
                    <Button shadow={true} onClick={() => {
                        handleSubmit()
                    }} >Cadastrar</Button>
                </div>
            </Footer>
        </Container>
    )
}