import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../contexts/useAuth'
//services
import cookImg from '../../../../assets/default_assets/cook.webp'
//contexts
import { useThemeContext } from '../../../../contexts/themeContext'
//components
import { Body } from '../../../../components/container/Body'
import { SwiperContainer } from '../../../../components/swiper/swiper';
import { Container } from '../../../../components/container/container'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { Quillmodules } from '../../../../components/richText/quill.modules'
import { FaCocktail } from 'react-icons/fa'
import { ScreenModal } from '../../../../components/modals/notification/screenModal'
import { TitlePage } from '../../../../components/titlePages/title.page'
import { Title } from '../../../../components/titlePages/title'
import { useScreenSizeContext } from '../../../../contexts/screenSizeContext'
import { GastronomyShow } from './gastronomyShow'
import { useApiContext } from '../../../../contexts/ApiInterceptorContext'

export function GastronomyClient() {

    const {defaultCallsSchedules, api} = useApiContext()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { screenX } = useScreenSizeContext()
    const { setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [gastronomysAppendPictures, setGastronomysAppendPictures] = useState([])
    const [screenModalText, setScreenModalText] = useState('')
    const [selectedGastronomy, setSelectedGastronomy] = useState('')

    async function formatAPIPhoto(apiData, pictures, setFunction) {

        if (apiData.gastronomyPictures?.length > 0) {

            let masterPicture = apiData[pictures].filter(pictureMaster => pictureMaster.isMaster)
            let photosAppendArray = []
            let photosArray = []

            //Pega a foto de capa se foi definido pelo usuario
            if (masterPicture.length > 0) {
                const file = await api.get(`api/v1/gastronomyPictures/gastronomy_id/${apiData.id}/picture/${masterPicture[0].picture}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                const fileObj = URL.createObjectURL(file.data)
                photosArray.push(fileObj)
                photosAppendArray.push({
                    photo: fileObj
                })
                //caso nao tenha foto de capa, pega a primeira foto
            } else {
                await Promise.all(apiData[pictures].map(async (picture, index) => {
                    if (index === 0) {
                        const file = await api.get(`api/v1/gastronomyPictures/gastronomy_id/${apiData.id}/picture/${picture.picture}`, {
                            responseType: 'blob',
                            responseEncoding: 'base64',
                            headers: {
                                Authorization: `Bearer ${userData[0].token}`
                            }
                        })
                        const fileObj = URL.createObjectURL(file.data)
                        photosArray.push(fileObj)
                        photosAppendArray.push({
                            photo: fileObj
                        })
                    }
                }))
            }

            //se tem foto publicada traz com base nas regras acima
            if (photosAppendArray?.length > 0) {
                setFunction((prev) => ([...prev, { apiData, photosAppendArray }]))
                return true
            }
            //caso o usuario nao defina foto, irá utilizar a foto padrao da gastronomia
        } else {
            let photosAppendArray = [{ photo: cookImg }]
            setFunction((prev) => ([...prev, { apiData, photosAppendArray }]))
            return true
        }
    }

    async function getData() {
        
        const gastronomy = await defaultCallsSchedules.getGastronomyByStatus(userData[0]?.token, true)
        if (gastronomy.data.length > 0) {
            Promise.all(gastronomy.data.map(async gastronomy => {
                let thisGastronomy = await defaultCallsSchedules.getGastronomyById(userData[0]?.token, gastronomy.id)
                await formatAPIPhoto(thisGastronomy.data, 'gastronomyPictures', setGastronomysAppendPictures)
            }))
        }

        if (gastronomy.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(gastronomy.data)
            return
        }
    }

    useEffect(() => {
        getData()
    }, [])

    async function handleSelectThisCard(card, toSelectActivitie) {
        gastronomysAppendPictures.map(gastronomy => {
            if (gastronomy.apiData.id !== card) {
                let otherActivitie = document.getElementById(`gastronomy${gastronomy.apiData.id}`)
                otherActivitie.style.boxShadow = ''
            }
        })
        setSelectedGastronomy(toSelectActivitie)
        let thisCard = document.getElementById(card)
        thisCard.style.boxShadow = '0 0 10px 2px var(--primaryDefaultLight)'

        let thisPhoto = document.getElementById(card)
        thisPhoto.scrollIntoView({ behavior: 'smooth' })

    }

    return (
        <>
            {
                selectedGastronomy?.apiData?.id > 0 ?
                    <GastronomyShow gastronomy={selectedGastronomy.apiData} back={(e) => setSelectedGastronomy(e)} />
                    :
                    <>
                        <ScreenModal title={`Cardápio - ${screenModalText?.title}`}>
                            <div className='flex flex-col max-w-[1000px] w-auto xl:w-[1000px]'>
                                <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96 mb-12' readOnly={true} theme={"bubble"} value={screenModalText.menu} />
                            </div>
                        </ScreenModal>
                        <Container>
                            <TitlePage>
                                <Title></Title>
                            </TitlePage>
                            <Body>
                                <h1 className='text-2xl font-light text-primaryDefaultLight dark:text-titleGrayTextDark mb-14'>Conheça nossa gastronomia</h1>
                                <div className='flex flex-col'>
                                    <div id='gastronomyArea' className=' flex flex-row gap-2 items-center justify-center w-full overflow-auto scrollbar-none' style={{
                                        maxWidth: screenX > 640 ? screenX - 300 : 'auto'
                                    }}>
                                        <div className='flex flex-row gap-8 items-center justify-center w-full p-6'>
                                            <div id='moveRow' style={{
                                            }} className='flex flex-row flex-wrap gap-8 items-center justify-center transition-all duration-300 ease-in-out'>
                                                {
                                                    gastronomysAppendPictures.length > 3 ?
                                                        gastronomysAppendPictures.map((gastronomy, index) => {
                                                            return <div
                                                                style={{
                                                                    backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), rgba(27, 27, 27, 0.9)), url(${gastronomy.photosAppendArray[0].photo})`,
                                                                    transform: selectedGastronomy?.apiData?.id === gastronomy?.apiData?.id ? 'scale(1.06)' : 'scale(1)',
                                                                    filter: selectedGastronomy?.apiData?.id > 0 ? selectedGastronomy?.apiData?.id === gastronomy?.apiData?.id ? 'grayscale(0)' : 'grayscale(0.90)' : 'grayscale(0)',
                                                                }}
                                                                id={`gastronomy${gastronomy.apiData.id}`}
                                                                onClick={() => handleSelectThisCard(`gastronomy${gastronomy.apiData.id}`, gastronomy)}
                                                                className='px-2 bg-cover animate-[wiggleLeft_1s_ease-in-out] cursor-pointer hover:shadow-borderShadow-xl 
                                                    w-[200px] 2xl:w-[200px]  h-48 2xl:h-48 hover:scale-105 transition-all duration-300 ease-in-out boxShadow 
                                                    rounded-xl text-lg font-light items-center justify-center flex flex-col flex-wrap'>
                                                                <div className='w-[95%] flex flex-col items-center justify-center'>
                                                                    <a className='text-xl text-white text-center font-light'>{gastronomy.apiData.title}</a>
                                                                </div>
                                                            </div>
                                                        })
                                                        :
                                                        gastronomysAppendPictures.map((gastronomy, index) => {
                                                            return <div
                                                                style={{
                                                                    backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), rgba(27, 27, 27, 0.9)), url(${gastronomy.photosAppendArray[0].photo})`,
                                                                    transform: selectedGastronomy?.apiData?.id === gastronomy?.apiData?.id ? 'scale(1.06)' : 'scale(1)',
                                                                    filter: selectedGastronomy?.apiData?.id > 0 ? selectedGastronomy?.apiData?.id === gastronomy?.apiData?.id ? 'grayscale(0)' : 'grayscale(0.90)' : 'grayscale(0)',
                                                                }}
                                                                id={`gastronomy${gastronomy.apiData.id}`}
                                                                onClick={() => handleSelectThisCard(`gastronomy${gastronomy.apiData.id}`, gastronomy)}
                                                                className='px-2 bg-cover animate-[wiggleLeft_1s_ease-in-out] cursor-pointer hover:shadow-borderShadow-xl w-[250px] 2xl:w-[300px]  h-56 2xl:h-64 hover:scale-105 transition-all duration-300 ease-in-out boxShadow rounded-xl text-lg font-light items-center justify-center flex flex-col'>
                                                                <a className='text-xl text-white text-center font-light'>{gastronomy.apiData.title}</a>
                                                            </div>
                                                        })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Body>
                        </Container>
                    </>
            }
        </>
    )
}

