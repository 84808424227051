import { HashRouter, Route, Routes } from "react-router-dom";
import { UserTerms } from "../pages/userTerms/userTerms";

export function TermsOfUseRoutes() {

    return (
        <HashRouter>
            <Routes>
                <Route path='/' element={<UserTerms />} />
                <Route path='/*' element={<UserTerms />} />
            </Routes>
        </HashRouter>
    )
}