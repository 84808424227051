import { Container } from "../../../components/container/container";
import { Body } from '../../../components/container/Body';
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { useEffect, useState } from "react";
import { NewTableDefault } from "../../../components/table/new.table.default";
import { useAuth } from "../../../contexts/useAuth";
import { useNavigate } from "react-router-dom";
import { MdMarkEmailUnread } from "react-icons/md";
import Tippy from "@tippyjs/react";
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { FaExternalLinkAlt, FaSearch } from "react-icons/fa";
import { useThemeContext } from "../../../contexts/themeContext";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { SelectPeriod } from "../../../components/period/selectPeriod";
import moment from "moment";
import { Button } from "../../../components/buttons/button.default";
import { InputDinamicAutoComplete } from "../../../components/input/input.dinamic.autocomplete";
import { LabelInput } from "../../../components/label/label.input";
import { FiSlash } from "react-icons/fi";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function FinancialCharges() {
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { defaultCallsFinancial } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [filter, setFilter] = useState({})
    const [filterConstructor, setFilterConstructor] = useState({})
    const [ loadingFilter, setLoadingFilter ] = useState(false)
    const [ usersAndClientsList, setUsersAndClientsList ] = useState([])

    useEffect(() => {
        async function getDataToFilter() {
            const initialFilter = getInitialFilter()
            setFilter(initialFilter)

            const usersAndClientsAPI = await defaultCallsFinancial.getUsersClientsAndSuppliersWithoutPagination(userData[0].token)
            if (usersAndClientsAPI?.error){
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(usersAndClientsAPI.data) 
            }
            setUsersAndClientsList(usersAndClientsAPI.data)

            setLoading(false)
        }
        
        getDataToFilter()
    }, [])

    function getInitialFilter(){
        const defaultFilter = {
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            finishDate: moment().endOf('month').format('YYYY-MM-DD'),
        }
        try {
            const lastFilter = sessionStorage.getItem(`lastFilter:charges`)
            if(lastFilter){
                const filter = JSON.parse(lastFilter)
                if(filter?.startDate && filter?.finishDate){
                    return filter
                }
            }
            return defaultFilter
        } catch (error) {
            return defaultFilter
        }
    }

    function setAndSaveFilter(filterObj){
        setFilter(filterObj)
        sessionStorage.setItem(`lastFilter:charges`, JSON.stringify(filterObj))
    }

    return (
        <>
              <ScreenModal>
                <div className={`px-6 py-14 boxShadow duration-300 flex flex-col`}>
                    <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center'>
                        <FaSearch />
                        <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                    </div>
                    {
                        loadingFilter ?
                        <></>
                        :
                        <>
                            <form className="flex flex-col justify-center items-center">
                                <div className="flex flex-row gap-3 items-center justify-center">
                                    <p className="text-gray-400 text-sm w-24">Contato </p>
                                    <LabelInput>
                                        <InputDinamicAutoComplete
                                            width={72}
                                            height={96}
                                            id={'usersAndClients'}
                                            value={
                                                filterConstructor?.financialClientsAndSuppliers_id ?
                                                    usersAndClientsList?.find(item => item?.linked == 'financialClientsAndSuppliers' && item?.linked_id == filterConstructor?.financialClientsAndSuppliers_id)?.name
                                                : filterConstructor?.client_users_id ?
                                                    usersAndClientsList?.find(item => item?.linked == 'client_users_id' && item?.linked_id == filterConstructor?.client_users_id)?.name
                                                :
                                                ''
                                            }
                                            onChange={(e) => { setFilterConstructor({ ...filterConstructor, financialClientsAndSuppliers_id: e?.id })} }
                                            endpoint={'/api/v1/financial/clientsAndSuppliers'}
                                            module={'financial'}
                                            selectedLabel={'name'}
                                            optionList={['id', 'name']}
                                            filters={{
                                                isClient: true
                                            }}
                                            token={userData[0]?.token}
                                            mapTotalPages={['totalPages']}
                                            objectChange='name'
                                        />
                                    </LabelInput>
                                </div>    
                            </form>
                            <div className='flex w-full flex-col items-end justify-start mt-6'>
                                <Button onClick={() => {
                                    setAndSaveFilter({ ...filter, filterConstructor })
                                    setFilter({ ...filter, ...filterConstructor })
                                    setShowUniversalModal(false)
                                }} shadow={true}>Pesquisar</Button>
                            </div>
                        </>
                    }
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className="flex flex-row justify-start w-full items-center">
                        <Title text="Cobranças" />
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading 
                        ?
                        <div className="flex flex-row w-full justify-center items-center">
                            <DefaultLoader />
                        </div>
                        :
                        <div className="w-full h-full overflow-auto">
                            <div className={`w-full flex flex-row items-center justify-between mb-6`}>
                                <div className={`w-full flex`}>
                                    <SelectPeriod
                                        initialDate={filter?.startDate}
                                        endDate={filter?.finishDate}
                                        onChange={(e) => {
                                            setAndSaveFilter({ ...filter, ...e })
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row sm:justify-end justify-center items-center gap-3 w-full">
                                    <Button onClick={() => setShowUniversalModal(true)}>Filtros</Button>
                                    {
                                        Object.keys(filterConstructor).length > 0 &&
                                        <Tippy
                                            key={`clearFilter`}
                                            content={<span>Limpar filtro</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='top'
                                            delay={100}>
                                            <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                                <FiSlash className='cursor-pointer' onClick={async () => {
                                                    setLoadingFilter(true)
                                                    setFilterConstructor({})
                                                    setAndSaveFilter({
                                                        startDate: filter.startDate,
                                                        finishDate: filter.finishDate,
                                                    })
                                                    await new Promise(resolve => setTimeout(resolve, 500))
                                                    setLoadingFilter(false)
                                                }} />
                                            </div>
                                        </Tippy>
                                    }
                                </div>
                            </div>
                            <NewTableDefault
                                key={'table-charges'}
                                endpoint="/api/v1/financial/cashFlow/read/charges"
                                token={userData[0]?.token}
                                module="financial"  
                                columns={[
                                    {
                                        description: 'ID',
                                        field: 'id',
                                        type: 'number',
                                    },
                                    {
                                        description: 'Data/vencimento',
                                        field: 'dueDate',
                                        type: 'date',
                                    },
                                    {
                                        description: 'Data/cobrança',
                                        field: 'chargeDate',
                                        type: 'date',
                                    },
                                    {
                                        description: 'Contato',
                                        field: 'clientOrSupplier_name',
                                        type: 'string',
                                    },
                                    {
                                        description: 'Enviado p/',
                                        field: 'chargeEmail',
                                        type: 'string',
                                    },
                                    {
                                        description: 'Valor total',
                                        field: 'totalValue',
                                        type: 'money',
                                    },
                                    {
                                        description: 'Ações',
                                        newFieldValue: (row) => {
                                            return <div className="flex flex-row items-center justify-center gap-2">
                                                <Tippy content={'Reenviar e-mail de notificação'}
                                                    arrow={true}
                                                    animation='perspective'
                                                    placement='top'
                                                    delay={100}>
                                                    <div className='cursor-pointer text-zinc-500'>
                                                        <MdMarkEmailUnread 
                                                            size={18}
                                                            onClick={() => alert("A FAZER")} 
                                                        />
                                                    </div>
                                                </Tippy>
                                                <Tippy content={'Visualizar venda'}
                                                    arrow={true}
                                                    animation='perspective'
                                                    placement='top'
                                                    delay={100}>
                                                    <div className='cursor-pointer text-blue-500'>
                                                        <FaExternalLinkAlt 
                                                            size={14}
                                                            onClick={() => navigate(`/financial/bills/edit/${row?.bills_id}`)} 
                                                        />
                                                    </div>
                                                </Tippy>
                                            </div>
                                        }
                                    },
                                ]}
                                mapApiData={['data']}
                                filters={filter}
                                usePagination={true}
                                mapQuantityOfRegisters={['quantity']}
                                mapTotalPages={['totalPages']}
                            />
                        </div>
                    }
                </Body>
            </Container>
        </>
    )
}
