import {
    FaFeather,
    FaCalendarCheck,
    FaRunning,
    FaMoneyBill,
    FaCheck,
    FaClock,
    FaHome,
    FaHandsHelping,
    FaBaseballBall
} from 'react-icons/fa'

export const clientMenuItems = [
    {
        "icon": <FaHome />,
        "name": "Home",
        "type": "normal",
        "path": "/",
        "permissions": [1,2,3]
    },
    {
        "icon": <FaCalendarCheck />,
        "module": 1,
        "name": "Reservas",
        "type": "normal",
        "path": "/reservationIndex",
        "permissions": [1,2,3]
    },    
    {
        "icon": <FaRunning />,
        "module": 2,
        "name": "Inscrições",
        "type": "normal",
        "path": "/activitiesActionsCreate",
        "permissions": [1,2,3,4]
    },
    {
        "icon": <FaHandsHelping />,
        "name": "Convênios",
        "type": "normal",
        "path": "/agreements",
        "permissions": [1,2,3,4]
    },
    {
        "icon": <FaBaseballBall />,
        "name": "Eventos",
        "type": "normal",
        "path": "/events",
        "permissions": [1,2,3,4]
    },    
    {
        "icon": <FaClock />,
        "module": 1,
        "name": "Minhas reservas",
        "type": "normal",
        "path": "/mySchedules",
        "permissions": [1,2,3]
    },
    {
        "icon": <FaCheck />,
        "module": 2,
        "name": "Minhas inscrições",
        "type": "normal",
        "path": "/mySubscriptions",
        "permissions": [1,2,3]
    },
    {
        "icon": <FaMoneyBill />,
        "name": "Meu saldo",
        "type": "normal",
        "path": "/myBalance",
        "permissions": [1,2,3]
    },
    {
        "icon": <FaFeather />,
        "module": 1,
        "name": "Sorteios",
        "type": "normal",
        "path": "/mySchedulesDraw",
        "permissions": [1,2,3,4]
    },
]