import React, { useEffect, useState } from 'react'
//components
import { Container } from '../../../../components/container/container'
import { TitlePage } from '../../../../components/titlePages/title.page'
import { Title } from '../../../../components/titlePages/title'
import { Body } from '../../../../components/container/Body'
import { Button } from '../../../../components/buttons/button.default'
import { LabelInput } from '../../../../components/label/label.input'
import { InputAutoComplete } from '../../../../components/input/input.autocomplete'
import { UsersToFind } from '../../../../components/table/usersToFind.table'
import { ScreenModal } from '../../../../components/modals/notification/screenModal'
//contexts
import { useThemeContext } from '../../../../contexts/themeContext'
import { useAuth } from '../../../../contexts/useAuth'
import { DefaultLoader } from '../../../../components/loaders/defaultLoader'
import { InputCheckBox } from '../../../../components/input/input.checkBox'
import { Footer } from '../../../../components/footer/Footer'
import { useNavigate } from 'react-router-dom'
//utils
import { paymentMethods } from '../../../../utils/payment/paymentMethods'
import { Input } from '../../../../components/input/input'
import { responseError } from '../../../../utils/responsesFunctions/error.response'
import moment from 'moment'
import { useApiContext } from '../../../../contexts/ApiInterceptorContext'

export function ActivitiesActionsCreate() {

    const { defaultCallsSchedules, api } = useApiContext()
    const { setShowUniversalModal, setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const [loadingPlan, setLoadingPlan] = useState(false)

    const [activitiesList, setActivitiesList] = useState([])
    const [activitiesPlansList, setActivitiesPlansList] = useState([])
    const [activitiesPlansLinked, setActivitiesPlansLinked] = useState([])

    const [selectedUserToActivitie, setSelectedUserToActivitie] = useState('')
    const [selectedActivitie, setSelectedAcitvitie] = useState('')
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [selectedPlan, setSelectedPlan] = useState('')
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState({
        id: 0, description: 'Pendente', valid: false
    })
    const [selectedNumberOfInstallMents, setSelectedNumberOfInstallMents] = useState('')
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')

    const [statusPaymentOptions, setStatusPaymentOptions] = useState([
        {
            id: 0, description: 'Pendente'
        },
        {
            id: 1, description: 'Pago'
        },
    ])

    async function changePlan() {

        setLoadingPlan(true)
        setSelectedNumberOfInstallMents({ id: 1 })
        await new Promise(resolve => setTimeout(resolve, 40))
        setLoadingPlan(false)
    }
    useEffect(() => {

        changePlan()

    }, [selectedPlan])

    async function getData() {

        setLoading(true)
        const activitiesData = await defaultCallsSchedules.getActivities(userData[0].token)
        const activitiesPlansData = await defaultCallsSchedules.getActivitiesPlans(userData[0].token)

        if (activitiesData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(activitiesData.data)
            return
        }

        if (activitiesPlansData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(activitiesPlansData.data)
            return
        }
        setActivitiesList(activitiesData.data.filter(activitie => {
            if(activitie.active && activitie?.openForSubscriptions && !activitie.isSchool){
                return activitie
            }
        }))
        setActivitiesPlansList(activitiesPlansData.data)
    }

    useEffect(() => {
        getData()
        setLoading(false)
    }, [])

    async function handleActivitiePlan(activitie) {

        setLoading(true)
        const activitieDetails = await defaultCallsSchedules.getActivitiesById(userData[0].token, activitie.id)

        let plans = []

        activitieDetails.data[0].activitiesPlans.map(activitie => {
            activitie?.valuesPerAssociations.filter((plan) => {
                if (plan.associations_id === selectedUserToActivitie?.associations_id) {
                    plans.push(plan)
                }
            })
        })

        const plansData = await Promise.all(plans.map(async plan => {
            const planDetails = await defaultCallsSchedules.getActivitiesPlansById(userData[0].token, plan?.activitiesPlans_id)
            plan.details = planDetails.data[0]
            plan.planName = planDetails.data[0]?.planName
        }))

        setActivitiesPlansLinked(plans)
        setSelectedAcitvitie(activitieDetails.data[0])
        setLoading(false)
    }

    function handleScheduleUser(e) {

        // setSelectedCategorie(``)
        // setSelectedService(``)
        // setSelectedDay(``)
        // setSelectedHour(``)

        //chama api para trazer o saldo
        setSelectedUserToActivitie({
            ...e,
            balance: 10
        })

        setShowUniversalModal(false)
    }

    async function handleSubmit() {

        let apiData = {
            startDate: startDate,
            activities_id: selectedActivitie?.id,
            activities_plans_id: selectedPlan?.activitiesPlans_id,
            activitiesSubscriptions_users_id: selectedUserToActivitie?.id,
            numberOfInstallments: selectedNumberOfInstallMents.id,
            paymentMethod: selectedPaymentMethod?.value
        }
        setLoading(true)
        try {
            await api.post(`/api/v1/activitiesSubscriptions`, apiData,
                {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })

            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Usuário inscrito com sucesso!')
            setLoading(false)
            navigate('/activitiesActions')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>

            <ScreenModal>
                <UsersToFind handleSelect={(e) => handleScheduleUser(e)} />
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Nova inscrição'} />
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='w-full h-96 flex flex-col items-center justify-center'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='flex flex-col justify-start items-start gap-6 w-full'>
                                <div className='flex flex-row gap-4 w-full items-start justify-start'>
                                    <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Responsável:</a>
                                    {
                                        selectedUserToActivitie !== '' ?
                                            <div className='flex flex-row gap-2'>
                                                <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{selectedUserToActivitie.firstName}</a>
                                                <a className='text-sm underline hover:brightness-125 cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark' onClick={() => setShowUniversalModal(true)}>Selecionar</a>

                                            </div>
                                            :
                                            <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm underline hover:brightness-125 cursor-pointer' onClick={() => setShowUniversalModal(true)}>Selecionar</a>
                                    }
                                </div>
                                {
                                    selectedUserToActivitie !== '' &&
                                    <div className='flex flex-col flex-wrap gap-4 items-start justify-start w-full mb-20'>
                                        <LabelInput text={'Data de inicio'}>
                                            <Input type={'date'}  value={startDate}  onChange={(e) => setStartDate(e.target.value)}/>
                                        </LabelInput>
                                        <LabelInput text={'Atividade'}>
                                            <InputAutoComplete preSelectedValue={selectedActivitie?.activitiesName} data={activitiesList} onChange={(e) => handleActivitiePlan(e)} optionList={['id', 'activitiesName']} selectedLabel={'activitiesName'}></InputAutoComplete>
                                        </LabelInput>
                                        <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark mt-2'>Selecione o plano</a>
                                        <span className='w-full border-b border-primaryDefaultLight'></span>
                                        {
                                            activitiesPlansLinked.map(plan => {
                                                return <div className='flex flex-row gap-4'>
                                                    <InputCheckBox value={selectedPlan.id === plan.id ? true : false} onClick={() => setSelectedPlan(plan)} />
                                                    <div className='flex flex-row gap-2'>
                                                        <a className='text-sm text-inputPlaceholderLight'>{plan?.planName}</a>
                                                        <a className='text-sm text-inputPlaceholderLight'>{plan.details?.numberOfInstallments}X de</a>
                                                        <a className='text-sm text-inputPlaceholderLight'>R$ {(plan.value / plan.details?.numberOfInstallments).toFixed(2).toString().replace('.', ',')}</a>
                                                        <a className='text-sm text-inputPlaceholderLight'>{`(Mensal) - `}</a>
                                                        <a className='text-sm text-inputPlaceholderLight'>{plan.value?.toFixed(2).toString().replace('.', ',')}</a>

                                                    </div>

                                                </div>
                                            })
                                        }
                                        {
                                            selectedPlan?.id &&
                                            <>
                                                <LabelInput text={'Forma de pagamento'}>
                                                    <InputAutoComplete
                                                        preSelectedValue={selectedPaymentMethod?.description}
                                                        data={paymentMethods}
                                                        selectedLabel={'description'}
                                                        optionList={['description']}
                                                        onChange={(e) => { setSelectedPaymentMethod(e) }}
                                                    />
                                                </LabelInput>
                                                {
                                                    selectedPaymentMethod &&
                                                    <>
                                                        {
                                                            selectedPaymentMethod?.value === 'D' || selectedPaymentMethod?.value === 'CD'
                                                                || selectedPaymentMethod?.value === 'P' ?
                                                                <LabelInput text={'Valor a pagar'}>
                                                                    <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{`1x de ${(selectedPlan.value / 1).toFixed(2).toString().replace('.', ',')}`}</a>
                                                                </LabelInput>
                                                                :
                                                                loadingPlan ?
                                                                    <LabelInput text={'Parcelado em'}>
                                                                        <Input disabled={false} />
                                                                    </LabelInput>
                                                                    :
                                                                    <LabelInput text={'Parcelado em'}>
                                                                        <InputAutoComplete
                                                                            preSelectedValue={`1x de ${(selectedPlan.value / 1).toFixed(2).toString().replace('.', ',')}`}
                                                                            data={Array(selectedPlan?.details?.numberOfInstallments).fill({ id: 0, description: 'teste' }).map((plan, index) => { return { id: index + 1, description: `${index + 1}x de ${(selectedPlan.value / (index + 1)).toFixed(2).toString().replace('.', ',')}` } })}
                                                                            selectedLabel={'description'}
                                                                            optionList={['description']}
                                                                            onChange={(e) => { setSelectedNumberOfInstallMents(e) }}
                                                                        />
                                                                    </LabelInput>

                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate('/')}>Cancelar</Button>
                        <Button shadow={true} approval={true} onClick={() => {
                            handleSubmit()
                        }}>Salvar</Button>
                    </div>
                </Footer>
            </Container>
        </>

    )
}