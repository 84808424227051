import React, { useEffect, useState } from "react";
import { FaArrowDown, FaArrowUp, FaList, FaPlus, FaReply } from "react-icons/fa";
import { Button } from "../../../components/buttons/button.default";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { InputCheckBox } from "../../../components/input/input.checkBox";
import { LabelInput } from "../../../components/label/label.input";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { TableDefault } from "../../../components/table/table.default";
import { UsersToFind } from "../../../components/table/usersToFind.table";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import CurrencyInput from "../../../components/input/inputCurrencyPtBr";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { TableMobile } from "../../../components/table/table.mobile";
import { generateCsv } from "../../../utils/excelFunctions/generateCsv";
import { copyToClipboard } from "../../../utils/copyClipboard";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function UsersBalance() {

    
    //contexts
    const { defaultCallsSchedules, api } = useApiContext()
    const { userData } = useAuth()
    const { setShowUniversalModal, setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    //controll states
    const [loading, setLoading] = useState(false)
    const [showBalanceCreate, setShowBalanceCreate] = useState(false)
    //selected variables
    const [selectedUser, setSelectedUser] = useState('')
    const [newBalance, setNewBalance] = useState('')
    //data
    const [balanceList, setBalanceList] = useState([])
    const tableThead = [
        {
            "name": 'Descriçao',
            "original_name": 'descriptionToShow'
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        },
        {
            "name": 'Tipo',
            "original_name": 'type'
        },
        {
            "name": 'Valor',
            "original_name": "value"
        },
    ]

    const handleSelectUser = (e) => {

        setSelectedUser(e)
        setShowUniversalModal(false)
        getData(e)
    }

    function handleCsv() {
        const array = []
        balanceList.map(balance => {
            console.log('aquiiii',balance)
            array.push({
                'Descricao': balance.description,
                'Tipo': balance.entryType === 'D' ? 'Débito' : 'Crédito',
                'Valor': balance?.original_value,
                'Criado em': balance?.created_at
            })
        })
        generateCsv(array);
    }

    async function getData(user) {
        setLoading(true)

        const userBalance = await defaultCallsSchedules.getMyBalanceData(userData[0]?.token, user?.id)
        if (userBalance.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(userBalance.data)
        }

        const userBalanceHistory = await defaultCallsSchedules.getBalanceHistory(userData[0]?.token, user?.id)
        if (userBalanceHistory.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(userBalanceHistory.data)
        }

        if (userBalanceHistory?.data?.length > 0) {
            userBalanceHistory?.data?.map(balance => {
                balance.type = balance?.entryType === 'C' ? 'Crédito' : 'Débito'
            })
        }

        let formattedBalanceList = userBalanceHistory?.data.map(balance => {
            return {
                ...balance, 
                descriptionToShow: <Tippy content={<p>{balance.description  + " - Clique para copiar"}</p>}
                    arrow={true}
                    animation='shift-away'
                    placement='top'
                    delay={100}>
                    <div onClick={()=> copyToClipboard(balance.description )}>
                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{balance?.description?.slice(0, 30)?.concat('...')}</a>
                    </div>
                </Tippy>
            }
        })
        setBalanceList(formattedBalanceList)
        setSelectedUser(prev => ({ ...prev, balance: userBalance?.data[0]?.balance }))
        setShowBalanceCreate(false)
        setLoading(false)
    }

    async function handleSubmit() {
        if (!newBalance?.value) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Valor é obrigatório')
        }
        if (!newBalance?.description) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Motivo é obrigatório')
        }

        let newBalanceFormatted = {
            entryType: newBalance?.entryType ? 'C' : 'D',
            value: newBalance?.value,
            description: newBalance?.description?.length > 0 ? newBalance?.description : undefined,
            users_id: selectedUser?.id
        }

        try {
            await api.post(`/api/v1/balance`, newBalanceFormatted,
                {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            getData(selectedUser)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function handleNewBalance() {
        setNewBalance(prev => ({ ...prev, description: '', value: '', entryType: '' }))
        setShowBalanceCreate(true)
    }

    return (
        <>
            <ScreenModal>
                <UsersToFind handleSelect={handleSelectUser} />
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Consulta e lançamento de saldos'} />
                </TitlePage>
                <Body>
                    <div className="flex flex-col gap-4 w-full">
                        <div className='p-6 bg-zinc-50 dark:bg-thirdDefaultDark border border-zinc-100 dark:border-secondaryBorderDark flex flex-col items-start justify-start w-full'>
                            <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>Selecione o usuário abaixo para consultar o saldo e efetuar novos lançamentos</p>
                            {/* <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark underline cursor-pointer hover:text-primaryDefaultLight' onClick={() => navigate('/categoriesCreate')}>Criar categoria</p> */}
                        </div>

                        <div className="flex flex-col sm:flex-row gap-2">
                            <a className="text-sm font-bold w-16">Usuário:</a>
                            <a onClick={() => setShowUniversalModal(true)} className="cursor-pointer underline text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{
                                selectedUser?.firstName ? selectedUser?.firstName : 'Selecionar'
                            }</a>
                        </div>
                        {

                            loading ?
                                <div className="flex flex-col h-96 items-center justify-center">
                                    <DefaultLoader />
                                </div>
                                :
                                selectedUser?.firstName &&
                                <>
                                    <div className="flex flex-col sm:flex-row gap-2">
                                        <a className="text-sm font-bold w-16">Saldo:</a>
                                        <a className={`${selectedUser?.balance > 0 ? 'text-green-500' : 'text-titleBlackTextLight dark:text-titleGrayTextDark'} text-sm`}>{
                                            (selectedUser?.balance !== undefined && selectedUser?.balance !== '') ? selectedUser?.balance?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''
                                        }</a>
                                    </div>
                                    {
                                        !showBalanceCreate &&
                                        <div className="mt-6 flex flex-row items-start justify-start w-full">
                                            {
                                                !showBalanceCreate && (userData[0]?.permissions?.includes('balance:create') || userData[0]?.permissions?.includes('balance:update') || userData[0]?.typeAccess_id < 3) &&
                                                <Tippy content={<p>Novo lançamento</p>}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div onClick={() => handleNewBalance()} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                        <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Lançar</a>
                                                    </div>
                                                </Tippy>
                                            }
                                            <Tippy content={<p>Relatório detalhado em excel</p>}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}>
                                                <div onClick={() => handleCsv()} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                    <FaList className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Relatório</a>
                                                </div>
                                            </Tippy>
                                        </div>
                                    }
                                    {
                                        showBalanceCreate ?
                                            <div className="flex flex-col items-start justify-start gap-4 w-full">
                                                <div className='my-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                                    <h1 className='text-primaryDefaultLight text-base'>Novo saldo para {selectedUser?.firstName}</h1>
                                                </div>
                                                <div className="flex flex-row gap-4 items-center justify-center">
                                                    <LabelInput text={'Débito'}>
                                                        <div className="flex flex-row gap-1 items-center justify-center">
                                                            <FaArrowDown className="text-red-700" /> <InputCheckBox value={newBalance?.entryType ? false : true} onClick={() => setNewBalance(prev => ({ ...prev, entryType: false }))}></InputCheckBox>
                                                        </div>
                                                    </LabelInput>
                                                    <LabelInput text={'Crédito'}>
                                                        <div className="flex flex-row gap-1 items-center justify-center">
                                                            <FaArrowUp className="text-green-700" /> <InputCheckBox value={newBalance?.entryType ? true : false} onClick={() => setNewBalance(prev => ({ ...prev, entryType: true }))}></InputCheckBox>
                                                        </div>

                                                    </LabelInput>
                                                </div>
                                                <LabelInput text={'Valor'}>
                                                    <CurrencyInput placeHolder='R$ 0,00' onChange={(e) => {
                                                        setNewBalance(prev => ({ ...prev, value: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))
                                                    }}>
                                                    </CurrencyInput>
                                                </LabelInput>
                                                <LabelInput text={'Motivo *'}>
                                                    <textarea 
                                                        wrap="hard" 
                                                        value={newBalance?.description} 
                                                        onChange={(e) => setNewBalance(prev => ({ ...prev, description: e.target.value }))} 
                                                        maxLength={255}
                                                        className='p-2 border border-gray-400 border-opacity-75 h-64 w-96 text-sm
                                                                   outline-none transition-all duration-200 focus:shadow-borderShadow
                                                                   dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-'
                                                    >
                                                    </textarea>
                                                    <a className="text-xt text-inputPlaceholderLight">{newBalance?.description?.length} de 255</a>
                                                </LabelInput>
                                                <div className="mt-10 flex flex-row gap-2">
                                                    <Button shadow={true} approval={false} onClick={() => setShowBalanceCreate(false)}>Cancelar</Button>
                                                    <Button shadow={true} onClick={() => handleSubmit()} >Salvar</Button>
                                                </div>
                                            </div>
                                            :
                                            <div className="flex flex-col sm:flex-row gap-2">
                                                <div id='usersBalance' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                                    <TableDefault
                                                        cursor={'default'}
                                                        title={tableThead}
                                                        data={balanceList}
                                                        collumns={["descriptionToShow", "created_at", 'type', 'value']}
                                                    />
                                                </div>
                                                <div id='usersBalanceMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                                    <TableMobile
                                                        title={tableThead}
                                                        data={balanceList}
                                                        collumns={["descriptionToShow", "created_at", 'type', 'value']}
                                                    />
                                                </div>
                                            </div>
                                    }
                                </>
                        }

                    </div>
                </Body>
            </Container>
        </>
    )
}