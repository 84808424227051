//hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import { FaEdit, FaTrash } from "react-icons/fa";

import { Button } from "../../../components/buttons/button.default";
import { Container } from "../../../components/container/container";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { TableDefault } from "../../../components/table/table.default";
import { TableMobile } from '../../../components/table/table.mobile'
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { useAuth } from "../../../contexts/useAuth";
import { Body } from "../../../components/container/Body";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function Gastronomy() {

    const { userData } = useAuth()
    const { defaultCallsSchedules, api } = useApiContext()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [activeButton, setActiveButton] = useState(1)
    const [filteredList, setFilteredList] = useState([])
    const [gastronomyList, setGastronomylist] = useState([
        { 'title': "Lanchonete", "created_at": "2022-11-25" },
        { 'title': "Churrascaria", "created_at": "2022-11-20" },
    ])

    const tableThead = [
        {
            "name": 'Descrição',
            "original_name": 'title'
        },
        {
            "name": 'Criado em',
            "original_name": "created_at"
        },
    ]

    useEffect(() => {

        handleFilterActive(1)

    }, [gastronomyList])

    function handleFilterActive(status) {

        const newList = gastronomyList.filter((item) => {
            if (status === 1 && item?.active) {
                return item
            }
            if (status === 0 && !item?.active) {
                return item
            }
            if (status === undefined) {
                return item
            }
        })

        status !== undefined ? setFilteredList(newList) : setFilteredList(gastronomyList)
        status > 0 ? setActiveButton(1) : setActiveButton(0)
    }

    async function getData() {

        setLoading(true)
        const gastronomyData = await defaultCallsSchedules.getGastronomy(userData[0].token)
        if (gastronomyData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(gastronomyData.data)
        }
        setGastronomylist(gastronomyData.data)
        setLoading(false)
    }

    function handleEdit(e) {

        navigate(`/gastronomyEdit/${e.id}`)
    }

    useEffect(() => {
        getData()
    }, [])

    function handleEdit(e) {

        navigate(`/gastronomyEdit/${e.id}`)
    }

    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full items-start">
                    <Title text={'Gastronomia'} />
                    <Button module={"gastronomy:create"} userData={userData} shadow={true} onClick={() => navigate('/gastronomyCreate')}>+ Novo</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                        <div className="w-full h-96 flex flex-col items-center justify-center">
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div className='flex flex-row w-full justify-start items-center gap-4'>
                                <div className='flex flex-row items-center justify-start'>
                                    <Tippy content={'items Ativos'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${activeButton === 1 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActive(1)}>Ativos</button>
                                    </Tippy>
                                    <Tippy content={'Items inativos'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${activeButton === 0 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActive(0)}>Inativos</button>
                                    </Tippy>
                                </div>
                            </div>
                            <div id='gastronomyTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                <TableDefault
                                    title={tableThead}
                                    collumns={["title", "created_at"]}
                                    data={filteredList}
                                    onClick={(e) => handleEdit(e)} />
                            </div>
                            <div id='gastronomyMobileTableContent' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                {
                                    !loading &&
                                    <TableMobile
                                        onClick={(e) => e.func.execute(e.data)}
                                        title={tableThead}
                                        data={filteredList}
                                        collumns={["title", "created_at"]}
                                        functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                    />
                                }
                            </div>
                        </>
                }
            </Body>
        </Container>
    )
}