//hooks
import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleRight, FaTrash, FaEdit } from "react-icons/fa";
import { MdRefresh } from "react-icons/md";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
//contexts
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
//services
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { Button } from "../../../components/buttons/button.default";

import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { validateFields } from "../../../utils/form.validator";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function FinancialCategories() {

    const { userData } = useAuth()
    const { api_financial, defaultCallsFinancial } = useApiContext()
    const [loading, setLoading] = useState(true)
    const [categoriesFormatedList, setCategoriesFormatedList] = useState([])
    const [groupsList, setGroupsList] = useState([])
    const [categoriesList, setCategoriesList] = useState([])
    const [dreConfigList, setDreConfigList] = useState([])
    const [showModalType, setShowModalType] = useState('')
    const [modalLoading, setModalLoading] = useState(false)
    const [incomeOrExpense, setIncomeOrExpense] = useState('')
    const [selectedCategory, setSelectedCategory] = useState({})
    const [selectedGroup, setSelectedGroup] = useState({})
    const [newCategory, setNewCategory] = useState(false)

    const {
        setShowNotificationModal,
        setShowNotificationModalSuccess,
        setShowNotificationModalText,
        setShowUniversalModal
    } = useThemeContext()

    useEffect(() => {

        setLoading(true)
        async function getData() {
            try {
                const categoriesType = [
                    {
                        description: 'Receitas',
                        incomeOrExpense: 'R'
                    },
                    {
                        description: 'Despesas',
                        incomeOrExpense: 'D'
                    }
                ]
                const categoriesGroups = await defaultCallsFinancial.getFinancialCategoriesGroups(userData[0]?.token)
                const categories = await defaultCallsFinancial.getFinancialCategories(userData[0]?.token)
                const dreConfig = await defaultCallsFinancial.getFinancialDreConfig(userData[0]?.token)

                if (categoriesGroups.error) throw error
                if (categories.error) throw error
                if (dreConfig.error) throw error

                const formatObject = categoriesType.map(type => {
                    return {
                        ...type,
                        showGroups: true,
                        groups: categoriesGroups?.data.map(group => {
                            return type?.incomeOrExpense == group?.incomeOrExpense && {
                                ...group,
                                showCategories: true,
                                categories: categories?.data?.map(category => {
                                    return category?.financialCategoriesGroups_id == group?.id && {
                                        ...category
                                    }
                                }).filter(category => category)
                            }
                        }).filter(group => group)
                    }
                })

                setCategoriesList(categories.data)
                setDreConfigList(dreConfig.data)
                setGroupsList(categoriesGroups.data)
                setCategoriesFormatedList(formatObject)
                setLoading(false)
            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    return setShowNotificationModal(false)
                }
            }
        }

        getData()

    }, [])

    async function handleNewGroup() {

        try {
            const requiredFields = [
                { "name": 'groupDescription', "value": selectedGroup?.description, "required": true, "type": 'string' },
                { "name": 'groupExternalCode', "value": selectedGroup?.externalCode, "required": false, "type": 'string' },
            ]
            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            setModalLoading(true)
            const createNewGroup = await api_financial.post('/api/v1/financial/categoriesGroups', {
                description: selectedGroup?.description,
                externalCode: selectedGroup?.externalCode ? selectedGroup?.externalCode : undefined,
                incomeOrExpense: incomeOrExpense
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            addAlterOrRemoveGroup(createNewGroup?.data?.data[0])

            setShowModalType('')
            setGroupsList([...groupsList])
            setCategoriesFormatedList([...categoriesFormatedList])
            setSelectedGroup({})
            setModalLoading(false)
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            return setShowNotificationModalText("Grupo criado com sucesso!")
        } catch (error) {
            setModalLoading(false)
            setShowUniversalModal(false);
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleAlterGroup() {
        setModalLoading(true)
        try {

            const alterGroup = await api_financial.put(`/api/v1/financial/categoriesGroups/${selectedGroup.id}`, {
                description: selectedGroup?.description ? selectedGroup?.description : undefined,
                externalCode: selectedGroup?.externalCode ? selectedGroup?.externalCode : undefined,
                incomeOrExpense: incomeOrExpense
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            addAlterOrRemoveGroup(alterGroup?.data?.data[0])

            setShowModalType('')
            setGroupsList([...groupsList])
            setCategoriesFormatedList([...categoriesFormatedList])
            setSelectedGroup({})
            setModalLoading(false)
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            return setShowNotificationModalText("Grupo criado com sucesso!")
        } catch (error) {
            setModalLoading(false)
            setShowUniversalModal(false);
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDeleteGroup() {
        setModalLoading(true)
        try {
            await api_financial.delete(`/api/v1/financial/categoriesGroups/${selectedGroup.id}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            addAlterOrRemoveGroup(selectedGroup, 'remove')

            setCategoriesFormatedList([...categoriesFormatedList])
            setGroupsList([...groupsList])
            setSelectedGroup({})

            setModalLoading(false)
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            return setShowNotificationModalText("Grupo removido com sucesso!")
        } catch (error) {
            setModalLoading(false)
            setShowUniversalModal(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function addAlterOrRemoveGroup(group, remove = null) {
        const groupExists = groupsList.find(i => i.id == group.id)
        const type = incomeOrExpense == 'R' ? 0 : 1

        if (!groupExists) {
            groupsList.push(group)
            return categoriesFormatedList[type]?.groups?.push({
                ...group,
                showCategories: true,
                categories: []
            })
        } else {
            categoriesFormatedList[type]?.groups?.forEach((item, index) => {
                if (item.id == group.id && remove == 'remove') {
                    groupsList.splice(groupsList.findIndex(item2 => item2?.id == group?.id), 1)
                    categoriesFormatedList[type].groups.splice(index, 1)
                } else if (item.id == group.id) {
                    categoriesFormatedList[type].groups[index] = {
                        ...group,
                        showCategories: categoriesFormatedList[type]?.groups[index]?.showCategories,
                        categories: categoriesFormatedList[type]?.groups[index]?.categories
                    }
                }
            })
        }
    }

    async function handleNewCategory(incomeOrExpense) {
        
        try {
            const requiredFields = [
                { "name": 'categoryDescription', "value": selectedCategory?.description, "required": true, "type": 'string' }, 
                { "name": 'categoryConfigDRE', "value": selectedCategory?.financialCategoriesConfigDre_id, "required": true, "type": 'number' },
            ]
            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            const newCategory = await api_financial.post('/api/v1/financial/categories', {
                description: selectedCategory?.description,
                financialCategoriesGroups_id: selectedCategory?.financialCategoriesGroups_id,
                financialCategoriesConfigDre_id: selectedCategory?.financialCategoriesConfigDre_id,
                externalCode: selectedCategory?.externalCode ? selectedCategory?.externalCode : undefined
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            setNewCategory(false)
            console.log({...newCategory?.data?.data[0], incomeOrExpense})
            addAlterOrRemoveCategory({...newCategory?.data?.data[0], incomeOrExpense})
            setSelectedCategory({})
            setSelectedGroup({})
            setCategoriesFormatedList([...categoriesFormatedList])
            setCategoriesList([...categoriesList])

            setShowModalType('')
            setLoading(false)
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)

            return setShowNotificationModalText("Categoria criada com sucesso!")
        } catch (error) {
            setModalLoading(false)
            setShowUniversalModal(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDeleteCategory() {
        setModalLoading(true)
        try {

            await api_financial.delete(`/api/v1/financial/categories/${selectedCategory.id}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            setNewCategory(false)
            addAlterOrRemoveCategory(selectedCategory, 'remove')

            setCategoriesFormatedList([...categoriesFormatedList])
            setCategoriesList([...categoriesList])

            setModalLoading(false)
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            return setShowNotificationModalText("Categoria Removida com sucesso!")
        } catch (error) {
            setModalLoading(false)
            setShowUniversalModal(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleAlterCategory() {

        try {

            const alterCategory = await api_financial.put(`/api/v1/financial/categories/${selectedCategory.id}`, {
                description: selectedCategory?.description ? selectedCategory?.description : undefined,
                financialCategoriesGroups_id: selectedCategory?.financialCategoriesGroups_id ? selectedCategory?.financialCategoriesGroups_id : undefined,
                financialCategoriesConfigDre_id: selectedCategory?.financialCategoriesConfigDre_id ? selectedCategory?.financialCategoriesConfigDre_id : undefined,
                externalCode: selectedCategory?.externalCode ? selectedCategory?.externalCode : undefined
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModalType('')
            addAlterOrRemoveCategory({...alterCategory?.data?.data[0], incomeOrExpense: selectedCategory?.incomeOrExpense})
            setSelectedCategory({})
            setSelectedGroup({})
            setCategoriesFormatedList([...categoriesFormatedList])
            setCategoriesList([...categoriesList])

            setModalLoading(false)
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            return setShowNotificationModalText("Categoria alterada com sucesso!")
        } catch (error) {
            setModalLoading(false)
            setShowUniversalModal(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function addAlterOrRemoveCategory(category, remove = null) {
        const categoryExists = categoriesList.find(i => i.id == category.id)
        const type = incomeOrExpense == 'R' ? 0 : 1
        const groupIndex = categoriesFormatedList[type]?.groups?.findIndex(i => i.id == selectedGroup?.id)

        if (!categoryExists) { //criação de categoria
            categoriesList.push(category)
            categoriesFormatedList[type]?.groups[groupIndex]?.categories?.push(category)
        } else {
            categoriesFormatedList[type]?.groups[groupIndex]?.categories?.forEach((i, index) => {
                if (i.id == category.id && remove == 'remove') { // remoção de categoria
                    categoriesList.splice(categoriesList.findIndex(i2 => i2?.id == category?.id), 1)
                    categoriesFormatedList[type].groups[groupIndex].categories.splice(index, 1)
                } else if (i.id == category.id) { //alteração de categoria
                    const categoryItem = categoriesFormatedList[type].groups[groupIndex].categories[index]
                    if (category.financialCategoriesGroups_id != categoryItem.financialCategoriesGroups_id) { //se mudar grupo da categoria
                        categoriesFormatedList[type]?.groups[groupIndex]?.categories.splice(index, 1)
                        const newGroupIndex = categoriesFormatedList[type]?.groups?.findIndex(i => i.id == category.financialCategoriesGroups_id)
                        categoriesFormatedList[type]?.groups[newGroupIndex]?.categories.push(category)
                    } else { // se mudar apenas dados basicos
                        categoriesFormatedList[type].groups[groupIndex].categories[index] = category
                    }
                }
            })
        }
    }

    return (
        <>
            <ScreenModal>
                {// Loading
                    modalLoading &&
                    <div className="flex items-center justify-center w-96 h-56 mb-10">
                        <DefaultLoader />
                    </div>
                }
                {// Grupo
                    ['newGroup', 'alterGroup', 'removeGroup'].includes(showModalType) && !modalLoading &&
                    <div className="flex flex-col items-center justify-start">
                        <h2 className="text-primaryDefaultLight text-14">{showModalType == 'newGroup' ? 'Novo' : showModalType == 'alterGroup' ? 'Alterar' : 'Remover'} Grupo</h2>
                        <div className="flex flex-col items-center justify-start pt-4 pb-7 px-10 gap-3">
                            {showModalType == 'removeGroup'
                                ? (
                                    <>
                                        <p>Tem certeza que deseja remover esse grupo?</p>
                                        <div className="flex gap-10 mt-4">
                                            <Button
                                                module={"removeGroup:remove"}
                                                userData={userData}
                                                approval={true}
                                                shadow={true}
                                                height={8}
                                                onClick={async () => {
                                                    await handleDeleteGroup()
                                                }}
                                            >Sim</Button>
                                            <Button
                                                module={"removeGroup:remove"}
                                                userData={userData}
                                                approval={false}
                                                shadow={true}
                                                height={8}
                                                onClick={() => {
                                                    setSelectedCategory({})
                                                    setShowModalType('')
                                                    setShowUniversalModal(false)
                                                }}
                                            >Não</Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <LabelInput text={'Descrição: *'}>
                                            <Input id='groupDescription' type={'text'} value={selectedGroup?.description} onChange={(e) => setSelectedGroup((prev) => ({ ...prev, description: e.target.value }))} />
                                        </LabelInput>
                                        <LabelInput text={'Código externo:'}>
                                            <Input id='groupExternalCode' type={'text'} value={selectedGroup?.externalCode} onChange={(e) => setSelectedGroup((prev) => ({ ...prev, externalCode: e.target.value }))} />
                                        </LabelInput>
                                        <div className="mt-4">
                                            <Button
                                                module={"financialCategories:create"}
                                                userData={userData}
                                                approval={true}
                                                shadow={true}
                                                height={8}
                                                onClick={() => {
                                                    if (showModalType == 'newGroup') {
                                                        handleNewGroup()
                                                    } else {
                                                        handleAlterGroup()
                                                    }
                                                }}
                                            >Criar</Button>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                }
                {// Categoria
                    ['alterCategory', 'removeCategory'].includes(showModalType) && !modalLoading &&
                    <div className="flex flex-col items-center justify-start">
                        <h2 className="text-primaryDefaultLight text-14">
                            {showModalType == 'newCategory' ? 'Nova' : showModalType == 'alterCategory' ? 'Alterar' : 'Remover'} Categoria
                        </h2>
                        <div className="flex flex-col items-center justify-start pt-4 pb-7 px-10 gap-3">
                            {
                                showModalType == 'removeCategory' ? (
                                    <>
                                        <p>Tem certeza que deseja remover essa categoria?</p>
                                        <div className="flex gap-10 mt-4">
                                            <Button
                                                module={"financialCategories:remove"}
                                                userData={userData}
                                                approval={true}
                                                shadow={true}
                                                height={8}
                                                onClick={async () => {
                                                    await handleDeleteCategory()
                                                }}
                                            >Sim</Button>
                                            <Button
                                                module={"financialCategories:remove"}
                                                userData={userData}
                                                approval={false}
                                                shadow={true}
                                                height={8}
                                                onClick={() => {
                                                    setSelectedCategory({})
                                                    setShowModalType('')
                                                    setShowUniversalModal(false)
                                                }}
                                            >Não</Button>
                                        </div>
                                    </>
                                )
                                    : (
                                        <div className="flex flex-col gap-4 items-center justify-center">
                                            {
                                                selectedCategory?.applicationModules_id != 3 &&
                                                <p className="text-[#8f8f8f]">
                                                    {
                                                        selectedCategory?.applicationModules_id == 1 ? 'Integrado com: Reservas' 
                                                            : selectedCategory?.applicationModules_id == 2 ? 'Integrado com: Inscrições em atividades' 
                                                            : selectedCategory?.applicationModules_id == 4 ? 'Integrado com: Eventos' 
                                                            : 'Integrado com: Gastronomia' 
                                                    }
                                                </p>
                                            }
                                            <LabelInput text={'Descrição: *'}>
                                                <Input
                                                    id='categoryDescription'
                                                    type={'text'}
                                                    value={selectedCategory?.description}
                                                    onChange={(e) => setSelectedCategory((prev) => ({ ...prev, description: e.target.value }))}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Grupo: *'}>
                                                <InputAutoComplete
                                                    data={groupsList.filter(group => group?.incomeOrExpense == selectedCategory?.incomeOrExpense)}
                                                    id={'categoryGroup'}
                                                    preSelectedValue={selectedGroup?.description}
                                                    onChange={(e) => setSelectedCategory(prev => ({ ...prev, financialCategoriesGroups_id: e.id }))}
                                                    placeHolder={'-'}
                                                    selectedLabel={'description'}
                                                    optionList={['id', 'description']}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Associar ao DRE: *'}>
                                                <InputAutoComplete
                                                    data={dreConfigList?.filter(dre => dre.incomeOrExpense == incomeOrExpense)}
                                                    id={'categoryConfigDRE'}
                                                    preSelectedValue={
                                                        selectedCategory?.financialCategoriesConfigDre_id
                                                            ? dreConfigList?.find((item) => item.id == selectedCategory?.financialCategoriesConfigDre_id)?.description
                                                            : ''
                                                    }
                                                    onChange={(e) => setSelectedCategory(prev => ({ ...prev, financialCategoriesConfigDre_id: e.id }))}
                                                    placeHolder={'-'}
                                                    selectedLabel={'description'}
                                                    optionList={['id', 'description']}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Código externo:'}>
                                                <Input
                                                    id='externalCode'
                                                    type={'text'}
                                                    value={selectedCategory?.externalCode}
                                                    onChange={(e) => setSelectedCategory((prev) => ({ ...prev, externalCode: e.target.value }))}
                                                />
                                            </LabelInput>
                                            <div className="mt-4 flex items-center gap-2">
                                                <Button
                                                    module={"financialCategories:create"}
                                                    userData={userData}
                                                    approval={true}
                                                    shadow={true}
                                                    height={8}
                                                    onClick={async () => {
                                                        if (showModalType == 'newCategory') {
                                                            await handleNewCategory()
                                                        } else {
                                                            await handleAlterCategory()
                                                        }
                                                    }}
                                                >{showModalType == 'newCategory' ? 'Criar' : 'Alterar'}</Button>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className="flex flex-row justify-between w-full">
                        <Title text='Categorias'></Title>
                    </div>
                </TitlePage>
                <Body>
                    {loading
                        ? <DefaultLoader />
                        : 
                        categoriesFormatedList?.length > 0
                        &&
                        <div className="w-full flex flex-col gap-5 text-sm text-slate-600 dark:text-titleGrayTextDark">
                            {categoriesFormatedList.map((type, iType) => {
                                return (
                                    <div key={iType} className={`border ${type.incomeOrExpense == 'R' ? 'bg-green-50 dark:border-green-800' : 'bg-red-50 dark:border-red-800'} rounded-lg dark:bg-primaryDefaultDark py-3 px-5 transition-all duration-300 ease-in-out`}>
                                        <div className={"flex items-center justify-between"}>
                                            <div className={`flex gap-2 ${type.showGroups ? 'pb-2' : ''}`}>
                                                <span
                                                    className="flex items-center gap-2 cursor-pointer"
                                                    onClick={() => {
                                                        type.showGroups = !type.showGroups
                                                        setCategoriesFormatedList([...categoriesFormatedList])
                                                    }}
                                                >
                                                    {type.showGroups ? <FaAngleDown /> : <FaAngleRight />}
                                                </span>
                                                <p className={`${type.incomeOrExpense == 'R' ? 'text-green-700' : 'text-red-800'} font-semibold`}>
                                                    {type.description}
                                                </p>
                                            </div>
                                        </div>
                                        <div className={`flex flex-col gap-5 mx-5 ${type.showGroups ? 'block' : 'hidden'}`}>
                                            {type?.groups?.map((group, iGroup) => {
                                                return (
                                                    <div key={iGroup} className={`${!group.active ? 'line-through' : ''} border dark:border-primaryBorderDark bg-white dark:bg-secondaryDefaultDark rounded-lg py-3 px-5 transition-all duration-300 ease-in-out`}>
                                                        <div className={`flex items-center justify-between ${group.showCategories ? 'pb-2' : ''}`}>
                                                            <div className="flex gap-2">
                                                                <span
                                                                    className="flex items-center gap-2 cursor-pointer"
                                                                    onClick={() => {
                                                                        group.showCategories = !group.showCategories
                                                                        setCategoriesFormatedList([...categoriesFormatedList])
                                                                    }}
                                                                >
                                                                    {group.showCategories ? <FaAngleDown /> : <FaAngleRight />}
                                                                </span>
                                                                <p className="font-normal">{group.description} {!group.active ? '(inativo)' : ''}</p>
                                                            </div>
                                                            <div className="flex gap-3 items-center">
                                                                <FaEdit
                                                                    onClick={async () => {
                                                                        console.log(group)
                                                                        console.log(type)
                                                                        setSelectedGroup(group)
                                                                        setIncomeOrExpense(type.incomeOrExpense == 'R' ? 'R' : 'D')
                                                                        setModalLoading(true)
                                                                        setShowUniversalModal(true)
                                                                        await new Promise(resolve => setTimeout(resolve, 60))
                                                                        setModalLoading(false)
                                                                        setShowModalType('alterGroup')
                                                                    }}
                                                                    className="text-primaryDefaultLight cursor-pointer"
                                                                />
                                                                <FaTrash
                                                                    onClick={async () => {
                                                                        setSelectedGroup(group)
                                                                        setIncomeOrExpense(type.incomeOrExpense == 'R' ? 'R' : 'D')
                                                                        setModalLoading(true)
                                                                        setShowUniversalModal(true)
                                                                        await new Promise(resolve => setTimeout(resolve, 60))
                                                                        setModalLoading(false)
                                                                        setShowModalType('removeGroup')
                                                                    }}
                                                                    className="text-red-800 cursor-pointer text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={`flex flex-col gap-2 mx-5 ${group.showCategories ? 'block' : 'hidden'}`}>
                                                            {group?.categories?.map((category, iCategory) => {
                                                                return (
                                                                    <div key={iCategory} className={`${!category.active ? 'line-through' : ''} flex items-center justify-between bg-white dark:bg-secondaryDefaultDark py-2 px-5 border dark:border-primaryBorderDark rounded-md`}>
                                                                        <p className="font-light">{category.description} {!category.active ? '(inativo)' : ''}</p>
                                                                        <div className="flex gap-3 items-center">
                                                                            <FaEdit
                                                                                onClick={async () => {
                                                                                    setNewCategory(false)
                                                                                    console.log(category)
                                                                                    setSelectedCategory(category)
                                                                                    setSelectedGroup(group)
                                                                                    setIncomeOrExpense(type.incomeOrExpense == 'R' ? 'R' : 'D')
                                                                                    setModalLoading(true)
                                                                                    setShowUniversalModal(true)
                                                                                    await new Promise(resolve => setTimeout(resolve, 60))
                                                                                    setModalLoading(false)
                                                                                    setShowModalType('alterCategory')
                                                                                }}
                                                                                className="text-primaryDefaultLight cursor-pointer"
                                                                            />
                                                                            {
                                                                                category.applicationModules_id != 3 ? (
                                                                                    <FaTrash className="text-red-800 cursor-not-allowed opacity-30 text-sm"/>
                                                                                ) : (
                                                                                    <FaTrash
                                                                                        onClick={() => {
                                                                                            setSelectedCategory(category)
                                                                                            setSelectedGroup(group)
                                                                                            setIncomeOrExpense(type.incomeOrExpense == 'R' ? 'R' : 'D')
                                                                                            setShowModalType('removeCategory')
                                                                                            setShowUniversalModal(true)
                                                                                        }}
                                                                                        className="text-red-800 cursor-pointer text-sm"
                                                                                    />
                                                                                )
                                                                            }
                                                                            
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="flex text-gray-400 items-center justify-between bg-white dark:bg-secondaryDefaultDark py-2 rounded-md">
                                                                {
                                                                    <button
                                                                        type="button"
                                                                        disabled={group.active ? false : true}
                                                                        className={`cursor-pointer w-fit ${type.incomeOrExpense == 'R' ? 'hover:text-green-700' : 'hover:text-red-800'} ${group.active ? '' : 'hover:text-gray-400 cursor-default'} delay-75`}
                                                                        onClick={async () => {
                                                                            if (group.active) {
                                                                                setNewCategory(newCategory && selectedGroup?.id === group?.id ? false : true)
                                                                                setSelectedGroup(group)
                                                                                setSelectedCategory({ financialCategoriesGroups_id: group.id })
                                                                                setIncomeOrExpense(type.incomeOrExpense == 'R' ? 'R' : 'D')
                                                                            }
                                                                        }}
                                                                    >
                                                                        {
                                                                            newCategory && selectedGroup?.id === group?.id
                                                                                ?
                                                                                <div className="flex items-center gap-[2px]">
                                                                                    <MdRefresh size={16} />
                                                                                    Cancelar
                                                                                </div>
                                                                                :
                                                                                <p>+ Nova Categoria</p>
                                                                        }
                                                                    </button>
                                                                }
                                                            </div>
                                                            {
                                                                newCategory && selectedGroup?.id === group?.id &&
                                                                <div className={`px-5 flex flex-col items-center max-w-full shadow-sm p-4 rounded-md border ${type.incomeOrExpense == 'R' ? 'bg-green-50' : 'bg-red-50'}`}>
                                                                    <div className="flex flex-wrap items-start gap-5">
                                                                        <LabelInput text={'Descrição: *'}>
                                                                            <Input
                                                                                id='categoryDescription'
                                                                                type={'text'}
                                                                                value={selectedCategory?.description}
                                                                                onChange={(e) => setSelectedCategory(prev => ({ ...prev, description: e.target.value }))}
                                                                            />
                                                                        </LabelInput>
                                                                        {/* <LabelInput text={'Grupo: *'}>
                                                                            <InputAutoComplete
                                                                                data={groupsList}
                                                                                id={'categoryGroup'}
                                                                                preSelectedValue={''}
                                                                                onChange={(e) => setSelectedCategory(prev => ({ ...prev, financialCategoriesGroups_id: e.id }))}
                                                                                placeHolder={'-'}
                                                                                selectedLabel={'description'}
                                                                                optionList={['id', 'description']}
                                                                            />
                                                                        </LabelInput> */}
                                                                        <LabelInput text={'Associar ao DRE: *'}>
                                                                            <InputAutoComplete
                                                                                data={dreConfigList.filter(dre => dre.incomeOrExpense == incomeOrExpense)}
                                                                                id={'categoryConfigDRE'}
                                                                                preSelectedValue={
                                                                                    ''
                                                                                }
                                                                                onChange={(e) => setSelectedCategory(prev => ({ ...prev, financialCategoriesConfigDre_id: e.id }))}
                                                                                placeHolder={'-'}
                                                                                selectedLabel={'description'}
                                                                                optionList={['id', 'description']}
                                                                            />
                                                                        </LabelInput>
                                                                        <LabelInput text={'Código externo:'}>
                                                                            <Input
                                                                                id='newCategoryDescription'
                                                                                type={'text'}
                                                                                value={selectedCategory?.externalCode}
                                                                                onChange={(e) => setSelectedCategory((prev) => ({ ...prev, externalCode: e.target.value }))}
                                                                            />
                                                                        </LabelInput>
                                                                    </div>
                                                                    <div className="w-full mt-4">
                                                                        <Button
                                                                            approval={true}
                                                                            onClick={() => handleNewCategory(group?.incomeOrExpense)}
                                                                        >
                                                                            Criar
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className="ml-5 text-gray-400">
                                                <button
                                                    type="button"
                                                    className={`cursor-pointer w-fit ${type.incomeOrExpense == 'R' ? 'hover:text-green-700' : 'hover:text-red-800'} delay-75`}
                                                    onClick={() => {
                                                        setShowModalType('newGroup')
                                                        setIncomeOrExpense(type.incomeOrExpense == 'R' ? 'R' : 'D')
                                                        setSelectedGroup({})
                                                        setShowUniversalModal(true)
                                                    }}
                                                >
                                                    + Novo Grupo
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </Body>
            </Container>
        </>
    )
}