//hooks
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
//components
import { Container } from '../../../components/container/container'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Input } from '../../../components/input/input'
import { Body } from '../../../components/container/Body'
import { Button } from '../../../components/buttons/button.default'
import { LabelInput } from '../../../components/label/label.input'
import { Footer } from '../../../components/footer/Footer'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import Multiselect from '../../../components/selects/mult.select'
import { Quillmodules } from '../../../components/richText/quill.modules'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function ServiceTermsCreate() {

    const { api, defaultCallsSchedules } = useApiContext()
    const { userData } = useAuth()
    const { screenX } = useScreenSizeContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)

    const [serviceList, setServiceList] = useState([])

    const [title, setTitle] = useState('')
    const [selectedService, setSelectedService] = useState([])
    const [description, setDescription] = useState('')
    const [deltaDescription, setDeltaDescription] = useState({})

    useEffect(() => {

        async function getData() {

            const servicesData = await defaultCallsSchedules.getServices(userData[0].token)
            if (servicesData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(servicesData.data)
            }

            setServiceList(servicesData.data)
            setLoading(false)
        }

        getData()

    }, [])

    async function handleSubmit() {

        setLoading(true)
        let formattedServices = []
        selectedService.map((service) => {
            formattedServices.push(service.id)
        })

        let apiObject = {
            title: title,
            termsOfUse: deltaDescription,
            services: formattedServices
        }
        try {
            const newTerm = await api.post(`/api/v1/termsOfUse`,
                apiObject, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Termo criado com sucesso!")
            setLoading(false)
            navigate('/serviceTermsOfUse')
        } catch (error) {
            console.log(responseError(error))
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function handleChange(content, delta, source, editor) {
        setDescription(content)
        setDeltaDescription(editor.getContents());
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Novo termo'} />
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ?
                        <div className='flex flex-col items-center justify-center w-full h-96'>
                            <DefaultLoader />
                        </div>
                        :
                        <div className='flex flex-col gap-6 items-start justify-start mb-32 w-full'>
                            <div className='border border-zinc-100 flex flex-col bg-zinc-50 dark:bg-secondaryDefaultDark dark:border dark:border-secondaryBorderDark rounded-lg w-full p-4 items-start justify-start'>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                    Selecione abaixo quais <strong>serviços</strong> este termo irá compor, ao selecionar o serviço, no momento da <strong>reserva</strong> para o cliente, será apresentado este termo
                                </p>
                            </div>
                            <div className='flex flex-col sm:flex-row gap-2 items-start justify-start'>
                                <LabelInput text={'Titulo'}>
                                    <Input charLimit={255} width={ screenX < 640 ? '72' : ''} value={title} type={'text'} onChange={(e) => setTitle(e.target.value)}></Input>
                                </LabelInput>
                                <LabelInput text={'Serviços'}>
                                    <Multiselect
                                        width={ screenX < 640 ? '72' : ''}
                                        items={serviceList}
                                        mult
                                        value={selectedService}
                                        onChange={(e) => {
                                            setSelectedService(e)
                                        }}
                                        selectedLabel={'name'}
                                        id={`selectedTermsServices`} />
                                </LabelInput>
                            </div>
                            <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96' readOnly={false} theme={"snow"} value={description} onChange={handleChange} />
                        </div>
                }
            </Body>
            {
                !loading &&
                <Footer>
                    <div className='w-full justify-between items-center h-full flex flex-row'>
                        <Button approval={false} onClick={() => navigate('/serviceTermsOfUse')} shadow={true}>Voltar</Button>
                        <Button onClick={() => handleSubmit()} shadow={true}>Cadastrar</Button>
                    </div>
                </Footer>
            }
        </Container>
    )
}