
//hooks
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
//utils
import Cropper from 'react-easy-crop'
import { getCroppedImg } from '../../../utils/formatImages/canvasUtil'
import { responseError } from '../../../utils/responsesFunctions/error.response';
//components
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
//contexts
import { useAuth } from '../../../contexts/useAuth';
import { useThemeContext } from '../../../contexts/themeContext';
import { resizePhoto } from '../../../utils/formatImages/resizeImage';
import { resizeImageCanvas } from '../../../utils/formatImages/resizeImageCanvas';
import { UrlToImage } from '../../../utils/formatImages/urlToImgFile';
import { useApiContext } from '../../../contexts/ApiInterceptorContext';

export function MyProfilePictureEdit({ imgSrc, picPreview, onChange }) {

    const { userData, setUserData } = useAuth()
    const { api_auth } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [successAvatarUpload, setSuccessAvatarUpload] = useState(false);
    const [picturePreview, setPicturePreview] = useState('');
    const [sendFile, setSendFile] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [loading, setLoading] = useState(false);
    const { userAvatar, setUserAvatar } = useAuth();
    const [aspectRatio, setAspectRatio] = useState({
        width: 350,
        height: 350
    })
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImageReady = await getCroppedImg(
                imageSrc,
                croppedAreaPixels
            )
            const mimeTypes = ['image/png', 'image/jpeg']
            const extension = ['.png', '.jpeg']

            try {

                const blob = await (await fetch(croppedImageReady)).blob();

                if (mimeTypes.indexOf(blob.type) > -1) {
                    const file = new File([blob], `image${extension[mimeTypes.indexOf(blob.type)]}`, {
                        type: blob.type
                    })

                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onload = async () => {
                        const image = new Image();
                        image.src = reader.result;
                        image.onload = async () => {
                            const resizedImage = resizeImageCanvas(image, 300, 300);
                            const fileResized = await UrlToImage(resizedImage, file?.name)
                            setCroppedImage(resizedImage);
                            setSendFile(fileResized);
                        };
                    }
                }
                else {
                    throw new Error('Esse tipo de arquivo não pode ser colocado como Imagem')
                }

            } catch (error) {
                if (error.response?.data?.message) {
                    return alert(error.response.data.message)
                }
                return alert(error.message)
            }
        } catch (e) {
            return alert('Erro ao tentar salvar imagem redimensionada.')
        }

    }, [imageSrc, croppedAreaPixels])

    useEffect(() => {
        setImageSrc(imgSrc);
        setPicturePreview(picPreview)
    }, [])


    async function handleSendImgToServer() {
        setLoading(true);
        try {

            const formData = new FormData();
            formData.append('image', sendFile);

            const avatarPostResult = await api_auth.post(`/api/v1/auth/myProfile/uploadPicture`, formData, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })

            if (avatarPostResult?.data?.data[0]?.photo?.length > 0) {
                userData[0].photo = avatarPostResult?.data?.data[0]?.photo
                setUserData([...userData])

            }
            setSuccessAvatarUpload(true)
            onChange('')
            setImageSrc(null)
            setLoading(false);
        }
        catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (

        picturePreview !== '' ? (
            <>
                <div className='cropArea -ml-28'>
                    {
                        loading ? (
                            <div id='userEdittedImageLoader'>
                                <DefaultLoader />
                            </div>
                        ) :
                            successAvatarUpload ?
                                (
                                    <div className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm flex flex-col items-center justify-center' id='uploadSuccessMsg'>
                                        <a className='mb-10'>Sucesso ao alterar foto de perfil</a>
                                        <button className='text-titleGrayTextLight h-8 w-20 border-none bg-primaryDefaultLight items-center justify-center' onClick={() => setSuccessAvatarUpload(false)}>Fechar</button>
                                    </div>
                                ) :
                                croppedImage !== null ? (
                                    <>
                                        <div id='croppedImageConfirmation' className='p-3 absolute top-20 items-center left-0 justify-center w-full flex flex-row gap-2 z-20'>
                                            <div className='p-6 rounded-2xl'>
                                                <a className='text-titleGrayTextLight text-xl font-bold '>Confirme o resultado</a>
                                            </div>

                                        </div>
                                        <div className='absolute z-20 bottom-20 items-center left-0 justify-center w-full flex flex-row gap-2'>
                                            <button className='boxShadow border border-primaryDefaultLight bg-secondaryDefaultLight hover:brightness-125 rounded-lg text-titleGrayTextLight p-2 w-28' onClick={() => handleSendImgToServer()}>Confirmar</button>
                                            <button className='boxShadow border border-primaryDefaultLight bg-secondaryDefaultLight hover:brightness-125 rounded-lg text-titleGrayTextLight p-2 w-28' onClick={() => setCroppedImage(null)}>Cancelar</button>
                                        </div>
                                        <div className='flex flex-col w-full h-full bg-[#6b6b6bb6] absolute top-0 left-0 items-center justify-center'>
                                            <div className={`boxShadow overflow-hidden flex flex-col items-center justify-center bg-white w-[${aspectRatio.width}px] h-[${aspectRatio.height}px] rounded-full`}>
                                                <img id='croppedImage' className='w-full h-full rounded-lg' srcSet={croppedImage} />
                                            </div>
                                        </div>
                                    </>) :
                                    (
                                        <div>
                                            <div className='absolute bottom-20 items-center left-0 justify-center w-full gap-2 flex flex-col z-[200]' id='croppedImageEdition'>

                                                <div className='flex flex-row gap-2'>
                                                    <button className='border border-primaryDefaultLight bg-secondaryDefaultLight hover:brightness-125 rounded-lg text-titleGrayTextLight p-2 w-28 boxShadow' onClick={showCroppedImage}>Cortar</button>
                                                    <button className='border border-primaryDefaultLight bg-secondaryDefaultLight hover:brightness-125 rounded-lg text-titleGrayTextLight p-2 w-28 boxShadow' onClick={() => onChange('')}>Cancelar</button>
                                                </div>
                                            </div>

                                            <Cropper
                                                image={picturePreview}
                                                crop={crop}
                                                style={{
                                                    mediaStyle: {
                                                        position: 'absolute',
                                                        margin: 'auto',
                                                        top: '0', left: "0",
                                                        right: '0', bottom: '0',
                                                        borderRadius: '50%',
                                                        overflow: 'hidden'

                                                    },
                                                    containerStyle: {
                                                        // borderRadius: '50%',
                                                        cursor: 'move',
                                                        position: 'absolute',
                                                        top: '0', left: "0",
                                                        right: '0', bottom: '0',
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'col',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        overflow: 'hidden',
                                                        touchAction: 'none',
                                                        userSelect: 'none'
                                                    },
                                                    cropAreaStyle: {
                                                        borderRadius: '50%',
                                                        border: '2px solid white',
                                                        position: 'absolute',
                                                        boxSizing: 'border-box',
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                        overflow: 'hidden',
                                                        boxShadow: '0 0 0 9999em'
                                                    }
                                                }}
                                                cropSize={{ width: aspectRatio.width, height: aspectRatio.height }}
                                                zoom={zoom}
                                                minZoom={0}
                                                disableAutomaticStylesInjection={true}
                                                aspect={4 / 3}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                            />
                                        </div>
                                    )}

                </div>

            </>

        ) : (<></>)
    )
}