//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Toogle } from '../../../components/toogle/Toogle'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { Footer } from '../../../components/footer/Footer'
import { validateFields } from '../../../utils/form.validator'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import { FaInfo } from 'react-icons/fa'
import { maxNumber } from '../../../utils/mask/maxNumber'

export function AssociatiosEdit() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { api_auth, api_financial, defaultCallsAuth, defaultCallsFinancial } = useApiContext()
    const associationId = useParams()
    const [loading, setLoading] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [associationStatus, setAssociationsStatus] = useState(false)
    const [associationStatusHistory, setAssociationsStatusHistory] = useState(false)
    const [agreementsAccess, setAgreementsAccess] = useState(true)
    const [requireRegistrationCode, setRequireRegistrationCode] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const {
        setShowNotificationModal, setShowNotificationModalSuccess,
        setShowNotificationModalText, showUniversalModal,
        setShowUniversalModal
    } = useThemeContext()

    //selected variables
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [isCommunity, setIsCommunity] = useState()
    const [isMonthlyPayer, setIsMonthlyPayer] = useState(false)
    const [monthlyPaymentType, setMonthlyPaymentType] = useState({ name: 'Porcentagem sob salário', id: 2, value: 2 },)
    const [monthlyPaymentValue, setMonthlyPaymentValue] = useState(0)
    const [paymentTypeList, setPaymentTypeList] = useState([
        { name: 'Fixo/valor', id: 1, value: 1 },
        { name: 'Porcentagem sob salário', id: 2, value: 2 },
    ])

    // config financial
    const [configFinancial, setConfigFinancial] = useState({
        useFinancialUsersBalance: false,
        useAutoRenewBalance: false,
        autoRenewResetBalance: false,
        autoRenewPeriod: '',
        autoRenewMonth: '',
        autoRenewDay: '',
        balanceType: '',
        balanceValue: ''
    })
    const boolOptions = [
        { value: true, label: "Sim" },
        { value: false, label: "Não" },
    ]
    const renewPeriodOptions = [
        { value: 'month', label: 'Mensal' },
        { value: 'year', label: 'Anual' },
    ]
    const balanceTypeOptions = [
        { value: "fixed", label: "Fixo" },
        { value: "percentageOfSalary", label: "Porcentagem do salário" },
    ]

    useEffect(() => {
        setLoading(true)
        setShowUniversalModal(false)
        async function getData() {
            const associationsGet = await defaultCallsAuth.getAssociationsById(userData[0]?.token, associationId?.id)
            if (associationsGet.error) {
                setLoading(false)
                setShowNotificationModal(associationsGet.data)
                return
            }
            setIsCommunity(associationsGet?.data[0]?.isCommunity)
            setName(associationsGet?.data[0]?.name)
            setDescription(associationsGet?.data[0]?.description)
            setAgreementsAccess(associationsGet?.data[0]?.agreementsAccess)
            setRequireRegistrationCode(associationsGet?.data[0]?.requireRegistrationCode)
            setIsMonthlyPayer(associationsGet.data[0]?.isMonthlyPayer)
            setMonthlyPaymentType(associationsGet.data[0]?.monthlyPaymentType === 1 ? { name: 'Fixo/valor', id: 1, value: 1 } : { name: 'Porcentagem sob salário', id: 2, value: 2 })
            setMonthlyPaymentValue(associationsGet.data[0]?.monthlyPaymentValue ? associationsGet.data[0]?.monthlyPaymentValue : 0)
            //inverter com a API
            setAssociationsStatus(associationsGet?.data[0]?.active ? true : false)
            setAssociationsStatusHistory(structuredClone(associationsGet?.data[0]?.active ? true : false))

            if (userData[0]?.customersData?.modules?.map(mod => mod?.id).includes(3)) {

                const financialAssociationsGet = await defaultCallsFinancial.getFinancialAssociationPerId(userData[0]?.token, associationId?.id)
                if (!financialAssociationsGet?.error) {
                    setConfigFinancial({
                        autoRenewDay: financialAssociationsGet?.data[0]?.autoRenewDay,
                        autoRenewMonth: financialAssociationsGet?.data[0]?.autoRenewMonth,
                        autoRenewPeriod: financialAssociationsGet?.data[0]?.autoRenewPeriod,
                        autoRenewResetBalance: financialAssociationsGet?.data[0]?.autoRenewResetBalance,
                        balanceType: financialAssociationsGet?.data[0]?.balanceType,
                        balanceValue: financialAssociationsGet?.data[0]?.balanceValue,
                        useAutoRenewBalance: financialAssociationsGet?.data[0]?.useAutoRenewBalance,
                        useFinancialUsersBalance: financialAssociationsGet?.data[0]?.useFinancialUsersBalance
                    })
                }
            }

            setLoading(false)
        }

        getData()

    }, [])

    async function handleSubmit() {
        let requiredFields = [
            { "name": 'name', "value": name, "required": true, "type": 'string' },
            { "name": 'description', "value": description, "required": true, "type": 'string' }
        ]
        let configFinancialFields = []
        let currentTab = activeTab

        if (currentTab === 1) {
            if (validateFields(requiredFields)?.length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalText('Campos obrigatórios não preenchidos!')
                return setShowNotificationModalSuccess(false)
            }
        }


        if (userData[0]?.customersData?.modules?.map(mod => mod?.id).includes(3)) {

            if (configFinancial?.useFinancialUsersBalance) {
                configFinancialFields.push(...[
                    { "name": 'useAutoRenewBalance', "value": String(configFinancial?.useAutoRenewBalance), "required": true, "type": 'string' },
                    { "name": 'balanceType', "value": configFinancial?.balanceType, "required": true, "type": 'string' },
                    { "name": 'balanceValue', "value": configFinancial?.balanceValue, "required": true, "type": 'string' },
                ])

                if (configFinancial?.useFinancialUsersBalance && configFinancial?.useAutoRenewBalance) {
                    configFinancialFields.push(...[
                        { "name": 'autoRenewDay', "value": configFinancial?.autoRenewDay, "required": true, "type": 'string' },
                        { "name": 'autoRenewPeriod', "value": configFinancial?.autoRenewPeriod, "required": true, "type": 'string' }
                    ])
                }

                if (configFinancial?.useAutoRenewBalance && configFinancial?.autoRenewPeriod === "year") {
                    configFinancialFields.push({ "name": 'autoRenewMonth', "value": configFinancial?.autoRenewMonth, "required": true, "type": 'string' })
                }
            }

            if (configFinancialFields?.length > 0) {
                setActiveTab(2)
                currentTab = 2
            }

            if (currentTab === 2) {
                if (validateFields(configFinancialFields)?.length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos da aba "Financeiro"')
                }
            }

        }

        try {
            setLoading(true)
            if (associationStatus !== associationStatusHistory) {
                await api_auth.put(`/api/v1/auth/associations/${associationId.id}/active/${associationStatus ? 1 : 0}`, {

                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            }

            await api_auth.put(`/api/v1/auth/associations/${associationId.id}`, {
                name,
                description,
                agreementsAccess,
                requireRegistrationCode
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            
            if(userData[0]?.customersData?.modules?.map(mod => mod?.id).includes(3)){
                await api_financial.put(`/api/v1/financial/associations/${associationId.id}`, {
                    useFinancialUsersBalance: [true, false].includes(configFinancial?.useFinancialUsersBalance) ? configFinancial?.useFinancialUsersBalance : undefined,
                    useAutoRenewBalance: [true, false].includes(configFinancial?.useAutoRenewBalance) ? configFinancial?.useAutoRenewBalance : undefined,
                    autoRenewPeriod: configFinancial?.autoRenewPeriod || undefined,
                    autoRenewMonth: configFinancial?.autoRenewMonth ? Number(configFinancial?.autoRenewMonth) : undefined,
                    autoRenewDay: configFinancial?.autoRenewDay ? Number(configFinancial?.autoRenewDay) : undefined,
                    autoRenewResetBalance: [true, false].includes(configFinancial?.autoRenewResetBalance) ? configFinancial?.autoRenewResetBalance : undefined,
                    balanceType: configFinancial?.balanceType || undefined,
                    balanceValue: configFinancial?.balanceValue ? Number(configFinancial?.balanceValue) : undefined,
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
            }


            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Associação editada com sucesso!")
            navigate(-1)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                if (error) {
                    return setShowNotificationModalText(responseError(error))
                }
                if (activeTab === 1) {
                    return setShowNotificationModalText('Verifique os campos informados na aba "Financeiro".')
                } else {
                    return setShowNotificationModalText('Verifique os campos informados na aba "Dados".')
                }
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDelete() {

        try {

            await api_auth.delete(`/api/v1/auth/associations/${associationId.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            navigate('/associations')
            setShowNotificationModalText('Associação removida com sucesso!')
            setLoading(false)
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function handleConfirmAssociation(status) {

        if (status) {
            setShowUniversalModal(true)
            setShowConfirmModal(true)
            setShowDelete(false)

        } else {
            setShowConfirmModal(false)
            setAssociationsStatus(true)
        }
    }

    function handleUpdateAssociationStatus() {

        setAssociationsStatus(false)
        setShowConfirmModal(false)
        setShowUniversalModal(false)
    }

    return (
        <>
            <ScreenModal>
                {
                    showDelete ?
                        <div className='w-full flex flex-col items-center justify-start p-6'>
                            <p className='mb-6 text-lg sm:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente deletar a associação?</p>
                            <div className='flex flex-row gap-2 w-full items-center justify-center'>
                                <Button shadow={true} approval={true} onClick={() => handleDelete()}>Sim</Button>
                                <Button shadow={false} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                            </div>
                        </div>
                        :
                        showConfirmModal &&
                        <div className='w-full flex flex-col items-center justify-start p-6'>
                            <p className='mb-6 text-lg sm:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente desativar a associação?</p>
                            <p className='mb-6 text-lg sm:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Todos os usuários da associação não irão poder mais se logar no sistema até que seja reativada</p>
                            <div className='flex flex-row gap-2 w-full items-center justify-center'>
                                <Button shadow={true} approval={true} onClick={() => handleUpdateAssociationStatus()}>Sim</Button>
                                <Button shadow={false} approval={false} onClick={() => { setShowUniversalModal(false); setAssociationsStatus(associationStatusHistory) }}>Não</Button>
                            </div>
                        </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Editar associação'}></Title>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <DefaultLoader />
                            :
                            <div className='w-full h-full'>
                                {
                                    userData[0]?.customersData?.modules?.map(mod => mod?.id).includes(3) && !isCommunity &&
                                    <div className='w-1/4 h-8 flex flex-row items-start justify-start border border-gray-300/50 mb-6'>
                                        <a
                                            onClick={() => setActiveTab(1)}
                                            className={`
                                                        cursor-pointer transition-all ease-in duration-200 text-sm
                                                        w-1/2 h-full ${activeTab === 1 ? 'bg-primaryDefaultLight text-white hover:brightness-110' : 'bg-transparent text-titleBlackTextLight hover:brightness-75'} 
                                                        font-semibold flex flex-col items-center justify-center
                                                    `}
                                        >
                                            Dados
                                        </a>
                                        <a
                                            onClick={() => setActiveTab(2)}
                                            className={`
                                                        cursor-pointer transition-all ease-out duration-200 text-sm
                                                        w-1/2 h-full ${activeTab === 2 ? 'bg-primaryDefaultLight text-white hover:brightness-110' : 'bg-transparent text-titleBlackTextLight hover:brightness-75'} 
                                                        font-semibold flex flex-col items-center justify-center
                                                    `}
                                        >
                                            Financeiro
                                        </a>
                                    </div>
                                }
                                {
                                    activeTab === 1 ?
                                        <form className='flex flex-col gap-2 sm:items-start justify-start sm:justify-center w-full'>
                                            <LabelInput text={'Nome:'}>
                                                <Input id='name' type={'text'} value={name} onChange={(e) => setName(e.target.value)}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Descrição:'}>
                                                <Input id='description' type={'text'} value={description} onChange={(e) => setDescription(e.target.value)}></Input>
                                            </LabelInput>
                                            <Tippy content={'Mantenha ativado caso esta associação possa ver os convênios disponíveis no sistema'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}>
                                                <div>
                                                    <LabelInput text={'Acesso a convênios'}>
                                                        <Toogle value={agreementsAccess} status={agreementsAccess} onClick={() => setAgreementsAccess(!agreementsAccess)} />
                                                    </LabelInput>
                                                </div>
                                            </Tippy>
                                            <Tippy content={'Ative caso esta associação exija que no cadastro do usuário haja matrícula'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}>
                                                <div>
                                                    <LabelInput text={'Exige matrícula'}>
                                                        <Toogle value={requireRegistrationCode} status={requireRegistrationCode} onClick={() => setRequireRegistrationCode(!requireRegistrationCode)} />
                                                    </LabelInput>
                                                </div>
                                            </Tippy>
                                            <div className='items-start justify-start w-full flex flex-row gap-4 flex-wrap cursor-pointer' onClick={() => handleConfirmAssociation(associationStatus)}>
                                                <LabelInput text={'Ativo'}>
                                                    <Toogle disabled={false} onClick={(e) => handleConfirmAssociation(e)} status={associationStatus}></Toogle>
                                                </LabelInput>
                                            </div>
                                        </form> :
                                        <div className='w-full items-start justify-start flex flex-col gap-4'>
                                            <div className='w-full flex flex-col gap-2 items-start justify-start'>
                                                <div className='flex flex-row flex-wrap gap-4 w-full items-start justify-start'>
                                                    <div className='flex flex-col items-start justify-start'>
                                                        <LabelInput text={'Controla saldo de usuários: *'}>
                                                            <InputAutoComplete
                                                                id={'useFinancialUsersBalance'}
                                                                preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.useFinancialUsersBalance)?.label || ''}
                                                                data={boolOptions}
                                                                onChange={(e) => setConfigFinancial(prev => ({ ...prev, useFinancialUsersBalance: e?.value }))}
                                                                optionList={['label']}
                                                                selectedLabel={'label'}
                                                            />
                                                        </LabelInput>
                                                    </div>
                                                    {
                                                        configFinancial?.useFinancialUsersBalance &&
                                                        <>
                                                            <div className='flex flex-col items-start justify-start'>
                                                                <LabelInput text={'Automatizar renovação de saldos: *'}>
                                                                    <InputAutoComplete
                                                                        id={'useAutoRenewBalance'}
                                                                        preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.useAutoRenewBalance)?.label || ''}
                                                                        data={boolOptions}
                                                                        onChange={(e) => setConfigFinancial(prev => ({ ...prev, useAutoRenewBalance: e?.value }))}
                                                                        optionList={['label']}
                                                                        selectedLabel={'label'}
                                                                    />
                                                                </LabelInput>
                                                            </div>
                                                            {
                                                                configFinancial?.useAutoRenewBalance &&
                                                                <>
                                                                    <LabelInput text={'Período de renovação: *'}>
                                                                        <InputAutoComplete
                                                                            id={'autoRenewPeriod'}
                                                                            preSelectedValue={renewPeriodOptions.find(op => op?.value === configFinancial?.autoRenewPeriod)?.label || ''}
                                                                            data={renewPeriodOptions}
                                                                            onChange={(e) => setConfigFinancial(prev => ({ ...prev, autoRenewPeriod: e?.value }))}
                                                                            optionList={['label']}
                                                                            selectedLabel={'label'}
                                                                        />
                                                                    </LabelInput>
                                                                    {
                                                                        configFinancial?.autoRenewPeriod === "year" &&
                                                                        <>
                                                                            <LabelInput text={'Informe o mês: *'}>
                                                                                <Input
                                                                                    id='autoRenewMonth'
                                                                                    charLimit={2}
                                                                                    max={12}
                                                                                    placeholder={'Ex: 1'}
                                                                                    type={'number'}
                                                                                    value={configFinancial?.autoRenewMonth || ''}
                                                                                    onChange={(e) => (e.target.value > 0 || !e.target.value) && setConfigFinancial(prev => ({ ...prev, autoRenewMonth: maxNumber(12, e.target.value) }))}
                                                                                ></Input>
                                                                            </LabelInput>
                                                                        </>
                                                                    }
                                                                    <LabelInput text={'Informe o dia: *'}>
                                                                        <Input
                                                                            id='autoRenewDay'
                                                                            charLimit={2}
                                                                            placeholder={'Ex: 1'}
                                                                            type={'number'}
                                                                            value={configFinancial?.autoRenewDay || ''}
                                                                            onChange={(e) => (e.target.value > 0 || !e.target.value) && setConfigFinancial(prev => ({ ...prev, autoRenewDay: maxNumber(31, e.target.value) }))}
                                                                        ></Input>
                                                                    </LabelInput>
                                                                    <div className='flex sm:w-auto gap-1 relative'>
                                                                        <LabelInput text={'Resetar saldo na renovação: *'}>
                                                                            <InputAutoComplete
                                                                                id={'autoRenewResetBalance'}
                                                                                preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.autoRenewResetBalance)?.label || ''}
                                                                                data={boolOptions}
                                                                                onChange={(e) => setConfigFinancial(prev => ({ ...prev, autoRenewResetBalance: e?.value }))}
                                                                                optionList={['label']}
                                                                                selectedLabel={'label'}
                                                                            />
                                                                        </LabelInput>
                                                                        <Tippy content={'Informe "Não" para que o saldo seja acumulado ou "Sim" para que na próxima renovação o saldo anterior seja zerado.'}
                                                                            arrow={true}
                                                                            animation='shift-away'
                                                                            placement='top'
                                                                            delay={100}
                                                                        >
                                                                            <div className='absolute right-0 text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                                                <FaInfo />
                                                                            </div>
                                                                        </Tippy>
                                                                    </div>
                                                                </>
                                                            }

                                                            <LabelInput text={'Saldo: *'}>
                                                                <InputAutoComplete
                                                                    id={'balanceType'}
                                                                    preSelectedValue={balanceTypeOptions.find(op => op?.value === configFinancial?.balanceType)?.label || ''}
                                                                    data={balanceTypeOptions}
                                                                    onChange={(e) => setConfigFinancial(prev => ({ ...prev, balanceType: e?.value }))}
                                                                    optionList={['label']}
                                                                    selectedLabel={'label'}
                                                                />
                                                            </LabelInput>
                                                            {
                                                                configFinancial?.balanceType !== "percentageOfSalary"
                                                                    ?
                                                                    <LabelInput text={'Valor do saldo: *'}>
                                                                        <CurrencyInput
                                                                            id={`balanceValue`}
                                                                            className='pl-2 border border-gray-400 border-opacity-75 
                                                                            outline-none
                                                                            sm:w-96 w-full
                                                                            text-sm sm:text-sm
                                                                            transition-all duration-200
                                                                            focus:shadow-borderShadow
                                                                            h-10 sm:h-8
                                                                            dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                                                            placeHolder={configFinancial.balanceValue ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(configFinancial.balanceValue) : 'R$ 0,00'}
                                                                            onChange={(e) => {
                                                                                setConfigFinancial(prev => ({ ...prev, balanceValue: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))
                                                                            }}
                                                                        />
                                                                    </LabelInput> :
                                                                    <LabelInput text={'Valor do saldo: (%) *'}>
                                                                        <Input
                                                                            id='balanceValue'
                                                                            charLimit={4}
                                                                            placeholder={'Ex: 1'}
                                                                            type={'number'}
                                                                            value={configFinancial?.balanceValue || ''}
                                                                            onChange={(e) => (e.target.value > 0 || !e.target.value) && setConfigFinancial(prev => ({ ...prev, balanceValue: maxNumber(100, e.target.value) }))}
                                                                        ></Input>
                                                                    </LabelInput>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => history.back()}>Voltar</Button>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <Button module={"associations:delete"} userData={userData} shadow={true} approval={false} onClick={() => { setShowDelete(true); setShowUniversalModal(true) }}>Excluir</Button>
                            {
                                !isCommunity ?
                                    <Button module={"associations:update"} userData={userData} shadow={true} approval={true} onClick={() => handleSubmit()}>Editar</Button> :
                                    <Tippy content={'Você não pode editar a associação comunidade, pois é nativa do sistema'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div>
                                            <Button module={"associations:update"} userData={userData} shadow={true} approval={true} disabled={true}>Editar</Button>
                                        </div>
                                    </Tippy>
                            }
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
}
