export function ObjectSortByProperty(property) {

    try {
        return (a, b) => {
            var stringA = a[property]?.toLowerCase();
            var stringB = b[property]?.toLowerCase();

            if (stringA < stringB) {
                return -1;
            }
            if (stringA > stringB) {
                return 1;
            }
            return 0;
        }

    } catch (error) {
        throw new Error(error)
    }
}