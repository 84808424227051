// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaCheck } from 'react-icons/fa'
import './login.css'
// img
import LoginLogo from '../../assets/logos/loginLogo.png'
import bgLogin from '../../assets/default_assets/bg-login.jpg'
// contexts
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { useThemeContext } from '../../contexts/themeContext'
//components
import { LabelInput } from '../../components/label/label.input'
import { Input } from '../../components/input/input'
import { Button } from '../../components/buttons/button.default'
import { DefaultLoader } from '../../components/loaders/defaultLoader'
//utils
import { colors } from '../../theme/colorsSchema'
import { validateFields } from '../../utils/form.validator'
import { maskCnpj } from '../../utils/mask/maskCnpj'
import { maskCep } from '../../utils/mask/maskCep'
import { maskPhone } from '../../utils/mask/maskPhone'

export function SignUpCustomerFreeTrial() {

    const navigate = useNavigate()
    const { api_adm } = useApiContext()

    const { screenX, screenY } = useScreenSizeContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [ customerAccount, setCustomerAccount ] = useState({
        app_name: undefined,
        cnpjcpf: undefined,
        name: undefined,
        address: undefined,
        city: undefined,
        state: undefined,
        zipCode: undefined,
        phoneArea: undefined,
        phoneNumber: undefined,
        firstName: undefined,
        lastName: undefined,
        birthday: undefined,
        email: undefined,
        password: undefined,
    })
    const [ loading, setLoading ] = useState(false)
    const [ executeWithSuccess, setExecuteWithSuccess ] = useState(false)

    const primaryDefaultLight = colors.find((color) => color.name === 'default').primaryColor
    const secondaryDefaultLight = colors.find((color) => color.name === 'default').primaryColor

    useEffect(() => {
        let dotPosition = window.location.hostname.indexOf('.')
        const thisCustomers = window.location.hostname.slice(0, dotPosition)

        if(thisCustomers != 'app'){
            navigate('/')
        }

        setShowNotificationModal(false)
        setDefaultColors()
    }, [])

    function setDefaultColors() {
        //lightColors
        document.documentElement.style.setProperty('--primaryDefaultLight', primaryDefaultLight)
        document.documentElement.style.setProperty('--secondaryDefaultLight', secondaryDefaultLight)
        document.documentElement.style.setProperty('--bgPrimaryLight', '#FFF')
        document.documentElement.style.setProperty('--bgSecondaryLight', '#ebf1f5')
        document.documentElement.style.setProperty('--titleGrayTextLight', '#FFF')
        document.documentElement.style.setProperty('--inputPlaceholderLight', '#8F8F8F')
        document.documentElement.style.setProperty('--primaryLightSuccess', '#64B694')
        //darkColors
        document.documentElement.style.setProperty('--hoverDark', '#2a2f36')
        document.documentElement.style.setProperty('--titleBlackTextDark', '#2a2f36')
        document.documentElement.style.setProperty('--titleGrayTextDark', '#C4C4C4')
        document.documentElement.style.setProperty('--primaryBorderDark', '#363E48')
        document.documentElement.style.setProperty('--secondaryBorderDark', '#444c56')
        document.documentElement.style.setProperty('--primaryDefaultDark', '')
        document.documentElement.style.setProperty('--secondaryDefaultDark', '')
    }

    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    async function handleSubmit(e){
        e.preventDefault()

        setLoading(true)
        try {

            const requiredFields = [
                { name: 'app_name', value: customerAccount.app_name, required: true, type: 'string'},
                { name: 'cnpjcpf', value: customerAccount?.cnpjcpf, required: true, type: 'string'},
                { name: 'name', value: customerAccount?.name, required: true, type: 'string'},
                { name: 'address', value: customerAccount?.address, required: true, type: 'string'},
                { name: 'city', value: customerAccount?.city, required: true, type: 'string'},
                { name: 'state', value: customerAccount?.state, required: true, type: 'string'},
                { name: 'zipCode', value: customerAccount?.zipCode, required: true, type: 'string'},
                { name: 'phoneArea', value: customerAccount?.phoneArea, required: true, type: 'string'},
                { name: 'phoneNumber', value: customerAccount?.phoneNumber, required: true, type: 'string'},
                { name: 'firstName', value: customerAccount?.firstName, required: true, type: 'string'},
                { name: 'lastName', value: customerAccount?.lastName, required: true, type: 'string'},
                { name: 'birthday', value: customerAccount?.birthday, required: true, type: 'string'},
                { name: 'email', value: customerAccount?.email, required: true, type: 'string'},
                { name: 'password', value: customerAccount?.password, required: true, type: 'string'},
            ]

            if(validateFields(requiredFields).length > 0){
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(error?.response?.data?.message || 'Um erro interno ocorreu, entre em contato com nossa equipe.')
                setLoading(false)
                return 
            }

            await api_adm.post('/api/adm/signUp/freeTrial', {
                app_name: customerAccount?.app_name,
                cnpjcpf: customerAccount?.cnpjcpf?.replace(/[^a-zA-Z0-9 ]/g, ''),
                name: customerAccount?.name,
                address: customerAccount?.address,
                city: customerAccount?.city,
                state: customerAccount?.state,
                zipCode: customerAccount?.zipCode.replace(/[^a-zA-Z0-9 ]/g, ''),
                phoneArea: customerAccount?.phoneArea,
                phoneNumber: customerAccount?.phoneNumber.replace(/[^a-zA-Z0-9 ]/g, ''),
                firstName: customerAccount?.firstName,
                lastName: customerAccount?.lastName,
                birthday: customerAccount?.birthday,
                email: customerAccount?.email,
                password: customerAccount?.password,
            })
            setLoading(false)
            setExecuteWithSuccess(true)
        } catch (error) {
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(error?.response?.data?.message || 'Um erro interno ocorreu, entre em contato com nossa equipe.')
        }
    }   

    return (
        <div 
            style={{ overflow: screenX < 600 ? 'hidden' : 'hidden', height: '100vh', backgroundImage: `linear-gradient(to bottom, rgba(${hexToRgb(primaryDefaultLight).r},${hexToRgb(primaryDefaultLight).g}, ${hexToRgb(primaryDefaultLight).b}, 0.80), rgba(${hexToRgb(secondaryDefaultLight).r},${hexToRgb(secondaryDefaultLight).g}, ${hexToRgb(secondaryDefaultLight).b}, .85)), url(${bgLogin})`}}
            className={`w-full bg-cover flex flex-col bg-primaryDefaultLight items-center justify-end sm:justify-center `}
        >
            <div className='bg-white w-full h-full lg:w-4/6 2xl:w-3/6 rounded-md shadow-borderShadow flex flex-col items-center justify-center gap-5 p-6 overflow-y-auto'>
                {
                    loading && 
                    <DefaultLoader/>
                }
                {
                    !loading && !executeWithSuccess &&
                    <>
                        <div className='flex flex-col sm:mt-16 2xl:mt-0'>
                            <div className='flex flex-col items-center justify-center max-h-32 max-w-28 overflow-hidden'>
                                <img className='mb-5' src={LoginLogo} width={screenX < 600 || screenY < 800 ? 250 : 200} />
                            </div>
                            <h1 className='text-center'><b>Teste grátis por 15 dias</b>. <br/>Comece hoje mesmo a inovar sua forma de atendimento!</h1>
                        </div>
                        <form className='w-full flex flex-col items-center justify-center gap-5' onSubmit={handleSubmit}>
                            <div className='w-full flex flex-col gap-3 border px-8 py-6'>
                                <p className='text-primaryDefaultLight text-base'>Dados da empresa</p>
                                <p className='text-sm text-inputPlaceholderLight'>https://app.agendarapido.com/{customerAccount.app_name}</p>
                                <div className='w-full flex flex-wrap items-start justify-start gap-3'>
                                    <LabelInput text={'Nome de exibição na URL: *'}>
                                        <Input 
                                            id='app_name' 
                                            type={'string'} 
                                            placeholder={'meuclube'}
                                            width={screenX < 760 ? '' : '64'} 
                                            
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const regex = /^[a-zA-Z]*$/;
                                                if (regex.test(value)) setCustomerAccount({...customerAccount, app_name: value})
                                            }} 
                                            value={customerAccount.app_name}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'CNPJ: *'}>
                                        <Input 
                                            id='cnpjcpf' 
                                            type={'string'} 
                                            placeholder={'00.000.000/0000-00'}
                                            charLimit={18}
                                            width={screenX < 760 ? '' : '64'} 
                                            onChange={(e) => setCustomerAccount({...customerAccount, cnpjcpf: maskCnpj(e.target.value)})} 
                                            value={customerAccount.cnpjcpf}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Nome da associação/clube: *'}>
                                        <Input 
                                            id='name' 
                                            type={'string'} 
                                            width={screenX < 760 ? '' : '64'} 
                                            onChange={(e) => setCustomerAccount({...customerAccount, name: e.target.value})} 
                                            value={customerAccount.name}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'CEP: *'}>
                                        <Input 
                                            id='zipCode' 
                                            type={'string'} 
                                            placeholder={'89204-636'}
                                            width={screenX < 760 ? '' : '64'} 
                                            charLimit={9}
                                            onChange={(e) => setCustomerAccount({...customerAccount, zipCode: maskCep(e.target.value)})} 
                                            value={customerAccount.zipCode}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Estado: *'}>
                                        <Input 
                                            id='state' 
                                            type={'string'} 
                                            placeholder={'SC'}
                                            width={screenX < 760 ? '' : '64'} 
                                            onChange={(e) => setCustomerAccount({...customerAccount, state: e.target.value})} 
                                            value={customerAccount.state}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Cidade: *'}>
                                        <Input 
                                            id='city' 
                                            type={'string'} 
                                            placeholder={'Joinville'}
                                            width={screenX < 760 ? '' : '64'} 
                                            onChange={(e) => setCustomerAccount({...customerAccount, city: e.target.value})} 
                                            value={customerAccount.city}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Logradouro: *'}>
                                        <Input 
                                            id='address' 
                                            type={'string'} 
                                            placeholder={'Rua Exemplo, 2048, Bairro Exemplo'}
                                            width={screenX < 760 ? '' : '64'} 
                                            onChange={(e) => setCustomerAccount({...customerAccount, address: e.target.value})} 
                                            value={customerAccount.address}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'DDD: *'}>
                                        <Input 
                                            id='phoneArea' 
                                            type={'number'} 
                                            placeholder={'47'}
                                            charLimit={3}
                                            width={screenX < 760 ? '' : '64'} 
                                            onChange={(e) => setCustomerAccount({...customerAccount, phoneArea: e.target.value})} 
                                            value={customerAccount.phoneArea}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Número telefone/celular: *'}>
                                        <Input 
                                            id='phoneNumber' 
                                            type={'string'} 
                                            placeholder={'9 9999-9999'}
                                            charLimit={10}
                                            width={screenX < 760 ? '' : '64'} 
                                            onChange={(e) => setCustomerAccount({...customerAccount, phoneNumber: maskPhone(e.target.value)})} 
                                            value={customerAccount.phoneNumber}
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                            <div className='w-full flex flex-col gap-3 border px-8 py-6'>
                                <p className='text-primaryDefaultLight text-base'>Dados de acesso <i className='text-xs text-inputPlaceholderLight'>(usuário)</i></p>
                                <div className='w-full flex flex-wrap items-start justify-start gap-3'>
                                    <LabelInput text={'Nome: *'}>
                                        <Input 
                                            id='firstName' 
                                            type={'string'} 
                                            width={screenX < 760 ? '' : '64'} 
                                            onChange={(e) => setCustomerAccount({...customerAccount, firstName: e.target.value})} 
                                            value={customerAccount.firstName}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Sobrenome: *'}>
                                        <Input 
                                            id='lastName' 
                                            type={'string'} 
                                            width={screenX < 760 ? '' : '64'} 
                                            onChange={(e) => setCustomerAccount({...customerAccount, lastName: e.target.value})} 
                                            value={customerAccount.lastName}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Data de nascimento: *'}>
                                        <Input 
                                            id='birthday' 
                                            type={'date'} 
                                            width={screenX < 760 ? '' : '64'} 
                                            onChange={(e) => setCustomerAccount({...customerAccount, birthday: e.target.value})} 
                                            value={customerAccount.birthday}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'E-mail: *'}>
                                        <Input 
                                            id='email' 
                                            type={'email'} 
                                            width={screenX < 760 ? '' : '64'} 
                                            onChange={(e) => setCustomerAccount({...customerAccount, email: e.target.value})} 
                                            value={customerAccount.email}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Senha: *'}>
                                        <Input 
                                            id='password' 
                                            type={'password'} 
                                            width={screenX < 760 ? '' : '64'} 
                                            onChange={(e) => setCustomerAccount({...customerAccount, password: e.target.value})} 
                                            value={customerAccount.password}
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                            <Button type={'submit'}> Criar conta e iniciar período de teste </Button>
                        </form>
                    </>
                }
                {
                    !loading && executeWithSuccess &&
                    <>
                        <div className='flex flex-col items-center 2xl:mt-0'>
                            <div className='flex flex-col items-center justify-center max-h-32 max-w-28 overflow-hidden mb-7'>
                                <img className='mb-5' src={LoginLogo} width={screenX < 600 || screenY < 800 ? 250 : 200} />
                            </div>
                            <h1 className='text-center text-2xl'><b>Sucesso ao criar conta</b>.</h1>
                            <p className='text-center'>Falta pouco para seu primeiro passo rumo a inovação...</p>
                            <br />
                            <h2 className='text-center text-lg'><b>Em seu e-mail chegou um link para ativação da conta.</b></h2>
                            <p className='text-center'>Clique no link e ative sua conta.</p>
                            <FaCheck className='mt-10 mb-5 text-2xl text-primaryDefaultLight'/>
                        </div>
                        <Button type={'button'} onClick={() => navigate(`/${customerAccount?.app_name || ''}`)}> Ir para login </Button>
                    </>
                }
            </div>
        </div>
    )
}
