export function validateFields(fields) {
    const errors = []

    
    fields.forEach((field) => {
        if (field?.required) {
            try {
                switch (field?.type) {
                    case 'string':
                        if (!field?.value) {
                            errors.push(field)
                            document.getElementById(field?.name).style.border = '1px solid red'
                        } else {
                            document.getElementById(field?.name).style.border = ''
                        }
                        break;
                    case 'object':
                        if (typeof (field.value) != 'object') {
                            errors.push(field)
                            document.getElementById(field?.name).style.border = '1px solid red'
                        } else {
                            document.getElementById(field?.name).style.border = ''
                        }
                        break;
                    case 'number':
                        if (!field?.value && field?.value != 0) {
                            errors.push(field)
                            document.getElementById(field?.name).style.border = '1px solid red'
                        } else {
                            document.getElementById(field?.name).style.border = ''
                        }
                        break;
                    default:
                        break;
                }
            } catch (error) {
                
            }
        }
    })
    console.log(errors)
    return errors
}