//hooks
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
import { NewTableDefault } from "../../../components/table/new.table.default";
//contexts
import { useAuth } from "../../../contexts/useAuth";

export function FinancialConfigPixQrCode() {
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [filter, setFilter] = useState({
        active: true
    })

    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full">
                    <Title text='Configuração de Pix - Qr code'></Title>
                    <Button module={"financialConfigPixQrCode:create"} userData={userData} shadow={true} onClick={() => navigate('/financial/config/configPixQrCode/create')}>+ Novo</Button>
                </div>
            </TitlePage>
            <Body>
                <div className="flex flex-col ga-4 w-full">
                    <div className='flex flex-row items-center justify-start w-full mb-6'>
                        <button 
                            className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${filter.active === true ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} 
                            onClick={() => setFilter({...filter, active: true})}
                        >Ativos</button>
                        <button 
                            className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${filter.active === false ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} 
                            onClick={() => setFilter({...filter, active: false})}
                        >Inativos</button>
                    </div>
                    <NewTableDefault
                        key={'table-configPixQrCode'}
                        endpoint="/api/v1/financial/configPixQrCode"
                        token={userData[0]?.token}
                        module="financial"
                        columns={[
                            {
                                description: 'ID',
                                field: 'id',
                                type: 'number'
                            },
                            {
                                description: 'Tipo',
                                field: 'pixType',
                                type: 'string'
                            },
                            {
                                description: 'Chave',
                                field: 'pixTypeValue',
                                type: 'string'
                            },
                            {
                                description: 'Beneficiário',
                                field: 'beneficiaryName',
                                type: 'string'
                            },
                            {
                                description: 'Identificador',
                                field: 'identifierCode',
                                type: 'string'
                            },
                            {
                                description: 'Criado em',
                                field: 'created_at',
                                type: 'date',
                            },
                        ]}
                        filters={filter}
                        onClickInTableLine={(row) => navigate(`/financial/config/configPixQrCode/${row.id}`)}
                    />
                </div>
            </Body>
        </Container>
    )
}
