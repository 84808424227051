import { Container } from "../../../components/container/container";
import { Body } from '../../../components/container/Body'
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { NewTableDefault } from "../../../components/table/new.table.default";
import { Button } from '../../../components/buttons/button.default'
import { useAuth } from "../../../contexts/useAuth";
import { useNavigate } from "react-router-dom";
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { useState } from "react";

export function FinancialAssociationsMonthlyPaymentConfig() {
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [filter, setFilter] = useState({
        active: true
    })

    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full items-center">
                    <Title text='Mensalidade por Associação'></Title>
                    <Button onClick={() => navigate('/financial/config/associationsMonthlyPayment/create')}>+ Novo</Button>   
                </div>
            </TitlePage>
            <Body hasFooter={true}>
                <div className='flex flex-row items-center justify-start w-full'>
                    <button 
                        className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 
                            flex flex-col items-center justify-center 
                            ${filter?.active ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} 
                            dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`
                        }
                        onClick={() => setFilter({...filter, active: true })}
                    >
                        Ativos
                    </button>
                    <button 
                        className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28
                            flex flex-col items-center justify-center 
                            ${!filter?.active ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} 
                            dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`
                        }
                        onClick={() => setFilter({...filter, active: false })}
                    >
                        Inativos
                    </button>
                </div>
                <div className="w-full">
                    <NewTableDefault 
                        key={'table-cashFlow'}
                        endpoint="/api/v1/financial/associationsMonthlyPaymentConfig"
                        token={userData[0]?.token}
                        module="financial"
                        columns={[
                            {
                                description: 'Associação',
                                field: 'associations_name',
                                type: 'string',
                            },
                            {
                                description: 'Cobrado por',
                                field: 'valueBy',
                                newFieldValue: (e) => {
                                    return e?.valueBy == 'salaryPercentage' 
                                        ? '% Salário'
                                        : 'Valor fixo'
                                }
                            },
                            {
                                description: 'Valor',
                                field: 'value',
                                newFieldValue: (e) => {
                                    return e?.valueBy == 'salaryPercentage' 
                                        ? `${e?.value} %`
                                        : e?.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                }
                            },
                            {
                                description: 'Envia Boleto',
                                field: 'sendInvoice',
                                type: 'boolean'
                            },
                            {
                                description: 'Dia de vencimento',
                                field: 'dueDay',
                                type: 'number'
                            },
                            {
                                description: 'Notifica por e-mail',
                                field: 'sendEmailToNotify',
                                type: 'boolean'
                            }
                        ]}
                        onClickInTableLine={(item) => navigate(`/financial/config/associationsMonthlyPayment/create/${item?.id}`)}                        
                        filters={filter}
                    />
                </div>
            </Body>
        </Container>
    )
}
