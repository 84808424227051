import React, { useState } from "react";
import { FaCheckCircle } from 'react-icons/fa'
import { InputCheckBox } from '../input/input.checkBox'
import { LabelInput } from "../label/label.input";

export function CardPlan({ key, plan, setSelectedPlan, selectedPlan, quantityOfActiveServices = 0, showCheckBox = false, removeButton = false }){
    const [data, setData] = useState(plan)
    const [value, setValue] = useState(data?.value + (quantityOfActiveServices * data?.valuePerAdditionalService))

    return (
        <div className={`p-6 w-80 min-h-[31rem] shadow-lg rounded-lg bg-gray-200/50 relative ${['Padrão', 'padrão', 'padrao'].includes(data?.name) && 'border border-gray-500'}`} key={key}>
            {
                ['Padrão', 'padrão', 'padrao'].includes(data?.name) ? 
                <p className="absolute top-2 left-3 text-xs border border-gray-300 px-2 bg-white rounded-md animate-pulse">
                    Mais popular
                </p>
                : <></>
            }
            <div className="flex flex-row w-full gap-2">
                {
                    showCheckBox && (
                        <LabelInput>
                            <InputCheckBox
                                onClick={() => setSelectedPlan(plan)}
                                value={selectedPlan?.id == plan?.id ? true : false}
                            />
                        </LabelInput>
                    )
                }
                <p className="w-full mb-4 text-lg font-medium select-none">{data?.name}</p>
            </div>
            <div className="flex flex-col w-full mb-4 select-none">
                <p className="text-zinc-400">A partir de</p>
                <div className="flex flex-row w-full items-center gap-1">
                    <p className="text-3xl font-semibold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data?.value)}</p>
                    <div className="text-zinc-400 flex flex-col -space-y-2">
                        <p>por</p>
                        <p>mês</p>
                    </div>
                </div>
                <p className="text-zinc-400 text-sm">Contempla {data?.quantityOfServices} espaços</p>
            </div>
            <div className="w-full mb-4 flex flex-col gap-1 select-none">
                <p className="text-zinc-400 text-sm">+{quantityOfActiveServices} espaços adicionais: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data?.valuePerAdditionalService)}/cada</p>
                <div className="flex flex-row gap-1 text-sm">
                    <p className="text-zinc-400">Totalizando:</p> 
                    <p className="font-semibold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)}</p>
                </div>                
            </div>
            {
                !removeButton ? 
                <div 
                    className="h-12 w-full bg-primaryDefaultLight text-white items-center justify-center flex rounded mb-6 cursor-pointer hover:brightness-90 select-none"
                    onClick={() => setSelectedPlan({
                        ...plan,
                        newValue: value
                    })}
                >
                    Assinar
                </div>
                : <></>
            }
            <p className="mt-2 text-zinc-400 text-sm select-none">Inclui:</p>
            <div className="flex flex-col gap-1 text-sm w-full select-none">
                {
                    data?.marketing_features?.map(mark => (
                    <div className="flex items-center gap-1 text-zinc-400">
                        <FaCheckCircle className="text-sm flex-shrink-0" />
                        <p className="leading-normal">{mark}</p>
                    </div>
                    ))
                }
            </div>
        </div>
    )
}