export const colors = [
    {name: 'orange', primaryColor:  '#f97316', secundaryColor: '#c2410c'},
    {name: 'slate', primaryColor:  '#94a3b8', secundaryColor: '#475569'},
    {name: 'red', primaryColor:  '#bf3737', secundaryColor: '#942c2c'},
    {name: 'green', primaryColor:  '#005c66', secundaryColor: '#00454d'},
    {name: 'emerald', primaryColor:  '#10b981', secundaryColor: '#047857'},
    {name: 'teal', primaryColor:  '#14b8a6', secundaryColor: '#0f766e'},
    {name: 'default', primaryColor:  '#0056E1', secundaryColor: '#011E42'},
    {name: 'blue', primaryColor:  '#3b82f6', secundaryColor: '#1d4ed8'},
    {name: 'indigo', primaryColor:  '#6366f1', secundaryColor: '#4338ca'},
    {name: 'purple', primaryColor:  '#8300b3', secundaryColor: '#700099'},
    {name: 'rose', primaryColor:  '#f43f5e', secundaryColor: '#be123c'},
    {name: 'yellow', primaryColor:  '#dbc327', secundaryColor: '#ad9a1f'},
]