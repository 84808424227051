//hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import { FaEdit } from "react-icons/fa";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { TableDefault } from '../../../components/table/table.default'
import { TableMobile } from '../../../components/table/table.mobile'
import { Button } from "../../../components/buttons/button.default";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
//services
import { formattTimeToShow } from "../../../services/api/callAPIsFunctions/defaultCalls.api";
//contexts
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function Associations() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const { defaultCallsAuth } = useApiContext()
    const [filteredAssociations, setFilteredAssociations] = useState(true)
    const { userData } = useAuth()
    const [associationsActiveButton, setAssociationsActiveButton] = useState(1)
    const {
        setShowNotificationModal, setShowNotificationModalSuccess,
    } = useThemeContext()
    const [associationsList, setAssociationsList] = useState([
        { "id": 1, 'name': 'Neotec' },
        { "id": 2, 'name': 'Colley' },
        { "id": 3, 'name': 'Ademilar' },
    ])
    const tableThead = [
        { 
            name: 'Código', 
            original_name: 'id' 
        },
        { 
            name: 'Nome', 
            original_name: 'name' 
        },
        {
            name: 'Detalhe',
            original_name: 'detail' 
        },
        { 
            name: 'Criado em', 
            original_name: 'created_at' 
        }
    ]

    useEffect(() => {

        setLoading(true)
        async function getData() {

            const associationsGet = await defaultCallsAuth.getAssociations(userData[0]?.token)
            if (associationsGet.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(associationsGet.data)
                return
            }
            setShowNotificationModalSuccess(true)
            setAssociationsList(associationsGet.data.map(association => {
                return { 
                    ...association, 
                    created_at: formattTimeToShow(association.created_at),
                    detail: [1, 2].includes(association.id) ? 'Padrão do sistema' : '-'
                }
            }))
            setLoading(false)
        }

        getData()

    }, [])

    useEffect(() => {

        handleFilterActiveAssociations(1)

    }, [associationsList])

    function handleFilterActiveAssociations(status) {

        const newAssociationsList = associationsList.filter((association) => {
            if (status === 1 && association?.active) {
                return association
            }
            if (status === 0 && !association?.active) {
                return association
            }
            if (status === undefined) {
                return association
            }
        })

        status !== undefined ? setFilteredAssociations(newAssociationsList) : setFilteredAssociations(associationsList)
        status > 0 ? setAssociationsActiveButton(1) : setAssociationsActiveButton(0)
    }

    function handleEdit(e) {
        navigate(`/associationsEdit/${e.id}`)
    }

    return (

        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full">
                    <Title text='Tipos e Associações'></Title>
                    <Button shadow={true} onClick={() => navigate('/associationsCreate')}>+ Novo</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                        <DefaultLoader />
                        :
                        <div className="flex flex-col ga-4 w-full">
                            <div className='flex flex-row items-center justify-start w-full mb-6'>
                                <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${associationsActiveButton === 1 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveAssociations(1)}>Ativos</button>
                                <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${associationsActiveButton === 0 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveAssociations(0)}>Inativos</button>
                            </div>
                            <div id='associationsTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow overflow-y-auto rounded-lg'>
                                <TableDefault
                                    rowTitle={'Clique para editar'}
                                    onClick={(e) =>
                                        navigate(`/associationsEdit/${e.id}`)}
                                    title={tableThead}
                                    data={filteredAssociations}
                                    collumns={["id","name", 'detail',"created_at"]}
                                />
                            </div>
                            <div id='associationsTableContentMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                <TableMobile
                                    onClick={(e) => e.func.execute(e.data)}
                                    title={tableThead}
                                    data={filteredAssociations}
                                    collumns={["id","name", 'detail', "created_at"]}
                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                />
                            </div>
                        </div>
                }
            </Body>
        </Container>
    )
}