//hooks
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { Input } from "../../../components/input/input";
import { FinancialBillsByConciliation } from "./financialBillsByConciliation";
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { FaBan, FaCheck, FaExternalLinkAlt, FaSearch, FaTimes, FaTrash } from "react-icons/fa";
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { NewTableDefault } from '../../../components/table/new.table.default'
import Tippy from "@tippyjs/react";
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
//contexts
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
// utils
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { validateFields } from "../../../utils/form.validator";
import { Footer } from "../../../components/footer/Footer";
import { FinancialExistingBillsByConciliation } from "./financialExistingBillsByConciliations";
import { CiBank } from "react-icons/ci";
import CurrencyInput from "../../../components/input/inputCurrencyPtBr";
import { InputDinamicAutoComplete } from "../../../components/input/input.dinamic.autocomplete";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function FinancialConciliationsTransactions() {

    const { api_financial, defaultCallsFinancial } = useApiContext()
    const { conciliations_id } = useParams()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [accountTransactions, setAccountTransactions] = useState({})
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true)
    const [showCreate, setShowCreate] = useState(false)
    const [showSendExists, setShowSendExists] = useState(false)
    const [transactionTemp, setTransactionTemp] = useState('')
    const [paymentMethodList, setPaymentMethodList] = useState([])
    const [loadingConciliation, setLoadingConciliation] = useState(false)
    const [filter, setFilter] = useState({})
    const [showModalTable, setShowModalTable] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [dataSendEdit, setDataSendEdit] = useState('')
    const [loadingEdit, setLoadingEdit] = useState(false)
    const [accountsTransfers, setAccountsTransfers] = useState([])
    const [accountsList, setAccountsList] = useState([])
    const [transfersConciliated, setTransfersConciliated] = useState([])

    useEffect(() => {

        async function getData() {
            setLoading(true)


            const getFinancialCategoriesAPI = await defaultCallsFinancial.getFinancialCategories(userData[0]?.token)
            if (getFinancialCategoriesAPI.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(getFinancialCategoriesAPI.data)
            }
            setCategories(getFinancialCategoriesAPI.data)

            const paymentMethodAPI = await defaultCallsFinancial.getFinancialPaymentMethod(userData[0]?.token)
            if (paymentMethodAPI.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(paymentMethodAPI.data)
            }
            setPaymentMethodList(paymentMethodAPI?.data)
             
        }

        async function getAccountTransactionsData() {
            try {
                const getAccountTransactions = await api_financial.get(`/api/v1/financial/conciliations/${conciliations_id}`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })

                const formatTransactions = getAccountTransactions.data?.data[0]?.transactions?.map(tran => {
                    return {
                        ...tran,
                        selectedOption: 1
                    }
                })
                setAccountTransactions({ ...getAccountTransactions.data?.data[0], transactions: formatTransactions })

                const accountsData = await defaultCallsFinancial.getAccounts(userData[0]?.token)
                setAccountsList(accountsData.data)

                const accountsTransfersAPI = await api_financial.get(`/api/v1/financial/accountsTransfers?financialAccounts_id=${getAccountTransactions.data?.data[0]?.financialAccounts_id}`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })

                setAccountsTransfers(accountsTransfersAPI?.data?.data?.map(transfer => {
                    const outAccount = accountsData.data?.find(accountData => accountData.id == transfer.out_account_id)?.name
                    const inAccount = accountsData.data?.find(accountData => accountData.id == transfer.in_account_id)?.name
                    return {
                        ...transfer,
                        outAccount,
                        inAccount
                    }
                }))

                setLoading(false)
            } catch (error) {
                const formatedError = responseError(error)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText(formatedError?.length > 0 ? formatedError : 'Erro inesperado')
            }
        }

        getData()
        getAccountTransactionsData()

    }, [])

    useEffect(() => {
        if (!showUniversalModal) {
            setFilter({})
            setShowModalTable(false)
            setShowModalEdit(false)
            setDataSendEdit('')
            setLoadingEdit(false)
        }
    }, [showUniversalModal])

    useEffect(() => {
        if (transactionTemp) {
            setAccountTransactions(prev => ({
                ...prev,
                transactions: accountTransactions?.transactions?.map(tran => {
                    if (tran?.id == transactionTemp?.id) {
                        return transactionTemp
                    } else {
                        return tran
                    }
                })
            }))
        }

        if (!showCreate || !showSendExists) {
            setTransactionTemp('')
        }
    }, [showCreate, showSendExists])

    useEffect(() => {
        calculateTotal()
    }, [dataSendEdit])

    async function handleSubmit(transaction) {
        setLoadingConciliation(true)

        if (transaction?.selectedOption == 2 && !transaction?.bill){

            const requiredFields = [
                { name: `transfers_${transaction?.id}`, value: transaction?.conciliationsTransactions, required: true, type: 'object' }
            ]

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setLoadingConciliation(false)
                accountTransactions.transactions = accountTransactions?.transactions?.map(tran => {
                    if (tran?.id == transaction?.id) {
                        return {
                            ...transaction,
                            loading: false
                        }
                    } else {
                        return tran
                    }
                })
                setAccountTransactions({ ...accountTransactions })
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            const transfer = transaction?.conciliationsTransactions
            try {
                await api_financial.post(`/api/v1/financial/accountsTransfers/conciliation`, {
                    id: transfer?.id,
                    conciliationsTransactions_id: transaction?.id,
                    incomeOrExpense: transaction?.incomeOrExpense
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                
                accountTransactions.transactions = accountTransactions?.transactions?.map(tran => {
                    if (tran?.id == transaction?.id) {
                        return {
                            ...transaction,
                            isConciliated: true,
                            loading: false
                        }
                    } else {
                        return tran
                    }
                })
                setAccountTransactions({ ...accountTransactions })
                setTransfersConciliated([...transfersConciliated, transfer?.id])

                setLoadingConciliation(false)
                setShowNotificationModalSuccess(true)
                setShowNotificationModal(true)
                setShowNotificationModalText("Conciliação realizada com sucesso")
                return
    
            } catch (error) {
                accountTransactions.transactions = accountTransactions?.transactions?.map(tran => {
                    if (tran?.id == transaction?.id) {
                        return {
                            ...transaction,
                            loading: false
                        }
                    } else {
                        return tran
                    }
                })
                setAccountTransactions({ ...accountTransactions })
                if (responseError(error).length > 0) {
                    setLoadingConciliation(false)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoadingConciliation(false)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        const bill = transaction?.bill
        const data = {
            entryDate: bill?.entryDate ? bill?.entryDate : transaction?.entryDate,
            dueDate: bill?.dueDate ? bill?.dueDate : transaction?.entryDate,
            description: bill?.description ? bill?.description : undefined,
            clientOrSupplierOrigin: bill?.clientOrigin ? bill?.clientOrigin : transaction?.client?.linked,
            clientOrSupplier_id: bill?.client_id ? bill?.client_id : transaction?.client?.linked_id,
            incomeOrExpense: bill?.incomeOrExpense ? bill?.incomeOrExpense : transaction?.incomeOrExpense,
            totalValue: bill?.totalValue ? bill.totalValue : transaction?.value,
            externalCode: bill?.externalCode ? bill?.externalCode : undefined,
            paymentCondition: 1, // À vista
            numberOfInstallments: 1,
            paymentStatus: 1, // Pago
            paymentDate: bill?.entryDate ? bill?.entryDate : transaction?.entryDate,
            discount: bill?.discount ? bill.discount : undefined,
            fees: bill?.fees ? bill.fees : undefined,
            financialAccounts_id: bill?.financialAccounts_id ? bill?.financialAccounts_id : accountTransactions?.financialAccounts_id,
            financialPaymentMethod_id: bill?.financialPaymentMethod?.id ? bill?.financialPaymentMethod?.id : transaction?.financialPaymentMethod?.id,
            financialConciliationsTransactions_id: transaction?.id,
            apportionments: bill?.apportionments ? bill?.apportionments.map(app => {
                return {
                    financialCategories_id: app?.financialCategories_id,
                    financialCostCenter_id: app?.financialCostCenter_id
                        ? app?.financialCostCenter_id
                        : undefined,
                    percentage: app?.percentage,
                    linked: app?.linked
                        ? app?.linked
                        : undefined,
                    linked_id: app?.linked_id
                        ? app?.linked_id
                        : undefined
                }
            }) : [{
                financialCategories_id: transaction?.category?.id,
                financialCostCenter_id: undefined,
                percentage: 100,
                linked: undefined,
                linked_id: undefined
            }],
            compositions: []
        }

        if (transaction?.isDiff && transaction?.selectedSends?.length) {
            const selectedSends = transaction?.selectedSends?.map(item => { return { id: item?.id } })
            try {
                const conciliationSendExists = await api_financial.post('/api/v1/financial/cashFlow/conciliationsSendExists', {
                    selectedSends,
                    bill: data
                }, {
                    headers: {
                        authorization: `Bearer ${userData[0]?.token}`
                    }
                })

                accountTransactions.transactions = accountTransactions?.transactions?.map(tran => {
                    if (tran?.id == transaction?.id) {
                        return {
                            ...transaction,
                            financialBills_id: conciliationSendExists?.data?.data?.id,
                            isConciliated: true,
                            loading: false
                        }
                    } else {
                        return tran
                    }
                })
                setAccountTransactions({ ...accountTransactions })

            } catch (error) {
                accountTransactions.transactions = accountTransactions?.transactions?.map(tran => {
                    if (tran?.id == transaction?.id) {
                        return {
                            ...transaction,
                            loading: false
                        }
                    } else {
                        return tran
                    }
                })
                setAccountTransactions({ ...accountTransactions })
                if (responseError(error).length > 0) {
                    setLoadingConciliation(false)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoadingConciliation(false)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

            setLoadingConciliation(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Conciliação realizada com sucesso")

            return
        }

        if (!transaction?.isDiff && transaction?.selectedSends?.length) {
            const selectedSends = transaction?.selectedSends?.map(item => { return { id: item?.id } })
            try {
                const conciliationSendExists = await api_financial.post('/api/v1/financial/cashFlow/conciliationsSendExists', {
                    selectedSends,
                    entryDate: transaction?.entryDate,
                    financialAccounts_id: transaction?.financialAccounts_id,
                    financialConciliationsTransactions_id: transaction?.id
                }, {
                    headers: {
                        authorization: `Bearer ${userData[0]?.token}`
                    }
                })

                accountTransactions.transactions = accountTransactions?.transactions?.map(tran => {
                    if (tran?.id == transaction?.id) {
                        return {
                            ...transaction,
                            financialBills_id: conciliationSendExists?.data?.data?.id,
                            isConciliated: true,
                            loading: false
                        }
                    } else {
                        return tran
                    }
                })
                setAccountTransactions({ ...accountTransactions })

            } catch (error) {
                accountTransactions.transactions = accountTransactions?.transactions?.map(tran => {
                    if (tran?.id == transaction?.id) {
                        return {
                            ...transaction,
                            loading: false
                        }
                    } else {
                        return tran
                    }
                })
                setAccountTransactions({ ...accountTransactions })
                if (responseError(error).length > 0) {
                    setLoadingConciliation(false)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoadingConciliation(false)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

            setLoadingConciliation(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Conciliação realizada com sucesso")

            return
        }

        const urlApi = '/api/v1/financial/bills/single'

        if (!transaction?.bill) {
            const requiredFields = [
                { name: `description_${transaction?.id}`, value: transaction?.newDescription, required: true, type: 'string' },
                { name: `category_${transaction?.id}`, value: transaction?.category, required: true, type: 'object' },
                { name: `paymentMethod_${transaction?.id}`, value: transaction?.financialPaymentMethod, required: true, type: 'object' },
                { name: `client_${transaction?.id}`, value: transaction?.client, required: true, type: 'object' }

            ]

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setLoadingConciliation(false)
                accountTransactions.transactions = accountTransactions?.transactions?.map(tran => {
                    if (tran?.id == transaction?.id) {
                        return {
                            ...transaction,
                            loading: false
                        }
                    } else {
                        return tran
                    }
                })
                setAccountTransactions({ ...accountTransactions })
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }
        }

        try {
            const createdBill = await api_financial.post(urlApi, data, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })

            accountTransactions.transactions = accountTransactions?.transactions?.map(tran => {
                if (tran?.id == transaction?.id) {
                    return {
                        ...transaction,
                        financialBills_id: createdBill?.data?.data?.id,
                        isConciliated: true,
                        loading: false
                    }
                } else {
                    return tran
                }
            })
            setAccountTransactions({ ...accountTransactions })

        } catch (error) {
            accountTransactions.transactions = accountTransactions?.transactions?.map(tran => {
                if (tran?.id == transaction?.id) {
                    return {
                        ...transaction,
                        loading: false
                    }
                } else {
                    return tran
                }
            })
            setAccountTransactions({ ...accountTransactions })
            if (responseError(error).length > 0) {
                setLoadingConciliation(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoadingConciliation(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setLoadingConciliation(false)
        setShowNotificationModalSuccess(true)
        setShowNotificationModal(true)
        setShowNotificationModalText("Conciliação realizada com sucesso")
    }

    function calculateTotal() {
        const fees = dataSendEdit?.fees ? Number(dataSendEdit?.fees) : 0
        const discount = dataSendEdit?.discount ? Number(dataSendEdit?.discount) : 0
        const calculate = Number((dataSendEdit?.value + fees) - discount).toFixed(2)
        return <p className="text-primaryDefaultLight text-md pl-2">
            Total: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(calculate)}
        </p>
    }

    async function handleEditSend() {
        setLoadingEdit(true)
        try {
            await api_financial.put(`/api/v1/financial/cashFlow/${dataSendEdit?.id}`, {
                paymentStatus: dataSendEdit?.paymentStatus,
                discount: dataSendEdit?.discount ? Number(dataSendEdit?.discount) : undefined,
                fees: dataSendEdit?.fees ? Number(dataSendEdit?.fees) : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Lançamento atualizado com sucesso")
            // setLoadingEdit(false)
            return

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoadingEdit(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoadingEdit(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleRemoveConciliationsOfTransfers(transaction){
        const { incomeOrExpense, conciliationsTransactions } = transaction

        try {        
            await api_financial.put(`api/v1/financial/accountsTransfers/removeConciliation/${conciliationsTransactions?.id}`, {
                whats_remove: incomeOrExpense === 'D' ? 'out_conciliationsTransactions_id' : 'in_conciliationsTransactions_id'
            }, {
                headers :{
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            accountTransactions.transactions = accountTransactions?.transactions?.map(tran => {
                if (tran?.id == transaction?.id) {
                    return {
                        ...transaction,
                        isConciliated: false,
                        showRemoveConciliation: false,
                        conciliationsTransactions: null
                    }
                } else {
                    return tran
                }
            })
            setAccountTransactions({ ...accountTransactions })
            const filterConciliateds = transfersConciliated?.filter(cons => cons != conciliationsTransactions?.id)
            setTransfersConciliated([...filterConciliateds])
            
            setLoadingConciliation(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Transferência desconciliada com sucesso")
            return

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoadingConciliation(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoadingConciliation(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    return (
        <>
            {
                showUniversalModal && showModalTable ?
                    <ScreenModal height={500} width={screenX <= 1024 ? 900 : 1000} title={'Fluxo de caixa'} alignTable="start">
                        <div className="p-4 w-full h-full">
                            {
                                Object.values(filter).length > 0 &&
                                <NewTableDefault
                                    key={'table-cashFlow'}
                                    endpoint="/api/v1/financial/cashFlow"
                                    token={userData[0]?.token}
                                    module="financial"
                                    columns={[
                                        {
                                            description: 'Cliente|Fornecedor',
                                            field: 'clientOrSupplier_name',
                                            type: 'string',
                                            newFieldValue: (line) => {
                                                if(line.financialClientsAndSuppliers_id){
                                                    return `${line.financialClientsAndSuppliers_name} (Cliente: ${line.financialClientsAndSuppliers_id})`
                                                }
                                                if(line.client_users_id){
                                                    return `${line.client_users_name} (Usuário: ${line.client_users_id})`
                                                }
                                                if(line.agreements_id){
                                                    return `${line.agreements_name} (Convênio: ${line.agreements_id})`
                                                }
                                                return '-'
                                            }
                                        },
                                        { //Vlr. Recebido
                                            description: 'Recebido|Pago',
                                            type: 'money',
                                            field: 'finalValue',
                                            withOrderBy: true,
                                            newFieldValue: function (line) {
                                                if (line?.paymentStatus == 1) {
                                                    return line?.finalValue
                                                }
                                                return 0
                                            }
                                        },
                                        { //Parcela
                                            description: 'Parcela',
                                            type: 'string',
                                            field: 'numberOfInstallments',
                                            withOrderBy: true,
                                            newFieldValue: function (line) {
                                                if (line?.paymentCondition == 1) {
                                                    return 'À vista'
                                                }
                                                return `${line?.installment} de ${line?.numberOfInstallments}`
                                            }
                                        },
                                        { //Data vencimento
                                            description: 'Vencimento',
                                            field: 'dueDate',
                                            type: 'date',
                                            withOrderBy: true,
                                        },
                                        { //Data do pagamento
                                            description: 'Pagamento',
                                            field: 'paymentDate',
                                            type: 'date',
                                            withOrderBy: true,
                                        },
                                        { //Tipo
                                            description: 'Tipo',
                                            type: 'string',
                                            field: 'entryType',
                                            withOrderBy: true,
                                            newFieldValue: function (line) {
                                                return line.entryType == 'sales' ? 'Venda'
                                                    : line.entryType == 'shopping' ? 'Compra'
                                                        : 'Avulso'
                                            }
                                        },
                                        { //Status
                                            description: 'Status',
                                            type: 'string',
                                            field: 'paymentStatus',
                                            withOrderBy: true,
                                            newFieldValue: function (line) {
                                                let status = <a className="text-yellow-500 text-sm">Pendente</a>
                                                if (line?.paymentStatus == 1) {
                                                    status = <a className="text-green-700 text-sm">Pago</a>
                                                }
                                                if (line?.paymentStatus == 2) {
                                                    status = <a className="text-red-700 text-sm">Cancelado</a>
                                                }
                                                return (
                                                    <div className="flex items-center justify-center gap-1">
                                                        {status}
                                                        {
                                                            line?.financialConciliationsTransactions_id &&
                                                            <Tippy content={'Conciliado'}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='top'
                                                                delay={100}
                                                            >
                                                                <div>
                                                                    <CiBank />
                                                                </div>
                                                            </Tippy>
                                                        }
                                                    </div>
                                                )
                                            }
                                        },
                                        { //Origem
                                            description: 'Origem',
                                            type: 'string',
                                            field: 'payments_id',
                                            withOrderBy: true,
                                            newFieldValue: function (line) {
                                                return line?.payments_id
                                                    ? line.applicationModules_name
                                                    : 'Manual'
                                            }
                                        }
                                    ]}
                                    mapApiData={['data', 'sends']}
                                    filters={filter}
                                    usePagination={true}
                                    mapQuantityOfRegisters={['quantity']}
                                    mapTotalPages={['totalPages']}
                                    onClickInTableLine={(row) => {
                                        setShowUniversalModal(false)
                                        setShowModalTable(false)
                                        navigate(`/financial/cashFlowEdit/${row?.id}`)
                                    }}
                                />
                            }
                        </div>
                    </ScreenModal>
                    : showUniversalModal && showModalEdit &&
                    <ScreenModal title={'Editar lançamento'} alignTable="start">
                        <div className="p-4 w-full h-full flex flex-col gap-2">
                            <LabelInput text={'Valor'}>
                                <CurrencyInput
                                    id="value"
                                    disabled
                                    className='pl-2 border border-gray-400 border-opacity-75 
                                outline-none
                                sm:w-96 w-full
                                text-sm sm:text-sm
                                transition-all duration-200
                                focus:shadow-borderShadow
                                disabled:bg-gray-200 placeholder:text-black
                                h-10 sm:h-8
                                dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                    placeholder={dataSendEdit?.value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dataSendEdit?.value) : 'R$ 0,00'}
                                />
                            </LabelInput>
                            <LabelInput text={'Juros'}>
                                <CurrencyInput
                                    id="fees"
                                    className='pl-2 border border-gray-400 border-opacity-75 
                                outline-none
                                sm:w-96 w-full
                                text-sm sm:text-sm
                                transition-all duration-200
                                focus:shadow-borderShadow
                                h-10 sm:h-8
                                dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                    placeholder={dataSendEdit?.fees ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dataSendEdit?.fees) : 'R$ 0,00'}
                                    onChange={(e) => {
                                        setDataSendEdit(prev => ({ ...prev, fees: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))
                                    }}
                                />
                            </LabelInput>
                            <LabelInput text={'Desconto'}>
                                <CurrencyInput
                                    id="discount"
                                    className='pl-2 border border-gray-400 border-opacity-75 
                                outline-none
                                sm:w-96 w-full
                                text-sm sm:text-sm
                                transition-all duration-200
                                focus:shadow-borderShadow
                                h-10 sm:h-8
                                dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                    placeholder={dataSendEdit?.discount ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dataSendEdit?.discount) : 'R$ 0,00'}
                                    onChange={(e) => {
                                        setDataSendEdit(prev => ({ ...prev, discount: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))
                                    }}
                                />
                            </LabelInput>
                            <div className="mt-8 flex flex-row justify-between items-center w-full">
                                <div className='flex flex-row items-center justify-center'>
                                    {calculateTotal()}
                                </div>
                                <Button onClick={() => handleEditSend()}>Editar</Button>
                            </div>
                        </div>
                    </ScreenModal>
            }
            <Container>
                <TitlePage>
                    <div className="flex flex-row justify-between w-full">
                        <Title text={`Conciliações`} />
                    </div>
                </TitlePage>
                {
                    showCreate ?
                        <FinancialBillsByConciliation
                            transaction={{
                                ...transactionTemp,
                                financialAccounts_id: accountTransactions?.financialAccounts_id
                            }}
                            setShow={setShowCreate}
                            setTransaction={setTransactionTemp}
                        />
                        : showSendExists ?
                            <FinancialExistingBillsByConciliation
                                transaction={{
                                    ...transactionTemp,
                                    startDate: accountTransactions?.startDate,
                                    finishDate: accountTransactions?.finishDate,
                                    financialAccounts_id: accountTransactions?.financialAccounts_id
                                }}
                                setShow={setShowSendExists}
                                setTransaction={setTransactionTemp}
                                setShowModalEdit={setShowModalEdit}
                                setDataSendEdit={setDataSendEdit}
                                loadingEdit={loadingEdit}
                            />
                            :
                            <>
                                <Body>
                                    <div className="hidden md:flex flex-col ga-4 w-full">
                                        {
                                            loading ?
                                                <div className="w-full flex items-center justify-center">
                                                    <DefaultLoader />
                                                </div>
                                                :
                                                <>
                                                    <div className="flex flex-col text-sm mb-2 text-zinc-500 dark:text-titleGrayTextDark">
                                                        <div>
                                                            <p><b>Banco:</b> {accountTransactions?.name}</p>
                                                            <p><b>Conta:</b> {accountTransactions?.accountNumber}</p>
                                                            <p><b>Agência:</b> {accountTransactions?.accountAgency}</p>
                                                            <p><b>Período:</b> {moment(accountTransactions?.startDate)?.utc(false)?.format('DD/MM/YYYY')} até {moment(accountTransactions?.finishDate)?.utc(false)?.format('DD/MM/YYYY')}</p>
                                                            <p><b>Saldo Final:</b> {accountTransactions?.finalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="flex flex-col w-full items-start justify-start">
                                                        <div className="grid grid-cols-3 gap-6 mt-4 w-[80%]">
                                                            <p className="text-primaryDefaultLight ">Lançamentos importados</p>
                                                            <p className=""></p>
                                                            <p className="text-primaryDefaultLight ">Lançamentos a cadastrar</p>
                                                        </div>
                                                        <div className="flex flex-col items-start justify-start mt-4 w-full gap-6">
                                                            {
                                                                accountTransactions?.transactions?.map((tran, index) => {
                                                                    const formatDate = moment(tran?.entryDate).locale('pt-br').format('dddd')
                                                                    const fees = tran?.bill?.fees ? tran?.bill?.fees : 0
                                                                    const discount = tran?.bill?.discount ? tran?.bill?.discount : 0
                                                                    const calculateValue = Number((tran?.bill?.totalValue + fees) - discount)
                                                                    const transfersExists = accountsTransfers?.find(item => item?.in_conciliationsTransactions_id === tran?.id || item?.out_conciliationsTransactions_id === tran?.id) || (tran?.isConciliated && tran?.conciliationsTransactions)
                                                                    const filterTransfers = accountsTransfers?.filter(acc =>
                                                                        acc?.referenceDate == tran?.entryDate 
                                                                        && (tran?.incomeOrExpense == 'R' ? acc?.in_account_id : acc?.out_account_id) == accountTransactions?.financialAccounts_id
                                                                        && (tran?.incomeOrExpense == 'R' ? !acc?.in_conciliationsTransactions_id : !acc?.out_conciliationsTransactions_id)
                                                                        && !transfersConciliated?.includes(acc?.id)
                                                                    )?.map(item => {
                                                                        let inputValue
                                                                        if (tran?.incomeOrExpense == 'R') {
                                                                            inputValue = `${item?.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - ${item?.outAccount}`
                                                                        }
                                                                        if (tran?.incomeOrExpense == 'D') {
                                                                            inputValue = `${item?.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - ${item?.inAccount}`
                                                                        }
                                                                        return { ...item, inputValue }
                                                                    })
                                                                    return (
                                                                        <div className={`grid grid-cols-3 gap-6 w-[80%] ${tran.loading ? 'pointer-events-none animate-pulse' : ''}`} key={index}>
                                                                            <div className={`${tran?.isConciliated ? 'h-48 opacity-70 bg-gray-200' : tran?.bill && !tran?.byGateway && !tran?.selectedSends?.length ? 'h-56' : !tran?.isDiff && tran?.selectedSends?.length ? 'h-44' : 'h-80'} flex flex-col w-[32rem] border border-gray-300 rounded-md gap-1 shadow-md`}>
                                                                                <div className="flex flex-row items-center justify-between p-2">
                                                                                    <div className="flex flex-row items-center justify-center gap-3">
                                                                                        <p className="font-semibold">{moment(tran?.entryDate).format('DD/MM/YYYY')}</p>
                                                                                        <p>{formatDate.charAt(0).toUpperCase() + formatDate.slice(1)}</p>
                                                                                    </div>
                                                                                    <p className={`text-lg font-semibold ${tran?.incomeOrExpense == 'R' ? 'text-green-500' : 'text-red-500'}`}>
                                                                                        {
                                                                                            tran?.incomeOrExpense == 'R'
                                                                                                ? '+' + tran?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                                                                : '-' + tran?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <hr />
                                                                                <p className="p-2 mt-4 text-start max-h-52 h-full overflow-auto">{tran?.description}</p>
                                                                                <hr />
                                                                                {
                                                                                    tran?.isConciliated ?
                                                                                        <p className="p-2 text-base font-semibold flex flex-row items-center gap-1 text-primaryDefaultLight">
                                                                                            Conciliado
                                                                                        </p>
                                                                                        :
                                                                                        <p className="p-2 text-base font-semibold flex flex-row items-center gap-1 text-red-500">
                                                                                            <FaTimes className="text-sm" />
                                                                                            Não conciliado
                                                                                        </p>
                                                                                }
                                                                            </div>
                                                                            <div className="flex items-center w-[32rem] justify-center h-full">
                                                                                {
                                                                                    loadingConciliation && !tran?.isConciliated ?
                                                                                        <Button height={12} cursorNotAllowed={true} onClick={() => { }}>Conciliar</Button>
                                                                                        : tran?.isConciliated ?
                                                                                            <p className="text-green-500 text-base font-semibold flex flex-row items-center gap-1">
                                                                                                <FaCheck className="text-base" />
                                                                                                Conciliado
                                                                                            </p>
                                                                                            :
                                                                                            <Button
                                                                                                height={12}
                                                                                                onClick={() => {
                                                                                                    tran.loading = true
                                                                                                    setAccountTransactions({ ...accountTransactions })
                                                                                                    handleSubmit(tran)
                                                                                                }}
                                                                                            >
                                                                                                <p className="text-base">Conciliar</p>
                                                                                            </Button>
                                                                                }
                                                                            </div>
                                                                            <div className={`${tran?.isConciliated ? 'h-48 opacity-70 bg-gray-200' : tran?.bill && !tran?.byGateway && !tran?.selectedSends?.length ? 'h-56' : !tran?.isDiff && tran?.selectedSends?.length ? 'h-44' : 'h-80'} w-[32rem] border border-gray-300 rounded-md shadow-md`}>
                                                                                {
                                                                                    tran?.isConciliated ?
                                                                                        <div className="flex flex-col justify-between h-full">
                                                                                            <div className="flex flex-col gap-4 w-full items-start justify-start">
                                                                                                <div className="flex flex-row justify-between items-center w-full p-2">
                                                                                                    {
                                                                                                        tran?.incomeOrExpense == 'R' ?
                                                                                                            <p className="flex flex-row items-center justify-center gap-2 text-green-500 font-semibold">                                                                                                                
                                                                                                                <GiReceiveMoney className="text-lg"/>
                                                                                                                Receita
                                                                                                            </p>
                                                                                                            :
                                                                                                            <p className="flex flex-row items-center justify-center gap-2 text-red-500 font-semibold">
                                                                                                                <GiPayMoney className="text-lg"/>
                                                                                                                Despesa
                                                                                                            </p>
                                                                                                    }
                                                                                                    <FaExternalLinkAlt
                                                                                                        className="text-blue-500 cursor-pointer hover:brightness-90"
                                                                                                        onClick={() => {
                                                                                                            if (transfersExists) {
                                                                                                                return navigate(`/financial/accountsTransfers`)
                                                                                                            }
                                                                                                            setFilter({
                                                                                                                startDate: '1900-01-01',
                                                                                                                finishDate: '2300-01-01',
                                                                                                                financialConciliationsTransactions_id: tran?.id
                                                                                                            })
                                                                                                            setShowModalTable(true)
                                                                                                            setShowUniversalModal(true)
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="flex flex-col items-start justify-start gap-3 p-2">                                                                                                    
                                                                                                    <p>Valor: {tran?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                                                                    {
                                                                                                        transfersExists && tran?.incomeOrExpense === 'R' ?
                                                                                                        <p><span className="text-primaryDefaultLight">Transferência bancária</span> ({transfersExists?.outAccount})</p>
                                                                                                        : transfersExists && tran?.incomeOrExpense === 'D' ?
                                                                                                        <p><span className="text-primaryDefaultLight">Transferência bancária</span> ({transfersExists?.inAccount})</p>
                                                                                                        : <p>Status do pagamento: Pago</p>
                                                                                                    }                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="w-full flex flex-row items-center justify-between p-2 gap-1">
                                                                                                <p className="text-base font-semibold  text-primaryDefaultLight">
                                                                                                    Conciliado
                                                                                                </p>
                                                                                                {
                                                                                                    transfersExists ?
                                                                                                    <>                                                                                                        
                                                                                                        {
                                                                                                            tran.showRemoveConciliation ?
                                                                                                            <div className="flex flex-row items-center justify-center gap-4 pr-3">
                                                                                                                <Tippy content={'Confirmar'}
                                                                                                                    arrow={true}
                                                                                                                    animation='shift-away'
                                                                                                                    placement='top'
                                                                                                                    delay={90}
                                                                                                                >
                                                                                                                    <div>
                                                                                                                        <FaCheck 
                                                                                                                            className="text-green-600 hover:cursor-pointer hover:brightness-75 transition-all duration-100"
                                                                                                                            onClick={() => {
                                                                                                                                handleRemoveConciliationsOfTransfers({...tran, conciliationsTransactions: transfersExists})
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </Tippy>
                                                                                                                <Tippy content={'Cancelar'}
                                                                                                                    arrow={true}
                                                                                                                    animation='shift-away'
                                                                                                                    placement='top'
                                                                                                                    delay={90}
                                                                                                                >
                                                                                                                    <div>
                                                                                                                        <FaBan 
                                                                                                                            className="text-red-600 hover:cursor-pointer hover:brightness-75 transition-all duration-100"
                                                                                                                            onClick={() => {
                                                                                                                                tran.showRemoveConciliation = false
                                                                                                                                setAccountTransactions({ ...accountTransactions })
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </Tippy>                                                                                                                
                                                                                                            </div>
                                                                                                            :
                                                                                                            <Button 
                                                                                                                height={6}
                                                                                                                onClick={() => {
                                                                                                                    tran.showRemoveConciliation = true
                                                                                                                    setAccountTransactions({ ...accountTransactions })
                                                                                                                }}
                                                                                                            >
                                                                                                                Desconciliar
                                                                                                            </Button>
                                                                                                        }
                                                                                                    </>
                                                                                                    : <></>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        tran?.bill ?
                                                                                            <div className="flex flex-col w-full items-start justify-start">
                                                                                                {
                                                                                                    tran?.selectedSends?.length ?
                                                                                                        <>
                                                                                                            <div className="flex flex-row justify-between items-center w-full p-2">
                                                                                                                <p className="text-primaryDefaultLight font-semibold">Selecionados existentes: {tran?.selectedSends?.length}</p>
                                                                                                                <FaTrash
                                                                                                                    className="text-red-500 cursor-pointer hover:brightness-90"
                                                                                                                    onClick={() => {
                                                                                                                        tran.bill = undefined
                                                                                                                        tran.isDiff = false
                                                                                                                        tran.selectedSends = []
                                                                                                                        setAccountTransactions({ ...accountTransactions })
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <hr className="border w-full"/>
                                                                                                            <div className="flex flex-col gap-3 p-3">
                                                                                                                <p className="text-primaryDefaultLight text-lg font-semibold">Novo</p>
                                                                                                                <p>Valor: <span className="text-primaryDefaultLight font-semibold">{calculateValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></p>
                                                                                                                <p>Status do pagamento: <span className="text-primaryDefaultLight font-semibold">Pago</span></p>
                                                                                                                <p>Condição de pagamento: <span className="text-primaryDefaultLight font-semibold">À vista</span></p>
                                                                                                                <p>Forma de pagamento: <span className="text-primaryDefaultLight font-semibold">{tran?.bill?.financialPaymentMethod?.description}</span></p>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <div className="flex flex-row justify-between items-center w-full p-1">
                                                                                                                {
                                                                                                                    tran?.incomeOrExpense == 'R' ?
                                                                                                                        <p className="flex flex-row items-center justify-center gap-2 text-green-500 font-semibold">
                                                                                                                            <GiReceiveMoney />
                                                                                                                            Receita
                                                                                                                        </p>
                                                                                                                        :
                                                                                                                        <p className="flex flex-row items-center justify-center gap-2 text-red-500 font-semibold">
                                                                                                                            <GiPayMoney />
                                                                                                                            Despesa
                                                                                                                        </p>
                                                                                                                }
                                                                                                                <FaTrash
                                                                                                                    className="text-red-500 text-sm cursor-pointer hover:brightness-90"
                                                                                                                    onClick={() => {
                                                                                                                        tran.bill = undefined
                                                                                                                        setAccountTransactions({ ...accountTransactions })
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="flex flex-col gap-1 p-1">
                                                                                                                <p>Valor: <span className="text-primaryDefaultLight font-semibold">{tran?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></p>
                                                                                                                <p>Status do pagamento: <span className="text-primaryDefaultLight font-semibold">Pago</span></p>
                                                                                                                <p>Condição de pagamento: <span className="text-primaryDefaultLight font-semibold">À vista</span></p>
                                                                                                                <p>Forma de pagamento: <span className="text-primaryDefaultLight font-semibold">{tran?.bill?.financialPaymentMethod?.description}</span></p>
                                                                                                            </div>
                                                                                                        </>
                                                                                                }
                                                                                            </div>
                                                                                            : tran?.selectedSends?.length ?
                                                                                                <div className="flex flex-col gap-4 w-full items-start justify-start">
                                                                                                    <div className="flex flex-row justify-between items-center w-full p-2">
                                                                                                        <p className="text-primaryDefaultLight font-semibold">Selecionados existentes: {tran?.selectedSends?.length}</p>
                                                                                                        <FaTrash
                                                                                                            className="text-red-500 cursor-pointer hover:brightness-90"
                                                                                                            onClick={() => {
                                                                                                                tran.bill = undefined
                                                                                                                tran.isDiff = false
                                                                                                                tran.selectedSends = []
                                                                                                                setAccountTransactions({ ...accountTransactions })
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <p className="p-2">
                                                                                                        Valor: {
                                                                                                            tran?.selectedSends?.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue?.finalValue), 0)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                                                                        }
                                                                                                    </p>
                                                                                                </div>
                                                                                                :
                                                                                                <>
                                                                                                    <div className="w-full p-5 flex flex-row items-center justify-between gap-1">
                                                                                                        <div className="flex flex-row items-center justify-start rounded-md select-none">
                                                                                                            <p
                                                                                                                className={`
                                                                                                                    rounded-l-md p-1 px-2
                                                                                                                    ${tran?.selectedOption == 1
                                                                                                                        ? 'bg-primaryDefaultLight text-white cursor-default'
                                                                                                                        : 'bg-gray-300 text-primaryDefaultLight hover:cursor-pointer hover:brightness-90 transition-all duration-100'
                                                                                                                    }
                                                                                                                `}
                                                                                                                onClick={() => {
                                                                                                                    tran.selectedOption = 1
                                                                                                                    tran.conciliationsTransactions = null
                                                                                                                    setAccountTransactions({ ...accountTransactions })
                                                                                                                }}
                                                                                                            >
                                                                                                                Novo lançamento
                                                                                                            </p>
                                                                                                            <p
                                                                                                                className={`
                                                                                                                    rounded-r-md p-1 px-2
                                                                                                                    ${tran?.selectedOption == 2
                                                                                                                        ? 'bg-primaryDefaultLight text-white cursor-default'
                                                                                                                        : 'bg-gray-300 text-primaryDefaultLight hover:cursor-pointer hover:brightness-90 transition-all duration-100'
                                                                                                                    }                                                                
                                                                                                                `}
                                                                                                                onClick={() => {
                                                                                                                    tran.selectedOption = 2
                                                                                                                    setAccountTransactions({ ...accountTransactions })
                                                                                                                }}
                                                                                                            >
                                                                                                                Transferência
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <p
                                                                                                            onClick={() => { setTransactionTemp(tran); setShowSendExists(true) }}
                                                                                                            className={`flex flex-row items-center justify-center gap-1 text-primaryDefaultLight cursor-pointer hover:brightness-75 transition-all duration-100 select-none`}
                                                                                                        >
                                                                                                            <FaSearch />
                                                                                                            Buscar lançamentos
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    {
                                                                                                        tran?.selectedOption == 1 ?
                                                                                                        <div className="flex flex-col gap-2 w-full items-start justify-between px-3 py-5 mb-4">
                                                                                                            <div className="flex flex-row gap-2">
                                                                                                                <LabelInput text={'Descrição'} required>
                                                                                                                    <Input
                                                                                                                        value={tran?.newDescription ? tran.newDescription : ''}
                                                                                                                        onChange={(e) => {
                                                                                                                            tran.newDescription = e?.target?.value
                                                                                                                            setAccountTransactions({ ...accountTransactions })
                                                                                                                        }}
                                                                                                                        id={`description_${tran?.id}`}
                                                                                                                        width={60}
                                                                                                                    />
                                                                                                                </LabelInput>
                                                                                                                <LabelInput text={tran?.incomeOrExpense == 'R' ? 'Cliente' : 'Fornecedor'} required>
                                                                                                                    <InputDinamicAutoComplete
                                                                                                                        id={`client_${tran?.id}`}
                                                                                                                        onChange={(e) => {
                                                                                                                            tran.client = e
                                                                                                                            setAccountTransactions({ ...accountTransactions })
                                                                                                                        }}
                                                                                                                        endpoint={'/api/v1/financial/utils/usersClientsAndSuppliers'}
                                                                                                                        module={'financial'}
                                                                                                                        optionList={['nameToShow']}
                                                                                                                        optionToSearch={'nameToShow'}
                                                                                                                        filters={{
                                                                                                                            isClient: tran?.incomeOrExpense == 'R' ? true : undefined,
                                                                                                                            isSupplier: tran?.incomeOrExpense == 'D' ? true : undefined
                                                                                                                        }}
                                                                                                                        token={userData[0]?.token}
                                                                                                                        mapTotalPages={['totalPages']}
                                                                                                                        width={60}
                                                                                                                    />
                                                                                                                </LabelInput>
                                                                                                            </div>
                                                                                                            <div className="flex flex-row gap-2">
                                                                                                                <LabelInput text={'Categoria'} required>
                                                                                                                    <InputAutoComplete
                                                                                                                        data={categories?.filter(cat => cat?.incomeOrExpense == tran?.incomeOrExpense)}
                                                                                                                        optionList={['description']}
                                                                                                                        selectedLabel={'description'}
                                                                                                                        onChange={(e) => {
                                                                                                                            tran.category = e
                                                                                                                            setAccountTransactions({ ...accountTransactions })
                                                                                                                        }}
                                                                                                                        value={tran?.category ? tran.category.description : ''}
                                                                                                                        preSelectedValue={tran?.category ? tran.category.description : ''}
                                                                                                                        id={`category_${tran?.id}`}
                                                                                                                        width={60}
                                                                                                                    />
                                                                                                                </LabelInput>
                                                                                                                <LabelInput text={'Forma de pagamento'} required>
                                                                                                                    <InputAutoComplete
                                                                                                                        data={paymentMethodList}
                                                                                                                        id={`paymentMethod_${tran?.id}`}
                                                                                                                        preSelectedValue={tran?.financialPaymentMethod ? tran?.financialPaymentMethod.description : ''}
                                                                                                                        value={tran?.financialPaymentMethod ? tran?.financialPaymentMethod.description : ''}
                                                                                                                        onChange={(e) => {
                                                                                                                            tran.financialPaymentMethod = e
                                                                                                                            setAccountTransactions({ ...accountTransactions })
                                                                                                                        }}
                                                                                                                        selectedLabel={'description'}
                                                                                                                        width={60}
                                                                                                                        optionList={['description']}
                                                                                                                    />
                                                                                                                </LabelInput>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div className="flex flex-col gap-2 w-full items-center justify-center px-3 py-5 mb-4">
                                                                                                            <p className="text-center mb-2 text-primaryDefaultLight font-medium">
                                                                                                                Selecione a transferência correspondente ao lançamento. As opções disponíveis na lista são filtradas de acordo com o tipo de lançamento.
                                                                                                            </p>
                                                                                                            <LabelInput text={'Transferência'} required>
                                                                                                                <InputAutoComplete
                                                                                                                    data={filterTransfers}
                                                                                                                    id={`transfers_${tran?.id}`}
                                                                                                                    preSelectedValue={tran?.conciliationsTransactions ? tran?.conciliationsTransactions.inputValue : ''}
                                                                                                                    value={tran?.conciliationsTransactions ? tran?.conciliationsTransactions.inputValue : ''}
                                                                                                                    onChange={(e) => {
                                                                                                                        tran.conciliationsTransactions = e
                                                                                                                        setAccountTransactions({ ...accountTransactions })
                                                                                                                    }}
                                                                                                                    selectedLabel={'inputValue'}
                                                                                                                    width={96}
                                                                                                                    optionList={['inputValue']}
                                                                                                                />
                                                                                                            </LabelInput>
                                                                                                        </div>
                                                                                                    }
                                                                                                    <hr/>
                                                                                                </>
                                                                                }
                                                                                <div className="flex flex-row gap-2 items-center justify-center mt-4 w-full">
                                                                                    {
                                                                                        tran?.isConciliated ?
                                                                                            <></>
                                                                                            :
                                                                                            tran?.bill || tran?.selectedSends?.length ?
                                                                                                <div className="flex flex-col gap-2 w-full items-center justify-center">
                                                                                                    <hr className="w-full border"/>
                                                                                                    <a 
                                                                                                        className={`text-primaryDefaultLight font-semibold p-1`}
                                                                                                    >
                                                                                                        Concilie esse lançamento para salvar as informações
                                                                                                    </a>
                                                                                                </div>
                                                                                                :
                                                                                                <div className="flex flex-row gap-2 w-full items-end justify-end">
                                                                                                    <p
                                                                                                        className={`
                                                                                                            mr-3 border bg-gray-200 border-gray-300 text-primaryDefaultLight px-2 
                                                                                                            rounded-md mt-1 select-none hover:cursor-pointer hover:brightness-90 transition-all duration-100
                                                                                                        `}
                                                                                                        onClick={() => { 
                                                                                                            setTransactionTemp(tran)
                                                                                                            setShowCreate(true) 
                                                                                                        }}
                                                                                                    >
                                                                                                        Completar informações
                                                                                                    </p>
                                                                                                </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                    <div className="flex md:hidden flex-col ga-4 w-full">
                                        <p className="w-full text-center text-primaryDefaultLight">Funcionalidade disponível apenas para desktop</p>
                                    </div>
                                </Body>
                                <Footer>
                                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                                        <Button shadow={true} approval={false} onClick={() => navigate(-1)}>Voltar</Button>
                                    </div>
                                </Footer>
                            </>
                }
            </Container>
        </>
    )
}