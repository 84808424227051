import React, { useEffect, useState } from 'react'
import { Body } from '../components/container/Body'
import { Container } from '../components/container/container'
import { Title } from '../components/titlePages/title'
import { TitlePage } from '../components/titlePages/title.page'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/useAuth'
import { FaCalendarCheck, FaCheck, FaClock, FaRunning, FaWallet } from 'react-icons/fa'
//imgs
import servicesImg from '../assets/default_assets/soccer.webp'
import cookImg from '../assets/default_assets/cook.webp'
import activitieImg from '../assets/default_assets/activitie_default.webp'
import structuresImg from '../assets/default_assets/structure.jpg'
import { useApiContext } from '../contexts/ApiInterceptorContext'

export function HomeClient() {

    const {defaultCallsAuth, defaultCallsSchedules, api} = useApiContext()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [hasServices, setHasServices] = useState(false)
    const [servicesAppendPhotos, setServicesAppendPhotos] = useState([])
    const [hasGastronomy, setHasGastronomy] = useState(false)
    const [gastronomysAppendPictures, setGastronomysAppendPictures] = useState([])
    const [hasActivities, setHasActivities] = useState(false)
    const [activitiessAppendPictures, setActivitiesAppendPictures] = useState([])
    const [hasStructures, setHasStructures] = useState(false)
    const [structuresAppendPictures, setStructuresAppendPictures] = useState([])
    const { userData } = useAuth()
    const customersModules = userData[0]?.customersData?.modules?.map(module => { return module.id })

    async function formatAPIGastronomyPhoto(picture, id) {

        if (picture?.length > 0) {
            let photosAppendArray = []
            try {
                const file = await api.get(`api/v1/gastronomyPictures/gastronomy_id/${id}/picture/${picture}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })

                const fileObj = URL.createObjectURL(file.data)
                photosAppendArray.push(fileObj)

                if (photosAppendArray.length > 0) {
                    setGastronomysAppendPictures(photosAppendArray)
                    return true
                }
                setGastronomysAppendPictures([cookImg])
                return false
            } catch (error) {
                setGastronomysAppendPictures([cookImg])
                return false
            }
        }
        setGastronomysAppendPictures([cookImg])
        return false
    }

    async function formatAPIServicePhoto(picture, id) {

        if (picture?.length > 0) {
            let photosAppendArray = []
            try {
                const file = await api.get(`api/v1/servicesPictures/services_id/${id}/picture/${picture}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })

                const fileObj = URL.createObjectURL(file.data)
                photosAppendArray.push(fileObj)

                if (photosAppendArray.length > 0) {
                    setServicesAppendPhotos(photosAppendArray)
                    return true
                }
                setServicesAppendPhotos([servicesImg])
                return false
            } catch (error) {
                setServicesAppendPhotos([servicesImg])
                return false
            }
        }
        setServicesAppendPhotos([servicesImg])
        return false
    }

    async function formatAPIActivitiesPhoto(picture, id) {
        if (picture?.length > 0) {
            let photosAppendArray = []

            try {
                const file = await api.get(`api/v1/activitiesPictures/activities_id/${id}/picture/${picture}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })

                const fileObj = URL.createObjectURL(file.data)
                photosAppendArray.push(fileObj)

                if (photosAppendArray.length > 0) {
                    setActivitiesAppendPictures(photosAppendArray)
                    return true
                }
                setActivitiesAppendPictures([activitieImg])
                return false

            } catch (error) {
                setActivitiesAppendPictures([activitieImg])
                return false
            }
        }
        setActivitiesAppendPictures([activitieImg])
        return false
    }

    async function formatAPIStructurePhoto(picture, id) {

        if (picture?.length > 0) {
            let photosAppendArray = []

            try {

                const file = await api.get(`api/v1/structuresPictures/structures_id/${id}/picture/${picture}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })

                const fileObj = URL.createObjectURL(file.data)
                photosAppendArray.push(fileObj)

                if (photosAppendArray.length > 0) {
                    setStructuresAppendPictures(photosAppendArray)
                    return true
                }
                setStructuresAppendPictures([structuresImg])
                return false
            } catch (error) {

                setStructuresAppendPictures([structuresImg])
                return false

            }
        }
        setStructuresAppendPictures([structuresImg])
        return false
    }

    async function getData() {
        setLoading(true)
        const customersDetails = await defaultCallsAuth.getCustomersDetails(userData[0].token)

        //se cliente tem serviços de reserva busca uma foto para exibir
        if (Number(customersDetails.data[0].schedules_services) > 0) {
            setHasServices(true)
            if (Object.keys(customersDetails.data[0]).indexOf('schedules_services_picture') > - 1) {
                let picture = customersDetails.data[0]?.schedules_services_picture?.picture
                let id = customersDetails.data[0]?.schedules_services_picture?.services_id
                await formatAPIServicePhoto(picture, id)
            }
        }

        //se cliente tem gastronomia cadastrada, busca 1 foto para exibir
        if (Number(customersDetails.data[0].gastronomy_services) > 0) {
            setHasGastronomy(true)
            if (Object.keys(customersDetails.data[0]).indexOf('gastronomy_services_picture') > - 1) {
                let picture = customersDetails.data[0]?.gastronomy_services_picture?.picture
                let id = customersDetails.data[0]?.gastronomy_services_picture?.gastronomy_id
                await formatAPIGastronomyPhoto(picture, id)
            }
        }

        //se cliente tem atividades busca uma foto para exibir
        if (Number(customersDetails.data[0].activities_services) > 0) {
            setHasActivities(true)
            if (Object.keys(customersDetails.data[0]).indexOf('activities_services_picture') > - 1) {

                let picture = customersDetails.data[0]?.activities_services_picture?.picture
                let id = customersDetails.data[0]?.activities_services_picture?.activities_id
                await formatAPIActivitiesPhoto(picture, id)
            }
        }

        //se cliente tem estruturas cadastradas busca 1 foto para exibir
        if (Number(customersDetails.data[0].structures_services) > 0) {
            setHasStructures(true)
            if (Object.keys(customersDetails.data[0]).indexOf('structures_services_picture') > - 1) {

                let picture = customersDetails.data[0]?.structures_services_picture?.picture
                let id = customersDetails.data[0]?.structures_services_picture?.structures_id
                await formatAPIStructurePhoto(picture, id)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Container>
            <TitlePage>
                <Title text={'Home'}></Title>
            </TitlePage>
            <Body shadow={false} background={false} padding={false}>
                {
                    loading ?
                        <div className='flex flex-col lg:flex-row w-full gap-3 items-start lg:justify-center h-full '>
                            <div className='flex flex-col w-full lg:w-[75%] gap-3 bg-white p-8 rounded-lg shadow-lg'>
                                <div className='flex flex-col'>
                                    <div className='flex flex-col gap-3'>
                                        <span className='animate-pulse bg-gray-300 h-6 w-48'></span>
                                        <span className='animate-pulse bg-gray-300 h-4 w-48'></span>
                                    </div>
                                </div>
                                <div className='flex flex-row flex-wrap gap-4 items-start justify-start mt-10'>
                                    <div className='flex flex-col gap-2 w-44 bg-zinc-50 p-3 h-20 rounded-lg hover:bg-zinc-100 transition-all duration-150 cursor-pointer'>
                                        <span className='w-12 h-8 animate-pulse bg-gray-300'></span>
                                        <span className='w-16 h-3 animate-pulse bg-gray-300'></span>
                                    </div>
                                    <div className='flex flex-col gap-2 w-44 bg-zinc-50 p-3 h-20 rounded-lg hover:bg-zinc-100 transition-all duration-150 cursor-pointer'>
                                        <span className='w-12 h-8 animate-pulse bg-gray-300'></span>
                                        <span className='w-16 h-3 animate-pulse bg-gray-300'></span>
                                    </div>
                                    <div className='flex flex-col gap-2 w-44 bg-zinc-50 p-3 h-20 rounded-lg hover:bg-zinc-100 transition-all duration-150 cursor-pointer'>
                                        <span className='w-12 h-8 animate-pulse bg-gray-300'></span>
                                        <span className='w-16 h-3 animate-pulse bg-gray-300'></span>
                                    </div>
                                    <div className='flex flex-col gap-2 w-44 bg-zinc-50 p-3 h-20 rounded-lg hover:bg-zinc-100 transition-all duration-150 cursor-pointer'>
                                        <span className='w-12 h-8 animate-pulse bg-gray-300'></span>
                                        <span className='w-16 h-3 animate-pulse bg-gray-300'></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className='flex flex-col lg:flex-row w-full gap-3 items-start lg:justify-center h-full'>
                                <div className='flex flex-col w-full lg:w-[75%] gap-3 '>
                                    <div className='shadow-lg mt-3 md:mt-0 w-full h-full p-8 bg-bgPrimaryLight dark:bg-secondaryDefaultDark border border-zinc-200 dark:border-primaryBorderDark rounded-lg'>
                                        <h2 className='text-lg text-primaryDefaultLight dark:text-titleGrayTextDark font-normal mt-2 '>Olá {userData[0]?.firstName}</h2>
                                        <h2 className='text-sm text-primaryDefaultLight dark:text-titleGrayTextDark font-normal mt-2 '>Acesso rápido</h2>
                                        <div className='flex flex-row flex-wrap gap-3 mt-10'>
                                            {                                            
                                                hasServices && customersModules.includes(1) &&
                                                <div onClick={() => navigate('/reservationIndex')} className='flex flex-col gap-2 w-36 2xl:w-44 bg-zinc-50 p-3 rounded-lg hover:bg-zinc-100 transition-all duration-150 cursor-pointer'>
                                                    <FaCalendarCheck className='text-xl text-primaryDefaultLight' />
                                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark und'>Reservar</p>
                                                </div>
                                            }
                                            {
                                                hasActivities && customersModules.includes(2) &&
                                                <div onClick={() => navigate('/activitiesActionsCreate')} className='flex flex-col gap-2 w-36 2xl:w-44 bg-zinc-50 p-3 rounded-lg hover:bg-zinc-100 transition-all duration-150 cursor-pointer'>
                                                    <FaRunning className='text-xl text-primaryDefaultLight' />
                                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Inscrições</p>
                                                </div>
                                            }
                                            {
                                                userData[0]?.customersData?.useBalance ?
                                                    <div onClick={() => navigate('/myBalance')} className='flex flex-col gap-2 w-36 2xl:w-44 bg-zinc-50 p-3 rounded-lg hover:bg-zinc-100 transition-all duration-150 cursor-pointer'>
                                                        <FaWallet className='text-xl text-primaryDefaultLight' />
                                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Meu saldo</p>
                                                    </div>
                                                    : <></>
                                            }
                                            {
                                                customersModules.includes(1) &&
                                                <div onClick={() => navigate('/mySchedules')} className='flex flex-col gap-2 w-36 2xl:w-44 bg-zinc-50 p-3 rounded-lg hover:bg-zinc-100 transition-all duration-150 cursor-pointer'>
                                                    <FaClock className='text-xl text-primaryDefaultLight' />
                                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Minhas reservas</p>
                                                </div>
                                            }
                                            {
                                                customersModules.includes(2) &&
                                                <div onClick={() => navigate('/mySubscriptions')} className='flex flex-col gap-2 w-36 2xl:w-44 bg-zinc-50 p-3 rounded-lg hover:bg-zinc-100 transition-all duration-150 cursor-pointer'>
                                                    <FaCheck className='text-xl text-primaryDefaultLight' />
                                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Minhas Inscrições</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='sm:grid sm:grid-cols-2 flex flex-col gap-4'>
                                        {
                                            hasServices && customersModules.includes(1) &&
                                            <div
                                                onClick={() => navigate('/reservationIndex')}
                                                style={{
                                                    backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), 
                                                    rgba(27, 27, 27, 0.9)), url(${servicesAppendPhotos})`,
                                                }}
                                                className='shadow-lg 
                                                hover:scale-105 cursor-pointer
                                                transition-all duration-200 bg-cover
                                                bg-no-repeat bg-center
                                                h-72 2xl:h-96 bg-bgPrimaryLight dark:bg-secondaryDefaultDark p-2 
                                                lg:p-8 w-full border border-zinc-200 dark:border 
                                                dark:border-primaryBorderDark rounded-lg
                                                flex flex-col justify-end items-start'>
                                                <a className='animate-[wiggle_1s_ease-in-out] font-light text-white text-2xl'>Reservas</a>
                                                <a className='animate-[wiggle_1s_ease-in-out] font-light text-white text-xl'>Conheça nossos espaços para reserva</a>
                                            </div>
                                        }
                                        {
                                            hasGastronomy &&
                                            <div
                                                onClick={() => navigate('/gastronomy')}
                                                style={{
                                                    backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), rgba(27, 27, 27, 0.9)), url(${gastronomysAppendPictures})`,
                                                }}
                                                className='shadow-lg 
                                            hover:scale-105 cursor-pointer bg-cover
                                            transition-all duration-200
                                            bg-no-repeat bg-center
                                            h-72 2xl:h-96 bg-bgPrimaryLight dark:bg-secondaryDefaultDark p-2 
                                            lg:p-8 w-full border border-zinc-200 dark:border 
                                            dark:border-primaryBorderDark rounded-lg
                                            flex flex-col justify-end items-start'>
                                                <a className='animate-[wiggle_1s_ease-in-out] font-light text-white text-2xl'>Gastronomia</a>
                                                <a className='animate-[wiggle_1s_ease-in-out] font-light text-white text-xl'>Conheça as opções</a>
                                            </div>
                                        }
                                        {
                                            hasActivities && customersModules.includes(2) &&
                                            <div
                                                onClick={() => navigate('/activitiesActionsCreate')}
                                                style={{
                                                    backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), rgba(27, 27, 27, 0.9)), url(${activitiessAppendPictures})`,
                                                }}
                                                className='shadow-lg 
                                            hover:scale-105 cursor-pointer bg-cover
                                            transition-all duration-200
                                            bg-no-repeat bg-center
                                            h-72 2xl:h-96 bg-bgPrimaryLight dark:bg-secondaryDefaultDark p-2 
                                            lg:p-8 w-full border border-zinc-200 dark:border 
                                            dark:border-primaryBorderDark rounded-lg
                                            flex flex-col justify-end items-start'>
                                                <a className='animate-[wiggle_1s_ease-in-out] font-light text-white text-2xl'>Atividades</a>
                                                <a className='animate-[wiggle_1s_ease-in-out] font-light text-white text-xl'>Conheça as opções</a>
                                            </div>
                                        }
                                        {
                                            hasStructures > 0 &&

                                            <div
                                                onClick={() => navigate('/structures')}
                                                style={{
                                                    backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), rgba(27, 27, 27, 0.9)), url(${structuresAppendPictures})`,
                                                }}
                                                className='shadow-lg 
                                            hover:scale-105 cursor-pointer bg-cover
                                            transition-all duration-200
                                            bg-no-repeat bg-center
                                            h-72 2xl:h-96 bg-bgPrimaryLight dark:bg-secondaryDefaultDark p-2 
                                            lg:p-8 w-full border border-zinc-200 dark:border 
                                            dark:border-primaryBorderDark rounded-lg
                                            flex flex-col justify-end items-start'>
                                                <a className='animate-[wiggle_1s_ease-in-out] font-light text-white text-2xl'>Estruturas</a>
                                                <a className='animate-[wiggle_1s_ease-in-out] font-light text-white text-xl'>Conheça as opções</a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </Body>
        </Container>
    )
}