//hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { NewTableDefault } from "../../../components/table/new.table.default";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";

export function Products() {

    const navigate = useNavigate()
    const { api_financial } = useApiContext()
    const { userData } = useAuth()
    const [productsUnitMeasures, setProductsUnitMeasures] = useState([])
    const [loading, setLoading] = useState(true)
    const [filter, setFilter] = useState({
        active: true
    })

    useEffect(() => {
        getData()
    }, [])
    
    async function getData() {
        try {
            setLoading(true)    
            
            const productsUnitMeasureData = await api_financial.get("api/v1/financial/productsUnitMeasure", {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            console.log(productsUnitMeasures)
            setProductsUnitMeasures(productsUnitMeasureData?.data?.data)
        } catch (error) {
            console.log('Error on try to get unit measures', error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full">
                    <Title text='Produtos'></Title>
                    <Button module={"financialProducts:create"} userData={userData} shadow={true} onClick={() => navigate('/financial/productsCreate')}>+ Novo</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    loading 
                        ? <DefaultLoader/>
                        : <div className="flex flex-col gap-2 w-full">
                            <div className='flex flex-row items-center justify-start w-full mb-6'>
                                <button 
                                    className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${filter.active === true ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} 
                                    onClick={() => setFilter({ ...filter, active: true})}
                                >Ativos</button>
                                <button 
                                    className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${filter.active === false ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} 
                                    onClick={() => setFilter({ ...filter, active: false})}
                                >Inativos</button>
                            </div>
                            <NewTableDefault
                                key={'table-patrimony'}
                                endpoint="/api/v1/financial/products"
                                token={userData[0]?.token}
                                module="financial"
                                columns={[
                                    {
                                        description: 'ID',
                                        field: 'id',
                                        type: 'number'
                                    },
                                    {
                                        description: 'Nome',
                                        field: 'name',
                                        type: 'string'
                                    },
                                    {
                                        description: 'sku',
                                        field: 'sku',
                                        type: 'string'
                                    },
                                    {
                                        description: 'Unidade de Medida',
                                        field: 'unitMeasure',
                                        type: 'string',
                                        newFieldValue: (e) => {
                                            return productsUnitMeasures.find(p => p.id == e.unitMeasure)?.description
                                        }
                                    },
                                    {
                                        description: 'Controla Estoque',
                                        field: 'useStock',
                                        type: 'boolean'
                                    },
                                    {
                                        description: 'Criado em',
                                        field: 'created_at',
                                        type: 'datetime'
                                    },
                                
                                ]}
                                filters={filter}
                                onClickInTableLine={(row) => navigate(`/financial/productsEdit/${row.id}`)}
                            />
                        </div>
                }
            </Body>
        </Container>
    )
}