//hooks
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { validateFields } from '../../../utils/form.validator'
import { justNumber } from '../../../utils/validators/justNumber'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { maskCnpj } from '../../../utils/mask/maskCnpj'
import { maskCpf } from '../../../utils/mask/maskCpf'
import { maskCep } from '../../../utils/mask/maskCep'
import { removeEspecialChar } from '../../../utils/validators/removeEspecialCharacter'
import { maskPhone } from '../../../utils/mask/maskPhone'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function ClientsAndSuppliersCreate() {

    const navigate = useNavigate()
    const { api_financial } = useApiContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const [cnpjDetailsObtained, setCnpjDetailsObtained] = useState(false)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    //selected variables
    const [clientsAndSuppliers, setclientsAndSuppliers] = useState({
        name: '',
        fantasyName: '',
        cnpj_cpf: '',
        isClientUser: false,
        personType: { id: 'PJ', name: 'Pessoa Jurídica', value: "PJ" },
        isSupplier: false,
        isClient: false
    })

    async function handleSubmit() {

        const requiredFields =
            clientsAndSuppliers?.personType.value === "PF" ?
                [
                    { "name": 'name', "value": clientsAndSuppliers?.name, "required": true, "type": 'string' },
                    { "name": 'cnpj_cpf', "value": clientsAndSuppliers?.cnpj_cpf, "required": true, "type": 'string' },
                    { "name": 'personType', "value": clientsAndSuppliers?.personType, "required": true, "type": 'string' },
                ]
                :
                [
                    { "name": 'name', "value": clientsAndSuppliers?.name, "required": true, "type": 'string' },
                    { "name": 'cnpj_cpf', "value": clientsAndSuppliers?.cnpj_cpf, "required": true, "type": 'string' },
                    { "name": 'personType', "value": clientsAndSuppliers?.personType, "required": true, "type": 'string' },
                ]
        //chamada API
        setLoading(true)
        try {

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            const formattedCnpj_Cpf = clientsAndSuppliers?.cnpj_cpf.replace(/[^\d]/g, '')
            await api_financial.post(`/api/v1/financial/clientsAndSuppliers`, {
                name: clientsAndSuppliers?.name,
                fantasyName: clientsAndSuppliers?.fantasyName ? clientsAndSuppliers?.fantasyName : undefined,
                personType: clientsAndSuppliers?.personType ? clientsAndSuppliers?.personType?.id : undefined,
                cnpj_cpf: formattedCnpj_Cpf ? formattedCnpj_Cpf : undefined,
                isSupplier: clientsAndSuppliers.isSupplier,
                isClient: clientsAndSuppliers.isClient,
                email: clientsAndSuppliers?.email ? clientsAndSuppliers?.email : undefined,
                dddPhone: clientsAndSuppliers.dddPhone ? clientsAndSuppliers.dddPhone : undefined,
                phone: clientsAndSuppliers?.phone ? clientsAndSuppliers?.phone : undefined,
                addressCep: clientsAndSuppliers?.addressCep ? Number(removeEspecialChar(clientsAndSuppliers?.addressCep)) : undefined,
                addressRoad: clientsAndSuppliers?.addressRoad ? clientsAndSuppliers?.addressRoad : undefined,
                addressNumber: clientsAndSuppliers?.addressNumber ? clientsAndSuppliers?.addressNumber : undefined,
                addressDistrict: clientsAndSuppliers?.addressDistrict ? clientsAndSuppliers?.addressDistrict : undefined,
                addressCity: clientsAndSuppliers?.addressCity ? clientsAndSuppliers?.addressCity : undefined,
                addressState: clientsAndSuppliers?.addressState ? clientsAndSuppliers?.addressState : undefined,
                addressCountry: clientsAndSuppliers?.addressCountry ? clientsAndSuppliers?.addressCountry : undefined,
                municipalRegistration: clientsAndSuppliers?.municipalRegistration ? clientsAndSuppliers?.municipalRegistration : undefined,
                stateRegistration: clientsAndSuppliers?.stateRegistration ? clientsAndSuppliers?.stateRegistration : undefined,
                observation: clientsAndSuppliers.observation ? clientsAndSuppliers.observation : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Cliente/Fornecedor criado com sucesso!")
            navigate(-1)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function getAddress() {
        if (clientsAndSuppliers?.addressCep) {
            setLoading(true)

            fetch(`https://viacep.com.br/ws/${clientsAndSuppliers?.addressCep}/json`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Erro na requisição à API Via CEP');
                    }
                    return response.json();
                })
                .then(data => {
                    setclientsAndSuppliers(prev => ({ ...prev, addressRoad: data.logradouro, addressDistrict: data.bairro, addressCity: data.localidade, addressState: data.uf, addressCountry: 'Brasil' }))
                    setLoading(false)
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error);
                });
        }
        await new Promise(resolve => setTimeout(resolve, 100))
        document.querySelector("#addressRoad").focus()
    }

    if (clientsAndSuppliers?.cnpj_cpf.replace(/[^\d]/g, '')?.length === 14 && clientsAndSuppliers?.personType?.value !== 'PF' && !cnpjDetailsObtained) {
        getDetailsInCnpj()
    }
    
    async function getDetailsInCnpj() {
        try {
            const formattedCnpj = clientsAndSuppliers?.cnpj_cpf.replace(/[^\d]/g, '')
            const response = await fetch(`https://brasilapi.com.br/api/cnpj/v1/${formattedCnpj}`)
            const detailsCnpj = await response.json()
            
            const data = {
                name: detailsCnpj?.razao_social,
                addressCep: detailsCnpj?.cep,
                addressRoad: detailsCnpj?.logradouro,
                addressDistrict: detailsCnpj?.bairro,
                addressCity: detailsCnpj?.municipio,
                addressState: detailsCnpj?.uf
            }
            setclientsAndSuppliers(prev => ({ 
                ...prev, 
                ...data
            }))
            setCnpjDetailsObtained(true)
            setLoading(false)
        } catch (error) {
            console.log('Erro ao buscar CNPJ')
            setLoading(false)
        }
    }

    function cleanByPersonType() {
        const data = {
            name: '',
            cnpj_cpf: '',
            fantasyName: '',
            email: ''
        }
        setclientsAndSuppliers(prev => ({
            ...prev,
            ...data
        }))
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <Title text={'Cadastro cliente/fornecedor'}></Title>
                </TitlePage>
                <Body hasFooter={true}>

                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <form className='flex flex-wrap gap-4 items-start justify-start sm:justify-start w-full'>
                                    <div className='flex gap-1 relative'>
                                        <LabelInput text={'Tipo: *'}>
                                            <InputAutoComplete
                                                preSelectedValue={"Pessoa Júridica"}
                                                data={[
                                                    { id: 'PJ', name: 'Pessoa Jurídica', value: "PJ" },
                                                    { id: 'PF', name: 'Pessoa Física', value: "PF" },
                                                ]}
                                                id={'personType'}
                                                onChange={(e) => { setclientsAndSuppliers(prev => ({ ...prev, personType: e })); cleanByPersonType()}}
                                                selectedLabel={'name'}
                                                optionList={['id', 'name']}
                                            />
                                        </LabelInput>
                                    </div>
                                    <LabelInput text={clientsAndSuppliers?.personType?.value === "PF" ? "CPF *" : "CNPJ *"}>
                                        <Input 
                                            charLimit={30} 
                                            id='cnpj_cpf' 
                                            type={'text'} 
                                            value={
                                                clientsAndSuppliers?.personType?.value === "PF"
                                                ?
                                                maskCpf(clientsAndSuppliers?.cnpj_cpf)
                                                :
                                                maskCnpj(clientsAndSuppliers?.cnpj_cpf)
                                            } 
                                            onChange={(e) => { setclientsAndSuppliers(prev => ({ ...prev, cnpj_cpf: e.target.value })); setCnpjDetailsObtained(false)}}
                                        >
                                        </Input>
                                    </LabelInput>
                                    <LabelInput text={clientsAndSuppliers?.personType?.value === "PF" ? "Nome *" : "Razão Social *"}>
                                        <Input id='name' type={'text'} value={clientsAndSuppliers?.name} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, name: e.target.value }))}></Input>
                                    </LabelInput>
                                    {clientsAndSuppliers?.personType?.value === "PJ" && 
                                        <LabelInput text={'Nome fantasia:'}>
                                            <Input id='fantasyName' type={'text'} value={clientsAndSuppliers?.fantasyName} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, fantasyName: e.target.value }))}></Input>
                                        </LabelInput>
                                    }
                                    <LabelInput text={'E-mail:'}>
                                        <Input charLimit={200} id='email' type={'text'} value={clientsAndSuppliers?.email} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, email: e.target.value }))}></Input>
                                    </LabelInput>
                                    <div className='flex gap-4'>
                                        <LabelInput text={'DDD:'}>
                                            <Input width={12} id='dddPhone' type={'text'} value={clientsAndSuppliers?.dddPhone} charLimit={2} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, dddPhone: justNumber(e.target.value) }))}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Telefone:'}>
                                            <Input 
                                                charLimit={9} 
                                                width={80} 
                                                id='phone' 
                                                type={'text'} 
                                                value={clientsAndSuppliers?.phone ? maskPhone(String(clientsAndSuppliers?.phone)) : ''} 
                                                onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, phone: justNumber(e.target.value) }))}
                                            ></Input>
                                        </LabelInput>
                                    </div>
                                    <LabelInput text={'CEP:'}>
                                        <Input 
                                            id='cep' 
                                            onBlur={() => getAddress()} 
                                            type={'text'} 
                                            value={clientsAndSuppliers?.addressCep ? maskCep(String(clientsAndSuppliers?.addressCep)) : ''} 
                                            onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, addressCep: e.target.value }))}
                                        ></Input>
                                    </LabelInput>
                                    <LabelInput text={'Rua:'}>
                                        <Input charLimit={255} id='addressRoad' type={'text'} value={clientsAndSuppliers?.addressRoad} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, addressRoad: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Número:'}>
                                        <Input id='addressNumber' type={'text'} value={clientsAndSuppliers?.addressNumber} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, addressNumber: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Bairro:'}>
                                        <Input charLimit={150} id='addressDistrict' type={'text'} value={clientsAndSuppliers?.addressDistrict} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, addressDistrict: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Cidade:'}>
                                        <Input charLimit={150} id='addressCity' type={'text'} value={clientsAndSuppliers?.addressCity} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, addressCity: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Estado:'}>
                                        <Input charLimit={150} id='addressState' type={'text'} value={clientsAndSuppliers?.addressState} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, addressState: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'País:'}>
                                        <Input charLimit={150} id='addressCountry' type={'text'} value={clientsAndSuppliers?.addressCountry} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, addressCountry: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Inscrição municipal:'}>
                                        <Input charLimit={30} id='stateRegistration' type={'text'} value={clientsAndSuppliers?.stateRegistration} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, stateRegistration: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Inscrição estadual:'}>
                                        <Input charLimit={30} id='municipalRegistration' type={'text'} value={clientsAndSuppliers?.municipalRegistration} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, municipalRegistration: e.target.value }))}></Input>
                                    </LabelInput>
                                    <div className='flex flex-col gap-4 w-full'>
                                        <a className='text-inputPlaceholderLight text-sm'>Tipo</a>
                                        <div className='flex gap-2 w-fit'>
                                            <div className='w-full flex gap-2'>
                                                <InputCheckBox insideCheck={true} onClick={() => setclientsAndSuppliers(prev => ({ ...prev, isSupplier: !clientsAndSuppliers?.isSupplier }))} value={clientsAndSuppliers?.isSupplier}></InputCheckBox>
                                                <a className='text-sm text-inputPlaceholderLight'>Fornecedor</a>
                                            </div>
                                            <div className='w-full flex gap-2'>
                                                <InputCheckBox insideCheck={true} onClick={() => setclientsAndSuppliers(prev => ({ ...prev, isClient: !clientsAndSuppliers?.isClient }))} value={clientsAndSuppliers?.isClient}></InputCheckBox>
                                                <a className='text-sm text-inputPlaceholderLight'>Cliente</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex w-full flex-col gap-1 items-start justify-start'>
                                        <a className='text-sm text-inputPlaceholderLight'>Obsvervação</a>
                                        <textarea id='observation' wrap="hard" value={clientsAndSuppliers?.observation} onChange={(e) => setclientsAndSuppliers(prev => ({ ...prev, observation: e.target.value }))} maxLength={2000} className='
                                                  p-2 border border-gray-400 border-opacity-75 
                                                  h-64
                                                  w-96
                                                  text-sm
                                                  outline-none transition-all duration-200 focus:shadow-borderShadow
                                                  dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark 
                                                   '>
                                        </textarea>
                                    </div>
                                </form>
                            </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate(-1)}>Voltar</Button>
                        <Button shadow={true} approval={true} onClick={() => handleSubmit()}>Cadastrar</Button>
                    </div>
                </Footer>
            </Container>
        </>

    )
}
