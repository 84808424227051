import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaFile } from 'react-icons/fa'
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
//services
//contexts
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
import { NewTableDefault } from "../../../components/table/new.table.default";
import { Footer } from "../../../components/footer/Footer";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import CurrencyInput from "../../../components/input/inputCurrencyPtBr";
import { validateFields } from "../../../utils/form.validator";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function FinancialConciliationsCreate() {

    const { account_id } = useParams()
    const {api_financial, defaultCallsFinancial } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [ accountsData, setAccountsData ] = useState()
    const [ conciliationData, setConciliationData ] = useState({})
    const [ loading, setLoading ] = useState(true)
    const [ dragging, setDragging ] = useState(false)
    const [ file, setFile ] = useState('')

    useEffect(() => {
        async function getAccountsData(){
            setLoading(true)
            const getAccount = await defaultCallsFinancial.getAccountsById(userData[0]?.token, account_id)
            if (getAccount.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(getAccount.data)
            }
            setAccountsData(getAccount.data)
            setLoading(false)
        }
        getAccountsData()
    }, [])

    const handleDragEnter = (e) => {
        e.preventDefault()
        setDragging(true)
    }

    const handleDragLeave = (e) => {
        e.preventDefault()
        setDragging(false)
    }

    const handleDrop = (e) => {
        e.preventDefault()
        setDragging(false)

        const files = [...e.dataTransfer.files];

        if(!files[0]?.name?.includes('.ofx')){
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Somente arquivo do tipo .ofx são permitidos!')
        }else{
            setShowNotificationModal(false)
            setFile(files[0])
        }
    }

    async function handleSubmit(){
        try {
            const requiredFields = [
                { "name": 'startDate', "value": conciliationData?.startDate, "required": true, "type": 'string' },
                { "name": 'finishDate', "value": conciliationData?.finishDate, "required": true, "type": 'string' },
                { "name": 'finalValue', "value": conciliationData?.finalValue, "required": true, "type": 'number' },
            ]
            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            const startDate = new Date(conciliationData?.startDate)
            const finishDate = new Date(conciliationData?.finishDate)
            if(Number(finishDate) < Number(startDate)){
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('A "Data fim" não pode ser menor que a "Data de início"')
            }

            setLoading(true)

            const formData = new FormData()
            formData.append('ofx', file)
            formData.append('data', JSON.stringify({
                startDate: conciliationData?.startDate,
                finishDate: conciliationData?.finishDate,
                finalValue: conciliationData?.finalValue,
                financialAccounts_id: account_id
            }))

            await api_financial.post('/api/v1/financial/conciliations', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: `Bearer ${userData[0]?.token}`
                },
            })

            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Importação de arquivo OFX')
            navigate(-1)
        } catch (error) {
            const formatedError = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(formatedError?.length > 0 ? formatedError : 'Erro inesperado')
        } finally {
            setLoading(false)
        }
    }

    function handleNewFile(e) {
        if (!e.name.includes('.ofx')) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Somente arquivo do tipo .csv são permitidos!')
        } else {
            setShowNotificationModal(false)
            setFile(e)
        }
    }

    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full">
                    <Title text={`Importação de OFX`}/>
                </div>
            </TitlePage>
            <Body hasFooter={true}>
                <div className="flex flex-col ga-4 w-full">
                    {
                        loading && 
                        <div className="flex items-center justify-center w-full">
                            <DefaultLoader/>
                        </div>
                    }
                    {  
                        !loading &&
                        <div className="text-sm mb-2 text-zinc-500 dark:text-titleGrayTextDark flex flex-col w-full gap-5">
                            <div>
                                <p>Banco: {accountsData[0]?.bankName} | Agência: {accountsData[0]?.accountAgency}</p>
                                <p>Conta: {accountsData[0]?.accountNumber}</p>
                            </div>
                            <div className="w-full flex gap-5">
                                <LabelInput text={"Data de início: *"}>
                                    <Input id='startDate' type={'date'} value={conciliationData?.startDate} onChange={(e) => setConciliationData(prev => ({ ...prev, startDate: e.target.value }))} width={'48'}/>
                                </LabelInput>
                                <LabelInput text={"Data fim: *"}>
                                    <Input id='finishDate' type={'date'} value={conciliationData?.finishDate} onChange={(e) => setConciliationData(prev => ({ ...prev, finishDate: e.target.value }))} width={'48'}/>
                                </LabelInput>
                                <LabelInput text={"Saldo na conta (data fim): *"}>
                                    <CurrencyInput 
                                        id='finalValue' 
                                        placeHolder={
                                            conciliationData?.finalValue 
                                                ? conciliationData?.finalValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })
                                                : 'R$ 0,00' 
                                        } 
                                        onChange={(e) => {
                                            setConciliationData(prev => ({...prev, finalValue: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))}))
                                        }}
                                    />
                                </LabelInput>
                            </div>
                            <div
                                className={` w-full p-2 gap-2 border border-gray-400 border-opacity-75 dark:border-secondaryBorderDark  ${dragging ? 'bg-zinc-300  dark:bg-zinc-700' : 'dark:bg-thirdDefaultDark'} rounded-sm flex flex-col items-center justify-center`}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                onDragOver={handleDragEnter}
                                onDrop={handleDrop}
                                style={{
                                    height: 300,
                                }}
                            >
                                <FaFile className="text-3xl text-primaryDefaultLight" />
                                {dragging && file === '' ? <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Solte para enviar este arquivo OFX</p> : file === '' && <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Arraste e solte um arquivo aqui</p>}
                                {
                                    file !== '' && <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{file.name}</p>

                                }
                                <input id='importFile' type="file" className="hidden" onChange={(e) => handleNewFile(e.target.files[0])}></input>
                                <Button onClick={() => document.getElementById('importFile')?.click()}>
                                    {file === '' ? 'Selecionar arquivo' : 'Alterar arquivo'}
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button shadow={true} approval={false} onClick={() => navigate(-1)}>Voltar</Button>
                    <Button module={"financialConciliations:create"} userData={userData} shadow={true} onClick={() => handleSubmit()}>Importar</Button>
                </div>
            </Footer>
        </Container>
    )
}