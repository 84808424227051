//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
//components
import { Container } from '../../../components/container/container'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { TableDefault } from '../../../components/table/table.default'
import { TableMobile } from '../../../components/table/table.mobile'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { FaEdit, FaTrash } from 'react-icons/fa'
//contexts
import { useAuth } from '../../../contexts/useAuth'
//services
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function Activities() {

    const { defaultCallsSchedules } = useApiContext()
    const { userData } = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [activitiesActiveButton, setActivitiesActiveButton] = useState(1)

    const tableThead = [
        {
            "name": 'Atividade',
            "original_name": 'activitiesName'
        },
        {
            "name": 'Criado em',
            "original_name": "created_at"
        },
    ]

    const [activitiesList, setActivitiesList] = useState([
        { id: 0, activitiesName: 'Academia', created_at: '2022-11-30' },
        { id: 1, activitiesName: 'Escolinha', created_at: '2022-11-30' },
    ])
    const [filteredActivities, setFilteredActivities] = useState([])

    useEffect(() => {

        handleFilterActiveActivities(1)

    }, [activitiesList])

    function handleFilterActiveActivities(status) {

        const newActivitiesList = activitiesList.filter((association) => {
            if (status === 1 && association?.active) {
                return association
            }
            if (status === 0 && !association?.active) {
                return association
            }
            if (status === undefined) {
                return association
            }
        })

        status !== undefined ? setFilteredActivities(newActivitiesList) : setFilteredActivities(activitiesList)
        status > 0 ? setActivitiesActiveButton(1) : setActivitiesActiveButton(0)
    }

    function handleEdit(e) {

        navigate(`/activitiesEdit/${e.id}`)

    }

    async function getData() {

        setLoading(true)

        const activitiesData = await defaultCallsSchedules.getActivities(userData[0].token)

        if (activitiesData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(activitiesData.data)
            return
        }

        setActivitiesList(activitiesData.data)
        setFilteredActivities(activitiesData.data)
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Container>
            <TitlePage>
                <div className='flex flex-row justify-between w-full items-center'>
                    <Title text='Atividades' />
                    <Button module={"activities:create"} userData={userData} shadow={true} onClick={() => navigate('/activitiesCreate')}>+ Nova</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                        <div className='flex flex-col w-full h-96 items-center justify-center'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div className='flex flex-row items-center justify-start w-full mb-6'>
                                <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${activitiesActiveButton === 1 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveActivities(1)}>Ativos</button>
                                <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${activitiesActiveButton === 0 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveActivities(0)}>Inativos</button>
                            </div>
                            <div id='activitiesTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow overflow-y-auto rounded-lg'>
                                <TableDefault
                                    onClick={(e) => handleEdit(e)}
                                    title={tableThead}
                                    data={filteredActivities}
                                    collumns={["activitiesName", "created_at"]}
                                    loading={loading}
                                    filter={(e) => orderTableData(e)}
                                />
                            </div>
                            <div id='activitiesTableContentMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                <TableMobile
                                    onClick={(e) => e.func.execute(e.data)}
                                    title={tableThead}
                                    data={filteredActivities}
                                    collumns={["activitiesName", "created_at"]}
                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                />
                            </div>
                        </>
                }
            </Body>
        </Container>
    )
}