//hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import { FaEdit } from "react-icons/fa";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { TableDefault } from '../../../components/table/table.default'
import { TableMobile } from '../../../components/table/table.mobile'
import { Button } from "../../../components/buttons/button.default";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
//services
import { formattTimeToShow } from "../../../services/api/callAPIsFunctions/defaultCalls.api";
//contexts
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function Sectors() {

    const { defaultCallsAuth } = useApiContext()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [filteredSectors, setFilteredSectors] = useState(true)
    const { userData } = useAuth()
    const [sectorsActiveButton, setSectorsActiveButton] = useState(1)
    const {
        setShowNotificationModal, setShowNotificationModalSuccess,
    } = useThemeContext()
    const [sectorsList, setSectorsList] = useState([])
    const tableThead = [
        {
            name: 'Código',
            original_name: 'id'
        },
        {
            name: 'Nome',
            original_name: 'name'
        },
        {
            name: 'Descrição',
            original_name: 'description'
        },
        {
            name: 'Criado em',
            original_name: 'created_at'
        }
    ]

    useEffect(() => {

        setLoading(true)
        async function getData() {

            const sectorsGet = await defaultCallsAuth.getSectors(userData[0]?.token)
            if (sectorsGet.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(sectorsGet.data)
                return
            }
            setShowNotificationModalSuccess(true)
            setSectorsList(sectorsGet.data.map(sector => {
                return {
                    ...sector,
                    created_at: formattTimeToShow(sector.created_at),
                }
            }))
            setLoading(false)
        }

        getData()

    }, [])

    useEffect(() => {

        handleFilterActiveSectors(1)

    }, [sectorsList])

    function handleFilterActiveSectors(status) {

        const newsectorsList = sectorsList.filter((sector) => {
            if (status === 1 && sector?.active) {
                return sector
            }
            if (status === 0 && !sector?.active) {
                return sector
            }
            if (status === undefined) {
                return sector
            }
        })

        status !== undefined ? setFilteredSectors(newsectorsList) : setFilteredSectors(sectorsList)
        status > 0 ? setSectorsActiveButton(1) : setSectorsActiveButton(0)
    }

    function handleEdit(e) {
        navigate(`/financial/sectorsEdit/${e.id}`)
    }

    return (

        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full">
                    <Title text='Setores'></Title>
                    <Button module={"financialSectors:create"} userData={userData} shadow={true} onClick={() => navigate('/sectorsCreate')}>+ Novo</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                        <DefaultLoader />
                        :
                        <div className="flex flex-col ga-4 w-full">
                            <div className='flex flex-row items-center justify-start w-full mb-6'>
                                <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${sectorsActiveButton === 1 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveSectors(1)}>Ativos</button>
                                <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${sectorsActiveButton === 0 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveSectors(0)}>Inativos</button>
                            </div>
                            <div id='sectorTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow overflow-y-auto rounded-lg'>
                                <TableDefault
                                    rowTitle={'Clique para editar'}
                                    onClick={(e) =>
                                        navigate(`/sectorsEdit/${e.id}`)}
                                    title={tableThead}
                                    data={filteredSectors}
                                    collumns={["id", "name", "description", "created_at"]}
                                />
                            </div>
                            <div id='sectorTableContentMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                <TableMobile
                                    onClick={(e) => e.func.execute(e.data)}
                                    title={tableThead}
                                    data={filteredSectors}
                                    collumns={["id", "name", "description", "created_at"]}
                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                />
                            </div>
                        </div>
                }
            </Body>
        </Container>
    )
}