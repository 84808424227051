import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/useAuth";
import { useApiContext } from "../../contexts/ApiInterceptorContext";
import { FaCheckCircle, FaArrowLeft } from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import agendaRapidoLogo from '../../assets/logos/loginLogo.png';
import cvvLogo from '../../assets/logos/cvv-icon.jpeg';
import { Input } from '../../components/input/input';
import { LabelInput } from '../../components/label/label.input';
import credit1 from '../../assets/logos/credit-1.webp';
import credit2 from '../../assets/logos/credit-2.webp';
import credit3 from '../../assets/logos/credit-3.webp';
import credit4 from '../../assets/logos/credit-4.webp';
import credit5 from '../../assets/logos/credit-5.webp';
import cardChip from '../../assets/reservation/chip.png';
import { CardPlan } from "../card/cardPlan";
import { maskCpf } from '../../utils/mask/maskCpf'
import { maskCnpj } from '../../utils/mask/maskCnpj'
import { maskCep } from '../../utils/mask/maskCep'
import { maskPhone } from '../../utils/mask/maskPhone'

export function FreeTrialExpiredModal(){
    const { userData } = useAuth()
    const { api_adm } = useApiContext()
    const [loading, setLoading] = useState(true)
    const [plans, setPlans] = useState([])
    const [selectedPlan, setSelectedPlan] = useState('')
    const [selectedNumberCard, setSelectedNumberCard] = useState('')
    const [selectedEmail, setSelectedEmail] = useState('')
    const [selectedCpfCnpj, setSelectedCpfCnpj] = useState('')
    const [selectedPhone, setSelectedPhone] = useState('')
    const [selectedCep, setSelectedCep] = useState('')
    const [selectedAddressNumber, setSelectedAddressNumber] = useState('')
    const [selectedExpiryDate, setSelectedExpiryDate] = useState('')
    const [selectedCVC, setSelectedCVC] = useState('')
    const [selectedName, setSelectedName] = useState('')
    const [quantityOfActiveServices, setQuantityOfActiveServices] = useState('')

    useEffect(() => {
        getData()
    }, [])

    async function getData(){
        try {
            const getPlansAPI = await api_adm.get('/api/adm/public/plans')
            const responseData = getPlansAPI?.data?.data
            setPlans(responseData.sort((a, b) => a.value - b.value))

            const getActiveServiceAPI = await api_adm.get('/api/adm/customer/quantityOfActiveServices', {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setQuantityOfActiveServices(getActiveServiceAPI?.data?.data[0]?.quantity)

            setLoading(false)
        } catch(error){
            console.log(error)
            console.log(error?.response)
        } finally {
            setLoading(false)
        }
    }

    const handleCardNumberChange = (e) => {
        const inputValue = e.target.value
        const formattedValue = formatCardNumber(inputValue)
        setSelectedNumberCard(formattedValue)
    }

    const formatCardNumber = (value) => {
        const numericValue = value.replace(/\D/g, "")
        return numericValue.match(/.{1,4}/g)?.join(" ") || ""
    }

    const formatExpiryDate = (value) => {
        const numericValue = value.replace(/\D/g, "")
        if (numericValue.length <= 2) {
          return numericValue;
        }
        return numericValue.slice(0, 2) + "/" + numericValue.slice(2, 6)
    }
    
    const handleExpiryDateChange = (e) => {
        const inputValue = e.target.value
        const formattedValue = formatExpiryDate(inputValue)
        setSelectedExpiryDate(formattedValue)
    }

    function clearStates(){
        setSelectedPlan('')
        setSelectedNumberCard('')
        setSelectedExpiryDate('')
        setSelectedCVC('')
        setSelectedName('')
        setSelectedEmail('')
        setSelectedCpfCnpj('')
        setSelectedPhone('')
        setSelectedCep('')
        setSelectedAddressNumber('')
    }

    async function handleRenewSubscription(e){
        e.preventDefault()
        setLoading(true)
        try {
            await api_adm.post(`/api/adm/customer/subscription/create`, {
                plans_id: selectedPlan?.id,
                creditCard: {
                  holderName: selectedName,
                  number: selectedNumberCard?.replaceAll(' ', ''),
                  expiryMonth: selectedExpiryDate?.split('/')[0],
                  expiryYear: selectedExpiryDate?.split('/')[1],
                  ccv: selectedCVC,
                  email: selectedEmail,
                  cpfCnpj: selectedCpfCnpj?.replaceAll('.', '')?.replace('-', '')?.replace('/', ''),
                  phone: selectedPhone,
                  postalCode: selectedCep?.replace('-', ''),
                  addressNumber: selectedAddressNumber
                }
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
        } catch(error){
            console.log(error)
            console.log(error?.response)
        } finally {
            setLoading(false)
        }
    }

    const marketingAssociation = [
        'Todos os recursos do Plano Pro',
        'Controle de associados',
        'Cobrança de mensalidade'
    ]

    return (
        <div className="modal absolute w-[100vw] h-[100vh] z-[9998] flex items-center justify-center">
            <div className="w-[94vw] h-[85vh] modal-content p-6 rounded-md bg-white relative shadow-lg z-[9999] flex items-center flex-col justify-center gap-6">
                {
                    [1,2].includes(userData[0].typeAccess_id) && !selectedPlan ?
                    <>
                        <h2 className='font-semibold text-red-700 text-xl'>Renovação Pendente</h2>
                        <p className='text-zinc-700'>Seu plano está <strong>expirado</strong>. Por favor, <strong>renove</strong> para continuar utilizando os serviços do Agenda Rápido.</p>
                        <div className='w-full h-full overflow-auto scroll scrollbar-thin flex flex-wrap items-start justify-center gap-5 mt-10'>
                            {
                                loading ?
                                Array.from({ length: 3 }, (_, index) => {
                                    return (
                                        <div className="p-6 w-80 shadow-lg rounded-lg animate-pulse bg-gray-200/50" key={index}>
                                            <div className="h-4 w-16 bg-gray-300 rounded mb-4"></div>
                                            <div className="h-6 w-24 bg-gray-300 rounded mb-4"></div>
                                            <div className="h-8 w-32 bg-gray-300 rounded mb-4"></div>
                                            <div className="h-10 w-full bg-gray-300 rounded mb-4"></div>
                                            <div className="h-12 w-full bg-gray-300 rounded mb-6"></div>
                                            <div className="space-y-2">
                                                <div className="h-4 w-3/4 bg-gray-300 rounded"></div>
                                                <div className="h-4 w-2/3 bg-gray-300 rounded"></div>
                                                <div className="h-4 w-5/6 bg-gray-300 rounded"></div>
                                                <div className="h-4 w-3/4 bg-gray-300 rounded"></div>
                                                <div className="h-4 w-2/3 bg-gray-300 rounded"></div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="flex flex-wrap w-full items-start justify-center gap-5">
                                    {plans?.map(plan => (
                                        <CardPlan 
                                            key={plan?.id} 
                                            plan={plan}
                                            setSelectedPlan={setSelectedPlan}
                                            quantityOfActiveServices={quantityOfActiveServices}
                                        />
                                    ))}
                                    <div className="p-6 w-80 min-h-[31rem] shadow-lg rounded-lg bg-gray-200/50" key={'card-association'}>
                                        <p className="w-full mb-4 text-lg font-medium select-none">Associações e Grêmios</p>
                                        <div className="h-[10rem]"></div>
                                        <div 
                                            className="h-12 w-full bg-primaryDefaultLight text-white items-center justify-center flex rounded mb-6 cursor-pointer hover:brightness-90 select-none"
                                            onClick={() => {}}
                                        >
                                            Fale conosco
                                        </div>
                                        <p className="mt-2 text-zinc-400 text-sm select-none">Inclui:</p>
                                        <div className="flex flex-col gap-1 text-sm w-full select-none">
                                            {
                                                marketingAssociation?.map(mark => (
                                                <div className="flex items-center gap-1 text-zinc-400">
                                                    <FaCheckCircle className="text-sm flex-shrink-0" />
                                                    <p className="leading-normal">{mark}</p>
                                                </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>                                
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className='w-full h-full overflow-auto scroll scrollbar-thin flex flex-wrap items-start justify-center gap-10'>
                            <div className="flex flex-col items-start w-96 h-full">
                                <div 
                                    className="flex flex-row gap-2 mt-10 items-center justify-start hover:brightness-75 hover:cursor-pointer transition-all duration-100" 
                                    onClick={() => clearStates()}
                                >
                                    <FaArrowLeft className="text-zinc-400 text-sm"/>
                                    <img src={agendaRapidoLogo} width={150} />
                                </div>
                                <div className="flex flex-col w-full mb-4 select-none mt-10">
                                    <p className="text-zinc-400">Assinar {selectedPlan?.name}</p>
                                    <div className="flex flex-row w-full items-center gap-1">
                                        <p className="text-3xl font-semibold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(selectedPlan?.newValue)}</p>
                                        <div className="text-zinc-400 flex flex-col -space-y-2">
                                            <p>por</p>
                                            <p>mês</p>
                                        </div>
                                    </div>
                                    <p className="text-xs text-zinc-400">Cobrado mensalmente</p>
                                </div>
                                <div className="flex flex-col w-full mt-10">
                                    <div className="flex flex-row w-full justify-between text-sm">
                                        <p>{selectedPlan?.name}</p>
                                        <p>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(selectedPlan?.value)}</p>
                                    </div>
                                    <p className="text-xs text-zinc-400">Quantidade 1</p>                                    
                                </div>
                                {
                                    quantityOfActiveServices > 0 &&
                                    <div className="flex flex-col w-full mt-4">
                                        <div className="flex flex-row w-full justify-between text-sm">
                                            <p>Adicional</p>
                                            <p>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(selectedPlan?.valuePerAdditionalService * quantityOfActiveServices)}</p>
                                        </div>
                                        <p className="text-xs text-zinc-400">Quantidade {quantityOfActiveServices}</p>
                                    </div>
                                }
                                <div className="flex flex-col w-full items-center justify-center mt-10">
                                    <div className='flex flex-row gap-1 items-center justify-start mb-1'>
                                        <Tippy content={'Mastercard'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <img src={credit1} width={50}></img>
                                        </Tippy>
                                        <Tippy content={'Visa'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <img src={credit2} width={50}></img>
                                        </Tippy>
                                        <Tippy content={'Elo'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <img src={credit3} width={50}></img>
                                        </Tippy>
                                        <Tippy content={'American Express'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <img src={credit4} width={50}></img>
                                        </Tippy>
                                        <Tippy content={'Hipercard'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <img src={credit5} width={50}></img>
                                        </Tippy>
                                    </div>
                                    <div id='creditCardVirtual' className='mb-4 shadow-lg shadow-zinc-400 dark:shadow-zinc-900 bg-gradient-to-r from-primaryDefaultLight to-secondaryDefaultLight rounded-lg w-72 h-40 flex flex-col items-center justify-center gap-2 p-4'>
                                        <div className='flex flex-col w-full items-start justify-start'>
                                            <img width={30} height={25} src={cardChip} />
                                            <p className='mb-2 text-titleGrayTextLight text-sm'>{selectedNumberCard ? selectedNumberCard : 'XXXX XXXX XXXX XXXX'}</p>
                                            <div className='flex flex-col w-full items-start justify-center gap-1'>
                                                <p className='text-[0.60rem] text-titleGrayTextLight font-bold'>Vencimento</p>
                                                <p className='text-[0.60rem] text-titleGrayTextLight'>{selectedExpiryDate ? selectedExpiryDate : 'MM / AA'}</p>
                                                <p className='text-[0.60rem] text-titleGrayTextLight '>{selectedName ? selectedName : 'Seu nome como no cartão'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-[32rem] h-full pl-3">
                                <div class="max-w-md mx-auto p-6 bg-white flex flex-col gap-2 mt-10 items-start justify-start">
                                    <h2 className="text-xl">Pagar com cartão</h2>
                                    <form class="w-full bg-white p-4 rounded-md shadow-md" onSubmit={handleRenewSubscription}>
                                        <div class="mb-4">
                                            <h3 class="block text-sm font-medium text-gray-700 mb-1">Dados do titular</h3>
                                            <LabelInput>
                                                <Input
                                                    type="email"
                                                    placeholder={'E-mail *'}
                                                    width={42}
                                                    charLimit={255}
                                                    value={selectedEmail ? selectedEmail : ''}
                                                    onChange={(e) => setSelectedEmail(e?.target?.value)}
                                                    required
                                                />
                                            </LabelInput>
                                            <LabelInput>
                                                <Input
                                                    type="text"
                                                    placeholder={'CPF / CNPJ *'}
                                                    width={42}
                                                    charLimit={18}
                                                    value={selectedCpfCnpj && selectedCpfCnpj?.length < 15 ? maskCpf(selectedCpfCnpj) : selectedCpfCnpj && selectedCpfCnpj?.length > 14 ? maskCnpj(selectedCpfCnpj) : ''}
                                                    onChange={(e) => setSelectedCpfCnpj(e?.target?.value)}
                                                    required
                                                />
                                            </LabelInput>
                                            <LabelInput>
                                                <Input
                                                    type="text"
                                                    placeholder={'Telefone *'}
                                                    width={42}
                                                    charLimit={255}
                                                    value={selectedPhone ? maskPhone(selectedPhone) : ''}
                                                    onChange={(e) => setSelectedPhone(e?.target?.value)}
                                                    required
                                                />
                                            </LabelInput>
                                            <div class="grid grid-cols-3 gap-2 mt-2 mb-4">
                                                <div className="col-span-2">
                                                    <LabelInput>
                                                        <Input
                                                            type="text"
                                                            placeholder="CEP *"
                                                            width={42}
                                                            value={selectedCep ? maskCep(selectedCep) : ''}
                                                            onChange={(e) => setSelectedCep(e?.target?.value)}
                                                            required
                                                        />
                                                    </LabelInput>
                                                </div>
                                                <LabelInput>
                                                    <Input
                                                        type="text"
                                                        placeholder="Número *"
                                                        width={42}
                                                        charLimit={7}
                                                        value={selectedAddressNumber ? selectedAddressNumber : ''}
                                                        onChange={(e) => setSelectedAddressNumber(e?.target?.value)}
                                                        required
                                                    />
                                                </LabelInput>
                                            </div>
                                            <h3 class="block text-sm font-medium text-gray-700 mb-1">Dados do cartão</h3>
                                            <LabelInput>
                                                <Input
                                                    type="text"
                                                    placeholder="1234 1234 1234 1234"
                                                    width={42}
                                                    charLimit={19}
                                                    value={selectedNumberCard ? selectedNumberCard : ''}
                                                    onChange={handleCardNumberChange}
                                                />
                                            </LabelInput>
                                            <div class="grid grid-cols-2 gap-2 mt-2">
                                                <LabelInput>
                                                    <Input
                                                        type="text"
                                                        placeholder="MM / AAAA"
                                                        width={42}
                                                        value={selectedExpiryDate ? selectedExpiryDate : ''}
                                                        charLimit={9}
                                                        onChange={handleExpiryDateChange}
                                                    />
                                                </LabelInput>
                                                <LabelInput>
                                                    <Input
                                                        type="text"
                                                        placeholder="CVC"
                                                        width={42}
                                                        charLimit={3}
                                                        value={selectedCVC ? selectedCVC : ''}
                                                        onChange={(e) => setSelectedCVC(e?.target?.value)}
                                                        required
                                                    />
                                                    <div class="absolute inset-y-0 right-2 flex items-center">
                                                        <img src={cvvLogo} alt="Info" class="h-4 w-auto" />
                                                    </div>
                                                </LabelInput>
                                            </div>
                                        </div>
                                        <div>
                                            <h3 class="block text-sm font-medium text-gray-700 mb-1">Nome do titular do cartão</h3>
                                            <LabelInput>
                                                <Input
                                                    type="text"
                                                    placeholder="Nome completo *"
                                                    onChange={(e) => setSelectedName(e?.target?.value)}
                                                    required
                                                    charLimit={255}
                                                    width={42}
                                                    value={selectedName ? selectedName : ''}
                                                />
                                            </LabelInput>
                                        </div>
                                        <button type="submit" class="w-full py-2 bg-blue-600 text-white font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-blue-500 mt-3">
                                            Assinar
                                        </button>
                                    </form>
                                    <p class="mt-4 text-xs text-gray-500 text-center">
                                        Ao confirmar sua assinatura, você permite que Agenda Rápido cobre você em referência a 
                                        pagamentos futuros em conformidade com os termos da empresa. Você pode cancelar a assinatura quando quiser.
                                    </p>
                                </div>                                
                            </div>                            
                        </div>
                    </>
                }
                {
                    [3].includes(userData[0].typeAccess_id) &&
                        <>
                            <h2 className='font-semibold text-red-700 text-xl'>Renovação Pendente</h2>
                            <p className='text-zinc-700'>Seu plano está <strong>expirado</strong>. Por favor, <strong>renove</strong> para continuar utilizando os serviços do Agenda Rápido.</p>
                            <p className='text-zinc-700'>Entre em contato com o gestor para entender o que esta ocorrendo.</p>
                        </>
                }
                {
                    ![1,2,3].includes(userData[0].typeAccess_id) &&
                    <>
                            <h2 className='font-semibold text-red-700 text-xl'>Acesso Indisponível</h2>
                            <p className='text-zinc-700'>Entre em contato com a clube/grêmio/associação para entender o que está ocorrendo.</p>
                        </>
                }
            </div>
            <div className='bg-zinc-700 opacity-80 absolute z-[9998] h-full w-full'></div>
        </div>
    )
}
