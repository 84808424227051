//hooks
import React from 'react'
import { Route, Routes, HashRouter } from 'react-router-dom'
//components
import { Menu } from '../components/menu/menu'
//contexts
import { useAuth } from '../contexts/useAuth'
import { useScreenSizeContext } from '../contexts/screenSizeContext'
//pages
import { ProtectedRoute } from '../pages/security/privateRoute'
import { MyProfileEdit } from '../pages/_general/myProfile/myProfile'
import { HomeClient } from '../pages/home.client'
import { ActivitiesActionsCreateClients } from '../pages/onMenu/_schedulesAndActivitiesSubs/activitiesActions/activitiesActionsCreate.clients'
import { MyBalance } from '../pages/onMenu/_schedulesAndActivitiesSubs/myBalance/myBalance'
import { GastronomyClient } from '../pages/onMenu/_schedulesAndActivitiesSubs/gastronomy/gastronomy.clients'
import { ReceiptConfirmation } from '../components/receipt/receiptConfirmation'
import { ReceiptActivitiesActions } from '../components/receipt/receiptActivitiesActions'
import { Receipt } from '../components/receipt/receipt'
import { ReservationClient } from '../pages/onMenu/_schedulesAndActivitiesSubs/reservation/reservation.create.client'
import { StructuresClient } from '../pages/onMenu/_schedulesAndActivitiesSubs/structures/structures.clients'
import { AgreementsClient } from '../pages/onMenu/_schedulesAndActivitiesSubs/agreements/agreements.client'
import { EventsClient } from '../pages/onMenu/_schedulesAndActivitiesSubs/events/events.client'
import { MySchedules } from '../pages/onMenu/_schedulesAndActivitiesSubs/mySchedules/mySchedules'
import { MySubscriptions } from '../pages/onMenu/_schedulesAndActivitiesSubs/mySubscriptions/mySubscriptions'
import { ReservationFailed } from '../components/reservationStatus/reservationFailed'
import { MySchedulesDetails } from '../pages/onMenu/_schedulesAndActivitiesSubs/mySchedules/mySchedulesDetails'
import { MySchedulesDraw } from '../pages/onMenu/_schedulesAndActivitiesSubs/mySchedules/mySchedulesDraw'
import { MySchedulesDrawDetails } from '../pages/onMenu/_schedulesAndActivitiesSubs/mySchedules/mySchedulesDrawDetails'
import { ActivitiesActionsCreateFailed } from '../components/ActivitiesActionsCreateStatus/ActivitiesActionsCreateFailed'
import { MySubscriptionsDetails } from '../pages/onMenu/_schedulesAndActivitiesSubs/mySubscriptions/mySubscriptionsDetails'
//config
import { generalRoutesConfig, activitiesAndSchedulesRoutesConfig, financialRoutesConfig } from './routes.config'
import { ReservationClientRoute } from '../pages/onMenu/_schedulesAndActivitiesSubs/reservation/reservation.client.route'

export function AppRoutes() {

    const { userData, selectedModule } = useAuth()
    const { screenX } = useScreenSizeContext()
    const notInApp = document.URL.includes('receipt')
    const customersModules = userData[0]?.customersData?.modules?.map(module => { return module.id })

    return (
        <>
            <HashRouter>
                <Routes>
                    <Route path='/receiptConfirmation' element={
                        <ReceiptConfirmation />
                    }>
                    </Route>
                    <Route path='/receipt' element={
                        <Receipt />
                    }>
                    </Route>
                    <Route path='/receiptActivitiesActions' element={
                        <ReceiptActivitiesActions />
                    }>
                    </Route>
                </Routes>
                {
                    !notInApp &&
                    <div className={`${screenX < 640 ? '' : 'h-[100vh]'}  flex flex-col-reverse sm:flex-row justify-between md:justify-items-start overflow-hidden`}>
                        {
                            !document.URL.includes('receipt') &&
                            <Menu />
                        }
                        {
                            userData[0]?.email?.length === 0 || !userData[0]?.email ?
                                <Routes>
                                    <Route path={`/*`} element={
                                        <ProtectedRoute user={userData} func={[]}>
                                            <MyProfileEdit />
                                        </ProtectedRoute>
                                    } />
                                    <Route path='/myProfileEdit/:id' element={<MyProfileEdit />} />
                                </Routes>
                                :
                                userData[0]?.typeAccess_id < 4 ?
                                    //Our Customer Routes
                                    <Routes>
                                        {/* GERAL */}
                                        {generalRoutesConfig.map(routeConfig => {
                                            return routeConfig.protectedRoute.length == 0 ? (
                                                <Route path={routeConfig.path} element={routeConfig.element} />
                                            ) : (
                                                <Route path={routeConfig.path} element={
                                                    <ProtectedRoute module={routeConfig.module} user={userData} func={routeConfig.protectedRoute}>
                                                        {routeConfig.element}
                                                    </ProtectedRoute>
                                                } />
                                            )
                                        })}
                                        {/* RESERVAS E INSCRIÇÕES */}
                                        {(selectedModule.includes(1) || selectedModule.includes(2)) &&
                                            activitiesAndSchedulesRoutesConfig.map(routeConfig => {
                                                return routeConfig.protectedRoute.length == 0 ? (
                                                    <Route path={routeConfig.path} element={routeConfig.element} />
                                                ) : (
                                                    <Route path={routeConfig.path} element={
                                                        <ProtectedRoute module={routeConfig.module} user={userData} func={routeConfig.protectedRoute}>
                                                            {routeConfig.element}
                                                        </ProtectedRoute>
                                                    } />
                                                )
                                            })}
                                        {/* FINANCEIRO */}
                                        {selectedModule.includes(3) &&
                                            financialRoutesConfig.map(routeConfig => {
                                                return routeConfig.protectedRoute.length == 0 ? (
                                                    <Route path={routeConfig.path} element={routeConfig.element} />
                                                ) : (
                                                    <Route path={routeConfig.path} element={
                                                        <ProtectedRoute module={routeConfig.module} user={userData} func={routeConfig.protectedRoute}>
                                                            {routeConfig.element}
                                                        </ProtectedRoute>
                                                    } />
                                                )
                                            })}
                                    </Routes>
                                    :
                                    //ROTAS DOS CLIENTES FINAIS - TIPO DE ACESSO 4
                                    <Routes>
                                        <Route path='/' element={<HomeClient />} />
                                        {
                                            customersModules.includes(2) &&
                                            <>
                                                <Route path='/activitiesActionsCreate' element={<ActivitiesActionsCreateClients />} />
                                                <Route path='/activitiesActionsCreate/:paymentId' element={<ActivitiesActionsCreateClients />} />
                                                <Route path='/activitiesActionsCreateFailed/:id' element={<ActivitiesActionsCreateFailed />} />
                                                <Route path='/mySubscriptions' element={<MySubscriptions />} />
                                                <Route path='/mySubscriptionsDetails/:id' element={<MySubscriptionsDetails />} />
                                            </>
                                        }
                                        <Route path='/agreements' element={<AgreementsClient />} />
                                        <Route path='/events' element={<EventsClient />} />
                                        <Route path='/gastronomy' element={<GastronomyClient />} />
                                        {
                                            userData[0]?.customersData?.useBalance &&
                                            <Route path='/myBalance' element={<MyBalance />} />
                                        }
                                        <Route path='/myProfileEdit/:id' element={<MyProfileEdit />} />
                                        <Route path='/mySchedules' element={<MySchedules />} />
                                        <Route path='/mySchedulesDraw' element={<MySchedulesDraw />} />
                                        <Route path='/mySchedulesDrawDetails/:id' element={<MySchedulesDrawDetails />} />
                                        <Route path='/mySchedulesDetails' element={<MySchedulesDetails />} />
                                        <Route path='/reservationIndex' element={<ReservationClientRoute />} />
                                        <Route path='/reservation' element={<ReservationClient />} />
                                        <Route path='/reservation/:paramsPaymentId' element={<ReservationClient />} />
                                        <Route path='/reservation/categories_id/:categories_id/services_id/:services_id' element={<ReservationClient />} />
                                        <Route path='/reservation/categories_id/:categories_id/services_id/:services_id/date/:date' element={<ReservationClient />} />
                                        <Route path='/reservationFailed/:id' element={<ReservationFailed />} />
                                        <Route path='/structures' element={<StructuresClient />} />
                                    </Routes>
                        }
                    </div>
                }
            </HashRouter>
        </>
    )
}
